import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'itfgGetTopicRoot',
})
export class GetTopicRootPipe implements PipeTransform {
  transform(value: string): string {
    if (!value) {
      return value;
    }

    return value.split('/')[0];
  }
}
