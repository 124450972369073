import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { interval } from 'rxjs';
import { map } from 'rxjs/operators'


@Component({
  selector: 'itfg-unauthorized-page',
  templateUrl: './unauthorized-page.component.html',
  styleUrls: ['./unauthorized-page.component.scss'],
})
export class UnauthorizedPageComponent implements OnInit, OnDestroy {
  public countdown;
  public timeLeft = 4;
  constructor( private router: Router) {}

  ngOnInit() {
    this.countdown = setInterval(() => {
      this.timeLeft = this.timeLeft - 1; 
      
      if(!this.timeLeft){
        this.router.navigate([''])
      }
    }, 1000)
  }

  ngOnDestroy() {
    clearInterval(this.countdown);
  }
}
