import { filter, takeUntil } from 'rxjs/operators';
import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  ViewContainerRef,
  OnChanges,
  SimpleChanges,
  OnDestroy,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DialogProviderService } from '@app/core/services/dialog-provider.service';

import { FormComponentData } from '../../../../core/types/form-component-data';
import { Utils } from '../../../../core/utils/utils';
import { BlacklistService } from '../../../../core/services/blacklist.service';
import { BlacklistEditComponent } from '../../blacklist-edit/blacklist-edit.component';
import { IdentifierValidation } from '../../../../core/validation/identifier-validation';
import {
  SearchCriterium,
  SearchOperations,
} from '../../../../core/types/search-criterium';
import { TranslateService } from '@ngx-translate/core';
import { Brand } from '../../../../core/types/brand';
import { IpInfo } from '../../../../core/types/ip-info.type';
import { IdentityReportsService } from '../../../../core/services/identity-reports.service';
import { Client, ClientLastLogin } from '../../../../core/types/client';
import * as moment from 'moment/moment';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';

@Component({
  selector: 'itfg-profile-data-edit',
  templateUrl: './profile-data-edit.component.html',
  styleUrls: ['./profile-data-edit.component.scss'],
})
export class ProfileDataEditComponent implements OnInit, OnChanges, OnDestroy {
  profileDataForm: UntypedFormGroup;
  @Output() dataChanged = new EventEmitter<FormComponentData>();
  @Output() sendPasswordByEmailClicked = new EventEmitter<FormComponentData>();
  @Output() sendPasswordBySmsClicked = new EventEmitter<FormComponentData>();
  @Input() data: any;
  @Input() client: Client;
  @Input() isNewItem: boolean;
  @Input() isDirty: boolean;
  @Input() brandList: Brand[];
  @Input() lastLogin: ClientLastLogin;
  phoneIsInBlacklist: boolean;
  isPrivateIp: boolean;
  isInCreditEdit: boolean;
  isInClientEdit: boolean;
  ipInfo: IpInfo;
  METABASE_IP_MATCHES_LINK: string;
  blacklistData: any;
  formComponentData: FormComponentData = {} as FormComponentData;
  minValuePassword: number;
  _unsubscribe = new Subject<void>();


  constructor(
    private _dialogService: DialogProviderService,
    private _viewContainerRef: ViewContainerRef,
    private translate: TranslateService,
    public formBuilder: UntypedFormBuilder,
    public blacklistService: BlacklistService,
    public dialog: MatDialog,
    private identity: IdentityReportsService,
    private router: Router,
  ) {
    this.phoneIsInBlacklist = false;
    this.blacklistData = {};
    this.METABASE_IP_MATCHES_LINK = '';
  }

  ngOnInit() {
    this.setInitialData();
    this.isInCreditEdit = this.router.url.includes('credits');
    this.isInClientEdit = this.router.url.includes('clients');
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.isDirty) {
      Object.keys(this.profileDataForm.controls).forEach(control => {
        this.profileDataForm.controls[control].markAsTouched();
      });
    }
    if (changes.data && this.data) {
      this.setInitialData();
    }
    if (changes.lastLogin && this.lastLogin) {
      this.isPrivateIp = Utils.isPrivateIp(this.lastLogin.ip);
      this.identity.getIpInfo$(this.lastLogin.ip)
        .pipe(takeUntil(this._unsubscribe))
        .subscribe(info => {
          this.ipInfo = info;
          const yearAgo = moment().subtract(1, 'years').format('YYYY-MM-DD');
          this.METABASE_IP_MATCHES_LINK = 'http://18.185.196.150:3000/question/393?ip=' + this.lastLogin.ip + '&fromDate=' + yearAgo;
        });
    }
  }

  setInitialData() {
    this.ipInfo = undefined;
    this.profileDataForm = this.createProfileDataForm(this.isNewItem);

    if (this.isNewItem) {
      this.profileDataForm.get('brand').setValidators([Validators.required]);
    }
    this.watchForFormChanges();

    if (!this.isNewItem && this.data.mobile.length > 0) {
      this.checkIfPhoneIsInBlacklist();
    }
  }

  checkIfPhoneIsInBlacklist() {
    this.phoneIsInBlacklist = false;
    const blacklistHttpCallOptions: Array<SearchCriterium> = [
      // Value types:
      // '1': 'Civil'
      // '2': 'Employer id'
      // '3': 'Phone number'
      {
        key: 'blacklistType.id',
        operation: SearchOperations.EQUALS,
        value: '3',
      },
      {
        key: 'value',
        operation: SearchOperations.IN,
        value: [this.profileDataForm.value.mobile],
      },
    ];

    this.blacklistService
      .getBlacklist({ criteria: JSON.stringify(blacklistHttpCallOptions) })
      .pipe(takeUntil(this._unsubscribe))
      .subscribe(response => {
        this.blacklistData = response;
        if (response.content && response.content.length > 0) {
          this.phoneIsInBlacklist = true;
        }
      });
  }

  addToBlacklist() {
    const blacklistDialogOblect = {
      blacklistType: {
        id: 3,
      },
      value: this.profileDataForm.value.mobile,
      isPreset: true,
    };
    const dialogRef = this.dialog.open(BlacklistEditComponent, {
      data: blacklistDialogOblect,
    });

    dialogRef.afterClosed()
      .pipe(takeUntil(this._unsubscribe))
      .subscribe(result => {
        this.checkIfPhoneIsInBlacklist();
      });
  }

  editInBlacklist() {
    const blacklistDialogOblect = this.blacklistData.content[0];
    blacklistDialogOblect.isPreset = true;

    const dialogRef = this.dialog.open(BlacklistEditComponent, {
      data: blacklistDialogOblect,
    });

    dialogRef.afterClosed()
      .pipe(takeUntil(this._unsubscribe))
      .subscribe(result => {
        this.checkIfPhoneIsInBlacklist();
      });
  }

  createProfileDataForm(isNewItem) {
    this.minValuePassword = 8;
    const fb = this.formBuilder;
    return fb.group({
      email: [
        this.data.email ? this.data.email : '',
        [Validators.email, IdentifierValidation.isValidIdEmail],
      ],
      brand: [this.data && this.data.brand ? this.data.brand : null],
      clientId: [this.client.id ? this.client.id : null],
      mobile: [
        this.data.mobile ? this.data.mobile : '',
        [IdentifierValidation.bulgarianPhoneNumber],
      ],
      emailConfirmed: [
        this.data.emailConfirmed ? this.data.emailConfirmed : false,
      ],
      mobileConfirmed: [
        this.data.mobileConfirmed ? this.data.mobileConfirmed : false,
      ],
      pep: [this.data.pep ? this.data.pep : false],
      loyaltyEnabled: [this.data.loyaltyEnabled ? this.data.loyaltyEnabled : false],
      facebookId: [this.data.facebookId ? this.data.facebookId : null],
      googleId: [this.data.googleId ? this.data.googleId : null]
    });
  }

  compareItemsById(firstObj, secondObj) {
    if (firstObj && secondObj) {
      return firstObj.id === secondObj.id;
    } else {
      return false;
    }
  }

  watchForFormChanges() {
    this.profileDataForm.valueChanges
      .pipe(takeUntil(this._unsubscribe))
      .subscribe(val => {
        // Set values for model
        this.formComponentData.valid = this.profileDataForm.valid;
        this.formComponentData.dirty = this.profileDataForm.dirty;
        this.formComponentData.data = this.profileDataForm.value;

        // Remove empty records
        this.formComponentData.data = Utils.removeValues(
          this.formComponentData.data,
          ''
        );

        this.dataChanged.emit(this.formComponentData);
      });
  }

  sendPasswordByEmail() {
    this._dialogService
      .openConfirm({
        message: this.translate.instant(
          'clients.sendPasswordByEmailConfirmationMessage',
          { email: this.data.email }
        ),
        viewContainerRef: this._viewContainerRef,
        title: this.translate.instant('global.confirm'),
        cancelButton: this.translate.instant('global.cancel'),
        acceptButton: this.translate.instant('global.confirm'),
      })
      .afterClosed()
      .pipe(
        takeUntil(this._unsubscribe),
        filter(accept => accept === true)
      )
      .subscribe((accept: boolean) => {
        this.sendPasswordByEmailClicked.emit();
      });
  }

  sendPasswordBySms() {
    this._dialogService
      .openConfirm({
        message: this.translate.instant(
          'clients.sendPasswordBySmsConfirmationMessage',
          { phoneNumber: this.data.mobile }
        ),
        viewContainerRef: this._viewContainerRef,
        title: this.translate.instant('global.confirm'),
        cancelButton: this.translate.instant('global.cancel'),
        acceptButton: this.translate.instant('global.confirm'),
      })
      .afterClosed()
      .pipe(
        takeUntil(this._unsubscribe),
        filter(accept => accept === true)
      )
      .subscribe((accept: boolean) => {
        this.sendPasswordBySmsClicked.emit();
      });
  }

  ngOnDestroy() {
    this._unsubscribe.next();
    this._unsubscribe.complete();
  }
}
