import { Component, ElementRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DialogProviderService } from '@app/core/services/dialog-provider.service';
import { SessionService } from '@app/core/services/session.service';
import { error } from 'console';
import { MfaInstructionsDialog } from '../mfa-instructions-dialog/mfa-instructions-dialog.component';

@Component({
  selector: 'itfg-mfa-verification',
  templateUrl: './mfa-verification.component.html',
  styleUrl: './mfa-verification.component.scss'
})
export class MfaVerificationComponent {
  data = '';
  errored: boolean = false;
  mfaSecretShown: boolean = false;
  qrCode: string = '';
  secret: string = '';

  rememberMe: boolean = false;

  constructor(
    private session: SessionService,
    private router: Router,
    private dialogService: DialogProviderService,
  ) {
    const state = this.router.getCurrentNavigation()?.extras?.state;
    const hasQrCode: boolean = state?.hasOwnProperty('qrCode');
    if (hasQrCode) {
      const {secret,qrCode} = this.router.getCurrentNavigation().extras.state;
      this.qrCode = qrCode;
      this.secret = secret;
    }
  }

  submitTotpCode() {
    if (this.data.length !== 6) {
      return;
    }
    this.errored = false;
    this.session.verifyTotpToken(this.data, this.rememberMe).subscribe({
      next: () => {
        this.session.getCurrentlyLoggedOperator();
        this.session.administratorLoaded.subscribe(() => this.router.navigate(['/home']));
      },
      error: (err) => {
        this.errored = true;
      }
    })
  }

  openTwoFactorSetupInstructions() {
    this.dialogService.open(MfaInstructionsDialog, {
      maxWidth: '700px'
    });
  }

  cancelTwoFactorVerification() {
    this.session.logout();
  }
}
