<div flex class="itfg-note">
  <div flex layout="row" layout-align="center center">
    <mat-form-field class="form-field" subscriptSizing="dynamic">
      <mat-label>Бележка</mat-label>

      <textarea
        matInput
        #textarea
        cdkTextareaAutosize
        cdkAutosizeMinRows="3"
        cdkAutosizeMaxRows="4.5"
        [formControl]="formControl"
        [matAutocomplete]="auto"
      ></textarea>
      <!-- TODO: Add these to the text area once operators start working with topics -->
      <!-- (keydown.Tab)="addTail($event)"
                (keydown.ArrowRight)="navigateForwardWithArrowRight($event)"
                (keydown.Backspace)="removeTail($event)"
                (keydown.ArrowLeft)="navigateBackWithArrowLeft($event)" -->

      <mat-autocomplete
        #auto="matAutocomplete"
        autoActiveFirstOption
        hideSingleSelectionIndicator
        [displayWith]="displayFn"
        (optionActivated)="onOptionActivated($event)"
        (optionSelected)="onOptionSelected($event)"
      >
        <ng-container *ngIf="slashCase !== SlashCase.DOUBLE">
          <mat-option
            *ngFor="let option of filteredOptions$ | async"
            [value]="option"
            [ngClass]="{ 'activated-option': activatedOption === option }"
            class="full-width-option"
          >
            <div class="option-content">
              <mat-icon
                *ngIf="option.data.path.split('/').length > 1"
                class="icon-arrow-left"
                fontSet="material-symbols-outlined"
                (click)="
                  $event.preventDefault();
                  $event.stopPropagation();
                  navigateBackWithArrowLeft($event, option)
                "
                >arrow_left</mat-icon
              >
              <div class="content">
                <span>{{ option.data.name | itfgFormatTopicItem }}</span>
                <span class="option-meta">{{
                  option.data.path | itfgGetTopicRoot | itfgFormatTopicItem
                }}</span>
              </div>
              <div class="option-right">
                <span class="option-meta option-code">{{
                  option.data.code
                }}</span>
                <mat-icon
                  *ngIf="option.children.length > 0"
                  class="icon-arrow-right"
                  fontSet="material-symbols-outlined"
                  (click)="
                    $event.preventDefault();
                    $event.stopPropagation();
                    navigateForwardWithArrowRight($event, option)
                  "
                  >arrow_right</mat-icon
                >
              </div>
            </div>
          </mat-option>
        </ng-container>

        <ng-container *ngIf="slashCase === SlashCase.DOUBLE">
          <mat-option
            *ngFor="let option of filteredSpecialOptions$ | async"
            [value]="option"
            [disabled]="
              (!openedCredit && !selectedCredit) ||
              (!openedCreditInCollection && !selectedCreditInCollection)
            "
          >
            <mat-icon matListItemIcon fontSet="material-symbols-outlined">{{
              option.icon
            }}</mat-icon>

            <div
              matListItemTitle
              flex
              layout="row"
              layout-align="center center"
            >
              <span>{{ option.name }}</span>

              <mat-icon
                *ngIf="doubleSlashManualFlag"
                fontSet="material-symbols-outlined"
                >abc</mat-icon
              >
            </div>
          </mat-option>
        </ng-container>
      </mat-autocomplete>

      <mat-chip-listbox>
        <mat-chip-option
          *ngFor="let topic of selectedTopics"
          [selectable]="false"
          [removable]="true"
          (removed)="removeTopic(topic)"
        >
          {{ topic.name | itfgFormatTopicItem }}

          <button matChipRemove>
            <mat-icon>cancel</mat-icon>
          </button>
        </mat-chip-option>
      </mat-chip-listbox>
    </mat-form-field>

    <button
      mat-icon-button
      (click)="onSave()"
      class="save-button"
      [disabled]="
        (!openedCredit && !selectedCredit && !call && !client && !noteToEdit) ||
        (noteText?.length === 0 &&
          selectedTopics?.size === 0 &&
          mode === NoteMode.DEFAULT) ||
        isSavingNote
      "
      [class.disabled]="
        (!openedCredit && !selectedCredit && !call && !client && !noteToEdit) ||
        (noteText?.length === 0 &&
          selectedTopics?.size === 0 &&
          mode === NoteMode.DEFAULT) ||
        isSavingNote
      "
    >
      <mat-icon fontSet="material-symbols-outlined" class="fill">save</mat-icon>
    </button>
  </div>
</div>
