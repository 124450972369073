<div layout="row" flex layout-align="space-between center">
  
  <mat-form-field flex="30">
    <mat-label>{{ 'global.name' |translate}}</mat-label>
    <input matInput type="text" [(ngModel)]="data.name" readonly>
  </mat-form-field>
  
  <mat-form-field flex="15">
    <mat-label>{{ 'global.phone' |translate}}</mat-label>
    <input matInput type="text" [(ngModel)]="data.phone" readonly>
  </mat-form-field>
  
  <mat-form-field flex="15">
    <input matInput
           [(ngModel)]="data.time"
           readonly="true"
           [matDatepicker]="picker"
           placeholder="{{ 'clientRelations.company.extractionDate' |translate}}"
           (click)="picker.open()">
      <mat-label>{{ 'clientRelations.company.extractionDate' |translate}}</mat-label>
    <mat-datepicker-toggle matSuffix
                           [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker>
    </mat-datepicker>
  </mat-form-field>

  <mat-form-field flex="20">
    <mat-label>{{ 'clientRelations.company.source' |translate}}</mat-label>
    <input matInput type="text" [(ngModel)]="data.source" readonly>
  </mat-form-field>

  <button flex="5"
    mat-icon-button
    (click)="onAddToContacts()"
    [disabled] = disableAddToContacts
    matTooltip="{{'clientRelations.company.addToContacts' | translate}}"
    >
    <mat-icon>move_to_inbox</mat-icon>
  </button>
</div>
        
        <!-- matTooltip="{{ (client?.loyaltyEnabled ? 'clients.loyaltyEnabled' : 'clients.loyaltyDisabled') | translate  }}" -->