<div class="dialog-container">
  <div class="modal-dialog-title"
       itfgMatDialogRestorePosition
       cdkDrag
       cdkDragRootElement=".cdk-overlay-pane"
       cdkDragBoundary="body">
    <div flex
         layout-align="space-between center"
         layout="row" cdkDragHandle>
      <span [translate]="'credits.creditCalculator'">Credit calculator</span>
      <button tabindex="-1"
              mat-icon-button
              (click)="close()">
        <mat-icon>clear</mat-icon>
      </button>
    </div>
  </div>
  <div mat-dialog-content>
    <itfg-credit-calculator-form
                                 [creditCalculatorTemplateCode]="standaloneCalculatorTemplateCode"></itfg-credit-calculator-form>
  </div>
</div>
