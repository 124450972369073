import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { MenuItem } from '../../../../config/app-config';

@Component({
  selector: 'itfg-menu-list-item',
  templateUrl: './menu-list-item.component.html',
  styleUrls: ['./menu-list-item.component.scss'],
})
export class MenuListItemComponent implements OnInit {
  @Input() config: MenuItem;
  @Input() showLabel: boolean;
  @Input() collapsed;

  constructor() {}

  ngOnInit() {}
}
