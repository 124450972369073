import { Pipe, PipeTransform } from '@angular/core';
import { ClientRelationType } from '../../../core/types/client-relation';

@Pipe({
  name: 'relationTypesTranslation',
})
export class RelationTypesTranslationPipe implements PipeTransform {
  static readonly relationTypesTranslationMap: { [key: string]: string } = {
    [ClientRelationType.PHONE]: 'global.phoneNumber',
    [ClientRelationType.CIVIL_ID]: 'global.civilId',
    [ClientRelationType.EMAIL]: 'global.email',
    [ClientRelationType.ID_CARD_NUMBER]: 'clients.idCardNumber',
    [ClientRelationType.ADDRESS]: 'global.address',
    [ClientRelationType.EMPLOYER_ID]: 'clients.employerId',
    [ClientRelationType.IP]: 'clients.ipAddress',
    [ClientRelationType.BANK_ACCOUNT]: 'clients.bankAccount',
  };

  transform(value: ClientRelationType): string {
    return RelationTypesTranslationPipe.relationTypesTranslationMap[value];
  }
}
