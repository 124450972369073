// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  envName: 'staging',
  production: false,
  CREDITS_API_URL: 'https://credits.staging.aws.smilecredit.bg/',
  // CREDITS_API_URL: 'http://localhost:8080/',
  COLLECTION_API_URL: 'https://collection.staging.aws.smilecredit.bg/',
  // COLLECTION_API_URL: 'http://www.google.com:81/',
  IDENTITY_REPORTS_API_URL:
    'https://identity-reports.staging.aws.smilecredit.bg/',
  // IDENTITY_REPORTS_API_URL: 'http://localhost:8082/',
  PAYMENTS_API_URL: 'https://payments.staging.aws.smilecredit.bg/',
  SCORING_API_URL: 'https://scoring.staging.aws.smilecredit.bg/',
  // SCORING_API_URL: 'http://localhost:8082/',
  FACE_RECOGNITION_API_URL:
    'https://face-recognition.staging.aws.smilecredit.bg/',
  CLIENT_COMMUNICATION_API_URL:
    'https://client-communication.staging.aws.smilecredit.bg/',
  // CLIENT_COMMUNICATION_API_URL: 'http://localhost:8080/',
  P2P_API_URL: 'https://p2p.staging.aws.smilecredit.bg/',
};
