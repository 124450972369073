import { Component, OnInit, Inject, ViewContainerRef } from '@angular/core';
import { TagService } from '../../../../core/services/tag.service';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { mergeMap, filter } from 'rxjs/operators';
import { Tag, EditTagDto } from '../../../../core/types/tag';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NotificationService } from '../../../../core/services/notification.service';

@Component({
  selector: 'itfg-tag-edit',
  templateUrl: './tag-edit.component.html',
  styleUrls: ['./tag-edit.component.scss'],
})
export class TagEditComponent implements OnInit {
  public isNewItem: boolean;
  public tagForm: UntypedFormGroup = this.createTagForm();
  selectedColor = '#6c798f';
  toggle = false;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: any,
    public dialogRef: MatDialogRef<TagEditComponent>,
    public vcRef: ViewContainerRef,
    private readonly tagService: TagService,
    private readonly route: ActivatedRoute,
    private readonly formBuilder: UntypedFormBuilder,
    private readonly notifications: NotificationService
  ) {
    this.isNewItem = !data.tag;
    if (!this.isNewItem) {
      this.selectedColor = data.tag.color;
    }
    this.tagForm.setValue(this.parseTagToFormValue(data.tag));
  }

  ngOnInit() {
    // this.setInteractionMode();
  }

  setInteractionMode() {
    this.route.paramMap
      .pipe(
        filter((params: ParamMap) => typeof params.get('id') === 'string'),
        mergeMap((params: ParamMap) =>
          this.tagService.getTagById$(params.get('id'))
        ),
        filter(tag => tag !== null)
      )
      .subscribe((tag: EditTagDto) => {
        this.isNewItem = false;
        delete tag.id;
        this.tagForm.setValue(tag);
      });
  }

  createTagForm(): UntypedFormGroup {
    const fb = this.formBuilder;
    const group = fb.group({
      id: null,
      code: [null, Validators.required],
      name: ['', Validators.required],
      description: '',
      color: '',
    });
    return group;
  }

  onTagSave(event: any) {
    const formValue = this.tagForm.value;
    if (this.tagForm.valid) {
      this.dialogRef.close({
        ...formValue,
        color: this.selectedColor,
      });
    } else {
      this.notifications.showLocalizedErrorMessage({
        notificationText: 'forms.formSavedIsInvalid',
      });
    }
  }

  parseTagToFormValue(tag: Partial<Tag> = {}) {
    return {
      id: tag.id || null,
      name: tag.name || '',
      code: tag.code || '',
      description: tag.description || '',
      color: tag.color || '',
    };
  }
}
