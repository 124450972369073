import { Component, OnInit, Input } from '@angular/core';
import { Tag } from '../../../../../app/core/types/tag';

@Component({
  selector: 'itfg-tags-table-cell-view',
  templateUrl: './tags-table-cell-view.component.html',
  styleUrls: ['./tags-table-cell-view.component.scss'],
})
export class TagsTableCellViewComponent implements OnInit {
  @Input()
  tags: Tag[];

  constructor() {}

  ngOnInit() {}
}
