import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ItfgDataTableColumn } from '@app/core/components/data-table/types/data-table.column';
import { DialogProviderService } from '@app/core/services/dialog-provider.service';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { filter, finalize } from 'rxjs/operators';
import { CloseMessageAction } from '../../../../core/types/close-message-action';
import { NotificationService } from '../../../../core/services/notification.service';
import { SmsService } from '../../../../core/services/sms.service';
import { Client } from '../../../../core/types/client';
import { Credit } from '../../../../core/types/credit';
import { Page } from '../../../../core/types/page';
import { SearchOperations } from '../../../../core/types/search-criterium';
import { SearchOptions } from '../../../../core/types/search-options';
import { DeserializedSms } from '../../../../core/types/sms';
import { SmsTemplateCode } from '../../../../core/types/sms-template-code';
import { SendSmsDialogComponent } from './send-sms-dialog/send-sms-dialog.component';
import { ListBaseComponent } from '@app/main/list-base/list-base.component';
import { ColumnsService } from '@app/core/services/columns.service';
import { MatPaginator } from '@angular/material/paginator';
import { LoadingService } from '@app/core/services/loading.service';

@Component({
  selector: 'itfg-client-sms',
  templateUrl: './client-sms.component.html',
  styleUrls: ['./client-sms.component.scss']
})
export class ClientSmsComponent extends ListBaseComponent implements OnInit {

  @Input()
  credit: Credit;
  @Input()
  client: Client;
  @ViewChild('pagingBarResponsive', { static: true })
  public pagingBarResponsive: MatPaginator;

  // clientEmailConfirmed: Boolean;
  smsPage: Page<DeserializedSms> = {};
  smsColumns: ItfgDataTableColumn[] = [];
  searchOptions = new SearchOptions({ pageSize: 5 });

  constructor(
    private smsService: SmsService,
    private dialog: MatDialog,
    private loading: LoadingService,
    private translate: TranslateService,
    private dialogService: DialogProviderService,
    private notifications: NotificationService,
    public columnsService: ColumnsService,
    ) {
      super(columnsService);
    }


    ngOnChanges(changes): void {
      if(changes.client) {
        this.setClientSearchCriteria()
        this.updateSmsList()
      }

      if(changes.credit) {
        this.setCreditSearchCriteria()
        this.updateSmsList()
      }

    }
    
    ngOnInit(): void {
    
    this.smsService.onSmsSend.subscribe((sms) => {
      this.updateSmsList()
    });
    this.triggerDataChange$.subscribe((searchOptions: SearchOptions) => {
      this.updateSmsList();
      });

    this.translate
      .get([
        'templates.sendTime',
        'templates.templateName',
        'global.credit',
        'global.phoneNumber',
        'global.message',
        'global.actions',

      ])
      .subscribe(translation => {
        this.smsColumns = [
          {
            name: 'sent_at',
            label: translation['templates.sendTime'],
            width: 160,
          },
          {
            name: 'template.name',
            label: translation['templates.templateName'],
            width: 200,
          },
          {
            name: 'credit_id',
            label: translation['global.credit'],
            width: 120,
          },
          {
            name: 'recipient',
            label: translation['global.phoneNumber'],
            width: 200,
          },
          { name: 'body', label: translation['global.message'] },
          // {
          //   name: 'actions',
          //   label: translation['global.actions'],
          //   width: 150,
          //   numeric: true,
          // },
        ];
      });
    }

    sendNewSms(event: MouseEvent) {
      const dialogRef = this.dialog.open(SendSmsDialogComponent, {
        minWidth: '800px',
        panelClass: 'new-sms-dialog',
        data: {
          client: this.client || this.credit?.user,
        },
      });
  
      dialogRef
        .afterClosed()
        .pipe(filter((action: CloseMessageAction) => action?.reloadData))
        .subscribe(() => {
          this.updateSmsList();
        });
    }

  onSmsModeChange(event) {
    const DTO = this.searchOptions.getDTO()

    if(DTO.criteria.includes('credit_id')){
      this.setClientSearchCriteria()
    } else {
      this.setCreditSearchCriteria()
    }  
    this.updateSmsList()
  }


  // resendSms(smsId: number) {      // TODO add forward 
  //   const dialogConfig = {
  //     message: this.translate.instant('communication.resendSmsConfirmMessage'),
  //     disableClose: false,
  //     title: this.translate.instant('communication.resendSms'),
  //     cancelButton: this.translate.instant('global.cancel'),
  //     acceptButton: this.translate.instant('global.confirm'),
  //   };

  //   this.dialogService
  //     .openConfirm(dialogConfig)
  //     .afterClosed()
  //     .subscribe((accept: boolean) => {
  //       if (accept) {
  //         this.smsService.resendSms(smsId).subscribe(
  //           () => {
  //             this.notifications.showLocalizedSuccessMessage({
  //               notificationText: 'communication.successfullyResentSms',
  //             });
  //             this.updateSmsList();
  //           },
  //           (error: HttpErrorResponse) =>
  //             this.notifications.showLocalizedErrorMessage({
  //               notificationText: error.message
  //           })
  //         );
  //       }
  //     });
  // }

  getSmsList(): Observable<Page<DeserializedSms>> {
    return this.smsService.getSmsList({
      params: this.searchOptions.getDTO(),
    });
  }

  setCreditSearchCriteria() {
    this.searchOptions.criteria =  [{
      key: 'credit_id',
      operation: SearchOperations.EQUALS,
      value: this.credit.id,
    }]
  }

  setClientSearchCriteria() {
    this.searchOptions.criteria = [{
      key: 'user_id',
      operation: SearchOperations.EQUALS,
      value: this.client?.id || this.credit?.user?.id,
    }]
  }

  pageChanged(page) {
    super.pageChanged(page);
    this.updateSmsList();
  }

  updateSmsList() {
    this.loading.register('smsListLoading');
    this.smsService.getSmsList({ params: this.searchOptions.getDTO() })
      .pipe(
        finalize(() => {
          this.loading.resolve('smsListLoading');
        })
      )
      .subscribe((smsPage: Page<DeserializedSms>) => {
        this.smsPage = smsPage;
      });
  }

}
