import { FilterValueListService, FilterValue } from '../filter/types';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ApplicationStatusNames } from '../../core/types/application-type';

@Injectable()
export class ApplicationStatusValueListService
  implements FilterValueListService {
  constructor(private translate: TranslateService) {}

  getFilterValues$(): FilterValue[] {
    return Object.values(ApplicationStatusNames).map(value => ({
      value: value,
      displayText: this.translate.instant('applications.statusNames.' + value),
    }));
  }
}
