import { Component, OnInit, Inject } from '@angular/core';
import { SimpleSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import {MAT_SNACK_BAR_DATA} from '@angular/material/snack-bar';

@Component({
  selector: 'itfg-warning-notification-prompt',
  templateUrl: './warning-notification-prompt.component.html',
  styleUrls: ['./warning-notification-prompt.component.scss'],
})
export class WarningNotificationPrompt implements OnInit {
  constructor(private ref: MatSnackBarRef<WarningNotificationPrompt>, @Inject(MAT_SNACK_BAR_DATA) public data: {info: string}) {}

  ngOnInit() {}

  dismiss() {
    this.ref.dismissWithAction();
  }
}
