
<form class="login-form" novalidate [formGroup]="loginForm" (ngSubmit)="onSubmit($event);">
  <div layout="column">
    <mat-form-field>
      <input type="email" required name="email" matInput formControlName="username" placeholder="{{ 'global.email' | translate }}">
      <mat-label>{{ 'global.email' | translate }}</mat-label>
    </mat-form-field>
    <mat-form-field>
      <input type="password" required name="password" matInput formControlName="password" placeholder="{{ 'global.password' | translate }}">
      <mat-label>{{ 'global.password' | translate }}</mat-label>
    </mat-form-field>
  </div>
  <button type="submit" mat-raised-button >{{ 'global.login' | translate }}</button>
</form>
<itfg-message [opened]="loginErrored" label="{{ 'global.error' | translate }}" sublabel="{{ 'auth.invalidCredentials' | translate }}" type="error"></itfg-message>