import { Injectable } from '@angular/core';
import { RequestService } from './request.service';
import { Page } from '../types/page';
import { Tag, EditTagDto } from '../types/tag';
import { Observable, Subject } from 'rxjs';
import { SearchOptionsDTO } from '../types/search-options';

@Injectable()
export class TagService {
  onTagAddedOrDeleted: Subject<Tag[]> = new Subject<Tag[]>();
  constructor(private request: RequestService) { }

  getTagList$(options?: SearchOptionsDTO): Observable<Page<Tag>> {
    return this.request.get(['tags'], { params: options });
  }

  getTagById$(id: string | number): Observable<Tag> {
    return this.request.get(['tags', id]);
  }

  getTagsContainingIgnoreCase$(value: string) {
    return this.request.get(
      ['tags', 'findByNameContainingIgnoreCase'],
      { params: { name: value } }
    );
  }

  getTagByCode(value: string) {
    return this.request.get(['tags', 'findByCode'],
      { params: { code: value } }
    )
  }

  createTag$(createTagDto: Tag): Observable<Tag> {
    return this.request.post(['tags'], { body: createTagDto });
  }

  editTag$(editTagDto: EditTagDto): Observable<Tag> {
    return this.request.post(['tags'], { body: editTagDto });
  }

  deleteTagById$(id: number) {
    return this.request.delete(['tags', id]);
  }

  tagUsers(clientIds: number[], tagId: number): Observable<any> {
    return this.request.post(['tags', tagId, 'users'], { body: clientIds });
  }

  untagUsers(clientIds: number[], tagId: number): Observable<Page<Tag>> {
    return this.request.delete(['tags', tagId, 'users'], { body: clientIds });
  }

  tagCredits(clientIds: number[], tagId: number): Observable<any> {
    return this.request.post(['tags', tagId, 'credits'], { body: clientIds });
  }

  untagCredits(clientIds: number[], tagId: number): Observable<Page<Tag>> {
    return this.request.delete(['tags', tagId, 'credits'], { body: clientIds });
  }

}


