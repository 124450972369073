<form novalidate [itfgPermissions]="['USER_WRITE']" [formGroup]="occupationStatusForm">
  <mat-card appearance="outlined">
    <mat-card-title>
      <span class="edit-screen-card-title" [translate]="'clients.occupationStatus'">Occupation status</span>
    </mat-card-title>
    <mat-card-content>
      <div layout="row" layout-wrap layout-align="start end">
        <mat-card appearance="outlined">
          <mat-card-content flex="100" layout="row" layout-wrap layout-align="space-around center">
            <div flex="100" layout="row" layout-wrap layout-align="space-around center">
              <mat-form-field flex>
                <mat-select matInput formControlName="employmentType"
                            placeholder="{{ 'clients.employmentType' | translate }}">
                  <mat-option [disabled]="(['USER_WRITE'] | isUnauthorized | async)"
                              *ngFor="let employment of employmentType" [value]="employment.id">{{ employment.type }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field flex>
                <input matInput type="text" formControlName="workPosition"
                       placeholder="{{ 'clients.workPosition' | translate }}"/>
      <mat-label>{{ 'clients.workPosition' | translate }}</mat-label>
                <mat-error *ngIf="occupationStatusForm.get('workPosition').hasError('maxlength')"
                           [translate]="'forms.maxLength'" [translateParams]="{ value: WORK_POSITION_MAX_LENGTH }">No
                  letter
                </mat-error>
              </mat-form-field>
            </div>
            <div flex="100" layout="row" layout-wrap layout-align="space-around center">
              <div flex="50" layout="column">
                <div class="label-style">{{ 'clients.workExperience' | translate }}</div>
                <div flex="100" layout="row" layout-align="space-around center">
                  <mat-form-field flex>
                    <input matInput type="number" min="0" formControlName="workExperienceYears"
                           placeholder="{{ 'global.years' | translate }}"
                    />
      <mat-label>{{ 'global.years' | translate }}</mat-label>
                    <mat-error *ngIf="occupationStatusForm.get('workExperienceYears').hasError('max')"
                               [translate]="'forms.maxValue'" [translateParams]="{ value: WORK_EXPERIENCE_MAX_VALUE }">
                      No
                      letter
                    </mat-error>
                  </mat-form-field>
                  <mat-form-field flex>
                    <input matInput type="number" min="0" formControlName="workExperienceMonth"
                           placeholder="{{ 'global.months' | translate }}"
                    />
      <mat-label>{{ 'global.months' | translate }}</mat-label>
                    <mat-error *ngIf="occupationStatusForm.get('workExperienceMonth').hasError('max')"
                               [translate]="'forms.maxValue'" [translateParams]="{ value: WORK_EXPERIENCE_MAX_VALUE }">
                      No
                      letter
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
              <div flex="50" layout="column">
                <div class="label-style">{{ 'clients.workExperienceTotal' | translate }}</div>
                <div flex="100" layout="row" layout-align="space-around center">
                  <mat-form-field flex>
                    <input matInput type="number" min="0" formControlName="workExperienceTotalYears"
                           placeholder="{{ 'global.years' | translate }}"
                    />
      <mat-label>{{ 'global.years' | translate }}</mat-label>
                    <mat-error *ngIf="occupationStatusForm.get('workExperienceYears').hasError('max')"
                               [translate]="'forms.maxValue'" [translateParams]="{ value: WORK_EXPERIENCE_MAX_VALUE }">
                      No
                      letter
                    </mat-error>
                  </mat-form-field>
                  <mat-form-field flex>
                    <input matInput type="number" min="0" formControlName="workExperienceTotalMonth"
                           placeholder="{{ 'global.months' | translate }}"
                    />
      <mat-label>{{ 'global.months' | translate }}</mat-label>
                    <mat-error *ngIf="occupationStatusForm.get('workExperienceMonth').hasError('max')"
                               [translate]="'forms.maxValue'" [translateParams]="{ value: WORK_EXPERIENCE_MAX_VALUE }">
                      No
                      letter
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div flex="100" layout="row" layout-wrap layout-align="space-around center">
              <mat-form-field flex>
                <input matInput type="text" formControlName="employerName"
                       placeholder="{{ 'clients.employerName' | translate }}"/>
      <mat-label>{{ 'clients.employerName' | translate }}</mat-label>
                <mat-error *ngIf="occupationStatusForm.get('employerName').hasError('maxlength')"
                           [translate]="'forms.maxLength'" [translateParams]="{ value: EMPLOYER_NAME_MAX_LENGTH }">No
                  letter
                </mat-error>
              </mat-form-field>
              <mat-form-field flex>
                <input matInput itfgTrimInput type="text" class="employer-id-input-styles" formControlName="employerId"
                       placeholder="{{ 'clients.employerId' | translate }}"
                />
      <mat-label>{{ 'clients.employerId' | translate }}</mat-label>
                <mat-error *ngIf="occupationStatusForm.get('employerId').hasError('maxlength')"
                           [translate]="'forms.maxLength'" [translateParams]="{ value: EMPLOYER_ID_MAX_LENGTH }">No
                  letter
                </mat-error>
                <mat-error *ngIf="occupationStatusForm.get('employerId').hasError('incorrectEik')"
                           [translate]="'clients.invalidemployerNumber'">Invalid employer number
                </mat-error>
                <button *ngIf="!employerIdIsInBlacklist" type="button" matSuffix mat-icon-button
                        [disabled]="isNewItem || (['BLACKLIST_WRITE'] | isUnauthorized | async)"
                        class="input-suffix-button add-toblacklist-flag-color"
                        (click)="addToBlacklist()">
                  <mat-icon matTooltip="{{ 'clients.addEmployerToBlacklist' | translate }}"
                            [matTooltipDisabled]="isNewItem" matTooltipPosition="above">not_interested
                  </mat-icon>
                </button>
                <button *ngIf="employerIdIsInBlacklist" type="button" matSuffix mat-icon-button
                        [disabled]="isNewItem || (['BLACKLIST_READ'] | isUnauthorized | async)"
                        class="input-suffix-button blacklisted-flag-color"
                        (click)="editInBlacklist()">
                  <mat-icon matTooltip="{{ 'clients.editEmployerInBlacklist' | translate }}"
                            [matTooltipDisabled]="isNewItem" matTooltipPosition="above">not_interested
                  </mat-icon>
                </button>
              </mat-form-field>
              <mat-form-field flex>
                <mat-select matInput formControlName="education" placeholder="{{ 'clients.education' | translate }}">
                  <mat-option [disabled]="(['USER_WRITE'] | isUnauthorized | async)" *ngFor="let type of education"
                              [value]="type.id">{{ type.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>


            </div>
            <div flex="100" layout="row" layout-wrap layout-align="start center">
              <mat-form-field flex flex-gt-xs="33">
                <input matInput type="text" formControlName="workCity" [matAutocomplete]="city"
                       itfgSelectFirstAutocompleteOptionOnBlur placeholder="{{ 'clients.workCity' | translate }}"
                />
      <mat-label>{{ 'clients.workCity' | translate }}</mat-label>
                <mat-error *ngIf="occupationStatusForm.get('workCity').hasError('lacksLatin')"
                           [translate]="'forms.fieldShouldNotHaveLatinCharacters'"></mat-error>
                <mat-error *ngIf="occupationStatusForm.get('workCity').hasError('notValidCity')"
                           [translate]="'forms.notValidCity'"></mat-error>
                <mat-autocomplete #city="matAutocomplete" itfgHighlightFirstAutocompleteOptionOnChange
                                  [displayWith]="mapCityToDisplayValue">
                  <mat-option *ngFor="let city of cityList" [value]="city">
                    {{ city | cityDisplayText }}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
              <mat-form-field flex>
                <input matInput type="text" formControlName="workAddress"
                       placeholder="{{ 'clients.workAddress' | translate }}"/>
      <mat-label>{{ 'clients.workAddress' | translate }}</mat-label>
                <mat-hint [ngStyle]="{color: 'red' }" *ngIf="occupationStatusForm.hasError('cityRequiredForAddress')"
                          [translate]="'forms.addressRequiresCity'">Address requires city
                </mat-hint>
              </mat-form-field>
            </div>
          </mat-card-content>
        </mat-card>
        <mat-card appearance="outlined">
          <mat-card-content>
            <div layout="column">
              <mat-form-field flex="100">
                <mat-select matInput formControlName="bankTransferSalary"
                            placeholder="{{ 'clients.bankTransferSalary' | translate }}">
                  <mat-option [disabled]="(['USER_WRITE'] | isUnauthorized | async)" *ngFor="let payment of paymentType"
                              [value]="payment.id">{{ payment.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <div flex="100" layout="row" layout-wrap layout-align="space-between center">
                <mat-form-field flex>
                  <input matInput type="number" min="0" step="10" formControlName="salary"
                         placeholder="{{ 'clients.salary' | translate }}"
                  />
      <mat-label>{{ 'clients.salary' | translate }}</mat-label>
                  <mat-error *ngIf="occupationStatusForm.get('salary').hasError('max')" [translate]="'forms.maxValue'"
                             [translateParams]="{ value: SALARY_MAX_VALUE }">No letter
                  </mat-error>
                </mat-form-field>
                <mat-form-field flex>
                  <input matInput type="number" min="0" step="10" formControlName="extraIncome"
                         placeholder="{{ 'clients.extraIncome' | translate }}"
                  />
      <mat-label>{{ 'clients.extraIncome' | translate }}</mat-label>
                  <mat-error *ngIf="occupationStatusForm.get('extraIncome').hasError('max')"
                             [translate]="'forms.maxValue'" [translateParams]="{ value: EXTRA_INCOME_MAX_VALUE }">No
                    letter
                  </mat-error>
                </mat-form-field>
              </div>
              <mat-form-field flex="100">
                <textarea matInput cdkTextareaAutosize cdkAutosizeMaxRows="8" cdkAutosizeMinRows="3"
                          formControlName="extraIncomeNotes"
                          placeholder="{{ 'clients.extraIncomeNotes' | translate }}"></textarea>
              </mat-form-field>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </mat-card-content>
  </mat-card>
</form>
