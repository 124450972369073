import {
  Component,
  OnInit,
  Input,
  OnChanges,
  ChangeDetectionStrategy,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  StyleConfigMap,
  CREDIT_STATUS_STYLE_MAP,
} from '../../../config/credit-config';
import { CreditStatus } from '../../../core/types/credit-status';

@Component({
  selector: 'itfg-credit-status-icon',
  templateUrl: './credit-status-icon.component.html',
  styleUrls: ['./credit-status-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreditStatusIconComponent implements OnInit, OnChanges {
  @Input() creditStatus: CreditStatus;
  @Input() showName = false;
  @Input() activeAssignment = null;
  public activeAssignmentDisplayText = '';
  public creditStatusStyleMap: StyleConfigMap;
  public color: string;
  public icon: string;

  constructor(
    private translate: TranslateService,
  ) {
    this.creditStatusStyleMap = CREDIT_STATUS_STYLE_MAP;
  }

  ngOnInit() {}

  ngOnChanges() {
    this.icon = this.creditStatusStyleMap[this.creditStatus.name].icon;
    this.color = this.creditStatusStyleMap[
      this.creditStatus.name
    ].backgroundColor;
    if (this.activeAssignment) {
      this.showName = false;
      let translationKey = 'collection.sentStatusTextTemplate';
      if (this.activeAssignment.assignTime) {
        translationKey = 'collection.assignedStatusTextTemplate';
      }
      this.activeAssignmentDisplayText = this.translate.instant(
        translationKey,
        {
          instanceName: this.activeAssignment.instanceName || this.translate.instant(
            'collection.agentType.' + this.activeAssignment.operator.category
          ),
        }
      );
      // this.activeAssignmentDisplayText = this.activeAssignment.instanceName || this.activeAssignment.operator.name;
      // this.statusHoverText =
      //   `${this.translatedStatus}` +
      //   (this.status.reason ? ` - ${this.translatedReason}` : '');
    }

  }
}
