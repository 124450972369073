import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { SessionService } from '../../core/services/session.service';
import { PreserveRouteService } from '../../core/services/preserveRoute.service';
import { UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'itfg-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss'],
})
export class LoginFormComponent implements OnInit, OnDestroy {
  loginErrored: boolean;

  loginForm: UntypedFormGroup;
  session: SessionService;
  preserveService: PreserveRouteService;
  router: Router;
  _unsubscribe: Subject<void> = new Subject<void>();

  constructor(
    session: SessionService,
    router: Router,
    preserveService: PreserveRouteService
  ) {
    this.loginErrored = false;
    this.session = session;
    this.router = router;
    this.preserveService = preserveService;

    this.loginForm = new UntypedFormGroup({
      username: new UntypedFormControl(),
      password: new UntypedFormControl(),
    });
  }

  ngOnInit() {
    this.session.administratorLoaded
    .pipe(takeUntil(this._unsubscribe))
    .subscribe(() => {
      const lastVisitedRoute = this.session.getLastVisitedRoute();
      if (lastVisitedRoute) {
        // this.preserveService.lastVisitedRoute = '';
        this.router.navigateByUrl(lastVisitedRoute); 
        return;
      }
      this.session.setLastVisitedRoute('/');
      this.router.navigate(['/home']);
    })
  }

  onSubmit(event): void {
    if (this.loginForm.valid) {
      this.loginErrored = false;

      const login$ = this.session.login(this.loginForm.value);

      login$.subscribe({
        next: (response: object | boolean) => {
          if (typeof response === 'object' && !Array.isArray(response) && response !== null) {
            this.router.navigate(['auth', '2fa'], {state: { ...response } });
          } else if (typeof response === 'boolean') {
            if (response) {
              this.session.getCurrentlyLoggedOperator();
            } else {
              this.loginErrored = true;
            }
          }
        },
        error: (error: Error) => {
          if (error.message === 'WWW-AUTHENTICATE-TOTP') {
            this.router.navigate(['auth', '2fa']);
          }
        }
      });
    }
  }

  ngOnDestroy() {
    this._unsubscribe.next();
    this._unsubscribe.complete();
  }
}
