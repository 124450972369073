import { Injectable } from '@angular/core';
export enum LoadingType {
  Circular = "circular",
  Linear = "linear"
}
export enum LoadingMode {
  Determinate = "determinate",
  Indeterminate = "indeterminate"
}
export enum LoadingStrategy {
  Overlay = "overlay",
  Replace = "replace"
}
export enum LoadingStyle {
  FullScreen = "fullscreen",
  Overlay = "overlay",
  None = "none"
}
export interface ItfgLoadingConfig {
  name: string;
  type?: LoadingType;
  mode?: LoadingMode;
  color?: 'primary' | 'accent' | 'warn';
}

@Injectable()
export class LoadingService {

  create(config: ItfgLoadingConfig): void {
  }

  register(arg1: string): boolean {
    return true;
  }

  resolve(arg1: string): boolean {
    return true;
  }
}
