import { Component, Input, OnChanges, OnDestroy } from '@angular/core';
import { IdentityReportsService } from '@app/core/services/identity-reports.service';
import { Client } from '@app/core/types/client';
import { IdCardImageTypes, IdentityReport, IdentityVerificationReportData } from '@app/core/types/identity-reports';
import { forkJoin, map, Observable, Subject, takeUntil, tap } from 'rxjs';
import { saveAs } from 'file-saver';
import { IdentityDocument } from '@app/core/types/client-document';
import { FormControl, FormGroup } from '@angular/forms';
import { BrandCodes } from '@app/core/types/brand';

@Component({
  selector: 'itfg-identity-verification',
  templateUrl: './identity-verification.component.html',
  styleUrl: './identity-verification.component.scss'
})
export class IdentityVerificationComponent implements OnChanges, OnDestroy {
  @Input() client: Client;
  report: IdentityReport<IdentityVerificationReportData>;
  reportListData = [];
  requestTime = new Date();
  _unsubscribe = new Subject<void>();
  hoveredDocument = {
    id: null,
    url: null,
  };
  documentIdToBlob: { [key: string]: Blob } = {}
  documentsForm: FormGroup;
  documentsControl = new FormControl();

  constructor(
    private identityReportsService: IdentityReportsService,
  ) {
    this.documentsForm = new FormGroup({
      documents: this.documentsControl,
    });
    this.listenForDocumentsListSelectedValueChanges();
  }

  ngOnChanges() {
    if (this.client.id) {
      this.fetchIdentityVerificationList(this.client.civilId, <BrandCodes>this.client.brand.code);
    }
  }

  listenForDocumentsListSelectedValueChanges() {
    this.documentsControl.valueChanges.pipe(
      map((valueArr: Array<IdentityDocument>) => {
        return valueArr ? valueArr[0] : null
      }),
    ).subscribe((document: IdentityDocument) => {
      this.resetSelectedDocumentContent();
      this.onSelectedDocumentChange(document);
    });
  }

  fetchIdentityVerificationList(civilId: string, brandCode: BrandCodes) {
    forkJoin({
      reportsList: this.identityReportsService.getIdentityVerificationReportList(civilId, brandCode),
      latestReport: this.identityReportsService.getLatestIdentityVerificationReport$(civilId, brandCode)
    }).subscribe(({ reportsList, latestReport }) => {
      this.reportListData = reportsList;
      this.setCurrentReport(latestReport);
      if (this.report?.time) {
        this.requestTime = new Date(this.report.time);
      }
    });
  }

  setCurrentReport(report: IdentityReport<IdentityVerificationReportData>) {
    this.report = report;
    if (this.report?.documents.length > 0) {
      const foundSelfie = this.report.documents.find((doc) => doc.type.name === IdCardImageTypes.SELFIE);
      if (foundSelfie) {
        this.documentsControl.patchValue([foundSelfie]);
      }
    }
  }

  dataChanged(event: any) {
    const reportId = event.value.id;
    this.identityReportsService.getIdentityVerificationReportById$(this.client.civilId, <BrandCodes>this.client.brand.code, reportId).subscribe((res) => {
      this.setCurrentReport(res);
      this.requestTime = new Date(this.report.time);
    });
  }

  downloadSelectedDocument() {
    let document: IdentityDocument = null;

    if (this.documentsControl.value) {
      document = this.documentsControl.value[0];
    }

    if (!document) {
      return;
    }

    if (this.documentIdToBlob[document.id]) {
      saveAs(this.documentIdToBlob[document.id], document.name);
      return;
    }
    this.loadImageContent$(document).subscribe((blob: Blob) => {
      saveAs(blob, document.name);
    })
  }

  loadImageContent$(document: IdentityDocument): Observable<Blob> {
    return this.identityReportsService
      .renderIdentityDocument(this.client.civilId, document.id)
      .pipe(
        map((doc) => {
          const blob = new Blob(<any>[doc]);
          this.documentIdToBlob[document.id] = blob;
          return blob;
        }),
        takeUntil(this._unsubscribe),
      );
  }
  
  onSelectedDocumentChange(document: IdentityDocument): void {
    if (this.hoveredDocument?.id) {
      URL.revokeObjectURL(this.hoveredDocument.url);
      this.hoveredDocument.id = null,
      this.hoveredDocument.url = null;
    }

    if (this.documentIdToBlob[document.id]) {
      this.hoveredDocument.id = document.id,
      this.hoveredDocument.url = URL.createObjectURL(this.documentIdToBlob[document.id]);
    } else {
      this.loadImageContent$(document).subscribe((blob: Blob) => {
        this.hoveredDocument.id = document.id,
        this.hoveredDocument.url = URL.createObjectURL(blob);
      });
    }
  }

  resetSelectedDocumentContent(): void {
    if (this.hoveredDocument?.id) {
      URL.revokeObjectURL(this.hoveredDocument.url);
      this.hoveredDocument.id = null,
      this.hoveredDocument.url = null;
    }
  }

  ngOnDestroy() {
    this._unsubscribe.next();
    this._unsubscribe.complete();
    this.documentIdToBlob = {};
  }

}
