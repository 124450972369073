import { Injectable } from '@angular/core';
import { RequestService } from './request.service';
import { Observable } from 'rxjs';
import { Ccr } from '../types/ccr';

@Injectable()
export class CcrService {
  constructor(private request: RequestService) {}

  getCcrList(civilId): Observable<Ccr[]> {
    return this.request.get(['institution-docs', 'customers', civilId, 'ccr']);
  }

  updateCcrList(civilId): Observable<Ccr> {
    return this.request.post(['institution-docs', 'customers', civilId, 'ccr']);
  }

  getOneCcr(civilId, ccrId): Observable<Ccr> {
    return this.request.post(['institution-docs', 'customers', civilId, 'ccr']);
  }
}
