import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash-es';

@Pipe({
  name: 'filterOptionsTranslation'
})
export class TranslateFilterOptionPipe implements PipeTransform {
  constructor(private translate: TranslateService) {}

  transform(option: any, translationPath?: string): any {
    if (translationPath) {
      const translationKey = `${translationPath}.${_.camelCase(option)}`;
      return this.translate.instant(translationKey);
    }
    return option;
  }
}
