import {
  Directive,
  Input,
  OnDestroy,
  OnInit,
  HostBinding,
} from '@angular/core';
import { FilterCardComponent } from '../filter-card/filter-card.component';
import { SearchCriterium } from '../../../core/types/search-criterium';
import { Subscription } from 'rxjs';

@Directive({
  selector: '[itfgFilterButton]',
})
export class FilterButtonDirective implements OnInit, OnDestroy {
  @Input() forCard: FilterCardComponent;
  @HostBinding('class.mat-accent') colored: boolean;

  private subscriptions: Subscription[];

  constructor() {
    this.subscriptions = [];
  }

  ngOnInit() {
    this.subscriptions.push(
      this.forCard.filtersApplied.subscribe(
        this.applyColoringIfFiltersPresent.bind(this)
      )
    );

    this.subscriptions.push(
      this.forCard.lastFilterRemoved.subscribe(this.removeColoring.bind(this))
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  applyColoringIfFiltersPresent(filterList: SearchCriterium[]) {
    this.colored = filterList.length > 0;
  }

  removeColoring() {
    this.colored = false;
  }
}
