<div flex
     layout="row"
     layout-align="center center" (click)="changeStatusDialog()">
    <button mat-icon-button
            [ngStyle]="styleOptions"
            matTooltip="{{ ('global.' + this.idCardStatus.name.toLowerCase() | translate).toUpperCase() }}"
            matTooltipPosition="above">
      <mat-icon>{{icon}}</mat-icon>
      </button>
</div>
