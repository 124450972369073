import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { EventLogAuditEntity } from '@app/core/types/event-log.type';
import { Utils } from '@app/core/utils/utils';

@Component({
  selector: 'itfg-event-log-add-entity-display',
  templateUrl: './event-log-add-entity-display.component.html',
  styleUrls: ['./event-log-add-entity-display.component.scss'],
})
export class EventLogAddEntityDisplayComponent implements OnInit, OnChanges {
  @Input() fields: [
    {
      name: 'string';
      label: 'string';
    }
  ];
  @Input() data: EventLogAuditEntity;

  deepValue: (obj: any, path: string) => any;

  propListLength: number;

  constructor() {
    this.deepValue = Utils.deepValue;
  }

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.data && this.data) {
      const filteredValues = {};
      for (const prop in this.data) {
        if (
          this.data.hasOwnProperty(prop) &&
          this.data[prop] !== null &&
          this.data[prop] !== undefined
        ) {
          filteredValues[prop] = this.data[prop];
        }
      }
      this.data = filteredValues;
      this.propListLength = Math.max(
        this.fields.length,
        Object.keys(this.data).length
      );
    }
  }
}
