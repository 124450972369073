<div layout="column" >
  <div layout="row"
       layout-align="space-between center"
       flex>
  <span [translate]="'ccr.getNewCcrReport'"
        mat-dialog-title>Get new ccr report</span>
    <button tabindex="-1"
            mat-icon-button
            class="close-button"
            [mat-dialog-close]="false">
      <mat-icon>clear</mat-icon>
    </button>
  </div>
  <div>
    <form [formGroup]="sourceTypeForm"
          (ngSubmit)="onSubmit()">
      <div layout="column" layout-wrap>
        <mat-form-field>
          <mat-label>
            <span [translate]="'ccr.chooseReportType'">Choose report</span>
          </mat-label>
          <mat-select formControlName="type"
                      disableOptionCentering="true"
                      (selectionChange)="changeFormValuesByConfiguration('type', $event?.value)"
                      >
            <mat-select-trigger>
            <span layout="row"
                  layout-align="start end">
              <span>{{ sourceTypeForm.get('type').value && "reports.types." + sourceTypeForm.get('type').value | translate }}</span>
            </span>
            </mat-select-trigger>

            <mat-option *ngFor="let reportType of data.ccrReportTypes | keyvalue"
                        [value]="reportType.value">
              {{ "reports.types." + reportType?.value | translate }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <!-- workaround for making mat-select readonly; Remove after implementing ccr_requests BNB -->
        <mat-form-field *ngIf="sourceTypeForm.get('type').value === CcrReportTypes?.CCR_REQUESTS">
          <mat-label>
            <span [translate]="'ccr.chooseSourceType'">Choose source</span>
          </mat-label>
          <input
            matInput
            type="text"
            [readonly]="true"
            value="{{'reports.sources.DG' | translate}}"
          />
        </mat-form-field>
        <mat-form-field *ngIf="sourceTypeForm.get('type').value !== CcrReportTypes?.CCR_REQUESTS">
          <mat-label>
            <span [translate]="'ccr.chooseSourceType'">Choose source</span>
          </mat-label>
          <mat-select formControlName="source" 
                      (selectionChange)="changeFormValuesByConfiguration('source', $event?.value)"
                      disableOptionCentering="true"
                      >
            <mat-select-trigger>
            <span layout="row"
                  layout-align="start end">
              <span>{{ sourceTypeForm.get('source').value && "reports.sources." + sourceTypeForm.get('source').value | translate }}</span>
            </span>
            </mat-select-trigger>

            <mat-option *ngFor="let sourceType of data.ccrSourceTypes | keyvalue"
                        [value]="sourceType.value">
              {{ "reports.sources." + sourceType?.key | translate }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field>
                    <mat-label>
                      <span [translate]="'ccr.chooseCacheDays'">Choose cache days</span>
                    </mat-label>
                    <input
                      matInput
                      formControlName="ttl"
                      type="number"
                      [readonly]="
                        sourceTypeForm.get('source').value === CcrSourceTypes?.BNB ||
                        sourceTypeForm.get('type').value === CcrReportTypes?.CCR_REQUESTS
                        "
                      placeholder="{{ 'global.days' | translate }}"
                    />
        <mat-error *ngIf="sourceTypeForm.get('ttl').hasError('min')"
                   [translate]="'forms.minValue'"
                   [translateParams]="{value: MIN_CACHE_VALUE}">This field is required</mat-error>
          <mat-error *ngIf="sourceTypeForm.get('ttl').hasError('max')"
                     [translate]="'forms.maxValue'"
                     [translateParams]="{value: MAX_CACHE_VALUE}">This field is required</mat-error>
        </mat-form-field>
        <div layout="row"
             class="margin-top-24px"
             layout-wrap layout-align="end center">
          <button type="submit"
                  [disabled]="!sourceTypeForm.valid"
                  mat-raised-button>
            {{ 'global.download' | translate }}
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
