<div
  class="edit-screen-title"
  layout="row"
  layout-align="start"
  flex
>
  <itfg-brand-logo [brand]="client?.brand"></itfg-brand-logo>
  <span *ngIf="!activeCredit">{{ 'credits.newCredit' | translate }} /
    {{ (client | clientFullName) || '' }}
  </span>

  <span *ngIf="!!activeCredit">
    {{
      'credits.creditTitleTemplate'
        | translate
          : {
              creditId: activeCredit?.id || '',
              clientFullName: (activeCredit?.user | clientFullName) || ''
            }
    }}
  </span>

</div>
<div layout="column">
    <mat-divider style="margin: 10px"></mat-divider>
  <div layout="column">
    <mat-card appearance="outlined" flex>
      <mat-card-title>
        <span [translate]="'clients.clientDetails'"
          >Profile data</span
        >
      </mat-card-title>

      <mat-card-content>
        <div layout="column">
          <div layout="row" layout-wrap>
            <div layout="column" class="flex-padding" flex>
              <mat-form-field>
                <input matInput type="number" [value]="client?.id" placeholder="{{ 'global.numberSign' | translate }}" readonly>
      <mat-label>{{ 'global.numberSign' | translate }}</mat-label>
              </mat-form-field>
              <mat-form-field flex>
                <input matInput type="text" [value]="campaignTag?.name" placeholder="{{ 'global.discountCode' | translate }}" readonly>
      <mat-label>{{ 'global.discountCode' | translate }}</mat-label>
                <!-- <mat-label><strong> <span [ngStyle]="{ 'color': campaignTag?.color }">{{ 'global.discountCode' | translate }}</span></strong></mat-label> -->
              </mat-form-field>
            </div>
            <div layout="column" class="flex-padding" flex>
              <mat-form-field flex>
                <input matInput type="text" [value]="client?.fullName" placeholder="{{ 'global.name' | translate }}" readonly>
      <mat-label>{{ 'global.name' | translate }}</mat-label>
              </mat-form-field>
              <mat-form-field flex>
                <input matInput type="text" [value]="client?.civilId" placeholder="{{ 'global.civilId' | translate }}" readonly>
      <mat-label>{{ 'global.civilId' | translate }}</mat-label>
              </mat-form-field>
            </div>
            <div layout="column" class="flex-padding" flex>
              <mat-form-field flex>
                <input matInput type="text" [value]="client?.email" placeholder="{{ 'global.email' | translate }}" readonly>
      <mat-label>{{ 'global.email' | translate }}</mat-label>
              </mat-form-field>
              <mat-form-field flex>
                <input matInput type="text" [value]="client?.mobile" placeholder="{{ 'clients.mobilePhone' | translate }}" readonly>
      <mat-label>{{ 'clients.mobilePhone' | translate }}</mat-label>
              </mat-form-field>
            </div>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
    <mat-card appearance="outlined" flex>
      <mat-card-title>
        Добави контакт
      </mat-card-title>
      <mat-card-content layout="column">
        <div layout="row"
              layout-wrap
              layout-align="space-between center"
              *ngFor="let contact of addedContacts"
              >
          <mat-form-field flex class="edit-contact-aditional-padding" >
            <input
              readonly
              [value]="contact.phone"
              matInput
              type="text"
              placeholder="{{ 'global.phone' | translate }}"
            />
      <mat-label>{{ 'global.phone' | translate }}</mat-label>
          </mat-form-field>
          <mat-form-field flex class="edit-contact-aditional-padding">
            <input
              readonly
              matInput
              [value]="contact.description"
              type="text"
              placeholder="{{ 'clients.description' | translate }}"
            />
      <mat-label>{{ 'clients.description' | translate }}</mat-label>
          </mat-form-field>
          <div
            flex
            layout="row"
            layout-align="space-between center"
            class="edit-contact-aditional-padding"
          >
            <mat-checkbox
              [disabled]="true"
              [checked]="contact.contacted"
            >
              <div [translate]="'clients.contacted'">Confirmed contact</div>
            </mat-checkbox>
          </div>
        </div>
        <form #contactsFormRef="ngForm"
              layout="row"
              layout-wrap
              layout-align="space-between center"
              [formGroup]="contactsForm"
              (ngSubmit)="onSubmitContact($event);"
              >
          <mat-form-field flex class="edit-contact-aditional-padding" >
            <input
              formControlName="phone"
              itfgPhonePrefix
              itfgTrimInput
              matInput
              type="text"
              placeholder="{{ 'global.phone' | translate }}"
            />
      <mat-label>{{ 'global.phone' | translate }}</mat-label>
            <mat-error
              *ngIf="contactsForm.get('phone').hasError('pattern')"
              [translate]="'forms.bulgarianPhoneCode'"
              >Missing 359</mat-error
            >
            <mat-error
              *ngIf="contactsForm.get('phone').hasError('minLength')"
                [translate]="'clients.phoneMinLength'"
                >Min length 8 symbols</mat-error
              >
            <mat-error
            *ngIf="contactsForm.get('phone').hasError('required')"
              [translate]="'forms.required'"
              >This field is required</mat-error
            >
          </mat-form-field>
          <mat-form-field flex class="edit-contact-aditional-padding">
            <input
              formControlName="description"
              matInput
              itfgTrimInput
              type="text"
              placeholder="{{ 'clients.description' | translate }}"
            />
      <mat-label>{{ 'clients.description' | translate }}</mat-label>
            <mat-error
            *ngIf="contactsForm.get('description').hasError('required')"
              [translate]="'forms.required'"
              >This field is required</mat-error
            >
          </mat-form-field>
          <div
            flex
            layout="row"
            layout-align="space-between center"
            class="edit-contact-aditional-padding"
          >
            <mat-checkbox
              formControlName="contacted"
            >
              <div [translate]="'clients.contacted'">Confirmed contact</div>
            </mat-checkbox>
            <button [disabled]="submitContactDisabled" mat-raised-button type="submit">
              {{ 'global.save' | translate}}
            </button>
          </div>
        </form>
      </mat-card-content>
    </mat-card>
  </div>
  <form #creditFormRef="ngForm" [formGroup]="form" (ngSubmit)="onSubmitCredit($event);" novalidate flex>
    <mat-card appearance="outlined" flex="100">
      <mat-card-title>
        <span [translate]="'credits.creditParams'"
              *ngIf="inEditMode"
          >Credit data</span
        >
        <span *ngIf="!inEditMode && activeCredit">
          <div layout="row">
            {{
              'credits.creditTitleTemplate'
                | translate
                  : {
                      creditId: activeCredit?.id || '',
                      clientFullName: ''
                    }
            }}
            <itfg-credit-status-icon [creditStatus]="activeCredit.creditStatus" [showName]="true"></itfg-credit-status-icon>
          </div>
        </span>
      </mat-card-title>
      <mat-card-subtitle *ngIf="!inEditMode && activeCredit">
        <span>
        </span>
      </mat-card-subtitle>
      <mat-card-content>
        <div flex layout="row" layout-wrap>
          <ng-container *ngIf="inEditMode">
              <itfg-credit-calculator-form
                class="right-padding-position-fix"
                flex-gt-sm="67"
                flex-gt-md="67"
                flex="100"
                [creditCalculatorTemplateCode]="creditCalculatorTemplateCode"
                [client]="client"
                (selectedProductPlan)="productPlanChanges($event)"
                ></itfg-credit-calculator-form>
            </ng-container>
            <ng-container *ngIf="!inEditMode">
              <itfg-credit-calculator-form
                class="right-padding-position-fix"
                flex-gt-sm="67"
                flex-gt-md="67"
                flex="100"
                [disableAll]="true"
                [creditCalculatorTemplateCode]="creditCalculatorCloneTemplateCode"
                [client]="client"
                [credit]="activeCredit"
                [creditToCopy]="activeCredit"
                (selectedProductPlan)="productPlanChanges($event)"
              ></itfg-credit-calculator-form>
            </ng-container>
            <mat-card appearance="outlined" flex>
              <mat-card-content>
                <div layout="column">
                  <mat-form-field *ngIf="inEditMode"
                    flex
                    class="right-padding-position-fix"
                  >
                    <mat-select
                      placeholder="{{ 'accounts.paymentMethod' | translate }}"
                      formControlName="payOutPreference"
                      [compareWith]="comparePaymentOptions"
                      (selectionChange)="selectedPaymentMethodChange($event)" 
                      required
                    >
                      <mat-select-trigger *ngIf="form.value.payOutPreference?.paymentMethod?.name === paymentMethodTypes.BANK">
                        <span matPrefix
                              matTooltip="{{ (!!form.value.payOutPreference.bankAccount.verified ? 'global.verified' : 'global.notVerified') | translate }}"
                              matTooltipPosition="left">
                          <mat-icon class="not-verified-bank-account" *ngIf="!form.value.payOutPreference.bankAccount.verified" style="margin-right: 0;vertical-align: baseline;" fontSet="fa-solid" fontIcon="fa-minus-square"> </mat-icon>
                          <mat-icon class="verified-bank-account" *ngIf="!!form.value.payOutPreference.bankAccount.verified" style="margin-right: 0;vertical-align: baseline;" fontSet="fa-solid" fontIcon="fa-check-square"> </mat-icon>
                          {{ form.value.payOutPreference.bankAccount.iban | formatIban:'obfuscated' }}
                        </span>
                      </mat-select-trigger>
                      <mat-optgroup
                        *ngFor="let group of paymentMethodGroups"
                        [label]="
                          group.name | paymentMethodToTranslationKey | translate
                        "
                      >
                        <ng-container
                          *ngIf="
                            group.name === paymentMethodTypes.BANK;
                            else otherMethods
                          "
                        >
                          <mat-option
                            *ngIf="group.methods.length === 0"
                            class="noClientBankAccounts"
                            disabled
                          >
                            {{ 'clients.noClientBankAccounts' | translate }}
                          </mat-option>
                          <mat-option
                            *ngFor="let method of group.methods"
                            [value]="method"
                          >
                            <span matPrefix
                                  matTooltip="{{ (!!method.bankAccount.verified ? 'global.verified' : 'global.notVerified') | translate }}"
                                  matTooltipPosition="left">
                              <mat-icon class="not-verified-bank-account" *ngIf="!method.bankAccount.verified" style="margin-right: 0;vertical-align: baseline;" fontSet="fa-solid" fontIcon="fa-minus-square"> </mat-icon>
                              <mat-icon class="verified-bank-account" *ngIf="!!method.bankAccount.verified" style="margin-right: 0;vertical-align: baseline;" fontSet="fa-solid" fontIcon="fa-check-square"> </mat-icon>
                              {{ method.bankAccount.iban | formatIban:'obfuscated' }}
                            </span>
                          </mat-option>
                          <mat-option
                            value="createBankAccount"
                            style="background: #dee3fd17;"
                          >
                            <span matPrefix style="margin-right: 8px;">
                              <mat-icon style="margin-right: 0;vertical-align: baseline;" fontSet="fa-solid" fontIcon="fa-plus"></mat-icon>
                              Добави сметка
                            </span>
                          </mat-option>
                        </ng-container>
                        <ng-template #otherMethods>
                          <mat-option
                            *ngFor="let method of group.methods"
                            [value]="method"
                          >
                            {{
                            method.paymentMethod.name
                            | paymentMethodToTranslationKey
                            | translate
                            }}
                          </mat-option>
                        </ng-template>
                      </mat-optgroup>
                    </mat-select>
                    <mat-error
                      *ngIf="
                        form.get('payOutPreference').hasError('required')
                      "
                      [translate]="'forms.required'"
                    >This field is required
                    </mat-error>
                  </mat-form-field>
                  <mat-form-field *ngIf="inEditMode">
                    <input
                      formControlName="loyaltyPoints"
                      [min]="minLoyaltyPoints"
                      [max]="maxLoyaltyPoints"
                      placeholder="{{ 'clients.loyaltyPoints' | translate }}"
                      matInput type="number"
                      [value]="maxLoyaltyPoints"
                      />
      <mat-label>{{ 'clients.loyaltyPoints' | translate }}</mat-label>
                      <mat-error
                        *ngIf="
                          form.get('loyaltyPoints').hasError('min')
                        "
                        [translate]="'forms.minValue'"
                        [translateParams]="{ value: minLoyaltyPoints }"
                      >Min value error
                      </mat-error>
                      <mat-error
                        *ngIf="
                          form.get('loyaltyPoints').hasError('max')
                        "
                        [translate]="'forms.maxValue'"
                        [translateParams]="{ value: maxLoyaltyPoints }"
                      >Max value error
                      </mat-error>
                  </mat-form-field>
                  <mat-form-field class="flex-padding" flex style="width: 100%;">
                    <mat-label>{{ 'communication.note' | translate }}</mat-label>
                    <textarea matInput
                              cdkTextareaAutosize
                              cdkAutosizeMinRows="6"
                              cdkAutosizeMaxRows="15"
                              formControlName="note">
                    </textarea>
                    <mat-error
                      *ngIf="form.get('note').hasError('minlength')"
                      [translate]="'forms.minLength'"
                      [translateParams]="{ value: 2 }"
                      >Min value error
                    </mat-error>
                    <mat-error *ngIf="form.get('note').hasError('required')"
                    [translate]="'forms.required'">This field is required</mat-error>
                  </mat-form-field>
                </div>
              </mat-card-content>
              <mat-card-actions *ngIf="!inEditMode">
                <button type="button" mat-raised-button (click)="saveNote(activeCredit)">{{ 'global.save' | translate }}</button>
              </mat-card-actions>
            </mat-card>
        </div>
      </mat-card-content>
      <mat-card-actions *ngIf="inEditMode">
        <button type="submit" mat-raised-button>{{ 'global.save' | translate }}</button>
      </mat-card-actions>
    </mat-card>
  </form>
</div>
