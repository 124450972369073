export enum EntityMatchOperations {
  MODIFIED = 'MODIFIED',
  NOT_MODIFIED = 'NOT_MODIFIED',
  ADDED = 'ADDED',
  REMOVED = 'REMOVED',
}

export interface EntityMatchField {
  newValue: any;
  oldValue: any;
  operation: EntityMatchOperations;
}

export interface EntityMatch {
  fields:
    | {
        [key: string]: EntityMatchField;
      }
    | EntityMatch;
  operation: EntityMatchOperations;
}
