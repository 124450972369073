import { Injectable } from '@angular/core';
import { RequestService } from './request.service';
import { map, mergeMap } from 'rxjs/operators';
import { Page } from '../types/page';
import {
  Bucket,
  BucketOperator,
  BucketCredit,
  BucketCreditView,
} from '../types/bucket';
import { Administrator } from '../types/administrator';
import { CollectionRequestService } from './collection-request.service';
import { AdministratorService } from './administrator.service';
import { SearchOptions, SearchOptionsDTO } from '../types/search-options';
import { SearchOperations } from '../types/search-criterium';
import { Observable, of, forkJoin, scheduled, asyncScheduler } from 'rxjs';
import { ProductService } from './product.service';
import { BrandService } from './brand.service';
import { Brand } from '../types/brand';
import { Product } from '../types/product';
import { SessionService } from './session.service';
import { ReminderService } from './reminder.service';
import { Reminder } from '../types/reminder';
import { CreditAssignment } from '../types/credit-assignment';
import { PaymentPromiseService } from './payment-promise.service';

@Injectable()
export class BucketService {
  request: RequestService;

  constructor(
    request: CollectionRequestService,
    private administratorService: AdministratorService,
    private brandService: BrandService,
    private productService: ProductService,
    private sessionService: SessionService,
    private reminderService: ReminderService,
    private paymentPromiseService: PaymentPromiseService,
  ) {
    this.request = request;
  }

  getBucketList$(options?: any): Observable<Page<Bucket>> {
    return this.request.get(['buckets'], { params: options }).pipe(
      mergeMap((page: Page<Bucket>) => {
        const brands$ = this.brandService.getBrandList();
        const products$ = this.productService.getProductList();
        return forkJoin(brands$, products$).pipe(
          map(([brands, products]) => {
            page.content.map((bucket: Bucket) => {
              bucket.brand = brands.find(b => b.id === bucket.brandId);
              bucket.product = products.content.find(
                (p: { id: number }) => p.id === bucket.productId
              );
              return bucket;
            });
            return page;
          })
        );
      })
    );
  }

  getBucketById$(id: number | string, options?: any) {
    return this.request.get(['buckets', id], { params: options });
  }

  saveBucket$(bucket: Bucket, options: any = {}) {
    const requestOptions = Object.assign(options, { body: bucket });
    return this.request.post(['buckets'], requestOptions);
  }

  saveAndReassign(operator: BucketOperator) {
    return this.request.post(['bucket-operators', 'save-and-unassign'], {
      body: operator,
    });
  }

  deleteBucket$(id: number | string): Observable<any> {
    return this.request.delete(['buckets', id]);
  }

  getBucketOperatorList$(options?: any): Observable<Page<BucketOperator>> {
    const bucketOperators$ = this.request.get(['operators', 'bucket'], {
      params: options,
    }).pipe(
      mergeMap((bucketOperatorPage: Page<BucketOperator>) => {
        const bucketOperators = bucketOperatorPage.content;
  
        const searchOptions = new SearchOptions();
        searchOptions.pageSize = 1000;
        searchOptions.criteria = [
          {
            key: 'active',
            operation: SearchOperations.EQUALS,
            value: true,
          },
          {
            key: 'id',
            operation: SearchOperations.IN,
            value: bucketOperators.map((bucketOperator) => bucketOperator.external_operator_id),
          }
        ];
  
        return this.administratorService.getAdministratorList(searchOptions.getDTO()).pipe(
          map((activeAdministratorsPage: Page<Administrator>) => {
            const activeOperatorIds = activeAdministratorsPage.content.map(operator => operator.id);
  
            const activeBucketOperators = bucketOperators.filter(bucketOperator =>
              activeOperatorIds.includes(bucketOperator.external_operator_id)
            );
  
            return { ...bucketOperatorPage, content: activeBucketOperators };
          })
        );
      })
    );
  
    return bucketOperators$;
  }
  

  getBucketOperatorById$(id: number | string) {
    return this.request.get(['operators', id, 'bucket']);
  }

  saveBucketOperator$(body: any, options: any = {}) {
    const requestOptions = Object.assign(options, { body: body });
    return this.request.post(['operators'], requestOptions);
  }

  deleteBucketOperator$(id: number | string): Observable<any> {
    return this.request.delete(['bucket-operators', id]);
  }

  getBucketOperatorPastAssignments$(
    ownerId: number,
    searchOptions: SearchOptionsDTO
  ) {
    return this.request
      .get(['bucket-operators', ownerId, 'past-assignments'], {
        params: searchOptions,
      })
      .pipe(mergeMap(this.creditAssignmentsMapper.bind(this)));
  }

  saveAndReturnPastAssignments$(operator: BucketOperator) {
    return this.request.post(
      ['bucket-operators', operator.id, 'save-and-return'],
      {
        body: operator,
      }
    );
  }

  getBucketCreditList$(options?: any): Observable<Page<BucketCreditView>> {
    return this.request
      .get(['credit-assignments', 'bucket-credits'], { params: options })
      .pipe(
        map((page: Page<BucketCreditView>) => {
          page.content.map(bc => {
            if (bc.paymentPromise) {
              bc.paymentPromise = this.paymentPromiseService.paymentPromiseStatusMap(bc.paymentPromise);
            }
            return bc;
          });
          return page;
        }),
      );
      // .pipe(mergeMap(this.creditAssignmentsMapper.bind(this)));
  }

  creditAssignmentsMapper(bucketCredits: Page<CreditAssignment>) {
    if (bucketCredits.content.length === 0) {
      return of(bucketCredits);
    }

    const productIds = bucketCredits.content.map(
      bucketCredit => bucketCredit.credit.productId
    );
    const brands$ = this.brandService.getBrandList();
    const products$ = this.productService.getProductList(
      this.getManySearchOptions(productIds).getDTO()
    );
    const observables$ = [];
    const empty$ = scheduled([], asyncScheduler);

    observables$.push(brands$);

    if (productIds.length === 0) {
      observables$.push(empty$);
    } else {
      observables$.push(products$);
    }

    return forkJoin([...observables$]).pipe(
      map((res: unknown) => {
        const brandList = res[0] || [];
        const productPage = res[1] || [];
        bucketCredits.content = bucketCredits.content.map(
          (bucketCredit: CreditAssignment) => {
            const bucketBrand = brandList.find(
              (brand: Brand) => brand.id === bucketCredit['bucket'].brandId
            );
            const bucketProduct = productPage.content.find(
              (product: Product) => product.id === bucketCredit.credit.productId
            );

            return {
              ...bucketCredit,
              brand: bucketBrand,
              product: bucketProduct,
            };
          }
        );

        return bucketCredits;
      })
    );
  }

  // TODO: Map Bucket brand
  mapBrand$(page: Page<Bucket>) {}

  // TODO: Map Bucket product
  mapProduct$(page: Page<Bucket>) {}

  getManySearchOptions(ids: number[]) {
    const getManySearchOptions = new SearchOptions();
    getManySearchOptions.addCriterium({
      key: 'id',
      operation: SearchOperations.IN,
      value: ids,
    });
    return getManySearchOptions;
  }

  regenerateBucket$(bucket: Bucket) {
    return this.request.post(['bucket-credits', 'generate', bucket.id]);
  }

  reassignBucket$(bucket: Bucket) {
    return this.request.post(['bucket-credits', 'assignees', bucket.id]);
  }

  generateIncremental$(bucket: Bucket) {
    return this.request.post(['bucket-credits', 'incremental', bucket.id]);
  }

  mapBucketOperator$(
    bucketOperator: BucketOperator,
    administratorPage: Page<Administrator>
  ) {
    const administrator = administratorPage.content.find(
      (admin: Administrator) => {
        return admin.id === bucketOperator.external_operator_id;
      }
    );

    return {
      ...bucketOperator,
      operator: administrator,
    };
  }
}
