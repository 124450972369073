import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { AuthComponent } from './auth.component';
import { AuthGuardService } from './auth-guard.service';
import { MfaVerificationComponent } from './mfa-verification/mfa-verification.component';

const routes: Routes = [
  {
    path: 'auth',
    component: AuthComponent,
    canActivate: [AuthGuardService],
    children: [
      {
        path: 'login',
        component: LoginComponent,
        data: { 
          titleKey: 'global.login',
          permissions: [],
         },
      },
      {
        path: '2fa',
        component: MfaVerificationComponent,
        data: {
          titleKey: 'global.mfa',
        },
      },
      {
        path: '2fa',
        component: MfaVerificationComponent,
        data: { 
          titleKey: 'global.mfa',
          permissions: ['PRE_AUTH_ADMIN'],
        },
      },
      { path: '', pathMatch: 'full', redirectTo: 'login' },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  declarations: [],
  exports: [RouterModule],
  providers: [AuthGuardService],
})
export class AuthRoutingModule {}
