import { Component, OnDestroy, OnInit } from '@angular/core';
import { toggleAnimation } from './toggle-animation';
import {
  MAT_TOOLTIP_DEFAULT_OPTIONS,
  MatTooltipDefaultOptions,
} from '@angular/material/tooltip';
import { RightDrawerService } from './right-drawer.service';

const customTooltipOptions: Partial<MatTooltipDefaultOptions> = {
  disableTooltipInteractivity: true,
};

@Component({
  selector: 'itfg-right-drawer',
  templateUrl: './right-drawer.component.html',
  styleUrls: ['./right-drawer.component.scss'],
  animations: [toggleAnimation],
  providers: [
    { provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: customTooltipOptions },
  ],
})
export class RightDrawerComponent implements OnInit, OnDestroy {
  constructor(public service: RightDrawerService) {}

  ngOnInit(): void {
    this.service.isComponentInitialized = true;
  }

  ngOnDestroy(): void {
    this.service.isComponentInitialized = false;
  }
}
