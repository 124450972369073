export class ReminderOption {
  text: string;
  _date: any;
  set date(date: any) {
    this._date = date;
  }
  get date() {
    return this._date;
  }
  timestampFunc?: () => any;

  displayText() {
    return `${this.timestampFunc().format('DD.MM.YYYY HH:mm')} - (${this.text})`;
  }



  constructor({
    timestampFunc = null,
    text,
  }) {
    this.timestampFunc = timestampFunc;
    this.text = text;
  }

}

export class ReminderOptionList {}