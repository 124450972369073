import { Component, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { ParamMap, ActivatedRoute } from '@angular/router';
import { forkJoin, Observable, Subject } from 'rxjs';
import { finalize, mergeMap, tap } from 'rxjs/operators';
import {
  filter,
  takeUntil,
} from 'rxjs/operators';
import { Client } from '../../../core/types/client';
import { ClientService } from '../../../core/services/client.service';
import { CreditCalculatorTemplateCode } from '../../../core/types/credit-calculator-template-code';
import { CreditDto, PayOutPreferenceDto } from '../../../core/types/creditDto';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { PaymentMethod, PaymentMethodTypes } from '../../../core/types/payment-method';
import { BankAccount } from '../../../core/types/bank-account';
import { PaymentMethodService } from '../../../core/services/payment-method.service';
import {
  forkJoin as observableForkJoin,
} from 'rxjs';
import { LoyaltyPointsService } from '../../../core/services/loyalty-points.service';
import { CreditService } from '../../../core/services/credit.service';
import { ProductPlanEntry } from '../../../core/types/product-plan-entry';
import { NotificationService } from '../../../core/services/notification.service';
import { Tag } from '../../../core/types/tag';
import { Router } from '@angular/router';
import { Credit } from '../../../core/types/credit';
import { CreditStatusNames } from '../../../core/types/credit-status';
import { ConversationService } from '../../../core/services/conversation.service';
import { DeserializedConversation } from '../../../core/types/converstion';
import { MatDialog } from '@angular/material/dialog';
import { ClientAddBankAccountComponent } from '../../client/client-edit/add-bank-account-modal/client-add-bank-account.component';
import { DialogProviderService } from '@app/core/services/dialog-provider.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'itfg-affiliate-page-create',
  templateUrl: './affiliate-page-create.component.html',
  styleUrls: ['./affiliate-page-create.component.scss']
})
export class AffiliatePageCreateComponent implements OnInit, OnDestroy {
  _unsubscribe = new Subject<void>();
  userId: number;
  client: Client;
  creditCalculatorTemplateCode = CreditCalculatorTemplateCode.STAND_ALONE_CALCULATOR;
  creditCalculatorCloneTemplateCode = CreditCalculatorTemplateCode.CLONE_CALCULATOR;
  form: UntypedFormGroup;
  contactsForm: UntypedFormGroup;
  paymentMethodGroups = [];
  paymentMethodTypes: typeof PaymentMethodTypes = PaymentMethodTypes;
  bankAccountList: BankAccount[];
  paymentMethodList: PaymentMethod[];
  availableLoyaltyPoints: number;
  productPlan: ProductPlanEntry;
  campaignFromUrl: string;
  campaignTag: Tag;
  inEditMode: boolean = false;
  activeCredit: Credit;
  activeStatuses: string[] = [
    CreditStatusNames.APPROVED,
    CreditStatusNames.REGULAR,
    CreditStatusNames.CONFIRMED,
    CreditStatusNames.NEW,
    CreditStatusNames.OVERDUE,
  ];
  loyaltyPointsAvailableNumber = 0;
  minLoyaltyPoints = 0;
  maxLoyaltyPoints: number;
  addedContacts = [];
  @ViewChild('contactsFormRef') contactsFormRef;
  @ViewChild('creditFormRef') creditFormRef;
  submitContactDisabled = false;
  public _viewContainerRef: ViewContainerRef;

  constructor(
    private clientService: ClientService,
    private fb: UntypedFormBuilder,
    private paymentMethodService: PaymentMethodService,
    private route: ActivatedRoute,
    private router: Router,
    protected loyaltyPointsService: LoyaltyPointsService,
    private creditService: CreditService,
    private notifications: NotificationService,
    private conversations: ConversationService,
    private dialog: MatDialog,
    private dialogs: DialogProviderService,
    private translate: TranslateService,
    ) { }

  ngOnInit(): void {
    this.createForm();
    this.paramMapSubscription();
    this.campaignChange$().subscribe((params: ParamMap) => {
      this.campaignFromUrl = params.get('campaign');
    });
  }

  createForm() {
    this.form = this.fb.group({
      payOutPreference: [null, Validators.required],
      note: null,
      loyaltyPoints: 0,
    })
    this.contactsForm = this.fb.group({
      description: ['', Validators.required],
      phone: ['', [Validators.required, Validators.pattern(/^\+359/), Validators.minLength(8)]],
      contacted: false,
    },
    {updateOn: 'submit'})
  }

  paramMapSubscription() {
    const paramMap$ = this.route.paramMap;
    paramMap$
      .pipe(
        filter((params: ParamMap) => typeof params.get('userId') === 'string'),
        mergeMap((params: ParamMap) => {
            this.userId = Number(params.get('userId'));
            return forkJoin([
              this.clientService.getClientById(this.userId),
              this.loyaltyPointsService.getLoyaltyAvailableNumber$(this.userId)
            ]);
          }
        ),
        filter((res: [Client, number]) => res[0] !== null)
      )
      .subscribe((res: [Client, number]) => {
        this.client = res[0];
        this.loyaltyPointsAvailableNumber = res[1];
        this.campaignTag = this.client.tags ? this.client.tags.find((tag: Tag) => tag.code === this.campaignFromUrl) : null;
        if (!this.campaignTag) {
          this.router.navigate(['/resource-not-found'], {
            skipLocationChange: true,
          });
        }
        this.creditService.searchCredit({
          civilId: this.client.civilId,
        }).subscribe((creditRes: Credit[]) => {
          const activeOrLastCredit: Credit = creditRes[0];
          if (!activeOrLastCredit || !this.activeStatuses.includes(activeOrLastCredit?.creditStatus.name)) {
            this.inEditMode = true;
            this.availableLoyaltyPoints = res[1];
            this.getPaymentMethodsAndBankAccounts(this.client);
          } else {
            this.activeCredit = activeOrLastCredit;
            this.setNoteValidators();
          }
        });

      });
  }

  ccc() {
    window.close();
  }

  showSuccessDialog(credit: Credit) {
    window.location.reload();
    // this.translate
    // .get(
    //   [
    //     'applications.successfullyCreatedapplication',
    //     'global.dismiss',
    //     'global.stay',
    //   ],
    //   {
    //     creditId: credit.id,
    //   }
    // )
    // .subscribe(translation => {
    //   const dialogConfig = {
    //     message: '',
    //     title: translation['applications.successfullyCreatedapplication'],
    //     disableClose: false,
    //     viewContainerRef: this._viewContainerRef,
    //     cancelButton: translation['global.stay'],
    //     acceptButton: translation['global.dismiss'],
    //   };

    //   this.dialogs
    //     .openConfirm(dialogConfig)
    //     .afterClosed()
    //     .subscribe((accept: boolean) => {
    //       if (accept) {
    //         window.close();
    //       } else {
    //         window.location.reload();
    //       }
    //     });
    // });
  }

  selectedPaymentMethodChange(event: any) {
    if (event.value === 'createBankAccount') {
      this.dialog.open(ClientAddBankAccountComponent, {
          data: {
            userId: this.userId,
          },
          autoFocus: false,
      })
      .afterClosed()
      .subscribe(selectedValue => {
        this.getPaymentMethodsAndBankAccounts(this.client, selectedValue);
      });
    }
  }

  setNoteValidators() {
    Object.keys(this.form.controls).forEach(key => {
      this.form.controls[key].setValidators(null);
      this.form.controls[key].updateValueAndValidity();
    });
    this.form.controls['note'].setValidators([
      Validators.required,
    ]);
    this.form.controls['note'].updateValueAndValidity();
  }

  campaignChange$(): Observable<any> {
    return this.route.queryParamMap.pipe(
      takeUntil(this._unsubscribe),
      filter((paramMap: ParamMap) => typeof paramMap.get('campaign') === 'string')
    );
  }

  comparePaymentOptions(
    firstObject: PayOutPreferenceDto,
    secondObject: PayOutPreferenceDto
  ) {
    if (!firstObject || !secondObject) {
      return false;
    }
    if (firstObject.bankAccount && secondObject.bankAccount) {
      return firstObject.bankAccount.id === secondObject.bankAccount.id;
    }
    return firstObject.paymentMethod.id === secondObject.paymentMethod.id;
  }

  groupPaymentMethods(
    paymentMethods: PaymentMethod[],
    bankAccounts: BankAccount[]
  ) {
    const methodGroups = [];

    paymentMethods.forEach((paymentMethod: PaymentMethod) => {
      const methods: Array<PayOutPreferenceDto> = [];

      if (paymentMethod.name === this.paymentMethodTypes.BANK) {
        this.bankAccountList.forEach(bankAccount => {
          methods.push({
            paymentMethod: paymentMethod,
            bankAccount: bankAccount,
          });
        });
      } else {
        methods.push({
          paymentMethod: paymentMethod,
          bankAccount: null,
        });
      }

      methodGroups.push({
        name: paymentMethod.name,
        methods: methods,
      });
    });

    this.paymentMethodGroups = methodGroups;
  }

  getPaymentMethodsAndBankAccounts(client: Client, selectedAccount = null) {
    observableForkJoin([
        this.paymentMethodService.getPaymentMethodList(client?.brand.id),
        this.clientService.getClientBankAccounts(client.id)
      ])
      .subscribe(
      ([paymentMethods, bankAccounts]: [PaymentMethod[], BankAccount[]]) => {
        this.paymentMethodList = paymentMethods.filter(method => method.name !== PaymentMethodTypes.CASH);
        this.bankAccountList = bankAccounts;
        this.clientService.clientBankAccounts$.next(bankAccounts);
        this.groupPaymentMethods(this.paymentMethodList, bankAccounts);
        if (selectedAccount) {
          const addedPayOutPreference = this.paymentMethodGroups.find(g => g.name === PaymentMethodTypes.BANK)
                                                                .methods
                                                                .find(pm => pm.bankAccount.iban = selectedAccount.iban);
          this.form.patchValue({
            payOutPreference: addedPayOutPreference,
          });
        }
      }
    );
  }

  productPlanChanges(plan: ProductPlanEntry) {
    this.productPlan = plan;
    this.setValidatorsForLoyaltyPoints();
  }

  onSubmitCredit(event) {
    if (this.form.valid && !!this.productPlan) {
      const dto: CreditDto = {
        discountCode: "",
        loyaltyPoints: this.form.value.loyaltyPoints,
        operatorId: null,
        payOutPreference: this.form.value.payOutPreference,
        productPlanId: this.productPlan.id,
        tags: [
          this.campaignTag.code
        ],
        userId: this.client.id,
      }
      this.creditService.saveCredit(dto)
                        .subscribe((credit: Credit) => {
                          if (this.form.value.note && this.form.value.note.length > 0) {
                            this.saveNote(credit);
                          }
                          this.showSuccessDialog(credit);
                        });
    } else {
      if (!this.productPlan) {
        this.notifications.showLocalizedErrorMessage({
          notificationText: 'credits.selectProductPlanLabel',
        });
      } else {
        this.notifications.showLocalizedErrorMessage({
          notificationText: 'forms.formSavedIsInvalid',
        });
      }
    }
  }

  setValidatorsForLoyaltyPoints() {
    this.maxLoyaltyPoints = this.loyaltyPointsAvailableNumber < 0 ? 0 : this.loyaltyPointsAvailableNumber;

    if (this.productPlan) {
      const totalForfeit = Math.trunc((this.productPlan.installmentForfeit + this.productPlan.installmentUtilizationFee) * this.productPlan.installmentsNumber);

      if (this.maxLoyaltyPoints > totalForfeit) {
        this.maxLoyaltyPoints = totalForfeit;
      }
    }
    this.form.controls['loyaltyPoints'].setValidators([Validators.max(this.maxLoyaltyPoints), Validators.min(this.minLoyaltyPoints)]);
  }

  saveNote(credit: Credit) {
    if (this.form.valid) {
      const newConversationDto = {
        type: null,
        contacted: null,
        phone: null,
        notes: this.form.value.note,
        credit: { id: credit.id },
      };
      this.conversations.saveConversation(
        newConversationDto
      )
      .subscribe(
        (conversation: DeserializedConversation) => {
          this.notifications.showLocalizedSuccessMessage({
            notificationText: 'communication.noteSuccessfullyAdded',
          });
          this.creditFormRef.resetForm();
          this.form.reset();
        }
      );
    } else {
      this.notifications.showLocalizedErrorMessage({
        notificationText: 'forms.formSavedIsInvalid',
      });
    }
  }

  onSubmitContact(event) {
    if (this.contactsForm.valid) {
      this.submitContactDisabled = true;
      this.client.contactPhones.push(this.contactsForm.value);
      this.clientService.saveClient(this.client).subscribe(res => {
        this.addedContacts.push(this.contactsForm.value);
        this.notifications.showLocalizedSuccessMessage({
          notificationText: 'clients.contactSuccessfullyAdded',
        });
        this.contactsFormRef.resetForm();
        this.contactsForm.reset();
        this.submitContactDisabled = false;
      });
    } else {
      this.notifications.showLocalizedErrorMessage({
        notificationText: 'forms.formSavedIsInvalid',
      });
    }
  }

  ngOnDestroy() {
    this._unsubscribe.next();
    this._unsubscribe.complete();
  }

}
