import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FilterValueListService, FilterValue } from '../filter/types';
import { PaymentsApiService } from '../../core/services/payments-api.service';
import { BankAccount } from '../../core/types/bank-account';
import { IbanCodePipe } from '../credit/pipes/to-iban-code.pipe';

@Injectable()
export class PaymentBankAccountValueListService implements FilterValueListService {
  constructor(
    private paymentsService: PaymentsApiService,
    private formatIbanPipe: IbanCodePipe
  ) {}

  getFilterValues$(): Observable<any> {
    return this.paymentsService.getBankAccounts$().pipe(
    map((bankAccountList: BankAccount[]): FilterValue[] => {
          return bankAccountList.map(
            (bankAccount : BankAccount) : FilterValue => {
              return {
                value: this.formatIbanPipe.transform(bankAccount.iban),
                displayText: this.formatIbanPipe.transform(bankAccount.iban)
              }
            }
          )
      })
    )
  }
}
