import { Component, OnInit, Inject, ViewContainerRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Client } from '@app/core/types/client';
import { ContactPhone, ContactPhoneType } from '@app/core/types/contact-phone';
import { InteractionMode } from '@app/core/types/interaction-mode';

@Component({
  selector: 'itfg-event-log-add-contact-dialog',
  templateUrl: './event-log-add-contact-dialog.component.html',
  styleUrls: ['./event-log-add-contact-dialog.component.scss'],
})
export class EventLogAddContactDialogComponent implements OnInit {
  public client: Client;
  public phoneNumber: string;
  public interactionMode: InteractionMode;
  public eventLogContacts: ContactPhone[];
  public eventLogPhoneTypes: ContactPhoneType[];

  constructor(
    public dialogRef: MatDialogRef<EventLogAddContactDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      client: Client;
      phoneNumber: string;
      interactionMode: InteractionMode;
      eventLogPhoneTypes: ContactPhoneType[];
      eventLogContacts: ContactPhone[];
    },
    public _viewContainerRef: ViewContainerRef
  ) {
    this.client = data.client;
    this.eventLogContacts = data.eventLogContacts;
    this.eventLogPhoneTypes = data.eventLogPhoneTypes,
    this.interactionMode = data.interactionMode;
  }

  ngOnInit() {}

  onNoClick(): void {
    this.dialogRef.close();
  }
}
