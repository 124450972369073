import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FilterValueListService, FilterValue } from '../filter/types';
import { CityService } from '../../core/services/city.service';
import { City } from '../../core/types/city';
import { CityDisplayTextPipe } from '../../shared/pipes/city-display-text.pipe';

@Injectable()
export class CityValueListService implements FilterValueListService {
  constructor(
    private cities: CityService,
    private cityDisplayTextPipe: CityDisplayTextPipe
  ) {}

  getFilterValues$(cityToSearchFor?: string): Observable<FilterValue[]> {
    return this.cities.getCitiesStartingWith(cityToSearchFor || '').pipe(
      map((cityList: City[]): FilterValue[] => {
        return cityList.map(this.mapCityToFilterValue.bind(this));
      })
    );
  }

  getFilterCardValueById$(id: number | string): Observable<City> {
    return this.cities
      .getCityById(id)
      .pipe(map(this.mapCityToFilterValue.bind(this)));
  }

  mapCityToFilterValue(city: City) {
    return {
      value: city.id,
      displayText: `${city.name} - ${city.municipality.name}`,
    };
  }
}
