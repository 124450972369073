<mat-card>
  <mat-card-header>
    <mat-card-title class="edit-screen-card-title"
    >{{ 'nssi.summaryInfo' | translate }}
    </mat-card-title>

  </mat-card-header>
  <mat-card-content>
    <div><i>{{ 'nssi.nssi7summaryInfo' | translate: {
      activeContracts: summary?.activeContracts.length,
      terminatedContracts: summary?.terminatedContracts.length,
      workExperienceYears: workExperienceYears,
      workExperienceMonth: workExperienceMonth,
      workExperienceTotalYears: workExperienceTotalYears,
      workExperienceTotalMonth: workExperienceTotalMonth
    } }}</i></div>
    <h4>{{ 'nssi.activeContracts' | translate }}</h4>
    <div *ngIf="summary?.activeContracts?.length === 0"><i>{{'nssi.noActiveContracts' | translate}}</i></div>
    <itfg-data-table *ngIf="summary?.activeContracts?.length > 0"
      flex
      [data]="summary?.activeContracts"
      [columns]="nssi7SummaryColumns"
    >
      <ng-template itfgDataTableTemplate="salary" let-salary="value">
        <span>{{ salary | toCustomItfgAmount }}</span>
      </ng-template>
      <ng-template itfgDataTableTemplate="contractReason" let-contractReason="value">
        <span matTooltip="{{ contractReason | nssiContractReason }}"
              matTooltipPosition="above">{{ contractReason}}</span>
      </ng-template>
    </itfg-data-table>
    <h4>{{ 'nssi.terminatedContracts' | translate }}</h4>
    <div *ngIf="summary?.terminatedContracts?.length === 0"><i>{{'nssi.noTerminatedContracts' | translate}}</i></div>
    <itfg-data-table *ngIf="summary?.terminatedContracts?.length > 0"
      flex
      [data]="summary?.terminatedContracts"
      [columns]="nssi7SummaryColumns"
    >
      <ng-template itfgDataTableTemplate="salary" let-salary="value">
        <span>{{ salary | toCustomItfgAmount }}</span>
      </ng-template>
      <ng-template itfgDataTableTemplate="contractReason" let-contractReason="value">
        <span matTooltip="{{ contractReason | nssiContractReason }}"
              matTooltipPosition="above">{{ contractReason}}</span>
      </ng-template>
    </itfg-data-table>

  </mat-card-content>
</mat-card>
