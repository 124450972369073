import { HttpClient } from '@angular/common/http';
import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  AfterViewInit,
} from '@angular/core';

@Component({
  selector: 'itfg-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
})
export class FileUploadComponent implements OnInit {
  file: File | null = null;
  @Input() hidden = false;
  @Output() fileSelected: EventEmitter<File> = new EventEmitter();
  @ViewChild('fileUpload')
  public fileUpload: any;

  constructor() {}

  triggerUploadButton() {
    if (this.fileUpload?.nativeElement) {
      this.fileUpload.nativeElement.click();
    }
  }

  ngOnInit(): void {}

  // On file Select
  onChange(event: any) {
    const file: File = event.target.files[0];
    if (file) {
      this.file = file;
      this.fileSelected.emit(this.file);
    }
  }
}
