import { ClientEditComponent } from './client-edit.component';
import { Component, OnInit, ChangeDetectorRef, ViewContainerRef } from '@angular/core';
import { ClientService } from '../../../core/services/client.service';
import { BrandService } from '../../../core/services/brand.service';
import { TranslateService } from '@ngx-translate/core';
import { UntypedFormBuilder } from '@angular/forms';
import { ActivatedRoute, Router, ParamMap } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { NotificationService } from '../../../core/services/notification.service';
import { LoaderService } from '../../../core/services/loader.service';
import { FormManipulationService } from '../../../core/services/form-manipulation.service';
import { ErrorService } from '../../../core/services/error.service';
import { filter, mergeMap, share, takeUntil } from 'rxjs/operators';
import { ApplicationService } from '../../../core/services/application.service';
import { Application } from '../../../core/types/application-type';
import { forkJoin as observableForkJoin, Observable } from 'rxjs';
import { Client } from '../../../core/types/client';
import {LoyaltyPointsService} from '../../../core/services/loyalty-points.service';
import {TagService} from '../../../core/services/tag.service';
import { SmsService } from '../../../core/services/sms.service';
import { EmailService } from '../../../core/services/email.service';
import { ContactService } from '../../../core/services/contacts.service';
import { FilterGroupType } from '@app/main/filter/types';
import { IbanUtilService } from '@app/core/services/iban-util.service';
import { DialogProviderService } from '@app/core/services/dialog-provider.service';
import { EventLogStateService } from '@app/main/main-layout/right-drawer/event-log/event-log-state.service';
import { IdentityReportsService } from '@app/core/services/identity-reports.service';
@Component({
  selector: 'itfg-client-add-for-new-credit',
  templateUrl: './client-edit.component.html',
  styleUrls: ['./client-edit.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClientAddForNewCreditComponent extends ClientEditComponent
  implements OnInit {
  private application: Application = new Application();
  // TODO: Continue form validation snackbar from here
  constructor(
    protected clientService: ClientService,
    protected tagService: TagService,
    protected contactService: ContactService,
    protected brandDataService: BrandService,
    protected translateService: TranslateService,
    protected formBuilder: UntypedFormBuilder,
    protected notificationService: NotificationService,
    protected route: ActivatedRoute,
    protected router: Router,
    public dialog: MatDialog,
    public loaderService: LoaderService,
    public dialogService: DialogProviderService,
    public viewContainerRef: ViewContainerRef,
    protected formManipulationService: FormManipulationService,
    public errorService: ErrorService,
    private activatedRoute: ActivatedRoute,
    private applicationService: ApplicationService,
    protected loyaltyPointsService: LoyaltyPointsService,
    protected smsService: SmsService,
    protected emailService: EmailService,
    private changeDetectorRef: ChangeDetectorRef,
    private ibanUtilsService: IbanUtilService,
    protected eventLogStateService: EventLogStateService,
    protected identityReportsService: IdentityReportsService,
  ) {
    super(
      clientService,
      contactService,
      tagService,
      brandDataService,
      translateService,
      formBuilder,
      notificationService,
      route,
      router,
      dialog,
      loaderService,
      dialogService,
      viewContainerRef,
      formManipulationService,
      errorService,
      loyaltyPointsService,
      smsService,
      emailService,
      eventLogStateService,
      identityReportsService
    );

    this.refIdChange$().subscribe((application: Application) => {
      this.setClientDataFromApplication(application);
    });

    this.civilIdChange$().subscribe((civilId: string) => {
      this.setClientDataByCivilId(civilId);
    });
  }

  refIdChange$(): Observable<any> {
    return this.activatedRoute.queryParamMap.pipe(
      takeUntil(this._unsubscribe),
      filter(
        (paramMap: ParamMap) =>
          typeof paramMap.get('applicationRef') === 'string'
      ),
      mergeMap((paramMap: ParamMap) => {
        const refId = paramMap.get('applicationRef');
        return this.applicationService.getApplicationById$(refId);
      })
    );
  }

  civilIdChange$(): Observable<any> {
    return this.activatedRoute.queryParamMap.pipe(
      takeUntil(this._unsubscribe),
      filter(
        (paramMap: ParamMap) =>
          typeof paramMap.get('civilIdRef') === 'string'
      )
    )
  }

  async setClientDataFromApplication(application: Application) {
    this.application = application;

    const contactPhoneNumber = this.application.contactPhone
    ? this.application.contactPhone.startsWith("0")
      ? `+359${this.application.contactPhone.substring(1)}`
      : this.application.contactPhone
    : "";

    if (this.application.source?.name.toLowerCase() === FilterGroupType.PARTNER.toLowerCase()) {
      const validIban = this.ibanUtilsService.extractAndValidateIban(this.application?.notes);
      if (validIban) {
        this.initialBankAccounts.push({
            iban: validIban,
            verified: validIban === this.application.partner?.iban
        });
    }
  }

    const client: Client = new Client({
      id: null,
      
      employmentType: this.application.employmentStatus,
      contactPhones: contactPhoneNumber ?
       [
          {
            id: null,
            type: {
              id: 3,
            },
            phone: contactPhoneNumber,
            description: this.application.contactName,
            active: true,
          },
        ]
      : [],

      ...this.application,
    });
    delete client.id;
    delete client.status;
    this.client = client;
    this.clientComponentData = this.breakdownClientForComponents(
      client,
      null,
      null
    );
    this.clientDataChangesDetected = true;
    this.enableSaveButton = true;
    this.changeDetectorRef.detectChanges();
  }

  async setClientDataByCivilId(civilId: any) {
    const client: Client = new Client({
      id: null,
      civilId: civilId.params?.civilIdRef
    });
    delete client.id;
    delete client.status;
    this.client = client;
    this.clientComponentData = this.breakdownClientForComponents(
      client,
      null,
      null
    );
  }

  navigateToNextPage() {
    const queryParams = {
      client: this.client.id,
    };
    if (this.application && this.application.id) {
      queryParams['applicationRef'] = this.application.id;
    }
    this.router.navigate(['credits', 'new', 'fill-credit'], { queryParams });
  }

  save(): any {
    const contactsToSave = this.client.contactPhones;
    return super.save().then(() => {
      // TODO: delete brand code check after implementing CD website
      if(this.client.email && this.client.brand.code === "SC"){
        this.emailService.sendEmailConfirmation(this.client.id)
          .subscribe(emailConfirmation => console.log(emailConfirmation));
      }

        if (contactsToSave && contactsToSave.length > 0) {
          const contactObservables = contactsToSave.map((contact) => {
            return this.contactService.createContact(this.client.id, contact);
          });

          observableForkJoin(contactObservables).subscribe(
            (responses) => {
              console.log(responses);
            },
            (error) => {
              console.log(error);
            }
          );
        }
    })
    .catch((error) => {
      console.log(error);
    });
  }
}