import {Component, Inject, OnInit} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import {Client} from '../../../../core/types/client';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {CompensationTypes} from '../../../../core/types/identity-reports';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import * as moment from 'moment';

export interface ChooseCompensationTypeReportData {
  client: Client;
  compensationTypesList: CompensationTypes;
}

@Component({
  selector: 'itfg-choose-nssi-type-report',
  templateUrl: './choose-compensation-type-report.component.html',
  styleUrls: ['./choose-compensation-type-report.component.scss']
})
export class ChooseCompensationTypeReportComponent implements OnInit {

  public compensationTypesForm: UntypedFormGroup;
  public currentDate: Date = new Date();
  public dateFrom: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ChooseCompensationTypeReportData,
    private dialogRef: MatDialogRef<ChooseCompensationTypeReportComponent>,
    private fb: UntypedFormBuilder
  ) {
    this.dateFrom = moment().subtract(1, 'years').format('YYYY-MM-DD');
  }

  ngOnInit() {
    this.compensationTypesForm = this.fb.group({
      type: '',
      dateFrom: this.dateFrom
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onSubmit() {
    this.dialogRef.close(this.compensationTypesForm.getRawValue());
  }

}
