import { Injectable } from '@angular/core';
import { IdentityReportsRequestService } from './identity-reports-request.service';
import { of } from 'rxjs';

@Injectable()
export class GraphService {
  constructor(public request: IdentityReportsRequestService) {
  }


  getConnectionByUser(userId: number) {
    return this.request.get(['graph', 'connections', userId])
  }

  getPath(userId: number, nodeLabel: string, nodeProperties: string) {
    return this.request.get(['graph', 'path'], { params: { userId, nodeLabel, nodeProperties } })
  }

  getNeighbors(nodeLabel: string, nodeProperties: string) {
    return this.request.get(['graph', 'neighbors'], { params: { nodeLabel, nodeProperties } })
  }

}
