import { filter, debounceTime, map, takeUntil } from 'rxjs/operators';
import {
  Component,
  OnInit,
  OnChanges,
  Input,
  OnDestroy,
  Output,
  ViewChildren,
  QueryList,
  ElementRef,
  Renderer2,
  ViewChild,
} from '@angular/core';
import {
  SearchCriterium,
  SearchOperations,
} from '../../../core/types/search-criterium';
import { FilterService } from '../filter.service';
import { EventEmitter } from '@angular/core';
import { FilterCardConfig, FilterUIType, Filter, FilterValue } from '../types';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatButton } from '@angular/material/button';
import { MatDatepicker } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { Subscription, Observable, Subject } from 'rxjs';
import { trigger, style, transition, animate } from '@angular/animations';
import { FormControl } from '../../../../../node_modules/@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { SessionService } from '../../../core/services/session.service';

import {
  UserPagePreferences,
  UserSettings,
  PageName,
} from '../../../core/types/user-preferences';
import { FilterCardAddFilterComponent } from '../filter-card-add-filter/filter-card-add-filter.component';
import { FilterCardSelectFiltersComponent } from '../filter-card-select-filters/filter-card-select-filters.component';
import { CdkDragDrop } from '@angular/cdk/drag-drop';

@Component({
  selector: 'itfg-filter-card',
  templateUrl: './filter-card.component.html',
  styleUrls: ['./filter-card.component.scss'],
  animations: [
    trigger('cardState', [
      transition(':enter', [
        style({
          transformOrigin: 'top',
          transform: 'scaleY(0)',
          opacity: 0,
        }),
        animate(
          150,
          style({
            transformOrigin: 'top',
            transform: 'scaleY(1)',
            opacity: 1,
          })
        ),
      ]),
      transition(':leave', [
        style({
          transformOrigin: 'top',
          transform: 'scaleY(1)',
          opacity: 1,
        }),
        animate(
          150,
          style({
            transformOrigin: 'top',
            transform: 'scaleY(0)',
            opacity: 0,
          })
        ),
      ]),
    ]),
  ],
})
export class FilterCardComponent implements OnInit, OnChanges, OnDestroy {
  @Input() slim: boolean;
  @Input() isShown: boolean;
  @Input() isSettingsShown: boolean;
  @Input() filters: FilterService;
  @Input() sessionSettingsPageName: PageName;
  @Input() filtersConfig: FilterCardConfig;
  @Input() initialFilters: SearchCriterium[];
  @Output() filtersApplied: EventEmitter<
    SearchCriterium[]
  > = new EventEmitter();
  @Output() lastFilterRemoved: EventEmitter<void> = new EventEmitter();
  @Output() setShownFilters: EventEmitter<boolean> = new EventEmitter();

  @ViewChild('searchButton') searchButton: MatButton;
  @ViewChildren('valueInput') valueInputs: QueryList<ElementRef>;
  @ViewChild('dropdown') dropdown: MatSelect;

  filterList: Filter[];
  filterPaths: string[];
  filterUITypes: any;
  operationsList: SearchOperations[];
  columns: string[];
  reorderMode = false;
  lastRemovedFilter: Filter;
  autocompleteValueChanged: Subject<any> = new Subject();
  public userPagePreferences: UserPagePreferences;
  public userSettings: UserSettings;
  public autocompleteValueChangedSubscription: Subscription;
  operationsListTranslationMap: {
    [key: string]: string;
  };
  filterOptionsCtrl = new FormControl();
  activeFilterIndex;
  currentFilterValues;
  _unsubscribe = new Subject<void>();

  private onFilterChangesCallback: (filterList: SearchCriterium[]) => any;
  private filtersChangedSubscription: Subscription;

  // private _unsubscribe = new Subject<void>();

  constructor(
    private translateService: TranslateService,
    private sessionService: SessionService,
    private dialogService: MatDialog,
    public renderer: Renderer2
  ) {
    this.userPagePreferences = {
      filters: { config: [], isShown: false },
      columns: [],
    };
    this.userSettings = new UserSettings();

    this.operationsListTranslationMap = {
      [SearchOperations.EQUALS]: 'filtering.equals',
      [SearchOperations.LESS_THAN]: 'filtering.lessThan',
      [SearchOperations.LESS_THAN_OR_EQUAL]: 'filtering.lessThanOrEqual',
      [SearchOperations.GREATER_THAN]: 'filtering.greaterThan',
      [SearchOperations.GREATER_THAN_OR_EQUAL]: 'filtering.greaterThanOrEqual',
      [SearchOperations.IN]: 'filtering.isContainedIn',
      [SearchOperations.LIKE]: 'filtering.contains',
    };

    this.filterUITypes = {
      autocomplete: FilterUIType.AUTOCOMPLETE,
      datepicker: FilterUIType.DATEPICKER,
      dropdown: FilterUIType.DROPDOWN,
      input: FilterUIType.INPUT,
      multiselect: FilterUIType.MULTISELECT,
      multiselectAutocomplete: FilterUIType.MULTISELECT_AUTOCOMPLETE,
    };
  }

  ngOnInit() {
    this.filterList = this.filters.getFilters();
    this.onFilterChangesCallback = this.onFilterChanges.bind(this);
    this.filtersChangedSubscription = this.filters.filtersChanged.subscribe(
      this.onFilterChanges.bind(this)
    );

    this.autocompleteValueChangedSubscription = this.autocompleteValueChanged
      .pipe(
        filter(filterSearch => typeof filterSearch.searchValue === 'string'),
        filter(filterSearch => filterSearch.searchValue.length >= 3),
        debounceTime(700),
        map(filterSearch => {
          return {
            filter:
              filterSearch.filter || this.filterList[filterSearch.filterIndex],
            searchValue: filterSearch.searchValue,
          };
        })
      )
      .subscribe(filterSearch => {
        const filterValues$ = <Observable<FilterValue[]>>(
          filterSearch.filter.config.valueListService.getFilterValues$(
            filterSearch.searchValue
          )
        );
        filterValues$.subscribe((filterValues: FilterValue[]) => {
          filterSearch.filter.filterValues = filterValues;
        });
      });
    this.translateFilterKeys();
    this.applyUserPreferences();
    this.filterOptionsCtrl.valueChanges
      .pipe(
        takeUntil(this._unsubscribe),
        debounceTime(100)
      )
      .subscribe(this.filterValueChanged.bind(this));
  }

  ngOnChanges(changes) {
    if (changes.isShown && !changes.isShown.firstChange) {
      this.saveFiltersState();
    }
    if (changes.isSettingsShown && !changes.isSettingsShown.firstChange) {
      this.selectFiltersClicked();
    }
  }
  selectFiltersClicked() {
    const filtersPreferences = this.sessionService.getFilterSettings(
      this.sessionSettingsPageName
    );
    this.dialogService
      .open(FilterCardSelectFiltersComponent, {
        data: {
          filtersPreferences: filtersPreferences,
          filtersConfig: this.filtersConfig,
          operationsListTranslationMap: this.operationsListTranslationMap,
        },
        maxWidth: '80vw',
        autoFocus: false,
      })
      .afterClosed()
      .pipe(filter(result => !!result))
      .subscribe((result) => {
        result.removeFiltersIndexesList.forEach(index => {
          this.removeFilter(index);
        });
        result.addFiltersList.forEach(filterToAdd => {
          this.filters.addFilter(filterToAdd);
          if (this.sessionSettingsPageName) {
            this.saveFiltersState();
          }
        });
        this.isSettingsShown = false;
        this.searchButton.focus();
      });
  }

  addFilterClicked() {
    const emptyFilter = {
      criterium: {
        key: undefined,
        operation: undefined,
        value: '',
      },
    };

    this.dialogService
      .open(FilterCardAddFilterComponent, {
        data: {
          filter: this.lastRemovedFilter || emptyFilter,
          filtersConfig: this.filtersConfig,
          autocompleteValueChanged: this.autocompleteValueChanged,
          filterUITypes: this.filterUITypes,
          operationsListTranslationMap: this.operationsListTranslationMap,
        },
        minWidth: '25vw',
        restoreFocus: false,
      })
      .afterClosed()
      .pipe(filter((filterToAdd: Filter) => !!filterToAdd))
      .subscribe((filterToAdd: Filter) => {
        this.searchButton.focus();
        this.lastRemovedFilter = undefined;
        this.filters.addFilter(filterToAdd);
      });
  }

  refreshAutocompleteValues(filterIndex: number, searchValue: string) {
    this.autocompleteValueChanged.next({
      filterIndex: filterIndex,
      searchValue: searchValue,
    });
  }

  translateFilterKeys() {
    const translationKeys: string[] = [];

    Object.keys(this.filtersConfig).forEach((key: string) =>
      translationKeys.push(this.filtersConfig[key].nameTranslationKey)
    );
    this.translateService.get(translationKeys).subscribe(translations => {
      Object.keys(this.filtersConfig).forEach(key => {
        this.filtersConfig[key].translatedKey =
          translations[this.filtersConfig[key].nameTranslationKey];
      });
    });
  }

  drop(event: CdkDragDrop<any>) {
    const prevIndex = event.item.data;
    const currentIndex = event.container.data;

    if (prevIndex !== currentIndex) {
      [this.filterList[currentIndex], this.filterList[prevIndex]] = [this.filterList[prevIndex], this.filterList[currentIndex]];
      this.filters.setFilters(this.filterList);
      if (this.sessionSettingsPageName) {
        this.saveFiltersState();
      }
    }
  }

  toggleReorderMode() {
    this.reorderMode = !this.reorderMode;
  }

  matSelectOpenedChange(opened: boolean, index: number) {
    if (opened) {
      this.activeFilterIndex = index;
      this.currentFilterValues = this.filterList[index].filterValues;
    } else {
      this.activeFilterIndex = undefined;
      this.filterList[index].filterValues = this.currentFilterValues;
    }
  }

  removeFilterOrValue(event, filterIndex) {
    event.stopPropagation();

    const filter = this.filterList[filterIndex];
    const filterValue = filter.criterium.value;
    const isMultiselect = filter.config.type === FilterUIType.MULTISELECT || filter.config.type === FilterUIType.MULTISELECT_AUTOCOMPLETE;

    if (filterValue && filterValue !== '' && !(Array.isArray(filterValue) && filterValue.length === 0)) {
      filter.criterium.value = isMultiselect ? [] : '';
      this.applyFilters();
    } else {
      this.removeFilter(filterIndex);
    }
  }

  removeFilter(filterIndex) {
    const removedFilter = this.filters.removeFilter(filterIndex);
    this.lastRemovedFilter = removedFilter;
    if (this.sessionSettingsPageName) {
      this.saveFiltersState();
    }
  }

  applyFilters() {
    if (this.sessionSettingsPageName) {
      this.saveFiltersState();
    }
    this.filtersApplied.emit(this.filters.getFiltersForAPICall());
  }

  clearAllFilters() {
    this.filters.clearAllFilters();
    this.applyFilters();
  }

  clearAllFilterValues() {
    this.filters.clearAllFilterValues();
    this.applyFilters();
  }

  onFilterChanges(filterList: Filter[]) {
    this.filterList = filterList;
  }

  saveFiltersState() {
    // TODO: Cache filter values
    const filtersCriterium = this.filters.filters.map(entry => entry.criterium);

    if (
      filtersCriterium.length < 1 ||
      (filtersCriterium.length === 1 &&
        (!filtersCriterium[0].key && !filtersCriterium[0].operation))
    ) {
      this.sessionService.removeFilterSettings(
        this.sessionSettingsPageName,
        this.isShown
      );
    }
    this.userPagePreferences.filters.config = filtersCriterium;
    this.userPagePreferences.filters.isShown = this.isShown;
    this.userSettings.addPagePreferences(
      this.sessionSettingsPageName,
      this.userPagePreferences
    );
    this.sessionService.setFilterSettings(
      this.sessionSettingsPageName,
      this.userSettings
    );
  }

  refreshFilterValues(filterIndex: number) {
    const filterValues = this.filterList[
      filterIndex
    ].config.valueListService.getFilterValues$();
    this.handleNewFilterValues(filterValues, filterIndex);
  }

  handleNewFilterValues(
    filterValues: FilterValue[] | Observable<FilterValue[]>,
    filterIndex: number
  ) {
    if (filterValues instanceof Observable) {
      filterValues.subscribe((filterValueList: FilterValue[]) => {
        this.filterList[filterIndex].filterValues = filterValueList;
      });
    } else {
      this.filterList[filterIndex].filterValues = filterValues;
    }
  }

  mapValueToDisplayText(filterValue: FilterValue) {
    return filterValue ? filterValue.displayText : '';
  }

  showDatepicker(event: any, datepicker: MatDatepicker<any>) {
    datepicker.open();
  }

  hideDatepicker(event: any, datepicker: MatDatepicker<any>) {
    datepicker.close();
  }

  toggleDatepicker(event: any, datepicker: MatDatepicker<any>) {
    datepicker.opened ? datepicker.close() : datepicker.open();
  }

  applyFiltersPreferences(filtersPreferences) {
    filtersPreferences.forEach((preference, index) => {
      if (
        this.filterList.length > 0 &&
        this.filterList[0].criterium.key === undefined
      ) {
        // Fill the initial empty filter
        this.filterList[index].config = this.filtersConfig[preference.key];
        this.filterList[index].criterium = preference;
      } else {
        // Add new filter
        const newFilter = {
          config: this.filtersConfig[preference.key],
          criterium: preference,
        };
        this.filterList.push(newFilter);
        this.filters.addFilter(newFilter);
      }

      this.onFilterChanges(this.filterList);
    });
  }

  removeChipSelection(filterIndex: number, filterValueIndex: number) {
    const chipsSelected = <any[]>this.filterList[filterIndex].criterium.value;
    chipsSelected.splice(filterValueIndex, 1);
  }

  chipOptionSelected(filterIndex: number, event: MatAutocompleteSelectedEvent) {
    const valueInput: ElementRef = this.valueInputs.toArray()[filterIndex];
    this.renderer.setProperty(valueInput.nativeElement, 'value', '');
    this.filterList[filterIndex].filterValues = [];
    const chipsSelected = <any[]>this.filterList[filterIndex].criterium.value;
    chipsSelected.push(event.option.value);
  }

  applyUserPreferences() {
    let filtersPreferences = this.sessionService.getFilterSettings(
      this.sessionSettingsPageName
    );
    if (
      filtersPreferences &&
      filtersPreferences.config &&
      filtersPreferences.config.length === 0 &&
      this.initialFilters
    ) {
      filtersPreferences.config = this.initialFilters;
    } else if (
      !filtersPreferences ||
      (filtersPreferences && !filtersPreferences.config)
    ) {
      filtersPreferences.config = this.initialFilters || [];
    }
    if (this.sessionSettingsPageName && filtersPreferences.config) {
      // this.isShown = filtersPreferences.isShown;
      if (filtersPreferences.config.length > 0) {
        filtersPreferences = this.filterExistingFilters(filtersPreferences);
        this.applyFiltersPreferences(filtersPreferences.config);
      }
      this.filtersApplied.emit(this.filters.getFiltersForAPICall());
      setTimeout(() => {
        this.setShownFilters.emit(
          filtersPreferences.config ? true : filtersPreferences.isShown
        );
      });

      filtersPreferences.config.forEach((filterPref, index) => {
        if (
          !!this.filterList[index].config &&
          (this.filterList[index].config.type === FilterUIType.MULTISELECT ||
            this.filterList[index].config.type === FilterUIType.DROPDOWN)
        ) {
          this.refreshFilterValues(index);
        }
      });
    } else {
      // TODO: get filters with api call and save them in the storage
    }
  }

  filterValueChanged(searchStr) {
    if (this.filterList[this.activeFilterIndex]) {
      this.filterList[
        this.activeFilterIndex
      ].filterValues = this.currentFilterValues.filter(value => {
        return value.displayText
          .toLowerCase()
          .includes(searchStr.toLowerCase());
      });
    }
  }
  filterExistingFilters(filtersPreferences) {
    if (
      filtersPreferences &&
      filtersPreferences.config
    ) {
      const existingFilters = filtersPreferences.config.filter(preference => this.filtersConfig.hasOwnProperty(preference.key));
      if (existingFilters.length < filtersPreferences.config.length) {
        filtersPreferences.config = existingFilters;
      }
    }
    return filtersPreferences;
}

  ngOnDestroy() {
    this._unsubscribe.next();
    this._unsubscribe.complete();
    this.autocompleteValueChangedSubscription.unsubscribe();
  }
}
