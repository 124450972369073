import { filter, mergeMap, debounceTime, takeUntil, pairwise, distinctUntilChanged, map } from 'rxjs/operators';
import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  AfterViewChecked,
  Renderer2,
  ChangeDetectorRef,
  OnDestroy,
} from '@angular/core';
import { forkJoin, BehaviorSubject, Subject, timer } from 'rxjs';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators, AbstractControl,
  UntypedFormControl,
} from '@angular/forms';
import { Client } from '../../core/types/client';
import { Product } from '../../core/types/product';
import { ProductService } from '../../core/services/product.service';
import { ProductPlanService } from '../../core/services/product-plan.service';
import { SearchOperations } from '../../core/types/search-criterium';
import { CreditCalculatorTemplateCode } from '../../core/types/credit-calculator-template-code';
import { TranslateService } from '@ngx-translate/core';
import { ProductPlanEntry } from '../../core/types/product-plan-entry';
import { Credit } from '../../core/types/credit';
import { Application } from '../../core/types/application-type';
import {
  SearchDirection,
  SearchOptions,
} from '../../core/types/search-options';
import { Page } from '../../core/types/page';
import { of } from 'rxjs';
import { ProductPlanTypes } from '../../core/types/product-plan-types';
import { MatTooltipDefaultOptions, MAT_TOOLTIP_DEFAULT_OPTIONS } from '@angular/material/tooltip';
import { CreditService } from '@app/core/services/credit.service';
import { Utils } from '@app/core/utils/utils';
import * as moment from 'moment/moment';
import { NotificationService } from '@app/core/services/notification.service';
import { InsurancePlanCacheService } from '@app/shared/cache/insurance-plans.cache';
import { InsuranceErrors } from '@app/core/types/insurance';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { ItfgDateAdapter } from '@app/core/adapters/date-adapter.service';
const customTooltipOptions: Partial<MatTooltipDefaultOptions> = {
  disableTooltipInteractivity: true,
};

const MY_DATE_FORMATS = {
  parse: {
    dateInput: 'DD.MM.YYYY',
  },
  display: {
    dateInput: 'DD.MM.YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'itfg-credit-calculator-form',
  templateUrl: './credit-calculator-form.component.html',
  styleUrls: ['./credit-calculator-form.component.scss'],
  providers: [
    { provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: customTooltipOptions },
    { provide: DateAdapter, useClass: ItfgDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS },
  ],
})
export class CreditCalculatorFormComponent
  implements OnChanges, OnInit, AfterViewChecked, OnDestroy {
  @Input() client: Client;
  @Input() creditCalculatorTemplateCode: CreditCalculatorTemplateCode;
  @Input() disableAll: boolean = false;
  @Input() disableSliders: boolean;
  @Input() disableDropdown: boolean;
  @Input() setProductAsInvalid: boolean;
  @Input() credit: Credit;
  @Input() minAllowedPrincipal = 0;
  @Input() creditToCopy: Credit;
  @Input() loyaltyPointsAvailableNumberInput: number;
  @Input() isInsuranceCheckboxChecked: boolean = false;
  @Output() selectedProductPlan = new EventEmitter<ProductPlanEntry>();
  @Output() selectedProductType = new EventEmitter<ProductPlanTypes>();
  @Output() selectedLoyaltyPoints = new EventEmitter<number>();
  @Output() isSaveButtonDisabled = new EventEmitter<boolean>();
  @Output() onFetchingInsuranceError = new EventEmitter<boolean>();
  @Input() application: Application;
  @ViewChild('principalInputRef') principalInputRef: ElementRef;
  @ViewChild('periodInputRef') periodInputRef: ElementRef;
  @ViewChild('loyaltyPointsInputRef') loyaltyPointsInputRef: ElementRef;
  @ViewChild('birthDateInputRef') birthDateInputRef: ElementRef;

  chosenProductPlan: ProductPlanEntry;
  calculatorForm: UntypedFormGroup;
  AllProducts: Product[] = [];
  productList: Product[] = [];
  productPlanList: ProductPlanEntry[];
  productPlan: ProductPlanEntry;
  // productPrincipalMin: number = 0;
  // productPrincipalMax: number = 0;
  // productPrincipalStep: number = 0;
  productPeriodsNumberMin: number = 0;
  productPeriodsNumberMax: number = 0;
  productPeriodsNumberStep: number = 0;
  creditCalculatorTemplateCodes: typeof CreditCalculatorTemplateCode;
  periodInstallment: number;
  productPeriodsSelectedValue: number;
  selectedPrincipalIndex: number;
  loyaltyPointsAvailableNumber = 0;
  maxLoyaltyPoints = 0;
  minLoyaltyPoints = 0;
  columns: any[];
  productUnitTranslation: string;
  installmentUnitTranslation: string;
  refinanceThresholdPlan: ProductPlanEntry;
  isClone: boolean;
  isRefinance: boolean;
  copyIsFromADifferentSource: boolean;
  isDisabled = false;
  productPlans$ = new BehaviorSubject([]);
  _allProductPlans: ProductPlanEntry[];
  uniquePrincipalValues: any[] = [];
  calculatorValues: any = {
    min: 0,
    max: 0,
    step: 0,
  };
  productPlanMapByPrice: any = {};
  productPlanValues: any = {
    amountStep: 0,
    periodFrom: 0,
    periodTo: 0,
    periodStep: 0,
    periodInstallment: 0,
  };
  productTypeMap: any;
  editPrincipal: boolean = false;
  editPeriod: boolean = false;
  editLoyaltyPoints: boolean = false;
  productTypes: typeof ProductPlanTypes = ProductPlanTypes;
  insuranceAmount: number;
  currentDate: Date = new Date();
  defaultDate: Date = new Date(2000, 0, 1);;
  originalProductPlan: ProductPlanEntry | null = null;
  isDragging: boolean = false;
  isFetchingInsurance: boolean = false;
  birthDateCtrl: UntypedFormControl = new UntypedFormControl({ value: this.defaultDate, disabled: true });
  _unsubscribe: Subject<void> = new Subject<void>();

  get allProductPlans(): ProductPlanEntry[] {
    return this._allProductPlans;
  }

  set allProductPlans(plans: ProductPlanEntry[]) {
    this._allProductPlans = plans;
  }
  matchingApplicationPlan: ProductPlanEntry;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private productService: ProductService,
    private productPlanService: ProductPlanService,
    private translate: TranslateService,
    private renderer2: Renderer2,
    private creditService: CreditService,
    private notifications: NotificationService,
    private insurancePlanCache: InsurancePlanCacheService,
    private cdr: ChangeDetectorRef,
  ) {
    this.calculatorForm = this.createCalculatorForm();
    this.periodInstallment = 0;
    this.creditCalculatorTemplateCodes = CreditCalculatorTemplateCode;
    this.productPeriodsSelectedValue = 0;
    this.selectedPrincipalIndex = 0;
    this.columns = [];
    this.productPlan = {};
    this.productUnitTranslation = '';
  }

  ngOnInit() {
    if (!this.client) {
      this.getProducts()
        .pipe(takeUntil(this._unsubscribe))
        .subscribe(([productPage, productPlansPage]) => {
          this.processProducts(productPage, productPlansPage);
        });
    }

    this.checkAndDisableFormConrols();
    this.setDataTable();
    this.watchForFormChanges();
    this.watchForInsuranceChanges();
  }

  processProducts(productPage, productPlansPage) {
    this.productList = productPage.content.sort((a, b) => a.id - b.id);
    this.AllProducts = productPage.content.sort((a, b) => a.id - b.id);
    this.allProductPlans = productPlansPage.content;
    this.productTypeMap = this.buildProductTypeMap(
      this.productList || this.AllProducts
    );

    if (this.client && this.client.id) {
      this.filterProductsByBrand(this.client.brand.id);
    }
  }

  checkAndDisableFormConrols() {
    if (this.disableAll || this.disableDropdown) {
      this.calculatorForm.controls['product'].disable();
    }
  }

  setDataTable() {
    this.translate
      .get(['global.installment', 'global.returnAmount', 'plan.apr'])
      .pipe(takeUntil(this._unsubscribe))
      .subscribe(translation => {
        this.columns = [
          {
            name: 'installmentAmount',
            label: translation['global.installment'],
          },
          {
            name: 'totalReturnAmount',
            label: translation['global.returnAmount'],
            format: v => (v ? v.toFixed(2) : null),
          },
          { name: 'product.apr', label: translation['plan.apr'] },
        ];
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.application && this.application && this.application.id) {
      this.setApplicationParams();
    }
    this.setCalculatorMode();
    const hasMinAllowedPrincipal =
      changes.minAllowedPrincipal &&
      this.minAllowedPrincipal &&
      this.credit &&
      this.credit.id;
    const isClone =
      changes.credit &&
      this.credit &&
      this.credit.id &&
      this.creditCalculatorTemplateCode ===
      this.creditCalculatorTemplateCodes.CLONE_CALCULATOR;
    this.creditCalculatorTemplateCodes.CLONE_CALCULATOR;

    if (hasMinAllowedPrincipal && this.isRefinance) {
      this.selectProduct(this.creditToCopy.product.id);
      this.getAndSetProductPlan(this.creditToCopy.product.id);
    }

    if (isClone) {
      this.getAndSetProductPlan(this.credit.product.id);
      this.productPeriodsSelectedValue =
        this.periodInstallment !== 0
          ? this.credit.installmentsNumber
          : this.credit.installmentDays;
      this.selectProduct(this.creditToCopy.product.id);
    }

    if (changes.client && this.client && this.client.id) {
      this.getProducts()
        .pipe(takeUntil(this._unsubscribe))
        .subscribe(([productPage, productPlansPage]) => {
          this.processProducts(productPage, productPlansPage);
        });

      const clientBirthDate = Utils.convertCivilIdToBirthDate(this.client.civilId);
      this.birthDateCtrl.setValue(clientBirthDate);
    }

    if (this.setProductAsInvalid) {
      this.calculatorForm.controls['product'].markAsTouched();
    }

    if (changes.loyaltyPointsAvailableNumberInput) {
      this.setValidatorsForLoyaltyPoints();
    }

    if (changes.isInsuranceCheckboxChecked) {
      this.calculatorForm.get('insuranceEnabled').setValue(this.isInsuranceCheckboxChecked);
    }
  }

  ngAfterViewChecked() {
    if (this.principalInputRef) {
      this.renderer2
        .selectRootElement(this.principalInputRef.nativeElement)
        .focus();
    }
    if (this.periodInputRef) {
      this.renderer2
        .selectRootElement(this.periodInputRef.nativeElement)
        .focus();
    }
    if (this.loyaltyPointsInputRef) {
      this.renderer2
        .selectRootElement(this.loyaltyPointsInputRef.nativeElement)
        .focus();
    }
  }

  onPrincipalEdit = (inputStr: string) => {
    const input = Number(inputStr.replace(',', '.'));
    const newPrincipal = this.getNearestValue(
      input,
      this.uniquePrincipalValues
    );
    const newPrincipalIndex = this.uniquePrincipalValues.findIndex(
      x => x === newPrincipal
    );

    this.selectedPrincipalIndex = newPrincipalIndex;
    this.productPrincipalChanges({ value: newPrincipalIndex });
    this.editPrincipal = false;
  };

  getNearestValue = (target: number, options: number[]) => {
    let nearestValue = options[0];
    let minDifference = Math.abs(target - nearestValue);

    options.forEach(option => {
      const currDifference = Math.abs(target - option);

      if (currDifference < minDifference) {
        nearestValue = option;
        minDifference = currDifference;
      }
    });

    return nearestValue;
  };

  onPeriodEdit = (inputStr: string) => {
    const input = Number(inputStr.replace(',', '.'));
    const selPrincipal = this.uniquePrincipalValues[
      this.selectedPrincipalIndex
    ];
    const { steps: periodSteps } = this.getPeriodRangeForAmount(selPrincipal);
    const newPeriod = this.getNearestValue(input, periodSteps);

    this.productPeriodsSelectedValue = newPeriod;
    this.productInstallmentsChanges({ value: newPeriod });
    this.editPeriod = false;
  };

  onLoyaltyPointsEdit = (inputStr: string) => {
    const input = Number(inputStr.replace(',', '.'));

    const newLoyaltyPoints = this.getNearestValue(input, Array.from({ length: this.maxLoyaltyPoints + 1 }, (_, i) => i));

    this.loyaltyPointsAvailableNumber = newLoyaltyPoints;
    this.calculatorForm.patchValue({
      loyaltyPoints: this.loyaltyPointsAvailableNumber,
    });
    this.editLoyaltyPoints = false;
  }

  filterProductsByBrand(brandId: number) {
    this.productList = this.AllProducts.filter(
      product => product.brand.id === brandId
    );
    this.calculatorForm.controls['product'].markAsUntouched();

    if (
      (this.productPlan.product &&
        this.productPlan.product.brand &&
        this.productPlan.product.brand.id &&
        this.client &&
        this.client.brand &&
        brandId &&
        this.productPlan.product.brand.id !== brandId) ||
      !this.productList.length
    ) {
      this.deleteSelectedValues();
    }
  }

  selectProduct(id: number) {
    this.calculatorForm.controls['product'].setValue(JSON.stringify(id));
  }

  setCalculatorMode() {
    this.isRefinance =
      this.creditCalculatorTemplateCode ===
      this.creditCalculatorTemplateCodes.REFINANCE_CALCULATOR;
    this.isClone =
      this.creditCalculatorTemplateCode ===
      this.creditCalculatorTemplateCodes.CLONE_CALCULATOR;
    this.copyIsFromADifferentSource =
      this.creditToCopy &&
      this.credit &&
      this.creditToCopy.id !== this.credit.id;
  }

  setApplicationParams(selectedProductType?: string) {
    if (!this.allProductPlans) {
      this.getProducts()
        .pipe(takeUntil(this._unsubscribe))
        .subscribe(([productPage, productPlansPage]) => {
          const matchingPlan = this.findBestCreditPlan(
            this.application.principal,
            this.application.period,
            productPlansPage.content,
            selectedProductType ? selectedProductType : this.application.product
          );
          this.matchingApplicationPlan = matchingPlan;
          this.selectProduct(this.matchingApplicationPlan.product.id);
          this.getAndSetProductPlan(this.matchingApplicationPlan.product.id);
        });
    } else {
      const matchingPlan = this.findBestCreditPlan(
        this.application.principal,
        this.application.period,
        this.allProductPlans,
        selectedProductType ? selectedProductType : this.application.product
      );
      this.matchingApplicationPlan = matchingPlan;
      this.selectProduct(this.matchingApplicationPlan.product.id);
      this.getAndSetProductPlan(this.matchingApplicationPlan.product.id);
    }
  }

  findBestCreditPlan(
    principal: number,
    period: number,
    productPlans: ProductPlanEntry[],
    productType?: string
  ) {
    let filteredProductPlans: ProductPlanEntry[];

    if (productType) {
      filteredProductPlans = productPlans.filter(
        productPlan => productPlan.product.code === productType
      );
    } else {
      filteredProductPlans = productPlans.filter(
        productPlan => productPlan.product.code !== ProductPlanTypes.MICRO
      );
    }

    if (period) {
      return this.productPlanService.findClosestMatchingPlanByPrincipalAndPeriod(
        principal,
        period,
        filteredProductPlans
      );
    } else {
      return this.productPlanService.findClosestMatchingPlanByPrincipal(
        principal,
        filteredProductPlans
      );
    }
  }

  getProducts() {
    const productFilter: SearchOptions = new SearchOptions({
      pageSize: 100,
      direction: SearchDirection.ASCENDING,
      criteria: [
        {
          key: 'active',
          operation: SearchOperations.EQUALS,
          value: true,
        },
      ],
    });

    if (this.client && this.client.id) {
      productFilter.addCriterium({
        key: 'brand.id',
        operation: SearchOperations.EQUALS,
        value: this.client.brand.id,
      });
    }

    return this.productService.getProductList(productFilter.getDTO()).pipe(
      mergeMap((productPage: Page<ProductPlanEntry>) => {
        const productIds = productPage.content.map(
          (product: Product) => product.id
        );
        const options: SearchOptions = new SearchOptions({
          pageSize: 100000,
          criteria: [
            {
              key: 'product.id',
              operation: SearchOperations.IN,
              value: productIds,
            },
          ],
        });
        return forkJoin(
          of(productPage),
          this.productPlanService.getProductPlanList(options.getDTO())
        );
      })
    );
  }

  deleteSelectedValues() {
    this.productPeriodsNumberMin = 0;
    this.productPeriodsNumberMax = 0;
    this.productPeriodsNumberStep = 0;
    this.productPeriodsSelectedValue = 0;
    this.selectedPrincipalIndex = 0;
    this.periodInstallment = 0;
    this.calculatorForm.patchValue({
      product: '',
      productPrincipal: '',
      productPeriodsNumber: '',
    });
    this.calculatorForm.controls['productPrincipal'].disable();
    this.calculatorForm.controls['productPeriodsNumber'].disable();
  }

  setValidatorsForLoyaltyPoints() {
    if (this.productPlan) {
      this.loyaltyPointsAvailableNumber = this.loyaltyPointsAvailableNumberInput;
      const loyaltyPointsControl: AbstractControl = this.calculatorForm.controls['loyaltyPoints'];
      this.loyaltyPointsAvailableNumber = loyaltyPointsControl.disabled ? 0 : this.loyaltyPointsAvailableNumber;
      this.maxLoyaltyPoints = this.loyaltyPointsAvailableNumber < 0 ? 0 : this.loyaltyPointsAvailableNumber;

      const totalForfeit = Math.trunc(this.productPlan.installmentForfeit * this.productPlan.installmentsNumber);

      if (this.maxLoyaltyPoints > totalForfeit) {
        this.maxLoyaltyPoints = totalForfeit;

      }
      if (this.creditCalculatorTemplateCode === this.creditCalculatorTemplateCodes.STAND_ALONE_CALCULATOR) {
        this.maxLoyaltyPoints = totalForfeit;
      }
      if (this.creditCalculatorTemplateCode !== this.creditCalculatorTemplateCodes.STAND_ALONE_CALCULATOR) {
        this.loyaltyPointsAvailableNumber = this.maxLoyaltyPoints;
      } else {
        this.loyaltyPointsAvailableNumber = this.minLoyaltyPoints;
      }
      this.calculatorForm.controls['loyaltyPoints'].setValidators([Validators.max(this.maxLoyaltyPoints), Validators.min(this.minLoyaltyPoints)]);
      this.cdr.detectChanges();
    }
  }

  findClosestStep(steps, currentlySelected) {
    return steps.reverse().reduce((a, b) => {
      return Math.abs(b - currentlySelected) < Math.abs(a - currentlySelected)
        ? b
        : a;
    });
  }

  loyaltyPointsChanges(event) {
    this.loyaltyPointsAvailableNumber = event.value;
    this.calculatorForm.patchValue({
      loyaltyPoints: this.loyaltyPointsAvailableNumber,
    });
  }

  productPrincipalChanges(event) {
    // Disables the slider from being dragged bellow the selected threshold in Refinance mode
    if (this.minAllowedPrincipal) {
      this.selectedPrincipalIndex = this.uniquePrincipalValues.indexOf(
        Math.max(
          this.refinanceThresholdPlan.principal,
          this.uniquePrincipalValues[event.value]
        )
      );
    } else {
      this.selectedPrincipalIndex = event.value;
    }

    const selectedAmount = this.uniquePrincipalValues[
      this.selectedPrincipalIndex
    ];
    let { min, max, steps } = this.getPeriodRangeForAmount(selectedAmount);
    const currentlySelected = this.productPeriodsSelectedValue;
    const stepIsAvailable: boolean = !!steps.find(s => s === currentlySelected);
    if (!stepIsAvailable) {
      const closestStep = this.findClosestStep(steps, currentlySelected);
      if (
        !this.productPeriodsSelectedValue ||
        this.productPeriodsSelectedValue === 0
      ) {
        this.productPeriodsSelectedValue = min;
      }
      this.productInstallmentsChanges({ value: closestStep });
      this.productInstallmentsChanges({ value: closestStep });
    }
    this.calculatorForm.patchValue({
      productPrincipal: this.selectedPrincipalIndex,
    });
    this.setValidatorsForLoyaltyPoints();
  }

  pricipalOneStepUp() {
    if (
      Number(this.calculatorForm.value.productPrincipal) <
      this.calculatorValues.max
    ) {
      this.productPrincipalChanges({ value: this.selectedPrincipalIndex + 1 });
    }
  }

  pricipalOneStepDown() {
    if (
      this.calculatorForm.value.productPrincipal > this.calculatorValues.min &&
      (!this.refinanceThresholdPlan ||
        this.uniquePrincipalValues[
        Number(this.calculatorForm.value.productPrincipal)
        ] > this.refinanceThresholdPlan.principal)
    ) {
      this.productPrincipalChanges({ value: this.selectedPrincipalIndex - 1 });
    }
  }

  loyaltyPointsStepUp() {
    if (
      this.calculatorForm.value.loyaltyPoints <
      this.maxLoyaltyPoints
    ) {
      this.loyaltyPointsAvailableNumber += 1;
      this.calculatorForm.patchValue({
        loyaltyPoints: this.loyaltyPointsAvailableNumber,
      });
    }
  }

  loyaltyPointsStepDown() {
    if (
      this.calculatorForm.value.loyaltyPoints > this.minLoyaltyPoints
    ) {
      this.loyaltyPointsAvailableNumber -= 1;
      this.calculatorForm.patchValue({
        loyaltyPoints: this.loyaltyPointsAvailableNumber,
      });
    }
  }

  productInstallmentsChanges(event) {
    this.updatePeriod(event.value);
    // this.calculatorForm.patchValue({ productPeriodsNumber: this.productPeriodsSelectedValue });
    // this.productPlan = this.getSelectedPlanFromMap(
    //   this.selectedPrincipalIndex,
    //   this.productPeriodsSelectedValue
    // );
    this.setValidatorsForLoyaltyPoints();
  }

  updatePeriod(selectedPeriod: number = this.productPeriodsSelectedValue) {
    const selectedPrincipal = this.uniquePrincipalValues[
      this.selectedPrincipalIndex
    ];
    const {
      min: minPeriod,
      max: maxPeriod,
      steps,
    } = this.getPeriodRangeForAmount(selectedPrincipal);
    // const illegalStep: boolean = !steps.includes(selectedPeriod);

    // if (illegalStep) {
    //   return;
    // }
    this.productPeriodsSelectedValue = this.findClosestStep(
      steps,
      selectedPeriod
    );
    // if (selectedPeriod < minPeriod) {
    //   this.productPeriodsSelectedValue = minPeriod;
    // } else if (selectedPeriod > maxPeriod) {
    //   this.productPeriodsSelectedValue = maxPeriod;
    // } else {
    //   this.productPeriodsSelectedValue = selectedPeriod;
    // }
    this.calculatorForm.patchValue({
      productPeriodsNumber: this.productPeriodsSelectedValue,
    });
  }

  periodsNumberOneStepUp() {
    let incrementNumber: number;
    const {
      min: minPeriod,
      max: maxPeriod,
      steps,
    } = this.getPeriodRangeForAmount(
      this.uniquePrincipalValues[this.selectedPrincipalIndex]
    );
    let currStep: number = steps.indexOf(
      this.calculatorForm.value.productPeriodsNumber
    );
    let nextStepIndex = Math.min(currStep + 1, steps.length - 1);
    incrementNumber = steps[nextStepIndex];

    this.productInstallmentsChanges({ value: incrementNumber });
    this.productInstallmentsChanges({ value: incrementNumber });

    this.productUnitTranslation =
      'global.' + this.productPlan.product.productPeriod.unit.toLowerCase();

    this.productUnitTranslation = this.getTranslation(
      this.productUnitTranslation,
      this.productPeriodsSelectedValue
    );
  }

  periodsNumberOneStepDown() {
    const {
      min: minPeriod,
      max: maxPeriod,
      steps,
    } = this.getPeriodRangeForAmount(
      this.uniquePrincipalValues[this.selectedPrincipalIndex]
    );
    let decrementNumber: number;
    let currStep: number =
      steps.indexOf(this.calculatorForm.value.productPeriodsNumber) || 0;
    let prevStepIndex = Math.max(currStep - 1, 0);
    decrementNumber = steps[prevStepIndex];

    this.productInstallmentsChanges({ value: decrementNumber });
    this.productInstallmentsChanges({ value: decrementNumber });
    this.productUnitTranslation =
      'global.' + this.productPlan.product.productPeriod.unit.toLowerCase();

    this.productUnitTranslation = this.getTranslation(
      this.productUnitTranslation,
      this.productPeriodsSelectedValue
    );
  }

  buildProductTypeMap(productList) {
    this.productTypeMap = {};
    productList.forEach(product => {
      this.productTypeMap[product.id] = product.code;
    });
    return this.productTypeMap;
  }

  productChanged(event) {
    if (event.value && this.application && this.application.id) {
      const productType = this.productTypeMap[event.value];
      this.setApplicationParams(productType);
    }

    if (event.value && !this.disableSliders && !this.disableAll) {
      this.isSaveButtonDisabled.emit(this.isDisabled);
      this.getAndSetProductPlan(event.value);
    }

    const productType = this.productTypeMap[event.value];
    this.selectedProductType.emit(productType);
  }

  watchForFormChanges() {
    this.calculatorForm.valueChanges.pipe(
      debounceTime(10),
      // distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
      pairwise(),
    ).subscribe(([old, value]) => {
      let productPlanMatches: ProductPlanEntry[];

      const onLoyaltyPointsChanged: boolean = old.loyaltyPoints !== value.loyaltyPoints;
      const onProductChangeToInstallments: boolean = old.product !== value.product && Number(value.product) === 2;
      const hasSelectedProductPlan = this.productPlanList && this.productPlanList.length && this.productPlan;

      if ((hasSelectedProductPlan && !this.isRefinance) || (!!this.isRefinance && !!this.refinanceThresholdPlan)) {
        if (this.productPlan.installmentsNumber > 1) {
          productPlanMatches = this.productPlanList.filter(plan => {
            const step = this.productPlan.installmentsNumber > 1 ? 1 : 0;
            const installmentsNumberMatch = plan.installmentsNumber === Number(value.productPeriodsNumber / step);
            const principalMatch = plan.principal === this.uniquePrincipalValues[Number(value.productPrincipal)];
            return installmentsNumberMatch && principalMatch;
          });
        } else if (this.productPlan.installmentsNumber === 1) {
          productPlanMatches = this.productPlanList.filter(plan => {
            const installmentDaysMatch = plan.installmentDays === Number(value.productPeriodsNumber);
            const principalMatch = plan.principal === this.uniquePrincipalValues[Number(value.productPrincipal)];
            return installmentDaysMatch && principalMatch;
          });
        }

        if (productPlanMatches.length > 0) {
          this.originalProductPlan = productPlanMatches[0];
          if (!onLoyaltyPointsChanged) {
            if (value.insuranceEnabled && !this.isDragging && Number(value.product) === 2 && this.originalProductPlan.product.code === ProductPlanTypes.INSTALLMENTS) {
              this.setProductPlanWithInsurance(onProductChangeToInstallments);
            } else {
              this.productPlan = this.originalProductPlan;
            }
          }


          this.setTranslations();
          this.selectedProductPlan.emit(this.originalProductPlan);
          this.selectedLoyaltyPoints.emit(this.loyaltyPointsAvailableNumber);
        }
      }
    });
  }

  setTranslations() {
    const AMOUNT_DIFFERENCE_TOLERANCE = 0.02;

    this.productPlan.totalReturnAmount =
      this.productPlan.installmentAmount *
      this.productPlan.installmentsNumber;
    if (Math.abs(this.productPlan.totalReturnAmount - this.productPlan.principal) <= AMOUNT_DIFFERENCE_TOLERANCE) {
      this.productPlan.totalReturnAmount = this.productPlan.principal;
    }
    this.productPlan.totalReturnAmountWithForfeit = (this.productPlan?.installmentAmount + this.productPlan?.installmentForfeit +
      this.productPlan?.installmentUtilizationFee) * this.productPlan?.installmentsNumber -
      this.loyaltyPointsAvailableNumber;

    if (Math.abs(this.productPlan.totalReturnAmountWithForfeit - this.productPlan.principal) <= AMOUNT_DIFFERENCE_TOLERANCE) {
      this.productPlan.totalReturnAmountWithForfeit = this.productPlan.principal;
    }

    this.productUnitTranslation =
      'global.' +
      this.productPlan.product.productPeriod.unit.toLowerCase();
    this.productUnitTranslation = this.getTranslation(
      this.productUnitTranslation,
      this.productPeriodsSelectedValue
    );
    this.installmentUnitTranslation = 'global.installment';
    this.installmentUnitTranslation = this.getTranslation(
      this.installmentUnitTranslation,
      this.productPlan.installmentsNumber
    );

    this.cdr.detectChanges();
  }

  getTranslation(translationUnit, value) {
    if (value === 1) {
      return translationUnit;
    } else {
      return (translationUnit += 's');
    }
  }

  getAndSetProductPlan(productId) {
    productId = Number(productId);
    this.productPlanService
      .getProductPlanForProduct(productId)
      .pipe(
        takeUntil(this._unsubscribe),
        filter(plan => plan !== null)
      )
      .subscribe(productPlan => {
        this.isDisabled = false;
        this.isSaveButtonDisabled.emit(this.isDisabled);
        this.productPlanList = productPlan;
        this.setRefinanceThresholdPlan(productPlan);
        this.productPlan = productPlan[0];
        this.originalProductPlan = this.productPlan;
        this.setProductPlanValues();
        this.setTranslations();
        this.selectedProductType.emit(this.productPlan.product.code);

        this.periodInstallment = this.productPlanValues.periodInstallment;
        this.productPeriodsNumberMin =
          this.periodInstallment !== 0
            ? this.productPlanValues.periodFrom
            : this.productPlan.installmentDays;
        this.productPeriodsNumberMax =
          this.periodInstallment !== 0
            ? this.productPlanValues.periodTo
            : productPlan[productPlan.length - 1].installmentDays;
        this.productPeriodsNumberStep =
          this.periodInstallment !== 0
            ? this.productPlanValues.periodStep
            : this.productPlan.installmentDays;

        this.calculatorForm.patchValue({
          productPrincipal: 0,
          productPeriodsNumber: this.productPeriodsNumberMin,
        });
        this.selectedPrincipalIndex = 0;

        // Choose min allowed period
        this.productPeriodsSelectedValue = this.productPeriodsNumberMin;

        if (!this.disableAll) {
          this.calculatorForm.controls['productPrincipal'].enable();
          this.calculatorForm.controls['productPeriodsNumber'].enable();
          this.calculatorForm.controls['loyaltyPoints'].enable();
        }

        if (this.isRefinance) {
          this.selectProductPlan(this.refinanceThresholdPlan);
        }

        if (this.creditProductSelected(productId, this.creditToCopy)) {
          this.setDefaultValues(this.creditToCopy, this.productPlanList);
        }

        const matchingPlanIsSelected =
          this.matchingApplicationPlan &&
          this.matchingApplicationPlan.product.id === productId;
        if (matchingPlanIsSelected && !this.isRefinance) {
          this.selectProductPlan(this.matchingApplicationPlan);
          this.updatePeriod(
            this.matchingApplicationPlan.installmentsNumber > 1
              ? this.matchingApplicationPlan.installmentsNumber
              : this.matchingApplicationPlan.installmentDays
          );
        }
        this.setValidatorsForLoyaltyPoints();
      });
  }

  onDragStarted(event: any, source?: string) {
    this.isDragging = true;
    if (this.calculatorForm.get('insuranceEnabled').value && source !== 'loyaltyPoints' && this.originalProductPlan && this.originalProductPlan.product?.code === ProductPlanTypes.INSTALLMENTS) {
      this.isFetchingInsurance = true;
    }
  }

  onDragEnded(event: any, source?: string) {
    if (this.isDragging) {
      setTimeout(() => {
        this.isDragging = false;

        if (this.originalProductPlan && this.originalProductPlan.product?.code === ProductPlanTypes.INSTALLMENTS && this.calculatorForm.get('insuranceEnabled').value && source !== 'period' && source !== 'loyaltyPoints') {
          this.setProductPlanWithInsurance();
        }

        if (source === 'period') {
          this.productInstallmentsChanges(event);
        }
      }, 10);
    }
  }



  getSelectedPlanFromMap(index: number, period: number) {
    const principal = this.uniquePrincipalValues[index];
    return this.productPlanMapByPrice[principal][period];
  }

  getPeriodRangeForAmount(amount: number) {
    const suitablePlans = this.productPlanList
      .filter(p => p.principal === amount)
      .sort((a, b) => {
        if (
          a.product.productPeriod.unit === 'DAY' &&
          b.product.productPeriod.unit === 'DAY'
        ) {
          return a.installmentDays - b.installmentDays;
        }

        return a.installmentsNumber - b.installmentsNumber;
      });

    let min, max, steps;
    if (suitablePlans[0].installmentsNumber > 1) {
      min = suitablePlans[0]?.installmentsNumber;
      max = suitablePlans[suitablePlans.length - 1]?.installmentsNumber;
      steps = suitablePlans.map(p => p.installmentsNumber);
    } else {
      min = suitablePlans[0]?.installmentDays;
      max = suitablePlans[suitablePlans.length - 1]?.installmentDays;
      steps = suitablePlans.map(p => p.installmentDays);
    }
    return { min, max, steps };
  }

  setProductPlanValues() {
    this.uniquePrincipalValues = [
      ...new Set(this.productPlanList.map(p => p.principal)),
    ];

    for (let plan of this.productPlanList) {
      const period =
        plan.installmentsNumber > 1
          ? plan.installmentsNumber
          : plan.installmentDays;

      if (!this.productPlanMapByPrice.hasOwnProperty(plan.principal)) {
        this.productPlanMapByPrice[plan.principal] = {};
      }

      if (!this.productPlanMapByPrice[plan.principal].hasOwnProperty(period)) {
        this.productPlanMapByPrice[plan.principal][period] = [];
      }
      this.productPlanMapByPrice[plan.principal][period] = plan;
    }

    this.calculatorValues = {
      min: 0,
      max: this.uniquePrincipalValues.length - 1,
      step: 1,
    };

    this.productPlanValues = {
      amountStep: this.uniquePrincipalValues.length,
      periodFrom: this.productPlan.installmentsNumber,
      periodTo: this.productPlanList[this.productPlanList.length - 1]
        .installmentsNumber,
      periodStep:
        this.productPlan.installmentsNumber > 1
          ? 1
          : this.productPlan.installmentDays,
      periodInstallment: this.productPlan.installmentsNumber > 1 ? 1 : 0,
    };
  }

  setDefaultValues(credit: Credit, productPlanList: ProductPlanEntry[]): void {
    const matchingProductPlan: ProductPlanEntry = this.productPlanService.findClosestMatchingProductPlan(
      credit,
      productPlanList
    );
    this.productPlan = matchingProductPlan;
    this.originalProductPlan = matchingProductPlan;
    this.selectedProductPlan.emit(this.originalProductPlan);
    this.selectedProductType.emit(this.originalProductPlan.product.code);
    const period: number =
      matchingProductPlan.installmentsNumber > 1
        ? matchingProductPlan.installmentsNumber
        : matchingProductPlan.installmentDays;

    let principal = matchingProductPlan.principal;
    // TODO: REFACTOR
    if (
      this.isRefinance &&
      !this.copyIsFromADifferentSource &&
      this.refinanceThresholdPlan
    ) {
      principal = this.refinanceThresholdPlan.principal;
    }
    if (this.isRefinance || this.isClone) {
      this.setPrincipalAndPeriod({
        principal,
        period,
      });

      if (this.isRefinance && this.refinanceThresholdPlan) {
        const { min, max } = this.getPeriodRangeForAmount(this.refinanceThresholdPlan.principal);
        if (period > max) {
          if (period > max) {
            this.updatePeriod(max);
          }
        }
      }
    }

    this.setTranslations();
  }

  creditProductSelected(productId: number, credit: Credit): boolean {
    return credit ? credit.product.id === productId : false;
  }

  setPrincipalAndPeriod({ principal = null, period = null }) {
    if (principal) {
      this.calculatorForm.controls['productPrincipal'].setValue(
        this.uniquePrincipalValues.indexOf(principal), { emitEvent: true }
      );
      this.selectedPrincipalIndex = this.uniquePrincipalValues.indexOf(
        principal
      );
    }

    if (period) {
      this.calculatorForm.controls['productPeriodsNumber'].setValue(period, { emitEvent: true });
      this.calculatorForm.controls['productPeriodsNumber'].setValue(period, { emitEvent: true });
      this.productPeriodsSelectedValue = period;
    }

  }

  setRefinanceThresholdPlan(productPlan) {
    if (this.isRefinance) {
      this.refinanceThresholdPlan = productPlan.find(
        e => e.principal - this.minAllowedPrincipal >= 0
      );
      if (!this.refinanceThresholdPlan) {
        // this.calculatorForm.controls['product'].disable();
        this.calculatorForm.controls['productPrincipal'].disable();
        this.calculatorForm.controls['productPeriodsNumber'].disable();
        this.isDisabled = true;
        this.isSaveButtonDisabled.emit(this.isDisabled);
      }
    }
  }

  selectProductPlan(targetProductPlan) {
    if (targetProductPlan) {
      this.productPrincipalChanges({
        value: this.uniquePrincipalValues.indexOf(targetProductPlan.principal),
      });
    }
  }

  setProductPlanWithInsurance(onProductChangeToInstallments?: boolean) {
    this.isFetchingInsurance = true;
    const birthDateCtrlValue = this.birthDateCtrl.value;

    if (!birthDateCtrlValue) {
      this.resetInsurancePlan('products.enterBirthDate');
      return;
    }

    const birthDate = moment(birthDateCtrlValue).format("YYYY-MM-DD");
    const maturePersonMaxBirthDate = moment().subtract(18, 'years').toDate();

    if (moment(birthDate).isAfter(maturePersonMaxBirthDate)) {
      this.resetInsurancePlan('products.enterValidBirthDate');
      return;
    }

    const principal = this.originalProductPlan.principal;
    const period = this.originalProductPlan.installmentsNumber;
    const productPlanId = this.originalProductPlan.id;
    const cacheKey = this.insurancePlanCache.generateCacheKey(productPlanId, birthDate, principal, period);

    if (this.insurancePlanCache.isCached(cacheKey)) {
      const cachedData = this.insurancePlanCache.getCachedInsurance(cacheKey);
      if (cachedData) {
        this.applyCachedPlan(cachedData);
        return;
      }
    }

    this.fetchAndApplyNewInsurancePlan(productPlanId, principal, period, birthDate, cacheKey, onProductChangeToInstallments);
  }

  resetInsurancePlan(error: any) {
    this.isFetchingInsurance = false;
    this.productPlan = this.originalProductPlan;
    this.insuranceAmount = 0;

    if (error.error[0]?.code) {
      this.notifications.showLocalizedErrorMessage({
        notificationText: this.translate.instant('products.euroinsErrors.') + error.error[0].code,
      })
    }

    if (error.error.message === InsuranceErrors.EUROINS_ERROR) {
      this.notifications.showLocalizedErrorMessage({
        notificationText: this.translate.instant('error.euroinsConnectionErrorMessage')
      })
    }
  }

  applyCachedPlan(cachedData: any) {
    this.isFetchingInsurance = false;
    this.productPlan = cachedData.insurancePlanData;
    this.insuranceAmount = cachedData.insuranceAmount;
    this.processInsurancePlan();
  }

  fetchAndApplyNewInsurancePlan(productPlanId: number, principal: number, period: number, birthDate: string, cacheKey: string, onProductChangeToInstallments?: boolean) {
    timer(onProductChangeToInstallments ? 350 : 0)
      .pipe(
        mergeMap(() => this.creditService.getProductPlanWithInsurance(productPlanId, birthDate)),
        takeUntil(this._unsubscribe),
        mergeMap((productPlanWithInsurance: any) => {
          this.productPlan = productPlanWithInsurance;
          return this.creditService.getInsuranceAmount({ principal, months: period, birthDate }).pipe(
            map(insuranceAmount => ({ insuranceAmount, productPlanWithInsurance }))
          );
        })
      )
      .subscribe(
        ({ insuranceAmount, productPlanWithInsurance }) => {
          this.isFetchingInsurance = false;
          this.insuranceAmount = insuranceAmount;
          this.processInsurancePlan();
          this.insurancePlanCache.storeInsurance(cacheKey, productPlanWithInsurance, insuranceAmount);
        },
        error => this.handleInsuranceError(error)
      );
  }
  handleInsuranceError(error: any) {
    this.resetInsurancePlan(error);
    this.calculatorForm.get('insuranceEnabled').setValue(false);
    this.isInsuranceCheckboxChecked = false;
    this.onFetchingInsuranceError.emit(true);
    this.setTranslations();
  }


  toggleInsurance() {
    const currentStatus = this.calculatorForm.get('insuranceEnabled').value
    this.calculatorForm.get('insuranceEnabled').setValue(!currentStatus);
  }

  processInsurancePlan() {
    this.selectedProductPlan.emit(this.originalProductPlan);
    this.setValidatorsForLoyaltyPoints();
    this.setTranslations();
  }

  createCalculatorForm(): UntypedFormGroup {
    const fb = this.formBuilder;

    return fb.group({
      product: ['', [Validators.required]],
      productPrincipal: [{ value: '', disabled: true }],
      productPeriodsNumber: [{ value: '', disabled: true }],
      loyaltyPoints: [''],
      insuranceEnabled: [false],
    });
  }

  watchForInsuranceChanges() {
    if (this.creditCalculatorTemplateCode === this.creditCalculatorTemplateCodes.STAND_ALONE_CALCULATOR) {
      this.calculatorForm.get('insuranceEnabled').valueChanges.subscribe((enabled: boolean) => {
        if (enabled) {
          if (!this.birthDateCtrl.value) {
            this.birthDateCtrl.setValue(this.defaultDate);
          }
          this.birthDateCtrl.enable();
          this.focusBirthDateInput();
        } else {
          this.birthDateCtrl.disable();
        }
      })
    }
  }

  focusBirthDateInput() {
    setTimeout(() => {
      this.birthDateInputRef.nativeElement.focus();
    }, 100)
  }

  ngOnDestroy() {
    this._unsubscribe.next();
    this._unsubscribe.complete();
  }
}
