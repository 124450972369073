import { COMMA, ENTER } from '@angular/cdk/keycodes';
import {
  Component,
  ElementRef,
  ViewChild,
  OnInit,
  Input,
  SimpleChanges,
  OnChanges,
  ViewContainerRef,
  OnDestroy,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent, MatAutocomplete, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { filter, map, takeUntil } from 'rxjs/operators';
import { Tag, TagTypeNames } from '../../../core/types/tag';
import { TagService } from '../../../core/services/tag.service';
import { DialogProviderService } from '@app/core/services/dialog-provider.service';
import { TranslateService } from '@ngx-translate/core';
import { CreditService } from '../../../core/services/credit.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorService } from '../../../core/services/error.service';
import { NotificationService } from '../../../core/services/notification.service';
import { ClientService } from '../../../core/services/client.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'itfg-tag-chips',
  templateUrl: './tag-chips.component.html',
  styleUrls: ['./tag-chips.component.scss'],
})
export class TagChipsComponent implements OnInit, OnChanges, OnDestroy {
  @Input() inputId: number;
  @Input() inputTags: Tag[];
  @Input() tagTypeName: string;
  @Input() isTagInCreditForm: boolean;
  public allTags: Tag[];
  public filteredTagList: Tag[];
  public maxTags: number;
  tagTypeNames: typeof TagTypeNames = TagTypeNames;
  _unsubscribe = new Subject<void>();



  id: number;
  tags;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  tagsCtrl = new UntypedFormControl();

  @ViewChild('tagInput') tagInput: ElementRef<
    HTMLInputElement
  >;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;
  @ViewChild(MatAutocompleteTrigger)
  autocompleteControl: MatAutocompleteTrigger;

  constructor(
    private credits: CreditService,
    private clients: ClientService,
    private notifications: NotificationService,
    private errorService: ErrorService,
    private tagService: TagService,
    private dialogService: DialogProviderService,
    private translate: TranslateService,
    private _viewContainerRef: ViewContainerRef
  ) {
    this.id = this.inputId;
    this.tags = this.inputTags;
    this.allTags = [];
    this.filteredTagList = [];
    this.maxTags = 3;
  }

  ngOnInit() {
    this.tagService.getTagList$()
      .pipe(takeUntil(this._unsubscribe))
      .subscribe(tagList => {
        this.allTags = tagList.content;
        this.updateFilterList();

        this.tagsCtrl.valueChanges
          .pipe(takeUntil(this._unsubscribe))
          .subscribe(value => {
            this.updateFilterList();
            this.filteredTagList =
              value !== null ? this._filter(value) : this.filteredTagList.slice();
          });
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.inputId) {
      this.id = this.inputId;
    }
    if (changes.inputTags) {
      this.tags = this.inputTags;
    }
  }

  add(event: MatChipInputEvent): void {
    // Add only when MatAutocomplete is not open
    // To make sure this does not conflict with OptionSelected Event
    if (!this.matAutocomplete.isOpen) {
      const input = event.input;
      const value = event.value;

      if ((value || '').trim()) {
        if (this.filteredTagList.filter(tag => tag.name === value).length > 0) {
          this.tags.push(
            this.filteredTagList.filter(tag => tag.name === value)
          );
        }
      }

      // Reset the input value
      if (input) {
        input.value = '';
      }

      this.tagsCtrl.setValue(null);
    }
  }

  focusInput(event) {
    this.tagInput.nativeElement.focus();
  }

  remove(tag: Tag): void {

    this.dialogService
      .openConfirm({
        message: this.translate.instant(
          'credits.removeTagConfirmationMessage',
          { tagName: tag.name }
        ),
        viewContainerRef: this._viewContainerRef,
        cancelButton: this.translate.instant('global.cancel'),
        acceptButton: this.translate.instant('global.confirm'),
      })
      .afterClosed()
      .pipe(
        takeUntil(this._unsubscribe),
        filter(accept => accept === true)
      )
      .subscribe((accept: boolean) => {
        if (this.tagTypeName === this.tagTypeNames.CREDIT) {
          const index = this.tags.indexOf(tag);
          if (this.isTagInCreditForm) {
            if (index >= 0) {
              this.tags.splice(index, 1);
            }
            this.updateFilterList();
            return;
          }
          this.tagService.untagCredits([this.id], tag.id)
            .pipe(takeUntil(this._unsubscribe))
            .subscribe(
              result => {
                this.notifications.showLocalizedSuccessMessage({
                  notificationText: 'credits.tagSuccessfullyRemoved',
                });


                if (index >= 0) {
                  this.tags.splice(index, 1);
                }
                this.tagService.onTagAddedOrDeleted.next(this.tags);
                this.updateFilterList();
              },
              (error: HttpErrorResponse) =>
                this.errorService.showSnackbarErrorMessage(error)
            );
        } else if (this.tagTypeName === this.tagTypeNames.CLIENT) {
          this.tagService.untagUsers([this.id], tag.id)
            .pipe(takeUntil(this._unsubscribe))
            .subscribe(
              result => {
                this.notifications.showLocalizedSuccessMessage({
                  notificationText: 'credits.tagSuccessfullyRemoved',
                });
                const index = this.tags.indexOf(tag);

                if (index >= 0) {
                  this.tags.splice(index, 1);
                }
                this.updateFilterList();
              },
              (error: HttpErrorResponse) =>
                this.errorService.showSnackbarErrorMessage(error)
            );
        }

      });
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.dialogService
      .openConfirm({
        message: this.translate.instant('credits.addTagConfirmationMessage', {
          tagName: event.option.value.name,
        }),
        viewContainerRef: this._viewContainerRef,
        title: this.translate.instant('global.confirm'),
        cancelButton: this.translate.instant('global.cancel'),
        acceptButton: this.translate.instant('global.confirm'),
      })
      .afterClosed()
      .pipe(
        takeUntil(this._unsubscribe),
        filter(accept => accept === true)
      )
      .subscribe((accept: boolean) => {
        this.tagService.tagCredits([this.id], event.option.value.id)
          .pipe(takeUntil(this._unsubscribe))
          .subscribe(
            tag => {
              this.tagInput.nativeElement.value = '';
              this.tagsCtrl.setValue(null);
              this.tagInput.nativeElement.blur();
              this.autocompleteControl.closePanel();
              this.tags.push(event.option.value);

              this.updateFilterList();
              this.notifications.showLocalizedSuccessMessage({
                notificationText: 'credits.tagSuccessfullyAdded',
              });
            },
            (error: HttpErrorResponse) =>
              this.errorService.showSnackbarErrorMessage(error)
          );
      });
  }

  private _filter(value) {
    const filterValue = value.name
      ? value.name.toLowerCase()
      : value.toLowerCase();

    return this.filteredTagList.filter(
      tag => tag.name.toLowerCase().indexOf(filterValue) === 0
    );
  }

  updateFilterList() {
    this.filteredTagList = this.allTags.filter(
      tag => !this.tags.map(t => t.name).includes(tag.name)
    );
  }

  ngOnDestroy() {
    this._unsubscribe.next();
    this._unsubscribe.complete();
  }
}
