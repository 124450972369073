import { Credit } from './credit';
import { Administrator } from './administrator';
import { Brand } from './brand';
import { Country } from './country';
import { Education } from './education';
import { EmploymentType } from './employment-type';
import { Partner } from './partner';

export class BaseApplication {
  id: number;
  civilId: string;
  credit?: Credit;
  email: string;
  firstName: string;
  middleName: string;
  lastName: string;
  mobile: string;
  operator?: Administrator;
  principal: number;
  source: ApplicationSource;
  createTime?: Date;
  brand: Brand;
  notes?: string;
  status: ApplicationStatus;
  bought: boolean;
  reference: string;
  period: number;
  idCardNumber: string;
  contactName: string;
  contactPhone: string;
  product?: string;
  partner?: Partner;

  constructor(partial: Partial<BaseApplication> = {}) {
    Object.assign(this, partial);
  }
}

export class Application extends BaseApplication {
  nationality: string;
  secondNationality: string;
  education: string;
  employmentStatus: string;

  constructor(partial: Partial<Application> = {}) {
    super(partial);
  }
}

export class ParsedApplication extends BaseApplication {
  nationality: Country;
  secondNationality: Country;
  education: Education;
  employmentStatus: EmploymentType;

  constructor(partial: Partial<ParsedApplication> = {}) {
    super(partial);
  }
}

export interface ApplicationSource {
  id: number;
  name: string;
}

export interface ApplicationStatus {
  id: number;
  name: string;
}

export enum ApplicationStatusNames {
  NEW = 'NEW',
  PROCESSING = 'PROCESSING',
  COMPLETED = 'COMPLETED',
}

export enum ApplicationErrorTypes {
  NOT_FOUND = "Няма намерени резултати",
}
