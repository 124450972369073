import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FilterValueListService, FilterValue } from '../filter/types';
import { TopicsService } from '@app/core/services/topics.service';
import { Topic, TopicNode } from '@app/core/types/topic';

@Injectable({
  providedIn: 'root'
})
export class TopicsValueListService implements FilterValueListService {
  constructor(private topicsService: TopicsService) {}

  getFilterValues$(): Observable<FilterValue[]> {
    return this.topicsService.getTree$().pipe(
      map(treeData => {
        let result: FilterValue[] = [];

        if (treeData.children && treeData.children.length > 0) {
          treeData.children.forEach((node: TopicNode) => this.extractValues(node, result));
        }
        return result;
      })
    );
  }

  extractValues(node: TopicNode, result: FilterValue[]): void {
    if (node.data) {
      result.push({
        value: node.data.code,
        displayText: node.data.name
      });
    }
    if (node.children && node.children.length > 0) {
      node.children.forEach(child => this.extractValues(child, result));
    }
  }
}
