import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatToBulgarianPhoneNumber'
})
export class FormatToBulgarianPhoneNumberPipe implements PipeTransform {

  transform(phoneNumber: string): string {
    if (!phoneNumber) return '';

    return phoneNumber.startsWith('0')
      ? `+359${phoneNumber.substring(1)}`
      : phoneNumber;
  }
}
