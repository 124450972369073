<div class="payment-promises">
  <div flex layout="row">
    <button
      [disabled]="!openedCredit || !canAddPaymentPromises"
      mat-stroked-button
      class="custom-button"
      (click)="openCreatePromiseDialog()"
    >
      <div layout="row" layout-align="space-between center">
        <span>Уговорки</span>

        <mat-icon fontSet="material-symbols-outlined">
          add_circle
        </mat-icon>
      </div>
    </button>
  </div>

  <div>
    <mat-list>
      <mat-list-item
        *ngFor="let promise of paymentPromises; let first = first"
        [ngClass]="{ first }"
      >
        <div flex layout="row" layout-align="space-between center">
          <div flex layout="row" layout-align="start center">
            <mat-icon
              fontSet="material-symbols-outlined"
              class="status-icon"
              [ngStyle]="{
                color: promiseStatusStyleMap[promise.status]?.color
              }"
              [matTooltip]="
                promise.status
                  | paymentPromiseStatusToTranslationKey
                  | translate
              "
              matTooltipPosition="left"
              >{{ promiseStatusStyleMap[promise.status]?.icon }}</mat-icon
            >

            <!-- matTooltip="{{ promise.operatorEmail }}" -->
            <span class="text" (click)="openPromiseEditDialog(promise)"
              >{{ promise.amount }} лв. на
              {{ promise.date | date: 'dd.MM.yy' }}</span
            >
          </div>

          <mat-icon
            fontSet="material-symbols-outlined"
            class="remove"
            (click)="deactivatePromise(promise)"
          >
            remove
          </mat-icon>
        </div>
      </mat-list-item>
    </mat-list>
  </div>
</div>
