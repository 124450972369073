<div class="margin-bottom-30px">
  <itfg-marital-status-report [inputClient]="inputClient"></itfg-marital-status-report>
</div>
<mat-divider></mat-divider>
<div class="margin-bottom-30px">
  <itfg-person-data-report [inputClient]="inputClient"></itfg-person-data-report>
</div>
<mat-divider></mat-divider>
<div class="margin-bottom-30px">
  <itfg-address-report [inputClient]="inputClient"
                       [reportType]="ReportTypes.PERMANENT_ADDRESS"></itfg-address-report>
</div>
<mat-divider></mat-divider>
<div class="margin-bottom-30px">
  <itfg-address-report [inputClient]="inputClient"
                       [reportType]="ReportTypes.TEMPORARY_ADDRESS"></itfg-address-report>
</div>



