export interface BlacklistType {
  id: number;
  name: BlacklistTypeName;
}

export enum BlacklistTypeName {
  CIVIL = 'CIVIL',
  EMPLOYER = 'EMPLOYER',
  PHONE = 'PHONE',
}
