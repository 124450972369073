<div class="dialog-container"
     style="margin-bottom: 15px">
  <div
    flex
    layout="row"
    layout-align="space-between center"
  >
    <span class="modal-dialog-title">Добави банкова сметка</span>
    <button matTooltip="{{ 'global.close' | translate }}" matTooltipPosition="above" tabindex="-1" mat-icon-button (click)="close()">
      <mat-icon>clear</mat-icon>
    </button>
  </div>
</div>
<form [formGroup]="bankAccountForm"
      (ngSubmit)="onSubmit($event)"
      novalidate>
  <mat-form-field class="margin-right-2 full-width">
    <input matInput type="text"
           (paste)="trimEmptyChars(bankAccountForm.controls['bankAccount'], $event)"
           formControlName="bankAccount"
           placeholder="{{
             (bankAccountForm.controls['bankAccount'].value | formatIban: 'bankIdCode')
             !== bankAccountForm.controls['bankAccount'].value ?
             (bankAccountForm.controls['bankAccount'].value | formatIban: 'bankIdCode') : ('clients.ibanBank' | translate)
            }}"
           >
      <mat-label>{{
             (bankAccountForm.controls['bankAccount'].value | formatIban: 'bankIdCode')
             !== bankAccountForm.controls['bankAccount'].value ?
             (bankAccountForm.controls['bankAccount'].value | formatIban: 'bankIdCode') : ('clients.ibanBank' | translate)
            }}</mat-label>
    <mat-error *ngIf="bankAccountForm.controls['bankAccount'].errors"
               [translate]="'forms.invalidFormat'">Invalid format
    </mat-error>
  </mat-form-field>
  <div layout="row" layout-align="end">
    <button mat-raised-button type="submit">
      {{ 'global.save' | translate }}
    </button>
  </div>
</form>