import { ChangeDetectorRef, Component, OnInit, ViewContainerRef } from '@angular/core';
import { IdentityReportsService } from '../../../../app/core/services/identity-reports.service';
import { UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ErrorService } from '../../../../app/core/services/error.service';
import { ClientService } from '../../../../app/core/services/client.service';
import { TranslateService } from '@ngx-translate/core';
import { IdentifierValidation } from '../../../../app/core/validation/identifier-validation';
import { DialogProviderService } from '@app/core/services/dialog-provider.service';
import { CcrReportComponent } from '../ccr-report/ccr-report.component';
import { CcrService } from '@app/core/services/ccr.service';
import { ToCustomItfgAmountPipe } from '../pipes/to-custom-itfg-amount.pipe';
import {NotificationService} from '@core/services/notification.service';

@Component({
  selector: 'itfg-ccr-standalone',
  templateUrl: './ccr-standalone.component.html',
  styleUrls: ['./ccr-standalone.component.scss']
})
export class CcrStandaloneComponent extends CcrReportComponent implements OnInit {

  civilId: UntypedFormControl = new UntypedFormControl(null, [
    Validators.minLength(0),
    Validators.maxLength(10),
    IdentifierValidation.onlyNumbers,
    IdentifierValidation.isValidEgn,
  ]);

  showTable = false;
  client: any = {
    id: 1,
    civilId: null,
  };

  isReport = true;

  constructor(
    translate: TranslateService,
    ccrService: CcrService,
    identityReportsService: IdentityReportsService,
    notificationService: NotificationService,
    formBuilder: UntypedFormBuilder,
    dialogService: DialogProviderService,
    _viewContainerRef: ViewContainerRef,
    route: ActivatedRoute,
    router: Router,
    clientService: ClientService,
    errorService: ErrorService,
    toCustomItfgAmount: ToCustomItfgAmountPipe,
    cdr: ChangeDetectorRef
  ) {
    super(
      translate,
      ccrService,
      identityReportsService,
      notificationService,
      dialogService,
      _viewContainerRef,
      route,
      router,
      clientService,
      errorService,
      toCustomItfgAmount,
      cdr
    );
  }

  ngOnInit(): void {
    this.civilId.valueChanges.pipe(
    ).subscribe(value => {
      if (this.civilId.valid === true && !!value) {
        this.showTable = true;
        this.client = {
          ...this.client,
          civilId: value,
        };
      } else {
        this.showTable = false;
      }
    });
  }

}
