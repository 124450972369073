<mat-card-header
  flex
  layout-align="space-between end"
  class="edit-note-header"
  layout="row"
>
  <mat-card-title
    class="edit-screen-card-title"
    [translate]="'historyLog.note.editNote'"
  >
    Add contact
  </mat-card-title>
  <button tabindex="-1" mat-icon-button (click)="onNoClick()">
    <mat-icon>clear</mat-icon>
  </button>
</mat-card-header>
<div *ngFor="let note of notes">
  <h4>Дата на създаване: {{ note.createdAt | itfgDateFormat }}</h4>
  <itfg-note [noteToEdit]="note" [mode]="NoteMode.EDIT"></itfg-note>
</div>
