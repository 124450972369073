import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { RequestService } from './request.service';
import { Observable, Subject } from 'rxjs';
import { Page } from '../types/page';
import { Sms, DeserializedSms } from '../types/sms';
import { ClientCommunicationRequestService } from './client-communication-request.service';
import { SmsTemplateCode } from '../types/sms-template-code';
import * as moment from 'moment';
export interface sendSmsDTO {
    clientId: number | null 
    smsTemplateCode: SmsTemplateCode   //Todo remove from here and export 
}

@Injectable()
export class SmsService {

  onSmsSend = new Subject<Sms>();

  constructor(
    private request: ClientCommunicationRequestService,
    ) {}

  // deserializeSms(smsToDeserialize: Sms): DeserializedSms {
  //   return {
  //     ...smsToDeserialize,
  //     sent_at:
  //       smsToDeserialize.sendTime && moment( new Date(smsToDeserialize.sendTime)).format('DD.MM.YYYY HH:mm' ),
  //   };
  // }

  serializeSms(smsToSerialize: DeserializedSms): Sms {
    return {
      ...smsToSerialize,
      sent_at: new Date(smsToSerialize.sent_at).toISOString(),
    };
  }

  getSmsList(options?: any): Observable<Page<DeserializedSms>> {
    return this.request.get(['sms'], options)
  }

  sendFreeTextSms( 
    userId: number,
    mobileNumber: string,
    smsBody: string
  ): Observable<Sms> {
    return this.request.post(['sms', 'free-text-sms'], {
      body: {
        userDTO:{  //todo add userDTO 
          id: userId,
          mobile: mobileNumber, 
        }, 
        text: smsBody
      },
    });
  }
  
  sendSms(dto: sendSmsDTO){ //: Observable<any> 
    // console.log(creditId,'creditId', smsTemplateCode, 'SmsTemplateCode')
    return this.request.post(['sms', 'send'], { 
      body: {client_id : dto.clientId},
      params: {code: dto.smsTemplateCode }
    });
  }

  
  sendMobileConfirmation(clientId){ //: Observable<any> 
    return this.request.post(['sms','send', 'mobile-confirmation'], { 
      body: {clientId},
    });
  }

  sendTemporaryPassword(clientId){ //: Observable<any> 
    return this.request.post(['sms','send', 'temporary-password'], { 
      body: {clientId},
    });
  }
  // resendSms(smsId: number, options?): Observable<Sms> {
  //   return this.request.post(['sms', smsId, 'resend']);   TODO: add forward
  // }

  getSmsPlaceholderList(smsTemplateBody: string): string[] {
    const placeholderList: string[] = [];
    let charFound = false;
    let currentPlaceholder = '';
    // tslint:disable-next-line:prefer-const
    for (let char of smsTemplateBody) {
      if (charFound && char !== '}') {
        currentPlaceholder += char;
      }
      if (char === '{') {
        charFound = true;
      }
      if (char === '}') {
        if (placeholderList.indexOf(currentPlaceholder) === -1) {
          placeholderList.push(currentPlaceholder);
        }
        currentPlaceholder = '';
        charFound = false;
      }
    }
    return placeholderList;
  }
}
