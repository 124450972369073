import { Component, OnInit } from '@angular/core';
import { NavigationInfoService } from '../../core/services/navigation-info.service';
import { BreadcrumbNode } from '../../core/types/breadcrumb-node';

@Component({
  selector: 'itfg-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss'],
})
export class BreadcrumbsComponent implements OnInit {
  breadcrumbs: BreadcrumbNode[];
  navigationInfo: NavigationInfoService;

  constructor(navigationInfo: NavigationInfoService) {
    this.navigationInfo = navigationInfo;
    this.navigationInfo.breadcrumbsChanged$.subscribe(
      (breadcrumbs: BreadcrumbNode[]) => {
        this.breadcrumbs = breadcrumbs;
      }
    );
  }

  ngOnInit() {
    this.breadcrumbs = this.navigationInfo.breadcrumbs;
  }
}
