import { Pipe, PipeTransform } from '@angular/core';
import { Administrator } from '../../core/types/administrator';

@Pipe({
  name: 'administratorFullName',
})
export class AdministratorFullNamePipe implements PipeTransform {
  transform(administrator: Administrator): string {
    return (
      administrator &&
      `${administrator.firstName.trim()} ${administrator.lastName.trim()}`
    );
  }
}
