import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'nssiContractReason'
})
export class NssiContractReasonPipe implements PipeTransform {
  static readonly ContractReasonMap: { [key: number]: string } = {
    1: 'безсрочен трудов договор по чл. 67, ал. 1, т. 1',
    7: 'трудов договор със срок на изпитване по чл. 70 от КТ',
    9: 'споразумение по чл. 107 от КТ във връзка с чл. 89 от КТ',
    10: 'допълнителен трудов договор по чл. 110 от КТ',
    11: 'допълнителен трудов договор по чл. 111 от КТ',
    12: 'трудов договор по чл. 114 от КТ',
    2: 'срочен трудов договор по чл. 68, ал. 1, т. 1 от КТ',
    3: 'трудов договор по чл. 68, ал. 1, т. 2 от КТ',
    4: 'трудов договор по чл. 68, ал. 1, т. 3 от КТ',
    5: 'трудов договор по чл. 68, ал. 1, т. 4 от КТ',
    6: 'трудов договор по чл. 68, ал. 1, т. 5 от КТ',
    8: 'споразумение по чл. 107 от КТ във връзка с чл. 83 от КТ',
    13: 'постановление по чл. 405а от КТ',
    14: 'трудов договор за ученичество по чл. 230 от КТ',
    15: 'трудов договор за вътрешно заместване по чл. 259 от КТ',
  };

  transform(reason: number): string {
    return NssiContractReasonPipe.ContractReasonMap[
      reason
      ];
  }

}
