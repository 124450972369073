<mat-card appearance="outlined">
  <mat-card-title>
    <span class="title">{{ 'ccr.standalonePageTitle' | translate }}</span>
  </mat-card-title>
  <mat-card-subtitle>
  </mat-card-subtitle>
  <mat-card-content>
    <form>
      <div layout="column" class="title" flex-gt-md="50">
        <mat-form-field
              class="white-space-no-wrap right-padding-16"
              [style.max-width.px]="300">
          <input
            matInput
            type="text"
            [formControl]="civilId"
            placeholder="{{ 'global.civilId' | translate }}"
          />
          <mat-error
          *ngIf="civilId.hasError('onlyNumbers') || civilId.hasError('minlength') || civilId.hasError('maxlength') || civilId.hasError('incorrectEgnFormat')"
          [translate]="'clients.invalidCivilId'">Invalid civil id
        </mat-error>
        </mat-form-field>
      </div>
    </form>
  </mat-card-content>
</mat-card>
<mat-card appearance="outlined" *ngIf="showTable">
  <mat-card-content>
    <div >
      <itfg-ccr-report [inputClient]="client" ></itfg-ccr-report>
    </div>
  </mat-card-content>
</mat-card>
<div *ngIf="!showTable" layout="row" layout-align="center">
  <h3 class="edit-screen-card-title">{{ 'ccr.enterValidCivilId' | translate }}</h3>
</div>
