<div>

  <mat-card appearance="outlined" class="bank-accounts-card">
    <mat-card-title>
      <div layout="row" layout-align="space-between center">
        <span class="edit-screen-card-title" [translate]="'clients.bankAccount'">Bank accounts</span>
        <button [disabled]="'USER_BANK_ACCOUNT_WRITE' | isUnauthorized | async" mat-raised-button class="button-actions add-bank-account" (click)="addBankRecord()"
                *ngIf="!newBankAccountAdded">
          <mat-icon>add</mat-icon>
        </button>
      </div>
    </mat-card-title>
    <mat-card-content>
      <form [formGroup]="bankAccountForm">
        <div layout="row">
          <div flex-gt-xs="100" flex="100">
            <!-- <div *ngFor="let bankAccount of bankAccountForm.get('bankAccountList');let i = index" formArrayName="bankAccountList" -->
            <div *ngFor="let bankAccount of (bankAccountForm.get('bankAccountList') | toFormArray).controls;let i = index"
                      formArrayName="bankAccountList"
                      [itfgPermissions]="['USER_BANK_ACCOUNT_WRITE']"
                      layout="column" class="section-divider">
              <div>
                <mat-card appearance="outlined">
                  <mat-card-content
                    *ngFor="let exp of (bankAccount | toFormGroup).controls | objectToArray ;let q = index"
                    flex="full-width"
                    class="margin-left-2">
                    <mat-form-field class="margin-right-2 full-width" formGroupName="{{i}}" *ngIf="q===0">
                      <input matInput type="text"
                             (paste)="trimEmptyChars(i,bankAccountForm.controls['bankAccountList'].controls[i].controls.iban,$event)"
                             formControlName="iban"
                             placeholder="{{
                               (bankAccountForm.controls['bankAccountList'].controls[i].controls.iban.value | formatIban: 'bankIdCode')
                               !== bankAccountForm.controls['bankAccountList'].controls[i].controls.iban.value ?
                               (bankAccountForm.controls['bankAccountList'].controls[i].controls.iban.value | formatIban: 'bankIdCode') : ('clients.ibanBank' | translate)
                              }}"
                             >
      <mat-label>{{
                               (bankAccountForm.controls['bankAccountList'].controls[i].controls.iban.value | formatIban: 'bankIdCode')
                               !== bankAccountForm.controls['bankAccountList'].controls[i].controls.iban.value ?
                               (bankAccountForm.controls['bankAccountList'].controls[i].controls.iban.value | formatIban: 'bankIdCode') : ('clients.ibanBank' | translate)
                              }}</mat-label>
                      <mat-error *ngIf="bankAccountForm.controls['bankAccountList'].controls[i].controls.iban.errors"
                                 [translate]="'forms.invalidFormat'">Invalid format
                      </mat-error>
                    </mat-form-field>
                    <div *ngIf="q===1" class="relative-positioning">
                      <div
                        *ngIf="i != indexOfEditedAccount && bankAccountForm.controls['bankAccountList'].controls[i].controls.id && !newBankAccountAdded"
                        class="file-input-field-mask" (click)="$event.preventDefault();"></div>
                      <div layout="row" layout-align="space-around center" formGroupName="{{i}}" class="column-gap-10">
                        <mat-form-field (click)="fileUpload.triggerUploadButton()" flex>
                          <input matInput placeholder="{{'clients.documentOrImage' | translate}}"
                                 #fileResult formControlName="userDocument"
                                 [value]="bankAccountForm.controls['bankAccountList'].controls[i].controls.userDocument?.value ? bankAccountForm.controls['bankAccountList'].controls[i].controls.userDocument.value.name : ''"
                          />
                          <mat-hint class="override-color"
                                    *ngIf="bankAccountForm.controls['bankAccountList'].controls[i].controls.userDocument.errors && bankAccountForm.controls['bankAccountList'].controls[i].controls.userDocument.errors.invalidField"
                                    [translate]="'clients.fileNameTooLong'"
                                    [translateParams]="{value: MAX_FILE_NAME_LENGTH}">
                            Some of the fields are invalid
                          </mat-hint>
                          <mat-hint *ngIf="indexOfEditedAccount===i" [translate]="'clients.selectDocumentInProperFormat'">selectDocumentInProperFormat
                          </mat-hint>
                        </mat-form-field>
                        <button mat-icon-button
                                *ngIf="bankAccountForm.controls['bankAccountList'].controls[i].controls.userDocument.value"
                                (click)="clearFileInput(i, $event)"
                                (keyup.enter)="fileInput.clear()"
                                [disabled]="i != indexOfEditedAccount && bankAccountForm.controls['bankAccountList'].controls[i].controls.id">
                          <mat-icon>cancel</mat-icon>
                        </button>
                        <itfg-file-upload
                        #fileUpload
                        (fileSelected)="onFileSelected($event, bankAccountForm.controls['bankAccountList'].controls[i].controls['userDocument'])"></itfg-file-upload>
                      </div>
                    </div>
                    <div class="margin-left-2" formGroupName="{{i}}" *ngIf="q===2" flex="100" layout="row"
                         layout-align="space-between center">

                      <mat-checkbox formControlName="verified">
                        {{'global.verified' | translate}}
                      </mat-checkbox>
                      <div layout="row" layout-align="end center">
                        <button flex mat-raised-button class="button-actions bank-account-buttons"
                                (click)="editBankAccountRecord(i, bankAccount)"
                                *ngIf="editMode && bankAccount && indexOfEditedAccount!=i
                                && (bankAccountForm.controls['bankAccountList'].controls[i].controls.id.value !== null)">
                          <mat-icon>edit</mat-icon>
                        </button>
                        <button flex mat-raised-button class="button-actions bank-account-buttons"
                                (click)="saveEditedBankAccountRecord(bankAccount)" *ngIf="indexOfEditedAccount===i">
                          <mat-icon>save</mat-icon>
                        </button>
                        <button flex mat-raised-button
                                [disabled]="'USER_BANK_ACCOUNT_DELETE' | isUnauthorized | async"
                                (click)="deleteBankRecord(i,bankAccountForm.controls['bankAccountList'].controls[i].controls)">
                          <mat-icon>delete</mat-icon>
                        </button>
                      </div>
                    </div>

                    <div *ngIf="q===2">
                      <mat-hint class="override-color" *ngIf="bankAccount.errors && bankAccount.errors['invalidField']"
                                [translate]="'clients.ibanSaveProblem'">Some of the fields are invalid
                      </mat-hint>
                    </div>
                  </mat-card-content>

                </mat-card>

              </div>

            </div>
          </div>
        </div>
      </form>
    </mat-card-content>
  </mat-card>
</div>
