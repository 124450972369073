import { Pipe, PipeTransform } from '@angular/core';
import { ObjectDiffPipe } from './object-diff.pipe';
import { EntityRevision } from '../../core/types/entities';

@Pipe({
  name: 'historyDiff',
})
export class HistoryDiffPipe implements PipeTransform {
  transform<T>(
    historyArray: EntityRevision<T>[]
  ): { [key: string]: boolean }[] {
    const objectDiffPipe = new ObjectDiffPipe();
    const historyArrayWithMatches = [];
    for (let i = 0; i < historyArray.length - 1; i++) {
      historyArrayWithMatches.push(
        objectDiffPipe.transform(
          historyArray[i].entity,
          historyArray[i + 1].entity
        )
      );
    }

    historyArrayWithMatches.push(
      objectDiffPipe.transform(historyArray[0].entity, historyArray[0].entity)
    );

    return historyArrayWithMatches;
  }
}
