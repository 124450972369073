import { FilterUIType } from "./filter-ui-type";
import { FilterValueListService } from "./filter-value-list-service";
import * as _ from 'lodash-es';

export class ColumnFilterConfig {
  name?: string;
  type?: FilterUIType;
  path?: string;
  valueList?: any[];
  valueListService?: FilterValueListService;
  currentValue?: any | any[];
  translatedKey?: string;
  optionsTranslationPath?: string
  filterPredicate: (item: any) => boolean;
  private _translationKey: string;
  private filterPredicateMap: { [key in any]: (item: any) => boolean }; // TODO: key in FilterUIType after adding rest of filters

  constructor(config: any) {
    this.filterPredicateMap = {
      [FilterUIType.INPUT]: this.inputFilter.bind(this),
      [FilterUIType.MULTISELECT]: this.multiselectFilter.bind(this),
      // TODO: Add rest of filter types
    };

    this.name = config.name;
    this.type = config.type;
    this.path = config.path;
    this.valueList = config.valueList || [];
    this.valueListService = config.valueListService;
    this.currentValue = config.currentValue || [];
    this.filterPredicate = config.filterPredicate || this.filterPredicateMap[this.type].bind(this);
    this.translatedKey = this.nameTranslationKey;
    this.optionsTranslationPath = config.optionsTranslationPath;

    if (config.translationKey) {
      this._translationKey = config.translationKey;
    }


  }

  getValueByPath(obj: any, path: string): any {
    return _.get(obj, path);
  }

  inputFilter(item: any): boolean {
    const itemValue = this.getValueByPath(item, this.path).toString();
    const inputValue = this.currentValue.toString();

    return itemValue.startsWith(inputValue) || itemValue.includes(inputValue);
  }

  multiselectFilter(item: any): boolean {
    const itemValue = this.getValueByPath(item, this.path);
    return this.currentValue.includes(itemValue);
  }

  get nameTranslationKey() {
    if (this._translationKey) {
      return `filtering.names.${this._translationKey}`;
    } else {
      const path: string[] = this.path.split('.');
      const camelCasedPath = path.slice(1);
      camelCasedPath.forEach(
        (pathSegment: string, index: number, array: string[]) => {
          array[index] =
            pathSegment.charAt(0).toUpperCase() + pathSegment.slice(1);
        }
      );
      const pathName = camelCasedPath.join('');
      return `filtering.names.${path[0]}${pathName}`;
    }
  }
}
