<div class="dialog-container">
  <div flex layout-align="space-between center" layout="row">
    <div class="modal-dialog-title" [translate]="'clients.changeStatus'" flex>Change status</div>
    <button tabindex="-1" mat-icon-button (click)="onNoClick()">
      <mat-icon>clear</mat-icon>
    </button>
  </div>
  <hr>
  <form [itfgPermissions]="['USER_WRITE']" layout="column" novalidate [formGroup]="statusEditForm" (ngSubmit)="onSubmit($event);" class="form-position-fixing">
    <div flex="100" layout="row" layout-wrap layout-align="center center">
      <div flex="100" layout="row" layout-wrap layout-align="space-around center">
        <mat-form-field flex class="edit-blacklist-aditional-padding">
          <mat-select required matInput formControlName="name" (selectionChange)="selectedValueChanged($event)" placeholder="{{ 'global.status' | translate }}">
            <mat-option *ngFor="let status of mappedClientStatuses" [value]="status.id">{{ 'global.' + status.name.toLowerCase() | translate}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div flex="100" layout="row" layout-wrap layout-align="space-between center">
        <mat-form-field flex class="edit-blacklist-aditional-padding">
          <mat-select required matInput formControlName="reason" placeholder="{{ 'global.reason' | translate }}">
            <mat-option *ngFor="let reason of mappedClientArchiveReasons" [value]="reason.id">{{ 'global.' + reason.reason.toLowerCase() | translate}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="dialog-bottom-nav-bar edit-blacklist-aditional-padding" flex="100" layout="row" layout-align="end center">
      <button mat-raised-button flex-offset="80" flex="20">
        {{ 'global.save' | translate }}
      </button>
    </div>
  </form>
</div>