import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ItfgDataTableColumn } from '@app/core/components/data-table/types/data-table.column';
@Injectable()
export class ColumnsService {
  onColumnsTableChange = new Subject<ItfgDataTableColumn[]>();
  onColumnsChange = new Subject<any>();

  constructor() {}
}
