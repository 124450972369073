export interface ClientStatus {
  id?: number;
  name?: string;
  reason?: string;
}

export enum ClientStatusNames {
  ACTIVE = 'ACTIVE',
  ARCHIVED = 'ARCHIVED',
  INCOMPLETE = 'INCOMPLETE',
}
