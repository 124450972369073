import { Injectable } from '@angular/core';
import { RequestService } from './request.service';
import { Partner } from '../types/partner';
import { Observable } from 'rxjs';
import { Page } from '../types/page';import { ClientCommunicationRequestService } from './client-communication-request.service';
import { EmailTemplateCode } from '../types/email-template-code';

@Injectable()
export class PartnersService {
  request: RequestService;
  clientCommunicationRequest: ClientCommunicationRequestService
  constructor(request: RequestService, clientCommunicationRequest: ClientCommunicationRequestService) {
    this.request = request;
    this.clientCommunicationRequest = clientCommunicationRequest
  }

  getPartnersList(params?): Observable<Page<Partner>> {
    return this.request.get(['partners'], { params: params });
  }

  savePartner(partnerDTO: Partner) {
    return this.request.post(['partners'], {
      body: partnerDTO,
    });
  }

  getPartnerById(id: number): Observable<Partner> {
    return this.request.get(['partners', id]);
  }

  deletePartnerById(id: number): Observable<Partner> {
    return this.request.delete(['partners', id]);
  }

  sendEmailConfirmationTempPassword(dto: {email: string}){ 
    console.log(dto);
    return this.clientCommunicationRequest.post(['email', 'partners', 'initial-confirmation-code'], {
      body: {email: dto.email,}, 
    });
  }

  sendEmailConfirmation(dto: {email: string}){ 
    console.log(dto);
    return this.clientCommunicationRequest.post(['email', 'partners', 'confirmation-code'], {
      body: {email: dto.email,}, 
    });
  }

  sendTemporaryPassword(dto: {email: string}){ 
    console.log(dto);
    return this.clientCommunicationRequest.post(['email', 'partners', 'temporary-password'], {
      body: {email: dto.email,}, 
    });
  }
}
