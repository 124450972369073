<ng-container>
  <!-- Missed call -->
  <div *ngIf="callStatus === callStatuses.AGENT_FREE_CALLBACK_REQUESTED"
       class="svg-icon call-missed-icon"
       [matTooltip]="'pbx.callType.missed' | translate"
       matTooltipPosition="above">
    <svg width="24"
         height="24"
         viewBox="0 0 24 24">
      <path d="M23.71,16.67C20.66,13.77 16.54,12 12,12C7.46,12 3.34,13.77 0.29,16.67C0.11,16.85 0,17.1 0,17.38C0,17.65 0.11,17.9 0.29,18.08L2.77,20.56C2.95,20.74 3.2,20.86 3.5,20.86C3.75,20.86 4,20.75 4.18,20.57C4.97,19.83 5.86,19.21 6.84,18.72C7.17,18.56 7.4,18.22 7.4,17.82V14.72C8.85,14.25 10.39,14 12,14C13.6,14 15.15,14.25 16.6,14.72V17.82C16.6,18.22 16.83,18.56 17.16,18.72C18.14,19.21 19.03,19.83 19.82,20.57C20,20.75 20.25,20.86 20.5,20.86C20.8,20.86 21.05,20.74 21.23,20.56L23.71,18.08C23.89,17.9 24,17.65 24,17.38C24,17.1 23.89,16.85 23.71,16.67M6.5,5.5L12,11L19,4L18,3L12,9L7.5,4.5H11V3H5V9H6.5V5.5Z"
            fill="var(--missed-call-base)" />
    </svg>
  </div>

  <!-- Incoming call that is not missed -->
  <div *ngIf="callDirection === callDirections.IN && callStatus !== callStatuses.AGENT_FREE_CALLBACK_REQUESTED"
       [matTooltip]="('communication.callDirections.' + callDirection.toLowerCase() | translate) + ' - ' + ((callStatus | callStatusTranslationPipe) | translate)"
       matTooltipPosition="above"
       class="svg-icon">
    <svg width="24"
         height="24"
         viewBox="0 0 24 24">
      <path d="M4,3A1,1 0 0,0 3,4A17,17 0 0,0 20,21A1,1 0 0,0 21,20V16.5A1,1 0 0,0 20,15.5C18.75,15.5 17.55,15.3 16.43,14.93C16.08,14.82 15.69,14.9 15.41,15.17L13.21,17.37C10.38,15.93 8.06,13.62 6.62,10.78L8.82,8.57C9.1,8.31 9.18,7.92 9.07,7.57C8.7,6.45 8.5,5.25 8.5,4A1,1 0 0,0 7.5,3H4M19,11V9.5H15.5L21,4L20,3L14.5,8.5V5H13V11H19Z"
            fill="var(--incoming-call-light)" />
    </svg>
  </div>

  <div *ngIf="callDirection === callDirections.OUT && callStatus === callStatuses.BUSY"
       [matTooltip]="('communication.callDirections.' + callDirection.toLowerCase() | translate) + ' - ' + ((callStatus | callStatusTranslationPipe) | translate)"
       matTooltipPosition="above"
       class="svg-icon">
    <svg width="24"
         height="24"
         viewBox="0 0 24 24">
      <path d="M15,12H17A5,5 0 0,0 12,7V9A3,3 0 0,1 15,12M19,12H21C21,7 16.97,3 12,3V5C15.86,5 19,8.13 19,12M20,15.5C18.75,15.5 17.55,15.3 16.43,14.93C16.08,14.82 15.69,14.9 15.41,15.18L13.21,17.38C10.38,15.94 8.06,13.62 6.62,10.79L8.82,8.59C9.1,8.31 9.18,7.92 9.07,7.57C8.7,6.45 8.5,5.25 8.5,4A1,1 0 0,0 7.5,3H4A1,1 0 0,0 3,4A17,17 0 0,0 20,21A1,1 0 0,0 21,20V16.5A1,1 0 0,0 20,15.5Z"
            fill="var(--busy-call-base)" />
    </svg>
  </div>

  <div *ngIf="callDirection === callDirections.OUT && callStatus === callStatuses.FAILED"
       [matTooltip]="('communication.callDirections.' + callDirection.toLowerCase() | translate) + ' - ' + ((callStatus | callStatusTranslationPipe) | translate)"
       matTooltipPosition="above"
       class="svg-icon">
    <svg width="24"
         height="24"
         viewBox="0 0 24 24">
      <path d="M15,12H17A5,5 0 0,0 12,7V9A3,3 0 0,1 15,12M19,12H21C21,7 16.97,3 12,3V5C15.86,5 19,8.13 19,12M20,15.5C18.75,15.5 17.55,15.3 16.43,14.93C16.08,14.82 15.69,14.9 15.41,15.18L13.21,17.38C10.38,15.94 8.06,13.62 6.62,10.79L8.82,8.59C9.1,8.31 9.18,7.92 9.07,7.57C8.7,6.45 8.5,5.25 8.5,4A1,1 0 0,0 7.5,3H4A1,1 0 0,0 3,4A17,17 0 0,0 20,21A1,1 0 0,0 21,20V16.5A1,1 0 0,0 20,15.5Z"
            fill="var(--failed-call-dark)" />
    </svg>
  </div>


  <div *ngIf="callDirection === callDirections.OUT && callStatus === callStatuses.NO_ANSWER"
       [matTooltip]="('communication.callDirections.' + callDirection.toLowerCase() | translate) + ' - ' + ((callStatus | callStatusTranslationPipe) | translate)"
       matTooltipPosition="above"
       class="svg-icon">
    <svg width="24"
         height="24"
         viewBox="0 0 24 24">
      <path d="M15,12H17A5,5 0 0,0 12,7V9A3,3 0 0,1 15,12M19,12H21C21,7 16.97,3 12,3V5C15.86,5 19,8.13 19,12M20,15.5C18.75,15.5 17.55,15.3 16.43,14.93C16.08,14.82 15.69,14.9 15.41,15.18L13.21,17.38C10.38,15.94 8.06,13.62 6.62,10.79L8.82,8.59C9.1,8.31 9.18,7.92 9.07,7.57C8.7,6.45 8.5,5.25 8.5,4A1,1 0 0,0 7.5,3H4A1,1 0 0,0 3,4A17,17 0 0,0 20,21A1,1 0 0,0 21,20V16.5A1,1 0 0,0 20,15.5Z"
            fill="var(--no-answer-call-light)" />
    </svg>
  </div>

  <div *ngIf="callDirection === callDirections.OUT && callStatus === callStatuses.ANSWER"
       [matTooltip]="('communication.callDirections.' + callDirection.toLowerCase() | translate) + ' - ' + ((callStatus | callStatusTranslationPipe) | translate)"
       matTooltipPosition="above"
       class="svg-icon">
    <svg width="24"
         height="24"
         viewBox="0 0 24 24">
      <path d="M15,12H17A5,5 0 0,0 12,7V9A3,3 0 0,1 15,12M19,12H21C21,7 16.97,3 12,3V5C15.86,5 19,8.13 19,12M20,15.5C18.75,15.5 17.55,15.3 16.43,14.93C16.08,14.82 15.69,14.9 15.41,15.18L13.21,17.38C10.38,15.94 8.06,13.62 6.62,10.79L8.82,8.59C9.1,8.31 9.18,7.92 9.07,7.57C8.7,6.45 8.5,5.25 8.5,4A1,1 0 0,0 7.5,3H4A1,1 0 0,0 3,4A17,17 0 0,0 20,21A1,1 0 0,0 21,20V16.5A1,1 0 0,0 20,15.5Z"
            fill="var(--outgoing-call-light)" />
    </svg>
  </div>

  <!-- Initiated call -->
  <mat-icon *ngIf="call.direction === callDirections.OUT && call?.status === callStatuses.INITIATED"
            [matTooltip]="('communication.callDirections.' + call.direction.toLowerCase() | translate) + ' - ' + ((call.status | callStatusTranslationPipe) | translate)"
            matTooltipPosition="above"
            class="svg-icon ringing-icon"
            #menuTrigger="matMenuTrigger"
            [matMenuTriggerFor]="callStatusActions">
    phone_in_talk
  </mat-icon>

  <mat-menu #callStatusActions="matMenu"
            [overlapTrigger]="false"
            class="call-status-menu">
    <mat-list>
      <mat-selection-list [(ngModel)]="selectedStatus"
                          [multiple]="false"
                          [ngModelOptions]="{standalone: true}"
                          (click)="$event.stopPropagation()">
        <mat-list-option *ngFor="let status of callStatusOptions"
                         (click)="$event.stopPropagation()"
                         [value]="status">
          {{ ('communication.fullCallStatuses.' + status) | translate }}
        </mat-list-option>
      </mat-selection-list>
    </mat-list>
    <div layout="row"
         layout-align="end end"
         class="padding-8px">
      <button mat-raised-button
              (click)="onSaveCallStatus()"
              color="primary"
              [disabled]="!selectedStatus">
        {{ 'global.save' | translate }}
      </button>
    </div>
  </mat-menu>
</ng-container>
