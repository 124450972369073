import { SpecialOption, SpecialOptionType } from './note.types';

export const specialOptions: SpecialOption[] = [
  {
    name: 'Напомняне',
    type: SpecialOptionType.REMINDER,
    // aliases: ['reminder', 'напомняне'],
    icon: 'alarm',
  },
  {
    name: 'Уговорка',
    type: SpecialOptionType.PAYMENT_PROMISE,
    icon: 'universal_currency',
  },
];
