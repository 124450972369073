import { Injectable } from '@angular/core';
import {
  PaymentPromise,
  PaymentPromiseStatusType,
} from '../types/payment-promise';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Page } from '../types/page';
import { SearchOptions, SearchOptionsDTO } from '../types/search-options';
import { CollectionRequestService } from './collection-request.service';
import * as moment from 'moment/moment';
import { map } from 'rxjs/operators';

@Injectable()
export class PaymentPromiseService {
  constructor(private request: CollectionRequestService) {}

  private _paymentPromises$: BehaviorSubject<
    Page<PaymentPromise>
  > = new BehaviorSubject({ content: [] });
  onCreditPaymentPromiseDataChange = new Subject<any>();
  onPromisesSearchOptionsChange = new Subject<SearchOptions>();

  public get paymentPromises$(): Observable<Page<PaymentPromise>> {
    return this._paymentPromises$.asObservable();
  }

  setPromises$(promises: Page<PaymentPromise>) {
    this._paymentPromises$.next(promises);
  }

  getPaymentPromiseList$(
    options: SearchOptionsDTO
  ): Observable<Page<PaymentPromise>> {
    return this.request.get(['payment-promises'], { params: options }).pipe(
      map((page: Page<PaymentPromise>) => {
        page.content.map(this.paymentPromiseStatusMap);
        return page;
      })
    );
  }

  getPaymentPromiseById$(id: number): Observable<PaymentPromise> {
    return this.request.get(['payment-promises', id]);
  }

  transferPaymentPromiseById$(
    id: number,
    dto: Partial<PaymentPromise>
  ): Observable<PaymentPromise> {
    return this.request.post(['payment-promises', id, 'transfer'], {
      body: dto,
    });
  }

  createPaymentPromise$(
    paymentPromiseDto: PaymentPromise
  ): Observable<PaymentPromise> {
    return this.request.post(['payment-promises'], { body: paymentPromiseDto });
  }

  editPaymentPromise$(
    paymentPromiseDto: PaymentPromise
  ): Observable<PaymentPromise> {
    return this.request.post(['payment-promises', paymentPromiseDto.id], {
      body: paymentPromiseDto,
    });
  }

  deletePaymentPromiseById$(id: number): Observable<PaymentPromise> {
    return this.request.delete(['payment-promises', id]);
  }
  deletePaymentPromiseByAgreementId$(
    agreementId: number
  ): Observable<PaymentPromise> {
    return this.request.delete(['payment-promises', 'agreements', agreementId]);
  }

  savePaymentPromisesBulk$(
    promises: PaymentPromise[]
  ): Observable<PaymentPromise[]> {
    return this.request.post(['payment-promises', 'agreements', 'bulk'], {
      body: { paymentPromises: promises },
    });
  }

  // active = 1 and due_date > today()

  // Падеж active = 1 and due_date = today()

  // Просрочена active = 1 and due_date < today()

  // Отложена active = 0 and paid_amount < amount

  // Платена active = 0 and paid_amount = amount

  paymentPromiseStatusMap(promise: PaymentPromise) {
    const dueDateIsToday = moment(promise.date).isSame(moment(), 'day');
    const afterDueDate = moment(promise.date).isBefore(moment(), 'day');

    if (promise.active) {
      // UPCOMING
      if (moment(promise.date).isAfter(moment(), 'day')) {
        promise.status = PaymentPromiseStatusType.UPCOMING;
      }

      // TODAY
      if (dueDateIsToday) {
        promise.status = PaymentPromiseStatusType.CURRENT;
      }

      // EXPIRED
      if (afterDueDate) {
        promise.status = PaymentPromiseStatusType.EXPIRED;
      }
    } else {
      if (promise.paidAmount === 0) {
        if (afterDueDate) {
          promise.status = PaymentPromiseStatusType.UNFULFILLED;
        } else {
          promise.status = PaymentPromiseStatusType.CANCELED;
        }
      } else if (promise.paidAmount < promise.amount) {
        promise.status = PaymentPromiseStatusType.PARTIALLY_PAID;
      } else {
        promise.status = PaymentPromiseStatusType.PAID;
      }
    }

    return promise;
  }
}
