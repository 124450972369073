export interface CreditStatus {
  id?: number;
  name?: string;
  reason?: string;
}

export enum CreditStatusNames {
  APPROVED = 'APPROVED',
  CONFIRMED = 'CONFIRMED',
  LOSS = 'LOSS',
  NEW = 'NEW',
  OVERDUE = 'OVERDUE',
  REGULAR = 'REGULAR',
  REJECTED = 'REJECTED',
  REPAID = 'REPAID',
}

export enum CreditStatusReasons {
  NO_REASON = 'NO_REASON',
  CALL_CENTER = 'CALL_CENTER',
  COLLECTOR = 'COLLECTOR',
  COURT = 'COURT',
  DCA = 'DCA',
  FOR_COLLECTOR = 'FOR_COLLECTOR',
  FOR_COURT = 'FOR_COURT',
  FOR_DCA = 'FOR_DCA',
  RETURNED_BY_COLLECTOR = 'RETURNED_BY_COLLECTOR',
  RETURNED_BY_DCA = 'RETURNED_BY_DCA',
  ABROAD = 'ABROAD',
  AGE = 'AGE',
  AGGRESSIVE = 'AGGRESSIVE',
  BLACKLIST_CIVIL = 'BLACKLIST_CIVIL',
  BLACKLIST_EMPLOYER = 'BLACKLIST_EMPLOYER',
  BLACKLIST_PHONE = 'BLACKLIST_PHONE',
  CORRECTION = 'CORRECTION',
  FAMILY_DEBTS = 'FAMILY_DEBTS',
  FRAUD = 'FRAUD',
  IRREGULAR_PAYER = 'IRREGULAR_PAYER',
  IRREGULAR_PAYER_INTERNAL = 'IRREGULAR_PAYER_INTERNAL',
  MATCHES = 'MATCHES',
  NOT_CONFIRMED = 'NOT_CONFIRMED',
  NOT_CONTACTABLE = 'NOT_CONTACTABLE',
  POSTPONED = 'POSTPONED',
  NOT_SUPPORTIVE = 'NOT_SUPPORTIVE',
  NO_EMPLOYMENT_CONTRACT = 'NO_EMPLOYMENT_CONTRACT',
  NO_INSURANCE = 'NO_INSURANCE',
  REDIRECTED = 'REDIRECTED',
  REFUSED = 'REFUSED',
  REFUSED_CAMPAIGN = 'REFUSED_CAMPAIGN',
  REQUEST_DATA_NOT_CONFIRMED = 'REQUEST_DATA_NOT_CONFIRMED',
  SOCIAL_STATUS = 'SOCIAL_STATUS',
  UNSTABLE_PERSON = 'UNSTABLE_PERSON',
  WORK_CONFIRMATION = 'WORK_CONFIRMATION',
  RISK = 'RISK',
  OVERRIDE = 'OVERRIDE',
  EMPLOYEE = 'EMPLOYEE',
  HIGH_RISK = 'HIGH_RISK',
  RISK_2 = 'RISK_2',
  UNEMPLOYED = 'UNEMPLOYED',
}
