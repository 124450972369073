import { Observable, of } from 'rxjs';
import { EventSourcePolyfill } from 'event-source-polyfill';
import { StorageService } from './storage.service';
import { BrowserStorageType, APP_CONFIG } from '../../config/app-config';
import { Injectable } from '@angular/core';
import { Favicons } from '../../favicon/favicons';
import { environment } from './../../../environments/environment';

@Injectable()
export class SseService {
  storage = new StorageService(BrowserStorageType.LocalStorage);
  eventSourceInitDict = {
    headers: {
      'x-auth-token':
        this.storage.getItem(APP_CONFIG.AUTH.SESSION_TOKEN_NAME) || '',
      withCredentials: true,
    },
  };

  constructor(private favicons: Favicons) {}

  observe$(url: string): Observable<Partial<MessageEvent>> {
    return new Observable<Partial<MessageEvent>>(obs => {
      const eventSource = new EventSourcePolyfill(
        url,
        this.eventSourceInitDict
      );
      eventSource.addEventListener(
        'message',
        (event: Partial<MessageEvent>) => {
          event = {
            ...event,
            data: JSON.parse(event.data),
          };
          this.favicons.activate('notification');
          obs.next(event);
        }
      );
      return () => eventSource.close();
    });
  }

  clientActivityStream$(clientId: number) {
    // Temporary disabled
    return of();
    return this.observe$(
      [
        environment.CREDITS_API_URL + 'admin',
        'users',
        clientId,
        'activity-stream',
      ].join('/')
    );
  }

  reset() {
    this.favicons.reset();
  }
}
