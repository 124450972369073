import { Pipe, PipeTransform } from '@angular/core';
import { ReminderOption } from '../credit-edit/credit-communication/credit-note-edit/reminder-option';

@Pipe({
  name: 'reminderOptionToDisplayText',
})
export class ReminderOptionToDisplayTextPipe implements PipeTransform {

  constructor() {}

  transform(value: ReminderOption) {
    let text = '';
    if (!!value && !!value.timestampFunc) {
      text = `${value.timestampFunc().format('DD.MM.YYYY HH:mm')} - (${value.text})`;
    } else if (!!value && !!value.text) {
      text = value.text;
    }
    return text;
  }
}
