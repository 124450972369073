import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';

import { RequestService } from './request.service';

import {
  Conversation,
  ConversationType,
  DeserializedConversation,
  DeserializedEditConversation,
  EditConversation,
  NoteState,
} from '../types/converstion';

import { Page } from '../types/page';

import { Observable, Subject } from 'rxjs';

import { DateService } from './date.service';

@Injectable()
export class ConversationService {
  public noteState: NoteState;
  onCreditNoteDataChange = new Subject<any>();

  constructor(private dates: DateService, private request: RequestService) {
    this.noteState = {};
  }

  set(note: Conversation, creditId: number) {
    this.noteState.note = note;
    this.noteState.creditId = creditId;
  }

  get() {
    return this.noteState;
  }

  getConversationList(
    options?: any
  ): Observable<Page<DeserializedConversation>> {
    return this.request.get(['conversations'], options).pipe(
      map(
        (
          conversationPage: Page<Conversation>
        ): Page<DeserializedConversation> => {
          return {
            ...conversationPage,
            content: conversationPage.content.map(
              this.deserializeConversation.bind(this)
            ),
          };
        }
      )
    );
  }

  getConversationById(
    id: number,
    options?: any
  ): Observable<DeserializedConversation> {
    return this.request.get(['conversations', id], options).pipe(
      map(
        (conversation: Conversation): DeserializedConversation => {
          return this.deserializeConversation(conversation);
        }
      )
    );
  }

  saveConversation(
    conversation: DeserializedConversation,
    options?: any
  ): Observable<DeserializedConversation> {
    const conversationToSave: Conversation = this.serializeConversation(
      conversation
    );
    return this.request.post(['conversations'], {
      body: conversationToSave,
    });
  }

  updateConversation(
    id: number,
    conversation: DeserializedConversation,
    options?: any
  ): Observable<DeserializedConversation> {
    const conversationToSave: Conversation = this.serializeConversation(
      conversation
    );
    const editConversation: EditConversation = this.transformConversationToEditConversation(
      conversationToSave
    );
    let requestOptions = {
      body: editConversation,
    };
    requestOptions = Object.assign(requestOptions, options);
    return this.request.post(['conversations', id], requestOptions);
  }

  serializeConversation(
    conversationToSerialize: DeserializedConversation
  ): Conversation {
    return {
      ...conversationToSerialize,
      promisedPaymentTime: conversationToSerialize.promisedPaymentTime
        ? conversationToSerialize.promisedPaymentTime.toISOString()
        : undefined,
      nextConversationTime: conversationToSerialize.nextConversationTime
        ? conversationToSerialize.nextConversationTime.toISOString()
        : undefined,
      time: conversationToSerialize.time
        ? conversationToSerialize.time.toISOString()
        : undefined,
    };
  }

  deserializeConversation(
    conversationToDeserialize: Conversation
  ): DeserializedConversation {
    return {
      ...conversationToDeserialize,
      promisedPaymentTime: conversationToDeserialize.promisedPaymentTime
        ? new Date(conversationToDeserialize.promisedPaymentTime)
        : undefined,
      nextConversationTime: conversationToDeserialize.nextConversationTime
        ? new Date(conversationToDeserialize.nextConversationTime)
        : undefined,
      time: conversationToDeserialize.time
        ? new Date(conversationToDeserialize.time)
        : undefined,
    };
  }

  getConversationTypesList(): Observable<ConversationType[]> {
    return this.request.get(['conversations', 'types']);
  }

  transformConversationToEditConversation(
    conversationToTransform: Conversation
  ): EditConversation {
    return {
      contacted: conversationToTransform.contacted,
      phone: conversationToTransform.phone,
      notes: conversationToTransform.notes,
      promisedPaymentAmount: conversationToTransform.promisedPaymentAmount,
      type: { ...conversationToTransform.type },
      nextConversationTime: conversationToTransform.nextConversationTime,
      promisedPaymentTime: conversationToTransform.promisedPaymentTime,
    };
  }

  transformDeserializedConversationToDeserializedEditConversation(
    conversationToTransform: DeserializedConversation
  ): DeserializedEditConversation {
    return {
      contacted: conversationToTransform.contacted,
      phone: conversationToTransform.phone,
      notes: conversationToTransform.notes,
      promisedPaymentAmount: conversationToTransform.promisedPaymentAmount,
      type: { ...conversationToTransform.type },
      nextConversationTime: new Date(
        conversationToTransform.nextConversationTime
      ),
      promisedPaymentTime: new Date(
        conversationToTransform.promisedPaymentTime
      ),
    };
  }
}
