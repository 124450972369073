<mat-card appearance="outlined">
  <mat-card-title>
    <span class="title">{{ 'nssi.standalonePageTitle' | translate }}</span>
  </mat-card-title>
  <mat-card-subtitle>
  </mat-card-subtitle>
  <mat-card-content>
    <div flex layout="row">
      <form flex layout="row">
        <div layout="row" class="title" flex="50" layout-align="start center" layout-wrap>
          <mat-form-field flex="40"
                          class="right-padding-16">
            <input
              matInput
              type="text"
              [formControl]="civilId"
              placeholder="{{ 'global.civilId' | translate }}"
            />
            <mat-error
              *ngIf="civilId.hasError('onlyNumbers') || civilId.hasError('minlength') || civilId.hasError('maxlength') || civilId.hasError('incorrectEgnFormat')"
              [translate]="'clients.invalidCivilId'">Invalid civil id
            </mat-error>
          </mat-form-field>
          <mat-form-field class="right-padding-16" flex="40">
            <input
              matInput
              type="text"
              [formControl]="idCardNumber"
              placeholder="{{ 'global.number' | translate }}"
            />
      <mat-label>{{ 'global.number' | translate }}</mat-label>
            <mat-error *ngIf="idCardNumber.errors" [translate]="'forms.invalidFormat'">Invalid
              format
            </mat-error>
          </mat-form-field>
        </div>
        <div flex="50" layout="row" class="title" layout-align="end center" layout-wrap>
          <button mat-raised-button class="right-margin-16"
                  [disabled]="(!idCardNumber.value || !idCardNumber.valid) || (!civilId.value || !civilId.valid) || (['MI_READ'] | isUnauthorized | async)"
                  (click)="getLatestReport()">
            Налична справка
          </button>
          <div class="white-space-no-wrap">
            <button
              mat-raised-button
              [disabled]="(!idCardNumber.value || !idCardNumber.valid) || (!civilId.value || !civilId.valid) || (['MI_ADD'] | isUnauthorized | async)"
              (click)="getNewMi()"
            >
              {{ 'mi.getNewMiReport' | translate }}
            </button>
          </div>
        </div>
      </form>

    </div>
    <div
    *ngIf="!reportData && !clientReportData && !isReport"
    flex
    layout="row"
    layout-align="start center"
    class="left-padding-16"
  >
    <h3 class="edit-screen-card-title">{{ 'mi.noMiFiles' | translate }}</h3>
  </div>
  <!-- <div
  *ngIf="reportData?.data && clientReportData && isReport && !isIdsMatching"
  flex
  layout="row"
  layout-align="start center"
  class="left-padding-16"
>
  <h3 class="edit-screen-card-title">{{ 'mi.idCardNumberDoNotMatch' | translate }}</h3>
</div> -->
<div *ngIf="reportData?.status === 'success' && !reportData?.data" flex layout="row" layout-align="start center" class="left-padding-16">
  <h3 class="edit-screen-card-title">{{ 'mi.missingMiDataLatestReport' | translate }}</h3>
</div>
<div *ngIf="reportData?.status === 'success' && reportData?.data && !isIdCardMatching" layout="row" layout-align="start center" class="left-padding-16">
  <h3 class="edit-screen-card-title">{{ 'mi.idCardNumberDoNotMatch' | translate }}</h3>
</div>
<div *ngIf="reportData?.status != 'success'" flex layout="row" layout-align="start center" class="left-padding-16">
  <h3 *ngIf="reportData?.status == 'incorrect_input'" class="edit-screen-card-title">{{ 'mi.incorrectDataMsg' | translate }}</h3>
  <h3 *ngIf="reportData?.status == 'not_found'" class="edit-screen-card-title">{{ 'mi.noMiData' | translate }}</h3>
  <h3 *ngIf="reportData?.status == 'server_timeout'" class="edit-screen-card-title">{{ 'reports.serverTimeoutError' | translate: {requestTime: requestTime | itfgDateFormat} }}</h3>
  <h3 *ngIf="reportData?.status == 'server_error'" class="edit-screen-card-title">{{ 'reports.serverGenericError' | translate: {requestTime: requestTime | itfgDateFormat} }}</h3>
</div>

<div *ngIf="isReport && reportData?.data && isIdCardMatching">
  <itfg-mi-card [isReport]="isReport"
                [isClientDataMatch]="isIdCardMatching"
                [civilId]="civilId.value"
                [idCardNumber]="idCardNumber.value"
                [reportData]="reportData"
                [clientReportData]="clientReportData"
                (scrollToClientMapper)="scrollTo()">
  </itfg-mi-card>
  <div flex layout="row" layout-align="end end" class="margin-right-16px" *ngxPermissionsOnly="'USER_ID_DATA_READ'">
          <span> {{ 'global.operator' | translate }}:&nbsp;</span
          ><span>{{ reportData?.requesterName }}</span>
  </div>
</div>


