import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { FilterValueListService, FilterValue } from '../filter/types';
import { PaymentMethodService } from '../../core/services/payment-method.service';
import { Observable } from 'rxjs';
import { PaymentMethod } from '../../core/types/payment-method';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class PaymentValueListService implements FilterValueListService {
  constructor(
    private payments: PaymentMethodService,
    private translate: TranslateService
  ) {}

  getFilterValues$(): Observable<FilterValue[]> {
    return this.payments.getPaymentMethodList().pipe(
      map((paymentList: PaymentMethod[]): FilterValue[] => {
        return paymentList.map(
          (payment: PaymentMethod): FilterValue => {
            return {
              value: payment.id,
              displayText: this.translate.instant(
                'payments.methods.' + payment.name
              )  + ' - ' + payment?.brand?.name,
            };
          }
        );
      })
    );
  }
}
