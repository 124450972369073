import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'booleanTranslation',
})
export class BooleanTranslationPipe implements PipeTransform {
  transform(value: boolean): string {
    return value ? 'global.yes' : 'global.no';
  }
}
