import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { RequestService } from './request.service';
import { Country } from '../types/country';

@Injectable()
export class CountryService {
  request: RequestService;
  private countries$: BehaviorSubject<Country[]> = new BehaviorSubject<Country[]>([]);

  constructor(request: RequestService) {
    this.request = request;
  }

  getCountries(): Observable<Country[]> {
    if (this.countries$.value.length === 0) {
      this.request.get(['countries'])
      .subscribe(
          (countries: Country[]) => {
            this.countries$.next(countries);
          },
          error => {
            this.countries$.next([]);
          }
        );
    }

    return this.countries$.asObservable();
  }

  getCountryById$(id: number): Observable<Country> {
    return this.request.get(['countries', id]);
  }

  findCountriesStartingWith(value): Observable<Country[]> {
    return this.request.get(['countries', 'findByNameStartingWithIgnoreCase'], {
      params: { name: value },
    });
  }
}
