export enum TemplateTypes {
  SMS = 'SMS',
  EMAIL = 'email',
  DOCUMENT = 'Document',
}

export enum DocumentTypes {
  HTML = 'html',
  PDF = 'pdf',
}
