<div flex layout-align="space-between center" layout="row">
  <div mat-dialog-title *ngIf="data.title">
    <p>{{ data.title }}</p>
  </div>
  <button tabindex="-1" mat-icon-button (click)="onNoClick()">
    <mat-icon>clear</mat-icon>
  </button>
</div>
<div mat-dialog-content>
  <p class="dialog-message">
    <span [innerHtml]="data.message"></span>
    <span *ngIf="data.warningMessage">
      <div>
        <mat-hint [style.color]="'#dc3545'">{{ data.warningMessage }}</mat-hint>
      </div>
    </span>
  </p>
</div>
<mat-progress-bar
  mode="determinate"
  [value]="data.progressBarValue"
></mat-progress-bar>
<ng-container *ngIf="data.expandingMessage">
  <mat-expansion-panel (opened)="expandingMessageOpen = true"
                       (closed)="expandingMessageOpen = false">
  <mat-expansion-panel-header>
    <mat-panel-title>
      {{ data.expandingMessage.title }}
    </mat-panel-title>
  </mat-expansion-panel-header>
  {{ data.expandingMessage.message }}
</mat-expansion-panel>
</ng-container>
