<div class="reminder">
  <div flex layout="row">
    <button
      [disabled]="!openedCredit"
      mat-stroked-button
      class="custom-button"
      (click)="openReminderDialog()"
    >
      <div layout="row" layout-align="space-between center">
        <span>Напомняне</span>

        <mat-icon fontSet="material-symbols-outlined">add_circle</mat-icon>
      </div>
    </button>
  </div>

  <div>
    <mat-list>
      <mat-list-item *ngIf="reminder">
        <div flex layout="row" layout-align="space-between center">
          <div flex layout="row">
            <mat-icon
              fontSet="material-symbols-outlined"
              [matTooltip]="
                reminderService.requestCreditReminderToDisplayText(
                  reminder?.time
                ).tooltip
              "
              matTooltipPosition="left"
              [ngClass]="{
                'status-icon-on': isReminderActive,
                'status-icon-off': !isReminderActive
              }"
              class="fill"
            >
              {{ isReminderActive ? 'alarm_on' : 'alarm_off' }}
            </mat-icon>

            <span class="text">{{
              reminder?.time | date: 'dd.MM.yy HH:mm'
            }}</span>
          </div>

          <mat-icon
            fontSet="material-symbols-outlined"
            class="remove"
            (click)="deactivateReminder(this.reminder)"
          >
            remove
          </mat-icon>
        </div>
      </mat-list-item>
    </mat-list>
  </div>
</div>
