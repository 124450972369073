<!-- INPUT FILTER -->
<ng-container *ngIf="filterConfig.type === filterUITypes.input">
  <mat-form-field class="remove-mat-form-field-wrapper">
    <input #valueInput
           matInput
           type="text"
           [(ngModel)]="filterConfig.currentValue"
           (ngModelChange)="emitFilterChange()"
           (click)="stopPropagation($event)"
           [placeholder]="filterConfig.translatedKey | translate">
    <!-- <mat-label>
      <span>{{ filterConfig.translatedKey | translate }}</span>
    </mat-label> -->
    <button matSuffix
            tabindex="-1"
            type="button"
            mat-icon-button
            [disabled]="filterConfig.currentValue | isEmptyFilter"
            matTooltip="{{ 'filtering.clearFilterValues' | translate }}"
            (click)="removeFilterValue($event, filterConfig)">
      <mat-icon>clear</mat-icon>
    </button>
  </mat-form-field>
</ng-container>

<!-- MULTISELECT FILTER -->
<ng-container *ngIf="filterConfig.type === filterUITypes.multiselect">
  <mat-list>
    <mat-selection-list #valueMultiselect
                        (selectionChange)="onSelectionChange($event, filterConfig)">
      <mat-list-option *ngFor="let option of filterOptions"
                       [value]="option.value || option"
                       (click)="stopPropagation($event)"
                       [selected]="filterConfig.currentValue.includes(option.value || option)">
        {{ option?.displayText || (option | filterOptionsTranslation:filterConfig?.optionsTranslationPath) }}
      </mat-list-option>
    </mat-selection-list>
  </mat-list>
  <div flex
       layout="row"
       layout-align="center center">
    <button mat-button
            (click)="applyFilters()">
      <span>{{ 'filtering.applyFilters' | translate }}</span>
    </button>
    <button mat-icon-button
            matTooltip="{{ 'filtering.clearFilterValues' | translate }}"
            [disabled]="filterConfig.currentValue | isEmptyFilter"
            (click)="removeFilterValue($event, filterConfig)">
        <mat-icon>clear</mat-icon>
    </button>
    </div>
</ng-container>

<!-- TODO: REST OF FILTER TYPES -->
