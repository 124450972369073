import { publishReplay, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarRef, SimpleSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition, MatSnackBarConfig } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { Observable, ConnectableObservable } from 'rxjs';
import { PbxNotificationComponent } from '@app/main/main-layout/right-drawer/event-log/pbx/pbx-notification-component/pbx-notification.component';

const showBaseSnackbarNotificationDefaults = {
  duration: 3000,
};

const showSnackbarNotificationDefaults = {
  ...showBaseSnackbarNotificationDefaults,
  dismissText: 'Dismiss',
};

const showLocalizedSnackbarNotificationDefaults = {
  ...showBaseSnackbarNotificationDefaults,
  dismissText: 'global.dismiss',
  duration: 3000
};

export interface SnackbarNotificationOptions {
  notificationText?: string;
  dismissText?: string;
  duration?: number;
  action?: 'info' | 'success' | 'error' | 'connecting';
  verticalPosition?: MatSnackBarVerticalPosition;
  horizontalPosition?: MatSnackBarHorizontalPosition;
}

@Injectable()
export class NotificationService {
  constructor(
    private snackBar: MatSnackBar,
    private translate: TranslateService
  ) { }

  public showSnackbarNotification({
    notificationText,
    dismissText = showSnackbarNotificationDefaults.dismissText,
    duration = showSnackbarNotificationDefaults.duration,
    action = 'info',
    verticalPosition = 'top',
    horizontalPosition = 'end',
  }: SnackbarNotificationOptions = showSnackbarNotificationDefaults): MatSnackBarRef<
    SimpleSnackBar
  > {
    return this.snackBar.open(notificationText, dismissText, {
      duration: duration,
      verticalPosition,
      horizontalPosition,
      panelClass: action,
    });
  }

  public showLocalizedSnackbarNotification({
    notificationText,
    dismissText = showLocalizedSnackbarNotificationDefaults.dismissText,
    duration = showLocalizedSnackbarNotificationDefaults.duration,
    action = 'info',
  }: SnackbarNotificationOptions = showLocalizedSnackbarNotificationDefaults): Observable<
    MatSnackBarRef<SimpleSnackBar>
  > {
    const result = this.translate.get([notificationText, dismissText]).pipe(
      map((translations: string[]) => {
        return this.snackBar.open(
          translations[notificationText],
          translations[dismissText],
          {
            duration: duration,
            verticalPosition: 'top',
            horizontalPosition: 'center',
            panelClass: action,
          }
        );
      }),
      publishReplay(1)
    );

    (result as ConnectableObservable<any>).connect();
    return result;
  }

  public showLocalizedSuccessMessage({
    notificationText,
    dismissText = showLocalizedSnackbarNotificationDefaults.dismissText,
    duration = showLocalizedSnackbarNotificationDefaults.duration,
  }) {
    return this.showLocalizedSnackbarNotification({
      notificationText,
      dismissText,
      duration,
      action: 'success',
    });
  }

  public showLocalizedErrorMessage({
    notificationText,
    dismissText = showLocalizedSnackbarNotificationDefaults.dismissText,
    duration = showLocalizedSnackbarNotificationDefaults.duration,
  }) {
    return this.showLocalizedSnackbarNotification({
      notificationText,
      dismissText,
      duration,
      action: 'error',
    });
  }

  public showPbxNotificationComponent(options: SnackbarNotificationOptions = {}): MatSnackBarRef<PbxNotificationComponent> {
    const snackBarConfig: MatSnackBarConfig = {
      data: { message: this.translate.instant(options.notificationText) },
      duration: options.duration || 0,
      verticalPosition: options.verticalPosition || 'top',
      horizontalPosition: options.horizontalPosition || 'center',
      panelClass: options.action || 'success' // 'connecting'
    };

    return this.snackBar.openFromComponent(PbxNotificationComponent, snackBarConfig);
  }

  public closePbxNotificationSnackbarComponent(snackBarRef: MatSnackBarRef<PbxNotificationComponent>): void {
    snackBarRef.dismiss();
  }
}
