import { Pipe, PipeTransform } from '@angular/core';
import { InstallmentStatusType } from '../../../core/types/installment';

@Pipe({
  name: 'installmentStatusToTranslationKey',
})
export class InstallmentStatusToTranslationKeyPipe implements PipeTransform {
  static readonly installmentStatusTranslationMap: { [key: string]: string } = {
    [InstallmentStatusType.OVERDUE]: 'credits.installmentStatusTypes.overdue',
    [InstallmentStatusType.NOT_MATURED]:
      'credits.installmentStatusTypes.notMatured',
    [InstallmentStatusType.PAID]: 'credits.installmentStatusTypes.paid',
    [InstallmentStatusType.MATURES_SOON]:
      'credits.installmentStatusTypes.maturesSoon',
    [InstallmentStatusType.MATURES_TODAY]:
      'credits.installmentStatusTypes.maturesToday',
  };

  transform(installmentStatus: InstallmentStatusType): string {
    return InstallmentStatusToTranslationKeyPipe
      .installmentStatusTranslationMap[installmentStatus];
  }
}
