import {Injectable} from '@angular/core';
import {RequestService} from './request.service';
import {Observable} from 'rxjs';
import {Permission} from '../types/permission';
import {PermissionSummary} from '../types/permission-summary';
import {PermissionAggregate} from '../types/permission-aggregate';

@Injectable()
export class PermissionService {
  request: RequestService;

  constructor(request: RequestService) {
    this.request = request;
  }

  getPermissionList(): Observable<Permission[]> {
    return this.request.get(['roles']);
  }

  generatePermissionSummary(permissions: Permission[]): PermissionSummary {
    const permissionSummary: PermissionSummary = {};
    permissions.forEach(
      (item: Permission, index: number, array: Permission[]) => {
        const keys = item.name.split('_').map(key => key.toLowerCase());
        let aggregateName = '';
        let permissionName = '';
        if (keys.length > 1) {
          aggregateName = keys.slice(0, keys.length - 1).join(' ');
          permissionName = keys[keys.length - 1];
        } else {
          aggregateName = keys[0];
          permissionName = keys[0];
        }

        if (!permissionSummary[aggregateName]) {
          permissionSummary[aggregateName] = {
            name: aggregateName,
            permissions: {},
          };
        }

        permissionSummary[aggregateName].permissions[permissionName] = {
          id: item.id,
          name: permissionName,
          description: item.description,
          active: false,
        };
      }
    );
    return permissionSummary;
  }

  generateAggregateArray(
    permissionSummary: PermissionSummary
  ): PermissionAggregate[] {
    const aggregateArray: PermissionAggregate[] = Object.values(
      permissionSummary
    );
    return aggregateArray;
  }

  sortAggregateArrayByRoleName(
    permissionAggregateArray: PermissionAggregate[]
  ): PermissionAggregate[] {
    return permissionAggregateArray.sort((a, b) =>
      a.name > b.name ? 1 : b.name > a.name ? -1 : 0
    );
  }

  populatePermissionSummary(
    permissionSummary: PermissionSummary,
    permissions: Permission[]
  ) {
    permissions.forEach(
      (item: Permission, index: number, array: Permission[]) => {
        const keys = item.name.split('_').map(key => key.toLowerCase());
        let aggregateName = '';
        let permissionName = '';
        if (keys.length > 1) {
          aggregateName = keys.slice(0, keys.length - 1).join(' ');
          permissionName = keys[keys.length - 1];
        } else {
          aggregateName = keys[0];
          permissionName = keys[0];
        }

        permissionSummary[aggregateName].permissions[
          permissionName
          ].active = true;
      }
    );
  }

  populatePermissionSummaryForGroups(permissionSummary: PermissionSummary, permissionGroups) {
    permissionGroups.forEach(group => {
      group.roles.forEach(
        (item: Permission, index: number, array: Permission[]) => {
          const keys = item.name.split('_').map(key => key.toLowerCase());
          let aggregateName = '';
          let permissionName = '';
          if (keys.length > 1) {
            aggregateName = keys.slice(0, keys.length - 1).join(' ');
            permissionName = keys[keys.length - 1];
          } else {
            aggregateName = keys[0];
            permissionName = keys[0];
          }

          permissionSummary[aggregateName].permissions[
            permissionName
            ].active = true;


          if (!permissionSummary[aggregateName].permissions[
            permissionName
        ].groupsWithPermission) {

            permissionSummary[aggregateName].permissions[
              permissionName
              ].groupsWithPermission = [];
          }

          permissionSummary[aggregateName].permissions[
            permissionName
            ].groupsWithPermission.push(group.name);

        }
      );
    });
  }


  generatePermissionArray(
    permissionAggregates: PermissionAggregate[]
  ): Permission[] {
    const permissions: Permission[] = [];
    permissionAggregates.forEach(
      (
        aggregate: PermissionAggregate,
        aggregateIndex: number,
        aggregates: PermissionAggregate[]
      ) => {
        const basePermissionName = aggregate.name;

        Object.keys(aggregate.permissions).forEach(
          (key: string, keyIndex: number, keys: string[]) => {
            // const permissionName = basePermissionName + '_' + aggregate.permissions[key].name;
            if (aggregate.permissions[key].active) {
              permissions.push({
                id: aggregate.permissions[key].id,
                // name: permissionName,
                description: aggregate.permissions[key].description,
              });
            }
          }
        );
      }
    );
    return permissions;
  }
}
