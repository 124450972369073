import {Component, Input, OnChanges, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import * as moment from 'moment';
import {Nssi7Summary, Nssi7SummaryContract} from '@core/types/identity-reports';

@Component({
  selector: 'itfg-nssi7-summary',
  templateUrl: './nssi7-summary.component.html',
  styleUrls: ['./nssi7-summary.component.scss']
})
export class Nssi7SummaryComponent implements OnInit, OnChanges {
  @Input() summary: Nssi7Summary;
  public nssi7SummaryColumns: any[];
  public activeContracts: Nssi7SummaryContract[];
  public terminatedContracts: Nssi7SummaryContract[];
  public workExperienceYears = 0;
  public workExperienceMonth = 0;
  public workExperienceTotalYears = 0;
  public workExperienceTotalMonth = 0;

  constructor(private translate: TranslateService) {
  }


  ngOnInit() {
    this.translate.get([
      'nssi.Bulstat',
      'nssi.ContractFoundationDate',
      'nssi.ContractReason',
      'nssi.ContractTerminationDate',
      'nssi.EmployerName',
      'nssi.Salary',
      'clients.workAddress',
      'clients.workExperience',
      'identityReports.workPosition'
    ])
      .subscribe(translations => {
        this.nssi7SummaryColumns = [
          {
            name: 'bulstat',
            label: translations['nssi.Bulstat'],
            width: 150,
          },
          {
            name: 'contractFoundationDate',
            label: translations['nssi.ContractFoundationDate'],
            width: 150,
          },
          {
            name: 'contractReason',
            label: translations['nssi.ContractReason'],
            width: 100
          },
          {
            name: 'contractTerminationDate',
            label: translations['nssi.ContractTerminationDate'],
            width: 150,
          },
          {
            name: 'employerName',
            label: translations['nssi.EmployerName'],
            width: 250,
          },
          {
            name: 'salary',
            label: translations['nssi.Salary'],
            numeric: true,
            width: 120,
          },
          {
            name: 'workAddress',
            label: translations['clients.workAddress'],
            width: 200,
          },
          {
            name: 'workExperience',
            label: translations['clients.workExperience'],
            width: 70,
          },
          {
            name: 'workPosition',
            label: translations['identityReports.workPosition'],
          }
        ];
      });
  }

  ngOnChanges() {
    this.parseSummary(this.summary);
  }
  parseSummary(summary) {
    this.activeContracts = summary?.activeContracts
      .sort((a, b) => moment(b.contractFoundationDate, 'DD/MM/YYYY').diff(moment(a.contractFoundationDate, 'DD-MM-YYYY')));
    this.terminatedContracts = summary?.terminatedContracts
      .sort((a, b) => moment(b.contractFoundationDate, 'DD/MM/YYYY').diff(moment(a.contractFoundationDate, 'DD-MM-YYYY')));
    this.workExperienceYears = Math.floor(summary?.workExperience / 12);
    this.workExperienceMonth = (summary?.workExperience % 12);
    this.workExperienceTotalYears = Math.floor(summary?.workExperienceTotal / 12);
    this.workExperienceTotalMonth = (summary?.workExperienceTotal % 12);
  }
}
