import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, FormGroup, Validators } from '@angular/forms';
import { IdentityReport, ListElement, MIReportData } from '../../../core/types/identity-reports';
import { IdentityReportsService } from '../../../core/services/identity-reports.service';
import { IdentifierValidation } from '../../../core/validation/identifier-validation';
import { NotificationService } from '../../../core/services/notification.service';
import { TranslateService } from '@ngx-translate/core';
import { DialogProviderService } from '@app/core/services/dialog-provider.service';
import { Utils } from '../../../core/utils/utils';

@Component({
  selector: 'itfg-mi-standalone',
  templateUrl: './mi-standalone.component.html',
  styleUrls: ['./mi-standalone.component.scss']
})
export class MiStandaloneComponent implements OnInit {
  public clientReportData: MIReportData;
  public readonly twoMontsMiliseconds: number;
  public dialogConfig: any;


  public reportData: IdentityReport<MIReportData>;
  public requestTime: Date;
  public currentDate: Date;
  public civilId: UntypedFormControl = new UntypedFormControl(null, [
    Validators.minLength(0),
    Validators.maxLength(10),
    IdentifierValidation.onlyNumbers,
    IdentifierValidation.isValidEgn,
  ]);
  public idCardNumber: UntypedFormControl = new UntypedFormControl(null, [
    Validators.minLength(0),
    Validators.maxLength(9),
    IdentifierValidation.isValidIdCardNumber
  ]);

  public isReport = true;
  public isNewReport = false;
  public isIdCardMatching = false;

  constructor(private identityReportsService: IdentityReportsService,
    private translate: TranslateService,
    private formBuilder: UntypedFormBuilder,
    private _viewContainerRef: ViewContainerRef,
    private dialogService: DialogProviderService,
    private notificationService: NotificationService) {
    this.currentDate = new Date();
    this.twoMontsMiliseconds = 1000 * 60 * 60 * 24 * 30 * 2;

  }

  ngOnInit(): void {
  }

  getLatestReport() {
    this.clientReportData = {};
    this.identityReportsService.getMiLatestReport(this.civilId.value)
      .subscribe(mvrReport => {
        this.reportData = mvrReport;
        this.requestTime = new Date(this.reportData?.time);
        // this.reportData?.data && Number(this.idCardNumber.value) === this.reportData?.data?.IdentityDocumentNumber
        //Temporary disabled check
        if (true) {
          this.clientReportData = this.reportData.data;
          this.isReport = true;
          this.isIdCardMatching = true;
        } else if (this.reportData?.data && Number(this.idCardNumber.value) !== this.reportData?.data?.IdentityDocumentNumber) {
          this.clientReportData = this.reportData.data;
          this.isReport = false;
          this.isIdCardMatching = false;
        } else {
          this.clientReportData = null;
          this.isReport = false;
          this.isIdCardMatching = false;
        }

        this.isNewReport = false;

      });
  }

  getNewMi() {
    this.translate
      .get([
        'mi.getNewMiReportWarning',
        'mi.miReportCriteriaMissing',
        'global.confirm',
        'global.cancel',
        'mi.getNewMiReport',
      ])
      .subscribe(translation => {
        this.dialogConfig = {
          message: translation['mi.getNewMiReportWarning'],
          disableClose: false,
          viewContainerRef: this._viewContainerRef,
          title: translation['mi.getNewMiReport'],
          cancelButton: translation['global.cancel'],
          acceptButton: translation['global.confirm'],
        };

        this.dialogService
          .openConfirm(this.dialogConfig)
          .afterClosed()
          .subscribe((accept: boolean) => {
            if (accept) {
              if (this.civilId.valid && this.idCardNumber.valid) {
                this.identityReportsService
                  .newMiReport(this.civilId.value, this.idCardNumber.value)
                  .subscribe(mvrReport => {
                    this.reportData = mvrReport;
                    this.requestTime = new Date(this.reportData.time);
                    this.clientReportData = this.reportData.data;
                    this.isReport = true;
                    this.isNewReport = true;
                    this.isIdCardMatching = true;
                  });
              } else {
                this.notificationService.showLocalizedSnackbarNotification({
                  notificationText: translation['mi.miReportCriteriaMissing'],
                  action: 'error',
                });
              }

            }
          });
      });
  }

  scrollToClientMapper() {
    console.log('scrollToClientMapper');
  }
}
