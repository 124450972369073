import { Injectable } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { NotificationService } from './notification.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError as observableThrowError } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable()
export class ErrorService {
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private notificationService: NotificationService
  ) {}

  public handleError(error, routeData) {
    if (error.status === 404) {
      let titleKey = '';
      routeData.subscribe((data: ParamMap) => console.log(data));
      routeData
        .pipe(filter(data => data['titleKey'] !== null))
        .subscribe((data: ParamMap) => (titleKey = data['titleKey']));
      this.router.navigate(['/resource-not-found', { titleKey: titleKey }], {
        skipLocationChange: true,
      });
    }
    if (error.status === 400) {
      this.router.navigate(['/page-not-found'], { skipLocationChange: true });
    }
  }

  public showSnackbarErrorMessage(
    errorResponse: HttpErrorResponse,
    caught?: Observable<any>
  ) {
    if (errorResponse.error.message) {
      this.notificationService.showLocalizedSnackbarNotification({
        notificationText: errorResponse.error.message,
        action: 'error',
      });
      return observableThrowError(errorResponse);
    }

    for (const error of errorResponse.error) {
      this.notificationService.showLocalizedSnackbarNotification({
        notificationText: error.code ? `error.${error.code}` : error.message,
        action: 'error',
      });
    }
    return observableThrowError(errorResponse);
  }
}
