import { FilterValueListService, FilterValue } from '../filter/types';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CreditStatusNames } from '@app/core/types/credit-status';

@Injectable()
export class CreditStatusNameValueListService
  implements FilterValueListService {
  constructor(private translate: TranslateService) {}

  getFilterValues$(): FilterValue[] {
    return Object.values(CreditStatusNames).map(value => ({
      value: value,
      displayText: this.translate.instant('credits.creditStatusList.' + value.toLowerCase()),
    }));
  }
}
