import { Injectable } from '@angular/core';
import { RequestService } from './request.service';
import { BehaviorSubject, Observable, startWith } from 'rxjs';
import { Administrator } from '../types/administrator';
import { Page } from '../types/page';
import { EntityHistoryService, EntityHistory } from '../types/entities';
import { EntityHistoryFilter } from '../types/entity-history-filter';
import { SearchOptionsDTO } from '../types/search-options';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class AdministratorService implements EntityHistoryService {
  request: RequestService;
  administratorsSubject = new BehaviorSubject<Administrator[]>([]);
  administrators$: Observable<Administrator[]> = this.administratorsSubject.asObservable()
  .pipe(startWith(this.administratorsSubject.getValue()));

  constructor(request: RequestService) {
    this.request = request;
    this.loadAdministrators();
  }

  getAdministratorList(params?: SearchOptionsDTO): Observable<Page<Administrator>> {
    return this.request.get(['administrators'], { params: params });
  }

  saveAdministrator(administrator): Observable<any> {
    return this.request.post(['administrators'], { body: administrator });
  }

  deleteAdministrator(id): Observable<any> {
    return this.request.delete(['administrators', id]);
  }

  getAdministratorById(id): Observable<any> {
    return this.request.get(['administrators', id]);
  }

  getAdministratorHistory(
    filter: EntityHistoryFilter
  ): Observable<EntityHistory<any>> {
    return this.request.get(['administrators', 'history'], {
      params: {
        filter: filter.getDTO(),
      },
    });
  }

  searchAdministratorsByNameContainingIgnoreCase$(
    name: string
  ): Observable<Administrator[]> {
    const requestBody = {
      params: {
        name,
      },
    };
    return this.request.get(
      ['administrators', 'search', 'findByNameContainingIgnoreCase'],
      requestBody
    );
  }

  enable2fa(administratorId: number) {
    return this.request.post(['administrators', administratorId, 'enable-2fa']);
  }

  disable2fa(administratorId: number) {
    return this.request.post(['administrators', administratorId, 'disable-2fa']);
  }

  revokeTrustedDevicesAcess(administratorId: number) {
    return this.request.post(['administrators', administratorId, 'revoke-trusted-devices']);
  }

  getEntityHistory(filter: EntityHistoryFilter) {
    return this.getAdministratorHistory(filter);
  }

  getAdministratorListSummary() {
    return this.request.get(['administrators', 'summary']);
  }

  loadAdministrators(): void {
    if (this.administratorsSubject.getValue().length > 0) {
      return;
    }

    this.getAdministratorListSummary().subscribe({
      next: (administrators: Administrator[]) => {
        this.administratorsSubject.next(administrators);
      },
      error: (error: HttpErrorResponse) => {
       this.administratorsSubject.next([])
      }
    });
  }
}
