<div *ngIf="client?.civilId && client?.id" class="top-padding-16">
  <h3 class="edit-screen-card-title">
    {{ 'credits.nssi' | translate }}
  </h3>
  <div
    flex
    layout="row"
    layout-wrap
    layout-align="space-between stretch"
  >
    <!--    <div-->
    <!--      *ngIf="nssiListData?.length > 0"-->
    <!--      flex-->
    <!--      layout="row"-->
    <!--      layout-align="start center"-->
    <!--      class="left-padding-16"-->
    <!--    >-->
    <!--      <h3 class="edit-screen-card-title" *ngIf="nssiData">-->
    <!--        {{ 'nssi.title.' + requestType | translate:{source: ('reports.sources.' + nssiData?.source | translate), cache: (metaStatusCode | reportCacheRegister) } }} <span *ngIf="requestTime">{{'nssi.from' | translate}}{{requestTime | date}}</span>-->
    <!--      </h3>-->
    <!--      <h3 *ngIf="!isReport" [translate]="'nssi.noReports'">No reports</h3>-->

    <!--    </div>-->
    <div flex="100" flex-gt-md="75" flex-gt-sm="100" flex-gt-xs="100">
      <itfg-reports-info-panel
        [listData]="nssiListData"
        [reportData]="nssiData"
        [reportTime]="reportTime"
        [requesterName]="requesterName"
        [metaStatusCode]="metaStatusCode"
        [requestTime]="requestTime"
        (dataChanged)="nssiDataChanged($event)"
      ></itfg-reports-info-panel>
    </div>
    <div flex layout="row" layout-align="end start" [style.min-height.px]="65">
      <itfg-client-differences-button
        [config]="nssiConfig"
      >
      </itfg-client-differences-button>
      <div *ngIf="!nssiAutomationLatestScore && creditId">
        <button mat-icon-button
                type="button" (click)="nssiAutomation()"
                [disabled]="['NSSI_ADD'] | isUnauthorized | async"
                matTooltipPosition="above"
                matTooltip="{{ 'nssi.automation' | translate }}">
          <mat-icon>auto_mode</mat-icon>
        </button>
      </div>
      <div class="right-padding-16" *ngIf="nssiAutomationLatestScore?.id && creditId">
        <button mat-icon-button
                [style.color]="NSSI_AUTOMATION_ACTION_MAPPER[nssiAutomationScoreData?.action].color"
                type="button" (click)="openAutomationDetails()"
                [disabled]="['NSSI_ADD'] | isUnauthorized | async"
                matTooltipPosition="above"
                matTooltip="{{'nssi.automationReasons.' + nssiAutomationScoreData?.reason | translate}}">
          <mat-icon>{{NSSI_AUTOMATION_ACTION_MAPPER[nssiAutomationScoreData?.action]?.icon}}</mat-icon>
        </button>
      </div>
      <div class="white-space-no-wrap">
        <button
          mat-raised-button
          [disabled]="['NSSI_ADD'] | isUnauthorized | async"
          (click)="getNewNssi()"
        >
          {{ 'nssi.newReport' | translate }}
        </button>
      </div>
    </div>
  </div>
  <div
    *ngIf="nssiListData?.length === 0 && !isReport"
    flex
    layout="row"
    layout-align="start"
    class="left-padding-16"
  >
    <h4 [translate]="'nssi.noNssiReports'">No reports</h4>
  </div>
  <div *ngIf="isReport">
    <ng-container [ngSwitch]="requestStatus">
      <ng-container *ngSwitchCase="'success'">
        <ng-container
          *ngIf="nssiListData?.length > 0 && requestType === NssiTypes.NSSI7"
        >
          <mat-card appearance="outlined">
            <mat-card-content>
            <span
              *ngIf="nssiListData.length === 0"
              flex
              layout="row"
              class="left-margin-25px"
              [translate]="'global.noData'"
            >
              No data</span
            >
              <itfg-data-table
                #dataTable
                [data]="nssi7ReportData"
                [columns]="nssi7Columns"
                [isClickable]="true"
              >
                <ng-template
                  itfgDataTableTemplate="actions"
                  let-value="value"
                  let-data="row"
                >
                  <div layout="row">
                    <a mat-icon-button [matMenuTriggerFor]="nssi7Actions" flex>
                      <mat-icon>more_vert</mat-icon>
                    </a>
                    <mat-menu #nssi7Actions="matMenu">
                      <!-- <itfg-client-differences-button
                      [config]="nssiCompanyConfig"
                    >
                    </itfg-client-differences-button> -->
                      <button mat-button (click)="updateClientOccupationData(data, CLIENT_MAPPER_NSSI7)">
                        {{ 'nssi.updateClient' | translate }}
                      </button>
                    </mat-menu>
                  </div>
                </ng-template>
              </itfg-data-table>
            </mat-card-content>
          </mat-card>
          <div *ngIf="nssi7Summary">
            <itfg-nssi7-summary [summary]="nssi7Summary"></itfg-nssi7-summary>
          </div>
        </ng-container>

        <ng-container
          *ngIf="nssiListData?.length > 0 && requestType === NssiTypes.NSSI2"
        >
        <span
          *ngIf="nssiListData?.length === 0"
          flex
          layout="row"
          class="left-margin-25px"
          [translate]="'global.noData'"
        >
          No data</span
        >
          <div *ngFor="let bulstatData of nssiBulstatData">
            <div>
              <mat-card appearance="outlined">
                <mat-card-content>
                  <div
                    flex
                    layout="row"
                    layout-wrap
                    layout-align="space-between stretch"
                  >
                    <mat-card *ngIf="!bulstatData?.Name" appearance="outlined" flex="100">
                      <mat-card-content
                        flex="100"
                        layout="row"
                        layout-wrap
                        layout-align="start"
                      >
                        <h4 flex="100"
                            layout="row"
                            layout-wrap
                            layout-align="start">{{ 'nssi.missingEmployerDataMessage' | translate }}</h4>
                      </mat-card-content>
                    </mat-card>
                    <mat-card *ngIf="bulstatData?.Name" appearance="outlined" flex="100">
                      <mat-card-content
                        flex="100"
                        layout="row"
                        layout-wrap
                        layout-align="space-between center"
                      >
                        <div
                          flex="100"
                          layout="row"
                          layout-wrap
                          layout-align="space-between center"
                        >
                          <mat-form-field flex class="right-padding-16">
                            <input
                              matInput
                              readonly
                              type="text"
                              placeholder="{{ 'nssi.Bulstat' | translate }}"
                              [value]="bulstatData?.Bulstat"
                            />
                            <mat-label>{{ 'nssi.Bulstat' | translate }}</mat-label>
                          </mat-form-field>
                          <mat-form-field flex class="right-padding-16">
                            <input
                              matInput
                              readonly
                              type="text"
                              placeholder="{{ 'nssi.EmployerName' | translate }}"
                              [value]="bulstatData?.Name"
                            />
                            <mat-label>{{ 'nssi.EmployerName' | translate }}</mat-label>
                          </mat-form-field>
                          <mat-form-field flex class="right-padding-16">
                            <input
                              matInput
                              readonly
                              type="text"
                              placeholder="{{ 'nssi.EmployerAddress' | translate }}"
                              [value]="bulstatData?.address ? bulstatData?.address : ''"
                            />
                            <mat-label>{{ 'nssi.EmployerAddress' | translate }}</mat-label>
                          </mat-form-field>
                        </div>
                      </mat-card-content>
                    </mat-card>
                  </div>
                  <itfg-data-table
                    #dataTable
                    [data]="nssi2ReportData[bulstatData.Bulstat]"
                    [columns]="nssi2Columns"
                    [isClickable]="true"
                  >
                    <ng-template
                      itfgDataTableTemplate="actions"
                      let-value="value"
                      let-data="row"
                    >
                      <div layout="row">
                        <a mat-icon-button [matMenuTriggerFor]="nssi2Actions" flex>
                          <mat-icon>more_vert</mat-icon>
                        </a>
                        <mat-menu #nssi2Actions="matMenu">
                          <!-- <itfg-client-differences-button
                          [config]="getReportDataForRow(data)"
                        >
                          {{ 'nssi.updateClient' | translate }}
                        </itfg-client-differences-button> -->
                          <button
                            mat-button
                            (click)="updateClientOccupationData(data, CLIENT_MAPPER_NSSI2)"
                          >
                            {{ 'nssi.updateClient' | translate }}
                          </button>
                        </mat-menu>
                      </div>
                    </ng-template>
                    <ng-template
                      itfgDataTableTemplate="Typeofisured"
                      let-value="value"
                      let-data="row"
                    >
                      <b matTooltip="{{('nssi.typeOfIsued' + value) | translate}}">{{value}}</b>
                    </ng-template>

                  </itfg-data-table>
                </mat-card-content>
              </mat-card>
            </div>
          </div>
        </ng-container>

        <div
          flex
          layout="row"
          layout-wrap
          layout-align="space-between stretch"
          *ngIf="nssi51Data?.data?.Pensioner && requestType === NssiTypes.NSSI51"
        >
          <itfg-nssi-pensioner-report [report]="nssi51Data"></itfg-nssi-pensioner-report>
        </div>
      </ng-container>

      <ng-container *ngSwitchCase="'no_data'">
        <h4 layout="row" layout-align="start">{{ 'nssi.noDataMessage' | translate }}</h4>
      </ng-container>

      <ng-container *ngSwitchCase="'server_error'">
        <h4 layout="row"
            layout-align="start">{{ 'reports.serverGenericError' | translate: {requestTime: requestTime | itfgDateFormat} }}
          <itfg-no-reports-info
            [alertMessage]="{civilId: client?.civilId, type: nssiData?.type, source: nssiData?.source, status: nssiData?.status, time: nssiData?.time, data: nssiData?.data}">
          </itfg-no-reports-info>
        </h4>
      </ng-container>
      <ng-container *ngSwitchCase="'server_timeout'">
        <h4 layout="row"
            layout-align="start">{{ 'reports.serverTimeoutError' | translate: {requestTime: requestTime | itfgDateFormat} }}
          <itfg-no-reports-info
            [alertMessage]="{civilId: client?.civilId, type: nssiData?.type, source: nssiData?.source, status: nssiData?.status, time: nssiData?.time, data: nssiData?.data}">
          </itfg-no-reports-info>
        </h4>
      </ng-container>

    </ng-container>

  </div>
</div>
