import { Directive, Output, EventEmitter, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Subject, timer } from 'rxjs';
import { buffer, debounceTime, map, switchMap, takeUntil, tap } from 'rxjs/operators';

@Directive({
  selector: '[itfgDetectMouseClick]'
})
export class DetectMouseClickDirective implements OnInit, OnDestroy {
  private click$ = new Subject<MouseEvent>();
  private destroy$ = new Subject<void>();

  @Output()
  doubleClick = new EventEmitter<MouseEvent>();

  @Output()
  singleClick = new EventEmitter<MouseEvent>();

  @Output()
  ctrlClick = new EventEmitter<MouseEvent>();

  @HostListener('click', ['$event'])
  onClick(event: MouseEvent) {
    if (event.ctrlKey) {
      this.ctrlClick.emit(event);
    } else {
      this.click$.next(event);
    }
  }

  ngOnInit() {
    this.click$
      .pipe(
        buffer(this.click$.pipe(debounceTime(250))),
        map(clicks => clicks),
        switchMap(clicks => {
          if (clicks.length === 2) {
            this.doubleClick.emit(clicks[1]);
            return timer(0);
          } else if (clicks.length === 1) {
            return timer(250).pipe(
              takeUntil(this.click$),
              tap(() => this.singleClick.emit(clicks[0]))
            );
          } else {
            return timer(0);
          }
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    this.click$.complete();
  }
}
