import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'toCustomItfgAmount'
})
export class ToCustomItfgAmountPipe implements PipeTransform {

  transform(number) {
    if (number === null || number === undefined) {
      return null;
    }
    number = Number(number).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1 ');

    return number;
  }
}
