import { Component, OnInit } from '@angular/core';
import { SessionService } from '../../../../../app/core/services/session.service';
import { AdministratorService } from '@app/core/services/administrator.service';
import { NotificationService } from '@app/core/services/notification.service';
import { Administrator } from '@app/core/types/administrator';
import { TranslateService } from '@ngx-translate/core';
import { DateFormat } from '@app/core/types/date-format';
import { DialogProviderService } from '@app/core/services/dialog-provider.service';
import { filter, mergeMap } from 'rxjs';

@Component({
  selector: 'itfg-administrator-profile',
  templateUrl: './administrator-profile.component.html',
  styleUrls: ['./administrator-profile.component.scss'],
})
export class AdministratorProfileComponent implements OnInit {
  operator: Administrator = {};
  columns = [];
  fromNowDateFormat = DateFormat.FROM_NOW;

  constructor(
    private sessionService: SessionService,
    private notification: NotificationService,
    private translateService: TranslateService,
    private _dialogService: DialogProviderService,
  ) { }

  ngOnInit() {
    this.operator = this.sessionService.currentlyLoggedOperator;
    this.sessionService.administratorLoaded.subscribe(user => {
      this.operator = user;
    });
    this.initializeColumns();
  }

  initializeColumns() {
    this.translateService
      .get([
        'twoFactorAuth.devices.expire',
        'twoFactorAuth.devices.createTime',
        'twoFactorAuth.devices.lastUsed',
        'twoFactorAuth.devices.browser',
        'global.actions',
      ])
      .subscribe(translations => {
        this.columns = [
          {
            name: 'userAgent.agent',
            label: translations['twoFactorAuth.devices.browser'],
            width: { max: 200 },
          },
          {
            name: 'createTime',
            label: translations['twoFactorAuth.devices.createTime'],
          },
          {
            name: 'lastUsed',
            label: translations['twoFactorAuth.devices.lastUsed'],
          },
          {
            name: 'expire',
            label: translations['twoFactorAuth.devices.expire'],
          },
          {
            name: 'actions',
            label: translations['global.actions'],
          },
        ];
      });
  }

  revokeTrustedDeviceById(deviceId: number) {
    this._dialogService
      .openConfirm({
        message: this.translateService.instant('twoFactorAuth.revokeTrustedDevicePrompt'),
        disableClose: false,
      })
      .afterClosed()
      .pipe(
        filter(accept => accept === true),
        mergeMap(() => this.sessionService.revokeTrustedDeviceById(deviceId)),
        mergeMap(() => this.sessionService.updaateCurrentlyLoggedOperator$())
      )
      .subscribe(() => {
        this.notification.showLocalizedSuccessMessage({
          notificationText: 'twoFactorAuth.successfullyRevokedTrustedDevice',
        });
      });
  }

  revokeTrustedDevicesAccess() {
    this._dialogService
      .openConfirm({
        message: this.translateService.instant('twoFactorAuth.revokeTrustedDevicesPrompt'),
        disableClose: false,
      })
      .afterClosed()
      .pipe(
        filter(accept => accept === true),
        mergeMap(() => this.sessionService.revokeTrustedDevices()),
        mergeMap(() => this.sessionService.updaateCurrentlyLoggedOperator$())
      )
      .subscribe(() => {
        this.notification.showLocalizedSuccessMessage({
          notificationText: 'twoFactorAuth.successfullyRevokedTrustedDevice',
        });
      });
  }

}
