import { Pipe, PipeTransform } from '@angular/core';
import { City } from '../../core/types/city';

@Pipe({
  name: 'cityDisplayText',
})
export class CityDisplayTextPipe implements PipeTransform {
  transform(city: City): any {
    return city
      ? (city.displayText = `${city.type}${city.name} - ${city.municipality.name}`)
      : '';
  }
}
