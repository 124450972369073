<div layout="column">
  <itfg-client-conversation
  [client] = client
>
</itfg-client-conversation>  

  <itfg-client-sms
    [client] = client 
  >
  </itfg-client-sms>
  <itfg-client-email
    [client] = client 
  >
  </itfg-client-email> 
</div>
