import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'creditInstalmentPeriod',
})
export class CreditInstalmentPeriodPipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  transform(value: any, args?: any): any {
    let period = 0;
    if (value % 7 === 0) {
      period = value / 7;
      return period === 1
        ? period + ' ' + this.translateService.instant('global.week')
        : period + ' ' + this.translateService.instant('global.weeks');
    } else if ((value * 10000) % (30.41666 * 10000) === 0) {
      period = (value * 10000) / (30.41666 * 10000);
      return period === 1
        ? period + ' ' + this.translateService.instant('global.month')
        : period + ' ' + this.translateService.instant('global.months');
    } else {
      period = value;
      return period + ' ' + this.translateService.instant('global.days');
    }
  }
}
