import { Injectable } from '@angular/core';
import { RequestService } from './request.service';
import { Observable } from 'rxjs';
import { Page } from '../types/page';

@Injectable()
export class CreditDocumentService {
  constructor(private request: RequestService) {}

  getCreditDocumentList(creditId: number, options): Observable<Page<any>> {
    return this.request.get(['credits', creditId, 'documents'], {
      params: options,
    });
  }

  getDocumentHtmlPreview(
    creditId: number,
    documentTemplateCode
  ): Observable<any> {
    return this.request.get(
      ['credits', creditId, 'documents', 'preview', 'html'],
      {
        params: { documentTemplateCode: documentTemplateCode },
        responseType: 'document',
      }
    );
  }

  getDocumentHtml(creditId: number, documentId: number): Observable<any> {
    return this.request.get(
      ['credits', creditId, 'documents', documentId, 'html'],
      { responseType: 'document' }
    );
  }

  getDocumentPdfPreview(
    creditId: number,
    documentTemplateCode
  ): Observable<any> {
    return this.request.get(
      ['credits', creditId, 'documents', 'preview', 'pdf'],
      {
        params: { documentTemplateCode: documentTemplateCode },
        responseType: 'blob',
      }
    );
  }

  getDocumentPdf(creditId: number, documentId: number): Observable<any> {
    return this.request.get(
      ['credits', creditId, 'documents', documentId, 'pdf'],
      { responseType: 'blob' }
    );
  }

  getDocumentById(creditId: number, documentId: number): Observable<any> {
    return this.request.get(['credits', creditId, 'documents', documentId]);
  }

  createCreditDocumentList(
    creditId: number,
    documentTemplateCode
  ): Observable<any> {
    return this.request.post(['credits', creditId, 'documents'], {
      body: { documentTemplateCode: documentTemplateCode },
    });
  }

  getInsuranceCertificateDocument(creditId: number): Observable<any> {
    return this.request.get(['insurances', 'credits', creditId, 'certificate', 'pdf'],
    { responseType: 'blob' }
    );
  }
}
