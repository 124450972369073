<ng-container *ngIf="tableData && ccrSummary && translations">
  <div flex layout="row" layout-align="space-between center">
      <h3 class="summary-title" >
        {{ "identityReports.summaryTitle" | translate}}
      </h3>
      <itfg-settings-menu
      [columns]="columns"
      [sessionSettingsPageName]="ccrSummary"
      >
    </itfg-settings-menu>
  </div>
  <itfg-column-card #columnCard
  hidden
  [columns]="columns"
  [sessionSettingsPageName]="ccrSummary">
  </itfg-column-card>
  
  <div flex class="variables-container" layout="row" layout-wrap  layout-align="start center">
    <div flex="25" *ngFor="let column of appliedColumns ">
      <mat-card class="summary-prop" appearance="outlined">
        <div class="variable-container" layout="row" layout-align="space-between center">
          <p>{{column.label}}</p>
          <p>{{ccrSummary[column.name]}}</p>
        </div>
      </mat-card>
    </div>
  </div>

</ng-container>