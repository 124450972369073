import 'reflect-metadata';
import { Component, Inject } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { MatIconRegistry } from '@angular/material/icon';
import { ConsoleToggleService } from './core/services/consoleToggleService.service';
// import { SpinnerOverlayService } from './core/services/spinner-overlay.service';
// import { LoaderService } from './core/services/loader.service';

@Component({
  selector: 'itfg-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'app';

  constructor(
    translate: TranslateService,
    public matIconRegistry: MatIconRegistry,
    private consoleToggleService: ConsoleToggleService
    // private loader: LoaderService,
    // private spinnerOverlay: SpinnerOverlayService,
  ) {
    // this.loader.loadingBus$.subscribe((isLoading: boolean) => { // uncomment to enable overlay loader
    //   if (isLoading) {
    //     this.spinnerOverlay.show();
    //   } else {
    //     this.spinnerOverlay.hide();
    //   }
    // })
    this.consoleToggleService.disableConsoleInProduction();
    matIconRegistry.registerFontClassAlias('fontawesome', 'fa');
    matIconRegistry.registerFontClassAlias('fontawesome', 'fas');
  }
}
