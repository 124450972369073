import { tap } from 'rxjs/operators';
import { lastValueFrom } from 'rxjs';
import { Injectable, Injector } from '@angular/core';
import { RequestService } from './request.service';
import { UserDetails } from '../types/user-details';
import { NgxPermissionsService } from '../../../../node_modules/ngx-permissions';
import { SessionService } from './session.service';

@Injectable()
export class PermissionsService {
  constructor(
    private permissions: NgxPermissionsService,
    private injector: Injector,
  ) {}

  get request() {
    return this.injector.get(RequestService);
  }

  get session() {
    return this.injector.get(SessionService);
  }

  async initialize() {
    return lastValueFrom(this.request
      .get(['me', 'details'])
      .pipe(
        tap((user: UserDetails) => {
          this.session.currentlyLoggedOperator = user;
          this.permissions.loadPermissions(user.roles);
        })
      ))
      .catch(err => {
        if (err.status === 403) {
          this.session.logout();
        }
      });
  }
}
