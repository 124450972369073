<div flex
     layout="row"
     layout-align="center center">
  <div
    flex
    layout="row"
    layout-align="center center"
    class="button-radius-3px"
    [ngStyle]="styleOptions"
  >
    <button mat-button
      class="mat-elevation-z2"
      [matTooltip]="statusHoverText"
      matTooltipPosition="above">
      <mat-icon>{{ icon }}</mat-icon>
      <span class="button-font-size">{{ statusDisplayText }}&nbsp;&nbsp;&nbsp;</span>
    </button>
  </div>

</div>
