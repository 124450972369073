import { ValidationErrors } from '@angular/forms';
import { UntypedFormControl } from '@angular/forms';

export const LanguageValidation = {
  hasLatin: hasLatin,
  lacksLatin: lacksLatin,
  hasCyrillic: hasCyrillic,
  lacksCyrillic: lacksCyrillic,
};

function hasCyrillic(control: UntypedFormControl): ValidationErrors {
  if (control.value === null) {
    return null;
  }

  if (control.value.match(/[\u0400-\u04FF]/)) {
    return null;
  } else {
    return { hasCyrillic: { value: control } };
  }
}

function lacksCyrillic(control: UntypedFormControl): ValidationErrors {
  if (control.value === null) {
    return null;
  }

  if (!control.value.match(/[\u0400-\u04FF]/)) {
    return null;
  } else {
    return { lacksCyrillic: { value: control } };
  }
}

function hasLatin(control: UntypedFormControl): ValidationErrors {
  if (control.value === null) {
    return null;
  }

  if (control.value.match(/[\u0021-\u007F]/)) {
    return null;
  } else {
    return { hasLatin: { value: control } };
  }
}

function lacksLatin(control: UntypedFormControl): ValidationErrors {
  if (control.value === null) {
    return null;
  }

  if (!control.value.match(/[\u0041-\u007A]/)) {
    return null;
  } else {
    return { lacksLatin: { value: control } };
  }
}
