import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ClaimComponent } from './claim.component';
import { APP_CONFIG } from '../../config/app-config';

const ACCESS_DENIED_ROUTE = APP_CONFIG.ACCESS_DENIED_ROUTE;

export const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        component: ClaimComponent,
        data: {
          breadcrumbKey: 'clients.claims',
          permissions: {
            only: ['CLAIM_READ'],
            redirectTo: ACCESS_DENIED_ROUTE,
          },
        },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ClaimRoutingModule {}
