import {
  FilterConfig,
  FilterUIType,
  FilterCardConfig,
  FilterGroupType,
} from '../../filter/types';
import { Injectable } from '@angular/core';
import {
  SearchOperations,
  SearchCriterium,
} from '../../../core/types/search-criterium';
import { PageName } from '../../../core/types/user-preferences';

@Injectable()
export class IncompleteClientListFilterConfigService {
  config: FilterCardConfig;
  pageName: PageName;
  initialFilters: SearchCriterium[];

  constructor() {
    this.pageName = PageName.CLIENTS;

    this.config = {
      id: new FilterConfig({
        path: 'id',
        type: FilterUIType.INPUT,
        group: FilterGroupType.CLIENT,
        availableOperations: [
          SearchOperations.EQUALS,
          SearchOperations.LESS_THAN,
          SearchOperations.GREATER_THAN,
        ],
      }),
      createTime: new FilterConfig({
        path: 'createTime',
        type: FilterUIType.DATEPICKER,
        group: FilterGroupType.CLIENT,
        availableOperations: [
          SearchOperations.EQUALS,
          SearchOperations.LESS_THAN,
          SearchOperations.GREATER_THAN,
        ],
      }),
      civilId: new FilterConfig({
        path: 'civilId',
        type: FilterUIType.INPUT,
        availableOperations: [SearchOperations.EQUALS],
      }),
      email: new FilterConfig({
        path: 'email',
        type: FilterUIType.INPUT,
        group: FilterGroupType.CLIENT,
        availableOperations: [SearchOperations.EQUALS, SearchOperations.LIKE],
      }),
      mobile: new FilterConfig({
        group: FilterGroupType.CLIENT,
        path: 'mobile',
        type: FilterUIType.INPUT,
        availableOperations: [SearchOperations.EQUALS, SearchOperations.LIKE],
      }),
    };

    this.initialFilters = [
      {
        key: 'createTime',
        operation: SearchOperations.GREATER_THAN,
        value: '',
      },
      {
        key: 'createTime',
        operation: SearchOperations.LESS_THAN,
        value: '',
      },
    ];
  }
}
