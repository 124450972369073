import { CreditStatusNames } from '../core/types/credit-status';
import { InstallmentStatusType } from '../core/types/installment';
import {PaymentAgreementStatusNames} from '../core/types/payment-agreement';

export interface StyleConfigMap {
  [key: string]: {
    backgroundColor?: string;
    class?: string;
    color?: string;
    icon?: string;
    name?: string;
  };
}

export const CLIENT_LOG_TOGGLE = 'CLIENT_LOG_TOGGLE';

export const CREDIT_STATUS_STYLE_MAP: StyleConfigMap = {
  [CreditStatusNames.NEW]: {
    backgroundColor: '#1E90FF',
    icon: 'fiber_new',
  },
  [CreditStatusNames.APPROVED]: {
    backgroundColor: '#0000CD',
    icon: 'done',
  },
  [CreditStatusNames.CONFIRMED]: {
    backgroundColor: '#000080',
    icon: 'done_all',
  },
  [CreditStatusNames.REJECTED]: {
    backgroundColor: '#e53935',
    icon: 'cancel',
  },
  [CreditStatusNames.REGULAR]: {
    backgroundColor: 'rgb(96, 163, 96)',
    icon: 'check_circle_outline',
  },
  [CreditStatusNames.OVERDUE]: {
    backgroundColor: '#ee753c',
    icon: 'warning',
  },
  [CreditStatusNames.LOSS]: {
    backgroundColor: '#696969',
    icon: 'trending_down',
  },
  [CreditStatusNames.REPAID]: {
    backgroundColor: '#228B22',
    icon: 'attach_money',
  },
  MONEYSENT: {
    backgroundColor: '#3f51b5',
    icon: 'payment',
  },
};

export const INSTALLMENT_STATUS_STYLE_MAP: StyleConfigMap = {
  [InstallmentStatusType.OVERDUE]: {
    color: 'rgba(235, 88, 18, 0.82)',
    icon: 'warning',
  },
  [InstallmentStatusType.PAID]: {
    color: 'green',
    icon: 'check_circle_outline',
  },
  [InstallmentStatusType.MATURES_SOON]: {
    color: '#ff6c00',
    icon: 'timelapse',
  },
  [InstallmentStatusType.MATURES_TODAY]: {
    color: '#ff6c00',
    icon: 'warning',
  },
  [InstallmentStatusType.NOT_MATURED]: {
    color: '#dddddd',
    icon: 'timelapse',
  },
};
export const PAYMENT_AGREEMENT_STATUS_STYLE_MAP = {
  [PaymentAgreementStatusNames.DRAFT]: {
    color: '#1E90FF',
    icon: 'fiber_new',
  },
  [PaymentAgreementStatusNames.APPROVED]: {
    color: '#0000CD',
    icon: 'done',
  },
  [PaymentAgreementStatusNames.ACTIVE]: {
    color: 'rgb(96, 163, 96)',
    icon: 'check_circle_outline'
  },
  [PaymentAgreementStatusNames.PAID]: {
    color: '#228B22',
    icon: 'attach_money',
  },
  [PaymentAgreementStatusNames.CANCELED]: {
    color: 'red',
    icon: 'cancel',
  },
};
