import { Component, Input, OnInit } from '@angular/core';
import { ClientOptionsMenuConfig } from '../../../../core/types/client';

@Component({
  selector: 'itfg-client-options-menu',
  templateUrl: './client-options-menu.component.html',
  styleUrls: ['./client-options-menu.component.scss'],
})
export class ClientOptionsMenuComponent implements OnInit {
  @Input() clientId: number;
  @Input() isInputClient: boolean;
  @Input()
  public config: ClientOptionsMenuConfig = new ClientOptionsMenuConfig();
  @Input() sendPasswordBySms: Function;
  @Input() sendPasswordByEmail: Function;
  @Input() data: any;
  @Input() isNewItem: boolean;

  constructor() {}

  ngOnInit() {}

  onTempPassSmsClick() {
    this.sendPasswordBySms();
  }

  onTempPassEmailClick() {
    this.sendPasswordByEmail();
  }
}
