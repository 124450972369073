export interface CcrRequests {
  rows: CcrRequestsRow[];
}

export interface CcrRequestsRow {
  key: string;
  value: CcrRequestsRowValue;
}

export class CcrRequestsRowValue {
  banks: CcrRequestsData;
  nonBanks: CcrRequestsData;
  total: number;

}
export interface CcrRequestsData {
  entityCount?: number;
  value?: number;
}
export enum CcrRequestsPeriods  {
  '3 дни' = 'CCR_3_Days',
  '5 дни' = 'CCR_5_Days',
  '10 дни' = 'CCR_10_Days',
  '20 дни' = 'CCR_20_Days',
  '30 дни' = 'CCR_30_Days'
}
