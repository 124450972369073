import {
  Component,
  OnInit,
  Inject,
  OnDestroy,
  ElementRef,
  QueryList,
  ViewChildren,
  Renderer2,
} from '@angular/core';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatDatepicker } from '@angular/material/datepicker';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { Filter, FilterCardConfig, FilterUIType, FilterValue } from '../types';
import { FilterGroup } from '../types/filter-group';
import { Observable } from 'rxjs';
import { UntypedFormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil, debounceTime } from 'rxjs/operators';

@Component({
  selector: 'itfg-filter-card-add-filter',
  templateUrl: './filter-card-add-filter.component.html',
  styleUrls: ['./filter-card-add-filter.component.scss'],
})
export class FilterCardAddFilterComponent implements OnInit, OnDestroy {
  filter: Filter;
  filtersConfig: FilterCardConfig;
  groupedFiltersConfig: FilterGroup[];
  filterOptionsCtrl: UntypedFormControl = new UntypedFormControl();
  _unsubscribe = new Subject<void>();
  autocompleteValueChanged: Subject<any>;
  dropdownFilterCtrl = new UntypedFormControl();
  filterUITypes: any;
  operationsListTranslationMap: {
    [key: string]: string;
  };
  @ViewChildren('valueInput') valueInputs: QueryList<ElementRef>;
  filterValuesCopy: FilterValue[];

  private _filterOptions(value: string): void {
    const filterValue = value.toLowerCase();

    const filteredOptions = Object.keys(this.filtersConfig)
      .filter(key =>
        this.filtersConfig[key].translatedKey
          .toLowerCase()
          .includes(filterValue)
      )
      .reduce((obj, key) => {
        obj[key] = this.filtersConfig[key];
        return obj;
      }, {});
    this.groupedFiltersConfig = this.groupFilterOptions(filteredOptions);
  }

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      filter: Filter;
      filtersConfig: FilterCardConfig;
      autocompleteValueChanged: Subject<any>;
      filterUITypes: any;
      operationsListTranslationMap: {
        [key: string]: string;
      };
    },
    private dialogRef: MatDialogRef<FilterCardAddFilterComponent>,
    public renderer: Renderer2
  ) {
    this.filtersConfig = data.filtersConfig;
    this.autocompleteValueChanged = data.autocompleteValueChanged;
    this.filterUITypes = this.data.filterUITypes;
    this.operationsListTranslationMap = this.data.operationsListTranslationMap;
    this.filter = this.data.filter;
  }

  ngOnInit() {
    this.groupedFiltersConfig = this.groupFilterOptions(this.filtersConfig);
    this.watchForfilterOptionsCtrlValueChanges();
  }

  addFilter() {
    this.dialogRef.close(this.filter);
  }

  onFilterKeySelectionChange(newSelection: MatSelectChange) {
    this.filter.config = this.filtersConfig[newSelection.value];
    this.filter.criterium.operation = this.filter.config.availableOperations[0];
    this.filter.criterium.value = '';
    if (
      this.filter.config.type === FilterUIType.MULTISELECT ||
      this.filter.config.type === FilterUIType.MULTISELECT_AUTOCOMPLETE
    ) {
      this.filter.criterium.value = [];
    } else {
      this.filter.criterium.value = '';
    }
    if (
      this.filter.config.valueListService &&
      (this.filter.config.type === FilterUIType.DROPDOWN ||
        this.filter.config.type === FilterUIType.MULTISELECT)
    ) {
      const filterValues = this.filter.config.valueListService.getFilterValues$();
      this.handleNewFilterValues(filterValues);
    }
  }

  refreshAutocompleteValues(searchValue: string) {
    this.autocompleteValueChanged.next({
      filter: this.filter,
      searchValue: searchValue,
    });
  }

  groupFilterOptions(filtersConfig: FilterCardConfig): FilterGroup[] {
    const groupedFiltersConfig = [];

    Object.keys(filtersConfig).forEach(key => {
      const filterConfig = filtersConfig[key];
      let targetedGroup = groupedFiltersConfig.find(
        (group: FilterGroup) => group.name === filterConfig.groupTranslationKey
      );

      if (!targetedGroup) {
        groupedFiltersConfig.push({
          name: filterConfig.groupTranslationKey,
          filters: {},
        });
      }
      targetedGroup = groupedFiltersConfig.find(
        (group: FilterGroup) => group.name === filterConfig.groupTranslationKey
      );
      targetedGroup.filters[key] = filterConfig;
    });

    return groupedFiltersConfig;
  }

  handleNewFilterValues(
    filterValues: FilterValue[] | Observable<FilterValue[]>
  ) {
    if (filterValues instanceof Observable) {
      filterValues.subscribe((filterValueList: FilterValue[]) => {
        this.filter.filterValues = filterValueList;
        this.filterValuesCopy = this.filter.filterValues;
      });
    } else {
      this.filter.filterValues = filterValues;
      this.filterValuesCopy = this.filter.filterValues;
    }
  }

  chipOptionSelected(event: MatAutocompleteSelectedEvent) {
    const valueInput: ElementRef = this.valueInputs.toArray()[0];
    this.renderer.setProperty(valueInput.nativeElement, 'value', '');
    this.filter.filterValues = [];
    const chipsSelected = <any[]>this.filter.criterium.value;
    chipsSelected.push(event.option.value);
  }

  removeChipSelection(filterValueIndex: number) {
    const chipsSelected = <any[]>this.filter.criterium.value;
    chipsSelected.splice(filterValueIndex, 1);
  }

  showDatepicker(event: any, datepicker: MatDatepicker<any>) {
    datepicker.open();
  }

  hideDatepicker(event: any, datepicker: MatDatepicker<any>) {
    datepicker.close();
  }

  toggleDatepicker(event: any, datepicker: MatDatepicker<any>) {
    datepicker.opened ? datepicker.close() : datepicker.open();
  }

  watchForfilterOptionsCtrlValueChanges() {
    this.dropdownFilterCtrl.valueChanges
      .pipe(
        takeUntil(this._unsubscribe),
        debounceTime(100)
      )
      .subscribe(searchStr => {
        this._filterDropdown(searchStr);
      });

    this.filterOptionsCtrl.valueChanges
      .pipe(
        takeUntil(this._unsubscribe),
        debounceTime(100)
      )
      .subscribe(() => {
        const searchStr = this.filterOptionsCtrl.value;
        this._filterOptions(searchStr);
      });
  }

  _filterDropdown(searchStr: string) {
    this.filter.filterValues = this.filterValuesCopy.filter(
      (fv: FilterValue) => {
        return fv.displayText.toLowerCase().includes(searchStr.toLowerCase());
      }
    );
  }

  mapValueToDisplayText(filterValue: FilterValue) {
    return filterValue ? filterValue.displayText : '';
  }

  onNoClick() {
    this.dialogRef.close();
  }

  ngOnDestroy() {
    this._unsubscribe.next();
    this._unsubscribe.complete();
  }
}
