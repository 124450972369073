import {mergeMap, filter, take, takeUntil} from 'rxjs/operators';
import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
  ViewContainerRef,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  ViewRef,
  OnDestroy,
} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {DialogProviderService} from '@app/core/services/dialog-provider.service';
import {Ccr, CcrReportTypes, CcrSourceTypes} from '../../../core/types/ccr';
import {Client} from '../../../core/types/client';
import {CcrService} from '../../../core/services/ccr.service';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {ClientService} from '../../../core/services/client.service';
import {ErrorService} from '../../../core/services/error.service';
import {IdentityReportsService} from '../../../core/services/identity-reports.service';
import {CcrActiveSummary} from '../../../core/types/ccr-summary';
import {CcrSection} from '../../../core/types/ccr-section';
import {CcrSummaryGroupNames} from '../../../core/types/ccr-summary-group';
import {ClientMapperTypes, IdentityReport, ListElement, MetaStatusCode, ReportConfig} from '../../../core/types/identity-reports';
import {ToCustomItfgAmountPipe} from '../pipes/to-custom-itfg-amount.pipe';
import {forkJoin, Observable, Subject} from 'rxjs';
import * as moment from 'moment/moment';
import {
  ClientCcrChooseSourceComponent
} from '@app/main/client/client-ccr/client-ccr-choose-source/client-ccr-choose-source.component';
import {NotificationService} from '@core/services/notification.service';
import {
  ClientCcrParseRequestsReportComponent
} from '@app/main/client/client-ccr/client-ccr-parse-requests-report/client-ccr-parse-requests-report/client-ccr-parse-requests-report.component';
import {CcrRequests} from '@core/types/ccr-requests-report';


@Component({
  selector: 'itfg-ccr-report',
  templateUrl: './ccr-report.component.html',
  styleUrls: ['./ccr-report.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CcrReportComponent implements OnInit, OnChanges, OnDestroy {
  @Input() inputClient: Client;
  ccrListData: ListElement[];
  CCR_CACHE_TTL = 30;
  metaStatusCode: MetaStatusCode;
  ccrReportData: IdentityReport<Ccr> | IdentityReport<CcrRequests>;
  ccrClientName: string;
  ccrClientCivilId: string;
  clientNamesMatch: boolean;
  columnsActive: any[];
  columnsOverdue: any[];
  columnsRelated: any[];
  columnsNew: any[];
  columnsNamesActive: string[];
  columnsNamesNew: string[];
  columnsNamesRelated: string[];
  columnsNamesOverdue: string[];
  ccrClientData: any;
  public CLIENT_PERSONAL_INFO_MAPPER: { [key: string]: string };
  public criticalProperties: any[];
  public ccrConfig: ReportConfig;
  dialogConfig: any;
  client: Client;
  ccrTime: Date;
  reportTime: Date;
  _unsubscribe = new Subject<void>();
  _ccrData: Ccr;

  get ccrData() {
    return this._ccrData;
  }

  set ccrData(ccrData) {
    this._ccrData = ccrData;
    // this.cdr.markForCheck();
    this.cdr.detectChanges();
  }

  constructor(
    private translate: TranslateService,
    private ccrService: CcrService,
    private identityReportsService: IdentityReportsService,
    private notificationService: NotificationService,
    private dialogService: DialogProviderService,
    private _viewContainerRef: ViewContainerRef,
    private route: ActivatedRoute,
    private router: Router,
    private clientService: ClientService,
    private errorService: ErrorService,
    private toCustomItfgAmount: ToCustomItfgAmountPipe,
    private cdr: ChangeDetectorRef
  ) {
    // this.ccrListData = [];
    this.columnsActive = [];
    this.columnsOverdue = [];
    this.columnsNew = [];

    this.CLIENT_PERSONAL_INFO_MAPPER = {
      [ClientMapperTypes.FIRST_NAME]: ClientMapperTypes.FIRST_NAME,
      [ClientMapperTypes.MIDDLE_NAME]: ClientMapperTypes.MIDDLE_NAME,
      [ClientMapperTypes.LAST_NAME]: ClientMapperTypes.LAST_NAME,
    };

    this.criticalProperties = [
      ClientMapperTypes.FIRST_NAME,
      ClientMapperTypes.MIDDLE_NAME,
      ClientMapperTypes.LAST_NAME
    ];
  }

  ngOnInit() {
    this.route.paramMap
      .pipe(
        filter((params: ParamMap) => typeof params.get('id') === 'string'),
        mergeMap((params: ParamMap) =>
          this.clientService.getClientById(Number(params.get('id')))
        )
      )
      .pipe(takeUntil(this._unsubscribe))
      .subscribe(
        client => {
          this.client = client;
          if (this.client.civilId) {
            this.setInitialGridData(this.client.civilId);
          }
        },
        error => {
          this.errorService.handleError(error, this.route.data);
        }
      );
    this.identityReportsService.getConstantByKey('ccr-cache-ttl').subscribe(ccrCacheTtl => {
      this.CCR_CACHE_TTL = ccrCacheTtl;
    });
  }

  ccrDataChanged(event) {
    for (let i = 0; i < this.ccrListData.length; i++) {
      if (event.value.id === this.ccrListData[i].id) {
        this.getReport(this.ccrListData[i].type, this.client.civilId, this.ccrListData[i].id);
        break;
      }
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.inputClient) {
      this.client = this.inputClient;
      if (this.client.civilId) {
        this.setInitialGridData(this.client.civilId);
      }
    }
  }

  setInitialGridData(clientId: string) {
    this.setCcrActiveGridData();
    this.setCcrOverdueGridData();
    this.setCcrNewGridData();
    this.setCcrRelatedActiveGridData();

    this.ccrData = {
      borrower: {},
      reportDate: '',
    };
    this.ccrListData = null;
    this.ccrData = null;
    this.ccrReportData = null;
    this.ccrTime = null;
    this.reportTime = null;


    this.identityReportsService.getCcrList(clientId).subscribe(ccrList => {
      this.ccrListData = ccrList;
      this.ccrListData.forEach(ccr => (ccr.time = new Date(ccr.time)));

      if (this.ccrListData && this.ccrListData.length > 0) {
        const latestCcrId = this.ccrListData[0].id;
        this.getReport(this.ccrListData[0].type, clientId, latestCcrId);
      }

      this.cdr.detectChanges();
    });
  }

  getCcrRequestByType(type: CcrReportTypes, civilId: string, source: CcrSourceTypes, ttl: number) {
    if (type === CcrReportTypes.CCR) {
      return this.identityReportsService
        .getNewCcr(civilId, source, ttl);
    } else {
      return this.identityReportsService
        .newCcrRequest(civilId, source, ttl);
    }
  }

  uploadCcrRequestsReport() {
    this.dialogService
      .open(ClientCcrParseRequestsReportComponent, {
        minWidth: '800px',
      })
      .afterClosed()
      .pipe(filter(report => !!report))
      .subscribe((reportData: CcrRequests) => {
        this.identityReportsService.uploadCcrRequestsReport(this.inputClient.civilId, reportData).subscribe(result => {
          this.setInitialGridData(this.client.civilId);
        }, error => {
          console.log(error);
        });

      });
  }

  getNewCcr() {
    this.dialogService
      .open(ClientCcrChooseSourceComponent, {
        minWidth: '250px',
        restoreFocus: false,
        autoFocus: false,
        data: {
          ccrReportTypes: CcrReportTypes,
          ccrSourceTypes: CcrSourceTypes,
          ttl: this.CCR_CACHE_TTL,
        },
      })
      .afterClosed()
      .pipe(
        filter(res => !!res),
        mergeMap((response) => {
          const source = response.source === CcrSourceTypes.DEFAULT ? undefined : response.source;
          const fromDate = response.dateFrom ?
            moment(response.dateFrom).format('YYYY-MM-DD') :
            moment().subtract(1, 'years').format('YYYY-MM-DD');
          return this.getCcrRequestByType(response.type, this.client.civilId, source, response.ttl);
        })
      )
      .subscribe(res => {
        if (res?.status === 503) {
          return this.notificationService.showLocalizedSnackbarNotification({
            notificationText: res.name ? res.name : res.message,
            action: 'error',
          });
        }
        this.setInitialGridData(this.client.civilId.toString());
      }, error => {
        console.log(error);
      });
  }

  getReport(type, civilId, reportId) {
    if (type === CcrReportTypes.CCR) {
      return this.getCcr(civilId, reportId);
    } else {
      return this.getCcrRequests(civilId, reportId);
    }
  }

  getCcrRequests(civilId: string, reportId: string) {
    this.identityReportsService.getOneCcrRequest(civilId, reportId)
      .pipe(takeUntil(this._unsubscribe))
      .subscribe(report => {
        this.ccrReportData = report;
        this.ccrTime = new Date(report.time);
        this.reportTime = report?.metaData?.reportDate ? new Date(report?.metaData?.reportDate) : null;
        this.metaStatusCode = report?.metaData?.statusCode;

        this.cdr.detectChanges();
      });
  }

  // TODO: rename param to civilId ?
  getCcr(clientId, ccrId) {
    forkJoin(
      {
        ccrReport: this.identityReportsService.getOneCcr(clientId, ccrId)
      }
    )
      .subscribe(({ccrReport}) => {
        this.ccrReportData = ccrReport;
        this.metaStatusCode = this.ccrReportData?.metaData?.statusCode;
        this.ccrData = this.addTotalActiveSummary(ccrReport.data);

        this.ccrClientName = this.ccrData ? this.ccrData.borrower.name : null;

        if (this.ccrClientName) {
          const clientNames = this.ccrClientName.split(' ');
          this.ccrConfig = {
            reportData: this.ccrData,
            client: this.client,
            clientMapper: this.CLIENT_PERSONAL_INFO_MAPPER,
            criticalProperties: this.criticalProperties,
            processReportData: (reportData) => {
              return this.identityReportsService.parseCcrDataForUpdate(clientNames);
            },
          };
        }

        this.ccrClientCivilId = this.ccrData ? this.ccrData.borrower.code : null;
        this.ccrTime = new Date(ccrReport.time);
        this.reportTime = ccrReport?.metaData?.reportDate ? new Date(ccrReport?.metaData?.reportDate) : null;
        this.cdr.detectChanges();
      });
  }

  setCcrActiveGridData() {
    this.translate
      .get([
        'ccr.summaryGroup',
        'global.date',
        'global.description',
        'ccr.amountApproved',
        'ccr.amountDrawn',
        'ccr.monthlyInstallment',
        'ccr.outstandingPerformingPrincipal',
        'ccr.outstandingOverduePrincipal',
        'ccr.balanceSheetValue',
        'ccr.offBalanceSheetValue',
        'ccr.offBalanceSheetDues',
        'ccr.offBalanceSheetConditional',
        'ccr.offBalanceSheetUnused',
        '',
      ])
      .subscribe(translation => {
        this.columnsActive = [
          {
            name: 'dateFrom',
            label: translation['global.date'],
            width: {min: 130},
          },
          {
            name: 'type',
            label: translation['global.description'],
            width: 140,
          },
          {
            name: 'amountApproved',
            label: translation['ccr.amountApproved'],
            width: {min: 105},
            numeric: true,
            format: number => this.toCustomItfgAmount.transform(number),
          },
          {
            name: 'amountDrawn',
            label: translation['ccr.amountDrawn'],
            width: {min: 105},
            numeric: true,
            format: number => this.toCustomItfgAmount.transform(number),
          },
          {
            name: 'outstandingPerformingPrincipal',
            label: translation['ccr.outstandingPerformingPrincipal'],
            width: {min: 105},
            numeric: true,
            format: number => this.toCustomItfgAmount.transform(number),
          },
          {
            name: 'outstandingOverduePrincipal',
            label: translation['ccr.outstandingOverduePrincipal'],
            width: {min: 105},
            numeric: true,
            format: number => this.toCustomItfgAmount.transform(Number(number.toFixed(2))),
          },
          {
            name: 'balanceSheetValue',
            label: translation['ccr.balanceSheetValue'],
            width: {min: 105},
            numeric: true,
            format: number => this.toCustomItfgAmount.transform(number),
          },
          {
            name: 'offBalanceSheetValue',
            label: translation['ccr.offBalanceSheetValue'],
            width: {min: 105},
            numeric: true,
            format: number => this.toCustomItfgAmount.transform(number),
          },
          {
            name: 'monthlyInstallment',
            label: translation['ccr.monthlyInstallment'],
            width: {min: 105},
            numeric: true,
            format: number => this.toCustomItfgAmount.transform(number),
          },

          {
            name: 'offBalanceSheetDues',
            label: translation['ccr.offBalanceSheetDues'],
            width: {min: 105},
            numeric: true,
            format: number => this.toCustomItfgAmount.transform(number),
          },
          {
            name: 'offBalanceSheetConditional',
            label: translation['ccr.offBalanceSheetConditional'],
            width: {min: 105},
            numeric: true,
            format: number => this.toCustomItfgAmount.transform(number),
          },
          {
            name: 'offBalanceSheetUnused',
            label: translation['ccr.offBalanceSheetUnused'],
            numeric: true,
            format: number => this.toCustomItfgAmount.transform(number),
          },
        ];
        this.columnsNamesActive = this.columnsActive.map(column => column.name);
      });
  }

  setCcrRelatedActiveGridData() {
    this.translate
      .get([
        'ccr.amountApproved',
        'ccr.balanceSheetValue',
        'ccr.credCount',
        'global.date',
        'ccr.offBalanceSheetValue',
        'ccr.overduePaymentPeriod',
      ])
      .subscribe(translation => {
        this.columnsRelated = [
          {
            name: 'dateFrom',
            label: translation['global.date'],
            width: {min: 130},
          },
          {
            name: 'overduePaymentPeriod',
            label: translation['ccr.overduePaymentPeriod'],
            width: {min: 150},
          },
          {
            name: 'credCount',
            label: translation['ccr.credCount'],
            width: {min: 150},
          },
          {
            name: 'amountApproved',
            label: translation['ccr.amountApproved'],
            width: {min: 105},
            numeric: true,
            format: number => this.toCustomItfgAmount.transform(number),
          },
          {
            name: 'balanceSheetValue',
            label: translation['ccr.balanceSheetValue'],
            width: {min: 220},
            numeric: true,
            format: number => this.toCustomItfgAmount.transform(number),
          },
          {
            name: 'offBalanceSheetValue',
            label: translation['ccr.offBalanceSheetValue'],
            numeric: true,
            format: number => this.toCustomItfgAmount.transform(number),
          },
        ];
        this.columnsNamesRelated = this.columnsRelated.map(
          column => column.name
        );
      });
  }

  setCcrOverdueGridData() {
    this.translate
      .get([
        'ccr.creditState',
        'global.year',
        'global.category',
        'ccr.monthCount',
        'ccr.maxCredCount',
        'ccr.maxOutstandingOverduePrincipal',
        'ccr.maxOutstandingOverdueInterestAndOthers',
        'ccr.maxOffBalanceSheetDues',
        'ccr.lastCorrection',
      ])
      .subscribe(translation => {
        this.columnsOverdue = [
          {
            name: 'active',
            label: translation['ccr.creditState'],
            width: {min: 100},
          },
          {
            name: 'year',
            label: translation['global.year'],
            width: {min: 100},
          },
          {
            name: 'category',
            label: translation['global.category'],
            width: {min: 140},
          },
          {
            name: 'monthsCount',
            label: translation['ccr.monthCount'],
            width: {min: 110},
          },
          {
            name: 'maxCredCount',
            label: translation['ccr.maxCredCount'],
            width: {min: 110},
          },
          {
            name: 'maxOutstandingOverduePrincipal',
            label: translation['ccr.maxOutstandingOverduePrincipal'],
            width: {min: 130},
            numeric: true,
            format: number => this.toCustomItfgAmount.transform(number),
          },
          {
            name: 'maxOutstandingOverdueInterestAndOthers',
            label: translation['ccr.maxOutstandingOverdueInterestAndOthers'],
            width: {min: 200},
            numeric: true,
            format: number => this.toCustomItfgAmount.transform(number),
          },
          {
            name: 'maxOffBalanceSheetDues',
            label: translation['ccr.maxOffBalanceSheetDues'],
            width: {min: 130},
            numeric: true,
            format: number => this.toCustomItfgAmount.transform(number),
          },
          {
            name: 'dateLastCorrection',
            label: translation['ccr.lastCorrection'],
            width: {min: 130},
          },
        ];
        this.columnsNamesOverdue = this.columnsOverdue.map(
          column => column.name
        );
      });
  }

  setCcrNewGridData() {
    this.translate
      .get(['ccr.amountApproved', 'ccr.type'])
      .subscribe(translation => {
        this.columnsNew = [
          {
            name: 'type',
            label: translation['ccr.type'],
            width: {max: 400},
          },
          {
            name: 'amountApproved',
            label: translation['ccr.amountApproved'],
            numeric: true,
            width: {max: 400},
            format: number => this.toCustomItfgAmount.transform(number),
          },
        ];
        this.columnsNamesNew = this.columnsNew.map(column => column.name);
      });
  }

  addTotalActiveSummary(ccrData) {
    const sections = ccrData ? ccrData.borrower.section : [];
    for (const section of sections) {
      const totalSummary: CcrActiveSummary = {};
      totalSummary['groupingAttribute'] = CcrSummaryGroupNames.TOTAL;
      totalSummary['summary'] = [];
      const summary = {};
      if (section.activeCredits.summaries.length > 0) {
        section.activeCredits.summaries[0].summary.forEach(prop => {
          prop = Object.entries(prop).map(([key, value]) => ({key, value}));

          for (let i = 0; i < prop.length; i++) {
            if (prop[i].key === 'type') {
              summary[prop[i].key] = this.translate.instant(
                'ccr.totalActiveSummary'
              );
            } else if (prop[i].key === 'dateFrom') {
              summary[prop[i].key] = null;
            } else {
              if (summary.hasOwnProperty(prop[i].key)) {
                summary[prop[i].key] = Number(
                  (summary[prop[i].key] + prop[i].value).toFixed(2)
                );
              } else {
                summary[prop[i].key] = {};
                summary[prop[i].key] = prop[i].value;
              }
            }
          }
        });
        totalSummary['summary'].push(summary);
        section.activeCredits.summaries.push(totalSummary);
      }
    }
    return ccrData;
  }

  ngOnDestroy(): void {
    this._unsubscribe.next();
    this._unsubscribe.complete();
  }
}
