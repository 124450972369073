import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { MatButtonModule } from '@angular/material/button';
import { MaterialModule } from '@app/lib/material.module';
import { SharedModule } from '@app/shared/shared.module';
import { CallStatusIconComponent } from './call-status-icon.component';
import { CallDirectionStatusIconComponent } from '../call-direction-and-status-icon/call-direction-and-status-icon.component';
import { MatMenuModule } from '@angular/material/menu';
import { FormsModule } from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    MatIconModule,
    TranslateModule,
    MatTooltipModule,
    MatButtonModule,
    MaterialModule,
    MatMenuModule,
    FormsModule,
  ],
  declarations: [CallStatusIconComponent, CallDirectionStatusIconComponent],
  exports: [CallStatusIconComponent, CallDirectionStatusIconComponent],
})

export class CallStatusIconModule {}
