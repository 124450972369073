import {Component, Inject, OnInit, ViewContainerRef} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {TranslateService} from '@ngx-translate/core';
import { ItfgDataTableColumn } from '@app/core/components/data-table/types/data-table.column';
import * as _ from 'lodash-es';
import {LoyaltyPointsFlow, LoyaltyPointsFlowData} from '../../../../core/types/loyalty-points-flow';
import {LoyaltyPointsService} from '../../../../core/services/loyalty-points.service';
import { DialogProviderService } from '@app/core/services/dialog-provider.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NotificationService } from '../../../../core/services/notification.service';
import { mergeMap, switchMap } from 'rxjs/operators';
import {NgxPermissionsService} from 'ngx-permissions';
import { of } from 'rxjs';
import { ErrorService } from '@app/core/services/error.service';


@Component({
  selector: 'itfg-loyalty-points-flow',
  templateUrl: './loyalty-points-flow.component.html',
  styleUrls: ['./loyalty-points-flow.component.scss']
})
export class LoyaltyPointsFlowComponent implements OnInit {
  public pointsFlowColumns: ItfgDataTableColumn[];
  public loyaltyPointsFlowData: LoyaltyPointsFlowData[];
  public dialogConfig: any;
  public isLoyaltyNumberChanged: boolean;
  public addLoyaltyPointsForm: UntypedFormGroup;
  public currentDate = new Date();
  public loyaltyPointsFormIsShown = false;
  public addLoyaltyPointsMinAmount = 1;
  private isAuthorizedToWrite = false;

  constructor(
    public _viewContainerRef: ViewContainerRef,
    public _dialogService: DialogProviderService,
    private translateService: TranslateService,
    private loyaltyPointsService: LoyaltyPointsService,
    private dialogRef: MatDialogRef<LoyaltyPointsFlowComponent>,
    @Inject(MAT_DIALOG_DATA) public data: LoyaltyPointsFlow,
    private formBuilder: UntypedFormBuilder,
    private notification: NotificationService,
    private ngxPermissionService: NgxPermissionsService,
    private errorService: ErrorService,
  ) {
    this.isLoyaltyNumberChanged = false;
  }

  ngOnInit() {
    this.parseLoyaltyPoints(this.data);
    this.initializeColumns();
    this.addLoyaltyPointsForm = this.formBuilder.group({
      amount: ['', [Validators.required, Validators.min(this.addLoyaltyPointsMinAmount)]],
      expiryDate: ['', [Validators.required]]
    });
  }

  initializeColumns() {
    of( this.ngxPermissionService.getPermission('LOYALTY_POINT_WRITE'))
    .pipe(mergeMap(isAuthorized => {
      this.isAuthorizedToWrite = !!isAuthorized
      return this.translateService
      .get([
        'credits.installmentComponent',
        'global.installment',
        'global.credit',
        'global.date',
        'clients.usedPoints',
        'clients.points',
        'global.actions'
      ])
    }))
    .subscribe((translation: string[]) => {
      this.pointsFlowColumns = [
        {
          name: 'createTime',
          label: translation['global.date']
        },
        {
          name: 'creditId',
          label: translation['global.credit']
        },
        {
          name: 'installmentNumber',
          label: translation['global.installment']
        },
        {
          name: 'received',
          label: '',
          width: 120
        },
        {
          name: 'points',
          label: translation['clients.points']
        },
        {
          name: 'usedPoints',
          label: translation['clients.usedPoints']
        },
        
      ];
      if(this.isAuthorizedToWrite){
        this.pointsFlowColumns.push(
          {
          name: 'actions', label: translation['global.actions']
          }
        )
      }
    });
  }

  parseLoyaltyPoints(data) {
    const currDate = new Date();

    const receivedPoints = data.received.map(function (points) {
      points.createTime = new Date(points.createTime);
      points.received = true;
      const expiredPoints = currDate.getTime() > new Date(points.expiryTime).getTime();
      const used = (points.points - points.usedPoints) === 0;
      points.notActive = expiredPoints || used;
      return points;
    });

    let usedPoints = data.used;
    usedPoints = usedPoints.map(function (points) {
      points.createTime = new Date(points.time);
      return points;
    });

    let loyaltyPointsFlow = receivedPoints.concat(usedPoints);
    loyaltyPointsFlow = loyaltyPointsFlow.sort((a, b) => b.createTime - a.createTime);
    loyaltyPointsFlow = loyaltyPointsFlow.map(function (points) {
      points.createTime = points.createTime.toISOString();
      return points;
    });
    this.loyaltyPointsFlowData = loyaltyPointsFlow;
  }

  deletePoint(pointId) {
    this.translateService
      .get(
        [
          'clients.deleteLoyaltyPointsMessage',
          'global.confirm',
          'global.cancel',
          'global.delete',
        ],
      )
      .subscribe(translation => {
        this.dialogConfig = {
          message: translation['clients.deleteLoyaltyPointsMessage'],
          disableClose: false,
          viewContainerRef: this._viewContainerRef,
          title: translation['global.delete'],
          cancelButton: translation['global.cancel'],
          acceptButton: translation['global.confirm'],
        };

        this._dialogService
          .openConfirm(this.dialogConfig)
          .afterClosed()
          .subscribe((accept: boolean) => {
            if (accept) {
              this.loyaltyPointsService.deleteLoyaltyPoints$(this.data.clientId, pointId)
              .pipe(switchMap(_ => { 
                return this.loyaltyPointsService.getLoyaltyPointsFlow$(this.data.clientId);
              }))
              .subscribe(res => {
                this.parseLoyaltyPoints({...this.data, ...res})
                this.loyaltyPointsService.loyaltyPointsFlowSubject.next(res);
                this.notification.showLocalizedSuccessMessage({ notificationText: this.translateService.instant('clients.loyaltyPointsDeleteSuccessMessage') });
              }, 
              (error: any) => {
                this.notification.showLocalizedErrorMessage({ notificationText: this.translateService.instant('clients.loyaltyPointsDeleteErrorMessage') });
              })
            }
          });
      });
  }
  addLoyaltyPoints() {
    const amount = this.addLoyaltyPointsForm.get('amount').value;
    const expiryDate = this.addLoyaltyPointsForm.get('expiryDate').value;
    
    this.loyaltyPointsService.addLoyaltyPoints$(this.data.clientId, expiryDate, amount)
    .pipe(switchMap(_ => { 
      return this.loyaltyPointsService.getLoyaltyPointsFlow$(this.data.clientId);
    }))
    .subscribe(res => {
      this.parseLoyaltyPoints({...this.data, ...res})
      this.loyaltyPointsService.loyaltyPointsFlowSubject.next(res);
      this.notification.showLocalizedSuccessMessage({ notificationText: this.translateService.instant('clients.loyaltyPointsAddSuccessMessage') });
    }, 
    (error: any) => {
      this.errorService.showSnackbarErrorMessage(error)
    })
  }
  onNoClick() {
    this.dialogRef.close(this.isLoyaltyNumberChanged);
  }

  showLoyaltyPointsForm() {
    this.loyaltyPointsFormIsShown = !this.loyaltyPointsFormIsShown;
  }
}
