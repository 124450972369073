import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { RequestService } from './request.service';
import { Page } from '../types/page';
import { AccountOwnerType } from '../types/account-owner-type';
import { ITFGAccount } from '../types/account';
import {PaymentMethod, PaymentMethodTypes} from '../types/payment-method';
import { IbanUtilService } from './iban-util.service';
import { TranslateService } from '@ngx-translate/core';
import { SearchOperations } from '../types/search-criterium';
import { map } from 'rxjs/operators';
import { SearchOptions } from '../types/search-options';
import { Brand } from '../types/brand';

@Injectable()
export class AccountService {
  request: RequestService;
  ibanUtilService: IbanUtilService;
  translate: TranslateService;

  accountTypes$ = new Subject<AccountOwnerType[]>();
  participantSelectedAccountType = new Subject<{
    direction: string;
    accountType: AccountOwnerType;
  }>();
  participantSelectedPaymentMethod = new Subject<{
    direction: string;
    paymentmethod: PaymentMethod;
  }>();
  virtualAccountAdded = new Subject<{
    isVirtualAccountAdded: boolean;
    virtualAccountData: ITFGAccount;
  }>();
  cashAccounts = new Subject<any>();

  constructor(
    request: RequestService,
    ibanUtilService: IbanUtilService,
    translate: TranslateService
  ) {
    this.request = request;
    this.ibanUtilService = ibanUtilService;
    this.translate = translate;
  }

  getAccountList$(params?): Observable<Page<ITFGAccount>> {
    return this.request.get(['accounts'], { params: params });
  }

  saveAccount$(accountToSave: ITFGAccount) {
    return this.request.post(['accounts'], { body: accountToSave });
  }

  getAccountOwnerTypes$(): Observable<AccountOwnerType[]> {
    return this.request.get(['accounts', 'owner-types']);
  }

  deleteAccount$(accountId: number | string): Observable<any> {
    return this.request.delete(['accounts', accountId]);
  }

  getAccountById$(accountId: number | string): Observable<ITFGAccount> {
    return this.request.get(['accounts', accountId]);
  }

  setupUserVirtualAccount$(userId, companyAccountId): Observable<any> {
    return this.request.post(['accounts', 'user', userId, 'virtual'], {
      body: companyAccountId,
    });
  }

  formatAccountToDisplaytext(account: ITFGAccount) {
    const accountOwner = !!account.brand ? 'COMPANY' : 'CLIENT';
    const accountOwnerTranslation = this.translate.instant(
      'accounts.accountTypes.' + accountOwner
    );

    if (this.ibanUtilService.isValidIban(account.code)) {
      const bankIdCode = this.ibanUtilService.matchIbanBICCode(account.code);

      return this.translate.instant('accounts.accountDisplayTextForIban', {
        owner: accountOwnerTranslation,
        bankName: this.translate.instant('bankIdCodes.' + bankIdCode),
        iban: account.code,
      });
    }

    if (accountOwner === 'CLIENT') {
      return this.translate.instant('transaction.type.' + accountOwner);
    }

    return this.translate.instant('accounts.accountDisplayText', {
      owner: accountOwnerTranslation,
      code: account.code,
    });
  }

  getAccountListByPaymentMethod(
    paymentMethod: PaymentMethod,
    brandId: number
  ): Observable<ITFGAccount[]> {
    const searchOptions = new SearchOptions();
    searchOptions.criteria = [
      {
        key: 'paymentMethod.id',
        operation: SearchOperations.EQUALS,
        value: paymentMethod.id,
      },
      {
        key: 'brand.id',
        operation: SearchOperations.EQUALS,
        value: brandId,
      },
    ];

    return this.getAccountList$(searchOptions.getDTO()).pipe(
      map((accountPage: Page<ITFGAccount>) => this.mapAccountListContent(accountPage, paymentMethod.name))
    );
  }
  mapAccountListContent(accountPage, selectedMethodName) {
    let accountList = accountPage.content.filter(acount => acount.active === true);
    if (selectedMethodName === PaymentMethodTypes.EASYPAY) {
      accountList = accountList.reverse();
    }
    return accountList;
  }
}
