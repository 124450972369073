import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewContainerRef,
} from '@angular/core';
import {Client} from '@core/types/client';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {IdentityReportsService} from '@core/services/identity-reports.service';
import {ActivatedRoute, ParamMap} from '@angular/router';
import {ClientService} from '@core/services/client.service';
import {ErrorService} from '@core/services/error.service';
import {TranslateService} from '@ngx-translate/core';
import {
  BulstatInfo,
  DataForEGNContracts,
  EgnInfo,
  ListElement,
  Nssi2,
  Nssi7,
  NssiTypes,
  IdentityReport,
  Nssi51,
  ClientNSSI7MapperTypes,
  ClientMapperTypes,
  ClientNSSI2MapperTypes,
  ClientNSSI51MapperTypes,
  ReportConfig,
  Nssi7Summary,
  NssiAutomationScore,
  NssiAutomationScoreData,
  NSSI_AUTOMATION_ACTION_MAPPER,
  NssiAutomationScoreClientUpdate, MetaStatusCode
} from '@core/types/identity-reports';
import {ChooseNssiTypeReportComponent} from './choose-nssi-type-report/choose-nssi-type-report.component';
import {filter, mergeMap, takeUntil} from 'rxjs/operators';
import {UpdateClientComponent} from '../update-client/update-client.component';
import {Subject} from 'rxjs';
import {DialogProviderService} from '@app/core/services/dialog-provider.service';
import {CreditService} from '@app/core/services/credit.service';
import {
  NssiAutomationDetailsComponent
} from '@app/main/identity-reports/nssi-report/nssi-automation-details/nssi-automation-details.component';
import {EmploymentType} from '@core/types/employment-type';
import { NssiSourceTypes} from '@core/types/ccr';
import {NotificationService} from '@core/services/notification.service';

@Component({
  selector: 'itfg-nssi-report',
  templateUrl: './nssi-report.component.html',
  styleUrls: ['./nssi-report.component.scss'],
})
export class NssiReportComponent implements OnInit, OnChanges, OnDestroy {
  @Input() client: Client;
  @Input() creditId: number;

  public nssiData: IdentityReport<any>;
  public metaStatusCode: MetaStatusCode;
  public nssi7Data: IdentityReport<Nssi7>;
  public nssi2Data: IdentityReport<Nssi2>;
  public nssi51Data: IdentityReport<Nssi51>;
  public nssiListData: ListElement[] = [];
  public nssi7ReportData: DataForEGNContracts[];
  public nssi2ReportData;
  public requestType: string;
  public requestTime: Date = new Date();
  public reportTime: Date = new Date();
  public requesterName: string;
  public requestStatus = null;
  public nssi7Columns: any[];
  public nssi2Columns: any[];
  public dialogConfig: any;
  public NssiTypes = NssiTypes;
  public isReport: boolean;
  public CLIENT_MAPPER_NSSI2: { [key: string]: string };
  public CLIENT_MAPPER_NSSI7: { [key: string]: string };
  public CLIENT_MAPPER_NSSI51: { [key: string]: string };
  public CLIENT_PERSONAL_INFO_MAPPER_NSSI2: { [key: string]: string };
  public CLIENT_PERSONAL_INFO_MAPPER_NSSI7: { [key: string]: string };
  public CLIENT_PERSONAL_INFO_MAPPER_NSSI51: { [key: string]: string };
  public nssiConfig: ReportConfig;
  public nssiCompanyConfig: ReportConfig;
  public nssi2CriticalProperties: any[];
  public nssi7CriticalProperties: any[];
  public nssi7Summary: Nssi7Summary;
  public nssi51CriticalProperties: any[];
  public nssi2NotUpdatableProperties: any[];
  public nssiAutomationLatestScore: NssiAutomationScore;
  public nssiAutomationScoreData: NssiAutomationScoreData;
  public nssiAutomationScoreClientUpdate: NssiAutomationScoreClientUpdate;
  public NSSI_AUTOMATION_ACTION_MAPPER: typeof NSSI_AUTOMATION_ACTION_MAPPER = NSSI_AUTOMATION_ACTION_MAPPER;
  public NSSI_AUTOMATION_MODEL_ID = 9;
  public NSSI_CACHE_TTL = 30;
  _unsubscribe: Subject<void> = new Subject<void>();

  public nssiCivilIdData: EgnInfo;
  public nssiBulstatData: BulstatInfo[];

  constructor(
    private identityReportsService: IdentityReportsService,
    private formBuilder: UntypedFormBuilder,
    private notificationService: NotificationService,
    private route: ActivatedRoute,
    private clientService: ClientService,
    private creditService: CreditService,
    private errorService: ErrorService,
    private translate: TranslateService,
    private dialogService: DialogProviderService,
    private _viewContainerRef: ViewContainerRef
  ) {

    this.CLIENT_MAPPER_NSSI7 = {
      [ClientMapperTypes.SALARY]: ClientNSSI7MapperTypes.EGN_BASE_SALARY,
      [ClientMapperTypes.EMPLOYER_ID]: ClientNSSI7MapperTypes.BULSTAT,
      [ClientMapperTypes.EMPLOYER_NAME]: ClientNSSI7MapperTypes.EMPLOYER_NAME,
      [ClientMapperTypes.WORK_ADDRESS_ADDRESS]: ClientNSSI7MapperTypes.EMPLOYER_ADDRESS,
      [ClientMapperTypes.WORK_POSITION]: ClientNSSI7MapperTypes.EGN_FUNCTION_DESCRIPTION,
    };
    this.CLIENT_MAPPER_NSSI2 = {
      [ClientMapperTypes.EMPLOYER_ID]: ClientNSSI2MapperTypes.BULSTAT,
      [ClientMapperTypes.SALARY]: ClientNSSI2MapperTypes.SALARY,
      [ClientMapperTypes.EMPLOYER_NAME]: ClientNSSI2MapperTypes.EMPLOYER_NAME,
      [ClientMapperTypes.WORK_ADDRESS_ADDRESS]: ClientNSSI2MapperTypes.WORK_ADRESS,
    };
    this.CLIENT_PERSONAL_INFO_MAPPER_NSSI2 = {
      [ClientMapperTypes.LAST_NAME]: ClientNSSI2MapperTypes.FAMILY_NAME,
      [ClientMapperTypes.ID_CARD_ADDRESS_CITY_NAME]: ClientNSSI2MapperTypes.CITY,
      [ClientMapperTypes.ID_CARD_ADDRESS_ADDRESS]: ClientNSSI2MapperTypes.ADDRESS,
    };
    this.CLIENT_PERSONAL_INFO_MAPPER_NSSI7 = {
      [ClientMapperTypes.FIRST_NAME]: ClientNSSI7MapperTypes.FIRST_NAME,
      [ClientMapperTypes.MIDDLE_NAME]: ClientNSSI7MapperTypes.SUR_NAME,
      [ClientMapperTypes.LAST_NAME]: ClientNSSI7MapperTypes.FAMILY_NAME,
    };
    this.CLIENT_PERSONAL_INFO_MAPPER_NSSI51 = {
      [ClientMapperTypes.FIRST_NAME]: ClientNSSI51MapperTypes.FIRST_NAME,
      [ClientMapperTypes.MIDDLE_NAME]: ClientNSSI51MapperTypes.MIDDLE_NAME,
      [ClientMapperTypes.LAST_NAME]: ClientNSSI51MapperTypes.LAST_NAME,
      [ClientMapperTypes.ID_CARD_ADDRESS_ADDRESS]: ClientNSSI51MapperTypes.ADDRESS,
      [ClientMapperTypes.SALARY]: ClientNSSI51MapperTypes.TOTAL_PENSION_AMOUNT,
      [ClientMapperTypes.EXTRA_INCOME]: ClientNSSI51MapperTypes.TOTAL_PENSION_AMOUNT
    };

    this.nssi2CriticalProperties = [
      ClientMapperTypes.LAST_NAME,
      ClientMapperTypes.ID_CARD_ADDRESS_CITY_NAME
    ];

    this.nssi7CriticalProperties = [
      ClientMapperTypes.FIRST_NAME,
      ClientMapperTypes.MIDDLE_NAME,
      ClientMapperTypes.LAST_NAME
    ];

    this.nssi51CriticalProperties = [
      ClientMapperTypes.FIRST_NAME,
      ClientMapperTypes.MIDDLE_NAME,
      ClientMapperTypes.LAST_NAME,
      ClientMapperTypes.SALARY,
      ClientMapperTypes.EXTRA_INCOME
    ];

    this.nssi2NotUpdatableProperties = [
      ClientMapperTypes.ID_CARD_ADDRESS_CITY_NAME
    ];
    this.isReport = true;
  }

  ngOnInit() {
    this.route.paramMap
      .pipe(
        filter((params: ParamMap) => typeof params.get('id') === 'string'),
        mergeMap((params: ParamMap) =>
          this.clientService.getClientById(Number(params.get('id')))
        )
      )
      .pipe(takeUntil(this._unsubscribe))
      .subscribe(
        client => {
          this.client = client;
          if (this.client.civilId) {
            this.setInitialGridData(this.client.civilId);
          }
        },
        error => {
          this.errorService.handleError(error, this.route.data);
        }
      );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.client) {
      if (this.client.civilId) {
        this.setInitialGridData(this.client.civilId);
      }
    }
  }

  setInitialGridData(civilId: string) {
    this.requesterName = '';
    this.identityReportsService
      .getNssiReportList$(civilId)
      .subscribe(nssiListData => {
        for (const listData of nssiListData) {
          const time = new Date(listData.time);
          listData.time = time;
          this.nssiListData.push(nssiListData);
        }
        this.nssiListData = nssiListData;

        if (this.nssiListData && this.nssiListData.length > 0) {
          const reportId = this.nssiListData[0].id;
          this.requestType = this.nssiListData[0].type;
          this.getReport(this.client.civilId, reportId, this.requestType);
          this.isReport = true;
        } else {
          this.isReport = false;
        }
      });

    this.setNssi7GridData();
    this.setNssi2GridData();
    if (this.creditId) {
      this.getLatestNssiScore(this.creditId, this.NSSI_AUTOMATION_MODEL_ID);
    }
  }

  setNssi7GridData() {
    this.translate
      .get([
        'nssi.EGN',
        'nssi.FirstName',
        'nssi.SurName',
        'nssi.FamilyName',
        'nssi.DateForEGNPersonalData',
        'nssi.ContractCodeCorrection',
        'nssi.ContractDocumentType',
        'nssi.Bulstat',
        'nssi.ContractReason',
        'nssi.ContractNumber',
        'nssi.ContractFoundationDate',
        'nssi.ContractExpirationDate',
        'nssi.ContractTerminationDate',
        'nssi.ContractAddDate',
        'nssi.EGNBaseSalary',
        'nssi.EGNFunction_Description',
        'nssi.EGNFunction_Code',
        'nssi.EGNEconomicActivity_Description',
        'nssi.EGNEconomicActivity_Code',
        'nssi.ruso',
        'nssi.DateInputInRUSO',
        'nssi.DateInputInNSSI',
        'nssi.CorrectOrIncorrect',
        'nssi.InputNumber',
        'nssi.EmployerName',
        'nssi.EmployerAddress',
        'nssi.EmployerPhone',
        'global.actions',
      ])
      .subscribe(translation => {
        this.nssi7Columns = [
          {
            name: 'actions',
            label: translation['global.actions'],
            width: {min: 100},
          },
          {
            name: 'Bulstat',
            label: translation['nssi.Bulstat'],
          },
          {
            name: 'EmployerName',
            label: translation['nssi.EmployerName'],
          },
          {
            name: 'ContractReason',
            label: translation['nssi.ContractReason'],
          },
          {
            name: 'ContractDocumentType',
            label: translation['nssi.ContractDocumentType'],
          },
          {
            name: 'ContractFoundationDate',
            label: translation['nssi.ContractFoundationDate'],
          },
          {
            name: 'ContractExpirationDate',
            label: translation['nssi.ContractExpirationDate'],
          },
          {
            name: 'ContractCodeCorrection',
            label: translation['nssi.ContractCodeCorrection'],
          },
          {
            name: 'ContractTerminationDate',
            label: translation['nssi.ContractTerminationDate'],
          },
          {
            name: 'ContractNumber',
            label: translation['nssi.ContractNumber'],
          },
          {
            name: 'DateInputInNSSI',
            label: translation['nssi.DateInputInNSSI'],
          },
          {
            name: 'EGNFunction_Description',
            label: translation['nssi.EGNFunction_Description'],
          },
          {
            name: 'EGNBaseSalary',
            label: translation['nssi.EGNBaseSalary'],
          },
          {
            name: 'EGNEconomicActivity_Description',
            label: translation['nssi.EGNEconomicActivity_Description'],
          },
          {
            name: 'EmployerAddress',
            label: translation['nssi.EmployerAddress'],
            width: {min: 200},
          },
          {
            name: 'EmployerPhone',
            label: translation['nssi.EmployerPhone'],
          },
          {
            name: 'EGNEconomicActivity_Code',
            label: translation['nssi.EGNEconomicActivity_Code'],
          },
          {
            name: 'EGNFunction_Code',
            label: translation['nssi.EGNFunction_Code'],
          },
          {
            name: 'DateForEGNPersonalData',
            label: translation['nssi.DateForEGNPersonalData'],
          },
          {
            name: 'DateInputInRUSO',
            label: translation['nssi.DateInputInRUSO'],
          },
          {
            name: 'InputNumber',
            label: translation['nssi.InputNumber'],
          },
          {
            name: 'ruso',
            label: translation['nssi.ruso'],
          },
          {
            name: 'CorrectOrIncorrect',
            label: translation['nssi.CorrectOrIncorrect'],
          },
          {
            name: 'ContractAddDate',
            label: translation['nssi.ContractAddDate'],
          },
        ];
      });

    // ContractAddDate: " "................................................
  }

  setNssi2GridData() {
    this.translate
      .get([
        'nssi.correctionflag',
        'nssi.ruso',
        'global.year',
        'global.month',
        'nssi.Bulstat',
        'nssi.Typeofisured',
        'nssi.Workdays',
        'nssi.Salary',
        'nssi.inputdate',
        'global.actions',
      ])
      .subscribe(translation => {
        this.nssi2Columns = [
          {
            name: 'actions',
            label: translation['global.actions'],
            width: {min: 100},
          },
          {
            name: 'Bulstat',
            label: translation['nssi.Bulstat'],
          },
          {
            name: 'Year',
            label: translation['global.year'],
          },

          {
            name: 'Month',
            label: translation['global.month'],
          },
          {
            name: 'Typeofisured',
            label: translation['nssi.Typeofisured'],
          },
          {
            name: 'Workdays',
            label: translation['nssi.Workdays'],
          },
          {
            name: 'Salary',
            label: translation['nssi.Salary'],
          },
          {
            name: 'inputdate',
            label: translation['nssi.inputdate'],
          },
          {
            name: 'correctionflag',
            label: translation['nssi.correctionflag'],
          },
          {
            name: 'Ruso',
            label: translation['nssi.ruso'],
            width: {min: 200},
          },
        ];
      });
  }

  nssiDataChanged(event) {
    for (let i = 0; i < this.nssiListData.length; i++) {
      if (event.value.id === this.nssiListData[i].id) {
        this.requestType = this.nssiListData[i].type;
        const reportId = this.nssiListData[i].id;
        this.getReport(this.client.civilId, reportId, this.requestType);

        break;
      }
    }
  }

  getReport(civilId, reportId, requestType) {
    this.identityReportsService
      .getNssiReportById(civilId, reportId, requestType)
      .subscribe((data: IdentityReport<any>) => {
        this.nssiData = data;
        this.metaStatusCode = this.nssiData?.metaData?.statusCode;
        this.requestType = data.type;
        this.requestTime = new Date(data.time);
        this.reportTime = data?.metaData?.reportDate ? new Date(data?.metaData?.reportDate) : null;
        this.requesterName = data.requesterName;
        this.requestStatus = data.status;
        if (this.requestType === NssiTypes.NSSI7 && data?.data) {
          this.identityReportsService.getSummarizedNssiReportById(civilId, reportId, requestType).subscribe(summary => {
            this.nssi7Summary = summary;

          });
          this.nssi7Data = data;
          this.nssi7ReportData = this.nssi7Data?.data ? this.nssi7Data?.data?.DataForEGNContracts : null;
          this.nssiConfig = {
            reportData: this.nssi7ReportData ? this.nssi7ReportData[this.nssi7ReportData?.length - 1] : null,
            client: this.client,
            clientMapper: this.CLIENT_PERSONAL_INFO_MAPPER_NSSI7,
            criticalProperties: this.nssi7CriticalProperties,
            processReportData: (reportData) => {
              return this.identityReportsService.parseNssi7DataForUpdate(reportData);
            },
          };
        } else if (this.requestType === NssiTypes.NSSI2 && data?.data) {
          this.nssi2Data = data;
          const personalInfoData = this.nssi2Data.data.PersonalInfo.sort(
            this.identityReportsService.sortNssi2PersonalData
          );
          this.nssiCivilIdData = this.nssi2Data.data.EgnInfo;
          this.nssiBulstatData = this.nssi2Data.data.BulstatInfo ? this.nssi2Data.data.BulstatInfo : [];

          this.nssi2ReportData = [];
          const personalInfoByBulstat = {};
          if (this.nssiBulstatData.length > 0) {
            for (const bulstatData of this.nssiBulstatData) {
              const reportData = personalInfoData.filter(
                d => d.Bulstat === bulstatData.Bulstat
              );
              const bulstat = bulstatData['Bulstat'];
              let address = bulstatData['address'];
              const companyName = bulstatData['Name'];
              const addressParser = /^(гр\.|с\.)[^,]+,\s(.*)$/;
              const addressMatch = address ? address.match(addressParser) : address;
              if (addressMatch && addressMatch[2]) {
                address = addressMatch[2];
              }
              for (const personalInfoObj of reportData) {
                personalInfoObj['Name'] = companyName;
                personalInfoObj['address'] = address;
              }

              personalInfoByBulstat[bulstat] = reportData;
            }
          } else {
            this.nssiBulstatData.push( {Bulstat: personalInfoData[0].Bulstat});
            personalInfoByBulstat[personalInfoData[0].Bulstat] = personalInfoData;
          }

          this.nssi2ReportData = personalInfoByBulstat;

          this.nssiConfig = {
            reportData: this.nssiCivilIdData,
            client: this.client,
            clientMapper: this.CLIENT_PERSONAL_INFO_MAPPER_NSSI2,
            criticalProperties: this.nssi2CriticalProperties,
            notUpdatableProperties: this.nssi2NotUpdatableProperties,
            processReportData: (reportData) => {
              return this.identityReportsService.parseNssi2DataForUpdate(reportData);
            },
          };
        } else if (this.requestType === NssiTypes.NSSI51) {
          this.nssi51Data = data;

          this.nssiConfig = {
            reportData: this.nssi51Data?.data,
            client: this.client,
            clientMapper: this.CLIENT_PERSONAL_INFO_MAPPER_NSSI51,
            criticalProperties: this.nssi51CriticalProperties,
            processReportData: (reportData) => {
              return this.identityReportsService.parseNssi51DataForUpdate(reportData);
            },
          };
        }
      } , err => {
     // console.log(err);

  });
  }

  getNewNssi() {
    this.dialogService
      .open(ChooseNssiTypeReportComponent, {
        data: {
          client: this.client,
          nssiTypesList: NssiTypes,
          nssiSourceTypes: NssiSourceTypes
        },
      })
      .afterClosed()
      .pipe(
        filter(res => !!res),
        mergeMap((response: { type: string, source: string, ttl: number }) => {
          const source = response.source === NssiSourceTypes.DEFAULT ? undefined : response.source;

          return this.identityReportsService.getNewNssiByType(
            this.client.civilId,
            response.type,
            source,
            response.ttl
          );
        })
      )
      .subscribe(res => {
        if (res?.status === 503) {
         return  this.notificationService.showLocalizedSnackbarNotification({
            notificationText: res.name ? res.name : res.message,
            action: 'error',
          });
        }

        this.setInitialGridData(this.client.civilId);
      }, error => {
        this.notificationService.showLocalizedSnackbarNotification({
          notificationText: error.code ? `error.${error.code}` : error.message,
          action: 'error',
        });
      });
  }

  updateClientOccupationData(data, clientMapper) {
    this.dialogService
      .open(UpdateClientComponent, {
        width: '650px',
        data: {
          client: this.client,
          clientData: data,
          CLIENT_MAPPER: clientMapper,
        },
      })
      .afterClosed()
      .pipe(filter(res => !!res))
      .subscribe((response: { nssiType: string }) => {
        this.setInitialGridData(this.client.civilId.toString());
      });
  }

  compareItemsById(firstObj, secondObj) {
    if (firstObj && secondObj) {
      return firstObj.id === secondObj.id;
    } else {
      return false;
    }
  }

  getLatestNssiScore(creditId, modelId) {
    this.creditService.getLatestScoreByModelId(creditId, modelId)
      .subscribe(score => {
        this.nssiAutomationLatestScore = score;
        this.nssiAutomationScoreData = JSON.parse(this.nssiAutomationLatestScore?.response_data || '""');
        this.nssiAutomationScoreClientUpdate = JSON.parse(this.nssiAutomationLatestScore?.request_data || '""');
       }, error => {
        console.log(error);
      });
  }

  nssiAutomation() {
    this.dialogService
      .openConfirm({
        message: this.translate.instant(
          'nssi.automationConfirmMessage'
        ),
        disableClose: false,
        viewContainerRef: this._viewContainerRef,
        title: this.translate.instant('nssi.automation'),
        cancelButton: this.translate.instant('global.cancel'),
        acceptButton: this.translate.instant('global.confirm'),
      })
      .afterClosed()
      .pipe(
        filter(accept => accept === true),
        mergeMap(() =>
          this.creditService.runNssiAotomationModel(this.creditId)
        ),
      )
      .subscribe(() => {
        this.setInitialGridData(this.client.civilId);
        this.clientService.getClientById(this.client.id).subscribe(client => {
          this.clientService.onClientOcupationDataChange.next(client);
        });
      }, error => {
        this.errorService.showSnackbarErrorMessage(error?.message || error);
      });
  }

  openAutomationDetails() {
    this.clientService.getEmploymentTypes()
      .pipe(takeUntil(this._unsubscribe))
      .subscribe((employmentTypes: EmploymentType[]) => {
        const employmentTypeId = this.nssiAutomationScoreClientUpdate.employmentTypeId;
        const clientDataEmployment: string | number = employmentTypeId ? employmentTypes.find(e => e.id === Number(employmentTypeId))?.type : employmentTypeId;
        const dialogRef = this.dialogService.open(NssiAutomationDetailsComponent, {
          data: {
            clientUpdate: this.nssiAutomationScoreClientUpdate,
            employmentType: clientDataEmployment,
            details: this.nssiAutomationLatestScore
          },
          autoFocus: false,
          maxWidth: 700
        });
        dialogRef.afterClosed().subscribe(options => {
          if (options) {
            this.nssiAutomation();
          }
        });

      }, err => {
        console.log(err);
      });
  }

  ngOnDestroy(): void {
    this._unsubscribe.next();
    this._unsubscribe.complete();
  }
}
