import { NgModule } from '@angular/core';
import { ClientOptionsMenuComponent } from './client-options-menu.component';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../../../../shared/shared.module';
import { MaterialModule } from '../../../../lib/material.module';

@NgModule({
  imports: [
    CommonModule,
    MatMenuModule,
    MatIconModule,
    TranslateModule,
    RouterModule,
    SharedModule,
    MatButtonModule,
    MaterialModule,
  ],
  declarations: [ClientOptionsMenuComponent],
  exports: [ClientOptionsMenuComponent],
})
export class ClientOptionsMenuModule {}
