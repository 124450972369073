import { Injectable } from '@angular/core';
import { RequestService } from './request.service';
import { Observable } from 'rxjs';
import { City } from '../types/city';
import {SearchOptionsDTO} from '../types/search-options';

@Injectable()
export class CityService {
  request: RequestService;

  constructor(request: RequestService) {
    this.request = request;
  }

  getCitiesStartingWith(value: string): Observable<City[]> {
    return this.request.get(
      ['cities', 'search', 'findByNameStartingWithIgnoreCase'],
      { params: { name: value } }
    );
  }

  getCityById(id: number | string): Observable<City> {
    return this.request.get(['cities', id]);
  }
  getCities (options: SearchOptionsDTO) {
    return this.request.get(
      ['cities'],
      { params:  options }
    );
  }
}
