import { Borrower } from './borrower';

export interface Ccr {
  borrower?: Borrower;
  reportDate?: string;
}

export interface CcrListElement {
  id: number;
  time: Date;
  _expandable: {
    civilId: string;
    data: string;
    requesterEmail: string;
    requesterId: string;
    requesterName: string;
    source: string;
    status: string;
    type: string;
  };
}

export enum CcrReportTypes {
  CCR = 'ccr',
  CCR_REQUESTS = 'ccr_requests'
}
export enum CcrSourceTypes {
  DEFAULT = 'DEFAULT',
  DG = 'DG',
  BNB = 'BNB'
}
export enum NssiSourceTypes {
  DEFAULT = 'DEFAULT',
  DG = 'DG',
  NSSI = 'NSSI'
}

export const DYNAMIC_CCR_SUMMARY_VARIABLES = [
  'banks_activeCredits_rest_underOneYear_monthlyInstallment_sum',
  'banks_activeCredits_rest_overOneYear_monthlyInstallment_sum',
  'nonbanks_activeCredits_rest_underOneYear_monthlyInstallment_sum',
  'nonbanks_activeCredits_rest_overOneYear_monthlyInstallment_sum',
  'banks_activeCredits_rest_underOneYear_outstandingPerformingPrincipal_sum',
  'banks_activeCredits_rest_overOneYear_outstandingPerformingPrincipal_sum',
  'nonbanks_activeCredits_rest_underOneYear_outstandingPerformingPrincipal_sum',
  'nonbanks_activeCredits_rest_overOneYear_outstandingPerformingPrincipal_sum',
  'banks_activeCredits_sourceEntityCount_value',
  'nonbanks_activeCredits_sourceEntityCount_value',
  'overdueHistory_count',
  'newCredits_count',
  'newCredits_amountApproved_sum',
  'banks_overdueHistory_false_count',
  'banks_overdueHistory_true_count',
  'nonbanks_overdueHistory_false_count',
  'nonbanks_overdueHistory_true_count',
];
