import { mergeMap, debounceTime, filter, tap, takeUntil, map, startWith } from 'rxjs/operators';
import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewContainerRef,
  OnChanges,
  SimpleChanges,
  OnDestroy,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
  UntypedFormControl,
} from '@angular/forms';
import { IdentifierValidation } from '../../../../core/validation/identifier-validation';
import { FormComponentData } from '../../../../core/types/form-component-data';
import { BlacklistService } from '../../../../core/services/blacklist.service';
import { MatDialog } from '@angular/material/dialog';
import { DialogProviderService } from '@app/core/services/dialog-provider.service';
import { BlacklistEditComponent } from '../../blacklist-edit/blacklist-edit.component';
import { CountryService } from '../../../../core/services/country.service';
import { AutoCompleteValidation } from '../../../../core/validation/autocomplete-validation';
import { LanguageValidation } from '../../../../core/validation/language-validation';
import { Utils } from '../../../../core/utils/utils';
import { Country } from '../../../../core/types/country';
import { MaritalStatus } from '../../../../core/types/marital-status';
import { ClientService } from '../../../../core/services/client.service';
import { Subject } from 'rxjs';
import { Observable } from 'rxjs';
import {VerificationStatusType, VERIFICATION_STATUS_STYLE_MAP} from '@core/verification-status-type';
import {VerificationStatusEditComponent} from '@app/main/client/client-edit/verification-status-edit/verification-status-edit.component';

@Component({
  selector: 'itfg-personal-data-edit',
  templateUrl: './personal-data-edit.component.html',
  styleUrls: ['./personal-data-edit.component.scss'],
})
export class PersonalDataEditComponent implements OnInit, OnChanges, OnDestroy {
  @Input() data;
  @Input() client;
  @Input() isNewItem: boolean;
  @Output() dataChanged = new EventEmitter<FormComponentData>();
  civilIdIsInBlacklist: boolean;
  blacklistData: any;
  personalDataForm: UntypedFormGroup;
  namesValidation: any = {};
  contryList: Country[] = [];
  filteredCountries: Observable<Country[]>;
  secondContryList: Country[] = [];
  filteredSecondCountryList: Observable<Country[]>;
  maritalStatuses: MaritalStatus[] = [];
  civilIdGender: string;
  civilIdAge: number;
  civilIdReadonly = false;
  verificationType = VerificationStatusType.FACE;
  _unsubscribe = new Subject<void>();
  VERIFICATION_STATUS_STYLE_MAP: typeof VERIFICATION_STATUS_STYLE_MAP = VERIFICATION_STATUS_STYLE_MAP;



  constructor(
    public formBuilder: UntypedFormBuilder,
    public blacklistService: BlacklistService,
    public dialog: MatDialog,
    public _dialogService: DialogProviderService,
    public _viewContainerRef: ViewContainerRef,
    public countries: CountryService,
    public clientService: ClientService,
    private dialogService: MatDialog,
  ) {
    this.namesValidation.minLength = 1;
    this.namesValidation.maxLength = 64;
    this.personalDataForm = this.createPersonalDataForm();
    this.civilIdIsInBlacklist = false;
    this.blacklistData = {};
    this.maritalStatuses = [];
  }

  ngOnInit() {
    this.setInitialData();
    this.getMaritalStatuses();
    this.getCountries();
    this.watchForFormChanges();
    this.watchForCountryInput();
    this.watchForCivilIdChanges();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.setInitialData();
  }

  setInitialData() {
    this.personalDataForm.setValue(this.parsePersonalData(this.data));
    this.personalDataForm.valueChanges
      .pipe(takeUntil(this._unsubscribe))
      .subscribe(value => {
        this.dataChanged.emit({
          valid: this.personalDataForm.valid,
          data: value,
          dirty: this.personalDataForm.dirty,
        });
      });

    // console.log(this.isNewItem, this.data);
    this.civilIdReadonly = false;
    if (!this.isNewItem && this.data.civilId && this.data.civilId.length > 0) {
      this.checkIfCivilIdIsInBlacklist();
      this.checkIfCanEditCivilId(this.data.civilId);
    }
  }

  watchForFormChanges() {
    this.personalDataForm.valueChanges
      .pipe(takeUntil(this._unsubscribe))
      .subscribe(value => {
        // prepare address
        const dataToEmit = value;
        this.dataChanged.emit({
          valid: this.personalDataForm.valid,
          data: dataToEmit,
          dirty: this.personalDataForm.dirty,
        });
      });
  }

  watchForCivilIdChanges() {
    this.personalDataForm.controls['civilId'].valueChanges
      .pipe(takeUntil(this._unsubscribe))
      .subscribe(
        changedId => {
          this.civilIdAge = Utils.calculateClientAgeFromCivilId(changedId);
          this.civilIdGender = Utils.getClientGenderFromCivilId(changedId);
        }
      );
  }

  checkIfCivilIdIsInBlacklist() {
    this.civilIdIsInBlacklist = false;
    const blacklistHttpCallOptions: Array<object> = [
      // Value types:
      // '1': 'Civil'
      // '2': 'Employer id'
      // '3': 'Phone number'
      { key: 'blacklistType.id', operation: '=', value: '1' },
      {
        key: 'value',
        operation: ':',
        value: [this.personalDataForm.value.civilId],
      },
    ];
    this.blacklistService
      .getBlacklist({ criteria: JSON.stringify(blacklistHttpCallOptions) })
      .pipe(takeUntil(this._unsubscribe))
      .subscribe(response => {
        this.blacklistData = response;
        if (response.content && response.content.length > 0) {
          this.civilIdIsInBlacklist = true;
        }
      });
  }

  checkIfCanEditCivilId(civilId: string) {
    const invalidCivilId = IdentifierValidation.isValidEgn(
      new UntypedFormControl(civilId)
    );
    this.civilIdReadonly = !invalidCivilId;
  }

  addToBlacklist() {
    const blacklistDialogOblect = {
      blacklistType: {
        id: 1,
      },
      value: this.personalDataForm.value.civilId,
      isPreset: true,
    };
    const dialogRef = this.dialog.open(BlacklistEditComponent, {
      data: blacklistDialogOblect,
    });

    dialogRef.afterClosed()
      .pipe(takeUntil(this._unsubscribe))
      .subscribe(result => {
        this.checkIfCivilIdIsInBlacklist();
      });
  }

  editInBlacklist() {
    const blacklistDialogOblect = this.blacklistData.content[0];
    blacklistDialogOblect.isPreset = true;

    const dialogRef = this.dialog.open(BlacklistEditComponent, {
      data: blacklistDialogOblect,
    });

    dialogRef.afterClosed()
      .pipe(takeUntil(this._unsubscribe))
      .subscribe(result => {
        this.checkIfCivilIdIsInBlacklist();
      });
  }

  getMaritalStatuses() {
    this.clientService.getMaritalStatuses()
      .pipe(takeUntil(this._unsubscribe))
      .subscribe((maritalStatuses: MaritalStatus[]) => {
        this.maritalStatuses = maritalStatuses;

        this.clientService.maritalStatusesSubject$.next(this.maritalStatuses);
      })
  }

  getCountries() {
    this.countries.getCountries().subscribe((countries: Country[]) => {
      this.contryList = countries;
    })
  }

  createPersonalDataForm(): UntypedFormGroup {
    const fb = this.formBuilder;
    return fb.group({
      firstName: ['', [Validators.maxLength(this.namesValidation.maxLength), LanguageValidation.lacksLatin]],
      middleName: ['', [Validators.maxLength(this.namesValidation.maxLength), LanguageValidation.lacksLatin]],
      lastName: ['', [Validators.maxLength(this.namesValidation.maxLength), LanguageValidation.lacksLatin]],
      civilId: [
        '',
        [
          Validators.required,
          Validators.minLength(0),
          Validators.maxLength(10),
          IdentifierValidation.onlyNumbers,
          IdentifierValidation.isValidEgn,
        ],
      ],
      nationality: [
        '',
        [
          AutoCompleteValidation.isAutocompleteValid(
            'lacksLatin',
            'name',
            LanguageValidation.lacksLatin
          ),
          AutoCompleteValidation.isAutocompleteValidObject(
            'notValidCountry',
            'name'
          ),
        ],
      ],
      secondNationality: [
        '',
        [
          AutoCompleteValidation.isAutocompleteValid(
            'lacksLatin',
            'name',
            LanguageValidation.lacksLatin
          ),
          AutoCompleteValidation.isAutocompleteValidObject(
            'notValidCountry',
            'name'
          ),
        ],
      ],
      maritalStatus: null,
      faceStatus: null,
    });
  }

  parsePersonalData(data) {
    if (!data.nationality) {
      data.nationality = '';
    }

    if (data.civilId) {
      this.civilIdGender = Utils.getClientGenderFromCivilId(data.civilId);
      this.civilIdAge = Utils.calculateClientAgeFromCivilId(data.civilId);

    } else {
      this.civilIdAge = null;
      this.civilIdGender = null;
    }

    return {
      ...data,
    };
  }

  // watchForCityInput() {
  //   console.log(this.personalDataForm.dirty);
  //   const inputLocation = this.personalDataForm.controls.currentCity;
  //   const cityInputChanges$ = inputLocation.valueChanges;
  //
  //   cityInputChanges$
  //     .filter((value) => value === '')
  //     .subscribe(() => this.citiesList = []);
  //
  //   cityInputChanges$
  //     .debounceTime(700)
  //     .filter((value) => value !== '')
  //     .mergeMap((value) => this.citiesService.getCitiesStartingWith(value))
  //     .subscribe((citiesList) => {
  //       this.citiesList = citiesList;
  //     });
  // }

  // mapCityToDisplayValue(city) {
  //   return city ? `${city.type}${city.name} - ${city.municipality.name}` : '';
  // }

  mapCountryToDisplayValue(country) {
    return country ? country.name : '';
  }

  compareItemsById(firstObj, secondObj) {
    if (firstObj && secondObj) {
      return firstObj.id === secondObj.id;
    } else {
      return false;
    }
  }

  watchForCountryInput() {
    this.filteredCountries = this.personalDataForm.get('nationality').valueChanges.pipe(
      startWith(''),
      map(value => {
        const name = typeof value === 'string' ? value : value?.name;
        return name ? this._filterNationality(name as string) : this.contryList.slice();
      })
    );

    this.filteredSecondCountryList = this.personalDataForm.get('secondNationality').valueChanges.pipe(
      startWith(''),
      map(value => {
        const name = typeof value === 'string' ? value : value?.name;
        return name ? this._filterNationality(name as string) : this.contryList.slice();
      })
    );
  }

  private _filterNationality(name: string): Country[] {
    const filterValue = name.toLowerCase();

    return this.contryList.filter((country: Country) => country.name.toLowerCase().startsWith(filterValue.toLowerCase()) || country.name.toLowerCase().includes(filterValue.toLowerCase()));
  }
  editVerificationStatus() {
    const verificationStatuses$ = this.clientService.getVerificationStatuses$(this.verificationType);
    verificationStatuses$
      .pipe(takeUntil(this._unsubscribe))
      .subscribe(statusList => {
        this.dialogService
          .open(VerificationStatusEditComponent, {
            data: {
              type: this.verificationType,
              civilId: this.client.civilId,
              brandCode: this.client.brand.code,
              status: this.client.faceStatus,
              verificationStatusList: statusList,
            },
          })
          .afterClosed()
          .pipe(filter(success => !!success))
          .subscribe((success: boolean) => {
            console.log(success);
          });
      });
  }

  ngOnDestroy(): void {
    this._unsubscribe.next();
    this._unsubscribe.complete();
  }
}
