<div *ngIf="data" flex layout="row" layout-align="end end">
  <button (click)="onNoClick()" mat-icon-button>
    <mat-icon>close</mat-icon>
  </button>
</div>
<mat-card appearance="outlined">
  <mat-card-title>
    <div flex layout="row" layout-align="space-between center">
      <span
        flex
        class="edit-screen-card-title"
        [translate]="
          data?.title ? 'global.' + data.title : 'identityReports.report'
        "
      >Report Data</span
      >
    <div flex layout="row" layout-align="space-between center">
        <span class="edit-screen-card-title" [translate]="'global.client'"
        >Client</span
        >
      <div layout="row" layout-align="end center" flex>
      <a   
      [routerLink]="['/credits/applications', clientData.id]"
      target="_blank"
      matTooltip="{{ 'applications.redirectToApplication' | translate }}"
      matTooltipPosition="above">
        <button
        *ngIf="data?.title"
        mat-icon-button
        class="margin-right-8px mat-elevation-z2"
        [disabled]="!clientData.id"
      >
        <mat-icon
        >request_quote
        </mat-icon>
      </button>
    </a>
        <button
          mat-icon-button
          class="margin-right-8px mat-elevation-z2"
          [disabled]="isClientUpdated"
          (click)="updateClient()"
        >
          <mat-icon
            matTooltip="{{ 'identityReports.updateAllValue' | translate }}"
            matTooltipPosition="above"
          >arrow_forward
          </mat-icon>
        </button>
        <button
          mat-icon-button
          [disabled]="isClientSaved || !isClientForSave"
          class="mat-elevation-z2"
          (click)="save()"
          [ngClass]="{ 'save-changes': isClientForSave && !isClientSaved }"
        >
          <mat-icon
            matTooltip="{{ 'identityReports.saveAllValue' | translate }}"
            matTooltipPosition="above"
          >save
          </mat-icon>
        </button>
      </div>
    </div>
  </div>
  </mat-card-title>
  <mat-card-content class="remove-mat-form-field-wrapper">
    <div layout="column">
      <div
        flex="100"
        layout="row"
        layout-wrap
        layout-align="space-between center"
        *ngFor="let row of dataRows"
      >
        <mat-form-field flex>
          <input
            matInput
            type="text"
            readonly
            [value]="row.reportValue"
            placeholder="{{ 'identityReports.' + row.key | translate }}"
            flex="90"
            itfgOverflowTooltip
            matTooltip
            [tooltipText]="row.reportValue"
            [tooltipPosition]="'above'"
          />
      <mat-label>{{ 'identityReports.' + row.key | translate }}</mat-label>
        </mat-form-field>
        <div flex="10" layout="row" layout-align="center center">
          <button
            flex
            type="button"
            [disabled]="['BLACKLIST_READ'] | isUnauthorized | async"
            mat-icon-button
            *ngIf="!row.isTempEqualReport && row.updateable"
            class="input-suffix-button"
            (click)="updateClientField(row)"
          >
            <mat-icon
              matTooltip="{{ 'identityReports.updateValue' | translate }}"
              matTooltipPosition="above"
            >arrow_forward
            </mat-icon>
          </button>
          <button
            flex
            type="button"
            [disabled]="['BLACKLIST_READ'] | isUnauthorized | async"
            mat-icon-button
            *ngIf="row.isTempEqualReport && !row.isTempEqualClient && row.updateable"
            class="input-suffix-button"
            (click)="revertClientField(row)"
          >
            <mat-icon
              matTooltip="{{ 'identityReports.undoValue' | translate }}"
              [matTooltipDisabled]="
                  ['BLACKLIST_READ'] | isUnauthorized | async
                "
              matTooltipPosition="above"
            >undo
            </mat-icon>
          </button>
        </div>
        <mat-form-field flex>
          <input
            matInput
            readonly
            type="text"
            [value]="row.tempValue"
            [style.color]="
              !row.isReportEqualClient && !row.updateable
              ? 'orange'
              : row.isTempEqualReport && !row.isReportEqualClient && row.updateable
              ? 'green'
              : !row.isReportEqualClient && row.updateable
              ? 'red'
              : ''
              "
            placeholder="{{ 'identityReports.' + row.key | translate }}"
            itfgOverflowTooltip
            matTooltip
            [tooltipText]="row.tempValue"
            [tooltipPosition]="'above'"
          />
      <mat-label>{{ 'identityReports.' + row.key | translate }}</mat-label>
        </mat-form-field>
      </div>
    </div>
  </mat-card-content>
</mat-card>
