import { ValidationErrors, UntypedFormControl, ValidatorFn } from '@angular/forms';
import { Utils } from '../utils/utils';

export const AutoCompleteValidation = {
  isAutocompleteValid: isAutocompleteValid,
  isAutocompleteValidObject: isAutocompleteValidObject,
  isAutocompleteValidObjectAndInvalidId: isAutocompleteValidObjectAndInvalidId,
};

function isAutocompleteValid(
  validationKey: string,
  propertyPath: string,
  validator: ValidatorFn
): ValidatorFn {
  return (control: UntypedFormControl): ValidationErrors => {
    let validationResult;
    let controlToValidate: UntypedFormControl = control;

    if (typeof control.value === 'object') {
      const value = Utils.deepValue(control.value, propertyPath);
      controlToValidate = new UntypedFormControl(value);
    }

    validationResult = validator(controlToValidate);

    if (validationResult === null) {
      return null;
    } else {
      const validationError = {};
      validationError[validationKey] = { value: controlToValidate.value };
      return validationError;
    }
  };
}

function isAutocompleteValidObject(
  validationKey: string,
  propertyPath: string
) {
  return (control: UntypedFormControl): ValidationErrors => {
    if (
      typeof control.value === 'object' ||
      control.value === '' ||
      !control.value
    ) {
      return null;
    } else {
      const validationError = {};
      validationError[validationKey] = { value: control.value };
      return validationError;
    }
  };
}

function isAutocompleteValidObjectAndInvalidId(
  validationKey: string,
  propertyPath: string
) {
  return (control: UntypedFormControl): ValidationErrors => {
    if (
      typeof control.value === 'object' &&
      control.value !== null &&
      Utils.deepValue(control.value, propertyPath) === null
    ) {
      const validationError = {};
      validationError[validationKey] = { value: control.value };
      return validationError;
    } else {
      return null;
    }
  };
}
