import { ModuleWithProviders, NgModule } from '@angular/core';
import { SocketIoConfig } from './socket-io.config';
import { SOCKET_CONFIG_TOKEN } from './socket-io.injectionToken';
import { Socket } from './socket-io.service';
import { SocketFactory } from './socket-io.factory';

@NgModule({})
export class SocketIoModule {
  static forRoot(config: SocketIoConfig): ModuleWithProviders<SocketIoModule> {
    return {
      ngModule: SocketIoModule,
      providers: [
        { provide: SOCKET_CONFIG_TOKEN, useValue: config },
        {
          provide: Socket,
          useFactory: SocketFactory,
          deps: [SOCKET_CONFIG_TOKEN],
        },
      ],
    };
  }
}
