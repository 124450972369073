import { Component, OnInit, Input } from '@angular/core';
import { BrandConfig, BRAND_CONFIG } from '../../../config/app-config';
import { Brand } from '../../../core/types/brand';

@Component({
  selector: 'itfg-brand-logo',
  templateUrl: './brand-logo.component.html',
  styleUrls: ['./brand-logo.component.scss'],
})
export class BrandLogoComponent implements OnInit {
  @Input() brand: any;
  public config: BrandConfig = BRAND_CONFIG;

  constructor() {}

  ngOnInit() {}
}
