import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'itfgDurationPipe'
})
export class FormatDurationPipe implements PipeTransform {

  constructor(private translate: TranslateService) {}

  transform(value: string | number): string {
    if (!value) {
      return '';
    }

    const totalSeconds = typeof value === 'string' ? Number(value) : value;

    const minutes: number = Math.floor(totalSeconds / 60);
    const seconds: number = totalSeconds % 60;

    if (totalSeconds < 60) {
      return this.translate.instant('callLog.callOverviewSeconds', { seconds });
    } else if (seconds === 0) {
      return this.translate.instant('callLog.callOverviewMinutes', { minutes });
    } else {
      return this.translate.instant('callLog.callOverviewFull', { minutes, seconds });
    }
  }
}
