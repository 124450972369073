import { Pipe, PipeTransform } from '@angular/core';

const isPrimitive = (value: any): boolean => {
  const type = typeof value;
  if (type === 'string' || type === 'number' || type === 'boolean') {
    return true;
  } else {
    return false;
  }
};

const getArrayDiff = <T>(array1: T[], array2: T[]): T[] => {
  const leftSide = array1.filter(function(i) {
    return array2.indexOf(i) === -1;
  });

  const rightSide = array2.filter(function(i) {
    return array1.indexOf(i) === -1;
  });

  return [...leftSide, ...rightSide];
};

@Pipe({
  name: 'objectDiff',
})
export class ObjectDiffPipe implements PipeTransform {
  transform<T>(firstObject: T, secondObject: T): { [key: string]: boolean } {
    const matches = {};

    const firstKeys = Object.keys(firstObject);
    const secondKeys = Object.keys(secondObject);

    const diffKeys = getArrayDiff(firstKeys, secondKeys);

    diffKeys.forEach(key => {
      matches[key] = false;
    });

    firstKeys.forEach(key => {
      const propertyType = typeof firstObject[key];

      if (
        isPrimitive(firstObject[key]) &&
        firstObject[key] === secondObject[key]
      ) {
        matches[key] = true;
      } else if (
        JSON.stringify(firstObject[key]) === JSON.stringify(secondObject[key])
      ) {
        matches[key] = true;
      } else {
        matches[key] = false;
      }
    });

    return matches;
  }
}
