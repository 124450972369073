<div *ngIf="client?.civilId && client?.id" class="top-padding-16">
  <h3 class="edit-screen-card-title">
    {{ 'identityReports.ccrReport' | translate }}
  </h3>
  <div
    flex
    layout="row"
    layout-wrap
    layout-align="space-between stretch"
  >
    <div flex="100" flex-gt-md="75" flex-gt-sm="100" flex-gt-xs="100">
      <itfg-reports-info-panel
        [listData]="ccrListData"
        [reportData]="ccrReportData"
        [reportTime]="reportTime"
        [requesterName]="ccrReportData?.requesterName"
        [metaStatusCode]="metaStatusCode"
        [requestTime]="ccrTime"
        (dataChanged)="ccrDataChanged($event)"
      ></itfg-reports-info-panel>
    </div>
    <div flex layout="row" layout-align="end start" [style.min-height.px]="65">
      <itfg-client-differences-button
        [config]="ccrConfig"
      >
      </itfg-client-differences-button>
      <button
        mat-icon-button
        [disabled]="['CCR_ADD'] | isUnauthorized | async"
        matTooltip="{{ 'ccrRequests.uploadReport' | translate }}"
        (click)="uploadCcrRequestsReport()"
      >
        <mat-icon>upload</mat-icon>
      </button>
      <button
        mat-raised-button
        [disabled]="['CCR_ADD'] | isUnauthorized | async"
        (click)="getNewCcr()"
      >
        {{ 'ccr.newCcr' | translate }}
      </button>

    </div>
  </div>
      <!-- <itfg-ccr-report-summary
      *ngIf= "ccrReportData?.status === 'success' &&
              !!ccrData &&
              ccrData?.borrower?.section?.length > 0 &&
              ccrReportData?.type === 'ccr'"

      [reportData]="ccrReportData"
      >
    </itfg-ccr-report-summary> -->
      <!-- CCR requests report -->
      <ng-container *ngIf="ccrReportData?.type === 'ccr_requests'">
        <mat-card appearance="outlined">
          <itfg-ccr-requests
            [report]="ccrReportData?.data">
          </itfg-ccr-requests>
        </mat-card>
      </ng-container>
      <h4
        *ngIf="ccrListData?.length === 0 && client?.id"
        flex
        layout="row"
        layout-align="start center"
      >
        {{ 'credits.noCcrFiles' | translate: { civilId: client?.civilId } }}
      </h4>
      <h4
        *ngIf="
          (
            ccrListData?.length > 0 &&
            client?.id &&
            ccrData?.borrower?.code &&
            ccrData?.borrower?.section?.length === 0 &&
            client?.civilId
          )
        "
        flex
        layout="row"
        layout-align="start center"
      >
        {{ 'credits.noCcrData' | translate: { civilId: client?.civilId } }}
      </h4>
      <h4
        *ngIf="
          (
            ccrListData?.length > 0 &&
            client?.id  &&
            client?.civilId && ccrReportData?.status === 'no_data'
          )
        "
        flex
        layout="row"
        layout-align="start center"
      >
        {{ 'credits.noCcrInfo' | translate: { civilId: client?.civilId, ccrTime: ccrTime | itfgDateFormat, source: ccrReportData?.source || '-'} }}
        <itfg-no-reports-info
          [alertMessage]="{civilId: client?.civilId, type: ccrReportData?.type, source: ccrReportData?.source, status: ccrReportData?.status, time: ccrReportData?.time, data: ccrReportData?.data}">
        </itfg-no-reports-info>
      </h4>
      <ng-container [ngSwitch]="ccrReportData?.status">
        <h4 *ngSwitchCase="'server_error'" layout="row" layout-align="start center">
          {{ 'reports.serverGenericError' | translate: {requestTime: ccrTime | itfgDateFormat} }}
          <itfg-no-reports-info
            [alertMessage]="{civilId: client?.civilId, type: ccrReportData?.type, source: ccrReportData?.source, status: ccrReportData?.status, time: ccrReportData?.time, data: ccrReportData?.data}">
          </itfg-no-reports-info>
        </h4>
        <h4 *ngSwitchCase="'server_timeout'" layout="row" layout-align="start center">
          {{ 'reports.serverTimeoutError' | translate: {requestTime: ccrTime | itfgDateFormat} }}
          <itfg-no-reports-info
            [alertMessage]="{civilId: client?.civilId, type: ccrReportData?.type, source: ccrReportData?.source, status: ccrReportData?.status, time: ccrReportData?.time, data: ccrReportData?.data}">
          </itfg-no-reports-info>
        </h4>
      </ng-container>
      <h4
        *ngIf="!client?.civilId && client?.id"
        [translate]="'credits.noCivilId'"
        flex
        layout="row"
        layout-align="start center"
      >
        The Client doest have civil id!
      </h4>

</div>
<ng-container *ngIf="ccrData?.borrower?.section?.length > 0 && ccrReportData?.type === 'ccr'">
  <h3
    *ngIf="ccrData?.borrower?.section?.length > 0"
    class="edit-screen-card-title left-margin-25px"
    flex
    layout="row"
    layout-padding="10"
    layout-align="left"
  >
    <span *ngIf="ccrData?.borrower?.section">{{
      'ccr.' + (ccrData?.borrower?.section)[0].entityType | translate
    }}</span>
  </h3>
  <div
    *ngIf="
      ccrData?.borrower?.section &&
      (ccrData?.borrower?.section)[0].activeCredits
    "
  >
    <mat-card appearance="outlined">
      <mat-card-header>
        <mat-card-title class="edit-screen-card-title"
          >1. {{ 'ccr.active' | translate }}</mat-card-title
        >
        <mat-card-subtitle>
          <p class="active-count">
            {{
              'ccr.ccrActiveCountTemplate'
                | translate
                  : {
                      creditsCount:
                        (ccrData?.borrower?.section)[0].activeCredits
                          ?.credCount,
                      sourcesCount:
                        (ccrData?.borrower?.section)[0].activeCredits
                          .sourceEntityCount
                    }
            }}
          </p>
        </mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        <span
          *ngIf="
            (ccrData?.borrower?.section)[0].activeCredits?.summaries
              ?.length === 0
          "
          flex
          layout="row"
          class="left-margin-25px"
          [translate]="'global.noData'"
        >
          No Data</span
        >
        <div
          *ngIf="
            (ccrData?.borrower?.section)[0].activeCredits?.summaries?.length >
            0
          "
        >
          <div
            *ngFor="
              let group of (ccrData?.borrower?.section)[0].activeCredits
                .summaries
            "
          >
            <div
              class="edit-screen-card-title left-padding-16 top-padding-16"
            >
              {{
                group.groupingAttribute
                  | summaryGroupToTranslation
                  | translate
              }}
            </div>

            <itfg-data-table
              *ngIf="group?.summary"
              [data]="group.summary"
              [columns]="columnsActive"
            >
              <ng-template itfgDataTableTemplate="dateFrom" let-value="value">
                <span>{{ value | date }}</span>
              </ng-template>
              <ng-template itfgDataTableTemplate="type" let-row="row">
                <span>{{
                  row['type'] ||
                    row['overduePaymentPeriod'] ||
                    row['rest'] ||
                    row['term']
                }}</span>
              </ng-template>
            </itfg-data-table>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
    <mat-card appearance="outlined">
      <mat-card-header>
        <mat-card-title class="edit-screen-card-title"
          >2. {{ 'ccr.overdue' | translate }}</mat-card-title
        >
      </mat-card-header>
      <mat-card-content>
        <span
          *ngIf="
            (ccrData?.borrower?.section)[0].overdueHistory?.summary
              ?.length === 0
          "
          flex
          layout="row"
          class="left-margin-25px"
          [translate]="'global.noData'"
        >
          No Data</span
        >

        <itfg-data-table
          *ngIf="
            (ccrData?.borrower?.section)[0].overdueHistory?.summary?.length >
            0
          "
          [data]="(ccrData?.borrower?.section)[0].overdueHistory.summary"
          [columns]="columnsOverdue"
        >
          <ng-template itfgDataTableTemplate="active" let-value="value">
            <span>{{
              (value ? 'ccr.stateActive' : 'ccr.stateRefund') | translate
            }}</span>
          </ng-template>
        </itfg-data-table>
      </mat-card-content>
    </mat-card>
    <mat-card appearance="outlined">
      <mat-card-header>
        <mat-card-title class="edit-screen-card-title"
          >3. {{ 'ccr.new' | translate }}
        </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <span
          *ngIf="
            (ccrData?.borrower?.section)[0].newCredits?.summary.length === 0
          "
          flex
          layout="row"
          class="left-margin-25px"
          [translate]="'global.noData'"
        >
          No data</span
        >

        <itfg-data-table
          *ngIf="
            (ccrData?.borrower?.section)[0].newCredits?.summary?.length > 0
          "
          [data]="(ccrData.borrower?.section)[0].newCredits.summary"
          [columns]="columnsNew"
        >
        </itfg-data-table>
      </mat-card-content>
    </mat-card>
    <mat-card appearance="outlined">
      <mat-card-header>
        <mat-card-title class="edit-screen-card-title"
          >4. {{ 'ccr.relatedCredits' | translate }}
        </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <span
          *ngIf="
            !((ccrData?.borrower?.section)[0]?.relatedActiveCredits
              ?.creditsSet)[0]?.credCount &&
            !((ccrData?.borrower?.section)[0]?.relatedActiveCredits
              ?.creditsSet)[1]?.credCount
          "
          flex
          layout="row"
          class="left-margin-25px"
          [translate]="'global.noData'"
        >
          No data</span
        >
        <div
          *ngIf="
            (ccrData?.borrower?.section)[0].relatedActiveCredits?.creditsSet
              ?.length > 0
          "
        >
          <div
            *ngFor="
              let creditSet of (ccrData?.borrower?.section)[0]
                ?.relatedActiveCredits?.creditsSet
            "
          >
            <div
              *ngIf="creditSet?.summary"
              class="edit-screen-card-title left-padding-16 top-padding-16"
            >
              {{ creditSet.personRole }}
            </div>
            <p
              *ngIf="creditSet?.summary"
              class="active-count  left-padding-16"
            >
              {{
                'ccr.ccrActiveCountTemplate'
                  | translate
                    : {
                        creditsCount: creditSet?.credCount,
                        sourcesCount: creditSet.sourceEntityCount
                      }
              }}
            </p>

            <itfg-data-table
              *ngIf="creditSet?.summary"
              [data]="creditSet.summary"
              [columns]="columnsRelated"
            >
              <ng-template itfgDataTableTemplate="dateFrom" let-value="value">
                <span>{{ value | date }}</span>
              </ng-template>
            </itfg-data-table>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
  <h5
    *ngIf="ccrData?.borrower?.section && (ccrData?.borrower?.section)[1]"
    class="edit-screen-card-title left-margin-25px"
    layout-padding="10"
    flex
    layout="row"
    layout-align="left"
  >
    <span>{{
      'ccr.' + (ccrData?.borrower?.section)[1].entityType | translate
    }}</span>
  </h5>
  <div *ngIf="ccrData?.borrower?.section && (ccrData?.borrower?.section)[1]">
    <mat-card appearance="outlined">
      <mat-card-header>
        <mat-card-title class="edit-screen-card-title"
          >1. {{ 'ccr.active' | translate
          }}<span *ngIf="(ccrData?.borrower?.section)[1]"></span
        ></mat-card-title>
        <mat-card-subtitle>
          <p class="active-count">
            {{
              'ccr.ccrActiveCountTemplate'
                | translate
                  : {
                      creditsCount:
                        (ccrData?.borrower?.section)[1].activeCredits
                          ?.credCount,
                      sourcesCount:
                        (ccrData?.borrower?.section)[1].activeCredits
                          .sourceEntityCount
                    }
            }}
          </p>
        </mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        <span
          *ngIf="
            (ccrData?.borrower?.section)[1].activeCredits?.summaries
              ?.length === 0
          "
          flex
          layout="row"
          class="left-margin-25px"
          [translate]="'global.noData'"
        >
          No Data</span
        >
        <div
          *ngIf="
            (ccrData?.borrower?.section)[1].activeCredits.summaries.length > 0
          "
        >
          <div
            *ngFor="
              let group of (ccrData?.borrower?.section)[1].activeCredits
                .summaries
            "
          >
            <div
              class="edit-screen-card-title left-padding-16 top-padding-16"
            >
              {{
                group.groupingAttribute
                  | summaryGroupToTranslation
                  | translate
              }}
            </div>

            <itfg-data-table [data]="group.summary" [columns]="columnsActive">
              <ng-template itfgDataTableTemplate="dateFrom" let-value="value">
                <span>{{ value | date }}</span>
              </ng-template>
              <ng-template itfgDataTableTemplate="type" let-row="row">
                <span>{{
                  row['type'] ||
                    row['overduePaymentPeriod'] ||
                    row['rest'] ||
                    row['term']
                }}</span>
              </ng-template>
            </itfg-data-table>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
    <mat-card appearance="outlined">
      <mat-card-header>
        <mat-card-title class="edit-screen-card-title"
          >2. {{ 'ccr.overdue' | translate
          }}<span *ngIf="(ccrData?.borrower?.section)[1]"></span
        ></mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <span
          *ngIf="
            (ccrData?.borrower?.section)[1].overdueHistory?.summary
              ?.length === 0
          "
          flex
          layout="row"
          class="left-margin-25px"
          [translate]="'global.noData'"
        >
          No Data</span
        >

        <itfg-data-table
          *ngIf="
            (ccrData?.borrower?.section)[1].overdueHistory?.summary?.length >
            0
          "
          [data]="(ccrData?.borrower?.section)[1].overdueHistory?.summary"
          [columns]="columnsOverdue"
        >
          <ng-template itfgDataTableTemplate="active" let-value="value">
            <span>{{
              (value ? 'ccr.stateActive' : 'ccr.stateRefund') | translate
            }}</span>
          </ng-template>
        </itfg-data-table>
      </mat-card-content>
    </mat-card>
    <mat-card appearance="outlined">
      <mat-card-header>
        <mat-card-title class="edit-screen-card-title"
          >3. {{ 'ccr.new' | translate
          }}<span *ngIf="(ccrData?.borrower?.section)[1]"></span
        ></mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <span
          *ngIf="
            (ccrData?.borrower?.section)[1].newCredits.summary?.length === 0
          "
          flex
          layout="row"
          class="left-margin-25px"
          [translate]="'global.noData'"
        >
          No Data</span
        >

        <itfg-data-table
          *ngIf="
            (ccrData?.borrower?.section)[1].newCredits?.summary?.length > 0
          "
          [data]="(ccrData?.borrower?.section)[1].newCredits?.summary"
          [columns]="columnsNew"
        >
        </itfg-data-table>
      </mat-card-content>
    </mat-card>
    <mat-card appearance="outlined">
      <mat-card-header>
        <mat-card-title class="edit-screen-card-title"
          >4. {{ 'ccr.relatedCredits' | translate }}
        </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <span
          *ngIf="
            !((ccrData?.borrower?.section)[1]?.relatedActiveCredits
              ?.creditsSet)[0]?.credCount &&
            !((ccrData?.borrower?.section)[1]?.relatedActiveCredits
              ?.creditsSet)[1]?.credCount
          "
          flex
          layout="row"
          class="left-margin-25px"
          [translate]="'global.noData'"
        >
          No data</span
        >
        <div
          *ngIf="
            (ccrData?.borrower?.section)[1]?.relatedActiveCredits?.creditsSet
              ?.length > 0
          "
        >
          <div
            *ngFor="
              let creditSet of (ccrData?.borrower?.section)[1]
                ?.relatedActiveCredits?.creditsSet
            "
          >
            <div
              *ngIf="creditSet?.summary"
              class="edit-screen-card-title left-padding-16 top-padding-16"
            >
              {{ creditSet.personRole }}
            </div>
            <p
              *ngIf="creditSet?.summary"
              class="active-count  left-padding-16"
            >
              {{
                'ccr.ccrActiveCountTemplate'
                  | translate
                    : {
                        creditsCount: creditSet?.credCount,
                        sourcesCount: creditSet.sourceEntityCount
                      }
              }}
            </p>

            <itfg-data-table
              *ngIf="creditSet?.summary"
              [data]="creditSet.summary"
              [columns]="columnsRelated"
            >
              <ng-template itfgDataTableTemplate="dateFrom" let-value="value">
                <span>{{ value | date }}</span>
              </ng-template>
            </itfg-data-table>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</ng-container>
