import { Injectable } from '@angular/core';
import { RequestService } from './request.service';
import { SearchOptionsDTO, SearchOptions } from '../types/search-options';
import {
  Reminder,
  EditReminderDto,
  CreateReminderDto,
} from '../types/reminder';
import { Subject, Observable } from 'rxjs';
import { Page } from '../types/page';
import * as moment from 'moment/moment';
import { Administrator } from '../types/administrator';
import { SearchOperations } from '../types/search-criterium';
import { SessionService } from './session.service';
import { CollectionRequestService } from './collection-request.service';
import { CreditStatusNames } from '../types/credit-status';

@Injectable()
export class ReminderService {
  request: CollectionRequestService;

  refreshReminder$ = new Subject<void>();

  constructor(
    request: CollectionRequestService,
    private sessionService: SessionService
  ) {
    this.request = request;
  }

  getReminderList$(options?: SearchOptionsDTO): Observable<Page<Reminder>> {
    return this.request.get(['reminders'], {
      params: {
        ...options,
        limit: options.pageSize,
      },
    });
  }

  getReminderById$(id: number) {
    return this.request.get(['reminders', id]);
  }

  createReminder$(reminder: CreateReminderDto) {
    return this.request.post(['reminders'], { body: reminder });
  }

  deleteReminder$(id: number) {
    return this.request.delete(['reminders', id]);
  }

  editReminder(id: number, editReminderDto: EditReminderDto) {
    return this.request.post(['reminders', id], { body: editReminderDto });
  }

  deactivateReminder(id: number) {
    return this.request.post(['reminders', id, 'deactivate']);
  }

  getMostRecentFutureOrOldestExpiredReminder(reminders: Reminder[]): Reminder {
    const upcomingReminders: Reminder[] = reminders.filter(reminder =>
      moment(reminder.time).isAfter(moment())
    );
    return upcomingReminders.length > 0
      ? upcomingReminders[upcomingReminders.length - 1]
      : reminders[0];
  }

  compareRemindersByDate(a: Reminder, b: Reminder) {
    const firstDate = moment(a.time);
    const secondDate = moment(b.time);
    if (firstDate.isAfter(secondDate)) {
      return -1;
    }
    if (firstDate.isBefore(secondDate)) {
      return 1;
    }
    return 0;
  }

  reminderIsActive(reminder: Reminder): boolean {
    if (!reminder) {
      return false;
    }
    return moment(reminder.time).isAfter(moment());
  }

  requestCreditReminderToDisplayText(date: any): any {
    if (!date) {
      return '';
    }
    let displayText = moment(date).fromNow();
    const time = moment(date);
    const isToday = time.isSame(moment(), 'day');
    const hoursDiff = time.diff(moment(), 'hours');
    if (isToday && hoursDiff <= -1) {
      displayText = 'днес';
    }

    return {
      display: moment(date).format('DD.MM.YYYY HH:mm'),
      tooltip: displayText,
    };
  }

  getOperatorReminderList$(): Observable<Page<Reminder>> {
    const currentOperator: Administrator = this.sessionService
      .currentlyLoggedOperator;
    const searchOptions = new SearchOptions({
      pageSize: 5000,
    });
    searchOptions.addCriterium({
      key: 'operatorId',
      operation: SearchOperations.EQUALS,
      value: currentOperator.id,
    });
    const operatorReminderList$: Observable<Page<
      Reminder
    >> = this.getReminderList$(searchOptions.getDTO());
    return operatorReminderList$;
  }

  mapReminderCreditStatus(reminders: Reminder[]): Reminder[] {
    for (let reminder of reminders) {
      if (reminder.credit.active && Number(reminder.credit.maturedUnpaid) > 0) {
        reminder.credit.creditStatus = {};
        reminder.credit.creditStatus.name = CreditStatusNames.OVERDUE;
      } else if (
        reminder.credit.active &&
        Number(reminder.credit.maturedUnpaid) === 0
      ) {
        reminder.credit.creditStatus = {};
        reminder.credit.creditStatus.name = CreditStatusNames.REGULAR;
      } else if (!reminder.credit.active) {
        reminder.credit.creditStatus = {};
        reminder.credit.creditStatus.name = CreditStatusNames.REPAID;
      } else {
        reminder.credit.creditStatus = {};
        reminder.credit.creditStatus.name = '';
      }
    }
    return reminders;
  }
}
