export interface CcrSummaryGroup {
  summaryGroup: string;
}
export enum CcrSummaryGroupNames {
  TYPE = 'type',
  OVERDUE_PAYMENT_PERIOD = 'overduePaymentPeriod',
  REST = 'rest',
  TERM = 'term',
  TOTAL = 'total',
}
