export enum SearchOperations {
  LESS_THAN = '<', // SQL LESS THAN
  LESS_THAN_OR_EQUAL = '<=', // SQL LESS THAN OR EQUAL
  GREATER_THAN = '>', // SQL GREATER THAN
  GREATER_THAN_OR_EQUAL = '>=', // SQL GREATER THAN OR EQUAL
  EQUALS = '=', // SQL EXACT MATCH
  LIKE = '~', // SQL LIKE
  IN = ':', // SQL IN
  EQUALS_CRUD = '$eq', // SQL EXACT MATCH
  LIKE_CRUD = '$cont', // SQL LIKE
  LESS_THAN_CRUD = '$lt', // SQL LESS THAN,
  GREATER_THAN_CRUD = '$gt', // SQL GREATER THAN,
  LESS_THAN_OR_EQUAL_CRUD = '$lte', // SQL LESS THAN OR EQUAL,
  GREATER_THAN_OR_EQUAL_CRUD = '$gte', // SQL GREATER THAN OR EQUAL,
  IN_CRUD = '$in', // SQL IN
  IS = 'is' // SQL IS
}



export interface SearchCriterium {
  key: string;
  operation: SearchOperations | string;
  value: number | number[] | string | string[] | boolean | boolean[];
}
