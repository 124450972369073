import { PipeTransform, Pipe } from '@angular/core';
import { IbanUtilService } from '../../../core/services/iban-util.service';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'formatIban',
})
export class FormatIbanPipe implements PipeTransform {
  private translatedIban = '';
  private translatedBankIdCode = '';

  constructor(
    private ibanUtilService: IbanUtilService,
    private translateService: TranslateService
  ) {}

  transform(iban: string, format: 'full' | 'bankIdCode'| 'bankName' | 'obfuscated' = 'full' ) {
    if (!iban || !this.ibanUtilService.isValidIban(iban)) {
      return iban;
    }
    const bankIdCode = this.ibanUtilService.matchIbanBICCode(iban);

    this.translateService
      .get('bankIdCodes.' + bankIdCode)
      .subscribe(translation => {
        this.translatedBankIdCode = translation;
        this.translatedIban = `${this.translatedBankIdCode} - ${iban}`;
      });

    if (format === 'bankIdCode') {
      return this.translatedBankIdCode;
    }

    if (format === 'obfuscated') {
      return `${this.translatedBankIdCode} - ${iban.replace(/(?<=.{2}).(?=.{4,}$)/g, '*')}`;
    }

    if (format === 'bankName') {
      return this.translatedBankIdCode
    }

    return this.translatedIban;
  }
}
