import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { InstallmentAccrualComponentType } from '../../../core/types/accrual';

@Pipe({
  name: 'isAccrualCorrectable'
})
export class IsAccrualCorrectablePipe implements PipeTransform {

  transform(row: any, installment: any, receivables: any): boolean {
    const accrualStartDate = moment(installment?.accrualsStartDate);
    const startOfThisYear = moment().startOf('year');

    if (accrualStartDate.isBefore(startOfThisYear)) {
      return true;
    }

    if (row.receivables === 0 || row.receivables === row.paidTotal) {
      return true;
    }

    if (row.component === InstallmentAccrualComponentType.TOTAL) {
      return receivables
        .filter(receivable => receivable.component !== InstallmentAccrualComponentType.TOTAL && receivable.component !== InstallmentAccrualComponentType.PRINCIPAL)
        .every(receivable => receivable.receivables === 0 || receivable.receivables === receivable.paidTotal);
    }

    return false;
  }
}