import { Injectable } from '@angular/core';
import {
  FilterCardConfig,
  FilterUIType,
  FilterGroupType,
  FilterConfig,
} from '../filter/types';
import { SearchOperations } from '../../core/types/search-criterium';
import { FilterValueLocalService } from '../filter/filter-value-local.service';
import { PageName } from '../../core/types/user-preferences';
@Injectable()
export class ClaimListFilterConfigService {
  config: FilterCardConfig;
  pageName: PageName;

  constructor() {
    this.pageName = PageName.CLAIMS;

    this.config = {
      id: new FilterConfig({
        path: 'id',
        type: FilterUIType.INPUT,
        group: FilterGroupType.CLAIM,
        availableOperations: [
          SearchOperations.EQUALS,
          SearchOperations.LESS_THAN,
          SearchOperations.GREATER_THAN,
        ],
      }),
      completed: new FilterConfig({
        path: 'completed',
        type: FilterUIType.DROPDOWN,
        group: FilterGroupType.CLAIM,
        translationKey: 'claims.completed',
        valueListService: new FilterValueLocalService([
          {
            value: true,
            displayText: 'global.yes',
          },
          {
            value: false,
            displayText: 'global.no',
          },
        ]),
        availableOperations: [SearchOperations.EQUALS],
      }),
      subject: new FilterConfig({
        path: 'subject',
        type: FilterUIType.INPUT,
        group: FilterGroupType.CLAIM,
        availableOperations: [SearchOperations.EQUALS, SearchOperations.LIKE],
      }),
      notes: new FilterConfig({
        path: 'notes',
        type: FilterUIType.INPUT,
        group: FilterGroupType.CLAIM,
        availableOperations: [SearchOperations.LIKE],
      }),
      time: new FilterConfig({
        path: 'time',
        translationKey: 'claims.createDate',
        type: FilterUIType.DATEPICKER,
        group: FilterGroupType.CLAIM,
        availableOperations: [
          SearchOperations.EQUALS,
          SearchOperations.LESS_THAN,
          SearchOperations.GREATER_THAN,
        ],
      }),
      'user.id': new FilterConfig({
        path: 'user.id',
        type: FilterUIType.INPUT,
        group: FilterGroupType.CLIENT,
        availableOperations: [
          SearchOperations.EQUALS,
          SearchOperations.LESS_THAN,
          SearchOperations.GREATER_THAN,
        ],
      }),
    };
  }
}
