<div layout="column" >
  <div layout="row"
       layout-align="space-between center"
       flex>
  <span [translate]="'address.getNewAddressReport.' + data?.reportType"
        mat-dialog-title>Get new address report</span>
    <button tabindex="-1"
            mat-icon-button
            class="close-button"
            [mat-dialog-close]="false">
      <mat-icon>clear</mat-icon>
    </button>
  </div>

  <div>
    <form [formGroup]="dateFromForm"
          (ngSubmit)="onSubmit()">
      <div layout="column" layout-wrap>
        <mat-form-field>
          <input
            matInput
            formControlName="dateFrom"
            [matDatepicker]="datePickerFrom"
            (click)="datePickerFrom.open()"
            [max]="currentDate"
            readonly
            placeholder="{{ 'global.from' | translate }}"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="datePickerFrom"
          ></mat-datepicker-toggle>
          <mat-datepicker #datePickerFrom [touchUi]="false"></mat-datepicker>
        </mat-form-field>
        <div layout="row"
             class="margin-top-24px"
             layout-wrap layout-align="end center">
          <button type="submit"
                  mat-raised-button>
            {{ 'global.download' | translate }}
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
