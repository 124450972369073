import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { CardInterface } from './card.interface';

@Component({
  selector: 'itfg-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
})
export class CardComponent implements OnInit {
  @Input() cardData: CardInterface;
  @Input() addButtonDisabled: boolean;
  @Input() editButtonDisabled: boolean;
  @Output() viewHistoryFunction: EventEmitter<any> = new EventEmitter();
  @Output() deleteCardFunction: EventEmitter<any> = new EventEmitter();
  @Output() editCardFunction: EventEmitter<any> = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  viewHistoryClicked(): void {
    this.viewHistoryFunction.emit(this.cardData);
  }

  deleteCardClicked(): void {
    this.deleteCardFunction.emit(this.cardData);
  }

  editCardClicked(): void {
    this.editCardFunction.emit(this.cardData);
  }
}
