import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ProductService } from '../../core/services/product.service';
import { Product } from '../../core/types/product';
import { FilterValue, FilterValueListService } from '../filter/types';
import { Observable } from 'rxjs';

@Injectable()
export class ProductValueListService implements FilterValueListService {
  constructor(private products: ProductService) {}

  getFilterValues$(): Observable<FilterValue[]> {
    return this.products.getProductList().pipe(
      map((productList): FilterValue[] => {
        return productList.content.map(
          (product: Product): FilterValue => {
            return {
              value: product.id,
              displayText: product.name,
            };
          }
        );
      })
    );
  }
}
