import { CallDirection, CallStatus, CallsCategoriesType } from "@app/core/types/call";
import { CallCategoryTypes } from "@app/main/main-layout/call-hub/types";

export interface StyleConfigMap {
  [key: string]: {
    backgroundColor?: string;
    class?: string;
    color?: string;
    icon?: string;
    fill?: string;
    name?: string;
  };
}

export const CALL_STATUS_STYLE_MAP: StyleConfigMap = {
  [CallStatus.ALL_BUSY_CALLBACK_REQUESTED]: {
    color: 'black',
    icon: 'phone_callback',
  },
  [CallStatus.HANGUP_AT_WELCOME]: {
    color: 'red',
    icon: 'call_end',
  },
  [CallStatus.AGENT_FREE_CALLBACK_REQUESTED]: {
    color: 'darkred',
    icon: 'phone_callback',
  },
  [CallStatus.ANSWER]: {
    color: 'green',
    icon: 'call',
  },
  [CallStatus.NO_ANSWER]: {
    color: 'darkgray',
    icon: 'call_missed_outgoing',
  },
  [CallStatus.ONGOING]: {
    color: 'blue',
    icon: 'phone_in_talk',
  },
  [CallStatus.CONNECTION_FAILED]: {
    color: 'gray',
    icon: 'signal_disconnected',
  },
  [CallStatus.BUSY]: {
    color: 'darkred',
    icon: 'phone_disabled',
  },
  [CallStatus.FAILED]: {
    color: 'darkred',
    icon: 'wifi_calling',
  },
  [CallStatus.AGENT_CONNECTED]: {
    color: 'darkgreen',
    icon: 'call',
  },
  [CallStatus.AGENT_FREE_NO_CALLBACK_REQUESTED]: {
    color: 'red',
    icon: 'call_missed',
  },
  [CallStatus.ALL_BUSY_NO_CALLBACK_REQUESTED]: {
    color: 'darkred',
    icon: 'call_missed',
  },
  [CallStatus.NON_WORKING_HOURS_CALLBACK_REQUESTED]: {
    color: '#d352d3',
    icon: 'timer_off',
  },
  [CallStatus.NON_WORKING_HOURS_NO_CALLBACK_REQUESTED]: {
    color: 'mediumpurple',
    icon: 'timer_off',
  },
  [CallStatus.INITIATED]: {
    color: '#01AAF1',
    icon: 'phone_in_talk',
    class: 'ringing-icon',
  },
}

export const CALL_STATUS_SVG_STYLE_MAP: StyleConfigMap = {
  [CallStatus.ALL_BUSY_CALLBACK_REQUESTED]: {
    icon: '<svg width="24" height="24" viewBox="0 0 24 24"><path d="M21,6V11H19.5V7.5H13.87L16.3,9.93L15.24,11L11,6.75L15.24,2.5L16.3,3.57L13.87,6H21M8.82,8.58C9.08,8.32 9.17,7.93 9.06,7.58C8.69,6.42 8.5,5.22 8.5,4A1,1 0 0,0 7.5,3H4A1,1 0 0,0 3,4A17,17 0 0,0 20,21A1,1 0 0,0 21,20V16.5A1,1 0 0,0 20,15.5C18.79,15.5 17.58,15.31 16.43,14.93C16.08,14.82 15.69,14.91 15.43,15.17L13.23,17.37C10.39,15.92 8.09,13.62 6.64,10.78L8.82,8.58Z" fill="var(--all-busy-call-base)" /></svg>',
  },
  [CallStatus.HANGUP_AT_WELCOME]: {
    icon: '<svg width="24" height="24" viewBox="0 0 24 24">  <path d="M12,9C10.4,9 8.85,9.25 7.4,9.72V12.82C7.4,13.22 7.17,13.56 6.84,13.72C5.86,14.21 4.97,14.84 4.17,15.57C4,15.75 3.75,15.86 3.5,15.86C3.2,15.86 2.95,15.74 2.77,15.56L0.29,13.08C0.11,12.9 0,12.65 0,12.38C0,12.1 0.11,11.85 0.29,11.67C3.34,8.77 7.46,7 12,7C16.54,7 20.66,8.77 23.71,11.67C23.89,11.85 24,12.1 24,12.38C24,12.65 23.89,12.9 23.71,13.08L21.23,15.56C21.05,15.74 20.8,15.86 20.5,15.86C20.25,15.86 20,15.75 19.82,15.57C19.03,14.84 18.14,14.21 17.16,13.72C16.83,13.56 16.6,13.22 16.6,12.82V9.72C15.15,9.25 13.6,9 12,9Z" fill="var(--hangup-at-welcome-call-base)" /></svg>',
  },
  [CallStatus.AGENT_FREE_CALLBACK_REQUESTED]: {
    icon: '<svg width="24" height="24" viewBox="0 0 24 24"><path d="M21,6V11H19.5V7.5H13.87L16.3,9.93L15.24,11L11,6.75L15.24,2.5L16.3,3.57L13.87,6H21M8.82,8.58C9.08,8.32 9.17,7.93 9.06,7.58C8.69,6.42 8.5,5.22 8.5,4A1,1 0 0,0 7.5,3H4A1,1 0 0,0 3,4A17,17 0 0,0 20,21A1,1 0 0,0 21,20V16.5A1,1 0 0,0 20,15.5C18.79,15.5 17.58,15.31 16.43,14.93C16.08,14.82 15.69,14.91 15.43,15.17L13.23,17.37C10.39,15.92 8.09,13.62 6.64,10.78L8.82,8.58Z" fill="var(--agent-free-call-base)" /></svg>',
  },
  [CallStatus.ANSWER]: {
    icon: '<svg width="24" height="24" viewBox="0 0 24 24"><path d="M6.62 10.79A15.1 15.1 0 0 0 13.21 17.38L15.41 15.18A1 1 0 0 1 16.41 14.93A11.36 11.36 0 0 0 20 15.5A1 1 0 0 1 21 16.5V20A1 1 0 0 1 20 21A17 17 0 0 1 3 4A1 1 0 0 1 4 3H7.5A1 1 0 0 1 8.5 4A11.36 11.36 0 0 0 9.07 7.57A1 1 0 0 1 8.82 8.57M21.71 4.71L20.29 3.29L15 8.59L12.71 6.29L11.29 7.71L15 11.41Z" fill="var(--successful-call-dark)" /></svg>',
  },
  [CallStatus.NO_ANSWER]: {
    icon: '<svg width="24" height="24" viewBox="0 0 24 24">  <path d="M6.62 10.79C8.06 13.62 10.38 15.94 13.21 17.38L15.41 15.18C15.69 14.9 16.08 14.82 16.43 14.93C17.55 15.3 18.75 15.5 20 15.5C20.55 15.5 21 15.95 21 16.5V20C21 20.55 20.55 21 20 21C10.61 21 3 13.39 3 4C3 3.45 3.45 3 4 3H7.5C8.05 3 8.5 3.45 8.5 4C8.5 5.25 8.7 6.45 9.07 7.57C9.18 7.92 9.1 8.31 8.82 8.59L6.62 10.79M16.5 2C14 2 12 4 12 6.5S14 11 16.5 11 21 9 21 6.5 19 2 16.5 2M16.5 9.5C14.84 9.5 13.5 8.16 13.5 6.5C13.5 5.94 13.65 5.42 13.92 5L18 9.08C17.58 9.35 17.06 9.5 16.5 9.5M19.08 8L15 3.92C15.42 3.65 15.94 3.5 16.5 3.5C18.16 3.5 19.5 4.84 19.5 6.5C19.5 7.06 19.35 7.58 19.08 8Z" fill="var(--no-answer-call-dark)" /></svg>',
  },
  [CallStatus.ONGOING]: {
    icon: '<svg width="24" height="24" viewBox="0 0 24 24"><path d="M15,12H17A5,5 0 0,0 12,7V9A3,3 0 0,1 15,12M19,12H21C21,7 16.97,3 12,3V5C15.86,5 19,8.13 19,12M20,15.5C18.75,15.5 17.55,15.3 16.43,14.93C16.08,14.82 15.69,14.9 15.41,15.18L13.21,17.38C10.38,15.94 8.06,13.62 6.62,10.79L8.82,8.59C9.1,8.31 9.18,7.92 9.07,7.57C8.7,6.45 8.5,5.25 8.5,4A1,1 0 0,0 7.5,3H4A1,1 0 0,0 3,4A17,17 0 0,0 20,21A1,1 0 0,0 21,20V16.5A1,1 0 0,0 20,15.5Z" fill="var(---ongoing-call-base)" /></svg>',
  },
  [CallStatus.CONNECTION_FAILED]: {
    icon: '<svg width="24" height="24" viewBox="0 0 24 24">  <path d="M17.6 14.2C17.9 13.5 18 12.8 18 12C18 8.7 15.3 6 12 6C11.2 6 10.4 6.2 9.8 6.4L11.4 8H12C14.2 8 16 9.8 16 12C16 12.2 16 12.4 15.9 12.6L17.6 14.2M12 4C16.4 4 20 7.6 20 12C20 13.4 19.6 14.6 19 15.7L20.5 17.2C21.4 15.7 22 13.9 22 12C22 6.5 17.5 2 12 2C10.1 2 8.3 2.5 6.8 3.5L8.3 5C9.4 4.3 10.6 4 12 4M3.3 2.5L2 3.8L4.1 5.9C2.8 7.6 2 9.7 2 12C2 15.7 4 18.9 7 20.6L8 18.9C5.6 17.5 4 14.9 4 12C4 10.2 4.6 8.6 5.5 7.3L7 8.8C6.4 9.7 6 10.8 6 12C6 14.2 7.2 16.1 9 17.2L10 15.5C8.8 14.8 8 13.5 8 12.1C8 11.5 8.2 10.9 8.4 10.3L10 11.9V12.1C10 13.2 10.9 14.1 12 14.1H12.2L19.7 21.6L21 20.3L4.3 3.5L3.3 2.5Z" fill="var(--connection-failed-call-base)" /></svg>',
  },
  [CallStatus.BUSY]: {
    icon: '<svg width="24" height="24" viewBox="0 0 24 24">  <path d="M12,9C10.4,9 8.85,9.25 7.4,9.72V12.82C7.4,13.22 7.17,13.56 6.84,13.72C5.86,14.21 4.97,14.84 4.17,15.57C4,15.75 3.75,15.86 3.5,15.86C3.2,15.86 2.95,15.74 2.77,15.56L0.29,13.08C0.11,12.9 0,12.65 0,12.38C0,12.1 0.11,11.85 0.29,11.67C3.34,8.77 7.46,7 12,7C16.54,7 20.66,8.77 23.71,11.67C23.89,11.85 24,12.1 24,12.38C24,12.65 23.89,12.9 23.71,13.08L21.23,15.56C21.05,15.74 20.8,15.86 20.5,15.86C20.25,15.86 20,15.75 19.82,15.57C19.03,14.84 18.14,14.21 17.16,13.72C16.83,13.56 16.6,13.22 16.6,12.82V9.72C15.15,9.25 13.6,9 12,9Z" fill="var(--busy-call-base)" /></svg>',  
  },
  [CallStatus.FAILED]: {
    icon: '<svg width="24" height="24" viewBox="0 0 24 24"><path d="M6.62,10.79C8.06,13.62 10.38,15.94 13.21,17.38L15.41,15.18C15.69,14.9 16.08,14.82 16.43,14.93C17.55,15.3 18.75,15.5 20,15.5A1,1 0 0,1 21,16.5V20A1,1 0 0,1 20,21A17,17 0 0,1 3,4C3,3.44 3.45,3 4,3H7.5A1,1 0 0,1 8.5,4C8.5,5.24 8.7,6.45 9.07,7.57C9.18,7.92 9.1,8.31 8.82,8.58L6.62,10.79M17,12V10H19V12H17M17,8V2H19V8H17Z" fill="var(--failed-call-dark)" /></svg>',
  },
  [CallStatus.AGENT_CONNECTED]: {
    icon: '<svg width="24" height="24" viewBox="0 0 24 24"><path d="M6.62 10.79A15.1 15.1 0 0 0 13.21 17.38L15.41 15.18A1 1 0 0 1 16.41 14.93A11.36 11.36 0 0 0 20 15.5A1 1 0 0 1 21 16.5V20A1 1 0 0 1 20 21A17 17 0 0 1 3 4A1 1 0 0 1 4 3H7.5A1 1 0 0 1 8.5 4A11.36 11.36 0 0 0 9.07 7.57A1 1 0 0 1 8.82 8.57M21.71 4.71L20.29 3.29L15 8.59L12.71 6.29L11.29 7.71L15 11.41Z" fill="var(--successful-call-dark)" /></svg>',
  },
  [CallStatus.AGENT_FREE_NO_CALLBACK_REQUESTED]: {
    icon: '<svg width="24" height="24" viewBox="0 0 24 24"><path d="M23.71,16.67C20.66,13.77 16.54,12 12,12C7.46,12 3.34,13.77 0.29,16.67C0.11,16.85 0,17.1 0,17.38C0,17.65 0.11,17.9 0.29,18.08L2.77,20.56C2.95,20.74 3.2,20.86 3.5,20.86C3.75,20.86 4,20.75 4.18,20.57C4.97,19.83 5.86,19.21 6.84,18.72C7.17,18.56 7.4,18.22 7.4,17.82V14.72C8.85,14.25 10.39,14 12,14C13.6,14 15.15,14.25 16.6,14.72V17.82C16.6,18.22 16.83,18.56 17.16,18.72C18.14,19.21 19.03,19.83 19.82,20.57C20,20.75 20.25,20.86 20.5,20.86C20.8,20.86 21.05,20.74 21.23,20.56L23.71,18.08C23.89,17.9 24,17.65 24,17.38C24,17.1 23.89,16.85 23.71,16.67M6.5,5.5L12,11L19,4L18,3L12,9L7.5,4.5H11V3H5V9H6.5V5.5Z" fill="var(--agent-free-call-base)" /></svg>', 
  },
  [CallStatus.ALL_BUSY_NO_CALLBACK_REQUESTED]: {
    icon: '<svg width="24" height="24" viewBox="0 0 24 24"><path d="M23.71,16.67C20.66,13.77 16.54,12 12,12C7.46,12 3.34,13.77 0.29,16.67C0.11,16.85 0,17.1 0,17.38C0,17.65 0.11,17.9 0.29,18.08L2.77,20.56C2.95,20.74 3.2,20.86 3.5,20.86C3.75,20.86 4,20.75 4.18,20.57C4.97,19.83 5.86,19.21 6.84,18.72C7.17,18.56 7.4,18.22 7.4,17.82V14.72C8.85,14.25 10.39,14 12,14C13.6,14 15.15,14.25 16.6,14.72V17.82C16.6,18.22 16.83,18.56 17.16,18.72C18.14,19.21 19.03,19.83 19.82,20.57C20,20.75 20.25,20.86 20.5,20.86C20.8,20.86 21.05,20.74 21.23,20.56L23.71,18.08C23.89,17.9 24,17.65 24,17.38C24,17.1 23.89,16.85 23.71,16.67M6.5,5.5L12,11L19,4L18,3L12,9L7.5,4.5H11V3H5V9H6.5V5.5Z" fill="var(--all-busy-call-base)" /></svg>', 
  },
  [CallStatus.NON_WORKING_HOURS_CALLBACK_REQUESTED]: {
    icon: '<svg width="24" height="24" viewBox="0 0 24 24"><path d="M15.9 1C12.1 1.1 9 4.2 9 8C9 11.9 12.1 15 16 15S23 11.9 23 8 19.9 1 15.9 1C16 1 15.9 1 15.9 1M16 3C18.8 3 21 5.2 21 8S18.8 13 16 13 11 10.8 11 8 13.2 3 16 3M15 4V9L18.6 11.2L19.4 10L16.5 8.3V4H15M4.6 12.8C6 15.6 8.4 18 11.2 19.4L13.4 17.2C13.7 16.9 14.1 16.8 14.4 17C15.5 17.4 16.7 17.6 18 17.6C18.5 17.6 19 18.1 19 18.6V22C19 22.5 18.5 23 18 23C8.6 23 1 15.4 1 6C1 5.4 1.5 5 2 5H5.5C6.1 5 6.5 5.4 6.5 6C6.5 7.2 6.7 8.4 7.1 9.6C7.2 10 7.1 10.3 6.9 10.6L4.6 12.8" fill="var(--non-working-hours-call-base)" /></svg>',
  },
  [CallStatus.NON_WORKING_HOURS_NO_CALLBACK_REQUESTED]: {
    icon: '<svg width="24" height="24" viewBox="0 0 24 24"><path d="M15.9 1C12.1 1.1 9 4.2 9 8C9 11.9 12.1 15 16 15S23 11.9 23 8 19.9 1 15.9 1C16 1 15.9 1 15.9 1M16 3C18.8 3 21 5.2 21 8S18.8 13 16 13 11 10.8 11 8 13.2 3 16 3M15 4V9L18.6 11.2L19.4 10L16.5 8.3V4H15M4.6 12.8C6 15.6 8.4 18 11.2 19.4L13.4 17.2C13.7 16.9 14.1 16.8 14.4 17C15.5 17.4 16.7 17.6 18 17.6C18.5 17.6 19 18.1 19 18.6V22C19 22.5 18.5 23 18 23C8.6 23 1 15.4 1 6C1 5.4 1.5 5 2 5H5.5C6.1 5 6.5 5.4 6.5 6C6.5 7.2 6.7 8.4 7.1 9.6C7.2 10 7.1 10.3 6.9 10.6L4.6 12.8" fill="var(--non-working-hours-call-base)" /></svg>',
  },
  [CallStatus.INITIATED]: {
    icon: '<svg width="24" height="24" viewBox="0 0 24 24"><path d="M15,12H17A5,5 0 0,0 12,7V9A3,3 0 0,1 15,12M19,12H21C21,7 16.97,3 12,3V5C15.86,5 19,8.13 19,12M20,15.5C18.75,15.5 17.55,15.3 16.43,14.93C16.08,14.82 15.69,14.9 15.41,15.18L13.21,17.38C10.38,15.94 8.06,13.62 6.62,10.79L8.82,8.59C9.1,8.31 9.18,7.92 9.07,7.57C8.7,6.45 8.5,5.25 8.5,4A1,1 0 0,0 7.5,3H4A1,1 0 0,0 3,4A17,17 0 0,0 20,21A1,1 0 0,0 21,20V16.5A1,1 0 0,0 20,15.5Z" fill="var(--initiated-call-base)" /></svg>',
    class: 'ringing-icon',
  },
}

export const CALL_CATEGORY_STYLE_MAP: StyleConfigMap = {
  [CallsCategoriesType.MISSED_AGENT]: {
    icon: '<svg width="24" height="24" viewBox="0 0 24 24"><path d="M23.71,16.67C20.66,13.77 16.54,12 12,12C7.46,12 3.34,13.77 0.29,16.67C0.11,16.85 0,17.1 0,17.38C0,17.65 0.11,17.9 0.29,18.08L2.77,20.56C2.95,20.74 3.2,20.86 3.5,20.86C3.75,20.86 4,20.75 4.18,20.57C4.97,19.83 5.86,19.21 6.84,18.72C7.17,18.56 7.4,18.22 7.4,17.82V14.72C8.85,14.25 10.39,14 12,14C13.6,14 15.15,14.25 16.6,14.72V17.82C16.6,18.22 16.83,18.56 17.16,18.72C18.14,19.21 19.03,19.83 19.82,20.57C20,20.75 20.25,20.86 20.5,20.86C20.8,20.86 21.05,20.74 21.23,20.56L23.71,18.08C23.89,17.9 24,17.65 24,17.38C24,17.1 23.89,16.85 23.71,16.67M6.5,5.5L12,11L19,4L18,3L12,9L7.5,4.5H11V3H5V9H6.5V5.5Z" fill="grey" /></svg>', 
  },
  [CallsCategoriesType.MISSED_NO_AGENT]: {
    icon: '<svg width="24" height="24" viewBox="0 0 24 24"><path d="M12,4A4,4 0 0,1 16,8C16,9.95 14.6,11.58 12.75,11.93L8.07,7.25C8.42,5.4 10.05,4 12,4M12.28,14L18.28,20L20,21.72L18.73,23L15.73,20H4V18C4,16.16 6.5,14.61 9.87,14.14L2.78,7.05L4.05,5.78L12.28,14M20,18V19.18L15.14,14.32C18,14.93 20,16.35 20,18Z" fill="grey" /></svg>', 
  },
  [CallsCategoriesType.WAITING_FOR_PROCESSING]: {
    icon: '<svg width="24" height="24" viewBox="0 0 24 24"><path d="M14,10H19.5L14,4.5V10M5,3H15L21,9V19A2,2 0 0,1 19,21H5C3.89,21 3,20.1 3,19V5C3,3.89 3.89,3 5,3M5,12V14H19V12H5M5,16V18H14V16H5Z" fill="grey" /></svg>',
  },
  [CallsCategoriesType.WAITING_FOR_CALLBACK]: {
    icon: '<svg width="24" height="24" viewBox="0 0 24 24"><path d="M21,6V11H19.5V7.5H13.87L16.3,9.93L15.24,11L11,6.75L15.24,2.5L16.3,3.57L13.87,6H21M8.82,8.58C9.08,8.32 9.17,7.93 9.06,7.58C8.69,6.42 8.5,5.22 8.5,4A1,1 0 0,0 7.5,3H4A1,1 0 0,0 3,4A17,17 0 0,0 20,21A1,1 0 0,0 21,20V16.5A1,1 0 0,0 20,15.5C18.79,15.5 17.58,15.31 16.43,14.93C16.08,14.82 15.69,14.91 15.43,15.17L13.23,17.37C10.39,15.92 8.09,13.62 6.64,10.78L8.82,8.58Z" fill="grey" /></svg>',
  },
  [CallsCategoriesType.ALL]: {
    icon: '<svg width="24" height="24" viewBox="0 0 24 24"><path d="M20,15.5A1,1 0 0,1 21,16.5V20A1,1 0 0,1 20,21A17,17 0 0,1 3,4A1,1 0 0,1 4,3H7.5A1,1 0 0,1 8.5,4C8.5,5.24 8.7,6.45 9.07,7.57C9.18,7.92 9.1,8.31 8.82,8.58L6.62,10.79C8.06,13.62 10.38,15.94 13.21,17.38L15.41,15.18C15.69,14.9 16.08,14.82 16.43,14.93C17.55,15.3 18.75,15.5 20,15.5M12,3H14V5H12M15,3H21V5H15M12,6H14V8H12M15,6H21V8H15M12,9H14V11H12M15,9H21V11H15" fill="grey" /></svg>',
  },
}

export const CallCategories = [
  { id: CallCategoryTypes.ALL, label: 'Всички', icon: '<svg width="24" height="24" viewBox="0 0 24 24" style="vertical-align: middle;"><path d="M20,15.5A1,1 0 0,1 21,16.5V20A1,1 0 0,1 20,21A17,17 0 0,1 3,4A1,1 0 0,1 4,3H7.5A1,1 0 0,1 8.5,4C8.5,5.24 8.7,6.45 9.07,7.57C9.18,7.92 9.1,8.31 8.82,8.58L6.62,10.79C8.06,13.62 10.38,15.94 13.21,17.38L15.41,15.18C15.69,14.9 16.08,14.82 16.43,14.93C17.55,15.3 18.75,15.5 20,15.5M12,3H14V5H12M15,3H21V5H15M12,6H14V8H12M15,6H21V8H15M12,9H14V11H12M15,9H21V11H15" fill="grey" /></svg>' },
  { id: CallCategoryTypes.MISSED, label: 'Пропуснати', icon: '<svg width="24" height="24" viewBox="0 0 24 24" style="vertical-align: middle;"><path d="M23.71,16.67C20.66,13.77 16.54,12 12,12C7.46,12 3.34,13.77 0.29,16.67C0.11,16.85 0,17.1 0,17.38C0,17.65 0.11,17.9 0.29,18.08L2.77,20.56C2.95,20.74 3.2,20.86 3.5,20.86C3.75,20.86 4,20.75 4.18,20.57C4.97,19.83 5.86,19.21 6.84,18.72C7.17,18.56 7.4,18.22 7.4,17.82V14.72C8.85,14.25 10.39,14 12,14C13.6,14 15.15,14.25 16.6,14.72V17.82C16.6,18.22 16.83,18.56 17.16,18.72C18.14,19.21 19.03,19.83 19.82,20.57C20,20.75 20.25,20.86 20.5,20.86C20.8,20.86 21.05,20.74 21.23,20.56L23.71,18.08C23.89,17.9 24,17.65 24,17.38C24,17.1 23.89,16.85 23.71,16.67M6.5,5.5L12,11L19,4L18,3L12,9L7.5,4.5H11V3H5V9H6.5V5.5Z" fill="grey" /></svg>' },
  { id: CallCategoryTypes.FOR_NOTE, label: 'За бележка', icon: '<svg width="24" height="24" viewBox="0 0 24 24" style="vertical-align: middle;"><path d="M14,10H19.5L14,4.5V10M5,3H15L21,9V19A2,2 0 0,1 19,21H5C3.89,21 3,20.1 3,19V5C3,3.89 3.89,3 5,3M5,12V14H19V12H5M5,16V18H14V16H5Z" fill="grey" /></svg>' },
  { id: CallCategoryTypes.FOR_CALLBACK, label: 'За обратно обаждане', icon: '<svg width="24" height="24" viewBox="0 0 24 24" style="vertical-align: middle;"><path d="M21,6V11H19.5V7.5H13.87L16.3,9.93L15.24,11L11,6.75L15.24,2.5L16.3,3.57L13.87,6H21M8.82,8.58C9.08,8.32 9.17,7.93 9.06,7.58C8.69,6.42 8.5,5.22 8.5,4A1,1 0 0,0 7.5,3H4A1,1 0 0,0 3,4A17,17 0 0,0 20,21A1,1 0 0,0 21,20V16.5A1,1 0 0,0 20,15.5C18.79,15.5 17.58,15.31 16.43,14.93C16.08,14.82 15.69,14.91 15.43,15.17L13.23,17.37C10.39,15.92 8.09,13.62 6.64,10.78L8.82,8.58Z" fill="grey" /></svg>' },
];

export const CALL_DIRECTION_STYLE_MAP: StyleConfigMap = {
  [CallDirection.IN]: {
    icon: 'call_received',
  },
  [CallDirection.OUT]: {
    icon: 'call_made',
  },
}

export const CALL_DIRECTION_SVG_STYLE_MAP: StyleConfigMap = {
  [CallDirection.IN]: {
    icon: `<svg width="24" height="24" viewBox="0 0 24 24">
             <path d="M4,3A1,1 0 0,0 3,4A17,17 0 0,0 20,21A1,1 0 0,0 21,20V16.5A1,1 0 0,0 20,15.5C18.75,15.5 17.55,15.3 16.43,14.93C16.08,14.82 15.69,14.9 15.41,15.17L13.21,17.37C10.38,15.93 8.06,13.62 6.62,10.78L8.82,8.57C9.1,8.31 9.18,7.92 9.07,7.57C8.7,6.45 8.5,5.25 8.5,4A1,1 0 0,0 7.5,3H4M19,11V9.5H15.5L21,4L20,3L14.5,8.5V5H13V11H19Z" fill="var(--incoming-call-light)" />
           </svg>`
  },
  [CallDirection.OUT]: {
    icon: `<svg width="24" height="24" viewBox="0 0 24 24">
             <path d="M4,3A1,1 0 0,0 3,4A17,17 0 0,0 20,21A1,1 0 0,0 21,20V16.5A1,1 0 0,0 20,15.5C18.75,15.5 17.55,15.3 16.43,14.93C16.08,14.82 15.69,14.9 15.41,15.17L13.21,17.37C10.38,15.93 8.06,13.62 6.62,10.78L8.82,8.57C9.1,8.31 9.18,7.92 9.07,7.57C8.7,6.45 8.5,5.25 8.5,4A1,1 0 0,0 7.5,3H4M15,3V4.5H18.5L13,10L14,11L19.5,5.5V9H21V3H15Z" fill="var(--outgoing-call-light)" />
           </svg>`
  },
};
