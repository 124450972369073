import {Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import { ItfgDataTableColumn } from '@app/core/components/data-table/types/data-table.column';
import {TranslateService} from '@ngx-translate/core';
import {Credit} from '../../../../core/types/credit';
import {ToCustomItfgCurrencyPipe} from '../../../credit/pipes/to-custom-itfg-currency.pipe';
import {VerificationService} from '../../../../core/services/verification.service';
import {NotificationService} from '../../../../core/services/notification.service';
import {Subject} from 'rxjs';
import {MatSnackBar, MatSnackBarConfig, MatSnackBarRef} from '@angular/material/snack-bar';
import {DataUpdatePromptComponent} from '../../../../core/components/data-update-prompt/data-update-prompt.component';
import {ObjectDiffPipe} from '../../../../shared/pipes/object-diff.pipe';
import {Favicons} from '../../../../favicon/favicons';

@Component({
  selector: 'itfg-verification-list',
  templateUrl: './verification-list.component.html',
  styleUrls: ['./verification-list.component.scss']
})
export class VerificationListComponent implements OnInit, OnDestroy {
  public columns: ItfgDataTableColumn[];
  public idVerificationData: Credit[];
  public updatedIdVerificationData: Credit[];
  public bankAccountsVerificationData: Credit[];
  public updatedBankAccountsVerificationData: Credit[];
  public idDataNewIds: number[];
  public idDataMissingIds: number[];
  public bankAccountsDataNewIds: number[];
  public bankAccountsDataMissingIds: number[];
  public isIdDataUpdated: boolean;
  public isBankAccountDataUpdated: boolean;
  public DATA_SIZE_LIMIT: number;
  public ID_INTERVAL: number;
  public BANK_ACCOUNT_INTERVAL: number;
  public idVerificationDataInterval: any;
  public bankAccountsVerificationDataInterval: any;
  _unsubscribe: Subject<void> = new Subject<void>();
  public snackbarRef: MatSnackBarRef<DataUpdatePromptComponent>;
  private objectDiffPipe: ObjectDiffPipe;
  public isOnFocus: boolean;

  constructor(private translateService: TranslateService,
              private toCustomItfgCurrency: ToCustomItfgCurrencyPipe,
              private notificationService: NotificationService,
              private verificationService: VerificationService,
              private snackBar: MatSnackBar,
              private favicons: Favicons) {
    this.DATA_SIZE_LIMIT = 25;
    this.ID_INTERVAL = 5 * 60 * 1000; // 5 minutes
    this.BANK_ACCOUNT_INTERVAL = 10 * 60 * 1000; // 10 minutes
    this.objectDiffPipe = new ObjectDiffPipe();
    this.idDataNewIds = [];
    this.idDataMissingIds = [];
    this.bankAccountsDataNewIds = [];
    this.bankAccountsDataMissingIds = [];
    this.isIdDataUpdated = false;
    this.isBankAccountDataUpdated = false;
    this.isOnFocus = true;
  }

  ngOnInit(): void {
    this.initializeColumns();
    this.getIdVerificationData();
    this.setIdVerificationUpdate();
    this.getBankAccountsVerificationData();
    this.setBankAccountsVerificationUpdate();
  }

  @HostListener('window:focus', ['$event'])
  onFocus(event: any): void {
    this.isOnFocus = true;
    this.favicons.reset();
  }

  @HostListener('window:blur', ['$event'])
  onBlur(event: any): void {
    this.isOnFocus = false;
  }

  initializeColumns() {
    this.translateService
      .get([
        'global.numberSign',
        'global.status',
        'global.tags',
        'credits.productName',
        'global.principal',
        'credits.createTime',
        'global.client',
        'credits.civilId',
        'global.operator',
        'global.actions'
      ])
      .subscribe((translations: string[]) => {
        this.columns = [
          {
            name: 'id',
            label: translations['global.numberSign'],
            width: 70,
          },
          {
            name: 'user',
            label: translations['global.client'],
            width: 300,
            isSortable: false,
          },
          {
            name: 'user.civilId',
            label: translations['credits.civilId'],
          },
          {
            name: 'creditStatus',
            label: translations['global.status'],
            width: 150,
          },
          {
            name: 'tags',
            label: translations['global.tags'],
            width: {min: 200},
            isSortable: false,
          },
          {
            name: 'product.name',
            label: translations['credits.productName'],
            width: {min: 200},
            isSortable: false,
          },
          {
            name: 'principal',
            label: translations['global.principal'],
            format: (principal: number) =>
              this.toCustomItfgCurrency.transform(principal),
            width: {
              min: 150,
            },
            numeric: true,
          },
          {
            name: 'createTime',
            label: translations['credits.createTime'],
          },
          {
            name: 'operator',
            label: translations['global.operator'],
          },
        ];
      });
  }

  getIdVerificationData() {
    this.verificationService.getIdVerification$(this.DATA_SIZE_LIMIT).subscribe(creditList => {
        this.idVerificationData = creditList;
        this.updatedIdVerificationData = creditList;
      },
      error => {
        this.notificationService.showLocalizedErrorMessage({
          notificationText: error.message
        });
      });

  }

  setIdVerificationUpdate() {
    this.idVerificationDataInterval = setInterval(() => {
      this.verificationService.getIdVerification$(this.DATA_SIZE_LIMIT)
        .subscribe(creditList => {

            this.idVerificationData = this.updatedIdVerificationData;
            const updatedIdVerificationData = [];
            this.isIdDataUpdated = false;

            for (const credit of creditList) {

              const isNew = this.idVerificationData.filter(item => item.id === credit.id).length === 0;
              if (isNew) {
                updatedIdVerificationData.push(credit);
                if (!this.idDataNewIds.includes(credit.id)) {
                  this.isIdDataUpdated = true;
                  this.idDataNewIds.push(credit.id);
                }
              }
            }

            for (const credit of this.idVerificationData) {
              const isMissing = creditList.filter(item => item.id === credit.id).length === 0;
              if (isMissing && !this.idDataMissingIds.includes(credit.id)) {
                this.idDataMissingIds.push(credit.id);
                if (this.idDataNewIds.includes(credit.id)) {
                  const index = this.idDataNewIds.indexOf(credit.id);
                  this.idDataNewIds.splice(index, 1);
                }
              } else if (!isMissing && this.idDataMissingIds.includes(credit.id)) {
                const indexOfMissing = this.idDataMissingIds.indexOf(credit.id);
                this.idDataMissingIds.splice(indexOfMissing, 1);
                this.idDataNewIds.push(credit.id);
                this.isIdDataUpdated = true;
              }
              updatedIdVerificationData.push(credit);
            }
            this.updatedIdVerificationData = updatedIdVerificationData;
            if (this.isIdDataUpdated) {
              this.notifyOperator();
            } else {
              this.resetFavicon();
            }
          },
          error => {
            this.notificationService.showLocalizedErrorMessage({
              notificationText: error.message
            });
          });
    }, this.ID_INTERVAL);

  }

  getBankAccountsVerificationData() {
    this.verificationService.getBankAccountVerification$(this.DATA_SIZE_LIMIT).subscribe(creditList => {
        this.bankAccountsVerificationData = creditList;
        this.updatedBankAccountsVerificationData = creditList;
      },
      error => {
        this.notificationService.showLocalizedErrorMessage({
          notificationText: error.message
        });
      });
  }

  setBankAccountsVerificationUpdate() {
    this.bankAccountsVerificationDataInterval = setInterval(() => {
      this.verificationService.getBankAccountVerification$(this.DATA_SIZE_LIMIT)
        .subscribe(creditList => {

            this.bankAccountsVerificationData = this.updatedBankAccountsVerificationData;
            const updatedBankAccountsVerificationData = [];
            this.isBankAccountDataUpdated = false;

            for (const credit of creditList) {

              const isNew = this.bankAccountsVerificationData.filter(item => item.id === credit.id).length === 0;
              if (isNew) {
                updatedBankAccountsVerificationData.push(credit);
                if (!this.bankAccountsDataNewIds.includes(credit.id)) {
                  this.isBankAccountDataUpdated = true;
                  this.bankAccountsDataNewIds.push(credit.id);
                }
              }
            }

            for (const credit of this.bankAccountsVerificationData) {

              const isMissing = creditList.filter(item => item.id === credit.id).length === 0;
              if (isMissing && !this.bankAccountsDataMissingIds.includes(credit.id)) {
                this.bankAccountsDataMissingIds.push(credit.id);
                if (this.bankAccountsDataNewIds.includes(credit.id)) {
                  const indexOfNew = this.bankAccountsDataNewIds.indexOf(credit.id);
                  this.bankAccountsDataNewIds.splice(indexOfNew, 1);
                }
              } else if (!isMissing && this.bankAccountsDataMissingIds.includes(credit.id)) {
                const indexOfMissing = this.bankAccountsDataMissingIds.indexOf(credit.id);
                this.bankAccountsDataMissingIds.splice(indexOfMissing, 1);
                this.bankAccountsDataNewIds.push(credit.id);
                this.isBankAccountDataUpdated = true;
              }
              updatedBankAccountsVerificationData.push(credit);
            }
            this.updatedBankAccountsVerificationData = updatedBankAccountsVerificationData;
            if (this.isBankAccountDataUpdated) {
              this.notifyOperator();
            } else {
              this.resetFavicon();
            }
          },
          error => {
            this.notificationService.showLocalizedErrorMessage({
              notificationText: error.message
            });
          });
    }, this.BANK_ACCOUNT_INTERVAL);
  }

  notifyOperator() {
    this.favicons.activate('notification');
    this.playAudio();
  }

  resetFavicon() {
    if (this.idDataNewIds.length === 0 && this.bankAccountsDataNewIds.length === 0) {
      this.favicons.reset();
    }
  }


  playAudio() {
    const audio = new Audio();
    audio.src = '../../../assets/sounds/pristine-609.ogg';
    audio.load();
    audio.play();
  }


  ngOnDestroy() {
    clearInterval(this.idVerificationDataInterval);
    clearInterval(this.bankAccountsVerificationDataInterval);
    this._unsubscribe.next();
    this._unsubscribe.complete();
  }

}
