import { Pipe, PipeTransform } from '@angular/core';
import { Utils } from '../../../core/utils/utils';

@Pipe({
  name: 'clientRelationTypeToTranslationKey',
})
export class ClientRelationTypeToTranslationKeyPipe implements PipeTransform {
  transform(clientRelationDescription: any, args?: any): any {
    return (
      'clientRelations.' +
      Utils.dotNotationToCamelCase(clientRelationDescription)
    );
  }
}
