import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
// import { MatDialogRef } from "@angular/material/legacy-dialog";
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IdentityReportsService } from "../../../../../core/services/identity-reports.service";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { ContactService } from "../../../../../core/services/contacts.service";
import { NotificationService } from "../../../../../core/services/notification.service";
import { ContactPhone } from "@app/core/types/contact-phone";


@Component({
  selector: 'itfg-company-phones',
  templateUrl: './company-phones.component.html',
  styleUrls: ['./company-phones.component.scss'],
})
export class CompanyPhonesComponent implements OnInit, OnDestroy {
  companyPhones = [];
  disableSearch = false;
  _unsubscribe: Subject<void> = new Subject<void>();

  constructor(
    @Inject(MAT_DIALOG_DATA) 
    public data: { identifier: string, clientId: number , phones: [], addContactType, contactListPhones },
    public dialogRef: MatDialogRef<CompanyPhonesComponent>,
    private contactService: ContactService,
    private identityReportsService: IdentityReportsService,
    private notificationService: NotificationService,

  ) {}

  ngOnInit() {
    this.companyPhones = this.data.phones;
  }

  close(): void {
   this.dialogRef.close();
  }

  updatePhones() {
    this.disableSearch = true;
    this.identityReportsService.findCompanyPhones$(this.data.identifier)
    .pipe(takeUntil(this._unsubscribe))
    .subscribe(res => {
      this.companyPhones = res;
      this.disableSearch = false;
    });
  }

  mapPhone(phone) {
    const phoneOnlyNumbers = phone.replace(/\D/g,'');
    let mappedPhone = phoneOnlyNumbers;
    if(mappedPhone[0] === '0'){
      mappedPhone = phoneOnlyNumbers.replace('0', '+359');
    } else if(mappedPhone[0] !== '+359'){
      mappedPhone = '+359' + mappedPhone;
    }

    return mappedPhone;
  }

  async addToContacts(data){
    const mappedPhone = this.mapPhone(data.phone);

    this.contactService.createContact(this.data.clientId ,{
      active: true,
      description: `${data.name}, EIK: ${data.eik}`,
      id: null,
      phone: mappedPhone,
      type: this.data.addContactType
    } ).subscribe((contact: ContactPhone) => {
      this.notificationService.showLocalizedSuccessMessage({
        notificationText: 'communication.contactSuccessfullyAdded',
      });
      this.data.contactListPhones.push(mappedPhone);
      this.contactService.onContactAddedSubject.next(contact);
    })
  }

  isExisting(phoneNumber){
    const mappedPhone = this.mapPhone(phoneNumber);
    if(
      this.data.contactListPhones.includes(phoneNumber) ||
      this.data.contactListPhones.includes(mappedPhone)
      ){
        return true;
      }
      return false;
  }
  ngOnDestroy() {
    this._unsubscribe.next();
    this._unsubscribe.complete();
  }
  }
