import { map, mergeMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FilterValueListService, FilterValue } from '../filter/types';
import { Page } from '../../core/types/page';
import { CollectionService } from '../../../app/core/services/collection.service';
import { CollectorOperator } from '../../../app/core/types/collector';
import { SearchOptions } from '../../core/types/search-options';
import { SearchOperations } from '../../core/types/search-criterium';
import { AdministratorService } from '../../core/services/administrator.service';
import { Administrator } from '../../core/types/administrator';

@Injectable()
export class CollectorAdministratorValueListService implements FilterValueListService {
  public searchOptions: SearchOptions;
  constructor(
    private collection: CollectionService,
    private administratorService: AdministratorService) {
    this.searchOptions = new SearchOptions();
    this.searchOptions.pageSize = 100;
  }

  getFilterValues$(): Observable<any> {
    return this.collection.getCollectorOperators$(this.searchOptions.getDTO()).pipe(
      mergeMap((collectorOperatorPage: Page<CollectorOperator>) => {
        const searchOptions = new SearchOptions();
        searchOptions.pageSize = 1000;
        searchOptions.criteria = [
          {
            key: 'active',
            operation: SearchOperations.EQUALS,
            value: true,
          },
          {
            key: 'id',
            operation: SearchOperations.IN,
            value: collectorOperatorPage.content.map((collectorOperator) => collectorOperator.external_operator_id),
          }
        ];

        return this.administratorService.getAdministratorList(searchOptions.getDTO()).pipe(
          map((activeAdministratorsPage: Page<Administrator>) => {
            const activeOperators = activeAdministratorsPage.content.sort((a, b) =>
              a.firstName < b.firstName ? -1 : a.firstName > b.firstName ? 1 : 0
            );
            return activeOperators.map(
              (operator): FilterValue => {
                return {
                  value: operator.id,
                  displayText: `${operator.firstName} ${operator.lastName}`,
                };
              }
            );
          })
        );
      })
    );
  }
}
