import { Pipe, PipeTransform } from '@angular/core';
import {Credit} from '../../core/types/credit';
import * as moment from 'moment/moment';
import {TranslateService} from '@ngx-translate/core';

@Pipe({
  name: 'utilizationFeeName'
})
export class UtilizationFeeNamePipe implements PipeTransform {
  public utilizationFeeName: string;
  private UTILIZATION_FEE_DATE_CHANGED = new Date('2022-10-19');

  constructor(
    private translateService: TranslateService,
  ) {}

  transform(credit: Credit): string {
    if (credit.installmentsNumber !== 1 &&  moment(this.UTILIZATION_FEE_DATE_CHANGED).isBefore(credit.createTime)) {
      this.utilizationFeeName = this.translateService.instant('global.utilizationFeeNames.or');
    } else {
      this.utilizationFeeName = this.translateService.instant('global.utilizationFeeNames.vip');
    }

    return this.utilizationFeeName;
  }
}
