import { Injectable } from '@angular/core';
import { APP_CONFIG } from '../../config/app-config';
import { StorageService } from './storage.service';

@Injectable()
export class AuthStorageService {
  storage: StorageService;

  constructor() {
    this.storage = new StorageService(APP_CONFIG.AUTH.STORAGE_TYPE);
  }

  getItem(itemName) {
    return this.storage.getItem(itemName);
  }

  setItem(itemName, itemValue) {
    return this.storage.setItem(itemName, itemValue);
  }

  getItemExp(itemName: string) {
    const item = this.storage.getItem(itemName)

    if (!item) {
      return null;
    } 

    const parsedItem = JSON.parse(item);
    if (new Date().getTime() > new Date(parsedItem.expiresOn).getTime()) {
      this.storage.removeItem(itemName);
      return null;
    }

    return parsedItem.value;
  }

  setItemExp(itemName: string, value: string, expiryInSeconds: number) {
    const item = {
      value,
      expiresOn: new Date().getTime() + expiryInSeconds
    }

    // const itemNameSuffix = itemName + '-exp';
    return this.storage.setItem(itemName, JSON.stringify(item));
  }

  removeItem(itemName) {
    return this.storage.removeItem(itemName);
  }
}
