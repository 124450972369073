<mat-card appearance="outlined" class="card-container" [ngClass]="{ 'disabled': cardData.active === false }">
  <div flex layout="row" layout-align="space-between center">
    <div flex="45" class="card-name">
      {{cardData.name}}
    </div>
    <img flex="50" mat-card-image src="http://via.placeholder.com/150x150?text=Company+Logo" alt="Card image">
  </div>
  <div class="bottom-nav-bar">
    <button mat-icon-button (click)="viewHistoryClicked()">
      <mat-icon>history</mat-icon>
    </button>
    <button [disabled]="addButtonDisabled" mat-icon-button (click)="deleteCardClicked()">
      <mat-icon>delete</mat-icon>
    </button>
    <button [disabled]="editButtonDisabled" mat-icon-button (click)="editCardClicked()">
      <mat-icon>mode_edit</mat-icon>
    </button>
  </div>
</mat-card>