import { Injectable } from "@angular/core";
import { NativeDateAdapter } from "@angular/material/core";

@Injectable()
export class ItfgDateAdapter extends NativeDateAdapter {

  override parse(value: any, parseFormat?: string): Date | null {
    if (typeof value === 'string' && value.trim().length > 0) {
      const dateRegex = /^\d{2}([./-])\d{2}\1\d{2}(\d{2})?$/;
      if (dateRegex.test(value)) {
        const [day, month, year] = value.split(/[-/.]/).map(Number);
        const fullYear = year < 100 ? (year + 2000) : year;
        return new Date(fullYear, month - 1, day);
      } else {
        const numericValue = parseInt(value, 10);
        if (!isNaN(numericValue) && numericValue > 0 && numericValue <= 31) {
          const now = new Date();
          return new Date(now.getFullYear(), now.getMonth(), numericValue);
        }
      }
    } else if (value instanceof Date) {
      return this.clone(value);
    }
    return null;
  }

  override format(date: Date, displayFormat: string): string {
    if (!this.isValid(date)) return '';
    return `${date.getDate().toString().padStart(2, '0')}.${(date.getMonth() + 1).toString().padStart(2, '0')}.${date.getFullYear()}`;
  }

  override isValid(date: Date | null): boolean {
    return date instanceof Date && !isNaN(date.getTime());
  }
}
