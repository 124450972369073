<div *ngIf="data.title"
     class="modal-dialog-title">
  <p [ngClass]="{'itfg-warn': data.titleWarningStyle}">{{ data.title }}</p>
</div>

<div class="mat-dialog-content">
  <div class="dialog-message">
    <span [innerHtml]="data.message"></span>
  </div>
  <div class="dialog-value-field">
    <mat-form-field>
      <!-- <mat-label>{{ data.title }}</mat-label> -->
      <input matInput [(ngModel)]="data.value">
    </mat-form-field>
  </div>
</div>

<div layout="row"
     layout-align="end center">
  <button mat-raised-button
          [mat-dialog-close]="false"
          cdkFocusInitial>{{ data.cancelButton }}</button>
  <button mat-raised-button
          color="accent"
          [mat-dialog-close]="data.value">{{ data.acceptButton }}</button>
</div>
