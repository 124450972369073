<mat-progress-bar
  *ngIf="showLoader"
  mode="indeterminate"
  color="accent"
></mat-progress-bar>

<itfg-toolbar
  [collapsed]="!leftSidenav.opened"
  [mode]="navigationMenuMode"
  (searchEvent)="searchClicked($event)"
  [toggleLeftSideNav]="toggleLeftSideNav"
  flex
>
  <itfg-navigation-menu
    [routes]="config"
    [drawerMode]="drawerMode"
    [collapsed]="true"
  ></itfg-navigation-menu>
</itfg-toolbar>

<mat-sidenav-container autosize>
  <mat-sidenav
    #leftSidenav
    [autoFocus]="true"
    mode="side"
    hasBackdrop="false"
    position="start"
    class="search-drawer"
    [style.width]="
      drawerMode === 'navigation'
        ? ''
        : drawerMode === 'call-hub'
          ? 'min-content'
          : '20vw'
    "
    style="height: 100%"
  >
    <div class="mat-sidenav-box">
      <ng-container [ngSwitch]="drawerMode">
        <itfg-navigation-menu
          *ngSwitchDefault
          [routes]="navMenuRoutes"
          [mode]="navigationMenuMode"
          [drawerMode]="drawerMode"
          [collapsed]="false"
        ></itfg-navigation-menu>

        <itfg-quick-search
          *ngSwitchCase="drawerModeTypes.Search"
          [searchCreditForm]="searchCreditForm"
          (searchEvent)="searchClicked($event)"
        >
        </itfg-quick-search>

        <itfg-call-hub *ngSwitchCase="drawerModeTypes.CallHub"></itfg-call-hub>
      </ng-container>
    </div>
  </mat-sidenav>

  <div class="resize-sidenav-container" (click)="toggleLayout()"></div>

  <div
    *ngIf="call || credit || client"
    class="right-drawer-trigger"
    (click)="toggleRightDrawer()"
    [class.opened]="rightSidenav?.opened"
  ></div>

  <div class="circle-opacity-container" *ngIf="logButtonVisible">
    <div class="circle-opacity">
      <button
        mat-fab
        [matTooltip]="
          (rightSidenav.opened
            ? 'historyLog.closeEventLog'
            : 'historyLog.openEventLog'
          ) | translate
        "
        matTooltipPosition="left"
        [disabled]="['USER_EVENT_LOG'] | isUnauthorized | async"
        (click)="toggleRightDrawer()"
      >
        <mat-icon>history</mat-icon>
      </button>
    </div>
  </div>

  <mat-sidenav
    #rightSidenav
    class="right-drawer"
    mode="side"
    opened="false"
    hasBackdrop="false"
    position="end"
  >
    @if (
      rightDrawerService.drawer.opened ||
      (rightDrawerService.isInCreditEdit &&
        rightDrawerService.isComponentInitialized)
    ) {
      <itfg-right-drawer></itfg-right-drawer>
    }
  </mat-sidenav>

  <ng-content #main></ng-content>
</mat-sidenav-container>
