import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'itfg-form-control-clear',
  templateUrl: './form-control-clear.component.html',
  styleUrls: ['./form-control-clear.component.scss'],
})
export class FormControlClearComponent implements OnInit {
  @Input() control: UntypedFormControl;

  constructor() {}

  ngOnInit() {}

  clearFormControl() {
    this.control.reset();
  }
}
