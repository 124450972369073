import { RequestService } from './request.service';
import { HttpClient } from '@angular/common/http';
import { APP_CONFIG } from '../../../app/config/app-config';
import { Injectable } from '@angular/core';
import { LoadingService } from './loading.service';

@Injectable()
export class CollectionRequestService extends RequestService {
  constructor(http: HttpClient, loading: LoadingService) {
    super(http, loading);
    this.apIUrl = APP_CONFIG.CONNECTION.API.COLLECTION;
  }
}
