<div class="dialog-container">
  <div flex layout-align="space-between center" layout="row">
      <div class="modal-dialog-title" [translate]="'filtering.addFilter'" flex>Add filter</div>
      <button tabindex="-1" mat-icon-button (click)="onNoClick()">
        <mat-icon>clear</mat-icon>
      </button>
    </div>
    <hr>
</div>

<form (ngSubmit)="addFilter()">
  <div layout="column">
    <div layout="row"
         layout-align="center center">
      <div layout="column"
           layout-align="stretch"
           layout-wrap
           flex>
        <mat-form-field class="filter-input-item"
                        flex="100"
                        flex-gt-xs="50"
                        flex-gt-sm="30"
                        flex-gt-md="30">
          <mat-label>
            <span [translate]="'filtering.chooseColumn'">Choose column</span>
          </mat-label>
          <mat-select [(value)]="filter.criterium.key"
                      (selectionChange)="onFilterKeySelectionChange($event)">
            <mat-option>
              <ngx-mat-select-search [formControl]="filterOptionsCtrl"
                                      placeholderLabel="{{ 'filtering.searchFilters' | translate }}"
                                      noEntriesFoundLabel="{{ 'filtering.noResultsFound' | translate }}">
              </ngx-mat-select-search>
            </mat-option>
            <mat-optgroup *ngFor="let groupConfig of groupedFiltersConfig"
                          [label]="groupConfig.name | translate">
              <mat-option *ngFor="let filterConfig of groupConfig.filters | objectToArray"
                          [value]="filterConfig.value.path">
                {{ filterConfig.value.nameTranslationKey | translate }}
              </mat-option>
            </mat-optgroup>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="filter-input-item"
                        flex="100"
                        flex-gt-xs="50"
                        flex-gt-sm="20"
                        flex-gt-md="20">
          <mat-label>
            <span [translate]="'filtering.filterType'">Filter type</span>
          </mat-label>
          <mat-select [(value)]="filter.criterium.operation"
                      [disabled]="filter.config === undefined">
            <div *ngIf="!(filter.config === undefined)">
              <mat-option *ngFor="let operation of filter.config.availableOperations"
                          [value]="operation">
                {{ operationsListTranslationMap[operation] | translate }}
              </mat-option>
            </div>
          </mat-select>
        </mat-form-field>
        <div [ngSwitch]="filter.config && filter.config.type"
             flex="100"
             flex-gt-xs="100"
             flex-gt-sm="50"
             flex-gt-md="50"
             layout="column">
          <mat-form-field *ngSwitchCase="filterUITypes.input"
                          class="filter-input-item">
            <input #valueInput
                   type="text"
                   matInput
                   [(ngModel)]="filter.criterium.value"
                   [ngModelOptions]="{standalone: true}">
            <mat-label>
              <span>{{ filter.config.nameTranslationKey | translate }}</span>
            </mat-label>
          </mat-form-field>
          <mat-form-field *ngSwitchCase="filterUITypes.dropdown"
                          class="filter-input-item">
            <mat-label>
              <span>{{ filter.config.nameTranslationKey | translate }}</span>
            </mat-label>
            <mat-select #valueInput
                        [(value)]="filter.criterium.value"
                        disableOptionCentering="true">
              <mat-option>
                <ngx-mat-select-search [formControl]="dropdownFilterCtrl"
                                       placeholderLabel="{{ 'filtering.searchValues' | translate }}"
                                       noEntriesFoundLabel="{{ 'filtering.noResultsFound' | translate }}">
                </ngx-mat-select-search>
              </mat-option>
              <mat-option *ngFor="let filterValue of filter.filterValues"
                          [value]="filterValue.value">
                {{ filterValue.displayText | translate }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field *ngSwitchCase="filterUITypes.multiselect"
                          class="filter-input-item">
            <mat-label>
              <span>{{ filter.config.nameTranslationKey | translate }}</span>
            </mat-label>
            <mat-select #valueInput
                        multiple
                        [(value)]="filter.criterium.value"
                        disableOptionCentering="true">
              <mat-option>
                <ngx-mat-select-search [formControl]="dropdownFilterCtrl"
                                        placeholderLabel="{{ 'filtering.searchValues' | translate }}"
                                        noEntriesFoundLabel="{{ 'filtering.noResultsFound' | translate }}">
                </ngx-mat-select-search>
              </mat-option>
              <mat-option *ngFor="let filterValue of filter.filterValues"
                          [value]="filterValue.value">
                {{ filterValue.displayText }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field *ngSwitchCase="filterUITypes.autocomplete"
                          class="filter-input-item">
            <mat-label>
              <span>{{ filter.config.nameTranslationKey | translate }}</span>
            </mat-label>
            <input #valueInput
                   type="text"
                   matInput
                   itfgSelectFirstAutocompleteOptionOnBlur
                   [(ngModel)]="filter.criterium.value"
                   [ngModelOptions]="{standalone: true}"
                   [matAutocomplete]="auto"
                   (input)="refreshAutocompleteValues($event.target.value)">
            <mat-autocomplete #auto="matAutocomplete"
                              itfgHighlightFirstAutocompleteOptionOnChange
                              [autoActiveFirstOption]="true"
                              [displayWith]="mapValueToDisplayText">
              <mat-option *ngFor="let filterValue of filter.filterValues"
                          [value]="filterValue">
                {{ filterValue.displayText }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
          <mat-form-field *ngSwitchCase="filterUITypes.multiselectAutocomplete"
                          class="filter-input-item">
            <mat-chip-grid #chipList>
              <mat-chip-row *ngFor="let filterValue of filter.criterium.value; let filterValueIndex = index"
                        [removable]="true"
                        (removed)="removeChipSelection(filterValueIndex)">
                {{ filterValue.displayText }}
                <mat-icon matChipRemove>cancel</mat-icon>
              </mat-chip-row>
              <input #valueInput
                     type="text"
                     matInput
                     itfgSelectFirstAutocompleteOptionOnBlur
                     [matChipInputFor]="chipList"
                     [matAutocomplete]="chipAuto"
                     (input)="refreshAutocompleteValues($event.target.value)" />
            </mat-chip-grid>
            <mat-label>
              <span>{{ filter.config.nameTranslationKey | translate }}</span>
            </mat-label>
            <mat-autocomplete #chipAuto="matAutocomplete"
                              itfgHighlightFirstAutocompleteOptionOnChange
                              [autoActiveFirstOption]="true"
                              (optionSelected)="chipOptionSelected($event)">
              <mat-option *ngFor="let filterValue of filter.filterValues"
                          [value]="filterValue">
                {{ filterValue.displayText }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
          <mat-form-field *ngSwitchCase="filterUITypes.datepicker"
                          class="filter-input-item">
            <input #valueInput
                   type="text"
                   matInput
                   readonly
                   [matDatepicker]="picker"
                   [(ngModel)]="filter.criterium.value"
                   [ngModelOptions]="{standalone: true}"
                   (click)="showDatepicker($event, picker)">
            <mat-label>
              <span>{{ filter.config.nameTranslationKey | translate }}</span>
            </mat-label>
            <mat-datepicker-toggle matSuffix
                                   [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
          <mat-form-field *ngSwitchDefault
                          class="filter-input-item">
            <input #valueInput
                   type="text"
                   matInput
                   disabled>
            <mat-label>
              <span [translate]="'global.value'">Value</span>
            </mat-label>
          </mat-form-field>
        </div>
        <div layout="row"
             layout-align="end"
             flex>
          <button type="submit"
                  mat-raised-button
                  class="filter-action-item">
            <span [translate]="'global.add'">Add</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</form>
