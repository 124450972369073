export enum CallCategoryTypes {
  ALL = 'ALL',
  MISSED = 'MISSED',
  NO_AGENT = 'NO_AGENT',
  FOR_NOTE = 'FOR_NOTE',
  FOR_CALLBACK = 'FOR_CALLBACK',
}

export interface Category {
  id: CallCategoryTypes;
  label: string;
  icon: string;
}

export interface ChCallsDto {
  category: CallCategoryTypes;
  operatorId: number;
}

export interface ChCallCreatedDto {
  lastCallId: number;
  category: CallCategoryTypes;
  operatorId: number;
}
