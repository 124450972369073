import { Pipe, PipeTransform } from '@angular/core';
import { Nationality } from '../../../core/types/identity-reports';

@Pipe({
  name: 'displayNationalityList',
})
export class DisplayNationalityListPipe implements PipeTransform {
  transform(NationalityList: Nationality[]): string {
    if (NationalityList.length > 0) {
      return NationalityList
        .map(n => (n?.NationalityName || '-') + ' / ' + (n?.NationalityCode || '-'))
        .join('; ');
    }
    return '';
  }
}
