import { ValidatorFn, UntypedFormControl } from '@angular/forms';
import { UntypedFormGroup, ValidationErrors } from '@angular/forms';

export const CompareValidation = {
  fieldsAreEqual: fieldsAreEqual,
  valuesAscending: valuesAscending,
  futureDate: futureDate,
  pastDate: pastDate,
};

function valuesAscending(
  validationKey: string,
  controlKeys: string[]
): ValidatorFn {
  return (group: UntypedFormGroup): ValidationErrors => {
    let areAscending = true;
    let i = 0;
    while (i < controlKeys.length - 1) {
      if (
        group.controls[controlKeys[i]].value >
        group.controls[controlKeys[i + 1]].value
      ) {
        areAscending = false;
        break;
      }
      i++;
    }

    if (areAscending) {
      return null;
    } else {
      const validationError = {};
      validationError[validationKey] = { value: controlKeys };
      return validationError;
    }
  };
}

function fieldsAreEqual(validationKey: string, controlKeys: string[]) {
  return (group: UntypedFormGroup): ValidationErrors => {
    let valuesAreEqual = true;

    for (let i = 0; i < controlKeys.length - 1; i++) {
      if (
        group.controls[controlKeys[i]].value !==
        group.controls[controlKeys[i + 1]].value
      ) {
        valuesAreEqual = false;
        break;
      }
    }

    if (valuesAreEqual) {
      return null;
    } else {
      const validationError = {};
      validationError[validationKey] = { value: controlKeys };
      return validationError;
    }
  };
}

function futureDate(control: UntypedFormControl) {
  if (control.value === null) {
    return null;
  }
  const currentDate = new Date();
  return new Date(control.value) > currentDate
    ? null
    : { pastDate: { value: control } };
}

function pastDate(control: UntypedFormControl) {
  if (control.value === null) {
    return null;
  }
  const currentDate = new Date();
  return new Date(control.value) < currentDate
    ? null
    : { pastDate: { value: control } };
}
