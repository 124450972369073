import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IPromptConfig } from '@app/core/types/dialog.type';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'itfg-prompt-dialog',
  templateUrl: './prompt-dialog.component.html',
  styleUrls: ['./prompt-dialog.component.scss'],
})
export class PromptDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<PromptDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: IPromptConfig,
    private translateService: TranslateService,
  ) {
  }

  ngOnInit() {
    if (!this.data.acceptButton) {
      this.data.acceptButton = this.translateService.instant('global.confirm');
    }
    if (!this.data.cancelButton) {
      this.data.cancelButton = this.translateService.instant('global.cancel');
    }
  }
}
