import { Injectable } from '@angular/core';
import { RequestService } from './request.service';
import { Observable } from 'rxjs';
import { GcSummaryCredit } from '../types/gc-credit-summary';

@Injectable({
  providedIn: 'root',
})
export class GcCreditSummaryService {
  constructor(private request: RequestService) {}

  getSummary$(clientCivilId: string): Observable<GcSummaryCredit[]> {
    return this.request.get(['gc', 'credits', 'summary'], {
      params: { civilId: clientCivilId },
    });
  }
}
