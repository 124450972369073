import { ErrorStateMatcher } from '@angular/material/core';
import { FormGroupDirective, NgForm, UntypedFormControl } from '@angular/forms';

export class CustomErrorStateMatcher implements ErrorStateMatcher {
  constructor(private controlSelector: string) {}
  isErrorState(
    control: UntypedFormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    const controlTouched = !!(control && (control.dirty || control.touched));
    const controlInvalid = !!(control && control.invalid);
    const parentInvalid = !!(
      control &&
      control.parent &&
      control.parent.invalid &&
      control.parent.errors &&
      control.parent.errors.hasOwnProperty(this.controlSelector) &&
      (control.parent.dirty || control.parent.touched)
    );

    return controlTouched && (controlInvalid || parentInvalid);
  }
}
