import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { RequestService } from './request.service';
import { Observable } from 'rxjs';
import { Page } from '../types/page';
import { Claim, DeserializedClaim, EditClaim } from '../types/claim';

@Injectable()
export class ClaimService {
  constructor(private request: RequestService) {}

  getClaimList$(options?: any): Observable<Page<DeserializedClaim>> {
    return this.request.get(['claims'], { params: options }).pipe(
      map(
        (claimPage: Page<Claim>): Page<DeserializedClaim> => {
          return {
            ...claimPage,
            content: claimPage.content.map(this.deserializeClaim.bind(this)),
          };
        }
      )
    );
  }

  getClaimById$(id: number, options?: any): Observable<DeserializedClaim> {
    return this.request.get(['claims', id], { params: options }).pipe(
      map(
        (claim: Claim): DeserializedClaim => {
          return this.deserializeClaim(claim);
        }
      )
    );
  }

  saveClaim$(
    claim: DeserializedClaim,
    options: any = {}
  ): Observable<DeserializedClaim> {
    const claimToSave = this.serializeClaim(claim);
    const requestOptions = Object.assign(options, { body: claimToSave });
    return this.request.post(['claims'], requestOptions);
  }

  updateClaim$(
    id: number,
    claim: DeserializedClaim,
    options: any = {}
  ): Observable<DeserializedClaim> {
    const claimToSave: Claim = this.serializeClaim(claim);
    const editClaim: EditClaim = this.transformClaimToEditClaim(claimToSave);
    let requestOptions = {
      body: editClaim,
    };
    requestOptions = Object.assign(requestOptions, { params: options });
    return this.request.post(['claims', id], requestOptions);
  }

  serializeClaim(deserializedClaim: DeserializedClaim): Claim {
    return {
      ...deserializedClaim,
      time: deserializedClaim.time
        ? deserializedClaim.time.toISOString()
        : undefined,
    };
  }

  deserializeClaim(claim: Claim) {
    return {
      ...claim,
      time: claim.time ? new Date(claim.time) : undefined,
    };
  }

  transformClaimToEditClaim(claim: Claim): EditClaim {
    return {
      completed: claim.completed,
      notes: claim.notes,
    };
  }
}
