import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'itfg-filter-settings-button',
  templateUrl: './filter-settings-button.component.html',
  styleUrls: ['./filter-settings-button.component.scss']
})
export class FilterSettingsButtonComponent implements OnInit {
  @Output() showFilterCardSettings = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  showSettings() {
    this.showFilterCardSettings.emit();
  }

}
