import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConversationContactStatusComponent } from './conversation-contact-status.component';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '../../../../../shared/shared.module';
import { CreditPipesModule } from '../../../credit-pipes.module';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    SharedModule,
    CreditPipesModule,
    MatIconModule,
    MatTooltipModule,
  ],
  declarations: [ConversationContactStatusComponent],
  exports: [ConversationContactStatusComponent],
})
export class ConversationContactStatusModule {}
