import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AuthRoutingModule } from './auth-routing.module';
import { LoginComponent } from './login/login.component';
import { LoginFormComponent } from './login-form/login-form.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { AuthComponent } from './auth.component';
import { SharedModule } from '../shared/shared.module';
import { MaterialModule } from '../lib/material.module';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ResourceNotFoundComponent } from './resource-not-found/resource-not-found.component';
import { UnauthorizedPageComponent } from './unauthorized-page/unauthorized-page.component';
import { MfaVerificationComponent } from './mfa-verification/mfa-verification.component';
import { TOtpInputComponent } from './totp-input/totp-input.component';
import {ClipboardModule} from '@angular/cdk/clipboard';
import { MfaInstructionsDialog } from './mfa-instructions-dialog/mfa-instructions-dialog.component';
import { MatTableModule } from '@angular/material/table';

@NgModule({
  imports: [
    CommonModule,
    AuthRoutingModule,
    MaterialModule,
    TranslateModule,
    ReactiveFormsModule,
    SharedModule,
    FormsModule,
    ClipboardModule,
    MatTableModule
  ],
  exports: [AuthRoutingModule],
  declarations: [
    AuthComponent,
    LoginComponent,
    MfaVerificationComponent,
    LoginFormComponent,
    PageNotFoundComponent,
    ResourceNotFoundComponent,
    PageNotFoundComponent,
    UnauthorizedPageComponent,
    TOtpInputComponent,
    MfaInstructionsDialog,
  ],
  providers: [],
})
export class AuthModule {}
