import { Pipe, PipeTransform } from '@angular/core';
import { PaymentMethodTypes } from '../../../core/types/payment-method';

@Pipe({
  name: 'paymentMethodToTranslationKey',
})
export class PaymentMethodToTranslationKeyPipe implements PipeTransform {
  static readonly paymentMethodTranslationMap: { [key: string]: string } = {
    [PaymentMethodTypes.BANK]: 'payments.methods.BANK',
    [PaymentMethodTypes.CASH]: 'payments.methods.CASH',
    [PaymentMethodTypes.EASYPAY]: 'payments.methods.EASYPAY',
  };

  transform(methodName: string): string {
    return PaymentMethodToTranslationKeyPipe.paymentMethodTranslationMap[
      methodName
    ];
  }
}
