import { NavigationConfig } from './app-config';

export const NAVIGATION_CONFIG: NavigationConfig = {
  routes: [
    {
      // Home
      label: 'global.home',
      icon: 'home',
      routerLink: '/home',
      children: [],
    },
    {
      // Administration
      label: 'global.settings',
      icon: 'settings',
      permissions: [
        'BRAND_READ',
        'ZONE_READ',
        'OFFICE_READ',
        'ADMINISTRATOR_READ_ALL',
        'ROLEGROUP_READ',
        'SMSTEMPLATE_READ',
        'MAILTEMPLATE_READ',
        'EMAILTEMPLATE_READ',
        'WORKTIME_READ',
        'DCA_READ',
      ],
      routerLink: '/administration',
      children: [
        {
          // Products
          label: 'global.products',
          icon: 'store',
          permissions: ['PRODUCT_READ', 'PRODUCT_WRITE'],
          routerLink: '/products',
          children: [
            {
              // Products list
              label: 'products.productList',
              icon: 'list',
              routerLink: '/products',
              permissions: ['PRODUCT_READ'],
            },
            {
              // New product
              label: 'products.newProduct',
              icon: 'add',
              routerLink: '/products/new',
              permissions: ['PRODUCT_WRITE'],
            },
          ],
        },
        {
          // Brands
          label: 'global.brands',
          icon: 'local_offer',
          routerLink: '/administration/brands',
          permissions: ['BRAND_READ'],
        },
        {
          // Zones
          label: 'global.zones',
          icon: 'aspect_ratio',
          routerLink: '/administration/zones',
          permissions: ['ZONE_READ'],
        },
        {
          // Offices
          label: 'global.offices',
          icon: 'location_city',
          routerLink: '/administration/offices',
          permissions: ['OFFICE_READ'],
        },
        {
          // Administrators
          label: 'global.administrators',
          icon: 'person',
          routerLink: '/administration/administrators',
          permissions: ['ADMINISTRATOR_READ_ALL'],
        },
        {
          // Partners
          label: 'global.affiliates',
          icon: 'business_center',
          routerLink: '/administration/partners',
          permissions: ['PARTNER_READ'],
        },
        {
          // Groups
          label: 'global.roles',
          icon: 'group',
          routerLink: '/administration/groups',
          permissions: ['ROLEGROUP_READ'],
        },
        {
          // Tags
          label: 'global.tags',
          icon: 'label',
          routerLink: '/administration/tags',
          permissions: ['TAG_READ'],
        },
        {
          // Templates
          label: 'global.templates',
          icon: 'contact_mail',
          routerLink: '/administration/templates',
          permissions: [
            'SMSTEMPLATE_READ',
            'MAILTEMPLATE_READ',
            'EMAILTEMPLATE_READ',
          ],
          children: [
            {
              // Brands
              label: 'templates.sms',
              icon: 'sms',
              routerLink: '/administration/templates/sms',
              permissions: ['BRAND_READ'],
            },
            {
              // Brands
              label: 'templates.emails',
              icon: 'mail_outline',
              routerLink: '/administration/templates/emails',
              permissions: ['BRAND_READ'],
            },
            {
              // Brands
              label: 'templates.documents',
              icon: 'description',
              routerLink: '/administration/templates/documents',
              permissions: ['BRAND_READ'],
            },
          ],
        },
        {
          // Business days
          label: 'global.businessDays',
          icon: 'date_range',
          routerLink: '/administration/businessDays',
          permissions: ['WORKTIME_READ'],
        },
        {
          // Communication
          label: 'clientComunications.communication',
          icon: 'business',
          // permissions: ['PRODUCT_READ', 'PRODUCT_WRITE'], //TODO add permissions
          routerLink: '/administration/communication',
          children: [
            {
              // topics
              label: 'clientComunications.topics.label',
              icon: 'clear_all',
              routerLink: '/administration/communication/topics',
              // permissions: ['PRODUCT_READ'], //TODO add permissions
            },
          ],
        },
      ],
    },
    {
      // Collection
      label: 'global.collection',
      icon: 'timelapse',
      permissions: [
        'BUCKET_READ',
        'BUCKET_CREDIT_READ',
        'COLLECTION_SETTINGS',
        'COLLECTION_COLLECTOR_CREDITS',
      ],
      routerLink: '/collection',
      children: [
        {
          // Config
          label: 'global.operators',
          icon: 'people',
          routerLink: '/collection/operators',
          permissions: ['COLLECTION_SETTINGS'],
        },
        {
          // Buckets
          label: 'global.buckets',
          icon: 'timelapse',
          permissions: ['BUCKET_READ', 'BUCKET_CREDIT_READ'],
          routerLink: '/collection/buckets',
          children: [
            {
              // Configuration
              label: 'buckets.configuration',
              icon: 'settings',
              routerLink: '/collection/buckets',
              permissions: ['BUCKET_READ'],
            },
            {
              // Operators
              label: 'buckets.operators.operators',
              icon: 'person',
              routerLink: '/collection/buckets/operators',
              permissions: ['BUCKET_READ'],
            },
            {
              // Credits
              label: 'global.credits',
              icon: 'payment',
              routerLink: '/collection/buckets/credits',
              permissions: ['BUCKET_CREDIT_READ'],
            },
          ],
        },
        {
          // Collectors
          label: 'global.collectors',
          icon: 'assignment_ind',
          permissions: ['COLLECTION_SETTINGS', 'COLLECTION_COLLECTOR_CREDITS'],
          routerLink: '/collection/collectors',
          children: [
            {
              label: 'collection.collector.list',
              icon: 'list',
              routerLink: '/collection/collectors',
              permissions: ['COLLECTION_SETTINGS'],
            },
            {
              label: 'global.operators',
              icon: 'person',
              routerLink: '/collection/collectors/operators',
              permissions: ['COLLECTION_SETTINGS'],
            },
            {
              label: 'global.credits',
              icon: 'payment',
              routerLink: '/collection/collectors/credits',
              permissions: ['COLLECTION_SETTINGS'],
            },
            {
              label: 'global.myCredits',
              icon: 'assignment_ind',
              routerLink: '/collection/collectors/my-credits',
              permissions: ['COLLECTION_SETTINGS', 'COLLECTION_COLLECTOR_CREDITS'],
            },
          ],
        },
        {
          // External collection
          label: 'collection.agentType.external',
          icon: 'domain',
          routerLink: '/collection/external',
          permissions: ['COLLECTION_SETTINGS'],
        },
        {
          // Legal collection
          label: 'collection.agentType.legal',
          icon: 'gavel',
          routerLink: '/collection/legal',
          permissions: ['COLLECTION_SETTINGS'],
        },
        {
          // Unassigned credits
          label: 'collection.unassignedCredits',
          icon: 'assignment_returned',
          routerLink: '/collection/unassigned',
          permissions: ['COLLECTION_SETTINGS'],
        },
        {
          label: 'collection.creditAssignmentBulk',
          icon: 'assignment',
          routerLink: '/collection/bulk-assignment',
          permissions: ['COLLECTION_AGENT_ASSIGN'],
        },
        {
          label: 'communication.paymentPromises',
          icon: 'payment',
          routerLink: '/collection/payment-promises',
          permissions: ['PAYMENT_PROMISE_READ'],
        },
        {
          label: 'communication.myPaymentPromises',
          icon: 'payment',
          routerLink: '/collection/payment-promises-filtered',
          permissions: ['PAYMENT_PROMISE_READ'],
        },
        {
          label: 'communication.myReminders',
          icon: 'notifications',
          routerLink: '/collection/reminders',
          permissions: [],
        },
      ],
    },
    {
      // Clients
      label: 'global.clients',
      icon: 'person',
      permissions: ['USER_LIST', 'USER_WRITE', 'CLAIM_READ', 'BLACKLIST_LIST'],
      routerLink: '/clients',
      children: [
        {
          // Client List
          label: 'clients.clientList',
          icon: 'list',
          routerLink: '/clients',
          permissions: ['USER_LIST'],
        },
        {
          label: 'clients.incompleteProfiles',
          icon: 'contact_phone',
          routerLink: '/clients/incomplete',
          permissions: [],
        },
        {
          label: 'clients.verification',
          icon: 'verified_user',
          routerLink: '/clients/verification',
          permissions: [],
        },
        {
          // New client
          label: 'clients.newClient',
          icon: 'add',
          routerLink: '/clients/new',
          permissions: ['USER_WRITE'],
        },
        {
          // New client
          label: 'clients.bulkTagging',
          icon: 'label',
          routerLink: '/clients/bulk-tagging',
          permissions: ['TAG_WRITE'],
        },
        {
          // Bulk loyalty points
          label: 'clients.addLoyaltyPoints',
          icon: 'loyalty',
          routerLink: '/clients/bulk-loyalty-points',
          permissions: ['LOYALTY_POINT_WRITE'],
        },
        {
          // Claims
          label: 'clients.claims',
          icon: 'assignment_late',
          routerLink: '/claims',
          permissions: ['CLAIM_READ'],
        },
        {
          // Blacklist
          label: 'clients.blacklist',
          icon: 'not_interested',
          routerLink: '/clients/blacklist',
          permissions: ['BLACKLIST_LIST'],
        },
        {
          // Nssi reports
          label: 'nssi.reports',
          icon: 'assignment_ind',
          permissions: ['NSSI_READ'],
          routerLink: '/nssi-reports',
        },
        {
          // Nssi reports
          label: 'mi.reports',
          icon: 'assignment_ind',
          permissions: ['MI_READ'],
          routerLink: '/mi-reports',
        },
        {
          // CCR reports
          label: 'ccr.reports',
          icon: 'assignment_ind',
          permissions: ['CCR_READ'],
          routerLink: '/ccr-reports',
        },
      ],
    },
    {
      // Credits
      label: 'global.credits',
      icon: 'payment',
      permissions: ['CREDIT_READ_ALL'],
      routerLink: '/credits',
      children: [
        {
          // Credit list
          label: 'credits.creditList',
          icon: 'list',
          routerLink: '/credits',
          permissions: ['CREDIT_READ_ALL'],
        },
        {
          label: 'global.requests',
          icon: 'assignment',
          routerLink: '/credits/requests',
          permissions: ['CREDIT_READ_ALL'],
        },
        {
          label: 'global.applications',
          icon: 'view_list',
          routerLink: '/credits/applications',
          permissions: ['CREDIT_READ_ALL'],
        },
        {
          label: 'credits.newCredit',
          icon: 'add',
          routerLink: '/credits/new',
          permissions: ['CREDIT_WRITE'],
        },
        {
          label: 'credits.bulkTagging',
          icon: 'label',
          routerLink: '/credits/bulk-tagging',
          permissions: ['CREDIT_READ_ALL'],
        },
      ],
    },
    {
      // Payments
      label: 'global.payments',
      icon: 'attach_money',
      permissions: ['ACCOUNT_LIST', 'TRANSACTION_LIST'],
      routerLink: '/payments',
      children: [
        {
          // Transactions
          label: 'global.transactions',
          icon: 'compare_arrows',
          routerLink: '/payments/transactions',
          permissions: ['TRANSACTION_LIST'],
        },
        {
          // Accounts
          label: 'global.accounts',
          icon: 'account_balance_wallet',
          routerLink: '/payments/accounts',
          permissions: ['ACCOUNT_LIST'],
        },
        {
          // Bank
          label: 'payments.banks.titleKey',
          icon: 'account_balance',
          permissions: ['PAYMENT_BOT_SESSION_READ', 'BANK_PAYMENTS_READ', 'PAYMENTS_ACCOUNTS_READ'],
          routerLink: '/payments/bank',
          children: [
            {
              label: 'payments.banks.sessions',
              icon: 'monitor',
              routerLink: '/payments/bank/sessions',
              permissions: ['PAYMENT_BOT_SESSION_READ'],
            },
            {
              label: 'payments.banks.payinTransactions',
              icon: 'payments',
              routerLink: '/payments/bank/payins',
              permissions: ['BANK_PAYMENTS_READ'],
            },
            {
              label: 'payments.banks.accounts',
              icon: 'account_balance',
              routerLink: '/payments/bank/bank-accounts',
              permissions: ['PAYMENTS_ACCOUNTS_READ'],
            },
          ],
        },
      ],
    },
    {
      // Reports
      label: 'reports.bnbReports',
      icon: 'assignment',
      permissions: ['REPORTS_BNB_READ'],
      routerLink: '/bnb-reports',
      children: [],
    },
    {
      // P2P
      label: 'p2p.platforms',
      icon: 'currency_exchange',
      permissions: ['P2P_LOANS_READ'],
      routerLink: '/p2p',
      children: [
        {
          label: 'p2p.income',
          icon: 'looks_one',
          routerLink: '/p2p/income',
          permissions: ['P2P_INCOME'],
          children: [
            {
              label: 'p2p.sendToIncome',
              icon: 'send',
              routerLink: '/p2p/income/income-list',
              permissions: ['P2P_INCOME'],
            },
            {
              label: 'global.payments',
              icon: 'attach_money',
              routerLink: '/p2p/income/income-payments',
              permissions: ['P2P_INCOME'],
            },
          ],
        },
        {
          label: 'p2p.bondster',
          icon: 'looks_two',
          routerLink: '/p2p/bondster',
          permissions: ['P2P_BONDSTER_LOANS_PREVIEW'],
          children: [
            {
              label: 'p2p.sendToBondsterSc',
              icon: 'send',
              routerLink: '/p2p/bondster/bondster-sc-list',
              permissions: ['P2P_BONDSTER_LOANS_PREVIEW'],
            },
            {
              label: 'p2p.buybackBondsterSc',
              icon: 'remove_circle',
              routerLink: '/p2p/bondster/bondster-sc-buyback',
              permissions: ['P2P_BONDSTER_LOANS_PREVIEW'],
            },
            {
              label: 'p2p.sendToBondsterGc',
              icon: 'send',
              routerLink: '/p2p/bondster-gc/bondster-gc-list',
              permissions: ['P2P_BONDSTER_LOANS_PREVIEW'],
            },
            {
              label: 'p2p.sendToBondsterCd',
              icon: 'send',
              routerLink: '/p2p/bondster-cd/bondster-cd-list',
              permissions: ['P2P_BONDSTER_LOANS_PREVIEW'],
            },
            {
              label: 'global.payments',
              icon: 'attach_money',
              routerLink: '/p2p/bondster/bondster-payments',
              permissions: ['P2P_BONDSTER_PAYMENTS_PREVIEW'],
            },
            {
              label: 'global.tasks',
              icon: 'list',
              routerLink: '/p2p/bondster/bondster-tasks',
              permissions: ['P2P_BONDSTER_LOANS_PREVIEW'],
            },
          ],
        },
        {
          label: 'p2p.lendermarket',
          icon: 'looks_3',
          routerLink: '/p2p/lendermarket',
          permissions: ['P2P_ELANA'], //TODO ADD PERMISSIONS
          children: [
            {
              label: 'p2p.sendToLendermarket',
              icon: 'send',
              routerLink: '/p2p/lendermarket/lendermarket-list',
              permissions: ['P2P_ELANA'],  // TODO ADD PERMISSIONS
            },
            {
              label: 'global.payments',
              icon: 'attach_money',
              routerLink: '/p2p/lendermarket/lendermarket-payments',
              permissions: ['P2P_ELANA'],  // TODO ADD PERMISSIONS
            },
          ],
        },
        {
          label: 'p2p.configuration.label',
          icon: 'settings',
          routerLink: '/p2p/configuration',
          permissions: ['P2P_LOANS_READ'], //TODO ADD PERMISSION WRITE
          children: []
        },
      ],
    },
  ],
};
