import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FilterValueListService, FilterValue } from '../filter/types';
import { AdministratorService } from '../../core/services/administrator.service';
import { Administrator } from '../../core/types/administrator';
import { Page } from '../../core/types/page';
import { SearchOptions } from '../../../app/core/types/search-options';
import { SearchOperations } from '../../../app/core/types/search-criterium';

@Injectable()
export class AdministratorValueListService implements FilterValueListService {
  constructor(private administrators: AdministratorService) {}

  getFilterValues$(startWith = ''): Observable<FilterValue[]> {
    const options = new SearchOptions({
      pageSize: 6000,
      criteria: [
        {
          key: 'firstName',
          operation: SearchOperations.LIKE,
          value: startWith,
        },
        {
          key: 'office.zone.brand.id',
          operation: SearchOperations.EQUALS,
          value: 1,
        },
        {
          key: 'active',
          operation: SearchOperations.EQUALS,
          value: true,
        },
      ],
    });

    return this.administrators.getAdministratorList(options.getDTO()).pipe(
      map(
        (administratorPage: Page<Administrator>): Administrator[] =>
          administratorPage.content
      ),
      map((administratorList: Administrator[]): FilterValue[] => {
        return administratorList
          .sort((a, b) =>
            a.firstName < b.firstName ? -1 : a.firstName > b.firstName ? 1 : 0
          )
          .map(
            (administrator: Administrator): FilterValue => {
              return {
                value: administrator.id,
                displayText: `${administrator.firstName} ${administrator.lastName}`,
              };
            }
          );
      })
    );
  }
}
