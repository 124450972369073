import { Pipe, PipeTransform } from '@angular/core';
import { CallStatus } from '@app/core/types/call';

@Pipe({
  name: 'callStatusTranslationPipe',
})
export class CallStatusTranslationPipe implements PipeTransform {
  static readonly callStatusTranslationMap: { [key: string]: string } = {
    [CallStatus.ANSWER]: 'communication.callStatuses.answer',
    [CallStatus.NO_ANSWER]: 'communication.callStatuses.noAnswer',
    [CallStatus.BUSY]: 'communication.callStatuses.busy',
    [CallStatus.FAILED]: 'communication.callStatuses.failed',
    [CallStatus.INITIATED]: 'communication.callStatuses.initiated',
    [CallStatus.ONGOING]: 'communication.callStatuses.ongoing',
    [CallStatus.CONNECTION_FAILED]: 'communication.callStatuses.connectionFailed',
    [CallStatus.AGENT_CONNECTED]: 'communication.callStatuses.agentConnected',
    [CallStatus.HANGUP_AT_WELCOME]: 'communication.callStatuses.hangupAtWelcome',
    [CallStatus.AGENT_FREE_CALLBACK_REQUESTED]: 'communication.callStatuses.agentFreeCallbackRequested',
    [CallStatus.AGENT_FREE_NO_CALLBACK_REQUESTED]: 'communication.callStatuses.agentFreeNoCallbackRequested',
    [CallStatus.ALL_BUSY_CALLBACK_REQUESTED]: 'communication.callStatuses.allBusyCallbackRequested',
    [CallStatus.ALL_BUSY_NO_CALLBACK_REQUESTED]: 'communication.callStatuses.allBusyNoCallbackRequested',
    [CallStatus.NON_WORKING_HOURS_CALLBACK_REQUESTED]: 'communication.callStatuses.nonWorkingHoursCallbackRequested',
    [CallStatus.NON_WORKING_HOURS_NO_CALLBACK_REQUESTED]: 'communication.callStatuses.nonWorkingHoursNoCallbackRequested',
  };

  transform(value: CallStatus): string {
    return CallStatusTranslationPipe.callStatusTranslationMap[value];
  }
}
