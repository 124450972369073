import { Pipe, PipeTransform } from '@angular/core';
import {PaymentAgreementStatusNames} from '../../../core/types/payment-agreement';

@Pipe({
  name: 'paymentAgreementStatusToTranslationKey',
})
export class PaymentAgreementStatusToTranslationKeyPipe implements PipeTransform {
  static readonly paymentAgreementStatusTranslationMap: { [key: string]: string } = {
    [PaymentAgreementStatusNames.DRAFT]: 'collection.paymentAgreementNames.draft',
    [PaymentAgreementStatusNames.APPROVED]: 'collection.paymentAgreementNames.approved',
    [PaymentAgreementStatusNames.ACTIVE]: 'collection.paymentAgreementNames.active',
    [PaymentAgreementStatusNames.PAID]: 'collection.paymentAgreementNames.paid',
    [PaymentAgreementStatusNames.CANCELED]: 'collection.paymentAgreementNames.cancel'
  };

  transform(paymentAgreementStatus: PaymentAgreementStatusNames): string {
    return PaymentAgreementStatusToTranslationKeyPipe
      .paymentAgreementStatusTranslationMap[paymentAgreementStatus];
  }
}
