export interface EventLogAuditBase {
  data: EventLogAuditData;
  editor: string;
  operation: EventLogAuditOperationType;
  type: EventLogAuditType;
}

export interface EventLogAudit extends EventLogAuditBase {
  time: string;
}

export interface EventLogAuditDisplay extends EventLogAuditBase {
  time?: {
    formatted?: any;
    raw?: any;
  };
  displayText?: any;
  creditId?: number;
  editableEntityData?: any;
  html?: any;
}

export enum EventLogAuditOperationType {
  ADD = 'ADD',
  EDIT = 'EDIT',
  DELETE = 'DELETE',
  SENT = 'SENT',
  ASSIGN = 'ASSIGN',
  RETURN = 'RETURN',
  REQUEST = 'REQUEST',
  CALL_INITIALIZED = 'CALL_INITIALIZED',
  CALL_MADE = 'CALL_MADE',
  NOTE_ADDED = 'NOTE_ADDED',
  NOTE_EDITED = 'NOTE_EDITED',
}

export enum EventLogAuditType {
  BANKACCOUNT = 'BANKACCOUNT',
  CCC = 'CCR',
  CONTACTPHONE = 'CONTACTPHONE',
  CONVERSATION = 'CONVERSATION',
  CREDIT = 'CREDIT',
  TRANSACTION = 'TRANSACTION',
  USER = 'USER',
  USERDOCUMENT = 'USERDOCUMENT',
  COLLECTION = 'COLLECTION',
  REPORT = 'REPORT',
  USERLOGIN = 'USERLOGIN',
  PAYMENTPROMISE = 'PAYMENTPROMISE',
  SCORE = 'SCORE',
  PAYMENTAGREEMENT = 'PAYMENTAGREEMENT',
  CALL = 'CALL',
  NOTE = 'NOTE',
}

export enum EventLogTabType {
  CALL = 'CALL',
  CREDIT = 'CREDIT',
}

export enum EventLogState {
  CREDIT_LOG = 'CREDIT_LOG',
  PBX_LOG = 'PBX_LOG',
}

export enum EventLogHeaderType {
  FILTERS = 'FILTERS',
  PBX = 'PBX',
  NOTE = 'NOTE',
}

export interface EventLogAuditEntity {
  [key: string]: any;
}

export enum filterGroups {
  IDENTITY_REPORTS = 'IDENTITY_REPORTS',
  COLLECTION = 'COLLECTION',
  SCORE = 'SCORE',
  CREDITS = 'CREDITS',
  CLIENT_COMMUNICATION = 'CLIENT_COMMUNICATION',
}

export interface EventLogAuditData {
  diff?: Array<EventLogAuditDiff>;
  entity: EventLogAuditEntity;
}

export interface EventLogAuditDiff {
  field: string;
  oldValue: string;
  newValue: string;
}
