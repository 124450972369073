export enum SlashCase {
  NONE,
  SINGLE,
  DOUBLE,
}

export enum SpecialOptionType {
  REMINDER,
  PAYMENT_PROMISE,
}

export interface SpecialOption {
  name: string;
  type: SpecialOptionType;
  icon: string;
}

export enum NoteMode {
  DEFAULT,
  PBX,
  EDIT
}
