import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FilterValueListService, FilterValue } from '../filter/types';
import { ClientService } from '../../core/services/client.service';
import { ClientStatus } from '../../core/types/client-status';
import { TranslateService } from '@ngx-translate/core';
import { ClientStatusToTranslationPipe } from '../client/pipes/client-status-to-translation.pipe';

@Injectable()
export class ClientStatusValueListService implements FilterValueListService {
  constructor(
    private clients: ClientService,
    private translateService: TranslateService
  ) {}

  getFilterValues$(): Observable<FilterValue[]> {
    const clientStatusToTranslationPipe: ClientStatusToTranslationPipe = new ClientStatusToTranslationPipe(
      this.translateService
    );
    return this.clients.getClientStatusList().pipe(
      map((clientStatusList: ClientStatus[]): FilterValue[] => {
        return clientStatusList.map(
          (clientStatus: ClientStatus): FilterValue => {
            return {
              value: clientStatus.id,
              displayText: clientStatus.reason
                ? `${clientStatusToTranslationPipe
                    .transform(clientStatus)
                    .name.toUpperCase()} - ${
                    clientStatusToTranslationPipe.transform(clientStatus).reason
                  }`
                : `${clientStatusToTranslationPipe
                    .transform(clientStatus)
                    .name.toUpperCase()}`,
            };
          }
        );
      })
    );
  }
}
