import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { BlacklistService } from '../../core/services/blacklist.service';
import { BlacklistType } from '../../core/types/blacklist-type';
import { BlacklistTypesTranslationPipe } from '../client/pipes/blacklist-types-translation.pipe';
import { FilterValue, FilterValueListService } from '../filter/types';

@Injectable()
export class BlacklistTypeValueListService implements FilterValueListService {
  constructor(
    private blacklistService: BlacklistService,
    private translateService: TranslateService
  ) {}

  getFilterValues$(): Observable<FilterValue[]> {
    const blacklistTranslationPipe: BlacklistTypesTranslationPipe = new BlacklistTypesTranslationPipe();
    return this.blacklistService.getBlacklistTypes().pipe(
      map((blacklistTypePage: BlacklistType[]): FilterValue[] => {
        return blacklistTypePage.map(
          (blacklistType: BlacklistType): FilterValue => {
            return {
              value: blacklistType.id,
              displayText: this.translateService.instant(
                blacklistTranslationPipe.transform(blacklistType.name)
              ),
            };
          }
        );
      })
    );
  }
}
