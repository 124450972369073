import { Pipe, PipeTransform } from '@angular/core';
import { IbanUtilService } from '../../../core/services/iban-util.service';

@Pipe({
  name: 'ibanCode'
})
export class IbanCodePipe implements PipeTransform {

  constructor(
    private ibanUtilService: IbanUtilService
  ){}

  transform(iban: string): string {

    const bankIdCode = iban ? this.ibanUtilService.matchIbanBICCode(iban) : '';
    return bankIdCode;
  }
}
