import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  OnDestroy,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, AbstractControl } from '@angular/forms';
import { CreditService } from '../../../core/services/credit.service';
import { Router } from '@angular/router';
import { NavigationMenuService } from '../../../core/services/navigation-menu.service';
import { Utils } from '../../../core/utils/utils';
import { Credit } from '../../../core/types/credit';
import { NotificationService } from '../../../core/services/notification.service';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'itfg-quick-search',
  templateUrl: './quick-search.component.html',
  styleUrls: ['./quick-search.component.scss'],
})
export class QuickSearchComponent implements OnInit, OnDestroy {
  @Input() searchCreditForm: UntypedFormGroup;
  @Output() searchEvent = new EventEmitter<boolean>();
  @ViewChild('creditIdInput') creditIdInput: ElementRef;
  _unsubscribe = new Subject<void>();

  searchCreditByIdForm: UntypedFormGroup;

  creditList: Credit[];
  isData: boolean;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private creditService: CreditService,
    private router: Router,
    private navigationMenuService: NavigationMenuService,
    private notificationsService: NotificationService
  ) {
    this.isData = true;
  }

  ngOnInit() {
    this.searchCreditByIdForm = this.createSearchByIdForm();
    this.navigationMenuService.onSearchDrawerOpened
      .pipe(takeUntil(this._unsubscribe))
      .subscribe(() => this.onSearchDrawerOpened());
    // this.searchCreditForm = this.createSearchForm();
  }

  createSearchByIdForm() {
    const fb = this.formBuilder;
    return fb.group({
      creditId: [''],
    });
  }

  onSearchDrawerOpened() {
    this.focusCreditIdField();
  }

  focusCreditIdField() {
    this.creditIdInput.nativeElement.focus();
  }

  transformFirstNameValue(event) {
    event.preventDefault();

    let firstNameValue = event.clipboardData.getData('Text').trim();

    firstNameValue = firstNameValue.split(' ');
    if (firstNameValue) {
      if (firstNameValue.length > 1 && firstNameValue[1].length > 0) {
        if (firstNameValue.length === 2) {
          this.searchCreditForm.get('firstName').patchValue(firstNameValue[0]);
          this.searchCreditForm.get('middleName').patchValue('');
          this.searchCreditForm.get('lastName').patchValue(firstNameValue[1]);
        } else {
          this.searchCreditForm.get('firstName').patchValue(firstNameValue[0]);
          this.searchCreditForm.get('middleName').patchValue(firstNameValue[1]);
        }
        if (firstNameValue.length > 2) {
          const lastNameValue = firstNameValue.splice(2).join(' ');
          this.searchCreditForm.get('lastName').patchValue(lastNameValue);
        }
      } else {
        this.searchCreditForm.get('firstName').patchValue(firstNameValue[0]);
      }
    }
  }

  transformMiddleNameValue(event) {
    event.preventDefault();

    let middleNameValue = event.clipboardData.getData('Text').trim();
    middleNameValue = middleNameValue.split(' ');

    if (middleNameValue) {
      if (middleNameValue.length > 1 && middleNameValue[1].length > 0) {
        this.searchCreditForm.get('middleName').patchValue(middleNameValue[0]);
        this.searchCreditForm.get('firstName').patchValue('');
        const lastNameValue = middleNameValue.splice(1).join(' ');
        this.searchCreditForm.get('lastName').patchValue(lastNameValue);
      } else {
        this.searchCreditForm.get('middleName').patchValue(middleNameValue[0]);
      }
    }
  }

  trimValue(event: any, ctrlName: string) {
    event.preventDefault();
    const control: AbstractControl = this.searchCreditForm.get(ctrlName);
    const trimmedValue = event.clipboardData.getData('Text').trim();
    control.patchValue(trimmedValue);
  }

  findCreditById(event) {
    event.preventDefault();
    const creditId = this.searchCreditByIdForm.value.creditId;
    if (creditId) {
      this.router.navigate(['credits', Number.parseInt(creditId, 10)]);
      this.closeSideDrawerMenu();
      this.navigationMenuService.onCloseNavigationDrawerClick.next(true);
      this.searchCreditByIdForm.reset();
    } else {
      this.notificationsService.showLocalizedSnackbarNotification({
        notificationText: 'credits.enterSearchCriteriaMessage',
      });
    }
  }

  findCredit(event) {
    event.preventDefault();
    this.creditList = [];
    this.isData = true;
    const params = this.searchCreditForm.value;

    Utils.checkObjectForEmptyValuesRecursive(params);
    Utils.trimValues(params);

    this.searchCreditForm.patchValue(params);

    const isSearchCriteria = Utils.checkIfObjectIsEmpty(params);

    if (isSearchCriteria && this.searchCreditForm.valid) {
      this.creditService
        .searchCredit(params)
        .pipe(
          catchError((error: any, caught: Observable<any>) => {
            console.log(error);
            if (error.error[0].message) {
              this.notificationsService.showLocalizedErrorMessage({
                notificationText: error.error[0].message,
              });
            }
            return throwError(() => error);;
          })
        )
        .subscribe(result => {
          if (result.length > 0) {
            this.isData = true;
            this.creditList = result;
          } else {
            this.isData = false;
          }
        });
    } else if (!isSearchCriteria) {
      this.notificationsService.showLocalizedSnackbarNotification({
        notificationText: 'credits.enterSearchCriteriaMessage',
      });
    } else if (!this.searchCreditForm.valid) {
      // this.notificationsService.showLocalizedSnackbarNotification({
      //   notificationText: 'forms.formSavedIsInvalid',
      // });
    }
  }

  resetCreditList() {
    this.creditList = [];
  }

  closeSideDrawerMenu() {
    // this.searchEvent.emit(false); //should remain opened for now
  }

  ngOnDestroy() {
    this._unsubscribe.next();
    this._unsubscribe.complete();
  }
}
