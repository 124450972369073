import { FilterValueListService, FilterValue } from './types';

export class FilterValueLocalService implements FilterValueListService {
  values: FilterValue[];

  constructor(values: FilterValue[]) {
    this.values = values;
  }

  getFilterValues$(): FilterValue[] {
    return this.values;
  }
}
