import { Credit } from './credit';
import { Administrator } from './administrator';

export enum ConversationTypes {
  INCOMING_CALL = 'INCOMING_CALL',
  OUTGOING_CALL_CONTACTED = 'OUTGOING_CALL_CONTACTED',
  OUTGOING_CALL_NOT_CONTACTED = 'OUTGOING_CALL_NOT_CONTACTED',
}

export interface ConversationType {
  id?: number;
  name?: ConversationTypes;
}

export interface ConversationBase {
  id?: number;
  contacted?: boolean;
  phone?: string;
  credit?: Credit;
  notes?: string;
  operator?: Administrator;
  promisedPaymentAmount?: number;
  type?: ConversationType;
}

export interface Conversation extends ConversationBase {
  nextConversationTime?: string;
  promisedPaymentTime?: string;
  time?: string;
}

export interface DeserializedConversation extends ConversationBase {
  nextConversationTime?: Date;
  promisedPaymentTime?: Date;
  time?: Date;
}

export interface EditConversationBase {
  contacted: boolean;
  phone: string;
  notes: string;
  promisedPaymentAmount: number;
  type: ConversationType;
}

export interface EditConversation extends EditConversationBase {
  nextConversationTime: string;
  promisedPaymentTime: string;
}

export interface DeserializedEditConversation extends EditConversationBase {
  nextConversationTime: Date;
  promisedPaymentTime: Date;
}
export interface NoteState {
  creditId?: number;
  note?: Conversation;
}
