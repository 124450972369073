   <ngx-graph
   [view]="view"
   [links]="links"
   [nodes]="nodes"
   [enableZoom]="false"
   [autoZoom]="true"
   [draggingEnabled]="true"
   [layout]="layout"
   >
   
   <ng-template #defsTemplate>
     <svg:marker id="arrow" viewBox="0 -5 10 10" refX="8" refY="0" markerWidth="4" markerHeight="4" orient="auto">
     <svg:path d="M0,-10L100,100L100,5" class="arrow-head" />
    </svg:marker>
  </ng-template>
  
  <ng-template #nodeTemplate let-node>
    <svg:g class="node" width="150" height="160">
      <svg:foreignObject width="150" height="160">
        <xhtml:div class="cardContainer">
          <div (dblclick)="emitFilterGraph(node)" [matTooltip]="('identityReports.graph.showRelations' | translate)">
            <itfg-graph-node
            [node]="node"
            [clientId]="clientId">
          </itfg-graph-node>
        </div>
      </xhtml:div>
    </svg:foreignObject>
  </svg:g>
</ng-template>

<!-- style.dominant-baseline sets label to the top/bottom of the edge -->
<ng-template #linkTemplate let-link>
  <svg:g class="edge">
    <svg:path class="line" stroke-width="2" ></svg:path>
    <svg:text class="edge-label" text-anchor="middle">
      <textPath
      [ngClass]="link.label"
      class="text-path"
      [attr.href]="'#' + link.id"
      [style.dominant-baseline]="link.label === 'HAS_CONTACT' ? 'text-after-edge' : 'text-before-edge'" 
      startOffset="50%"
      >
      {{ link.label }}
    </textPath>
  </svg:text>
</svg:g>
</ng-template>
</ngx-graph>