import { Edge, Node } from '@swimlane/ngx-graph';

export class GraphRecords {

  private records = []
  private summary = {}
  private _nodes: Node[] = [];
  private _links: Edge[] = [];
  private elementIds: Set<string> = new Set();

  constructor(partial: Partial<GraphRecords>) {
    Object.assign(this, partial);

    if (this.records) {
      for (let record of this.records) {
        for (let field of record._fields) {
          for (let segment of field.segments) {
            const { start, end, relationship } = segment;
            this.addNode(start);
            this.addNode(end);
            this.addRelationship(relationship);
          }
        }
      }
    }
  }

  get nodes() {
    return this._nodes;
  }

  get links() {
    return this._links;
  }

  addNode(node) {
    if (!node) {
      return
    }
    if (this.elementIds.has(node.elementId)) {
      return
    }
    this._nodes.push({
      id: node.elementId,
      label: node.labels[0],
      data: node.properties,
    });
    this.elementIds.add(node.elementId);
  }

  addRelationship(rel) {
    if (!rel) {
      return
    }
    if (this.elementIds.has(rel.elementId)) {
      return
    }
    this._links.push({
      source: rel.startNodeElementId,
      target: rel.endNodeElementId,
      label: rel.type,
      data: {
        // linkText: rel.type
      }
    });
    this.elementIds.add(rel.elementId);
  }
}

export const mockGraphData: Partial<GraphRecords> = {
  nodes: [{
    id: "1",
    label: '1'
  },
  {
    id: "1",
    label: '1'
  },
  ],
  links: [{
    source: "1",
    target: "2"
  }]
}