import { Injectable } from '@angular/core';
import {RequestService} from './request.service';
import {Observable, Subject} from 'rxjs';
import {LoyaltyPointsFlow} from '../types/loyalty-points-flow';

@Injectable()
export class LoyaltyPointsService {

  constructor(public request: RequestService) {}

  public loyaltyPointsFlowSubject: Subject<LoyaltyPointsFlow> = new Subject<LoyaltyPointsFlow>();

  getLoyaltyAvailableNumber$(userId: number | string): Observable<number> {
    return this.request.get(['users', userId, 'loyalty', 'available', 'number']);
  }
  getLoyaltyPointsFlow$(userId: number | string): Observable<LoyaltyPointsFlow> {
    return this.request.get(['users', userId, 'loyalty', 'points-flow']);
  }
  deleteLoyaltyPoints$(userId: number | string, id: number ): Observable<any> {
    return this.request.delete(['users', userId, 'loyalty', 'points', id]);
  }
  addLoyaltyPoints$(userId: number | string, expiryDate: string, number: number): Observable<any> {
    return this.request.post(['users', userId, 'loyalty', 'points'], {body: {expiryDate, number}});
  }
}
