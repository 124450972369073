import { Component, OnInit } from '@angular/core';
import { TagService } from '../../../../core/services/tag.service';
import { Tag } from '../../../../core/types/tag';
import { Page } from '../../../../core/types/page';
import { ItfgDataTableColumn } from '@app/core/components/data-table/types/data-table.column';
import { DialogProviderService } from '@app/core/services/dialog-provider.service';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { TagEditComponent } from '../tag-edit/tag-edit.component';
import { filter, mergeMap } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorService } from '../../../../core/services/error.service';
import { SearchOptions } from '../.././../../core/types/search-options';

@Component({
  selector: 'itfg-tag-list',
  templateUrl: './tag-list.component.html',
  styleUrls: ['./tag-list.component.scss'],
})
export class TagListComponent implements OnInit {
  public tagListData: Page<Tag>;
  public columns: ItfgDataTableColumn[];

  constructor(
    private readonly tagService: TagService,
    private readonly translateService: TranslateService,
    private readonly dialog: MatDialog,
    private readonly dialogService: DialogProviderService,
    private readonly errorService: ErrorService
  ) {}

  ngOnInit() {
    this.initColumns();
    this.loadTagList();
  }

  initColumns() {
    this.translateService
      .get([
        'global.view',
        'tags.description',
        'tags.color',
        'global.actions',
        'global.code',
      ])
      .subscribe((labels: string) => {
        this.columns = [
          {
            name: 'name',
            label: labels['global.view'],
            width: { max: 500 },
          },
          {
            name: 'code',
            label: labels['global.code'],
            width: { min: 200 },
          },
          {
            name: 'description',
            label: labels['tags.description'],
            width: { min: 450 },
          },
          {
            name: 'actions',
            label: labels['global.actions'],
            width: 170,
          },
        ];
      });
  }

  loadTagList() {
    const tagList$ = this.tagService.getTagList$(
      new SearchOptions({ pageSize: 6000 }).getDTO()
    );
    tagList$.subscribe((tagListData: Page<Tag>) => {
      this.tagListData = tagListData;
    });
  }

  openTagEdit(tag?: Tag) {
    this.dialog
      .open(TagEditComponent, {
        data: {
          tag,
        },
        minWidth: 350,
        minHeight: 400,
        autoFocus: false,
      })
      .afterClosed()
      .pipe(
        filter(res => !!res),
        mergeMap(res => {
          const $obs = res.id
            ? this.tagService.editTag$(res)
            : this.tagService.createTag$(res);
          return $obs;
        })
      )
      .subscribe(
        res => {
          this.loadTagList();
        },
        (error: HttpErrorResponse) =>
          this.errorService.showSnackbarErrorMessage(error)
      );
  }

  deleteTag(tag: Tag) {
    this.dialogService
      .openConfirm({
        message: this.translateService.instant(
          'tags.deleteTagConfirmationMessage',
          { tagName: tag.name }
        ),
        cancelButton: this.translateService.instant('global.cancel'),
        acceptButton: this.translateService.instant('global.confirm'),
      })
      .afterClosed()
      .pipe(
        filter(accept => !!accept),
        mergeMap(() => this.tagService.deleteTagById$(tag.id))
      )
      .subscribe(
        res => {
          this.loadTagList();
        },
        (error: HttpErrorResponse) =>
          this.errorService.showSnackbarErrorMessage(error)
      );
  }
}
