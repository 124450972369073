<div [matMenuTriggerFor]="menu"
     #menuTrigger="matMenuTrigger"
     class="matTabLabelHeader"
     (mouseenter)="mouseEnter(menuTrigger)"
     (mouseleave)="mouseLeave(menuTrigger)">
  <ng-content select="[trigger]"></ng-content>
</div>
<mat-menu #menu="matMenu"
          [hasBackdrop]="false">
  <div (mouseenter)="mouseEnter(menuTrigger)"
       (mouseleave)="mouseLeave(menuTrigger)">
    <ng-content select="[content]"></ng-content>
  </div>
</mat-menu>
