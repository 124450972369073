<div
  *ngIf="isReport && reportData?.data !== null"
  layout="row"
  layout-align="start stretch"
>
  <div flex layout="row" layout-align="start stretch">
    <mat-card appearance="outlined" flex>
      <div flex layout="row" layout-align="space-between center">
        <mat-card-title class="text-center">
          <span *ngIf="!isClientDataMatch">{{ 'identityReports.clientDataDoNotMatch' | translate }} {{requestTime | date}}</span>
          <span *ngIf="isClientDataMatch">{{ 'mi.miReport' | translate }} {{requestTime | date}}</span>
        </mat-card-title>
        <button
          *ngIf="!isClientDataMatch"
          mat-icon-button
          matTooltip="{{'global.name' | translate}}"
          matTooltipPosition="above"
          class="right-top-margin-16"
          (click)="scrollToClient()"
        >
          <mat-icon
            matTooltip="{{ 'identityReports.clientDataDoNotMatch' | translate }}"
            matTooltipPosition="above"
            [ngStyle]="{ color: 'red' }"
          >
            warning
          </mat-icon>
        </button>
      </div>
      <mat-card-content>
        <div flex layout="row">
          <div flex="20" layout="column" layout-align="center center" *ngxPermissionsOnly="'USER_ID_IMAGE_READ'"
               class="id-front-container">
            <img
              [src]="'data:image/jpg;base64,' + clientReportData?.Picture"
              alt="clientImage"
              class="max-width-85 id-front-image"
            />
            <div class="top-right"
                  (mouseenter)="mouseEnter(menuTrigger)"
                  (mouseleave)="mouseLeave(menuTrigger)">
              <itfg-id-card-face-verification [civilId]="reportData?.civilId" [loadedMiReportId]="reportData?.id" [loadedIdCardDocumentId]="selectedIdCardFront?.id"></itfg-id-card-face-verification>
            </div>
          </div>
          <mat-card appearance="outlined" flex="40">
            <mat-card-content>
              <div flex="100" layout="row" layout-align="center center" class="top-padding-16">
                <mat-form-field flex>
                  <input
                    matInput
                    readonly
                    type="text"
                    [value]="clientReportData?.DocumentStatusReason ? clientReportData?.DocumentActualStatus  + ' - ' + clientReportData?.DocumentStatusReason : clientReportData?.DocumentActualStatus || ''"
                    placeholder="{{ 'identityReports.idCardVerified' | translate }}"
                    flex="100"
                    [style.color]="
                clientReportData?.DocumentActualStatus === IDCardVerifiedType.VALID
                  ? 'green'
                  : 'red'
              "
                  />
      <mat-label>{{ 'identityReports.idCardVerified' | translate }}</mat-label>
                </mat-form-field>
              </div>
              <div flex="100" layout="row" layout-align="center center" class="column-gap-10">
                <mat-form-field flex="33">
                  <input
                    matInput
                    readonly
                    type="text"
                    [value]="clientReportData?.PersonNames?.FamilyName || ''"
                    placeholder="{{ 'identityReports.lastName' | translate }}"
                    flex="100"
                    matTooltip="{{
                  clientReportData?.PersonNames?.FamilyName || ''
                }}"
                    matTooltipPosition="above"
                  />
      <mat-label>{{ 'identityReports.lastName' | translate }}</mat-label>
                </mat-form-field>
                <mat-form-field flex="33">
                  <input
                    matInput
                    readonly
                    type="text"
                    [value]="clientReportData?.PersonNames?.FirstName || ''"
                    placeholder="{{ 'identityReports.firstName' | translate }}"
                    flex="100"
                    matTooltip="{{
                  clientReportData?.PersonNames?.FirstName || ''
                }}"
                    matTooltipPosition="above"
                  />
      <mat-label>{{ 'identityReports.firstName' | translate }}</mat-label>
                </mat-form-field>
                <mat-form-field flex="33">
                  <input
                    matInput
                    readonly
                    type="text"
                    [value]="clientReportData?.PersonNames?.Surname || ''"
                    placeholder="{{ 'identityReports.middleName' | translate }}"
                    flex="100"
                    matTooltip="{{
                  clientReportData?.PersonNames?.Surname || ''
                }}"
                    matTooltipPosition="above"
                  />
      <mat-label>{{ 'identityReports.middleName' | translate }}</mat-label>
                </mat-form-field>

              </div>
              <div
                flex="100"
                layout="row"
                class="column-gap-10"
                layout-align="space-between center"
              >
                <mat-form-field flex="33">
                  <input
                    matInput
                    readonly
                    type="text"
                    [value]="
                  civilId
                "
                    placeholder="{{ 'global.civilId' | translate }}"
                  />
      <mat-label>{{ 'global.civilId' | translate }}</mat-label>
                </mat-form-field>
                <mat-form-field flex="33">
                  <input
                    matInput
                    readonly
                    type="text"
                    [value]="
                  ('global.' + civilIdGender + 'Short' | translate) +
                  ' / ' +
                  (civilIdAge + ' ' + ('global.ageShort' | translate))
                "
                    placeholder="{{ 'global.genderAndAge' | translate }}"
                  />
      <mat-label>{{ 'global.genderAndAge' | translate }}</mat-label>
                </mat-form-field>
                <mat-form-field
                  flex="33"
                  >
                  <input
                    matInput
                    readonly
                    type="text"
                    [style.color]="clientReportData?.NationalityList?.length > 1 ? 'red' : ''"
                    [value]="
                  (clientReportData?.NationalityList | displayNationalityList)
                "
                    placeholder="{{ 'global.citizenship' | translate }}"
                    matTooltip="{{
                  (clientReportData?.NationalityList | displayNationalityList)
                }}"
                    matTooltipPosition="above"
                  />
                </mat-form-field>
              </div>
              <div flex="100" layout="row" layout-align="center center"   class="column-gap-10">
                <mat-form-field flex="33">
                  <input
                    matInput
                    readonly
                    type="text"
                    [value]="
                  (clientReportData?.BirthDate | date) || (clientReportData?.DataForeignCitizen?.BirthDate | date)
                "
                    placeholder="{{ 'global.dateOfBirth' | translate }}"
                  />
      <mat-label>{{ 'global.dateOfBirth' | translate }}</mat-label>
                </mat-form-field>
                <mat-form-field flex="33">
                  <input
                    matInput
                    readonly
                    type="text"
                    [value]="
                  clientReportData?.ValidDate | date
                "
                    placeholder="{{ 'identityReports.idCardValidityDate' | translate }}"
                  />
      <mat-label>{{ 'identityReports.idCardValidityDate' | translate }}</mat-label>
                </mat-form-field>
                <mat-form-field flex="33">
                  <input
                    matInput
                    readonly
                    type="text"
                    [value]="
                  clientReportData?.IdentityDocumentNumber
                "
                    placeholder="{{ 'identityReports.idCardNumber' | translate }}"
                  />
      <mat-label>{{ 'identityReports.idCardNumber' | translate }}</mat-label>
                </mat-form-field>
              </div>
            </mat-card-content>
          </mat-card>
          <mat-card appearance="outlined" flex="40">
            <mat-card-content>
              <div flex="100" layout="row" layout-align="center center" class="top-padding-16 column-gap-10">
                <mat-form-field flex="30">
                  <input
                    matInput
                    readonly
                    type="text"
                    [value]="
                 clientReportData?.PermanentAddress?.SettlementName || ''
                "
                    placeholder="{{ 'identityReports.idCardAddress.city.name' | translate }}"
                    flex="100"
                    matTooltip="{{
                  clientReportData?.PermanentAddress?.SettlementName || ''
                }}"
                    matTooltipPosition="above"
                  />
      <mat-label>{{ 'identityReports.idCardAddress.city.name' | translate }}</mat-label>
                </mat-form-field>
                <mat-form-field flex="70">
                  <input
                    matInput
                    readonly
                    type="text"
                    [value]="
                  clientReportData?.address || ''
                "
                    placeholder="{{ 'identityReports.idCardAddress.address' | translate }}"
                    flex="100"
                    matTooltip="{{
                  clientReportData?.address || ''
                }}"
                    matTooltipPosition="above"
                  />
      <mat-label>{{ 'identityReports.idCardAddress.address' | translate }}</mat-label>
                </mat-form-field>
              </div>
              <div flex="100" layout="row" layout-align="center center">
                <mat-form-field flex>
                  <input
                    matInput
                    readonly
                    type="text"
                    [value]="
                  clientReportData?.BirthPlace?.TerritorialUnitName || clientReportData?.BirthPlace?.CountryName || ''
                "
                    placeholder="{{ 'mi.BirthPlace' | translate }}"
                    flex="100"
                    matTooltip="{{
                  clientReportData?.BirthPlace?.TerritorialUnitName || ''
                }}"
                    matTooltipPosition="above"
                  />
      <mat-label>{{ 'mi.BirthPlace' | translate }}</mat-label>
                </mat-form-field>
              </div>
              <div
                layout="row"
                flex-gt-md="100"
                flex-gt-sm="50"
                flex-gt-xs="50"
                flex="100"
                class="column-gap-10"
                layout-align="space-between center"
              >

                <mat-form-field flex="50">
                  <input
                    matInput
                    readonly
                    type="text"
                    [value]="
                  clientReportData?.Height
                    ? clientReportData?.Height + ('global.cmShort' | translate)
                    : ''
                "
                    placeholder="{{ 'identityReports.height' | translate }}"
                  />
      <mat-label>{{ 'identityReports.height' | translate }}</mat-label>
                </mat-form-field>
                <mat-form-field flex="50">
                  <input
                    matInput
                    readonly
                    type="text"
                    [value]="clientReportData?.EyesColor || ''"
                    placeholder="{{ 'identityReports.eyes.eyeColor' | translate }}"
                  />
      <mat-label>{{ 'identityReports.eyes.eyeColor' | translate }}</mat-label>
                </mat-form-field>
              </div>
              <div flex="100" layout="row" layout-align="center center" class="column-gap-10">
                <mat-form-field flex="50">
                  <input
                    matInput
                    readonly
                    type="text"
                    [value]="clientReportData?.IssuerName || ''"
                    placeholder="{{ 'identityReports.idCardIssuer.name' | translate }}"
                    flex="100"
                  />
      <mat-label>{{ 'identityReports.idCardIssuer.name' | translate }}</mat-label>
                </mat-form-field>
                <mat-form-field flex="50">
                  <input
                    matInput
                    readonly
                    type="text"
                    [value]="(clientReportData?.IssueDate | date) || ''"
                    placeholder="{{ 'identityReports.idCardIssuedDate' | translate }}"
                    flex="100"
                  />
      <mat-label>{{ 'identityReports.idCardIssuedDate' | translate }}</mat-label>
                </mat-form-field>
              </div>
            </mat-card-content>
          </mat-card>
        </div>

        <div flex layout="row"
             layout-align="end end">
          <div
            flex="20"
            *ngIf="dataForeignCitizen?.PN"
            layout="row"

            layout-align="center center"
          >
            <mat-form-field flex>
              <input
                matInput
                readonly
                type="text"
                [value]="dataForeignCitizen?.PIN || ''"
                placeholder="{{ 'mi.PIN' | translate }}"
                flex="100"
                matTooltip="{{ dataForeignCitizen?.PIN || '' }}"
                matTooltipPosition="above"
              />
      <mat-label>{{ 'mi.PIN' | translate }}</mat-label>
            </mat-form-field>
          </div>
          <div
            flex="20"
            *ngIf="dataForeignCitizen?.PN"
            layout="row"

            layout-align="center center"
          >
            <mat-form-field flex>
              <input
                matInput
                readonly
                type="text"
                [value]="dataForeignCitizen?.PN || ''"
                placeholder="{{ 'mi.PN' | translate }}"
                flex="100"
                matTooltip="{{ dataForeignCitizen?.PN || '' }}"
                matTooltipPosition="above"
              />
      <mat-label>{{ 'mi.PN' | translate }}</mat-label>
            </mat-form-field>
          </div>
          <div
            flex="40"
            *ngIf="dataForeignCitizen?.PN"
            layout="row"

            layout-align="center center"
          >
            <mat-form-field flex>
              <input
                matInput
                readonly
                type="text"
                [value]="clientReportData?.RPTypeofPermit || ''"
                placeholder="{{ 'mi.RPTypeofPermit' | translate }}"
                flex="100"
                matTooltip="{{ clientReportData?.RPTypeofPermit || '' }}"
                matTooltipPosition="above"
              />
      <mat-label>{{ 'mi.RPTypeofPermit' | translate }}</mat-label>
            </mat-form-field>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>

