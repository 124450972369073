import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewContainerRef,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { StyleConfigMap } from '@app/config/credit-config';
import { DialogProviderService } from '@app/core/services/dialog-provider.service';
import { NotificationService } from '@app/core/services/notification.service';
import { PaymentPromiseService } from '@app/core/services/payment-promise.service';
import { Credit } from '@app/core/types/credit';
import { CreditStatusNames } from '@app/core/types/credit-status';
import { Page } from '@app/core/types/page';
import {
  PaymentPromise,
  PAYMENT_PROMISE_STYLE_MAP,
} from '@app/core/types/payment-promise';
import { SearchOperations } from '@app/core/types/search-criterium';
import { SearchDirection, SearchOptions } from '@app/core/types/search-options';
import { PaymentPromiseEditComponent } from '@app/main/credit/credit-edit/credit-communication/payment-promise-edit/payment-promise-edit.component';
import { PaymentPromiseDetailsComponent } from '@app/main/payment-promise/payment-promise-details/payment-promise-details.component';
import { TranslateService } from '@ngx-translate/core';
import { Subject, filter, takeUntil } from 'rxjs';

@Component({
  selector: 'itfg-payment-promises',
  templateUrl: './payment-promises.component.html',
  styleUrls: ['./payment-promises.component.scss'],
})
export class PaymentPromisesComponent implements OnInit, OnDestroy, OnChanges {
  @Input() openedCredit: Credit;

  paymentPromises: PaymentPromise[];
  promiseStatusStyleMap: StyleConfigMap;
  activeCreditStatuses: string[] = [
    CreditStatusNames.OVERDUE,
    CreditStatusNames.REGULAR,
  ];
  canAddPaymentPromises: boolean = false;
  private searchOptions: SearchOptions;

  private _unsubscribe = new Subject<void>();

  constructor(
    private dialog: MatDialog,
    private translate: TranslateService,
    private notifications: NotificationService,
    private paymentPromiseService: PaymentPromiseService,
    private viewContainerRef: ViewContainerRef,
    private dialogProviderService: DialogProviderService
  ) {}

  ngOnInit() {
    this.paymentPromiseService.onCreditPaymentPromiseDataChange
      .pipe(takeUntil(this._unsubscribe))
      .subscribe(data => {
        this.getPromises();
      });

    this.promiseStatusStyleMap = PAYMENT_PROMISE_STYLE_MAP;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.openedCredit && changes.openedCredit.currentValue) {
      this.searchOptions = new SearchOptions({
        page: 0,
        pageSize: 3,
        column: 'createdAt',
        direction: SearchDirection.DESCENDING,
        criteria: [
          {
            key: 'active',
            operation: SearchOperations.EQUALS,
            value: 1,
          },
          {
            key: 'credit.id',
            operation: SearchOperations.EQUALS,
            value: this.openedCredit.id,
          },
        ],
      });

      this.canAddPaymentPromises = this.activeCreditStatuses.includes(this.openedCredit.creditStatus.name);
      this.getPromises();
    }
  }

  deactivatePromise(promise: PaymentPromise) {
    promise.active = false;

    this.dialogProviderService
      .openConfirm({
        message: this.translate.instant(
          'communication.deactivatePaymentPromiseMessage'
        ),
        disableClose: false,
        viewContainerRef: this.viewContainerRef,
        title: this.translate.instant('global.deactivate'),
        cancelButton: this.translate.instant('global.cancel'),
        acceptButton: this.translate.instant('global.confirm'),
      })
      .afterClosed()
      .subscribe((accept: boolean) => {
        if (accept) {
          this.paymentPromiseService
            .editPaymentPromise$(promise)
            .pipe(filter(success => !!success))
            .subscribe(() => {
              this.notifications.showLocalizedSuccessMessage({
                notificationText:
                  'communication.deactivatePaymentPromiseSuccessMessage',
              });

              this.paymentPromiseService.onCreditPaymentPromiseDataChange.next(
                true
              );
            });
        }
      });
  }

  openCreatePromiseDialog() {
    return this.dialog.open(PaymentPromiseEditComponent, {
      data: {
        credit: this.openedCredit,
      },
    });
  }

  openPromiseEditDialog(promise: PaymentPromise) {
    return this.dialog
      .open(PaymentPromiseDetailsComponent, {
        data: {
          promise,
          credit: this.openedCredit,
        },
        minWidth: '400px',
      })
      .afterClosed()
      .pipe(filter(success => !!success))
      .subscribe((success: boolean) => {
        this.notifications.showLocalizedSuccessMessage({
          notificationText: 'communication.paymentPromiseSuccessfullyEdited',
        });

        this.paymentPromiseService.onCreditPaymentPromiseDataChange.next(true);
      });
  }

  getPromises() {
    this.paymentPromiseService
      .getPaymentPromiseList$(this.searchOptions.getDTO())
      .subscribe({
        next: (paymentPromisePage: Page<PaymentPromise>) => {
          this.paymentPromises = paymentPromisePage.content || [];

          this.paymentPromiseService.setPromises$(paymentPromisePage);
        },
        error: error => {
          console.error('Error fetching payment promises:', error);
        },
      });
  }

  ngOnDestroy() {
    this._unsubscribe.next();
    this._unsubscribe.complete();
  }
}
