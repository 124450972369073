import { D3ForceDirectedLayout } from "@swimlane/ngx-graph";
import { forceLink, forceSimulation, forceManyBody, forceCenter, forceCollide, forceY, forceX } from 'd3-force';

// const cache = {};

// /**
//  * Generates a short id.
//  *
//  */
// export function id(): string {
//   let newId = ('0000' + ((Math.random() * Math.pow(36, 4)) << 0).toString(36)).slice(-4);

//   newId = `a${newId}`;

//   // ensure not already used
//   if (!cache[newId]) {
//     cache[newId] = true;
//     return newId;
//   }

//   return id();
// }

export class ItfgGraphLayout extends D3ForceDirectedLayout {
  constructor() {
    super()
    this.defaultSettings.force = forceSimulation<any>()
      .force("center", forceCenter((window.screen.width + 100) / 2, window.screen.height / 2))
      .force('charge', forceManyBody().strength(-1500))
      .tick(500)
      .force('collide', forceCollide().radius(80).iterations(2))
      .force('y', forceY())
      .stop();

    this.defaultSettings.forceLink =
      forceLink<any, any>()
        .id(node => node.id)
        .distance(() => 240)
  }

  // onDrag(draggingNode, $event: MouseEvent): void {
  //   super.onDrag(draggingNode, $event);
  //   this.settings.force.stop()
  // }

  // d3GraphToOutputGraph(d3Graph: D3Graph): Graph {
  //   this.outputGraph.nodes = this.d3Graph.nodes.map((node: MergedNode) => ({
  //     ...node,
  //     id: node.id || id(),
  //     position: {
  //       x: node.x,
  //       y: node.y
  //     },
  //     dimension: {
  //       width: (node.dimension && node.dimension.width) || 20,
  //       height: (node.dimension && node.dimension.height) || 20
  //     },
  //     transform: `translate(${node.x - ((node.dimension && node.dimension.width) || 20) / 2 || 0}, ${node.y - ((node.dimension && node.dimension.height) || 20) / 2 || 0
  //       })`
  //   }));

  //   this.outputGraph.edges = this.d3Graph.edges.map(edge => ({
  //     ...edge,
  //     source: toD3Node(edge.source).id,
  //     target: toD3Node(edge.target).id,
  //     points: [
  //       {
  //         x: toD3Node(edge.source).x,
  //         y: toD3Node(edge.source).y
  //       },
  //       {
  //         x: toD3Node(edge.target).x,
  //         y: toD3Node(edge.target).y
  //       }
  //     ]
  //   }));

  //   this.outputGraph.edgeLabels = this.outputGraph.edges;
  //   return this.outputGraph;
  // }
}