import { map } from 'rxjs/operators';
import { Pipe, PipeTransform } from '@angular/core';
import { ITFGAccount } from '../../core/types/account';
import { Observable } from 'rxjs';
import { TranslateService } from '../../../../node_modules/@ngx-translate/core';
import { FormatIbanPipe } from '../../main/credit/pipes/format-iban.pipe';
import { IbanUtilService } from '../../core/services/iban-util.service';
import { ToCustomItfgCurrencyPipe } from '../../main/credit/pipes/to-custom-itfg-currency.pipe';

@Pipe({
  name: 'paymentAccountsDisplay$',
})
export class PaymentAccountsDisplayPipe implements PipeTransform {
  constructor(
    private translateService: TranslateService,
    private formatIban: FormatIbanPipe,
    private currencyPipe: ToCustomItfgCurrencyPipe,
    private ibanUtilService: IbanUtilService
  ) {}

  transform(account: ITFGAccount, args?: any): Observable<string> {
    return this.translateService.get('accounts.balance').pipe(
      map(balance => {
        return `${this.formatIban.transform(
          account.code
        )}, ${balance}: ${this.currencyPipe.transform(account.balance)}`;
      })
    );
  }
}
