import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { FilterValueListService, FilterValue } from '../filter/types';
import { Observable } from 'rxjs';
import { CreditService } from '../../core/services/credit.service';
import { CreditStatus } from '../../core/types/credit-status';
import { TranslateService } from '@ngx-translate/core';
import { CreditStatusToTranslationKeyPipe } from '../credit/pipes/credit-status-to-translation-key.pipe';

@Injectable()
export class CreditStatusValueListService implements FilterValueListService {
  constructor(
    private credits: CreditService,
    private translateService: TranslateService
  ) {}

  getFilterValues$(): Observable<FilterValue[]> {
    const creditStatusTranslationPipe: CreditStatusToTranslationKeyPipe = new CreditStatusToTranslationKeyPipe();
    return this.credits.getCreditStatusList().pipe(
      map((creditStatusList: CreditStatus[]): FilterValue[] => {
        creditStatusList = creditStatusList.filter(
          status => status.reason === null
        );
        return creditStatusList.map(
          (creditStatus: CreditStatus): FilterValue => {
            return {
              value: creditStatus.name,
              displayText: this.translateService
                .instant(creditStatusTranslationPipe.transform(creditStatus))
                .toUpperCase(),
            };
          }
        );
      })
    );
  }
}
