import { FilterValueListService, FilterValue } from '../filter/types';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TransactionType } from '../../../app/core/types/transaction';
import { TranslateService } from '@ngx-translate/core';
import { TransactionService } from '../../../app/core/services/transaction.service';

@Injectable()
export class TransactionTypeValueListService implements FilterValueListService {
  constructor(
    private transaction: TransactionService,
    private translate: TranslateService
  ) {}

  getFilterValues$(): Observable<FilterValue[]> {
    return this.transaction.getTransactionTypes$().pipe(
      map((transactionTypeList: TransactionType[]): FilterValue[] => {
        return transactionTypeList.map(
          (transactionType: TransactionType): FilterValue => {
            return {
              value: transactionType.id,
              displayText: this.translate.instant(
                'transaction.type.' + transactionType.name
              ),
            };
          }
        );
      })
    );
  }
}
