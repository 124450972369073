<a mat-icon-button flex [matMenuTriggerFor]="clientActions">
  <mat-icon>more_vert</mat-icon>
</a>

<mat-menu #clientActions="matMenu">
  <button
    *ngIf="config.showCreateNewCredit && clientId"
    mat-menu-item
    routerLink="/credits/new/fill-credit"
    [queryParams]="{ client: clientId }"
  >
    <mat-icon>add</mat-icon>
    <span [translate]="'credits.newCredit'">New Credit</span>
  </button>

  <a mat-menu-item [matMenuTriggerFor]="reports">
    <mat-icon>description</mat-icon>
    <span [translate]="'global.report'">Queries</span>
  </a>

  <mat-menu #reports="matMenu">
    <a
      mat-menu-item
      routerLink="/clients/{{ clientId }}/communication"
      *ngIf="!isInputClient && config.showCommunication && clientId"
    >
      <mat-icon>message</mat-icon>
      <span [translate]="'global.communication'">Client Communication</span>
    </a>

    <a
      *ngIf="config.showClaims && clientId"
      mat-menu-item
      routerLink="/clients/{{ clientId }}/claims"
    >
      <mat-icon>assignment_late</mat-icon>
      <span [translate]="'clients.claims'">Client Claims</span>
    </a>

    <a
      *ngIf="!isInputClient && config.showCreditsHistory && clientId"
      mat-menu-item
      routerLink="/clients/{{ clientId }}/credits"
    >
      <mat-icon>perm_contact_calendar</mat-icon>
      <span [translate]="'clients.clientCreditsHistory'">Credits History</span>
    </a>

    <a
      *ngIf="!isInputClient && config.showCCR && clientId"
      mat-menu-item
      routerLink="/clients/{{ clientId }}/ccr"
    >
      <mat-icon>folder</mat-icon>
      <span [translate]="'ccr.viewCcr'">View CCR</span>
    </a>

    <a
      *ngIf="!isInputClient && config.showNssiReports && clientId"
      mat-menu-item
      routerLink="/clients/{{ clientId }}/nssi"
    >
      <mat-icon>folder</mat-icon>
      <span [translate]="'nssi.reports'">View NSSI Reports</span>
    </a>

    <a
      *ngIf="!isInputClient && config.showMiReports && clientId"
      mat-menu-item
      routerLink="/clients/{{ clientId }}/mi"
    >
      <mat-icon>folder</mat-icon>
      <span [translate]="'mi.reports'">View MI Reports</span>
    </a>

    <a
      *ngIf="!isInputClient && config.showRelatedPeople && clientId"
      mat-menu-item
      routerLink="/clients/{{ clientId }}/related-people"
    >
      <mat-icon>people</mat-icon>
      <span [translate]="'clients.relatedPeople'">View related people</span>
    </a>

    <a
      mat-menu-item
      routerLink="/clients/{{ clientId }}/history"
      *ngIf="config.showClientHistory && clientId"
    >
      <mat-icon>history</mat-icon>
      <span [translate]="'global.viewHistory'">View client history</span>
    </a></mat-menu
  >

  <!-- TODO: Review brand code logic -->

  <button
    mat-menu-item
    (click)="onTempPassSmsClick()"
    [disabled]="
      isNewItem ||
      !data?.mobileConfirmed ||
      data?.brand.code === 'CD' ||
      (['SMS_SEND'] | isUnauthorized | async)
    "
  >
    <mat-icon>vpn_key</mat-icon>
    <span [translate]="'clients.temporaryPasswordSms'">Temp Pass SMS</span>
  </button>

  <button
    mat-menu-item
    (click)="onTempPassEmailClick()"
    [disabled]="
      isNewItem ||
      !data?.emailConfirmed ||
      data?.brand.code === 'CD' ||
      (['EMAIL_SEND'] | isUnauthorized | async)
    "
  >
    <mat-icon>vpn_key</mat-icon>
    <span [translate]="'clients.temporaryPasswordMail'">Temp Pass Mail</span>
  </button>
</mat-menu>
