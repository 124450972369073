import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'toCountryCode'
})
export class CountryCodePipe implements PipeTransform {

  constructor(private translateService: TranslateService) {}

  transform(countryName: string): string | null {
    let countryCode = null;
    const countries = this.translateService.instant('countries');

    for (const code in countries) {
      if (countries[code] === countryName) {
        countryCode = code;
        break;
      }
    }
    
    return countryCode;
  }
}