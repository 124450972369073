import {
  throwError as observableThrowError,
  Observable,
  Subscription,
  forkJoin as observableForkJoin,
  Subject,
} from 'rxjs';

import { filter, map, catchError, finalize, takeUntil } from 'rxjs/operators';

import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NotificationService } from '../../../../../core/services/notification.service';
import { sendSmsDTO, SmsService } from '../../../../../core/services/sms.service';
import { TemplatesService } from '../../../../../core/services/templates.service';
import { CloseMessageAction } from '../../../../../core/types/close-message-action';
import { ContactPhone } from '../../../../../core/types/contact-phone';
import { Page } from '../../../../../core/types/page';
import { SearchOptions } from '../../../../../core/types/search-options';
import { SendMessageData } from '../../../../../core/types/send-message-data';
import { SmsTemplate } from '../../../../../core/types/sms';
import { SmsTemplateCode } from '../../../../../core/types/sms-template-code';
import { RecipientTypes } from './recipient-types';
import { Client } from '../../../../../core/types/client';
import { ContactService } from '../../../../../core/services/contacts.service';

@Component({
  selector: 'itfg-send-sms-dialog',
  templateUrl: './send-sms-dialog.component.html',
  styleUrls: ['./send-sms-dialog.component.scss'],
})
export class SendSmsDialogComponent implements OnInit, OnDestroy {
  public sendSmsForm: UntypedFormGroup;
  public relatedContacts: ContactPhone[];
  public dataLoading: boolean;
  public smsTemplates: SmsTemplate[];
  public searchOptions: SearchOptions;
  public recipientTypes: typeof RecipientTypes;
  public smsTemplateCodes: SmsTemplate[];
  public observableSubscriptions: Subscription[];
  public clientFormSubscriptions: Subscription[];
  public contactFormSubscriptions: Subscription[];
  public disableSend = false;
  _unsubscribe: Subject<void> = new Subject<void>();
  
  // private smsTemplateCodesForConfirmedMobile: Array<string> = [
  //   // SmsTemplateCode.PAYMENT_INSTRUCTIONS_BANK,
  //   // SmsTemplateCode.PAYMENT_INSTRUCTIONS_EASYPAY,
  //   // SmsTemplateCode.LEGAL_PROCEEDING_REMINDER,
  //   SmsTemplateCode.TEMPORARY_PASSWORD,
  //   SmsTemplateCode.BLANK,
  // ];
  // private smsTemplateCodesForUnconfirmedMobile: Array<string> = [
  //   SmsTemplateCode.MOBILE_CONFIRMATION,
  //   SmsTemplateCode.BLANK,
  // ];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { client: Client },
    private dialogRef: MatDialogRef<SendSmsDialogComponent>,
    private formBuilder: UntypedFormBuilder,
    private contactService: ContactService,
    private templates: TemplatesService,
    private sms: SmsService,
    private notifications: NotificationService
  ) {
    this.observableSubscriptions = [];
    this.clientFormSubscriptions = [];
    this.contactFormSubscriptions = [];

    this.recipientTypes = RecipientTypes;
    // this.smsTemplateCodes = SmsTemplateCode;

    this.sendSmsForm = this.createSendSmsForm();
    this.switchToClient();
    this.dataLoading = false;
    this.searchOptions = new SearchOptions({ pageSize: 100 });
  }

  ngOnInit() {
    this.dataLoading = true;
    // gets send sms tempalte options 
    const smsTemplates$ = this.templates
      .getSMStemplatesByUser(this.data.client.id)
      .subscribe((res: SmsTemplate[]) => {
        this.smsTemplates = res;
      })
      .add(() => {
        this.dataLoading = false;
      })
      
      this.contactService.contactsSubject
      .pipe(takeUntil(this._unsubscribe))
      .subscribe((contacts: ContactPhone[]) => {
        this.relatedContacts = contacts;
      });

    const recipientTypeChangeSubscription = this.sendSmsForm
      .get('recipientType')
      .valueChanges.subscribe((recipientType: RecipientTypes) => {
        switch (recipientType) {
          case RecipientTypes.CLIENT:
            this.switchToClient();
            break;

          case RecipientTypes.CLIENT_CONTACT:
            this.switchToContact();
            break;
        }
      });

    this.observableSubscriptions.push(recipientTypeChangeSubscription);
  }

  ngOnDestroy() {
    this.detachClientFormSubscriptions();
    this.observableSubscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
    this._unsubscribe.next();
    this._unsubscribe.complete();
  }

  attachClientFormSubscriptions() {
    const templateChangeSubscription = this.sendSmsForm
      .get('clientRecipient.template')
      .valueChanges.pipe(
        filter((templateCode: SmsTemplateCode) => templateCode !== null)
      )
      .subscribe((templateCode: SmsTemplateCode) => {
        if (templateCode === SmsTemplateCode.BLANK) {
          this.addFreeTextField();
        } else {
          this.removeFreeTextField();
        }
      });
    this.clientFormSubscriptions.push(templateChangeSubscription);
  }

  detachClientFormSubscriptions() {
    this.clientFormSubscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }

  switchToClient() {
    this.sendSmsForm.removeControl('contactRecipient');
    this.setFormStatusWithoutRecipientType({ touched: false, dirty: false });
    const clientForm: UntypedFormGroup = this.createClientForm();
    this.initClientForm(clientForm);
    this.sendSmsForm.setControl('clientRecipient', clientForm);
    this.removeFreeTextField();
    this.attachClientFormSubscriptions();
  }

  switchToContact() {
    this.detachClientFormSubscriptions();
    this.setFormStatusWithoutRecipientType({ touched: false, dirty: false });
    this.sendSmsForm.removeControl('clientRecipient');
    const contactForm: UntypedFormGroup = this.createContactForm();
    this.initContactForm(contactForm);
    this.sendSmsForm.setControl('contactRecipient', contactForm);
    this.addFreeTextField();
    this.contactService.contactsSubject
    .pipe(takeUntil(this._unsubscribe))
    .subscribe((contacts: ContactPhone[]) => {
      this.relatedContacts = contacts;
    });
  }

  addFreeTextField() {
    this.sendSmsForm.setControl(
      'body',
      this.formBuilder.control(null, [Validators.required])
    );
  }

  removeFreeTextField() {
    this.sendSmsForm.removeControl('body');
  }

  setFormStatusWithoutRecipientType({ touched = false, dirty = false }) {
    const controlsToReset: AbstractControl[] = [];
    controlsToReset.push(this.sendSmsForm.get('body'));
    controlsToReset.push(this.sendSmsForm.get('clientRecipient.template'));
    controlsToReset.push(this.sendSmsForm.get('contactRecipient.recipient'));

    controlsToReset
      .filter(control => control !== null)
      .forEach(control => {
        if (touched) {
          control.markAsTouched();
        } else {
          control.markAsUntouched();
        }
        if (dirty) {
          control.markAsDirty();
        } else {
          control.markAsPristine();
        }
      });
  }

  compareItemsById(firstItem: { id: number }, secondItem: { id: number }) {
    if (firstItem && secondItem) {
      return firstItem.id === secondItem.id;
    } else {
      return false;
    }
  }

  close(action: CloseMessageAction = { reloadData: false }) {
    this.dialogRef.close(action);
  }

  // sendSms(event) {
  //   this.setFormStatusWithoutRecipientType({ touched: true, dirty: true });
  //   if (this.sendSmsForm.valid) {
  //     const sendEmailDTO: SendMessageData = this.createEmailDTOFromFormValue(
  //       this.sendSmsForm.getRawValue()
  //     );
  //     const sendSmsFormValue = { ...this.sendSmsForm.value };
  //     let smsRequest: Observable<any>;
  //     switch (sendSmsFormValue.recipientType) {
  //       case RecipientTypes.CLIENT:
  //         if (
  //           sendSmsFormValue.clientRecipient.template === SmsTemplateCode.BLANK
  //         ) {
  //           smsRequest = this.sms.sendFreeTextSms(
  //             this.data.client.id,
  //             this.data.client.mobile,
  //             sendSmsFormValue.body
  //           );
  //         } else {
  //           smsRequest = this.sms.sendSms({
  //              clientId: this.data.client.id,
  //             smsTemplateCode: sendSmsFormValue.clientRecipient.template
  //           } as sendSmsDTO );
  //         }
  //         break;

  //       case RecipientTypes.CLIENT_CONTACT:
  //         smsRequest = this.sms.sendFreeTextSms(
  //           this.data.client.id,
  //           sendSmsFormValue.contactRecipient.recipient.phone,
  //           sendSmsFormValue.body
  //         );
  //         break;

  //       default:
  //         throw new Error('Unhandled case when sending sms');
  //     }
  //     smsRequest
  //       .pipe(catchError(this.catchSendingMessageError.bind(this)))
  //       .subscribe(() => {
  //         this.notifications.showLocalizedSuccessMessage({
  //           notificationText: 'communication.smsSuccessfullySent',
  //         });
  //         this.close({ reloadData: true });
  //       });
  //   }
  // }


  sendSms() { 
    this.setFormStatusWithoutRecipientType({ touched: true, dirty: true });
    if (this.sendSmsForm.valid) {
      this.disableSend = true;
      const sendEmailDTO: SendMessageData = this.createEmailDTOFromFormValue(
        this.sendSmsForm.getRawValue()
      );
      const sendSmsFormValue = { ...this.sendSmsForm.value };
      let smsRequest: Observable<any>;
      switch (sendSmsFormValue.recipientType) {
        case RecipientTypes.CLIENT:
          if (
            sendSmsFormValue.clientRecipient.template === SmsTemplateCode.BLANK
          ) {
            smsRequest = this.sms.sendFreeTextSms(
              this.data.client.id,
              this.data.client.mobile,
              sendSmsFormValue.body
            );
          } else {
            smsRequest = this.sms.sendSms( 
              {
                clientId: this.data.client.id,
                smsTemplateCode: sendSmsFormValue.clientRecipient.template as SmsTemplateCode});
          }
          break;

        case RecipientTypes.CLIENT_CONTACT:
          smsRequest = this.sms.sendFreeTextSms(
            this.data.client.id,
            sendSmsFormValue.contactRecipient.recipient.phone,
            sendSmsFormValue.body
          );
          break;

        default:
          throw new Error('Unhandled case when sending sms');
      }
      smsRequest
        .pipe(catchError(this.catchSendingMessageError.bind(this)))
        .subscribe(() => {
          this.notifications.showLocalizedSuccessMessage({
            notificationText: 'communication.smsSuccessfullySent',
          });
          this.close({ reloadData: true });
        });
    }
  }

  createSendSmsForm(): UntypedFormGroup {
    const fb = this.formBuilder;
    return fb.group({
      recipientType: fb.control(this.recipientTypes.CLIENT, [
        Validators.required,
      ]),
      clientRecipient: this.createClientForm(),
    });
  }

  createClientForm() {
    return this.formBuilder.group({
      recipient: [null, [Validators.required]],
      template: [null, [Validators.required]],
    });
  }

  createContactForm() {
    return this.formBuilder.group({
      recipient: [null, [Validators.required]],
      template: [null, [Validators.required]],
    });
  }

  createEmailDTOFromFormValue(formValue: any): SendMessageData {
    return {
      template: formValue.template,
      body: formValue.body ? formValue.body : undefined,
    };
  }

  initClientForm(clientForm: UntypedFormGroup) {
    clientForm.setValue({
      recipient: this.data.client.mobile,
      template: null,
    });
  }

  initContactForm(contactForm: UntypedFormGroup) {
    contactForm.setValue({
      recipient: null,
      template: SmsTemplateCode.BLANK,
    });
  }

  private catchSendingMessageError(error: HttpErrorResponse) {
    this.notifications.showLocalizedErrorMessage({
      notificationText: error.error.message,
    });
    this.disableSend = false;
    return observableThrowError(error);
  }
}
