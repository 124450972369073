import { Pipe, PipeTransform } from '@angular/core';
import { Client } from '../../../core/types/client';

@Pipe({
  name: 'clientFullName',
})
export class ClientFullNamePipe implements PipeTransform {
  transform(client: Client, args?: any): string {
    let result = '';
    if (client) {
      if (client.firstName) {
        result += `${client.firstName}`;
      }
      if (client.middleName) {
        result += ` ${client.middleName}`;
      }
      if (client.lastName) {
        result += ` ${client.lastName}`;
      }
    }
    return result.trim();
  }
}
