<div layout="column">
  <div layout="row"
       layout-align="space-between start"
       flex>
    <div mat-dialog-title>
      <span *ngIf="isNewItem" class="edit-screen-title" [translate]="'tags.newTag'">New tag</span>
      <span *ngIf="!isNewItem" class="edit-screen-title" [translate]="'tags.editTag'">Edit tag</span>
    </div>
    <button tabindex="-1"
            class="close-mat-dialog"
            mat-icon-button
            [mat-dialog-close]="false">
      <mat-icon>clear</mat-icon>
    </button>
  </div>

  <form novalidate
        [formGroup]="tagForm"
        (ngSubmit)="onTagSave($event)"
        layout="column">
    <mat-form-field flex="20">
      <input matInput
             type="text"
             #ignoredInput
             readonly
             [value]="selectedColor"
             [style.color]="selectedColor"
             placeholder="{{ 'tags.color' | translate }}"
             (click)="toggle=!toggle;"
             [cpIgnoredElements]="[ignoredInput]"
             [(cpToggle)]="toggle"
             [(colorPicker)]="selectedColor"
             [cpPosition]="'left'"
             [cpAlphaChannel]="'disabled'"
             [cpOutputFormat]="'hex'"
             [cpDisableInput]="true"
             [cpUseRootViewContainer]="true"
             formControlName="color"/>
      <mat-label>{{ 'tags.color' | translate }}</mat-label>
    </mat-form-field>

    <mat-form-field flex>
      <input matInput
             [readonly]="!isNewItem"
             required
             type="text"
             class="flex-padding-24"
             formControlName="code"
             [value]="data?.tag?.code || null"
             placeholder="{{ 'global.code' | translate }}"/>
      <mat-label>{{ 'global.code' | translate }}</mat-label>
      <mat-error *ngIf="tagForm.get('code').hasError('required')" [translate]="'forms.required'">This field is
        required
      </mat-error>
    </mat-form-field>
    <mat-form-field flex>
      <input matInput
             required
             type="text"
             class="flex-padding-24"
             formControlName="name"
             placeholder="{{ 'tags.name' | translate }}"/>
      <mat-label>{{ 'tags.name' | translate }}</mat-label>
      <mat-error *ngIf="tagForm.get('name').hasError('required')" [translate]="'forms.required'">This field is
        required
      </mat-error>
    </mat-form-field>

    <mat-form-field>
    <textarea matInput
              cdkTextareaAutosize
              cdkAutosizeMaxRows="8"
              cdkAutosizeMinRows="4"
              formControlName="description"
              placeholder="{{ 'tags.description' | translate }}"></textarea>
    </mat-form-field>

    <div layout="row"
         layout-align="end">
      <button mat-stroked-button
              type="submit">
        <span [translate]="'global.save'">Save</span>
      </button>
    </div>

  </form>
</div>
