import {Component, Input, OnChanges, OnInit} from '@angular/core';
import {IdCardStatus, IdCardVerificationOptions, IdCardVerificationOptionType} from '../../../../core/types/id-card-verified';
import {StyleConfigMap} from '../../../../config/credit-config';
import {CreditStatusNames} from '../../../../core/types/credit-status';
import {ID_CARD_STATUS_STYLE_MAP, IdCardStyleConfigMap} from '../../../../config/id-card-status-config';
import {filter} from 'rxjs/operators';
import {MatDialog} from '@angular/material/dialog';
import {IdCardStatusEditComponent, IdCArdStatusEditComponentInputData} from './id-card-status-edit/id-card-status-edit.component';
import {NotificationService} from '../../../../core/services/notification.service';
import {HttpErrorResponse} from '@angular/common/http';
import {ErrorService} from '../../../../core/services/error.service';

@Component({
  selector: 'itfg-id-card-status',
  templateUrl: './id-card-status.component.html',
  styleUrls: ['./id-card-status.component.scss']
})
export class IdCardStatusComponent implements OnInit, OnChanges {
  @Input() statusValue: any;
  @Input() client;

  public idCardStatusStyleMap: IdCardStyleConfigMap;
  public idCardStatus;
  statusChangeOptions: CreditStatusNames[];
  styleOptions: any;
  icon: string;
  statusOptions: IdCardVerificationOptions;


  constructor(public dialog: MatDialog, private notifications: NotificationService, private errorService: ErrorService) {
    this.idCardStatusStyleMap = ID_CARD_STATUS_STYLE_MAP;
    this.statusOptions = new IdCardVerificationOptions();
  }

  ngOnInit(): void {
  }

  ngOnChanges() {
    this.idCardStatus = this.statusOptions.optionsList.filter(option => option.value === this.statusValue)[0];

    this.icon = this.idCardStatusStyleMap[this.idCardStatus.name].icon;

    this.styleOptions = {
      color: this.idCardStatusStyleMap[this.idCardStatus.name]
        .backgroundColor,
    };
  }

  changeStatusDialog() {

    this.dialog
      .open(IdCardStatusEditComponent, {
        restoreFocus: false,
        data: {
          client: this.client,
          idCardStatus: this.idCardStatus,
          statusList: this.statusOptions,
        },
      })
      .afterClosed()
      .subscribe(res => {
        if (res) {
          this.notifications.showLocalizedSuccessMessage({
            notificationText: 'mi.idCardChangeStatusSuccessMessage',
          });
        }

      }, (error: HttpErrorResponse) =>
    this.errorService.showSnackbarErrorMessage(error));
  }
}
