import {
  Component, EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional, Output,
  SimpleChange,
} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Client, ExternalClientDataTypes } from '../../../core/types/client';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormBuilder } from '@angular/forms';
import * as _ from 'lodash-es';
import {
  IDCardVerifiedTypes,
  ClientMapperTypes,
  ClientMIMapperTypes,
} from '../../../core/types/identity-reports';
import { ClientService } from '../../../core/services/client.service';
import { Utils } from '../../../core/utils/utils';
import { SearchOptions } from '../../../core/types/search-options';
import { SearchOperations } from '../../../core/types/search-criterium';
import { CityService } from '../../../core/services/city.service';
import { City } from '../../../core/types/city';
import { PoliceDepartment } from '../../../core/types/police-department';
import { NotificationService } from '../../../core/services/notification.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorService } from '../../../core/services/error.service';
import { CountryService } from '../../../core/services/country.service';
import { Router } from '@angular/router';
import { EmploymentType } from '../../../core/types/employment-type';
import { Education } from '../../../core/types/education';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { MaritalStatus } from '../../../core/types/marital-status';
import { UpdateDataRowService } from '../../../core/services/update-data-row.service';

export interface UpdateClientData {
  client: Client;
  clientData;
  CLIENT_MAPPER;
  title;
  updateData;
}

@Component({
  selector: 'itfg-update-client',
  templateUrl: './update-client.component.html',
  styleUrls: ['./update-client.component.scss'],
})
export class UpdateClientComponent implements OnInit, OnChanges, OnDestroy {
  @Input() inputClient: Client;
  @Input() inputClientData;
  @Input() clientDataType: string;
  @Input() INPUT_CLIENT_MAPPER;
  @Output() dataChanged = new EventEmitter<boolean>();

  public client: Client;
  public clientData;
  public CLIENT_MAPPER;
  public isClientForSave = true;
  public isClientSaved = true;
  public isClientUpdated = true;
  public dataRows = [];
  public searchOptions: SearchOptions;
  public clientDataCity: City;
  public clientDataNationality: any;
  public clientDataSecondNationality: any;
  public clientDataEducation: any;
  public clientDataEmployment: any;
  public clientDataMaritalStatus: any;
  public policeDepartments: Array<PoliceDepartment>;
  public invalidField = false;
  _unsubscribe: Subject<void> = new Subject<void>();

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public data: UpdateClientData,
    @Optional() private dialogRef: MatDialogRef<UpdateClientComponent>,
    private fb: UntypedFormBuilder,
    private clientService: ClientService,
    private dataRowSerivce: UpdateDataRowService,
    private cityService: CityService,
    private countryService: CountryService,
    private notificationService: NotificationService,
    private errorService: ErrorService,
    private router: Router
  ) {
    if (this.data) {
      this.client = data.client;
      this.clientData = data.clientData;
      this.CLIENT_MAPPER = data.CLIENT_MAPPER;
      this.dataRows = data.updateData ? data.updateData.dataRows : [];
      this.isClientForSave = data.updateData ? data.updateData.isClientForSave : this.isClientForSave;
      this.isClientSaved = data.updateData ? data.updateData.isClientSaved : this.isClientSaved;
      this.isClientUpdated = data.updateData ? data.updateData.isClientUpdated : this.isClientUpdated;
    }
    this.searchOptions = new SearchOptions();
  }

  ngOnInit() {
    if (this.dataRows.length === 0) {
      const data = this.dataRowSerivce.mapTempValues(this.client, this.clientData, this.CLIENT_MAPPER);

      this.dataRows = data.dataRows;
      this.isClientForSave = data.isClientForSave;
      this.isClientSaved = data.isClientSaved;
      this.isClientUpdated = data.isClientUpdated;
    }

    if (
      this.clientData?.nationality &&
      this.clientData?.nationality.id
    ) {
      const countryId = Number(this.clientData.nationality.id);
      this.countryService
        .getCountryById$(countryId)
        .pipe(takeUntil(this._unsubscribe))
        .subscribe((country: any) => {
          this.clientDataNationality = country;
        });
    }

    if (
      this.clientData?.secondNationality &&
      this.clientData?.secondNationality.id
    ) {
      const countryId = Number(this.clientData.secondNationality.id);
      this.countryService
        .getCountryById$(countryId)
        .pipe(takeUntil(this._unsubscribe))
        .subscribe((country: any) => {
          this.clientDataSecondNationality = country;
        });
    }
    if (
      this.clientData.Nationality &&
      this.clientData.Nationality.NationalityName
    ) {
      this.countryService
        .findCountriesStartingWith(
          this.clientData.Nationality.NationalityName
        )
        .pipe(takeUntil(this._unsubscribe))
        .subscribe(result => {
          if (result.length > 0) {
            this.clientDataNationality = result[0];
          }
        });
    }
    if (
      this.clientData.Nationality &&
      this.clientData.Nationality.NationalityName2
    ) {
      this.countryService
        .findCountriesStartingWith(
          this.clientData.Nationality.NationalityName2
        )
        .pipe(takeUntil(this._unsubscribe))
        .subscribe(result => {
          if (result.length > 0) {
            this.clientDataSecondNationality = result[0];
          }
        });
    }

    if (this.clientData?.education && this.clientData?.education.id) {
      this.clientService.getEducationTypes()
        .pipe(takeUntil(this._unsubscribe))
        .subscribe((educationTypes: Education[]) => {
          const educationId = Number(this.clientData.education.id)

          this.clientDataEducation = educationTypes.find(e => e.id === educationId) ||
            educationTypes.find(e => e.name.toLowerCase() === this.clientData.education.name.toLowerCase());
        })
    }

    if (this.clientData?.employmentStatus && this.clientData?.employmentStatus.id) {
      this.clientService.getEmploymentTypes()
        .pipe(takeUntil(this._unsubscribe))
        .subscribe((employmentTypes: EmploymentType[]) => {
          const employmentTypeId = Number(this.clientData.employmentStatus.id)

          this.clientDataEmployment = employmentTypes.find(e => e.id === employmentTypeId) ||
            employmentTypes.find(e => e.type.toLowerCase() === this.clientData.employmentStatus.name.toLowerCase());
        });
    }

    if (
      this.clientData?.MaritalStatusName
    ) {
      this.clientService.maritalStatusesSubject$
        .pipe(takeUntil(this._unsubscribe))
        .subscribe((maritalStatuses: MaritalStatus[]) => {
          this.clientDataMaritalStatus = maritalStatuses.find(e => e.name.toLowerCase() === this.clientData?.MaritalStatusName?.toLowerCase());
        });
    }
    if (
      (this.clientData.PermanentAddress &&
        this.clientData.PermanentAddress.SettlementName) || (this.clientData.SettlementName)
    ) {
      this.searchOptions = this.setSearchOptions(this.clientData);
      this.cityService
        .getCities(this.searchOptions.getDTO())
        .pipe(takeUntil(this._unsubscribe))
        .subscribe(result => {
          if (result.content.length > 0) {
            this.clientDataCity = result.content[0];
          }
        });
    }
    this.dataChanged.emit(this.isClientUpdated);
  }

  ngOnChanges(changes) {
    if (this.inputClient && this.INPUT_CLIENT_MAPPER && this.inputClientData) {
      this.client = this.inputClient;
      this.clientData = this.inputClientData;
      this.CLIENT_MAPPER = this.INPUT_CLIENT_MAPPER;

      const data = this.dataRowSerivce.mapTempValues(this.client, this.clientData, this.CLIENT_MAPPER);
      this.dataRows = data.dataRows;
      this.isClientForSave = data.isClientForSave;
      this.isClientSaved = data.isClientSaved;
      this.isClientUpdated = data.isClientUpdated;

      if (this.clientDataType === ExternalClientDataTypes.MI) {
        this.getPoliceDepartmentData();
      }

      if (
        (this.clientData.PermanentAddress &&
          this.clientData.PermanentAddress.SettlementName) || (this.clientData.SettlementName)
      ) {
        this.searchOptions = this.setSearchOptions(this.clientData);
        this.cityService
          .getCities(this.searchOptions.getDTO())
          .pipe(takeUntil(this._unsubscribe))
          .subscribe(result => {
            if (result.content.length > 0) {
              this.clientDataCity = result.content[0];
            }
          });
      }
      if (
        this.clientData.Nationality &&
        this.clientData.Nationality.NationalityName
      ) {
        this.countryService
          .findCountriesStartingWith(
            this.clientData.Nationality.NationalityName
          )
          .pipe(takeUntil(this._unsubscribe))
          .subscribe(result => {
            if (result.length > 0) {
              this.clientDataNationality = result[0];
            }
          });
      }
      if (
        this.clientData.SecondNationality &&
        this.clientData.SecondNationality.NationalityName
      ) {
        this.countryService
          .findCountriesStartingWith(
            this.clientData.SecondNationality.NationalityName
          )
          .pipe(takeUntil(this._unsubscribe))
          .subscribe(result => {
            if (result.length > 0) {
              this.clientDataSecondNationality = result[0];
            }
          });
      }
    }
    this.dataChanged.emit(this.isClientUpdated);
  }

  updateClientField(rowData) {
    this.isClientUpdated = true;

    for (const row of this.dataRows) {
      if (rowData.key === row.key) {
        row.tempValue = row.reportValue;

        this.updateRowData(rowData);
        if (
          row.key === ClientMapperTypes.ID_CARD_VERIFIED &&
          row.reportValue !== IDCardVerifiedTypes.VALID
        ) {
          row.tempValue = row.reportValue.split('-')[0].trim();
          row.isTempEqualReport = true;
        }
      }
      if (!row.isTempEqualClient) {
        this.isClientForSave = true;
        this.isClientSaved = false;
      }

      if (!row.isTempEqualReport) {
        this.isClientUpdated = false;
      }

      if (!row.updateable && !row.isTempEqualReport) {
        this.isClientUpdated = false;
      }
    }
  }

  revertClientField(rowData) {
    this.isClientForSave = false;
    for (const row of this.dataRows) {
      if (rowData.key === row.key) {
        row.reportValue = row.tempValue;
        row.tempValue = row.clientValue;
        this.updateRowData(rowData);
        if (row.reportValue === IDCardVerifiedTypes.NOT_VALID) {
          const reason = _.get(this.clientData, 'DocumentStatusReason');
          row.reportValue += ' - ' + reason;
        }
      }
      if (row.isTempEqualReport && !row.isReportEqualClient) {
        this.isClientForSave = true;
      }
      if (!row.isTempEqualReport) {
        this.isClientUpdated = false;
      }
    }
  }

  updateRowData(row) {
    row.isTempEqualReport =
      row.tempValue.toString().toLowerCase() ===
      row.reportValue.toString().toLowerCase();
    row.isTempEqualClient =
      row.tempValue.toString().toLowerCase() ===
      row.clientValue.toString().toLowerCase();
    row.isReportEqualClient =
      row.reportValue.toString().toLowerCase() ===
      row.clientValue.toString().toLowerCase();

  }

  updateClient() {
    for (const row of this.dataRows) {
      if (!row.updateable || row.isReportEqualClient) {
        continue;
      }
      row.tempValue = row.reportValue;
      this.updateRowData(row);

      if (
        row.key === ClientMapperTypes.ID_CARD_VERIFIED &&
        row.reportValue !== IDCardVerifiedTypes.VALID
      ) {
        row.tempValue = row.reportValue.split('-')[0].trim();
        row.isTempEqualReport =
          row.tempValue === row.reportValue.split('-')[0].trim();
      }
    }
    this.isClientForSave = true;
    this.isClientUpdated = true;
  }

  save() {
    let clientForSave = _.clone(this.client);
    this.invalidField = false;

    for (const row of this.dataRows) {
      let key = row.key;
      let value = row.tempValue;

      if (key === ClientMapperTypes.EMAIL && !row.isReportEqualClient) {
        const emailConfirmedKey = 'emailConfirmed';
        _.set(clientForSave, emailConfirmedKey, false);
      }

      if (key === ClientMapperTypes.ID_CARD_VERIFIED) {
        value = row.tempValue === IDCardVerifiedTypes.VALID;
      }
      if (key === ClientMapperTypes.ID_CARD_ADDRESS_CITY_NAME) {
        if (row.isTempEqualReport && !row.isTempEqualClient) {
          key = ClientMapperTypes.ID_CARD_ADDRESS_CITY;
          if (this.clientDataCity) {
            value = this.clientDataCity;
          } else {
            this.revertClientField(row);
            this.invalidField = true;
          }
        } else {
          continue;
        }
      }

      if (key === ClientMapperTypes.CURRENT_ADDRESS_CITY_NAME) {
        if (row.isTempEqualReport && !row.isTempEqualClient) {
          key = ClientMapperTypes.CURRENT_ADDRESS_CITY;
          if (this.clientDataCity) {
            value = this.clientDataCity;
          } else {
            this.revertClientField(row);
            this.invalidField = true;
          }
        } else {
          continue;
        }
      }
      if (key === ClientMapperTypes.NATIONALITY_NAME) {
        if (row.isTempEqualReport && !row.isTempEqualClient) {
          key = ClientMapperTypes.NATIONALITY;
          if (this.clientDataNationality) {
            value = this.clientDataNationality;
          } else {
            this.revertClientField(row);
            this.invalidField = true;
          }
        } else {
          continue;
        }
      }
      if (key === ClientMapperTypes.SECOND_NATIONALITY_NAME) {
        if (row.isTempEqualReport && !row.isTempEqualClient) {
          key = ClientMapperTypes.SECOND_NATIONALITY;
          if (this.clientDataSecondNationality) {
            value = this.clientDataSecondNationality;
          } else {
            this.revertClientField(row);
            this.invalidField = true;
          }
        } else {
          continue;
        }
      }
      if (key === ClientMapperTypes.EDUCATION_NAME) {
        if (row.isTempEqualReport && !row.isTempEqualClient) {
          key = ClientMapperTypes.EDUCATION;
          if (this.clientDataEducation) {
            value = this.clientDataEducation;
          } else {
            this.revertClientField(row);
            this.invalidField = true;
          }
        } else {
          continue;
        }
      }
      if (key === ClientMapperTypes.EMPLOYMENT_TYPE_TYPE) {
        if (row.isTempEqualReport && !row.isTempEqualClient) {
          key = ClientMapperTypes.EMPLOYMENT_TYPE;
          if (this.clientDataEmployment) {
            value = this.clientDataEmployment;
          } else {
            this.revertClientField(row);
            this.invalidField = true;
          }
        } else {
          continue;
        }
      }
      if (key === ClientMapperTypes.MARITAL_STATUS_NAME) {
        if (row.isTempEqualReport && !row.isTempEqualClient) {
          key = ClientMapperTypes.MARITAL_STATUS;
          if (this.clientDataMaritalStatus) {
            value = this.clientDataMaritalStatus;
          } else {
            this.revertClientField(row);
            this.invalidField = true;
          }
        } else {
          continue;
        }
      }
      if (key === ClientMapperTypes.ID_CARD_ISSUER_NAME) {
        if (row.isTempEqualReport && this.policeDepartments) {
          const policeDepartment = this.policeDepartments.filter(
            department => department.name === row.reportValue
          );
          value = policeDepartment[0];
          key = ClientMapperTypes.ID_CARD_ISSUER;
        }
      }
      _.set(clientForSave, key, value);
    }
    if (this.invalidField) {
      return this.notificationService.showLocalizedErrorMessage({
        notificationText: 'forms.formFieldIsInvalid',
      });
    }
    clientForSave = Utils.checkObjectForEmptyValuesRecursive(clientForSave);

    this.clientService.saveClient(clientForSave)
      .pipe(takeUntil(this._unsubscribe))
      .subscribe(
        savedClient => {
          this.client = savedClient;
          this.clientService.onClientDataChange.next(this.client);

          for (const row of this.dataRows) {
            row.clientValue = row.tempValue;
            this.updateRowData(row);
            if (
              row.key === ClientMapperTypes.ID_CARD_VERIFIED &&
              row.reportValue !== IDCardVerifiedTypes.VALID
            ) {
              row.clientValue = row.reportValue.split('-')[0].trim();
              row.isReportEqualClient =
                row.clientValue === row.reportValue.split('-')[0].trim();
            }
          }
          this.isClientSaved = true;
          this.dataChanged.emit(this.isClientUpdated);
          if (this.dialogRef) {
            this.dialogRef.close(savedClient);
          }

          this.notificationService.showLocalizedSuccessMessage({
            notificationText: 'clients.successfullySavedClient',
          });
        },
        (error: HttpErrorResponse) => {
          this.errorService.showSnackbarErrorMessage(error);
        }
      );
  }

  setSearchOptions(clientData) {
    const cityValue = _.get(clientData, 'PermanentAddress.SettlementName') || _.get(clientData, 'SettlementName');
    const separatorIndex = cityValue.indexOf('.');
    const cityName = cityValue.substr(separatorIndex + 1);
    const cityType = cityValue.substr(0, separatorIndex + 1);

    this.searchOptions.criteria = [
      {
        key: 'type',
        operation: SearchOperations.EQUALS,
        value: cityType,
      },
      {
        key: 'name',
        operation: SearchOperations.EQUALS,
        value: cityName,
      },
      {
        key: 'municipality.name',
        operation: SearchOperations.EQUALS,
        value: _.get(clientData, 'PermanentAddress.MunicipalityName') || _.get(clientData, 'MunicipalityName'),
      },
      {
        key: 'region.name',
        operation: SearchOperations.EQUALS,
        value: _.get(clientData, 'PermanentAddress.DistrictName') || _.get(clientData, 'DistrictName'),
      },
    ];
    return this.searchOptions;
  }

  modifyIdCardVerifiedValues(row, reportData) {
    if (row.tempValue !== null) {
      row.tempValue =
        row.tempValue === true
          ? IDCardVerifiedTypes.VALID
          : IDCardVerifiedTypes.NOT_VALID;
      row.clientValue =
        row.clientValue === true
          ? IDCardVerifiedTypes.VALID
          : IDCardVerifiedTypes.NOT_VALID;
    } else {
      row.tempValue = IDCardVerifiedTypes.NOT_VERIFIED;
      row.clientValue = IDCardVerifiedTypes.NOT_VERIFIED;
    }
    this.updateRowData(row);

    if (row.reportValue !== IDCardVerifiedTypes.VALID) {
      const reason = _.get(reportData, 'DocumentStatusReason');
      row.reportValue = IDCardVerifiedTypes.NOT_VALID;
      this.updateRowData(row);
      row.reportValue = IDCardVerifiedTypes.NOT_VALID + ' - ' + reason;
    }
    return row;
  }

  modifyIdCardCityValues(row, client) {
    const type = _.get(client, 'idCardAddress.city.type');
    row.tempValue = type ? (type + row.clientValue) : '';
    row.clientValue = type ? (type + row.clientValue) : '';
    return row;
  }
  modifyCurrentCityValues(row, client) {
    const type = _.get(client, 'currentAddress.city.type');
    row.tempValue = type ? (type + row.clientValue) : '';
    row.clientValue = type ? (type + row.clientValue) : '';
    return row;
  }

  getPoliceDepartmentData() {
    this.clientService.getPoliceDepartmentList()
      .pipe(takeUntil(this._unsubscribe))
      .subscribe(val => {
        this.policeDepartments = val;
      });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnDestroy() {
    this._unsubscribe.next();
    this._unsubscribe.complete();
  }
}
