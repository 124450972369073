import { Pipe, PipeTransform } from '@angular/core';
import { ClientStatus } from '../../../core/types/client-status';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'clientStatusToTranslation',
})
export class ClientStatusToTranslationPipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  transform(
    status: ClientStatus = { id: null, name: '', reason: '' },
    args?: any
  ): any {
    const translatedStatus = {
      ...status,
      name: this.translateService.instant(
        `global.${status.name.toLowerCase()}`
      ),
      reason: status.reason
        ? this.translateService.instant(`global.${status.reason.toLowerCase()}`)
        : null,
    };
    return translatedStatus;
  }
}
