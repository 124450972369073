import { Component, Input, OnInit, OnChanges } from '@angular/core';
import {
  ConversationType,
  ConversationTypes,
} from '../../../../../core/types/converstion';

@Component({
  selector: 'itfg-conversation-contact-status',
  templateUrl: './conversation-contact-status.component.html',
  styleUrls: ['./conversation-contact-status.component.scss'],
})
export class ConversationContactStatusComponent implements OnInit, OnChanges {
  @Input() contactedStatus: boolean;
  @Input() contactedPhone: number;
  @Input() conversationType: ConversationType;

  public phoneType: ConversationTypes;

  public contactStatusIconMap = {
    true: {
      color: '#008000',
    },
    false: {
      color: '#bf2313',
    },
  };
  public contactTypeIconMap = {
    [ConversationTypes.INCOMING_CALL]: {
      icon: 'call_received',
      color: 'rgb(63, 81, 181)',
    },
    [ConversationTypes.OUTGOING_CALL_CONTACTED]: {
      icon: 'call_made',
      color: '#008000',
    },
    [ConversationTypes.OUTGOING_CALL_NOT_CONTACTED]: {
      icon: 'call_missed',
      color: '#bf2313',
    },
  };

  constructor() {
    this.phoneType = ConversationTypes.OUTGOING_CALL_NOT_CONTACTED;
  }

  ngOnInit() {}

  ngOnChanges() {}
}
