export interface IdCardStatus {
  value?: any;
  name?: any;
}

export interface IdCardVerificationOptionType {
  name: string;
  value: any;
}

export enum IdCardVerificationName {
  VALID = 'VALID',
  UNVERIFIED = 'UNVERIFIED',
  INVALID = 'INVALID',
}

export class IdCardVerificationOptions {
  optionsList: IdCardVerificationOptionType[];

  constructor() {
    this.optionsList = [
      { name: IdCardVerificationName.VALID, value: true },
      { name: IdCardVerificationName.INVALID, value: false },
      { name: IdCardVerificationName.UNVERIFIED, value: null },
    ];
  }
}
