<a *ngIf="collapsed && (config.children && config.children.length > 0)"
    mat-list-item
    [matTooltip]="config.label | translate"
    matTooltipPosition="right"
    routerLinkActive="active-link">
  <mat-icon matListItemIcon>{{ config.icon }}</mat-icon>
  <a *ngFor="let route of config.children"
      [ngStyle]="{ 'display': 'none' }"
      [routerLink]="route.routerLink"></a>
</a>

<a *ngIf="collapsed && (!config.children || config.children.length === 0)"
    mat-list-item
    [routerLink]="config.routerLink"
    [matTooltip]="config.label | translate"
    matTooltipPosition="right"
    routerLinkActive="active-link">
  <mat-icon matListItemIcon>{{ config.icon }}</mat-icon>
</a>

<a *ngIf="!collapsed && (!config.children || config.children.length === 0)"
    class="expanded-nav-item"
    mat-list-item
    [routerLink]="config.routerLink"
    routerLinkActive="active-link"
    [routerLinkActiveOptions]="{ 'exact': true }">
  <mat-icon matListItemIcon>{{ config.icon }}</mat-icon>
  <span [translate]="config.label">Config label</span>
</a>
