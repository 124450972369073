<mat-list flex
          layout="row"
          layout-wrap="wrap">
  <ng-container *ngFor="let route of menuRoutes">
    <mat-nav-list>
      <a flex="20"
         *ngIf="!route.children  && !(route.permissions | isUnauthorized | async)"
         mat-list-item
         [routerLink]="route.routerLink"
         routerLinkActive="active-link"
         class="text-nodecoration mat-elevation-z1">
        <span flex="100"
              layout="row"
              layout-align="start center">
          <mat-icon class="margin-right-16px"
                    matListIcon>{{route.icon}}</mat-icon>
          <span [translate]="route.label">
          </span>
        </span>
      </a>
      <div flex="20"
           class="mat-elevation-z1"
           *ngIf="(route.children && route.children.length > 0) && !(route.permissions | isUnauthorized | async)">
        <mat-expansion-panel class="shadow-none padding-left-20px style-expansion-element">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <span flex="100"
                    layout="row"
                    layout-align="start center">
                <mat-icon class="margin-right-18px"
                          matListIcon>{{route.icon}}</mat-icon>
                <span [translate]="route.label"></span>
              </span>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <mat-nav-list flex="100">
            <ng-container *ngFor="let child of route.children">
              <a flex="100"
                 mat-list-item
                 [routerLink]="child.routerLink"
                 routerLinkActive="active-link"
                 class="text-nodecoration">
                <span flex="100"
                      layout="row"
                      layout-align="start center">
                  <mat-icon class="margin-right-16px"
                            matListIcon>{{child.icon}}</mat-icon>
                  <span [translate]="child.label">
                  </span>
                </span>
              </a>
            </ng-container>
          </mat-nav-list>
        </mat-expansion-panel>
      </div>
    </mat-nav-list>
  </ng-container>
</mat-list>
