import { StyleConfigMap } from '../../../app/config/credit-config';
import { Conversation } from './converstion';
import { Credit } from './credit';

export interface PaymentPromise {
  id?: number;
  date: string;
  createdAt?: string;
  updatedAt?: string;
  amount: number;
  operatorId: number;
  operatorEmail: string;
  credit: Credit;
  active?: boolean;
  status?: string;
  paidAmount?: number;
  agreementId?: number;
}

export enum PaymentPromiseStatusType {
  EXPIRED = 'EXPIRED',
  PAID = 'PAID',
  UPCOMING = 'UPCOMING',
  CURRENT = 'CURRENT',
  UNFULFILLED = 'UNFULFILLED',
  PARTIALLY_PAID = 'PARTIALLY_PAID',
  CANCELED = 'CANCELED',
}

// torev
export const PAYMENT_PROMISE_STYLE_MAP: StyleConfigMap = {
  [PaymentPromiseStatusType.UPCOMING]: {
    color: 'rgba(0, 0, 0, 0.4392156863)',
    icon: 'pending',
  },
  [PaymentPromiseStatusType.CURRENT]: {
    color: '#34568B',
    icon: 'timelapse',
  },
  [PaymentPromiseStatusType.EXPIRED]: {
    color: '#D70040',
    icon: 'schedule',
  },
  [PaymentPromiseStatusType.PAID]: {
    color: '#009E60',
    icon: 'check_circle',
  },
  [PaymentPromiseStatusType.UNFULFILLED]: {
    color: '#D70040',
    icon: 'cancel',
  },
  [PaymentPromiseStatusType.PARTIALLY_PAID]: {
    color: '#FFBF00',
    icon: 'radio_button_partial',
  },
  [PaymentPromiseStatusType.CANCELED]: {
    color: '#D70040',
    icon: 'block',
  },
};

// export const PAYMENT_PROMISE_STYLE_MAP: StyleConfigMap = {
//   [PaymentPromiseStatusType.UPCOMING]: {
//     color: 'gray',
//     icon: 'timelapse',
//   },
//   [PaymentPromiseStatusType.CURRENT]: {
//     color: '#ff6c00',
//     icon: 'timelapse',
//   },
//   [PaymentPromiseStatusType.EXPIRED]: {
//     color: 'rgb(255, 48, 0)',
//     icon: 'timelapse ',
//   },
//   [PaymentPromiseStatusType.PAID]: {
//     color: '#070',
//     icon: 'check_circle',
//   },
//   [PaymentPromiseStatusType.UNFULFILLED]: {
//     color: 'red',
//     icon: 'check_circle_outline',
//   },
//   [PaymentPromiseStatusType.PARTIALLY_PAID]: {
//     color: 'rgba(29, 149, 29, 0.61)',
//     icon: 'check_circle_outline',
//   },
//   [PaymentPromiseStatusType.CANCELED]: {
//     color: '#dddddd',
//     icon: 'cancel',
//   },
// };
