import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { NotificationService } from '@app/core/services/notification.service';
import { TranslateService } from '@ngx-translate/core';
import { Credit } from '@app/core/types/credit';
import { CallService } from '@app/core/services/call.service';
import {
  Call,
  CallDirection,
  CallOutgoingStatuses,
  CallIncomingStatuses,
  CallStatus,
  HIDDEN_PHONE_NUMBER,
  CallDialogMode,
} from '@app/core/types/call';
import { NoteComponent } from '../../notes/note/note.component';
import { NoteMode } from '../../notes/note/note.types';
import { MatSelectChange } from '@angular/material/select';
import { EventLogStateService } from '../../event-log-state.service';
@Component({
  selector: 'itfg-call-edit-dialog',
  templateUrl: './call-edit-dialog.component.html',
  styleUrls: ['./call-edit-dialog.component.scss'],
})
export class CallEditDialogComponent implements OnInit, OnDestroy {
  @ViewChild(NoteComponent) noteComponent: NoteComponent;
  public dialogMode: CallDialogMode = CallDialogMode.CREATE;
  public callDialogModes: typeof CallDialogMode = CallDialogMode;
  public createCallForm: UntypedFormGroup;
  public phoneNumberOptions: any[] = [];
  public callDirectionTypes: any[] = Object.values(CallDirection);
  public callStatusTypes: any[] = Object.values(CallOutgoingStatuses);
  public callOutgoingTypes = Object.values(CallOutgoingStatuses);
  public callIncomingTypes = Object.values(CallIncomingStatuses);
  public hiddenMobile: string = HIDDEN_PHONE_NUMBER;
  selectedCredit: Credit;
  NoteMode = NoteMode;
  _unsubscribe: Subject<void> = new Subject<void>();

  constructor(
    public dialogRef: MatDialogRef<CallEditDialogComponent>,
    private formBuilder: UntypedFormBuilder,
    private translateService: TranslateService,
    private notifications: NotificationService,
    private callService: CallService,
    private eventLogStateService: EventLogStateService,
    @Inject(MAT_DIALOG_DATA) public data: { mode: CallDialogMode, call: Call, phoneNumber: string, openedCredit: Credit, selectedCredit: Credit, creditContextArr: Credit[] }
  ) {
    this.dialogMode = data.mode;
  }

  ngOnInit(): void {
    if (this.data.creditContextArr && this.data.creditContextArr.length > 0 && this.data.selectedCredit) {
      this.selectedCredit = this.data.creditContextArr.find((credit: Credit) => credit.id === this.data.selectedCredit.id);
    }

    this.createCallForm = this.createManualCallForm();

    if (this.dialogMode === CallDialogMode.UPDATE) {
      this.createCallForm.controls.phoneNumber.disable();
      this.createCallForm.controls.direction.disable();
    }

    this.watchForDirectionChanges();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onSave(): void {
    const formValue = this.createCallForm.value;

    if (this.createCallForm.valid) {
      const callDto = {
        phoneNumber: formValue.phoneNumber,
        direction: formValue.direction,
        status: formValue.status,
      };

      this.createOrUpdateCall(callDto);
    } else {
      this.notifications.showLocalizedErrorMessage({
        notificationText: 'forms.formSavedIsInvalid',
      });
    }
  }

  createManualCallForm(): UntypedFormGroup {
    return this.formBuilder.group({
      phoneNumber: [
        this.dialogMode === CallDialogMode.CREATE ? this.data?.phoneNumber || this.hiddenMobile : this.data.call?.phoneNumber,
        Validators.required
      ],
      direction: [CallDirection.OUT, Validators.required],
      status: [CallStatus.ANSWER, Validators.required],
    });
  }

  watchForDirectionChanges() {
    this.createCallForm.get('direction').valueChanges.subscribe(direction => {
      if (direction === CallDirection.IN) {
        this.callStatusTypes = this.callIncomingTypes;
        this.createCallForm.get('status').setValue(CallStatus.AGENT_CONNECTED);
      } else {
        this.callStatusTypes = this.callOutgoingTypes;
        this.createCallForm.get('status').setValue(CallStatus.ANSWER);
      }
    });
  }

  createOrUpdateCall(callDto) {
    if (this.dialogMode === CallDialogMode.CREATE) {
      this.callService.createManualCall(callDto).subscribe({
        next: (call: Call) => this.handleCallResponse(call),
        error: (error) => this.handleCallError(error)
      });
    } else {
      const modifiedCallDto = {...callDto, reference: this.data.call.id}
      this.callService.updateCall(this.data.call?.id, modifiedCallDto).subscribe({
        next: (call: Call) => this.handleCallResponse(call),
        error: (error) => this.handleCallError(error)
      });
    }
  }

  handleCallResponse(call: Call) {
    this.eventLogStateService.setState({
      call: call
    })
    const notificationSuccessText = CallDialogMode.CREATE ? 
    this.translateService.instant('pbx.successfullyCreatedManualCall', { phoneNumber: call.phoneNumber === this.hiddenMobile ? this.translateService.instant('communication.hiddenNumber') : call.phoneNumber }) :
    this.translateService.instant('pbx.successfullyUpdatedCall');

    this.notifications.showLocalizedSuccessMessage({
      notificationText: notificationSuccessText
    });
    this.noteComponent.saveNoteFromCallDialog(call.id, this.selectedCredit);
    this.dialogRef.close(true);
  }

  handleCallError(error: any) {
    const notificationErrorText = this.dialogMode === CallDialogMode.CREATE ? 
    this.translateService.instant('pbx.unsuccessfullyCreatedManualCall') : 
    this.translateService.instant('pbx.unsuccessfullyUpdatedCall');

    this.notifications.showLocalizedErrorMessage({
      notificationText: notificationErrorText
    })
  }

  getBulgarianPhoneNumberWithCountryCode(phoneNumber: string) {
    if (phoneNumber) {
      return phoneNumber.startsWith('0')
        ? `+359${phoneNumber.substring(1)}`
        : phoneNumber;
    }
  }

  onSelectedCreditChanged(event: MatSelectChange): void {
    this.selectedCredit = event.value;
  }

  getTruncatedText(text: string, maxLength = Infinity) {
    return text.length > maxLength ? text.slice(0, maxLength) + '...' : text;
  }

  clearSelectedCredit() {
    this.selectedCredit = null;
  }

  ngOnDestroy() {
    this._unsubscribe.next();
    this._unsubscribe.complete();
  }
}
