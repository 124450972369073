import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { IConfirmConfig } from '@app/core/types/dialog.type';

@Component({
  selector: 'itfg-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
})
export class ConfirmDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: IConfirmConfig,
    public router: Router,
  ) {
  }

  ngOnInit() {}

  onRedirect(): void {
    this.router.navigate([this.data.redirectUrl]);
    this.dialogRef.close();
  }
}
