<div flex layout="row" layout-wrap>
  <div flex-gt-md="20" flex-gt-sm="20" flex-gt-xs="50" flex="40" layout="row" layout-align="start center">
    <mat-form-field flex="100">
      <mat-label>
        <span [translate]="'reports.source'">Choose source</span>
      </mat-label>
      <input
        matInput
        [value]="listData?.length > 0 && reportData?.source ? ('reports.sources.' + reportData?.source | translate) + (metaStatusCode | reportCacheRegister) : ''"
        type="text"
        readonly
      />
    </mat-form-field>
  </div>
  <div flex-gt-md="20" flex-gt-sm="20" flex-gt-xs="50" flex="60" layout="row" layout-align="start center">
    <mat-form-field flex="100">
      <mat-label>
        <span [translate]="'reports.toDate'">From</span>
      </mat-label>
      <input
        matInput
        [value]="listData?.length > 0 && reportTime ? (reportTime | date) : null"
        type="text"
        readonly
      />
    </mat-form-field>
  </div>
  <div flex-gt-md="20" flex-gt-sm="20" flex-gt-xs="50" flex="40" layout="row" layout-align="start center">
    <mat-form-field flex="100">
      <mat-label>
        <span [translate]="'global.operator'">Operator</span>
      </mat-label>
      <input
        matInput
        [value]="requesterName"
        type="text"
        readonly
      />
    </mat-form-field>
  </div>
  <div flex-gt-md="40" flex-gt-sm="40" flex-gt-xs="50" flex="60" layout="row" layout-align="start center">
    <mat-form-field flex="100"
                    class="white-space-no-wrap"
    >
      <mat-label>
        <span [translate]="'reports.reportFromDate'">From</span>
      </mat-label>
      <mat-select
        matInput
        [(value)]="reportData"
        (selectionChange)="listDataChanged($event)"
        placeholder="{{ 'nssi.chooseNssiReport' | translate }}"
        disableOptionCentering="true"
        [compareWith]="compareItemsById"
      >
        <mat-select-trigger>
          <div
            flex
            layout="row"
            layout-wrap
            layout-align="space-between center"
          >
              <span><b>{{
                (reportData?.type ? 'reports.types.' + reportData?.type?.toLowerCase() : '')
                  | translate
                }}</b> / {{requestTime | itfgDateFormat}}</span>
            <mat-icon
              *ngIf="
                  requestTime?.getTime() + twoMontsMiliseconds <
                  currentDate?.getTime()
                "
              matIconSuffix
              matTooltip="{{ 'credits.ccrOverTwoMonts' | translate }}"
              matTooltipPosition="above"
              [ngStyle]="{ 'margin-right': '10px', color: 'red' }"
            >warning
            </mat-icon>
          </div>
        </mat-select-trigger>
        <mat-option *ngFor="let report of listData" [value]="report">
            <span flex layout="row" layout-align="space-between center">
              <span [ngStyle]="{ 'width': '250px' }">{{ 'reports.types.' + (report.type ? report.type : reportData?.type?.toLowerCase()) | translate }}
                / {{ report.time | itfgDateFormat }}</span>
              <mat-icon
                *ngIf="
                  report.time.getTime() + twoMontsMiliseconds <
                  currentDate.getTime()
                "
                matIconSuffix
                matTooltip="{{ 'credits.ccrOverTwoMonts' | translate }}"
                matTooltipPosition="above"
              >warning</mat-icon
              >
            </span>
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>
