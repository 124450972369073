import { Component, Input, OnInit, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { ItfgDataTableColumn } from '@app/core/components/data-table/types/data-table.column';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { EmailService } from '../../../../core/services/email.service';
import { SearchDirection, SearchOptions } from '../../../../core/types/search-options';
import { DeserializedEmail, EmailContent } from '../../../../core/types/email';
import { Page } from '../../../../core/types/page';
import { SearchCriterium, SearchOperations } from '../../../../core/types/search-criterium';
import { Credit } from '../../../../core/types/credit';
import { TranslateService } from '@ngx-translate/core';
import { Client } from '../../../../core/types/client';
import { MatDialog } from '@angular/material/dialog';
import { EmailContentPreviewComponent } from './email-content-preview/email-content-preview.component';
import { DialogProviderService } from '@app/core/services/dialog-provider.service';
import { NotificationService } from '../../../../core/services/notification.service';
import { HttpErrorResponse } from '@angular/common/http';
import { EmailTemplateCode } from '../../../../core/types/email-template-code';
import { ClientService } from '../../../../core/services/client.service';
import { ListBaseComponent } from '@app/main/list-base/list-base.component';
import { ColumnsService } from '@app/core/services/columns.service';
import { MatPaginator } from '@angular/material/paginator';
import { LoadingService } from '@app/core/services/loading.service';

@Component({
  selector: 'itfg-client-email',
  templateUrl: './client-email.component.html',
  styleUrls: ['./client-email.component.scss']
})
export class EmailViewComponent extends ListBaseComponent implements OnInit, OnChanges {
  
  @Input()
  credit: Credit;
  @Input()
  client: Client;
  @ViewChild('pagingBarResponsive', { static: true })
  public pagingBarResponsive: MatPaginator;
  // includeAllEmails: boolean = false;
  clientEmailConfirmed: Boolean;
  emailPage: Page<DeserializedEmail> = {};
  emailColumns: ItfgDataTableColumn[] = [];
  searchOptions = new SearchOptions({ pageSize: 5 });

  constructor(
    private emails: EmailService,
    private dialog: MatDialog,
    private loading: LoadingService,
    private translate: TranslateService,
    private dialogService: DialogProviderService,
    private notifications: NotificationService,
    protected clientService: ClientService,
    public columnsService: ColumnsService,
    ) {
      super(columnsService);
    }


    ngOnChanges(changes: SimpleChanges): void {
      this.clientEmailConfirmed = this.client?.emailConfirmed || this.credit?.user?.emailConfirmed;

      if(changes.client) {
        this.setClientSearchCriteria()
        this.updateEmailList()
      }

      if(changes.credit) { //&& changes.credit?.firstChange
        this.setCreditSearchCriteria()
        this.updateEmailList()
      }
    }
    
    ngOnInit(): void {  
    this.emails.onEmailSend.subscribe((email) => {
      this.updateEmailList()
    });
    this.triggerDataChange$.subscribe((searchOptions: SearchOptions) => {
      this.updateEmailList();
      });
      
    this.translate
      .get([
        'templates.sendTime',
        'global.subject',
        'global.credit',
        'global.actions'
      ])
      .subscribe(translation => {
        this.emailColumns = [
          {
            name: 'sent_at',
            label: translation['templates.sendTime'],
            width: 160,
          },
          { name: 'template.name', label: translation['global.subject'] },
          {
            name: 'credit_id',
            label: translation['global.credit'],
            width: 120,
          },
          // {
          //   name: 'to',
          //   label: translation['global.email'],
          //   width: { max: 260 },
          // },
          {
            name: 'actions',
            label: translation['global.actions'],
            width: 150,
            numeric: true,
          },
        ];
      });

      this.clientService.onClientSaved
      .subscribe(client => {
        this.clientEmailConfirmed = client.emailConfirmed;
      })
    }


    sendConfirmationEmail(event: MouseEvent) {

      const dialogConfig = {
        message: this.translate.instant('communication.sendConfirmationMessage'),
        disableClose: false,
        // viewContainerRef: this._viewContainerRef,
        title: this.translate.instant('communication.sendConfirmation'),
        cancelButton: this.translate.instant('global.cancel'),
        acceptButton: this.translate.instant('global.confirm'),
      };
      
      this.dialogService
        .openConfirm(dialogConfig)
        .afterClosed()
        .subscribe((accept: boolean) => {
          if (accept) {
            this.emails
              .sendEmailConfirmation(this.client?.id || this.credit?.user?.id,)
              .subscribe(
                () => {
                  this.notifications.showLocalizedSuccessMessage({
                    notificationText: 'communication.mailSuccessfullySent',
                  });
                  this.updateEmailList();
                },
                (error: HttpErrorResponse) => {
                  this.notifications.showLocalizedSuccessMessage({
                    notificationText: error.message,
                  });
                }
              );
          }
        });
    }
  

  onEmailModeChange(event) {
    const DTO = this.searchOptions.getDTO()

    if(DTO.criteria.includes('credit_id')){
      this.setClientSearchCriteria()
    } else {
      this.setCreditSearchCriteria()
    }  
    this.updateEmailList()
  }


  // resendEmail(smsId: number) {
  //   const dialogConfig = {
  //     message: this.translate.instant('communication.resendEmailConfirmMessage'),
  //     disableClose: false,
  //     title: this.translate.instant('communication.resendEmail'),
  //     cancelButton: this.translate.instant('global.cancel'),
  //     acceptButton: this.translate.instant('global.confirm'),
  //   };

  //   this.dialogService
  //     .openConfirm(dialogConfig)
  //     .afterClosed()
  //     .subscribe((accept: boolean) => {
  //       if (accept) {
  //         this.emails.resendEmail(smsId).subscribe(
  //           () => {
  //             this.notifications.showLocalizedSuccessMessage({
  //               notificationText: 'communication.successfullyResentEmail',
  //             });
  //             this.updateEmailList();
  //           },
  //           (error: HttpErrorResponse) =>
  //             this.notifications.showLocalizedErrorMessage({
  //               notificationText: error.message
  //           })
  //         );
  //       }
  //     });
  // }


  
  viewSentEmail(email: DeserializedEmail) {
    this.emails
      .getEmailContentById(email.id)
      .subscribe((emailContent: EmailContent) => {
        this.dialog.open(EmailContentPreviewComponent, {
          data: { email: emailContent },
          panelClass: 'email-dialog',
          restoreFocus: false,
        });
      });
  }


  getEmailList(): Observable<Page<DeserializedEmail>> {
    return this.emails.getEmailList({
      params: this.searchOptions.getDTO(),
    });
  }

  setCreditSearchCriteria() {
    this.searchOptions.criteria =  [{
      key: 'credit_id',
      operation: SearchOperations.EQUALS,
      value: this.credit.id,
    }]
  }

  setClientSearchCriteria() {
    this.searchOptions.criteria = [{
      key: 'user_id',
      operation: SearchOperations.EQUALS,
      value: this.client?.id || this.credit?.user?.id,
    }]
  }

  // initializeSearchCriteria() {


  //   if(this.credit){
  //     console.log(this.creditCriteria[0].value, 'credit')
  //     this.searchOptions.criteria = this.creditCriteria
  //   } else {
  //     console.log(this.clientCriteria[0].value, 'clietn')
  //     this.searchOptions.criteria = this.clientCriteria
  //   }
      
  // }

  pageChanged(page) {
    super.pageChanged(page);
    this.updateEmailList();
  }

  updateEmailList() {
    this.loading.register('emailListLoading');
    this.emails.getEmailList({ params: this.searchOptions.getDTO() })
      .pipe(
        finalize(() => {
          this.loading.resolve('emailListLoading');
        })
      )
      .subscribe((emailPage: Page<DeserializedEmail>) => {
        this.emailPage = emailPage;
      });
  }
}