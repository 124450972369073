import { Component, OnInit, Optional, Output, EventEmitter } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import * as moment from 'moment/moment';

@Component({
  selector: 'itfg-reminder-choose-custom-option',
  templateUrl: './reminder-choose-custom-option.component.html',
  styleUrls: ['./reminder-choose-custom-option.component.scss']
})
export class ReminderChooseCustomOptionComponent implements OnInit {

  selectedDate = new Date();
  selectedHour: any = { h: 0, m: 0, s: 0 };
  hours: any[] = this.generateHours(new Date());
  now = new Date();
  @Output() dateChosenEmitter: EventEmitter<any> = new EventEmitter();

  constructor(
    @Optional()
    private dialogRef: MatDialogRef<ReminderChooseCustomOptionComponent>
  ) { }

  ngOnInit(): void {
  }

  generateHours(date) {
    const hours = [];
    for (let i = 9; i <= 17; i++) {
      const options = { h: i, m: 0, s: 0 };
      hours.push({
        options,
        display: moment(date).set(options).format('HH:mm'),
        disabled: moment(date).set(options).subtract({ minute: 10 }).isBefore(moment()),
        selected: false,
      });
    }
    return hours;
  }

  changeSelection(index) {
    this.hours.map(h => {
      h.selected = false;
      return h;
    });
    this.selectedHour = this.hours[index];
    this.hours[index].selected = true;
  }

  dateChanged(event) {
    this.hours = this.generateHours(event);
  }

  onNoClick() {
    this.dialogRef.close();
  }

  save() {
    const d = moment(this.selectedDate).set(this.selectedHour.options);
    this.dateChosenEmitter.emit(d);
  }

}
