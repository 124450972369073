import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {IdCardStatus, IdCardVerificationOptions} from '../../../../../core/types/id-card-verified';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {ID_CARD_STATUS_STYLE_MAP} from '../../../../../config/id-card-status-config';
import {ClientService} from '../../../../../core/services/client.service';
import {Client} from '../../../../../core/types/client';

export interface IdCArdStatusEditComponentInputData {
  client: Client;
  idCardStatus: IdCardStatus;
  statusList: IdCardVerificationOptions;
}
@Component({
  selector: 'itfg-id-card-status-edit',
  templateUrl: './id-card-status-edit.component.html',
  styleUrls: ['./id-card-status-edit.component.scss']
})
export class IdCardStatusEditComponent implements OnInit {

  public statusForm: UntypedFormGroup;
  public status: any;
  idCardStatusStyleMap: any = ID_CARD_STATUS_STYLE_MAP;

  constructor(@Inject(MAT_DIALOG_DATA)public data: IdCArdStatusEditComponentInputData,
              private fb: UntypedFormBuilder,
              private clientService: ClientService,
              private dialogRef: MatDialogRef<IdCardStatusEditComponent>,
  ) {
    this.status = this.data.idCardStatus;
    this.statusForm = this.fb.group({
      status: this.status.name
    });
  }

  ngOnInit(): void {

  }
  onSubmit () {
    const statusValue = this.data.statusList.optionsList.filter(option => option.name === this.statusForm.get('status').value)[0].value;
    this.clientService.idCardVerified$(this.data.client.id, statusValue).subscribe(client => {
      this.data.client = client;
      this.clientService.onClientDataChange.next(client);
      this.dialogRef.close(true);
    },
      err => {
        console.log(err);
        this.dialogRef.close(false);
      });

  }

}
