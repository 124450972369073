import {Credit} from './credit';
import {PaymentPromise} from './payment-promise';

export interface PaymentAgreement {
  id: number;
  approvedAt: Date;
  status: PaymentAgreementStatus;
  amount: number;
  dueDate: string;
  discount: number;
  discountLimit?: number;
  discountPercentLimit?: number;
  credit?: Credit;
  paymentAmount?: number;
  paymentPromises?: PaymentPromise[];
}
export interface PaymentAgreementStatus {
  name: string;
  id: number;
}
export enum PaymentAgreementStatusNames {
  DRAFT = 'DRAFT',
  APPROVED = 'APPROVED',
  ACTIVE = 'ACTIVE',
  CANCELED = 'CANCELED',
  PAID = 'PAID'
}
