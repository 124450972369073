import {StyleConfigMap} from '@app/config/credit-config';

export interface VerificationStatus {
  id: number;
  name: string;
  reason: string;
}

export enum VerificationStatusNames {
  VALID = 'VALID',
  INVALID = 'INVALID',
  PENDING_MANUAL = 'PENDING_MANUAL',
  PENDING_AUTO = 'PENDING_AUTO'
}

export enum CombinedStatusNameType {
  VALID = 'VALID',
  INVALID = 'INVALID',
  PENDING_AUTO = 'PENDING_AUTO',
  PENDING_MANUAL = 'PENDING_MANUAL',
  PENDING_FILE = 'PENDING_FILE',
  PENDING_VALIDATION = 'PENDING_VALIDATION',
  UNKNOWN = 'UNKNOWN',
}

export enum VerificationStatusReasons {
  NEW_CLIENT = 'NEW_CLIENT',
  FACE_VERIFIED = 'FACE_VERIFIED',
  UPLOADED = 'UPLOADED',
  CANCELED = 'CANCELED',
  RETRY = 'RETRY',
  SEVICE_UNAVAILABLE = 'SEVICE_UNAVAILABLE',
  DUPLICATED_ACCOUNT = 'DUPLICATED_ACCOUNT',
  DOCUMENT_NOT_SUPPORTED = 'DOCUMENT_NOT_SUPPORTED',
  DOCUMENT_BAD_QUALITY = 'DOCUMENT_BAD_QUALITY',
  TECHNICAL_ISSUES = 'TECHNICAL_ISSUES',
  NEW_CREDIT = 'NEW_CREDIT',
  VERIFICATION_UPDATE = 'VERIFICATION_UPDATE',
  PROCESSING_AUTO = 'PROCESSING_AUTO',
  DOCUMENT_VERIFIED = 'DOCUMENT_VERIFIED',
  LOW_QUALITY = 'LOW_QUALITY',
  ID_NOT_MATCH = 'ID_NOT_MATCH',
  DOCUMENT_DAMAGED = 'DOCUMENT_DAMAGED',
  FRAUD = 'FRAUD',
  FACE_NOT_MATCH = 'FACE_NOT_MATCH',
  IMAGE_CORRUPTED = 'IMAGE_CORRUPTED',
  DOCUMENT_CORRUPTED = 'DOCUMENT_CORRUPTED',
  DOCUMENT_COPY = 'DOCUMENT_COPY',
  DATA_NOT_MATCH = 'DATA_NOT_MATCH',
  DOCUMENT_EXPIRED = 'DOCUMENT_EXPIRED',
  UNKNOWN = 'UNKNOWN',
  FACE_BACKGROUND = 'FACE_BACKGROUND',
  PROOF_NOT_MATCH = 'PROOF_NOT_MATCH',
  MISSING_PROOF = 'MISSING_PROOF',
  FACE_NOT_VERIFIED = 'FACE_NOT_VERIFIED',
  IMAGE_BAD_QUALITY = 'IMAGE_BAD_QUALITY'
}


export enum VerificationStatusType {
  FACE = 'face',
  ID_CARD = 'id-card',
  EMAIL = 'email',
  MOBILE = 'mobile',
}
export const VERIFICATION_STATUS_STYLE_MAP: StyleConfigMap = {
  [VerificationStatusNames.VALID]: {
    backgroundColor: 'green',
    icon: 'done',
  },
  [VerificationStatusNames.INVALID]: {
    backgroundColor: 'red',
    icon: 'cancel',
  },
  [VerificationStatusNames.PENDING_MANUAL]: {
    backgroundColor: '#f18408',
    icon: 'person',
  },
  [VerificationStatusNames.PENDING_AUTO]: {
    backgroundColor: '#2353ef',
    icon: 'autorenew',
  }
};

export const VERIFICATION_STATUS_COMBINED_STYLE_MAP: StyleConfigMap = {
  [CombinedStatusNameType.VALID]: {
    backgroundColor: 'green',
    icon: 'verified_user',
  },
  [VerificationStatusNames.INVALID]: {
    backgroundColor: 'red',
    icon: 'cancel',
  },
  [CombinedStatusNameType.PENDING_VALIDATION]: {
    backgroundColor: '#f18408',
    icon: 'pending_actions',
  },
  [CombinedStatusNameType.PENDING_FILE]: {
    backgroundColor: '#2353ef',
    icon: 'pending',
  },
  [CombinedStatusNameType.PENDING_AUTO]: {
    backgroundColor: '#2353ef',
    icon: 'autorenew',
  }
}