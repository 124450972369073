import {
  Directive,
  Input,
  AfterViewInit,
  ContentChild,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { Observable } from 'rxjs';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { DataTableComponent } from '@app/core/components/data-table/components/data-table.component';

@Directive({
  selector: '[itfgScrollIntoView]',
})
export class ScrollElementIntoViewDirective
  implements AfterViewInit, OnChanges {
  @Input() itfgScrollIntoView: boolean;
  @Input() onTabSelected: Observable<MatTabChangeEvent>;
  @ContentChild(DataTableComponent)
  table: DataTableComponent;
  scrollableElement;

  constructor() {}

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.onTabSelected &&
      this.onTabSelected &&
      this.itfgScrollIntoView
    ) {
      this.scrollRowIntoView();
    }
  }

  ngAfterViewInit(): void {
    // TODO: FIX THIS
    // this.scrollableElement = this.table?._scrollableDiv.nativeElement;
    this.scrollRowIntoView();
  }

  scrollRowIntoView() {
    const targetIndex = this.findTargetColumnIndex(
      this.table?.data,
      this.itfgScrollIntoView
    );
    const elementPosition = targetIndex + 1;
    const pos = (elementPosition - 3) * 48;

    if (pos > 0) {
      setTimeout(
        () => this.scrollableElement.scrollTo({ top: pos, behavior: 'auto' }),
        0
      );
    }
  }

  findTargetColumnIndex(data, target) {
    return data ? data.indexOf(target, 0) : 0;
  }
}
