import { Pipe, PipeTransform } from '@angular/core';
import { BlacklistTypeName } from '../../../core/types/blacklist-type';

@Pipe({
  name: 'blacklistTypesTranslation',
})
export class BlacklistTypesTranslationPipe implements PipeTransform {
  static readonly relationTypesTranslationMap: { [key: string]: string } = {
    [BlacklistTypeName.CIVIL]: 'filtering.blacklistTypes.civil',
    [BlacklistTypeName.EMPLOYER]: 'filtering.blacklistTypes.employer',
    [BlacklistTypeName.PHONE]: 'filtering.blacklistTypes.phone',
  };

  transform(value: string): string {
    return BlacklistTypesTranslationPipe.relationTypesTranslationMap[value];
  }
}
