import { ValidationErrors, UntypedFormControl } from '@angular/forms';

export const fileValidation = {
  maxSize,
};

function maxSize(control: UntypedFormControl): ValidationErrors{
  const file = control.value;

  if (file) {
      const fileSize = file.size;
    const fileSizeInMB = fileSize / 1024 / 1024;
    if (fileSizeInMB > 9) {
        return {
          maxSize: true
        };
      } else {
        return null;
      }
    }

  return null;
}
