import { FilterValueListService, FilterValue } from '../filter/types';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CallStatus } from '@app/core/types/call';

@Injectable()
export class CallStatusValueListService implements FilterValueListService {
  constructor(private translate: TranslateService) { }

  getFilterValues$(): FilterValue[] {
    const statusMappings = {
      'communication.callStatuses.answer': [CallStatus.ANSWER, CallStatus.AGENT_CONNECTED],
      'communication.callStatuses.noAnswer': [CallStatus.NO_ANSWER],
      'communication.callStatuses.busy': [CallStatus.BUSY],
      'communication.callStatuses.failed': [CallStatus.FAILED],
      'communication.callStatuses.initiated': [CallStatus.INITIATED],
      'communication.callStatuses.ongoing': [CallStatus.ONGOING],
      'communication.callStatuses.connectionFailed': [CallStatus.CONNECTION_FAILED],
      'communication.callStatuses.agentFreeCallbackRequested': [CallStatus.AGENT_FREE_CALLBACK_REQUESTED, CallStatus.AGENT_FREE_NO_CALLBACK_REQUESTED],
      'communication.callStatuses.allBusyCallbackRequested': [CallStatus.ALL_BUSY_CALLBACK_REQUESTED, CallStatus.ALL_BUSY_NO_CALLBACK_REQUESTED],
      'communication.callStatuses.hangupAtWelcome': [CallStatus.HANGUP_AT_WELCOME],
      'communication.callStatuses.nonWorkingHoursCallbackRequested': [CallStatus.NON_WORKING_HOURS_CALLBACK_REQUESTED, CallStatus.NON_WORKING_HOURS_NO_CALLBACK_REQUESTED]
    };

    const filterValues: FilterValue[] = [];

    Object.keys(statusMappings).forEach(key => {
      // Join using a special delimiter that does not occur in any status strings
      filterValues.push({
        value: statusMappings[key].join('__'), // Double underscore as a delimiter
        displayText: this.translate.instant(key)
      });
    });

    return filterValues;
  }
}
