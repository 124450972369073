import { filter } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';

@Component({
  selector: 'itfg-resource-not-found',
  templateUrl: './resource-not-found.component.html',
  styleUrls: ['./resource-not-found.component.scss'],
})
export class ResourceNotFoundComponent implements OnInit {
  public data: string;

  constructor(private activatedRoute: ActivatedRoute, private router: Router) {
    this.data = '';
  }

  ngOnInit() {
    this.activatedRoute.paramMap
      .pipe(filter(params => typeof params.get('titleKey') === 'string'))
      .subscribe((params: ParamMap) => (this.data = params.get('titleKey')));
    this.router.navigate(['resource-not-found', { titleKey: this.data }], { skipLocationChange: true });
  }
}
