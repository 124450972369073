import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import { IdentityReportsService } from '@app/core/services/identity-reports.service';
import { IdentityDocument } from '@app/core/types/client-document';
import { FaceVerification, IdentityReport, MIReportData } from '@app/core/types/identity-reports';

@Component({
  selector: 'itfg-id-card-face-verification',
  templateUrl: './id-card-face-verification.component.html',
  styleUrls: ['./id-card-face-verification.component.scss']
})
export class IdCardFaceVerificationComponent implements OnChanges {

  faceVerification: FaceVerification = null;

  constructor(
    private identityReports: IdentityReportsService,
  ) {

  }

  @Input()
  loadedMiReportId: number;

  @Input()
  loadedIdCardDocumentId: number;

  @Input()
  civilId: string;

  ngOnChanges(changes: SimpleChanges): void {
    if (this.loadedIdCardDocumentId && this.loadedMiReportId && this.civilId) {
      this.getFaceVerification(this.civilId, this.loadedMiReportId, this.loadedIdCardDocumentId);
    }
  }

  getFaceVerification(civil_id: string, mi_report_id: number, document_id: number) {
    // TODO: Cache combinations ?
    const combinationChanged = !!this.faceVerification && (this.faceVerification.faceSourceId != mi_report_id
                                    || this.faceVerification.documentSourceId != document_id
                                    || this.faceVerification.userCivilId != civil_id);
    if (!this.faceVerification || combinationChanged) {
      this.faceVerification = null;
      this.identityReports.getLatestFaceVerificationReport$(
        civil_id,
        mi_report_id,
        document_id
      ).subscribe(res => {
        this.faceVerification = res;
      });
    }
  }

}
