import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {TagChipsComponent} from './tag-chips/tag-chips.component';
import {ColorPickerModule} from 'ngx-color-picker';
import {MatBadgeModule} from '@angular/material/badge';
import {MatInputModule} from '@angular/material/input';
import {TranslateModule} from '@ngx-translate/core';
import {MatDialogModule} from '@angular/material/dialog';
import { DataTableModule } from '@app/core/components/data-table/data-table.module';
import {MatButtonModule} from '@angular/material/button';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgxPermissionsModule} from 'ngx-permissions';
import {MatIconModule} from '@angular/material/icon';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MaterialModule} from '../../lib/material.module';
import {AddTagComponent} from './add-tag/add-tag.component';
import { MatChipsModule } from '@angular/material/chips';


@NgModule({
  declarations: [TagChipsComponent, AddTagComponent],
  imports: [
    CommonModule,
    MaterialModule,
    NgxPermissionsModule,
    TranslateModule,
    MatChipsModule,
    DataTableModule,
    MatButtonModule,
    MatTooltipModule,
    MatIconModule,
    MatDialogModule,
    FormsModule,
    ReactiveFormsModule,
    MatBadgeModule,
    MatFormFieldModule,
    ColorPickerModule,
    MatInputModule,
  ],
  exports: [TagChipsComponent, AddTagComponent],
})
export class TagsModule { }
