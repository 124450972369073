<div class="container"
     [ngStyle]="containerStyle">
  <table mat-table
         [dataSource]="data"
         matSort
         (matSortChange)="onSortChange($event)">
    <ng-container [matColumnDef]="column.name"
                  [ngSwitch]="column.name"
                  *ngFor="let column of columns; let i = index; trackBy: trackByMethod">
      <ng-container *ngIf="isSortable else notSortable">
        <th mat-sort-header
            [style.min-width]="
            column.width?.min ? column.width.min + 'px' : 'inherit'
          "
            [style.max-width]="
            column.width?.max ? column.width.max + 'px' : 'inherit'
          "
            [style.width]="column.width ? column.width + 'px' : 'inherit'"
            [ngClass]="{ numeric: column.numeric }"
            *matHeaderCellDef>
          <div layout="row"
               layout-align="start center">
            <span *ngIf="column.labelTooltip"
                  [matTooltip]="column.labelTooltip"
                  matTooltipPosition="above">
              {{ column.label }}
            </span>
            <span *ngIf="!column.labelTooltip">
              {{ column.label }}
            </span>

            <ng-container *ngIf="column.filterConfig">
              <button mat-icon-button
                      #trigger="matMenuTrigger"
                      [matMenuTriggerFor]="filterMenu"
                      [matMenuTriggerData]="{ close: false }"
                      [ngStyle]="{'color': column.filterConfig.currentValue?.length ? 'red' : 'inherit'}"
                      type="button">
                <mat-icon>arrow_drop_down</mat-icon>
              </button>

              <mat-menu #filterMenu="matMenu" class="filter-menu">
                <itfg-column-filter-card [filterConfig]="column.filterConfig"
                                         [menuTrigger]="trigger"
                                         [filterOptions]="column.filterConfig?.valueList"
                                         (filterConfigValueChange)="onFilterValueChange()">
                </itfg-column-filter-card>
              </mat-menu>
            </ng-container>
          </div>
        </th>
      </ng-container>

      <ng-template #notSortable>
        <th mat-header-cell
            [style.min-width]="
          column.width?.min ? column.width.min + 'px' : 'inherit'
        "
            [style.max-width]="
          column.width?.max ? column.width.max + 'px' : 'inherit'
        "
            [style.width]="column.width ? column.width + 'px' : 'inherit'"
            [ngClass]="{ numeric: column.numeric }"
            *matHeaderCellDef>
          <div layout="row"
               layout-align="start center">
            <span *ngIf="column.labelTooltip"
                  [matTooltip]="column.labelTooltip"
                  matTooltipPosition="above">
              {{ column.label }}
            </span>
            <span *ngIf="!column.labelTooltip">
              {{ column.label }}
            </span>

            <ng-container *ngIf="column.filterConfig">
              <button mat-icon-button
                      #trigger="matMenuTrigger"
                      [matMenuTriggerFor]="filterMenu"
                      [matMenuTriggerData]="{ close: false }"
                      [ngStyle]="{'color': column.filterConfig.currentValue?.length ? 'red' : 'inherit'}"
                      type="button">
                <mat-icon>arrow_drop_down</mat-icon>
              </button>

              <mat-menu #filterMenu="matMenu" class="filter-menu">
                <itfg-column-filter-card [filterConfig]="column.filterConfig"
                                         [menuTrigger]="trigger"
                                         [filterOptions]="column.filterConfig?.valueList"
                                         (filterConfigValueChange)="onFilterValueChange()">
                </itfg-column-filter-card>
              </mat-menu>
            </ng-container>
          </div>
        </th>
      </ng-template>

      <ng-container *ngIf="!getTemplateRef(column.name)">
        <td mat-cell
            [ngClass]="{ numeric: column.numeric, 'font-weight-bold': column.isBold }"
            *matCellDef="let element"
            [style.min-width]="
            column.width?.min ? column.width.min + 'px' : 'inherit'
          "
            [style.max-width]="
            column.width?.max ? column.width.max + 'px' : 'inherit'
          "
            [style.width]="column.width ? column.width + 'px' : 'inherit'">
          {{
          column.format
          ? column.format(getCellValue(column, element))
          : getCellValue(column, element)
          }}
        </td>
      </ng-container>

      <ng-container *ngIf="getTemplateRef(column.name)">
        <td mat-cell
            *matCellDef="let element"
            [ngClass]="{ numeric: column.numeric }"
            [style.min-width]="
            column.width?.min ? column.width.min + 'px' : 'inherit'
          "
            [style.max-width]="
            column.width?.max ? column.width.max + 'px' : 'inherit'
          "
            [style.width]="column.width ? column.width + 'px' : 'inherit'">
          <ng-template [ngTemplateOutlet]="getTemplateRef(column.name)"
                       [ngTemplateOutletContext]="{
              value: getCellValue(column, element),
              row: element,
              column: column.name
            }">
          </ng-template>
        </td>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="showHeader">
      <tr mat-header-row
          *matHeaderRowDef="shownColumns; sticky: stickyHeader"></tr>
    </ng-container>
    <tr mat-row
        *matRowDef="let element; columns: shownColumns"
        [ngClass]="{'highlight' : _selectedRowIndex === element.id, 'cursor-pointer' : isClickable }"
        [ngStyle]="element?.cssStyle || {}"
        (click)="rowClicked(element, $event)"
        >
    </tr>
  </table>
</div>
