import { finalize, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpContext, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { APP_CONFIG, HttpRequestOptions } from '../../config/app-config';
import { WebHttpUrlEncodingCodec } from '../utils/webHttpUrlEncodingCodec';
import { LoadingMode, LoadingService, LoadingType } from './loading.service';

@Injectable()
export class RequestService {
  protected apiUrl: string;
  private http: HttpClient;
  onPostRequest: Subject<void> = new Subject<void>();

  constructor(http: HttpClient, private loading: LoadingService) {
    this.apiUrl = APP_CONFIG.CONNECTION.API.ADMIN;
    this.http = http;
    this.loading = loading;
    this.loading.create({
      name: 'requestLoading',
      mode: LoadingMode.Indeterminate,
      type: LoadingType.Linear,
      color: 'accent',
    });
  }

  set apIUrl(url: string) {
    if (url[url.length - 1] !== '/') {
      url += '/';
    }
    this.apiUrl = url;
  }

  get apIUrl() {
    return this.apiUrl;
  }

  makeRequest(
    method: string,
    route: (string | number)[],
    options?: HttpRequestOptions
  ): Observable<any> {
    const path = route.join('/');
    // this.loading.register('requestLoading');

    let request$ = this.http.request(method, this.apiUrl + path, options || {});

    request$ = request$.pipe(
      finalize(() => {
        // this.loading.resolve('requestLoading');
      })
    );

    return request$;
  }

  get(route: (string | number)[], options?: HttpRequestOptions): Observable<any> {
    if (options && options.params) {
      let params = new HttpParams({ encoder: new WebHttpUrlEncodingCodec() });
      const keyValuePair = Object.entries(options.params);
      keyValuePair.forEach(pair => {
        const key = pair[0];
        const value = pair[1];
        if (Array.isArray(value)) {
          value.forEach(entry => {
            params = params.append(key, entry);
          });
        } else {
          params = params.set(key, <string>value);
        }
      });
      options.params = params;
    }
    return this.makeRequest('GET', route, options);
  }


  post(route: (string | number)[], options?: HttpRequestOptions): Observable<any> {
    if (options && options.params) {
      let params = new HttpParams({ encoder: new WebHttpUrlEncodingCodec() });
      const keyValuePair = Object.entries(options.params);
      keyValuePair.forEach(pair => {
        params = params.set(pair[0], <string>pair[1]);
      });
      options.params = params;
    }
    return this.makeRequest('POST', route, options).pipe(
      tap({
        complete: () => {
          return this.onPostRequest.next();
        },
      })
    );
  }

  put(route: (string | number)[], options?: HttpRequestOptions): Observable<any> {
    if (options && options.params) {
      let params = new HttpParams({ encoder: new WebHttpUrlEncodingCodec() });
      const keyValuePair = Object.entries(options.params);
      keyValuePair.forEach(pair => {
        params = params.set(pair[0], <string>pair[1]);
      });
      options.params = params;
    }
    return this.makeRequest('PUT', route, options).pipe(
      tap({
        complete: () => {
          return this.onPostRequest.next();
        },
      })
    );
  }

  patch(route: (string | number)[], options?: any): Observable<any> {
    if (options && options.params) {
      let params = new HttpParams({ encoder: new WebHttpUrlEncodingCodec() });
      const keyValuePair = Object.entries(options.params);
      keyValuePair.forEach(pair => {
        params = params.set(pair[0], <string>pair[1]);
      });
      options.params = params;
    }
    return this.makeRequest('PATCH', route, options).pipe(
      tap({
        complete: () => {
          return this.onPostRequest.next();
        },
      })
    );
  }


  delete(route: (string | number)[], options?: HttpRequestOptions): Observable<any> {
    return this.makeRequest('DELETE', route, options);
  }

  options(route: string[], options?: HttpRequestOptions): Observable<any> {
    return this.makeRequest('OPTIONS', route, options);
  }
}
