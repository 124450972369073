import { Component, OnInit, Input, ViewChild, OnDestroy } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { EventLogService } from '../../../core/services/event-log.service';

@Component({
  selector: 'itfg-search-in-event-log-button',
  templateUrl: './search-in-event-log-button.component.html',
  styleUrls: ['./search-in-event-log-button.component.scss'],
})
export class SearchInEventLogButtonComponent implements OnInit, OnDestroy {
  @Input() control: UntypedFormControl;


  constructor( private eventLogService: EventLogService ) {}

  ngOnInit() {
  }

  searchInEventLog(event) {
    this.eventLogService.openEventLog.next(true);
    this.eventLogService.searchInEventLog.next(this.control.value);
  }

  ngOnDestroy(): void {
    this.eventLogService.searchInEventLog.next(null);
  }
}
