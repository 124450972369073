import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { CallService } from '@app/core/services/call.service';
import { NotificationService } from '@app/core/services/notification.service';
import { Call, CallDirection, CallIncomingStatuses, CallOutgoingStatuses, CallStatus } from '@app/core/types/call';
import { EventLogStateService } from '@app/main/main-layout/right-drawer/event-log/event-log-state.service';

@Component({
  selector: 'itfg-call-direction-and-status-icon',
  templateUrl: './call-direction-and-status-icon.component.html',
  styleUrls: ['./call-direction-and-status-icon.component.scss'],
})
export class CallDirectionStatusIconComponent implements OnChanges {
  @Input() call: Call;
  @Input() callDirection: CallDirection;
  @Input() callStatus: CallStatus;
  selectedStatus: CallStatus;
  callStatusOptions: any[] = Object.values(CallStatus);
  callStatuses: typeof CallStatus = CallStatus;
  callDirections: typeof CallDirection = CallDirection;
  callOutgoingTypes: any[] = Object.values(CallOutgoingStatuses);
  callIncomingTypes: any[] = Object.values(CallIncomingStatuses);

  constructor(
    private callService: CallService, 
    private eventLogStateService: EventLogStateService,
    private notificationService: NotificationService,
  ) {}
  
  ngOnChanges(changes: SimpleChanges): void {
    if (this.call && changes.call.previousValue !== changes.call.currentValue) {
      this.selectedStatus = null;
      this.callStatusOptions = this.call.direction === CallDirection.OUT ? this.callOutgoingTypes : this.callIncomingTypes;
    }
  }

  onSaveCallStatus() {
    const callStatus = Array.isArray(this.selectedStatus) ? this.selectedStatus[0] : this.selectedStatus;
    const callDto = { status: callStatus };

    const modifiedCallDto = { ...callDto, reference: this.call.id };
      this.callService.updateCall(this.call.id, modifiedCallDto).subscribe({
        next: (call: Call) => {
          this.eventLogStateService.setState({ 
            call: call,
            phoneNumber: call.phoneNumber,
          });
          this.notificationService.showLocalizedSuccessMessage({
            notificationText: 'pbx.successfullySetCallStatusMessage'
          });
        },
        error: (error: HttpErrorResponse) => {
          this.notificationService.showLocalizedErrorMessage({
            notificationText: 'pbx.unsuccessfullySetCallStatusMessage'
          });
        }
      });
  }
}
