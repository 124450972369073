import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'administratorEmailToDisplayText',
})
export class AdministratorEmailToDisplayTextPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    return (
      value &&
      value.slice(0, value.indexOf('@') > 0 ? value.indexOf('@') : value.length)
    );
  }
}
