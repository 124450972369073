import { RequestService } from './request.service';
import { Observable } from 'rxjs';
import { Page } from '../types/page';
import { DCA } from '../types/dca';
import { Injectable } from '@angular/core';

@Injectable()
export class DCAService {
  constructor(private request: RequestService) {}

  getDCAList$(options?: any): Observable<Page<DCA>> {
    return this.request.get(['dca'], { params: options });
  }

  saveDCA$(dca: DCA, options: any = {}): Observable<DCA> {
    const requestOptions = Object.assign(options, { body: dca });
    return this.request.post(['dca'], requestOptions);
  }

  orderDCA$(dcaIds: Array<string | number>): Observable<null> {
    return this.request.post(['dca', 'order'], { body: dcaIds });
  }

  deleteDCA$(dcaId: string | number): Observable<any> {
    return this.request.delete(['dca', dcaId]);
  }
}
