import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {Tag} from '../../../core/types/tag';
import { UntypedFormControl, Validators } from '@angular/forms';
import { TagService } from '../../../core/services/tag.service';
import { map, startWith, takeUntil } from 'rxjs/operators';
import { Page } from '../../../core/types/page';
import { Observable, Subject } from 'rxjs';
import { SearchOptions } from '../../../core/types/search-options';
import { CreditService } from '../../../core/services/credit.service';
import { NotificationService } from '../../../core/services/notification.service';
import { AutoCompleteValidation } from '../../../core/validation/autocomplete-validation';

@Component({
  selector: 'itfg-add-tag',
  templateUrl: './add-tag.component.html',
  styleUrls: ['./add-tag.component.scss'],
})
export class AddTagComponent implements OnInit, OnDestroy {
  public tagControl = new UntypedFormControl(null, [
    Validators.required,
    AutoCompleteValidation.isAutocompleteValidObject('notValidTag', 'id'),
  ]);
  private id: number;
  private tags: Tag[] = [];
  private tagType: string;
  private shownTags: Tag[] = [];
  public filteredShownTags$: Observable<Tag[]>;
  public _unsubscribe = new Subject<void>();
  private _filter(value: string): Tag[] {
    return value
      ? this.shownTags.filter(tag =>
          tag.name.toLowerCase().includes(value.toLowerCase())
        )
      : this.shownTags.slice();
  }

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: any,
    public dialogRef: MatDialogRef<AddTagComponent>,
    private readonly tagService: TagService,
    private readonly creditService: CreditService,
    private readonly notifications: NotificationService
  ) {
    this.tags = data.tags;
    this.id = data.id;
    this.tagType = data.type;
    this.tagService
      .getTagList$(new SearchOptions({ pageSize: 6000 }).getDTO())
      .pipe(map((page: Page<Tag>) => page.content))
      .subscribe((tagList: Tag[]) => {
        this.shownTags = tagList.filter(
          (tag: Tag) => !this.tags.map(ct => ct.id).includes(tag.id)
        );
        this.filteredShownTags$ = this.tagControl.valueChanges.pipe(
          startWith(''),
          map(value => (typeof value === 'string' ? value : value.name)),
          map(value => this._filter(value)),
          takeUntil(this._unsubscribe)
        );
      });
  }

  ngOnInit() {}

  mapTagToDisplayValue(tag: Tag) {
    return tag ? tag.name : undefined;
  }

  onSave(event: any) {
    if (this.tagControl.valid) {

      const options = {
        id: this.id,
        tagId: this.tagControl.value.id,
        tagValue: this.tagControl.value
      };

      this.dialogRef.close(options);

    } else {
      this.notifications.showLocalizedErrorMessage({
        notificationText: 'forms.formSavedIsInvalid',
      });
    }
  }

  ngOnDestroy(): void {
    this._unsubscribe.next();
    this._unsubscribe.complete();
  }
}
