import { Pipe, PipeTransform } from '@angular/core';
import { Topic } from '@app/core/types/topic';

@Pipe({
  name: 'itfgFormatTopics'
})
export class FormatTopicsPipe implements PipeTransform {
  constructor() {}

  transform(topics: Topic[]): string {
    return topics.map(topic => {
      const lastSlashIndex = topic.path.lastIndexOf('/');
      let name = topic.path;
      if (lastSlashIndex !== -1) {
        name = topic.path.substring(lastSlashIndex + 1);
      }

      name = name.replace(/&/g, '/');
      return name;
    }).join(', ');
  }
}
