<!-- <ng-template tdLoading
             tdLoadingMode="indeterminate"
             tdLoadingType="circle"
             tdLoadingStrategy="overlay"
             tdLoadingColor="accent"
             [tdLoadingUntil]="!claimListLoading"> -->
  <itfg-data-table *ngIf="!claimListEmpty; else claimListEmptyBlock"
                 [data]="claimPage.content"
                 [columns]="claimColumns"
                 [isSortable]="true"
                 [sortBy]="sortBy"
                 (sortChange)="sort($event)"
                 [isClickable]="true">
    <ng-template itfgDataTableTemplate="time"
                 let-time="value">
      <itfg-date-format [date]=time></itfg-date-format>
    </ng-template>
    <ng-template itfgDataTableTemplate="user"
                 let-user="value">
      <a routerLink="/clients/{{user.id}}"
         target="_blank"
         flex>
        {{ user.id }}
      </a>
    </ng-template>
    <ng-template itfgDataTableTemplate="completed"
                 let-completed="value">
      <div [ngStyle]="{ 'padding-left': '20px' }">
        <mat-icon *ngIf="completed"
                  class="completed-claim">{{ 'check_circle' }}
        </mat-icon>
        <mat-icon *ngIf="!completed"
                  class="incomplete-claim">{{ 'cancel_circle' }}
        </mat-icon>
      </div>
    </ng-template>
    <ng-template itfgDataTableTemplate="actions"
                 let-claim="row">
      <button mat-icon-button
              [disabled]="['CLAIM_WRITE']| isUnauthorized | async"
              (click)="editClaim(claim)">
        <mat-icon>edit</mat-icon>
      </button>
    </ng-template>
  </itfg-data-table>
<!-- </ng-template> -->
<div [hidden]="claimListEmpty">
  <mat-paginator #pagingBarResponsive
  (page)="pageChanged($event)"
  [pageIndex]="claimPage.number"
  [length]="claimPage.totalElements"
  [pageSize]="claimPage.size"
  [pageSizeOptions]="[20,30,100]"
  showFirstLastButtons>
</mat-paginator>
</div>
<ng-template #claimListEmptyBlock>
  <h3 layout="row"
      layout-align="center center"
      class="no-client-claims"
      flex>
    {{ 'claims.noGrdpClaimsFound' | translate }}
  </h3>
</ng-template>
