export const EgnUtils = {
  generateRandomEgn,
};

function generateRandomDate(start, end) {
  return new Date(
    start.getTime() + Math.random() * (end.getTime() - start.getTime())
  );
}

function generateRandomRegion() {
  return generateNumberInInterval(0, 999);
}

function generateRandomGender() {
  return generateNumberInInterval(0, 1);
}

function padWithZero(number) {
  return number < 10 ? '0' + number : number;
}

function padWithZeroes(number) {
  if (number < 1000 && number > 99) {
    return number;
  } else if (number < 100 && number > 9) {
    return '0' + number;
  } else {
    return '00' + number;
  }
}

function generateNumberInInterval(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

function generateRandomEgn() {
  const args: any = {};
  args.date = generateRandomDate(new Date(1917, 0, 1), new Date(2098, 11, 30));

  args.year = args.date.getFullYear();
  args.month = args.date.getMonth();
  args.day = args.date.getDay();

  if (args.date < new Date(1900, 0, 1)) {
    args.month += 20;
  } else if (args.date > new Date(1999, 11, 31)) {
    args.month += 40;
  }

  // zero based
  args.month += 1;
  args.day += 1;
  args.year = args.year % 100;

  args.regionId = generateRandomRegion();
  args.gender = generateRandomGender();

  args.regionId = initRegion(args.regionId, args.gender);

  return computeValidEGN(args.year, args.month, args.day, args.regionId);
}

function initRegion(regionId, gender) {
  if (regionId % 2 === 0) {
    if (gender === 1) {
      regionId++;
    }
  } else {
    if (gender === 0) {
      regionId++;
    }
  }

  return gender;
}

function computeValidEGN(year, month, day, regionId) {
  const weights = [2, 4, 8, 5, 10, 9, 7, 3, 6];
  year = padWithZero(year);
  month = padWithZero(month);
  day = padWithZero(day);
  regionId = padWithZeroes(regionId);

  const egn = year + '' + month + '' + day + '' + regionId;

  let checkSum = 0;
  for (let i = 0; i < weights.length; ++i) {
    checkSum += weights[i] * Number(egn.charAt(i));
  }

  checkSum = checkSum % 11;
  checkSum = checkSum < 10 ? checkSum : 0;

  return egn + '' + checkSum;
}
