
<div class="container">
  <!-- <div flex layout-align="space-between start" layout="row">
    <div
      class="modal-dialog-title"
      flex
    >
      {{ 'communication.reminder' | translate }}
    </div>
    <button tabindex="-1" mat-icon-button (click)="onNoClick()">
      <mat-icon>clear</mat-icon>
    </button>
  </div> -->

  <div layout="column">
    <mat-card appearance="outlined">
      <mat-card-content>
        <mat-calendar (selectedChange)="dateChanged($event)"
                      #calendar
                      [(selected)]="selectedDate"
                      [minDate]="now">
        </mat-calendar>
      </mat-card-content>
    </mat-card>

    <mat-card appearance="outlined">
      <mat-card-content>
        <mat-chip-listbox aria-label="Fish selection">
          <mat-chip-option *ngFor="let hour of hours; index as i"
                    [selectable]="true"
                    [selected]="hour.selected"
                    [disabled]="hour.disabled"
                    (click)="changeSelection(i)">{{ hour.display }}</mat-chip-option>
        </mat-chip-listbox>
      </mat-card-content>
    </mat-card>
  </div>

  <div class="actions" layout="row" flex layout-align="end">
    <button mat-raised-button (click)="save()" type="button">
       {{ 'global.save' | translate }}
    </button>
  </div>

</div>