import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'itfg-progress-bar-dialog',
  templateUrl: './progress-bar-dialog.component.html',
  styleUrls: ['./progress-bar-dialog.component.scss'],
})
export class ProgressBarDialogComponent implements OnInit{
  constructor(
    public dialogRef: MatDialogRef<ProgressBarDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { progressBarValue?: number, title?: string; message?: string, warningMessage: string, expandingMessage?: {title?: string, message?: string}}
  ) {}

  ngOnInit() {
  }

  onNoClick() {
    this.dialogRef.close();
  }

  changeProgressBarValue(completed: number, total: number, step: number = 5) {
    const newValue = Math.ceil((completed / total) * 100);
    if(newValue > this.data.progressBarValue + step || newValue === 100) { // updates progress bar every 5 percent
      this.data.progressBarValue = newValue
    }
  }
}
