import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {Filter, FilterCardConfig, FilterUIType, FilterValue} from '../types';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {Observable} from 'rxjs';
import {debounceTime, takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';

@Component({
  selector: 'itfg-filter-card-select-filters',
  templateUrl: './filter-card-select-filters.component.html',
  styleUrls: ['./filter-card-select-filters.component.scss']
})
export class FilterCardSelectFiltersComponent implements OnInit, OnDestroy {
  public filter: Filter;
  public removeFiltersIndexesList: number[];
  public addFiltersList: Filter[];
  public formGroup: UntypedFormGroup;
  public filteredKeys: string[];
  public filterSearchCtrl: UntypedFormControl = new UntypedFormControl();
  _unsubscribe = new Subject<void>();

  private _filterOptions(value: string): void {
    this.filteredKeys = [];
    const filterValue = value.toLowerCase();

    const filteredOptions = Object.keys(this.data.filtersConfig)
      .filter(key =>
        this.data.filtersConfig[key].translatedKey
          .toLowerCase()
          .includes(filterValue)
      )
      .reduce((obj, key) => {
        obj[key] = this.data.filtersConfig[key];
        return obj;
      }, {});
    this.filteredKeys = Object.keys(filteredOptions);
  }

  constructor(formBuilder: UntypedFormBuilder,
              @Inject(MAT_DIALOG_DATA)
              public data: {
                filtersPreferences,
                filtersConfig: FilterCardConfig;
                operationsListTranslationMap: {
                  [key: string]: string;
                };
              },
              private dialogRef: MatDialogRef<FilterCardSelectFiltersComponent>
  ) {
    this.addFiltersList = [];
    this.removeFiltersIndexesList = [];
    this.formGroup = formBuilder.group({});

  }

  ngOnInit(): void {
    this.addControls();
    this.watchForfilterSearchCtrlValueChanges();
    this.filteredKeys = Object.keys(this.data.filtersConfig);
  }

  onFormSubmit() {
    Object.keys(this.formGroup.controls).forEach(controlKey => {
      const key = controlKey.split(' ')[0];
      const operation = controlKey.split(' ')[1];
      if (!this.formGroup.controls[controlKey].value) {
        if (this.data.filtersPreferences.config) {
          const filterValue = this.data.filtersPreferences.config
            .filter(filter => filter.key === key && filter.operation === operation);
          filterValue.forEach(value => {
            const filterIndex = this.data.filtersPreferences.config.indexOf(value);
            this.removeFiltersIndexesList.push(filterIndex);
          });
        }
      } else {
        const isExisting = this.data.filtersPreferences.config ? this.data.filtersPreferences.config
          .filter(filter => filter.key === key && filter.operation === operation).length > 0 : false;
        const currentFilterValues = this.data.filtersConfig[key].valueListService;

        if (!isExisting) {
          const criterium = {
            key: key,
            operation: operation,
            value: ''
          };
          this.filter = {
            config: this.data.filtersConfig[key],
            criterium: criterium
          };
          this.applyFilterValues(this.filter);
          this.addFiltersList.push(this.filter);
        }
      }
    });
    const result = {
      removeFiltersIndexesList: this.removeFiltersIndexesList.sort((a, b) => b - a),
      addFiltersList: this.addFiltersList
    };
    this.dialogRef.close(result);
  }

  addControls() {
    Object.keys(this.data.filtersConfig).forEach(filterName => {
      this.data.filtersConfig[filterName]['availableOperations'].forEach(operation => {
        const criteria = {
          key: filterName,
          operation: operation,
          value: ''
        };
        const controlState = false;
        const isAppliedFilter = this.data.filtersPreferences.config ? this.data.filtersPreferences.config
          .filter(filter => filter.key === criteria.key && filter.operation === criteria.operation).length > 0 : false;
        this.formGroup.addControl(
          filterName + ' ' + operation,
          new UntypedFormControl(isAppliedFilter)
        );
      });
    });
  }

  watchForfilterSearchCtrlValueChanges() {
    this.filterSearchCtrl.valueChanges
      .pipe(
        takeUntil(this._unsubscribe),
        debounceTime(100)
      )
      .subscribe(() => {
        const searchStr = this.filterSearchCtrl.value;
        this._filterOptions(searchStr);
      });
  }

  slideToggle(controlKey) {
    const currentValue = this.formGroup.controls[controlKey].value;
    this.formGroup.controls[controlKey].setValue(!currentValue);
  }

  applyFilterValues(filter) {
    if (
      filter.config.type === FilterUIType.MULTISELECT ||
      filter.config.type === FilterUIType.MULTISELECT_AUTOCOMPLETE
    ) {
      filter.criterium.value = [];
    } else {
      filter.criterium.value = '';
    }
    if (
      filter.config.valueListService &&
      (filter.config.type === FilterUIType.DROPDOWN ||
        filter.config.type === FilterUIType.MULTISELECT)
    ) {
      const filterValues = filter.config.valueListService.getFilterValues$();
      if (filterValues instanceof Observable) {
        filterValues.subscribe((filterValueList: FilterValue[]) => {
          filter.filterValues = filterValueList;
        });
      } else {
        filter.filterValues = filterValues;
      }
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnDestroy() {
    this._unsubscribe.next();
    this._unsubscribe.complete();
  }
}
