import { UntypedFormGroup, AbstractControl } from '@angular/forms';
import { Injectable } from '@angular/core';

@Injectable()
export class FormManipulationService {
  constructor() {}

  public serializeObjectToQueryString(objectToSerialize: Object): string {
    const params = new URLSearchParams();

    const keys = Object.keys(objectToSerialize);

    keys.forEach((key, index, array) => {
      params.set(key, objectToSerialize[key]);
    });

    return params.toString();
  }

  public createFormData(formGroup: UntypedFormGroup): FormData {
    const formData = new FormData();

    const keys = Object.keys(formGroup.value);
    const values = formGroup.value;

    keys.forEach((key, index, array) => {
      formData.append(key, values[key]);
    });

    return formData;
  }

  public markAllControlsTouched(controlToMark: AbstractControl) {
    controlToMark.markAsTouched();
    if ((<any>controlToMark).controls) {
      Object.values((<any>controlToMark).controls).forEach(innerControl =>
        this.markAllControlsTouched(<AbstractControl>innerControl)
      );
    }
  }

  public resetFormFields(formGroup: UntypedFormGroup, fields: Array<string>) {
    for (const field of fields) {
      const ctrl = formGroup.get(field);
      if (ctrl) {
        ctrl.reset();
      }
    }
  }
}
