<div *ngIf="client?.civilId && client?.id" class="top-padding-16">
  <h3 class="edit-screen-card-title">
    {{ 'compensation.compensation' | translate }}
  </h3>
  <div
    flex
    layout="row"
    layout-wrap
    layout-align="space-between stretch"
  >
    <div flex="100" flex-gt-md="75" flex-gt-sm="100" flex-gt-xs="100">
      <itfg-reports-info-panel
        [listData]="compensationListData"
        [reportData]="compensationData"
        [reportTime]="requestTime"
        [requesterName]="requesterName"
        [metaStatusCode]="''"
        [requestTime]="requestTime"
        (dataChanged)="compensationDataChanged($event)"
      ></itfg-reports-info-panel>
    </div>
  <div flex layout="row" layout-align="end start" [style.min-height.px]="65">
      <itfg-client-differences-button
        [config]="compensationConfig"
      >
      </itfg-client-differences-button>
      <div class="white-space-no-wrap right-padding-16">
        <button
          mat-raised-button
          [disabled]="['COMPENSATION_ADD'] | isUnauthorized | async"
          (click)="getNewCompensationReport()"
        >
          {{ 'compensation.newReport' | translate }}
        </button>
      </div>
    </div>
  </div>
  <div
    *ngIf="compensationListData?.length === 0 && !isReport"
    flex
    layout="row"
    layout-align="start"
    class="left-padding-16"
  >
    <h4
      [translate]="'compensation.noCompensationReportData'"
    >
      No reports
    </h4>
  </div>

  <div *ngIf="isReport">
    <ng-container [ngSwitch]="requestStatus">
      <ng-container *ngSwitchCase="'success'">
        <ng-container
          *ngIf="
            compensationListData?.length > 0 &&
            requestType === CompensationTypes.DISABILITY_COMPENSATION
          "
        >
          <mat-card appearance="outlined">
            <mat-card-header>
              <mat-card-title class="edit-screen-card-title margin-left-8px"
                >{{ 'disabilityCompensation.PaymentData' | translate }}
              </mat-card-title>
            </mat-card-header>
            <mat-card-content>
              <span
                *ngIf="compensationListData.length === 0"
                flex
                layout="row"
                class="left-margin-25px"
                [translate]="'global.noData'"
              >
                No data</span
              >
              <itfg-data-table
                #dataTable
                [data]="paymentData"
                [columns]="disabilityCompensationColumns"
                [isClickable]="true"
              >
                <ng-template
                  itfgDataTableTemplate="BenefitAmount"
                  let-amount="value"
                >
                  {{ amount | toCustomItfgCurrency }}
                </ng-template>
                <ng-template
                  itfgDataTableTemplate="BenefitDatePayment"
                  let-date="value"
                >
                  {{ date | date: 'dd.MM.yyyy' }}
                </ng-template>
              </itfg-data-table>
            </mat-card-content>
          </mat-card>
        </ng-container>

        <ng-container
          *ngIf="
            compensationListData?.length > 0 &&
            requestType === CompensationTypes.UNEMPLOYMENT_COMPENSATION
          "
        >
          <span
            *ngIf="compensationListData?.length === 0"
            flex
            layout="row"
            class="left-margin-25px"
            [translate]="'global.noData'"
          >
            No data</span
          >
          <mat-card appearance="outlined">
            <mat-card-header>
              <mat-card-title class="edit-screen-card-title margin-left-8px"
                >{{ 'unemploymentCompensation.PaymentData' | translate }}
              </mat-card-title>
            </mat-card-header>
            <mat-card-content>
              <span
                *ngIf="compensationListData.length === 0"
                flex
                layout="row"
                class="left-margin-25px"
                [translate]="'global.noData'"
              >
                No data</span
              >
              <div
                *ngFor="
                  let paymentByDate of paymentByDateData
                "
              >
                <h2 class="left-margin-25px">
                  {{ paymentByDate.RegNumberDate }}
                </h2>
                <div *ngFor="let paymentByType of paymentByDate.PaymentsByType">
                  <h4 class="left-margin-25px">
                    {{ paymentByType.BenefitType }}
                  </h4>
                  <itfg-data-table
                    #dataTable
                    [data]="paymentByType.Payments"
                    [columns]="unemploymentCompensationColumns"
                    [isClickable]="true"
                  >
                    <ng-template
                      itfgDataTableTemplate="BenefitAmount"
                      let-amount="value"
                    >
                      {{ amount | toCustomItfgCurrency }}
                    </ng-template>
                  </itfg-data-table>
                </div>
              </div>
            </mat-card-content>
          </mat-card>
        </ng-container>
      </ng-container>

      <ng-container *ngSwitchCase="'incorrect_input'">
        <h4 layout="row" layout-align="start">
          {{ 'compensation.incorrectDataMsg' | translate }}
        </h4>
      </ng-container>

      <ng-container *ngSwitchCase="'not_found'">
        <h4 layout="row" layout-align="start">
          {{
            'compensation.noCompensationDataFound'
              | translate
          }}
        </h4>
      </ng-container>

      <ng-container *ngSwitchCase="'server_timeout'">
        <h4 class="edit-screen-card-title" layout="row" layout-align="start">
          {{
            'reports.serverTimeoutError'
              | translate: { requestTime: requestTime | itfgDateFormat }
          }}
          <itfg-no-reports-info
            [alertMessage]="{
              civilId: client?.civilId,
              type: compensationData?.type,
              source: compensationData?.source,
              status: compensationData?.status,
              time: compensationData?.time,
              data: compensationData?.data
            }"
          >
          </itfg-no-reports-info>
        </h4>
      </ng-container>

      <ng-container *ngSwitchCase="'server_error'">
        <div layout="row" layout="row" layout-align="center">
          <h4 class="edit-screen-card-title">
            {{
              'reports.serverGenericError'
                | translate: { requestTime: requestTime | itfgDateFormat }
            }}
            <itfg-no-reports-info
              [alertMessage]="{
                civilId: client?.civilId,
                type: compensationData?.type,
                source: compensationData?.source,
                status: compensationData?.status,
                time: compensationData?.time,
                data: compensationData?.data
              }"
            >
            </itfg-no-reports-info>
          </h4>
        </div>
      </ng-container>
    </ng-container>

    <div
      flex
      layout="row"
      layout-align="end end"
      *ngIf="requesterName"
      class="margin-right-16px"
    >
      <span> {{ 'global.operator' | translate }}:&nbsp;</span
      ><span>{{ requesterName }}</span>
    </div>
  </div>
</div>
