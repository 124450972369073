import { Component } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';

@Component({
  selector: 'itfg-mat-tab-menu',
  templateUrl: './mat-tab-menu.component.html',
  styleUrls: ['./mat-tab-menu.component.scss'],
})
export class MatTabMenuComponent {
  timedOutCloser: any;
  TIMEOUT_DURATION = 500;

  constructor() { }

  mouseEnter(trigger: MatMenuTrigger) {
    if (this.timedOutCloser) {
      clearTimeout(this.timedOutCloser);
    }
    trigger.openMenu();
  }

  mouseLeave(trigger: MatMenuTrigger) {
    this.timedOutCloser = setTimeout(() => {
      trigger.closeMenu();
    }, this.TIMEOUT_DURATION);
  }
}
