import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClaimListComponent } from './claim-list/claim-list.component';
import { ClaimEditComponent } from './claim-edit/claim-edit.component';
import { MaterialModule } from '../../lib/material.module';
import { SharedModule } from '../../shared/shared.module';
import { FilterModule } from '../filter/filter.module';
import { ClaimComponent } from './claim.component';
import { TranslateModule } from '@ngx-translate/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ClaimRoutingModule } from './claim-routing.module';
import { CardModule } from '../card/card.module';
import { ColumnModule } from '../column/column.module';
import { DataTableModule } from '@app/core/components/data-table/data-table.module';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { ListBaseModule } from '../list-base/list-base.module';

@NgModule({
  imports: [
    CommonModule,
    ColumnModule,
    ClaimRoutingModule,
    CardModule,
    FilterModule,
    MaterialModule,
    DataTableModule,
    MatProgressSpinnerModule,
    DataTableModule,
    TranslateModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    ListBaseModule,
  ],
  declarations: [ClaimComponent, ClaimListComponent, ClaimEditComponent],
  exports: [ClaimListComponent],
  providers: [],
})
export class ClaimModule {}
