import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewContainerRef,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Reminder } from '@app/core/types/reminder';
import { ReminderEditComponent } from '@app/main/credit/credit-edit/credit-communication/reminder-edit/reminder-edit.component';
import { Credit } from '@app/core/types/credit';
import { Subject, filter, takeUntil } from 'rxjs';
import { ReminderService } from '@app/core/services/reminder.service';
import { SearchDirection, SearchOptions } from '@app/core/types/search-options';
import { Page } from '@app/core/types/page';
import { SearchOperations } from '@app/core/types/search-criterium';
import { DialogProviderService } from '@app/core/services/dialog-provider.service';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from '@app/core/services/notification.service';

@Component({
  selector: 'itfg-reminder',
  templateUrl: './reminder.component.html',
  styleUrls: ['./reminder.component.scss'],
})
export class ReminderComponent implements OnChanges, OnInit, OnDestroy {
  @Input() openedCredit: Credit;

  reminder: Reminder;
  isReminderActive: boolean;

  private searchOptions: SearchOptions;

  private _unsubscribe = new Subject<void>();

  constructor(
    private dialog: MatDialog,
    private reminderService: ReminderService,
    private dialogProviderService: DialogProviderService,
    private translate: TranslateService,
    private viewContainerRef: ViewContainerRef,
    private notifications: NotificationService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.openedCredit && changes.openedCredit.currentValue) {
      this.searchOptions = new SearchOptions({
        page: 0,
        pageSize: 5,
        column: 'createdAt',
        direction: SearchDirection.DESCENDING,
        criteria: [
          {
            key: 'credit.id',
            operation: SearchOperations.EQUALS,
            value: this.openedCredit.id,
          },
        ],
      });

      this.getReminder();
    }
  }

  ngOnInit() {
    this.reminderService.refreshReminder$
      .pipe(takeUntil(this._unsubscribe))
      .subscribe(() => {
        this.getReminder();
      });
  }

  openReminderDialog(reminder: Reminder = null) {
    this.dialog.open(ReminderEditComponent, {
      data: {
        reminder,
        credit: this.openedCredit,
      },
      minWidth: '400px',
      restoreFocus: false,
    });
  }

  getReminder() {
    this.reminderService
      .getReminderList$(this.searchOptions.getDTO())
      .subscribe({
        next: (reminder: Page<Reminder>) => {
          if (reminder.content.length > 0) {
            this.reminder = reminder.content[0];
            this.isReminderActive = this.reminderService.reminderIsActive(
              this.reminder
            );
          } else {
            this.reminder = null;

            console.warn('No reminders found.');
          }
        },
        error: error => {
          console.error('Error fetching reminders:', error);
        },
      });
  }

  deactivateReminder(reminder: Reminder) {
    this.dialogProviderService
      .openConfirm({
        message: this.translate.instant(
          'communication.deactivateReminderMessage'
        ),
        disableClose: false,
        viewContainerRef: this.viewContainerRef,
        title: this.translate.instant('global.deactivate'),
        cancelButton: this.translate.instant('global.cancel'),
        acceptButton: this.translate.instant('global.confirm'),
      })
      .afterClosed()
      .subscribe((accept: boolean) => {
        if (accept) {
          this.reminderService
            .deactivateReminder(reminder.id)
            .subscribe(res => {
              this.notifications.showLocalizedSuccessMessage({
                notificationText:
                  'communication.deactivateReminderSuccessMessage',
              });
              this.getReminder();
            });
        }
      });
  }

  ngOnDestroy() {
    this._unsubscribe.next();
    this._unsubscribe.complete();
  }
}
