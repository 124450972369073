<mat-tab-group (selectedIndexChange)="changeSelectedTabIndex($event)" [selectedIndex]="selectedTabIndex">
    <mat-tab>
      <div  flex class="main-container" layout-wrap layout-align="space-evenly end" layout="row">
        <div flex="50" *ngFor="let connectionType of connectionTypes">
          <mat-card class="connections-card" appearance="outlined">
            <mat-card-title class="connections-title">
              {{ translations[connectionType.type] }}
            </mat-card-title>
            <mat-card-content>
              <itfg-chip-nodes 
                      [records]="connectionType.data"
                      (redirectToGraphEvent)="showPathToNode($event, connectionType.type)"
                      (redirectToUserEvent)="redirectToUser($event)"
                      >
              </itfg-chip-nodes>
            </mat-card-content>
          </mat-card>
        </div>
      </div>

  </mat-tab>
  
  <mat-tab>
    <!-- <ng-template matTabContent> -->
      <!-- Error: <rect> attribute width: Expected length, "-Infinity". Error try after graph swimlane@ngx-graph update-->
      
      <div *ngIf="limitReached" class="limit-reached-container">
        <h3>{{'identityReports.graph.nodeLimitReached' | translate}}</h3>
      </div>
  
      <div class="circle-opacity-container" [matTooltip]="('identityReports.graph.backToAllRelations' | translate)">
        <div class="circle-opacity">
          <button
            mat-fab
            (click)="changeSelectedTabIndex(0)"
          >
            <mat-icon>chevron_left</mat-icon>
          </button>
        </div>
      </div>
  
      <div *ngIf="isFiltered" class="remove-filter-container" [matTooltip]="('identityReports.graph.backToInitialRelation' | translate)">
        <div class="remove-filter">
          <button
            mat-fab
            (click)="removeFilter()"
          >
            <mat-icon>clear</mat-icon>
          </button>
        </div>
      </div>
      
      <itfg-graph
        [nodes]="graphData?.nodes"
        [links]="graphData?.links"
        [clientId]="client?.id"
        (filterGraph)="filterGraph($event)"
      ></itfg-graph>
    <!-- </ng-template> -->
  </mat-tab>
  </mat-tab-group>

  <h3 *ngIf="connectionTypes && connectionTypes?.length == 0"
    layout="row"
    layout-align="center center"
    class="no-client-claims"
    [translate]="'clients.noRelatedPeopleFound'"
    flex>
  No related people found
</h3>
