import { Injectable } from '@angular/core';
import { APP_CONFIG } from '../../config/app-config';
import { RequestService } from './request.service';
import { FormManipulationService } from './form-manipulation.service';
import { Router } from '@angular/router';
import { Observable, tap, throwError } from 'rxjs';
import { AuthStorageService } from './auth-storage.service';
import { UserDetails } from '../types/user-details';
import { NgxPermissionsService } from '../../../../node_modules/ngx-permissions';
import { UserPagePreferences, UserSettings } from '../types/user-preferences';
import { Subject } from 'rxjs';
import { Platforms } from '../types/p2p-credit';

const AUTH_CONFIG = APP_CONFIG.AUTH;

@Injectable()
export class SessionService {
  private storage: AuthStorageService;
  private router: Router;
  private request: RequestService;
  public formManipulation: FormManipulationService;
  public currentlyLoggedOperator: UserDetails;
  public userPagePreferences: UserPagePreferences;
  public userSettings: UserSettings;
  public administratorLoaded = new Subject<UserDetails>();

  onColumnsChange = new Subject<any>();

  constructor(
    request: RequestService,
    router: Router,
    storage: AuthStorageService,
    formManipulation: FormManipulationService,
    private permissions: NgxPermissionsService
  ) {
    this.request = request;
    this.router = router;
    this.storage = storage;
    this.formManipulation = formManipulation;
    this.currentlyLoggedOperator = {};
    this.userSettings = new UserSettings();
    this.userPagePreferences = {
      filters: { config: [], isShown: true },
      columns: [],
    };
  }

  isAuthenticated(): boolean {
    if (this.storage.getItem(AUTH_CONFIG.SESSION_TOKEN_NAME)) {
      return true;
    } else {
      return false;
    }
  }

  getAuthToken(): string {
    return this.storage.getItem(AUTH_CONFIG.SESSION_TOKEN_NAME);
  }

  updaateCurrentlyLoggedOperator$() {
    return this.request.get(['me', 'details'])
      .pipe(
        tap((operator: UserDetails) => {
          this.currentlyLoggedOperator = operator;
          this.permissions.loadPermissions(operator.roles);
          this.administratorLoaded.next(operator);
        })
      )
  }

  getCurrentlyLoggedOperator() {
    this.request.get(['me', 'details'])
      .subscribe({
        next: (user: UserDetails) => {
          {
            this.currentlyLoggedOperator = user;
            this.permissions.loadPermissions(user.roles);
            this.administratorLoaded.next(user);
          }
        },
        error: (error) => {
          console.log({
            status: error.status
          })
        }
      });
  }

  login(loginForm: object): Observable<boolean> {
    const serializedForm = this.formManipulation.serializeObjectToQueryString(
      loginForm
    );

    const login$ = new Observable<boolean>(observer => {
      const request$ = this.request.post(['login'], {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: serializedForm,
        responseType: 'json',
        observe: 'response',
      });
      request$.subscribe({
        next: response => {
          const token = response.headers.get('x-auth-token');
          const authenticateHeader = response.headers.get('www-authenticate');
          const requiresTwoFactorAuth = authenticateHeader === "OTP";
          if (token) {
            this.storage.setItem(AUTH_CONFIG.SESSION_TOKEN_NAME, token);
            this.userPreferencesSettings();
            if (requiresTwoFactorAuth) {
              observer.error(new Error('WWW-AUTHENTICATE-TOTP'))
            } else if (response.body?.hasOwnProperty('qrCode')) {
              observer.next(response.body);
            } else {
              observer.next(true);
            }
          } else {
            observer.next(false);
          }
          observer.complete();
        },
        error: error => {
          console.log(error)
          console.log('problem');
          observer.next(false);
        },
      });
    });

    return login$;
  }

  logout(): void {
    this.request.post(['logout']).subscribe(() => {
      this.storage.removeItem(AUTH_CONFIG.SESSION_TOKEN_NAME);
      this.router.navigate(['/auth/login']);
      this.permissions.flushPermissions();
    })
  }

  verifyTotpToken(code: string, rememberMe = false) {
    return this.request.post(['2fa', 'verify'], {
      headers: {
        'Content-type': 'application/json'
      },
      withCredentials: rememberMe,
      params: {
        code,
        rememberMe
      }
    })
  }

  revokeTrustedDevices() {
    return this.request.post(['2fa', 'devices', 'revoke']);
  }

  revokeTrustedDeviceById(id: number) {
    return this.request.post(['2fa', 'devices', id, 'revoke']);
  }

  setFilterSettings(pageName, userSettings) {
    this.userPreferencesSettings();
    let currentSessionSettings = JSON.parse(
      this.storage.getItem('preferences')
    );
    if (
      currentSessionSettings.userSettings[pageName] &&
      currentSessionSettings.userSettings[pageName].filters
    ) {
      currentSessionSettings.userSettings[pageName].filters = {};
      if (userSettings.userSettings[pageName].filters.config.length > 0) {
        currentSessionSettings.userSettings[pageName].filters.config =
          userSettings.userSettings[pageName].filters.config;
      }
      currentSessionSettings.userSettings[pageName].filters.isShown =
        userSettings.userSettings[pageName].filters.isShown;
    } else {
      currentSessionSettings.userSettings[pageName] =
        userSettings.userSettings[pageName];
    }
    currentSessionSettings = { ...currentSessionSettings };

    return JSON.stringify(
      this.storage.setItem(
        'preferences',
        JSON.stringify(currentSessionSettings)
      )
    );
  }

  getFilterSettings(pageName) {
    const userPreferences = JSON.parse(this.storage.getItem('preferences'));
    if (userPreferences && userPreferences.userSettings[pageName]) {
      return userPreferences.userSettings[pageName].filters;
    }

    return [];
  }

  removeFilterSettings(pageName, isShown) {
    const userPreferences = JSON.parse(this.storage.getItem('preferences'));
    if (userPreferences && userPreferences.userSettings[pageName]) {
      userPreferences.userSettings[pageName].filters = {};
      userPreferences.userSettings[pageName].filters.config = [];
      userPreferences.userSettings[pageName].filters.isShown = isShown;
      return JSON.stringify(
        this.storage.setItem('preferences', JSON.stringify(userPreferences))
      );
    }
  }

  getColumnSettings(pageName) {
    const userPreferences = JSON.parse(this.storage.getItem('preferences'));
    if (userPreferences && userPreferences.userSettings[pageName]) {
      return userPreferences.userSettings[pageName].columns;
    }

    return [];
  }

  setColumnsSettings(pageName, userSettings) {
    this.userPreferencesSettings();
    let currentSessionSettings = JSON.parse(
      this.storage.getItem('preferences')
    );

    if (
      currentSessionSettings.userSettings[pageName] &&
      currentSessionSettings.userSettings[pageName].columns
    ) {
      currentSessionSettings.userSettings[pageName].columns =
        userSettings.userSettings[pageName].columns;
    } else {
      currentSessionSettings.userSettings[pageName] =
        userSettings.userSettings[pageName];
    }
    currentSessionSettings = { ...currentSessionSettings };

    return JSON.stringify(
      this.storage.setItem(
        'preferences',
        JSON.stringify(currentSessionSettings)
      )
    );
  }

  setLastVisitedTab(pageName, tabIndex) {
    this.userPreferencesSettings();
    let currentSessionSettings = JSON.parse(
      this.storage.getItem('preferences')
    );
    if (currentSessionSettings.userSettings[pageName]) {
      currentSessionSettings.userSettings[pageName].lastVisitedTab = tabIndex;
    }

    currentSessionSettings = { ...currentSessionSettings };


    this.storage.setItem(
      'preferences',
      JSON.stringify(currentSessionSettings));

  }

  getLastVisitedTab(pageName) {
    const userPreferences = JSON.parse(this.storage.getItem('preferences'));
    if (userPreferences && userPreferences.userSettings[pageName] && userPreferences.userSettings[pageName].lastVisitedTab) {
      return userPreferences.userSettings[pageName].lastVisitedTab;
    }

    return null;
  }

  userPreferencesSettings() {
    if (!this.storage.getItem('preferences')) {
      this.userSettings = new UserSettings();
      this.storage.setItem('preferences', JSON.stringify(this.userSettings));
    }
  }

  getLastVisitedRoute() {
    return this.storage.getItem(APP_CONFIG.LAST_VISITED_ROUTE);
  }

  setLastVisitedRoute(route: string) {
    this.storage.setItem(APP_CONFIG.LAST_VISITED_ROUTE, route);
  }

  saveP2pDialogSettings(platform: Platforms, interest: number, buybackPeriod: number | null) {
    const userPreferences = JSON.parse(this.storage.getItem('preferences'));
    userPreferences[`p2pSettings${platform}`] = { interest, buybackPeriod };
    console.log(userPreferences[`p2pSettings${platform}`])
    this.storage.setItem('preferences', JSON.stringify(userPreferences));
  }

  getP2pDialogSettings(platform: Platforms): { interest: number, buybackPeriod: number | null } {
    const userPreferences = JSON.parse(this.storage.getItem('preferences'));
    console.log(userPreferences[`p2pSettings${platform}`])
    return userPreferences[`p2pSettings${platform}`] || null;
  }
}
