<span layout-align="start center" layout="row">
  <mat-icon
    [ngStyle]="{ color: color }"
    matTooltip="
                  {{ creditStatus | creditStatusToTranslationKey | translate }}
                  {{
      creditStatus.reason
        ? ' - ' +
          (creditStatus.reason | creditStatusReasonToTranslationKey | translate)
        : ''
    }}
                "
    matTooltipPosition="above"
  >
    {{ icon }}
  </mat-icon>
  <span *ngIf="showName" [ngStyle]="{ 'padding-left': '5px' }" flex>
    {{
      creditStatus.reason
        ? (creditStatus.reason | creditStatusReasonToTranslationKey | translate)
        : (creditStatus | creditStatusToTranslationKey | translate)
    }}
  </span>
  <span *ngIf="activeAssignment" [ngStyle]="{ 'padding-left': '5px' }" flex>
    {{ activeAssignmentDisplayText }}
  </span>
</span>
