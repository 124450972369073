<div class="status-container"
     layout="row"
     layout-align="space-between center"
     flex>
  <ng-container *ngIf="isAuthorized; else unAuthorizedBlock">
    <button class="status-block trigger margin-right"
            mat-button
            (menuOpened)="statusMenuOpened()"
            [ngClass]="clientStatusStyleMap[clientStatus.name]?.class"
            [matMenuTriggerFor]="statusMenu">
      <span layout="row"
            layout-align="space-between center">
        <mat-icon class="status-icon">{{ clientStatusStyleMap[clientStatus.name]?.icon }}</mat-icon>
        <span class="status-name" *ngIf="clientStatus.id"
              flex>{{ (clientStatus | clientStatusToTranslation).name.toUpperCase() }}</span>
        <span *ngIf="!clientStatus.id" [translate]="'clients.selectClientStatus'"></span>
        <mat-icon>keyboard_arrow_down</mat-icon>
      </span>
    </button>
    <button class="status-block right-padding-fix status-reason"
            (menuOpened)="reasonMenuOpened()"
            [matMenuTriggerFor]="reasonMenu"
            mat-button
            *ngIf="shouldShowReasonTrigger()">
      <span layout="row"
            layout-align="space-between center">
        <span>{{ (clientStatus | clientStatusToTranslation).reason }}</span>
        <mat-icon>keyboard_arrow_down</mat-icon>
      </span>
    </button>
  </ng-container>

  <ng-template #unAuthorizedBlock>
    <div class="status-block disabled margin-right"
         [ngClass]="clientStatusStyleMap[clientStatus.name]?.class">
      <span layout="row"
            layout-align="space-between center">
        <mat-icon class="status-icon">{{ clientStatusStyleMap[clientStatus.name]?.icon }}</mat-icon>
        <span class="status-name"
              flex>{{ (clientStatus | clientStatusToTranslation).name.toUpperCase() }}</span>
      </span>
    </div>
    <div class="status-block disabled status-reason"
         mat-button
         *ngIf="shouldShowReasonTrigger()">
      <span layout="row"
            layout-align="center center">
        <span>{{ (clientStatus | clientStatusToTranslation).reason }}</span>
      </span>
    </div>
  </ng-template>
</div>

<mat-menu #statusMenu="matMenu"
          class="client-status-menu"
          [overlapTrigger]="false">
    <button class="status-block"
            mat-menu-item
            *ngFor="let status of menuStatuses"
            [ngClass]="clientStatusStyleMap[status.name]?.class"
            (click)="selectedStatusChanged(status)">
        <mat-icon class="status-icon">{{ clientStatusStyleMap[status.name]?.icon }}</mat-icon>
        <span class="status-name">{{ (status | clientStatusToTranslation).name.toUpperCase() }}</span>
      </button>
</mat-menu>

<mat-menu #reasonMenu="matMenu"
          [overlapTrigger]="false">
  <div layout="column"
       layout-align="start stretch">
    <button class="status-block"
            mat-menu-item
            (click)="selectedStatusChanged(status)"
            *ngFor="let status of currentReasons">
      <span>
        {{ ('global.' + status.reason.toLowerCase() | translate) }}
      </span>
    </button>
  </div>
</mat-menu>
