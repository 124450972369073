import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  Output,
  ContentChildren,
  TemplateRef,
  QueryList,
  AfterContentInit, EventEmitter, ViewChild, OnChanges, ChangeDetectorRef,
  OnDestroy,
} from '@angular/core';
import { MatSort, Sort } from '@angular/material/sort';
import { ItfgDataTableColumn } from '@app/core/components/data-table/types/data-table.column';

import { ItfgDataTableTemplateDirective } from '../directives/data-table-template.directive';
import * as _ from 'lodash-es';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { ItfgDataTableSortChangeEvent, ItfgDataTableSortingOrder } from '../types/sort.type';
import { Observable, Subject, takeUntil } from 'rxjs';
import { FilterValue } from '@app/main/filter/types/filter-value';
import { Utils } from '@app/core/utils/utils';
import { FilterUIType } from '@app/main/filter/types';

@Component({
  selector: 'itfg-data-table',
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DataTableComponent implements OnInit, OnChanges, AfterContentInit, OnDestroy {
  _columns: ItfgDataTableColumn[];
  _data: any;
  _originalData: any;
  _shownColumns: string[];
  _stickyHeader: boolean;
  _stickyFooter: boolean;
  _showHeader: boolean = true;
  _containerStyle = {};
  _selectedRowIndex: null | number = null;
  private _singleClickTimeout: any = null;
  _unsubscribe: Subject<void> = new Subject<void>();
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  @Output() clickedRow: EventEmitter<any> = new EventEmitter();
  @Output() doubleClickedRow: EventEmitter<any> = new EventEmitter();
  @Output() ctrlClickedRow: EventEmitter<any> = new EventEmitter();
  @Output() onScrollwheelClick: EventEmitter<any> = new EventEmitter();
  @Output() onSort: EventEmitter<ItfgDataTableSortChangeEvent> = new EventEmitter();
  @Input() isSortable: boolean;
  @Input() selectable: boolean; // TODO: IMPLEMENT
  @Input() multiple: boolean; // TODO: IMPLEMENT
  @Input() isClickable: boolean;
  @Input() sortBy: string; // TODO: IMPLEMENT
  @Input('shownColumns')
  set shownColumns(shownColumns: string[]) {
    this._shownColumns = shownColumns;
  }
  get shownColumns() {
    if (this._shownColumns) {
      return this._shownColumns;
    }
    return this.columns.map(column => column.name);
  }

  @Input('data')
  set data(data: any[]) {
    this._data = data ? data : [];
    this._originalData = this._data ? [...this._data] : [];
  }
  get data(): any[] {
    return this._data;
  }

  get hasData(): boolean {
    return this._data && this._data.length > 0;
  }

  @Input('stickyHeader')
  set stickyHeader(value: boolean) {
    this._stickyHeader = coerceBooleanProperty(value);
  }

  get stickyHeader() {
    return this._stickyHeader;
  }

  @Input('showHeader')
  set showHeader(value: boolean) {
    this._showHeader = coerceBooleanProperty(value);
  }

  get showHeader() {
    return this._showHeader;
  }

  @Input('containerStyle')
  set containerStyle(value: any) {
    this._containerStyle = value;
  }
  get containerStyle() {
    return this._containerStyle;
  }

  /** template fetching support */
  private _templateMap: Map<string, TemplateRef<any>> = new Map<
    string,
    TemplateRef<any>
  >();
  @ContentChildren(ItfgDataTableTemplateDirective, { descendants: true })
  _templates: QueryList<ItfgDataTableTemplateDirective>;

  /**
   * Getter method for template references
   */
  getTemplateRef(name: string): TemplateRef<any> {
    return this._templateMap.get(name);
  }

  /**
   * columns?: ItfgDataTableColumn[]
   * Sets additional column configuration. [ItfgDataTableColumn.name] has to exist in [data] as key.
   * Defaults to [data] keys.
   */
  @Input('columns')
  set columns(cols: ItfgDataTableColumn[]) {
    this._columns = cols;
  }
  get columns() {
    if (this._columns) {
      return this._columns;
    }

    if (this.hasData) {
      this._columns = [];
      const row: any = this._data[0];
      Object.keys(row).forEach((k: string) => {
        if (!this._columns.find((c: any) => c.name === k)) {
          this._columns.push({ name: k, label: k });
        }
      });
      return this._columns;
    }

    return [];
  }

  constructor(public cdr: ChangeDetectorRef) { }

  ngOnInit() { }

  ngOnChanges() {
    this.resolveFilterOptions();
    if (this.isSortable && this._data) {
      // this.onSort();
    }
  }

  /**
   * Loads templates and sets them in a map for faster access.
   */
  ngAfterContentInit(): void {
    for (const template of this._templates.toArray()) {
      this._templateMap.set(
        template.itfgDataTableTemplate,
        template.templateRef
      );
    }
  }

  getCellValue(column: ItfgDataTableColumn, value: any): string {
    return _.get(value, column.name);
  }

  rowClicked(row: any, event: MouseEvent) {
    if (!this.isClickable) {
      return;
    }
    if (event.ctrlKey) {
      this.ctrlClickedRow.emit(row);
      this._selectedRowIndex = row.id;
      return;
    }

    if (this._singleClickTimeout !== null) {
      clearTimeout(this._singleClickTimeout);
      this._singleClickTimeout = null;
      this.rowDoubleClicked(row);
      return;
    }

    this._singleClickTimeout = setTimeout(() => {
      this._singleClickTimeout = null;
      this.clickedRow.emit(row);
      this._selectedRowIndex = row.id;
    }, 250);

  }

  rowDoubleClicked(row: any) {
    this.doubleClickedRow.emit(row);
    this._selectedRowIndex = row.id;
  }

  onSortChange(sortState: Sort) {
    const sortEvent: ItfgDataTableSortChangeEvent = {
      name: sortState.active,
      order: sortState.direction.toUpperCase() as ItfgDataTableSortingOrder,
    };
    this.onSort.emit(sortEvent);
  }


  onFilterValueChange() {
    this.applyFilters();
  }

  applyFilters() {
    const activeFilters = this.getActiveFilters();
    this._data = this._originalData.filter(item => {
      return activeFilters.every(filterConfig => filterConfig.filterPredicate(item));
    });

  }

  trackByMethod(index: number, el: ItfgDataTableColumn): string {
    return el.name;
  }

  resolveFilterOptions() {
    this.columns.forEach(column => {
      if (!column.filterConfig || column.filterConfig?.type !== FilterUIType.MULTISELECT) return;
      column.filterConfig.currentValue = [];

      const filterValues$ = column.filterConfig.valueListService
        ? column.filterConfig.valueListService.getFilterValues$()
        : Utils.extractUniqueValues(this._originalData, column.filterConfig.path);

      if (filterValues$ instanceof Observable) {
        filterValues$
          .pipe(takeUntil(this._unsubscribe))
          .subscribe(filterValues => column.filterConfig.valueList = filterValues);
      } else {
        column.filterConfig.valueList = filterValues$;
      }
    });
  }

  getActiveFilters() {
    return this.columns
      .filter(column => column.filterConfig && column.filterConfig.currentValue && column.filterConfig.currentValue.length > 0)
      .map(column => column.filterConfig);
  }

  ngOnDestroy(): void {
    this._unsubscribe.next();
    this._unsubscribe.complete();
  }
}
