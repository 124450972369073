import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TagsTableCellViewComponent } from './tags-table-cell-view/tags-table-cell-view.component';
import { TagEditComponent } from './tag-edit/tag-edit.component';
import { TagListComponent } from './tag-list/tag-list.component';
import { NgxPermissionsModule } from 'ngx-permissions';
import { TranslateModule } from '@ngx-translate/core';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DataTableModule } from '@app/core/components/data-table/data-table.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ColorPickerModule } from 'ngx-color-picker';
import { SharedModule } from '../../../shared/shared.module';
import { ListBaseModule } from '../../list-base/list-base.module';

@NgModule({
  declarations: [
    TagListComponent,
    TagEditComponent,
    TagsTableCellViewComponent
  ],
  imports: [
    CommonModule,
    NgxPermissionsModule,
    TranslateModule,
    MatChipsModule,
    DataTableModule,
    MatButtonModule,
    MatTooltipModule,
    MatIconModule,
    MatDialogModule,
    FormsModule,
    ReactiveFormsModule,
    MatBadgeModule,
    SharedModule,
    MatFormFieldModule,
    ColorPickerModule,
    MatInputModule,
    ListBaseModule,
  ],
  exports: [TagListComponent, TagEditComponent, TagsTableCellViewComponent],
  providers: [],
})
export class TagModule {}
