import {Component, Inject, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import * as moment from 'moment';

@Component({
  selector: 'itfg-choose-date-from',
  templateUrl: './choose-date-from.component.html',
  styleUrls: ['./choose-date-from.component.scss']
})
export class ChooseDateFromComponent implements OnInit {
  public dateFromForm: UntypedFormGroup;
  public currentDate: Date = new Date();
  public dateFrom: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {reportType: string},
    private dialogRef: MatDialogRef<ChooseDateFromComponent>,
    private fb: UntypedFormBuilder
  ) {
    this.dateFrom = moment().subtract(1, 'years').format('YYYY-MM-DD');
  }

  ngOnInit() {
    this.dateFromForm = this.fb.group({
      dateFrom: this.dateFrom
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onSubmit() {
    this.dialogRef.close(this.dateFromForm.getRawValue());
  }

}

