import {
  Component, Inject, OnInit, ViewContainerRef,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { PaymentPromiseService } from '../../../../app/core/services/payment-promise.service';
import { StyleConfigMap } from '../../../../app/config/credit-config';
import { PaymentPromise, PAYMENT_PROMISE_STYLE_MAP } from '../../../../app/core/types/payment-promise';
import * as moment from 'moment/moment';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorService } from '../../../../app/core/services/error.service';
import { NotificationService } from '../../../../app/core/services/notification.service';
import { SessionService } from '../../../../app/core/services/session.service';
import { DialogProviderService } from '@app/core/services/dialog-provider.service';
@Component({
  selector: 'itfg-payment-promise-details',
  templateUrl: './payment-promise-details.component.html',
  styleUrls: ['./payment-promise-details.component.scss'],
})
export class PaymentPromiseDetailsComponent implements OnInit {
  paymentPromiseColumns: any;
  paymentPromises: PaymentPromise[];
  public promiseStatusStyleMap: StyleConfigMap;
  form: UntypedFormGroup;
  public datepickerStartDate: Date = new Date();
  canEditPaymentPromise = {
    date: true,
    amount: true,
    active: true
  };
  constructor(
    public dialogRef: MatDialogRef<PaymentPromiseDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { promise: PaymentPromise },
    private translate: TranslateService,
    private fb: UntypedFormBuilder,
    private paymentPromiseService: PaymentPromiseService,
    private errorService: ErrorService,
    private notificationService: NotificationService,
    private session: SessionService,
    private _dialogService: DialogProviderService,
    public _viewContainerRef: ViewContainerRef,
  ) {
    this.paymentPromises = [data.promise];
    this.promiseStatusStyleMap = PAYMENT_PROMISE_STYLE_MAP;
  }

  ngOnInit(): void {
    this.setCanEditPaymentPromise();
    this.form = this.fb.group({
      date: this.data.promise.date,
      amount: [
        this.data.promise.amount,
        [Validators.min(this.data.promise.paidAmount)]
      ],
      active: !!this.data.promise.active
    })
    this.translate
    .get([
      'global.numberSign',
      'templates.templateName',
      'global.credit',
      'global.phoneNumber',
      'global.message',
      'global.subject',
      'global.email',
      'global.date',
      'global.operator',
      'global.actions',
      'communication.conversationContacted',
      'communication.contactEstablished',
      'communication.nextConversationDate',
      'communication.note',
      'templates.sendTime',
      'global.amount',
      'clients.createTime',
      'filtering.names.paymentPromiseDate',
      'global.status'
    ])
    .subscribe(translation => {
      this.paymentPromiseColumns = [
        { name: 'status', label: translation['global.status'], width: 60 },
        {
          name: 'createdAt',
          label: translation['clients.createTime'],
          width: 250,
        },
        { name: 'date', label: translation['filtering.names.paymentPromiseDate'], width: 175 },
        {
          name: 'amount',
          label: translation['global.amount'],
          width: 250,
        },
        {
          name: 'operatorEmail',
          label: translation['global.operator'],
          width: 250,
        },

      ];
    });
  }

  isActiveStatusChanged(event) {
  }

  setCanEditPaymentPromise(): void {
    const sameDay = moment(this.data.promise.createdAt).isSame(moment(), 'day');
    const isCreator = this.data.promise.operatorId === this.session.currentlyLoggedOperator.id;
    const paidInFull = this.data.promise.amount === this.data.promise.paidAmount;
    const active = this.data.promise.active === true;

    if (!active) {
      if (!sameDay || (sameDay && !isCreator) || paidInFull) {
        this.canEditPaymentPromise.active = false;
      }
    }

    if (paidInFull) {
        this.canEditPaymentPromise.date = false;
        this.canEditPaymentPromise.amount = false;
        this.canEditPaymentPromise.active = false;
    }

  }

  openTransferConfirmDialog$() {
    const config = {
      message: this.translate.instant('communication.paymentPromiseTransferWarningMessage'),
      disableClose: false,
      viewContainerRef: this._viewContainerRef,
      title: this.translate.instant('communication.paymentPromiseTransferWarningTitle'),
      cancelButton: this.translate.instant('global.cancel'),
      acceptButton: this.translate.instant('global.confirm'),
    };

    return this._dialogService
      .openConfirm(config);
  }

  onSubmit(event) {
    const inSameDay = moment(this.data.promise.createdAt).isSame(moment(), 'day');
    const formValue = this.form.value;
    const dateChanged = formValue.date !== this.data.promise.date;
    const amountChanged = formValue.amount !== this.data.promise.amount;
    const activeChanged = formValue.active !== this.data.promise.active;
    const deactivate = (formValue.active === true) && this.data.promise.active === false;
    const isCreator = this.data.promise.operatorId === this.session.currentlyLoggedOperator.id;
    const onlyStatusChanged = activeChanged && !amountChanged && !dateChanged;
    let shouldEdit = true;
  
    const dto = {
      ...this.data.promise,
      amount: formValue.amount,
      date: moment(formValue.date).format('YYYY-MM-DD'),
      active: formValue.active,
    };
    let formValid = this.form.valid;

    if ((isCreator && inSameDay) || onlyStatusChanged) { // EDIT
      shouldEdit = true;
    } else { // TRANSFER
      shouldEdit = false;
    }

    if(onlyStatusChanged) {
      formValid = true;
    }

    if (!formValid) {
      return this.notificationService.showLocalizedErrorMessage({
        notificationText: 'forms.formSavedIsInvalid',
      });
    }

    if (shouldEdit) {
      this.paymentPromiseService
          .editPaymentPromise$(dto)
          .subscribe(res => {
        this.dialogRef.close(res);
      }, (error: HttpErrorResponse) => {
        this.notificationService.showLocalizedSnackbarNotification({
          notificationText: 'error.LOCKED',
          action: 'error',
        });
      }
    )
    } else {
      this.openTransferConfirmDialog$()
      .afterClosed()
      .subscribe((accept: boolean) => {
        if (accept) {
          const newPromiseDto = {
            amount: formValue.amount,
            date: moment(formValue.date).format('YYYY-MM-DD'),
          }
          this.paymentPromiseService
              .transferPaymentPromiseById$(this.data.promise.id, newPromiseDto)
              .subscribe(res => {
                this.dialogRef.close(res);
            })
        }
      });
    }
  }

  onNoClick() {
    this.dialogRef.close();
  }
}
