import { Component } from '@angular/core';

@Component({
  selector: 'itfg-notifications-button',
  templateUrl: './notifications-button.component.html',
  styleUrls: ['./notifications-button.component.scss'],
})
export class NotificationsButtonComponent {
  // TODO: Implement reminders and other stuff that could be potentially useful for the operators
  constructor() {

  }
 
}
