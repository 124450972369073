import { ItfgDataTableColumn } from '@app/core/components/data-table/types/data-table.column';

export enum ColumnStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export interface Column {
  status: ColumnStatus;
  config: ItfgDataTableColumn;
}
