import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ProductService } from '../../core/services/product.service';
import { FilterValue, FilterValueListService } from '../filter/types';
import { Observable } from 'rxjs';
import { GcProduct } from '../../core/types/gc-product';

@Injectable()
export class GcProductValueList implements FilterValueListService {
  constructor(private products: ProductService) {}

  getFilterValues$(): Observable<FilterValue[]> {
    return this.products.getGcProductList().pipe(
      map((productList): FilterValue[] => {
        return productList.map(
          (product: GcProduct): FilterValue => {
            return {
              value: product.code,
              displayText: product.label,
            };
          }
        );
      })
    );
  }
}