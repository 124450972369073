import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Router  } from '@angular/router';

@Component({
  selector: 'itfg-add-new',
  templateUrl: './add-new.component.html',
  styleUrls: ['./add-new.component.css']
})
export class AddNewComponent {

  @Input() disabled: Boolean;
  @Input() toolTip: String;

  @Input() link: String;

  @Output() onClick:EventEmitter<null> = new EventEmitter();

  constructor(private router : Router) { }

  addButtonClicked(): void {
    if(!!this.link){
      this.router.navigate([this.link]);
      return;  
    }
    this.onClick.emit(null);
  }
}
