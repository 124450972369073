<div layout="row">
  <mat-card appearance="outlined" flex="50">
    <div layout="row" layout-align="space-between">
      <mat-card-title>
        <span>{{ 'global.configuration' | translate }}</span>
      </mat-card-title>
      <span class="clients-additional-info">
        {{ 'tags.clientsLimitMessage' | translate : {clientsLimit: CLIENTS_LIMIT} }}
      </span>
    </div>
    <mat-card-content>
      <form (ngSubmit)="onSubmit($event)" [formGroup]="form" layout="column">

        <mat-form-field flex>
          <input type="text"
                 placeholder="{{ 'tags.tag' | translate }}"
                 [style.color]="form?.get('tagControl').value?.color"
                 matInput
                 itfgSelectFirstAutocompleteOptionOnBlur
                 formControlName="tagControl"
                 [matAutocomplete]="auto">
      <mat-label>{{ 'tags.tag' | translate }}</mat-label>
          <mat-autocomplete #auto="matAutocomplete"
                            autoActiveFirstOption
                            [displayWith]="mapTagToDisplayValue">
            <mat-option *ngFor="let tag of filteredShownTags$ | async"
                        [value]="tag">
              <span [style.color]="tag.color">{{tag.name}}</span>
            </mat-option>
          </mat-autocomplete>
          <mat-error *ngIf="form?.get('tagControl').hasError('required')"
                     [translate]="'forms.required'">This field is required
          </mat-error>
          <mat-error *ngIf="form?.get('tagControl').hasError('notValidTag')" [translate]="'forms.notValidTag'"></mat-error>
          <itfg-form-control-clear
          class="itfg-warn"
          (click)="clearTag($event)"
          matSuffix
          [control]="form?.get('tagControl')"
        >
        </itfg-form-control-clear>
        </mat-form-field>
        <mat-form-field>
          <mat-select
            placeholder="{{ 'global.action' | translate }}"
            formControlName="action"
          >
            <mat-option [value]="tagActionNames.ADD">
              {{ 'tags.addTag' | translate }}
            </mat-option>
            <mat-option [value]="tagActionNames.DELETE">
              {{ 'tags.removeTag' | translate }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field style="width: 100%;">
          <textarea
            matInput
            formControlName="clientIds"
            placeholder="{{'global.clients' | translate}}"
            cdkTextareaAutosize
            cdkAutosizeMinRows="15"
            cdkAutosizeMaxRows="50"
          ></textarea>
        </mat-form-field>
        <div layout="row" layout-align="end">

          <button
            type="submit"
            color="primary"
            mat-raised-button
            [disabled]="clientIds.length === 0 || !this.form.valid"
          >
            <span [translate]="'global.proceed'">Proceed</span>
          </button>
        </div>
      </form>
      <div class="margin-top-15" *ngIf="missingClientIds?.length > 0">
          {{ 'Несъществуващи клиенти: ' + missingClientIds.join(', ')}}
      </div>
    </mat-card-content>
  </mat-card>

  <mat-card appearance="outlined" flex="50">
    <div layout="row" layout-align="space-between">
      <mat-card-title>
        <span>{{ 'clients.viewClients' | translate }}</span>
      </mat-card-title>
      <span *ngIf="clientIds.length > 0" 
        class="clients-additional-info"
        >
        {{ ('clients.totalClients' | translate) + ' ' + clientIds.length }}
      </span>
      </div>
    <mat-card-content
      [ngClass]="{ 'blur-content': clientPage?.content?.length < 1 }"
    >
    <div *ngIf="clientPage?.content && !isLoadingResult">
      <itfg-data-table
        [style.height]="'80vh'"
        [data]="clientPage?.content"
        [isClickable]="true"
        [columns]="columns"
      >
        <ng-template itfgDataTableTemplate="id" let-clientId="value">
          <a routerLink="/clients/{{ clientId }}" target="_blank" flex>
            {{ clientId }}
          </a>
        </ng-template>
        <ng-template itfgDataTableTemplate="fullName" let-value="value"
                     let-row="row"
                     let-column="column">
            {{ row | clientFullName}}
        </ng-template>
        <ng-template itfgDataTableTemplate="tags"
                     let-tags="value">
          <itfg-tags-table-cell-view [tags]="tags"></itfg-tags-table-cell-view>
        </ng-template>
      </itfg-data-table>

    <mat-paginator #pagingBarResponsive
                  (page)="pageChanged($event)"
                  [pageIndex]="clientPage.number"
                  [length]="clientPage.totalElements"
                  [pageSize]="clientPage.size"
                  [pageSizeOptions]="[20,30,100]"
                  showFirstLastButtons>
    </mat-paginator>
    </div>
    </mat-card-content>
  </mat-card>
</div>

