import { Injectable } from '@angular/core';
import { RequestService } from './request.service';
import { Observable } from 'rxjs';
import { PermissionGroup } from '../types/permission-group';
import { EntityHistoryFilter } from '../types/entity-history-filter';
import { EntityHistory } from '../types/entities';

@Injectable()
export class PermissionGroupService {
  request: RequestService;

  constructor(request: RequestService) {
    this.request = request;
  }

  getPermissionGroupList(): Observable<PermissionGroup[]> {
    return this.request.get(['rolegroups']);
  }

  savePermissionGroup(group): Observable<any> {
    return this.request.post(['rolegroups'], { body: group });
  }

  getPermissionGroupById(id: number | string): Observable<PermissionGroup> {
    return this.request.get(['rolegroups', id]);
  }

  deletePermissionGroup(id: number | string): Observable<any> {
    return this.request.delete(['rolegroups', id]);
  }

  getPermissionGroupHistory(
    filter: EntityHistoryFilter
  ): Observable<EntityHistory<any>> {
    return this.request.get(['rolegroups', 'history'], {
      params: {
        filter: filter.getDTO(),
      },
    });
  }

  getEntityHistory(filter: EntityHistoryFilter) {
    return this.getPermissionGroupHistory(filter);
  }
}
