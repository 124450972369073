import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Node } from '@swimlane/ngx-graph';

@Component({
  selector: 'itfg-graph-node',
  templateUrl: './graph-node.component.html',
  styleUrls: ['./graph-node.component.scss'],
})
export class GraphNodeComponent {

  @Input() node: Node;
  @Input() clientId: string;

  constructor(private router: Router) {
  }

  getGenericNodeDataPreview() {
    const preview = [];
    for (let key of Object.keys(this.node.data)) {
      if (key === 'color') {
        continue;
      }
      preview.push(`${key}: ${this.node.data[key]}`);
    }
    return preview.join('\n');
  }

  redirectToUser(event, id: string) {
    event.stopPropagation()
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['/clients/', id])
    );

    return window.open('#' + url, '_blank');

  }

}
