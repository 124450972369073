import {
  Component,
  OnInit,
  Input,
  EventEmitter,
  Output,
  Renderer2,
} from '@angular/core';
import { NavigationConfig } from '../../../config/app-config';
import { trigger, style, animate, transition, keyframes } from '@angular/animations';
import { NavigationMenuService } from '../../../core/services/navigation-menu.service';
import { SessionService } from '../../../core/services/session.service';
// import { Socket } from '@app/main/socket-io/socket-io.service';
import { DrawerMode } from '@app/core/types/drawer-mode';
import { Socket } from '@app/main/socket-io/socket-io.service';
import { PbxEvents } from '@app/main/socket-io/types/pbx-event.enum';
import { Call } from '@app/core/types/call';

@Component({
  selector: 'itfg-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
  animations: [
    trigger('toolbarCollapse', [
      transition(':enter', [style({ opacity: 0 }), animate(200)]),
    ]),
    trigger('jiggle', [
      transition('false => true', [
        animate('3s ease-in-out', keyframes([
          style({ transform: 'rotate(-10deg)' }),
          style({ transform: 'rotate(10deg)' }),
          style({ transform: 'rotate(-10deg)' }),
          style({ transform: 'rotate(10deg)' }),
          style({ transform: 'none' }),
        ]))
      ])
    ]),
  ],
})
export class ToolbarComponent implements OnInit {
  @Input() config: NavigationConfig;
  @Input() mode: string;
  @Input() collapsed;
  @Input() toggleLeftSideNav: Function;

  @Output() searchEvent = new EventEmitter<boolean>();

  DrawerModeType = DrawerMode;
  callNotificationAnimation: boolean = false;
  hasCallNotifications: boolean = false;
  constructor(
    private navigationMenuService: NavigationMenuService,
    public sessionService: SessionService,
    public socketService: Socket,
    private renderer: Renderer2
  ) {}

  ngOnInit() {
      this.socketService.on(PbxEvents.NOTIF_NEW, (call: Call) => {
        this.triggerNotificationAnimation();
        this.hasCallNotifications = true;
      });
  }

  handleToggleLeftSideNav(mode: DrawerMode, side: string) {
    this.toggleLeftSideNav(mode, side);
    this.resetNotifications();
  }

  openProductCalculator() {
    this.navigationMenuService.toggleProductCalculator.next();
  }

  backArrowClicked() {
    this.navigationMenuService.onCloseNavigationDrawerClick.next(true);
  }

  swichLanguage(lang: string) {
    this.navigationMenuService.onChangeLanguageClick.next(lang);
  }

  toggleLayout() {
    this.searchEvent.emit(this.collapsed);
  }

  triggerNotificationAnimation() {
    this.callNotificationAnimation = true;
    setTimeout(() => this.callNotificationAnimation = false, 3000);
  }

  resetNotifications() {
    this.hasCallNotifications = false;
  }

  logout() {
    this.sessionService.logout();
  }
}
