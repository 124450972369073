import { Pipe, PipeTransform } from '@angular/core';
import { CreditStatusReasons } from '../../../core/types/credit-status';

@Pipe({
  name: 'creditStatusReasonToTranslationKey',
})
export class CreditStatusReasonToTranslationKeyPipe implements PipeTransform {
  static readonly reasonTypesTranslationMap: { [key: string]: string } = {
    [CreditStatusReasons.NO_REASON]: 'credits.creditStatusReasonList.noReason',
    [CreditStatusReasons.ABROAD]: 'credits.creditStatusReasonList.abroad',
    [CreditStatusReasons.AGE]: 'credits.creditStatusReasonList.age',
    [CreditStatusReasons.AGGRESSIVE]:
      'credits.creditStatusReasonList.aggressive',
    [CreditStatusReasons.BLACKLIST_CIVIL]:
      'credits.creditStatusReasonList.blacklistCivil',
    [CreditStatusReasons.BLACKLIST_EMPLOYER]:
      'credits.creditStatusReasonList.blacklistEmployer',
    [CreditStatusReasons.BLACKLIST_PHONE]:
      'credits.creditStatusReasonList.blacklistPhone',
    [CreditStatusReasons.CALL_CENTER]:
      'credits.creditStatusReasonList.callCenter',
    [CreditStatusReasons.COLLECTOR]: 'credits.creditStatusReasonList.collector',
    [CreditStatusReasons.CORRECTION]:
      'credits.creditStatusReasonList.correction',
    [CreditStatusReasons.COURT]: 'credits.creditStatusReasonList.court',
    [CreditStatusReasons.DCA]: 'credits.creditStatusReasonList.dca',
    [CreditStatusReasons.FAMILY_DEBTS]:
      'credits.creditStatusReasonList.familyDebts',
    [CreditStatusReasons.FOR_COLLECTOR]:
      'credits.creditStatusReasonList.forCollector',
    [CreditStatusReasons.FOR_COURT]: 'credits.creditStatusReasonList.forCourt',
    [CreditStatusReasons.FOR_DCA]: 'credits.creditStatusReasonList.forDca',
    [CreditStatusReasons.FRAUD]: 'credits.creditStatusReasonList.fraud',
    [CreditStatusReasons.IRREGULAR_PAYER]:
      'credits.creditStatusReasonList.irregularPayer',
    [CreditStatusReasons.IRREGULAR_PAYER_INTERNAL]:
      'credits.creditStatusReasonList.irregularPayerInternal',
    [CreditStatusReasons.MATCHES]: 'credits.creditStatusReasonList.matches',
    [CreditStatusReasons.NO_EMPLOYMENT_CONTRACT]:
      'credits.creditStatusReasonList.noEmploymentContract',
    [CreditStatusReasons.NO_INSURANCE]:
      'credits.creditStatusReasonList.noIinsurance',
    [CreditStatusReasons.NOT_CONFIRMED]:
      'credits.creditStatusReasonList.notConfirmed',
    [CreditStatusReasons.NOT_CONTACTABLE]:
      'credits.creditStatusReasonList.notContactable',
    [CreditStatusReasons.POSTPONED]: 'credits.creditStatusReasonList.postponed',
    [CreditStatusReasons.NOT_SUPPORTIVE]:
      'credits.creditStatusReasonList.notSupportive',
    [CreditStatusReasons.REDIRECTED]:
      'credits.creditStatusReasonList.redirected',
    [CreditStatusReasons.REFUSED]: 'credits.creditStatusReasonList.refused',
    [CreditStatusReasons.REFUSED_CAMPAIGN]:
      'credits.creditStatusReasonList.refusedCampaign',
    [CreditStatusReasons.REQUEST_DATA_NOT_CONFIRMED]:
      'credits.creditStatusReasonList.requestDataNotConfirmed',
    [CreditStatusReasons.RETURNED_BY_COLLECTOR]:
      'credits.creditStatusReasonList.returnedByCollector',
    [CreditStatusReasons.RETURNED_BY_DCA]:
      'credits.creditStatusReasonList.returnedByDca',
    [CreditStatusReasons.SOCIAL_STATUS]:
      'credits.creditStatusReasonList.socialStatus',
    [CreditStatusReasons.UNSTABLE_PERSON]:
      'credits.creditStatusReasonList.unstablePerson',
    [CreditStatusReasons.WORK_CONFIRMATION]:
      'credits.creditStatusReasonList.workConfirmation',
    [CreditStatusReasons.RISK]: 'credits.creditStatusReasonList.risk',
    [CreditStatusReasons.OVERRIDE]: 'credits.creditStatusReasonList.overwrite',
    [CreditStatusReasons.EMPLOYEE]: 'credits.creditStatusReasonList.service',
    [CreditStatusReasons.HIGH_RISK]: 'credits.creditStatusReasonList.highRisk',
    [CreditStatusReasons.RISK_2]: 'credits.creditStatusReasonList.secondRisk',
    [CreditStatusReasons.UNEMPLOYED]:
      'credits.creditStatusReasonList.withoutEC',
  };

  transform(reason: string): string {
    if (
      CreditStatusReasonToTranslationKeyPipe.reasonTypesTranslationMap.hasOwnProperty(
        reason
      )
    ) {
      return CreditStatusReasonToTranslationKeyPipe.reasonTypesTranslationMap[
        reason
      ];
    } else {
      return reason;
    }
  }
}
