import { filter } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';

import { ItfgDataTableColumn } from '@app/core/components/data-table/types/data-table.column';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import {
  SearchOptions,
  SearchDirection,
} from '../../../../core/types/search-options';
import { SearchOperations } from '../../../../core/types/search-criterium';
import { InteractionMode } from '../../../../core/types/interaction-mode';

@Component({
  selector: 'itfg-client-claim-list',
  templateUrl: './client-claim-list.component.html',
  styleUrls: ['./client-claim-list.component.scss'],
})
export class ClientClaimListComponent implements OnInit {
  public claimColumns: ItfgDataTableColumn[];
  public searchOptions: SearchOptions;
  public modes: typeof InteractionMode = InteractionMode;
  public userId: number;

  constructor(
    private route: ActivatedRoute,
    private translateService: TranslateService
  ) {}

  ngOnInit() {
    this.initializeColumns();
    this.route.paramMap
      .pipe(filter((params: ParamMap) => typeof params.get('id') === 'string'))
      .subscribe((params: ParamMap) => {
        this.userId = Number(params.get('id'));
        this.setClaimSearchOptions(this.userId);
      });
  }

  initializeColumns() {
    this.translateService
      .get([
        'global.actions',
        'global.numberSign',
        'claims.createDate',
        'claims.completed',
        'claims.subject',
        'claims.notes',
        'filtering.names.userId',
      ])
      .subscribe((labels: string[]) => {
        this.claimColumns = [
          {
            name: 'id',
            label: labels['global.numberSign'],
            width: 120,
          },
          {
            name: 'time',
            label: labels['claims.createDate'],
            width: 250,
          },
          {
            name: 'completed',
            label: labels['claims.completed'],
            width: 150,
          },
          {
            name: 'subject',
            label: labels['claims.subject'],
            width: 400,
          },
          {
            name: 'notes',
            label: labels['claims.notes'],
          },
          {
            name: 'actions',
            numeric: true,
            label: labels['global.actions'],
          },
        ];
      });
  }

  setClaimSearchOptions(clientId: number) {
    this.searchOptions = new SearchOptions({
      criteria: [
        {
          key: 'user.id',
          operation: SearchOperations.EQUALS,
          value: clientId,
        },
      ],
      page: 0,
      direction: SearchDirection.DESCENDING,
      pageSize: 20,
      column: 'id',
    });
  }
}
