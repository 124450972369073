import { debounceTime } from 'rxjs/operators';
import { Directive, AfterContentInit } from '@angular/core';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { ContentChild } from '@angular/core';

@Directive({
  selector: '[itfgHighlightFirstAutocompleteOptionOnChange]',
})
export class HighlightFirstAutocompleteOptionOnChangeDirective
  implements AfterContentInit {
  @ContentChild(MatAutocomplete)
  autocomplete: MatAutocomplete;

  constructor() {}

  ngAfterContentInit() {
    this.autocomplete?.options?.changes
      .pipe(debounceTime(0))
      .subscribe(result => {
        if (this.autocomplete.options.first) {
          this.autocomplete.options.first.setActiveStyles();
        }
      });
  }
}
