import { Injectable } from '@angular/core';
import { RequestService } from './request.service';
import { BuildDocumentDTO } from '../types/document';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError as observableThrowError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { DocumentTypes } from '../types/template-types';

@Injectable()
export class DocumentService {
  constructor(private request: RequestService) {}

  buildDocument$(params: BuildDocumentDTO) {
    return this.request.post(['documents', 'build'], {
      body: params,
    });
  }

  buildDocumentHtml$(params: BuildDocumentDTO) {
    return this.request.post(['documents', 'build', 'html'], {
      body: params,
      responseType: 'document',
    });
  }

  buildDocumentPdf$(params: BuildDocumentDTO) {
    return this.request.post(['documents', 'build', 'pdf'], {
      body: params,
      responseType: 'blob',
    });
  }

  getDocumentPlaceholderList(documentTemplateBody: string): string[] {
    const charFound = false;
    const openTag = '{{';
    const closeTag = '}}';
    let placeholderList: string[] = [];

    for (let i = 0; i < documentTemplateBody.length; i++) {
      const firstOpenTag = documentTemplateBody.indexOf(openTag, i);
      if (firstOpenTag !== -1) {
        const nextClosingTag = documentTemplateBody.indexOf(
          closeTag,
          firstOpenTag
        );
        if (nextClosingTag !== -1) {
          const currentPlaceHolder = documentTemplateBody.substring(
            firstOpenTag + openTag.length,
            nextClosingTag
          );
          if (placeholderList.indexOf(currentPlaceHolder) === -1) {
            placeholderList.push(currentPlaceHolder);
          }
          i = nextClosingTag + closeTag.length - 1;
        }
      }
    }

    placeholderList = placeholderList.filter(
      placeholder =>
        !placeholder.startsWith('/') &&
        !placeholder.startsWith('#') &&
        !placeholder.startsWith('^') &&
        !placeholder.startsWith('&')
    );

    return placeholderList;
  }

  viewDocument(documentTemplateObject, viewType: DocumentTypes) {
    const winPrint = window.open();

    if (viewType === DocumentTypes.HTML) {
      this.buildDocumentHtml$(documentTemplateObject)
        .pipe(
          catchError((error: HttpErrorResponse, caught: Observable<any>) => {
            winPrint.document.close();
            return observableThrowError(error);
          })
        )
        .subscribe(htmlDocument => {
          const htmlElement = htmlDocument.getElementsByTagName('html')[0];
          winPrint.document.write(htmlElement.outerHTML);
          winPrint.document.close();
        });
    } else {
      this.buildDocumentPdf$(documentTemplateObject)
        .pipe(
          catchError((error: HttpErrorResponse, caught: Observable<any>) => {
            console.log(error);
            winPrint.document.close();
            return observableThrowError(error);
          })
        )
        .subscribe(pdfDocument => {
          const blob = new Blob([pdfDocument], { type: 'application/pdf' });
          const url = window.URL.createObjectURL(blob);
          winPrint.location.href = url;
          winPrint.document.close();
        });
    }
  }
}
