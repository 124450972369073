<div layout="row" layout-align="space-between center" class="title">
  <span [translate]="'communication.paymentPromiseDetails'">Details for payment promise</span>
  <button matTooltip="{{ 'global.close' | translate }}" matTooltipPosition="above" tabindex="-1" mat-icon-button (click)="onNoClick()">
    <mat-icon>clear</mat-icon>
  </button>
</div>
<form novalidate
      [formGroup]="form"
      layout="column"
      (ngSubmit)="onSubmit($event);">
  <mat-form-field>
    <input matInput
          type="text"
          readonly
          value="{{ data.promise.status | paymentPromiseStatusToTranslationKey | translate }}"
          placeholder="{{ 'global.status' | translate }}" />
      <mat-label>{{ 'global.status' | translate }}</mat-label>
    <mat-icon matSuffix [ngStyle]="{'color': promiseStatusStyleMap[data.promise.status]?.color}">{{promiseStatusStyleMap[data.promise.status]?.icon}}</mat-icon>
  </mat-form-field>
  <mat-form-field>
    <input matInput
          type="text"
          readonly
          value="{{ data?.promise.createdAt | date: 'dd.MM.yyyy HH:mm' }}"
          placeholder="{{ 'clients.createTime' | translate }}" />
      <mat-label>{{ 'clients.createTime' | translate }}</mat-label>
  </mat-form-field>
  <mat-form-field>
    <input matInput
          type="text"
          readonly
          value="{{ data?.promise.updatedAt | date: 'dd.MM.yyyy HH:mm' }}"
          placeholder="{{ 'communication.paymentPromiseUpdatedAt' | translate }}" />
      <mat-label>{{ 'communication.paymentPromiseUpdatedAt' | translate }}</mat-label>
  </mat-form-field>
  <mat-form-field>
    <input matInput
            readonly="true"
            [matDatepicker]="picker"
            [min]="datepickerStartDate"
            formControlName="date"
            placeholder="{{ 'filtering.names.paymentPromiseDate' | translate }}"
            (click)="canEditPaymentPromise.date ? picker.open() : ''">
      <mat-label>{{ 'filtering.names.paymentPromiseDate' | translate }}</mat-label>
    <mat-datepicker-toggle matSuffix
                            [disabled]="!canEditPaymentPromise.date"
                            [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker>
    </mat-datepicker>
  </mat-form-field>
  <mat-form-field>
    <input matInput
            type="number"
            formControlName="amount"
            [readonly]="!canEditPaymentPromise.amount"
            placeholder="{{ 'communication.agreedUponPaymentAmount' | translate }}" />
      <mat-label>{{ 'communication.agreedUponPaymentAmount' | translate }}</mat-label>
      <mat-error
            *ngIf="
              form.get('amount').hasError('min')
            "
            [translate]="'forms.minValue'"
            [translateParams]="{ value: data.promise.paidAmount }"
          >Min value error
    </mat-error>
  </mat-form-field>
  <mat-form-field>
    <input matInput
            type="text"
            readonly
            value="{{ data.promise.paidAmount | toCustomItfgCurrency }}"
            placeholder="{{ 'credits.paid' | translate }}" />
      <mat-label>{{ 'credits.paid' | translate }}</mat-label>
  </mat-form-field>
  <mat-form-field>
    <input matInput
          type="text"
          readonly
          value="{{ data.promise.operatorEmail }}"
          placeholder="{{ 'global.operator' | translate }}" />
      <mat-label>{{ 'global.operator' | translate }}</mat-label>
  </mat-form-field>

  <div mat-dialog-actions layout-align="space-between center">
    <!-- <mat-checkbox formControlName="active" flex class="flex-padding">
      <div [translate]="'communication.paymentPromiseActive'">Active</div>
    </mat-checkbox> -->
    <mat-slide-toggle #slideToggle
                      matTooltip="{{ (slideToggle.checked ? 'historyLog.deactivatePaymentPromise' : 'historyLog.activatePaymentPromise') | translate }}"
                      [disabled]="!canEditPaymentPromise.active"
                      formControlName="active"
                      (change)="isActiveStatusChanged($event)"
                      appearance="legacy">
    </mat-slide-toggle>
    <button mat-raised-button
            [disabled]="!canEditPaymentPromise.date && !canEditPaymentPromise.amount && !canEditPaymentPromise.active"
            type="submit" *ngxPermissionsOnly="['PAYMENT_PROMISE_WRITE']">
      {{ 'global.save' | translate }}
    </button>
  </div>
</form>

<!-- <mat-card>
  <mat-card-content>

  </mat-card-content>
</mat-card> -->
