import { Component, Input, OnChanges, OnInit, Optional, SimpleChanges, ViewChild } from '@angular/core';
import { ItfgDataTableColumn } from '@app/core/components/data-table/types/data-table.column';
import { SearchDirection, SearchOptions } from '../../../../core/types/search-options';
import { DeserializedConversation } from '../../../../core/types/converstion';
import { Page } from '../../../../core/types/page';
import { Observable, Subject } from 'rxjs';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Credit } from '../../../../core/types/credit';
import { filter, finalize, takeUntil } from 'rxjs/operators';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { NotificationService } from '../../../../core/services/notification.service';
import { NgxPermissionsService } from 'ngx-permissions';
import { SearchOperations } from '../../../../core/types/search-criterium';
import { ListBaseComponent } from '@app/main/list-base/list-base.component';
import { ColumnsService } from '@app/core/services/columns.service';
import { MatPaginator } from '@angular/material/paginator';
import { LoadingService } from '@app/core/services/loading.service';
import { NoteService } from '@app/core/services/note.service';
import { Note } from '@app/core/types/note';
import { EditNoteDialogComponent } from '@app/main/main-layout/right-drawer/event-log/notes/edit-note-dialog/edit-note-dialog.component';
import { SessionService } from '@app/core/services/session.service';
import { UserDetails } from '@app/core/types/user-details';
import { AdministratorService } from '@app/core/services/administrator.service';
import { Administrator } from '@app/core/types/administrator';
import { Client } from '@app/core/types/client';

@Component({
  selector: 'itfg-client-conversation',
  templateUrl: './client-conversation.component.html',
  styleUrls: ['./client-conversation.component.scss']
})
export class ClientConversationComponent extends ListBaseComponent implements OnInit, OnChanges {
  @Input()
  credit: Credit;
  @Input()
  client: Client;

  notesPage: Page<DeserializedConversation> = {};
  notesColumns: ItfgDataTableColumn[];
  searchOptions: SearchOptions;
  includeAllConversations: boolean;
  selectedConversation: DeserializedConversation;
  conversationsLoading: boolean = false;
  currentlyLoggedOperator: UserDetails;
  administrators: Administrator[];
  _unsubscribe: Subject<void> = new Subject<void>();
  @ViewChild('pagingBarResponsive', { static: true })
  public pagingBarResponsive: MatPaginator;

  constructor(
    private translate: TranslateService,
    private loading: LoadingService,
    private dialog: MatDialog,
    private notifications: NotificationService,
    private ngxPermissionService: NgxPermissionsService,
    public columnsService: ColumnsService,
    public sessionService: SessionService,
    private noteService: NoteService,
    private administratorService: AdministratorService,
    @Optional()
    private editNoteDialogRef: MatDialogRef<EditNoteDialogComponent>,
  ) {
    super(columnsService);
    this.initializeSearchOptions();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.credit) {
      this.updatesearchOptionsForCredit();
      this.getCommunicationLists();
    }
    if (changes.client) {
      this.updatesearchOptionsForClient();
      this.getCommunicationLists();
    }
  }

  ngOnInit(): void {
    this.administratorService.administrators$
      .pipe(takeUntil(this._unsubscribe))
      .subscribe((administrators: Administrator[]) => {
        this.administrators = administrators;
      });

    this.noteService.onNoteChange
      .pipe(takeUntil(this._unsubscribe))
      .subscribe(data => {
        this.updateNotesList(true);
      });
    this.triggerDataChange$.subscribe((searchOptions: SearchOptions) => {
      this.updateNotesList();
    });

    this.translate
      .get([
        'communication.conversationContacted',
        'global.credit',
        'global.date',
        'communication.note',
        'global.operator',
        'clientComunications.topics.label',
        'global.actions',
      ])
      .subscribe(translation => {
        this.notesColumns = [
          {
            name: 'call',
            label: translation['communication.conversationContacted'],
            width: 180,
          },
          { name: 'creditId', label: translation['global.credit'], width: 100 },
          { name: 'createdAt', label: translation['global.date'], width: 140 },
          { name: 'content', label: translation['communication.note'] },
          { name: 'topics', label: translation['clientComunications.topics.label'], width: 250 },
          {
            name: 'operatorId',
            label: translation['global.operator'],
            width: 150,
          },
          {
            name: 'actions',
            label: translation['global.actions'],
            width: 100,
            numeric: true,
          },
        ];

      });

  }

  getCommunicationLists() {
    this.updateNotesList(true);
  }

  getNotesList(): Observable<Page<DeserializedConversation>> {
    return this.noteService.getNotes(this.searchOptions.getDTO());
  }

  updateNotesList(chain = false) {
    this.loading.register('notesListLoading');
    const notesList$ = this.getNotesList();
    notesList$
      .pipe(
        finalize(() => {
          this.loading.resolve('notesListLoading');
          // if (chain) {   // updates sms when conversations are updated
          //   this.smsService.onSmsSend.next({} as Sms);
          // }
        })
      )
      .subscribe((notesPage: Page<DeserializedConversation>) => {
        this.notesPage = notesPage;
      });
    return notesList$;
  }

  onNotesModeChange(toggle: MatSlideToggleChange) {
    if (this.includeAllConversations) {
      this.updatesearchOptionsForClient();
    } else {
      this.updatesearchOptionsForCredit();
    }

    this.updateNotesList(true);
  }

  pageChanged(page) {
    super.pageChanged(page);
    this.updateNotesList();
  }

  openEditNoteDialog(note: Note | Note[]) {
    if (!Array.isArray(note)) {
      note = [note];
    }

    return this.dialog
      .open(EditNoteDialogComponent, {
        data: {
          notes: note,
        },
        restoreFocus: false,
        minWidth: '600px',
      })
      .afterClosed()
      .pipe(filter(success => !!success))
      .subscribe((success: boolean) => {
        this.noteService.onNoteChange.next(true);
      });
  }

  private initializeSearchOptions() {
    this.searchOptions = new SearchOptions({
      page: 0,
      pageSize: 10,
      column: 'createdAt',
      direction: SearchDirection.DESCENDING,
    });

  }

  private updatesearchOptionsForCredit() {
    const readAllAuthorization = this.ngxPermissionService.getPermission('CONVERSATION_READ_ALL');
    if (readAllAuthorization) {
      this.searchOptions.criteria = [
        {
          key: 'creditId',
          operation: SearchOperations.EQUALS,
          value: this.credit.id,
        },

      ];
    } else {
      this.searchOptions.criteria = [
        {
          key: 'operatorId',
          operation: SearchOperations.EQUALS,
          value: this.currentlyLoggedOperator.id,
        },
        {
          key: 'creditId',
          operation: SearchOperations.EQUALS,
          value: this.credit.id,
        },
      ];
    }
  }

  private updatesearchOptionsForClient() {
    this.searchOptions.criteria = [
      {
        key: 'clientId',
        operation: SearchOperations.EQUALS,
        value: this.client?.id || this.credit?.user?.id,
      },
    ];
  }

  resetMatSlidersToggled() {
    this.includeAllConversations = false;
  }

  ngOnDestroy() {
    this._unsubscribe.next();
    this._unsubscribe.complete();
    if (this.editNoteDialogRef) {
      this.editNoteDialogRef.close();
    }
  }
}
