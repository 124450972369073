import { UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';

export const RequiredValidation = {
  fieldDependsOn: fieldDependsOn,
};

function fieldDependsOn(
  dependentControlKey: string,
  dependeeControlKeys: string[],
  validationKey: string
) {
  return (group: UntypedFormGroup): ValidationErrors => {
    const errorFields: string[] = [];

    if (Validators.required(group.controls[dependentControlKey]) !== null) {
      // If field is empty we don't have to check anything
      return null;
    } else {
      dependeeControlKeys.forEach((item: string) => {
        if (Validators.required(group.controls[item]) !== null) {
          errorFields.push(item);
        }
      });
    }

    if (errorFields.length === 0) {
      return null;
    } else {
      const validationError = {};
      validationError[validationKey] = {
        value: {
          dependentControlKey: dependentControlKey,
          dependeeControlKeys: dependeeControlKeys,
          errorFields: errorFields,
        },
      };
      return validationError;
    }
  };
}
