import { Decimal as DecimalCalc } from 'decimal.js';
DecimalCalc.config({ precision: 10, rounding: 4 });

export interface DecimalObject {
  [key: string]: Decimal;
}

export class Decimal extends DecimalCalc {
  static mapObjectValuesToDecimal(obj: { [key: string]: any }): any {
    const mappedObj = {};

    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const value = obj[key];
        mappedObj[key] = new Decimal(value);
      }
    }

    return mappedObj;
  }

  static parseObjectValuesFromDecimal(obj: { [key: string]: Decimal }): any {
    const mappedObj = {};

    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const value: Decimal = obj[key];
        mappedObj[key] = Number.parseFloat(value.toPrecision(10));
      }
    }

    return mappedObj;
  }

  static sum(...args: Array<Decimal>): Decimal {
    let sum = new Decimal(0);
    for (const num of args) {
      sum = sum.plus(num);
    }
    return sum;
  }

  static sumNumbers(...args: Array<number>): number {
    let sum = new Decimal(0);
    for (const num of args) {
      sum = sum.plus(new Decimal(num));
    }
    return sum.toNumber();
  }

  static sub(...args: Array<Decimal>): Decimal {
    let sum = new Decimal(0);
    for (const num of args) {
      sum = sum.minus(num);
    }
    return sum;
  }
}
