import { Injectable } from '@angular/core';

@Injectable()
export class DateService {
  constructor() {}

  toCustomITFGFormat(dateToFormat: Date, dateAndTime = true): string {
    const isoStringDate: string = dateToFormat.toISOString();
    const customDate = isoStringDate.replace(
      /(\d{4}-\d{2}-\d{2})T(\d{2}:\d{2}:\d{2})\.(\d{3}Z)/,
      dateAndTime ? '$1 $2' : '$1'
    );
    return customDate;
  }

  fromCustomITFGFormat(dateToParse: string): Date {
    const isoStringDate: string = dateToParse.replace(
      /(\d{4}-\d{2}-\d{2}) (\d{2}:\d{2}:\d{2})/,
      '$1T$2.000Z'
    );
    return new Date(isoStringDate);
  }

  concatDateAndTime(date, time) {
    if (!time) {
      return date;
    }
    const hoursAndMinutes = time.split(':');
    const formattedDate = new Date(date);
    formattedDate.setHours(<any>hoursAndMinutes[0], <any>hoursAndMinutes[1]);
    return formattedDate;
  }
}
