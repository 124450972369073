<div layout="column"
     flex
     flex-gt-sm="50"
     flex-gt-xs="50">
  <mat-card appearance="outlined"
            flex>
    <mat-card-title>
      <span [translate]="'administrators.personalData'"></span>
    </mat-card-title>
    <mat-card-content>
      <div layout="column">
        <mat-form-field flex>
          <input matInput
                 type="text"
                 readonly
                 value="{{ operator.id }}"
                 placeholder="{{ 'global.number' | translate }}" />
          <mat-label>{{ 'global.number' | translate }}</mat-label>
        </mat-form-field>
        <mat-form-field flex>
          <input matInput
                 type="text"
                 readonly
                 value="{{ operator.firstName }}"
                 placeholder="{{ 'users.firstName' | translate }}" />
          <mat-label>{{ 'users.firstName' | translate }}</mat-label>
        </mat-form-field>
        <mat-form-field flex>
          <input matInput
                 type="text"
                 readonly
                 value="{{ operator.lastName }}"
                 placeholder="{{ 'users.lastName' | translate }}" />
          <mat-label>{{ 'users.lastName' | translate }}</mat-label>
        </mat-form-field>
        <mat-form-field flex>
          <input matInput
                 type="text"
                 readonly
                 value="{{ operator.email }}"
                 placeholder="{{ 'global.email' | translate }}" />
          <mat-label>{{ 'global.email' | translate }}</mat-label>
        </mat-form-field>
      </div>
    </mat-card-content>
  </mat-card>
  <mat-card class="card"
            appearance="outlined">
    <mat-card-title>
      {{ 'twoFactorAuth.trustedDevices' | translate }}
    </mat-card-title>
    <mat-card-content>
      @if (operator?.trustedDevices && operator.trustedDevices.length > 0) {
      <itfg-data-table [data]="operator?.trustedDevices"
                       [columns]="columns"
                       [isClickable]="false">
        <ng-template itfgDataTableTemplate="createTime"
                     let-value="value">
          {{ value | itfgDateFormat }}
        </ng-template>
        <ng-template itfgDataTableTemplate="lastUsed"
                     let-value="value">
          <span [matTooltip]="value | itfgDateFormat"
                matTooltipPosition="above">
            {{ value | itfgDateFormat: fromNowDateFormat }}
          </span>
        </ng-template>
        <ng-template itfgDataTableTemplate="expire"
                     let-value="value">
          <span [matTooltip]="value | itfgDateFormat"
                matTooltipPosition="above">
            {{ value | itfgDateFormat: fromNowDateFormat }}
          </span>
        </ng-template>
        <ng-template itfgDataTableTemplate="userAgent.agent"
                     let-value="value">
          <div layout="row"
               layout-align="start end"
               [matTooltip]="value"
               matTooltipPosition="after">
            <img class="browser-icon"
                 matListItemIcon
                 src="../assets/images/browser-icons/{{value | parseUserAgent:'icon'}}.png"
                 alt="Browser Logo">
            <span>{{value | parseUserAgent}}</span>
          </div>
        </ng-template>
        <ng-template itfgDataTableTemplate="actions"
                     let-row="row">
          <button color="warn"
                  mat-stroked-button
                  matTooltipPosition="after"
                  matTooltip="{{'twoFactorAuth.revokeTrustedDeviceHint' | translate}}"
                  (click)="revokeTrustedDeviceById(row.id)">
            <span>Отмени</span>
          </button>
        </ng-template>
      </itfg-data-table>
      } @else {
      <p>{{ 'twoFactorAuth.noTrustedDevices' | translate }}</p>
      }
    </mat-card-content>
    <mat-card-footer class="card-footer">
      @if(operator?.trustedDevices && operator.trustedDevices.length > 0) {
      <button type="button"
              mat-stroked-button
              matTooltip="{{'twoFactorAuth.revokeTrustedDevicesHint' | translate}}"
              color="warn"
              (click)="revokeTrustedDevicesAccess()">{{ 'twoFactorAuth.revokeAllDevices' | translate }}</button>
      }
    </mat-card-footer>
  </mat-card>
</div>
