  <button mat-icon-button
     [matMenuTriggerFor]="settings"
     matTooltip="{{ 'global.settings' | translate }}"
     class="button-color-not-active"
     flex>
    <mat-icon>settings</mat-icon>
  </button>
  <mat-menu #settings="matMenu">
    <ng-container *ngIf="showFilterCardSettings.observers.length">
      <itfg-filter-settings-button (showFilterCardSettings)="showMenu()"></itfg-filter-settings-button>
    </ng-container>
    <ng-container *ngIf="columns">
      <itfg-column-button [columns]="columns"
                          [sessionSettingsPageName]="sessionSettingsPageName"></itfg-column-button>
    </ng-container>
  </mat-menu>
