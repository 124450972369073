import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { Client } from '../../../core/types/client';
@Component({
  selector: 'itfg-client-ccr',
  templateUrl: './client-ccr.component.html',
  styleUrls: ['./client-ccr.component.scss'],
})
export class ClientCcrComponent implements OnChanges, OnInit {
  @Input() client: Client;

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
  }

}
