import {
  FilterConfigType,
  FilterValueListService,
  FilterUIType,
  FilterConfigConstructorParam,
} from '.';
import { SearchOperations } from '../../../core/types/search-criterium';
import { FilterGroupType } from './filter-group-type';
import { Filter } from './filter';

export class FilterConfig implements FilterConfigType {
  path: string;
  type: FilterUIType;
  valueListService?: FilterValueListService;
  _group: FilterGroupType;
  availableOperations: SearchOperations[];
  private _translationKey: string;
  translatedKey?: string;
  transformFilterCriteriumForApiCall: (filter: Filter) => any;

  constructor(config: FilterConfigConstructorParam) {
    this.path = config.path;
    this.type = config.type;
    if (config.translationKey) {
      this._translationKey = config.translationKey;
    }
    this._group = config.group || FilterGroupType.OTHER;
    this.valueListService = config.valueListService;
    this.transformFilterCriteriumForApiCall = config.transformFilterCriteriumForApiCall;
    this.availableOperations = config.availableOperations || [
      SearchOperations.EQUALS,
      SearchOperations.LESS_THAN,
      SearchOperations.LESS_THAN_OR_EQUAL,
      SearchOperations.GREATER_THAN,
      SearchOperations.GREATER_THAN_OR_EQUAL,
      SearchOperations.LIKE,
      SearchOperations.IN,
    ];
  }

  get nameTranslationKey() {
    if (this._translationKey) {
      return `filtering.names.${this._translationKey}`;
    } else {
      const path: string[] = this.path.split('.');
      const camelCasedPath = path.slice(1);
      camelCasedPath.forEach(
        (pathSegment: string, index: number, array: string[]) => {
          array[index] =
            pathSegment.charAt(0).toUpperCase() + pathSegment.slice(1);
        }
      );
      const pathName = camelCasedPath.join('');
      return `filtering.names.${path[0]}${pathName}`;
    }
  }

  get groupTranslationKey() {
    return `filtering.groupTypes.${this._group.toLowerCase()}`;
  }
}
