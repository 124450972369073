<mat-card-header flex layout-align="space-between end" layout="row">
    <mat-card-title
      class="edit-screen-card-title"
      [translate]="'historyLog.addContact'"
    >
    Add contact
    </mat-card-title>
    <button tabindex="-1" mat-icon-button (click)="onNoClick()">
      <mat-icon>clear</mat-icon>
    </button>
  </mat-card-header>
  <itfg-contact-data-edit
    [client]="client"
    [interactionMode]="interactionMode"
    [inputContacts]="eventLogContacts"
    [inputPhoneTypes]="eventLogPhoneTypes"
  ></itfg-contact-data-edit>