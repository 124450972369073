import {
  Directive,
  Input,
  ContentChild,
  AfterViewInit,
  Renderer2,
  ElementRef,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { DataTableComponent } from '@app/core/components/data-table/components/data-table.component';
import { Observable } from 'rxjs';

@Directive({
  selector: '[itfgHighlightDataTableRow]',
})
export class HighlightDataTableRowDirective
  implements AfterViewInit, OnChanges {
  @Input()
  public itfgHighlightDataTableRow: any;
  @ContentChild(DataTableComponent)
  public table: DataTableComponent;
  @Input()
  public onTabSelected: Observable<MatTabChangeEvent>;

  constructor(private elementRef: ElementRef, private renderer2: Renderer2) {}

  ngAfterViewInit() {
    this.initialize();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.initialize();
  }

  initialize() {
    const rowOffsetParent = this.elementRef.nativeElement.offsetParent;
    if (this.itfgHighlightDataTableRow && rowOffsetParent) {
      this.setRowActiveStyle(rowOffsetParent.parentElement);
    }
  }

  getCurrentRowIndex(data: any[], selectedRow: any) {
    return data.indexOf(selectedRow, 0);
  }

  setRowActiveStyle(row: HTMLElement) {
    this.renderer2.addClass(row, 'highlighted');
  }
}
