<itfg-main-layout [config]="menuRoutes">
  <div class="grid">
    <div class="main">
      <div class="breadcrumbs">
        <itfg-breadcrumbs class="breadcrumbs"></itfg-breadcrumbs>
      </div>
      <router-outlet></router-outlet>
    </div>
  </div>
</itfg-main-layout>
