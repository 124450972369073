import { Injectable } from '@angular/core';
import { RequestService } from './request.service';
import { Observable } from 'rxjs';
import { Page } from '../types/page';
import { Office } from '../types/office';
import { EntityHistoryService, EntityHistory } from '../types/entities';
import { EntityHistoryFilter } from '../types/entity-history-filter';

@Injectable()
export class OfficeService implements EntityHistoryService {
  request: RequestService;

  constructor(request: RequestService) {
    this.request = request;
  }

  getOfficeList$(options?): Observable<Page<Office>> {
    return this.request.get(['offices'], { params: options });
  }

  getOfficeById$(id: number | string): Observable<any> {
    return this.request.get(['offices', id]);
  }

  deleteOffice$(id: number | string): Observable<any> {
    return this.request.delete(['offices', id]);
  }

  saveOffice$(office): Observable<any> {
    return this.request.post(['offices'], {
      body: office,
      responseType: 'text',
    });
  }

  getOfficeHistory$(
    filter: EntityHistoryFilter
  ): Observable<EntityHistory<any>> {
    return this.request.get(['offices', 'history'], {
      params: {
        filter: filter.getDTO(),
      },
    });
  }

  getEntityHistory(filter: EntityHistoryFilter) {
    return this.getOfficeHistory$(filter);
  }
}
