<div layout="column" >
  <div layout="row"
       layout-align="space-between center"
       flex>
  <span
        mat-dialog-title>{{
    ((data?.type === 'id-card' ? 'verification.idCardVerificationStatusChange' : 'verification.faceVerificationStatusChange') | translate: {type: ('verification.' + data?.type | translate)})}}</span>
    <button tabindex="-1"
            mat-icon-button
            class="close-button"
            [mat-dialog-close]="false">
      <mat-icon>clear</mat-icon>
    </button>
  </div>

  <div>
    <form [formGroup]="statusForm"
          (ngSubmit)="onSubmit()">
      <div layout="column" layout-wrap>

        <mat-form-field>
          <mat-select formControlName="status"
                      placeholder="{{ 'verification.selectStatus' | translate }}">

            <mat-select-trigger>
            <span layout="row"
                  layout-align="start end">
              <mat-icon [ngStyle]="{ color: verificationStatusStyleMap[statusForm.get('status').value]?.backgroundColor }">
                {{ verificationStatusStyleMap[statusForm.get('status').value]?.icon }}</mat-icon>
              <span>{{ statusForm.get('status').value && ((data?.type === 'id-card' ? "verification.verificationStatus." : "verification.verificationStatusFace.")) + statusForm.get('status').value.toLowerCase() | translate }}</span>
            </span>
            </mat-select-trigger>

            <mat-option [value]="status.name">{{ 'global.clear' | translate }}</mat-option>
            <mat-option *ngFor="let statusName of statusChangeOptions"
                        [value]="statusName">
              <mat-icon [ngStyle]="{ color: verificationStatusStyleMap[statusName]?.backgroundColor }">
                {{ verificationStatusStyleMap[statusName]?.icon }}
              </mat-icon>
              {{ (data?.type === 'id-card' ? "verification.verificationStatus." : "verification.verificationStatusFace.") + statusName.toLowerCase() | translate }}
            </mat-option>

          </mat-select>

        </mat-form-field>

        <mat-form-field flex>

          <mat-select formControlName="reason"
                      [disableOptionCentering]="true"
                      placeholder="{{ 'verification.verificationStatusReason' | translate }}">
            <mat-option>
              <ngx-mat-select-search [formControl]="reasonFilter"
                                     placeholderLabel="{{ 'filtering.searchValues' | translate }}"
                                     noEntriesFoundLabel="{{ 'filtering.noResultsFound' | translate }}">
              </ngx-mat-select-search>
            </mat-option>
            <mat-option *ngFor="let reason of statusReasonsList"
                        [value]="reason">
              {{ (reason ? (reason | verificationStatusReasonToTranslationKey) : 'verification.verificationStatusReasonList.noReason') | translate }}
            </mat-option>
          </mat-select>

          <button matSuffix
                  type="button"
                  matTooltip="{{ 'global.clear' | translate }}"
                  matTooltipPosition="above"
                  mat-icon-button
                  (click)="clearRason($event)">
            <mat-icon>clear</mat-icon>
          </button>
        </mat-form-field>

        <div layout="row"
             layout-wrap layout-align="end center">
          <button type="submit"
                  [disabled]="statusForm.get('status').disabled && statusForm.get('reason').disabled"
                  mat-raised-button>
            {{ 'global.save' | translate }}
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
