import { Pipe, PipeTransform } from '@angular/core';
import { Administrator } from '@app/core/types/administrator';

@Pipe({
  name: 'itfgAdminIdToName',
})
export class AdministratorExtensionToNamePipe implements PipeTransform {
  transform(administrators: Administrator[], operatorId: number, includeExtension: boolean = false): string {
    if (operatorId) {
      const operator = administrators.find((administrator: Administrator) => administrator.id === Number(operatorId));
      if (operator) {
        let name = operator.firstName + ' ' + operator.lastName;

        if (includeExtension && operator.phoneExtension) {
          name += ' (' + operator.phoneExtension + ')';
        } else if (includeExtension) {
          name += ' (Липсва екстеншън)';
        }

        return name;
      } else {
        return 'Липсва вътрешен номер';
      }
    } else {
      return 'Липсва вътрешен номер';
    }
  }
}
