import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {Client} from '../../../../core/types/client';
import {AdditionOrDebt, IdentityReport, Nssi51, Nssi51PensionerValueTypes, PensionerInfo, PensionsInfo} from '../../../../core/types/identity-reports';

@Component({
  selector: 'itfg-nssi-pensioner-report',
  templateUrl: './nssi-pensioner-report.component.html',
  styleUrls: ['./nssi-pensioner-report.component.scss']
})
export class NssiPensionerReportComponent implements OnInit, OnChanges {
  @Input() report: IdentityReport<Nssi51>;

  public pensionsData: PensionsInfo[];
  public pensionerData: PensionerInfo[];
  public additionOrDebtData: AdditionOrDebt[];

  constructor() { }

  ngOnInit(): void {
  }
  ngOnChanges(changes: SimpleChanges) {
    this.pensionsData = this.report?.data?.Pensions;
    this.pensionerData = this.report?.data?.Pensioner?.filter(infoObj =>
      !Object.values(Nssi51PensionerValueTypes).includes(infoObj.PensionerNameInfo as Nssi51PensionerValueTypes)
    );
    this.additionOrDebtData = this.report?.data?.Addition_or_Debt;
  }
}
