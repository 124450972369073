import { Injectable } from '@angular/core';
import * as _ from 'lodash-es';
import { ClientMapperTypes, IDCardVerifiedTypes } from '../types/identity-reports';

@Injectable({
  providedIn: 'root',
})
export class UpdateDataRowService {

  constructor() { }

  mapTempValues(client: any, reportData: any, CLIENT_MAPPER: any, notUpdatableProperties?: any[]) {
    const dataRows = [];
    let isClientForSave = false;
    let isClientSaved = false;
    let isClientUpdated = true;

    for (const key in CLIENT_MAPPER) {
      if (CLIENT_MAPPER.hasOwnProperty(key)) {
        const value: string = CLIENT_MAPPER[key];

        const reportValue = !!_.get(reportData, value)
          ? _.get(reportData, value)
          : '';
        const tempValue = !!_.get(client, key) ? _.get(client, key) : '';
        const clientValue = !!_.get(client, key) ? _.get(client, key) : '';

        let row = {
          reportValue: reportValue,
          tempValue: tempValue,
          clientValue: clientValue,
          isTempEqualReport:
            tempValue.toString().toLowerCase() ===
            reportValue.toString().toLowerCase(),
          isTempEqualClient:
            tempValue.toString().toLowerCase() ===
            clientValue.toString().toLowerCase(),
          isReportEqualClient:
            reportValue.toString().toLowerCase() ===
            clientValue.toString().toLowerCase(),
          key: key,
          updateable: this.isRowUpdateable(key, notUpdatableProperties)
        };

        if (key === ClientMapperTypes.ID_CARD_VERIFIED) {
          row = this.modifyIdCardVerifiedValues(row, reportData);
        }
        if (key === ClientMapperTypes.ID_CARD_ADDRESS_CITY_NAME) {
          row = this.modifyIdCardCityValues(row, client);
        }


        if (key === ClientMapperTypes.CURRENT_ADDRESS_CITY_NAME) {
          row = this.modifyCurrentCityValues(row, client);
        }

        this.updateRowData(row);

        dataRows.push(row);

        if (row.updateable && !row.isReportEqualClient) {
          isClientUpdated = false;
        }
      }
    }

    return { dataRows, isClientForSave, isClientSaved, isClientUpdated };
  }


  isRowUpdateable(key: any, properties: any[]): boolean {
    if (properties) {
      return !properties.includes(key);
    }

    return true;
  }

  updateRowData(row: any) {
    row.isTempEqualReport =
      row.tempValue.toString().toLowerCase() ===
      row.reportValue.toString().toLowerCase();
    row.isTempEqualClient =
      row.tempValue.toString().toLowerCase() ===
      row.clientValue.toString().toLowerCase();
    row.isReportEqualClient =
      row.reportValue.toString().toLowerCase() ===
      row.clientValue.toString().toLowerCase();
  }

  modifyIdCardVerifiedValues(row, reportData) {
    if (row.tempValue !== null) {
      row.tempValue =
        row.tempValue === true
          ? IDCardVerifiedTypes.VALID
          : IDCardVerifiedTypes.NOT_VALID;
      row.clientValue =
        row.clientValue === true
          ? IDCardVerifiedTypes.VALID
          : IDCardVerifiedTypes.NOT_VALID;
    } else {
      row.tempValue = IDCardVerifiedTypes.NOT_VERIFIED;
      row.clientValue = IDCardVerifiedTypes.NOT_VERIFIED;
    }
    this.updateRowData(row);

    if (row.reportValue !== IDCardVerifiedTypes.VALID) {
      const reason = _.get(reportData, 'DocumentStatusReason');
      row.reportValue = IDCardVerifiedTypes.NOT_VALID;
      this.updateRowData(row);
      row.reportValue = IDCardVerifiedTypes.NOT_VALID + ' - ' + reason;
    }
    return row;
  }

  modifyIdCardCityValues(row, client) {
    const type = _.get(client, 'idCardAddress.city.type');
    row.tempValue = type ? (type + row.clientValue) : '';
    row.clientValue = type ? (type + row.clientValue) : '';
    return row;
  }
  modifyCurrentCityValues(row, client) {
    const type = _.get(client, 'currentAddress.city.type');
    row.tempValue = type ? (type + row.clientValue) : '';
    row.clientValue = type ? (type + row.clientValue) : '';
    return row;
  }

}
