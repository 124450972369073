<mat-card-content class="filters">
  <div class="decorated-heading">
    <span
      >Филтри&nbsp;&nbsp;/&nbsp;&nbsp;{{ shownFiltersLength() }} oт
      {{ filterTemplate.subTemplates.length }}</span
    >
    <button mat-icon-button class="static">
      <mat-icon> </mat-icon>
    </button>
  </div>

  <mat-selection-list
    #subTemplatesSelectionList
    [(ngModel)]="selectedOptions"
    (ngModelChange)="updateAllShown($event)"
  >
    <mat-checkbox
      class="toggle-all-checkbox"
      labelPosition="before"
      #checkAllBox
      matTooltip="{{
        (allShown || checkAllBox.isIndeterminate
          ? 'filtering.deselectAll'
          : 'filtering.selectAll'
        ) | translate
      }}"
      matTooltipPosition="left"
      color="warn"
      [checked]="allShown"
      [indeterminate]="someComplete()"
      (change)="setAll($event.checked)"
    >
    </mat-checkbox>
    <div class="mat-list-options-div">
      <mat-list-option
        *ngFor="let subprofile of filterTemplate.subTemplates"
        [value]="subprofile.name"
        [selected]="subprofile.shown"
      >
        {{ 'historyLog.auditType.' + subprofile.name | translate }}
        <mat-icon
          *ngIf="subprofile.group | isLogIssuePipe: logIssues"
          class="itfg-warn vertical-align-top"
          inline="true"
          matTooltip="{{ 'historyLog.warning' | translate }}"
          matTooltipPosition="right"
          disableTooltipInteractivity
          >warning</mat-icon
        >
      </mat-list-option>
    </div>
  </mat-selection-list>
</mat-card-content>

<div layout="row" layout-align="end center" class="filter-settings-actions">
  <span class="full-log-toggle-text">{{
    (slideToggle?.checked
      ? 'historyLog.clientFullLogOn'
      : 'historyLog.clientFullLogOff'
    ) | translate
  }}</span>
  <mat-slide-toggle
    #slideToggle
    [checked]="clientLogToggled"
    class="full-log-toggle"
    (change)="slideToggleChanged($event)"
    color="warn"
  ></mat-slide-toggle>
</div>
