import { NgModule } from '@angular/core';
import { GraphComponent } from './components/graph.component';
import { NgxGraphModule } from '@swimlane/ngx-graph';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '@app/lib/material.module';
import { GraphNodeComponent } from './components/graph-node/graph-node.component';
import { ChipNodesComponent } from './components/chip-nodes/chip-nodes.component';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { GraphService } from '@app/core/services/graph.service';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [GraphComponent, GraphNodeComponent, ChipNodesComponent],
  imports: [
    NgxGraphModule,
    MaterialModule,
    CommonModule,
    MatBottomSheetModule,
    TranslateModule,
  ],
  exports: [GraphComponent, ChipNodesComponent],
  providers: [GraphService]
})
export class GraphModule { }
