import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  ElementRef,
  Input,
  OnChanges,
  SimpleChanges,
  ViewContainerRef,
} from '@angular/core';
import { Observable, Subject, forkJoin } from 'rxjs';
import { finalize } from 'rxjs/operators';
import {
  ConversationType,
  ConversationTypes,
  DeserializedConversation,
  NoteState,
} from '../../../../../core/types/converstion';
import { ConversationService } from '../../../../../core/services/conversation.service';
import { InteractionMode } from '../../../../../core/types/interaction-mode';
import { UntypedFormBuilder, Validators, UntypedFormGroup } from '@angular/forms';
import { NotificationService } from '../../../../../core/services/notification.service';
import * as moment from 'moment/moment';
import { timeValidation } from '../../../../../core/validation/time-validation';
import { CustomErrorStateMatcher } from '../../../../../core/validation/error-state-matcher';
import { FormManipulationService } from '../../../../../core/services/form-manipulation.service';
import { ContactPhone, ContactPhoneType } from '../../../../../core/types/contact-phone';
import { CreditService } from '../../../../../core/services/credit.service';
import { takeUntil, filter, take } from 'rxjs/operators';
import { BottomDrawerService } from '../../../../../core/services/bottom-drawer.service';
import { Credit } from '../../../../../core/types/credit';
import {
  AddNoteFormMode,
  AddNoteFormState,
} from '../../../../../core/types/add-note-form-mode';
import {
  trigger,
  style,
  state,
  transition,
  animate,
} from '@angular/animations';
import { ReminderService } from '../../../../../core/services/reminder.service';
import {
  Reminder,
  CreateReminderDto,
} from '../../../../../core/types/reminder';
import { SessionService } from '../../../../../core/services/session.service';
import { PaymentPromiseService } from '../../../../../core/services/payment-promise.service';
import { PaymentPromise, PAYMENT_PROMISE_STYLE_MAP } from '../../../../../core/types/payment-promise';
import { DateService } from '../../../../../core/services/date.service';
import { ReminderOption } from './reminder-option';
import { MatDialog } from '@angular/material/dialog';
import { ReminderChooseCustomOptionComponent } from '../reminder-choose-custom-option/reminder-choose-custom-option.component';
import { RequiredValidation } from '../../../../../core/validation/required-validation';
import { ReminderEditComponent } from '../reminder-edit/reminder-edit.component';
import { NgxPermissionsService } from 'ngx-permissions';
import { SearchDirection, SearchOptions } from '../../../../../core/types/search-options';
import { SearchOperations } from '../../../../../core/types/search-criterium';
import { Page } from '../../../../../core/types/page';
import { StyleConfigMap } from '../../../../../../app/config/credit-config';
import { PaymentPromiseEditComponent } from '../payment-promise-edit/payment-promise-edit.component';
import { PaymentPromiseDetailsComponent } from '../../../../payment-promise/payment-promise-details/payment-promise-details.component';
import { DialogProviderService } from '@app/core/services/dialog-provider.service';
import { TranslateService } from '@ngx-translate/core';
import { ContactService } from '../../../../../core/services/contacts.service';
import { ClientService } from '../../../../../core/services/client.service';
import { Client } from '../../../../../core/types/client';
import { UserDetails } from '../../../../../core/types/user-details';
import { EventLogAddContactDialogComponent } from '@app/main/main-layout/right-drawer/event-log/event-log-add-contact/event-log-add-contact-dialog.component';

@Component({
  selector: 'itfg-credit-note-edit',
  templateUrl: './credit-note-edit.component.html',
  styleUrls: ['./credit-note-edit.component.scss'],
  animations: [
    trigger('fadeInAnimation', [
      state('in', style({ opacity: 1 })),
      transition(':enter', [style({ opacity: 0 }), animate(0)]),
      transition(':leave', [animate(0, style({ opacity: 0 }))]),
    ]),
  ],
})
export class CreditNoteEditComponent implements OnInit, OnChanges, OnDestroy {
  public data: any = {};
  public conversationTypes$: Observable<ConversationType[]>;
  public conversationTypesList: ConversationType[];
  public selectedConversation: DeserializedConversation;
  public mode: InteractionMode;
  public interactionModes: typeof InteractionMode;
  public conversationForm: UntypedFormGroup;
  public datepickerStartDate: Date = new Date();
  public datepickerStartDateInterval: any = setInterval(() => {
    this.datepickerStartDate = new Date();
  }, 1000 * 60);
  public noteState: NoteState;
  public phoneType: ConversationType;
  relatedContacts: ContactPhone[];
  phoneTypes: ContactPhoneType[];
  clientContact: ContactPhone | string;
  hiddenMobile: string;
  timeMatcher = new CustomErrorStateMatcher('time');
  dateMatcher = new CustomErrorStateMatcher('date');
  _unsubscribe: Subject<void> = new Subject<void>();
  _opened: boolean;
  reminderIsActive = false;
  public formModes: typeof AddNoteFormMode = AddNoteFormMode;
  @Input() formMode: AddNoteFormMode = this.formModes.PHONE;
  @Input() credit: Credit;
  @ViewChild('phoneFormField') phoneFormField;
  public formState: AddNoteFormState = new AddNoteFormState(this.formMode);
  public paymentPromiseSearchOptions: SearchOptions = new SearchOptions({
    page: 0,
    pageSize: 3,
    column: 'createdAt',
    direction: SearchDirection.DESCENDING,
  });
  public reminderSearchOptions: SearchOptions = new SearchOptions({
    page: 0,
    pageSize: 1,
    column: 'createdAt',
    direction: SearchDirection.DESCENDING,
  });
  public paymentPromises: PaymentPromise[] = [];
  reminder: Reminder;
  public promiseStatusStyleMap: StyleConfigMap;
  saveNoteButtonDisabled = false;
  private operator: UserDetails = {};


  @ViewChild('addCreditNoteTextarea')
  addCreditNoteTextarea: ElementRef;
  set addNoteOpened(opened: boolean) {
    this._opened = opened;
    if (opened) {
      setTimeout(() => this.focusTextArea(), 0);
    }
  }

  get addNoteOpened() {
    return this._opened;
  }

  constructor(
    private conversations: ConversationService,
    private clientService: ClientService,
    private creditService: CreditService,
    private contactService: ContactService,
    private formBuilder: UntypedFormBuilder,
    private formManipulationService: FormManipulationService,
    private bottomDrawerService: BottomDrawerService,
    public reminderService: ReminderService,
    private session: SessionService,
    private paymentPromiseService: PaymentPromiseService,
    private dateService: DateService,
    private dialog: MatDialog,
    public ngxPermissionService: NgxPermissionsService,
    private dialogs: DialogProviderService,
    private viewContainerRef: ViewContainerRef,
    private notifications: NotificationService,
    private translate: TranslateService,
    public sessionService: SessionService,
  ) {
    this.interactionModes = InteractionMode;
    this.setInteractionMode(InteractionMode.NEW);
    this.hiddenMobile = '0'; // value !== null needed for select option
  }

  ngOnInit() {
    // this.loadPaymentPromises();
    this.promiseStatusStyleMap = PAYMENT_PROMISE_STYLE_MAP;
    this.conversationForm = this.createConversationForm(this.formBuilder);
    this.paymentPromiseService
      .onCreditPaymentPromiseDataChange
      .pipe(takeUntil(this._unsubscribe))
      .subscribe(data => {
        this.loadPaymentPromises();
      });
    this.creditService.onCreditDetailsCreditUpdate
      .pipe(takeUntil(this._unsubscribe))
      .subscribe(credit => {
        if (credit.id !== this.data.id) {
          this.data = credit;
          this.reminderSearchOptions = new SearchOptions({
            page: 0,
            pageSize: 1,
            column: 'createdAt',
            direction: SearchDirection.DESCENDING,
            criteria: [
              {
                key: 'credit.id',
                operation: SearchOperations.EQUALS,
                value: this.data.id,
              }
            ]
          });
          this.initComponentData(this.data);
        } else {
          this.clientContact = credit.user.mobile;
        }
        this.conversationForm
          .get('conversationMedium')
          .setValue(this.phoneType);
      });

    this.conversationTypes$ = this.conversations.getConversationTypesList();
    this.conversationTypes$
      .pipe(takeUntil(this._unsubscribe))
      .subscribe(typesList => {
        this.conversationTypesList = typesList;
        this.phoneType = this.conversationTypesList.find(
          type => type.name === ConversationTypes.OUTGOING_CALL_NOT_CONTACTED
        );
        this.noteState = this.conversations.get();
        if (
          this.conversationForm &&
          this.mode === InteractionMode.NEW &&
          !this.noteState.note
        ) {
          this.conversationForm
            .get('conversationMedium')
            .setValue(this.phoneType);
          this.conversationForm.get('phone').setValue(this.clientContact);
        }
      });

    this.bottomDrawerService.addNoteOpenedState
      .pipe(takeUntil(this._unsubscribe))
      .subscribe((val: boolean) => {
        this.addNoteOpened = val;
      });

    this.contactService.contactsSubject
      .pipe(takeUntil(this._unsubscribe))
      .subscribe((contacts: ContactPhone[]) => {
        this.relatedContacts = contacts;
      });

    this.contactService.phoneTypesSubject
      .pipe(takeUntil(this._unsubscribe))
      .subscribe((phoneTypes: ContactPhoneType[]) => {
        this.phoneTypes = phoneTypes;
      });

    this.clientService.onClientSaved
      .pipe(
        takeUntil(this._unsubscribe))
      .subscribe((client: Client) => {
        this.clientContact = client.mobile;
        this.conversationForm.get('phone').setValue(this.clientContact);
      })
    this.operator = this.sessionService.currentlyLoggedOperator;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.formMode && this.formMode) {
      if (changes.formMode.previousValue !== this.formMode) {
        this.formManipulationService.resetFormFields(this.conversationForm, [
          'reminder',
          'agreedUponPaymentAmount',
          'agreedUponPaymentDate',
        ]);
      }
      this.setFormState(this.formMode);
    }
    if (changes.credit && this.credit) {
      this.paymentPromiseSearchOptions.criteria = [
        {
          key: 'active',
          operation: SearchOperations.EQUALS,
          value: 1,
        },
        {
          key: 'credit.id',
          operation: SearchOperations.EQUALS,
          value: this.credit.id,
        },
      ]
      this.loadPaymentPromises();
    }
  }

  loadReminder() {
    this.reminderService.getReminderList$(
      this.reminderSearchOptions.getDTO()
    )
      .pipe(takeUntil(this._unsubscribe))
      .subscribe((reminder: Page<Reminder>) => {
        this.reminder = reminder.content[0];
        this.reminderIsActive = this.reminderService.reminderIsActive(this.reminder);
      });
  }

  paymentPromiseChanged() {
    this.paymentPromiseService.onCreditPaymentPromiseDataChange.next(true);
  }

  getPaymentPromisesList(): Observable<Page<PaymentPromise>> {
    return this.paymentPromiseService.getPaymentPromiseList$(
      this.paymentPromiseSearchOptions.getDTO()
    );
  }

  loadPaymentPromises() {
    console.log('load payment promises')

    this.getPaymentPromisesList()
      .pipe(takeUntil(this._unsubscribe))
      .subscribe(paymentPromisePage => {
        this.paymentPromises = paymentPromisePage.content
        this.paymentPromiseService.setPromises$(paymentPromisePage);
      })
    // this.paymentPromiseService  //gets payment promises list from subject
    // .paymentPromises$
    // .subscribe((page: Page<PaymentPromise>) => {
    //   let promises = page.content.filter(p => p.active === true);
    //   promises.sort((a, b) => {
    //     return new Date(a.date).getTime() - new Date(b.date).getTime();
    //   });
    //   this.paymentPromises = promises.slice(0, 3);
    // });
  }

  toggleReminderControls() {
    this.formState.patchState({
      reminderControlsShown: !this.formState.reminderControlsShown,
    });
  }

  openCustomReminderOptionWindow() {

  }

  setFormState(formMode: AddNoteFormMode) {
    this.formState.setState(formMode);
  }

  focusTextArea() {
    if (this.addCreditNoteTextarea) {
      const el = this.addCreditNoteTextarea.nativeElement;
      el.focus();
      el.setSelectionRange(el.value.length, el.value.length);
    }
  }

  compareConversationMedia(
    firstMedium: ConversationType,
    secondMedium: ConversationType
  ) {
    if (firstMedium && secondMedium) {
      return firstMedium.id === secondMedium.id;
    } else {
      return false;
    }
  }


  openPaymentPromiseDetails(promise: PaymentPromise) {
    return this.dialog
      .open(PaymentPromiseDetailsComponent, {
        data: {
          promise,
          credit: this.credit,
        },
        minWidth: '400px',
      })
      .afterClosed()
      .pipe(
        takeUntil(this._unsubscribe),
        filter(success => !!success)
      )
      .subscribe((success: boolean) => {
        this.notifications.showLocalizedSuccessMessage({
          notificationText:
            'communication.paymentPromiseSuccessfullyEdited',
        });
        this.paymentPromiseChanged();
      });;
  }

  openPaymentPromiseCreate() {
    return this.dialog
      .open(PaymentPromiseEditComponent, {
        data: {
          credit: this.data,
        },
      })
      .afterClosed()
      .pipe(
        takeUntil(this._unsubscribe),
        filter(success => !!success)
      )
      .subscribe((success: boolean) => {
        this.paymentPromiseChanged();
      });
  }

  openAddContactDialog(event: any) {
    const currentPhoneValue = this.conversationForm.get('phone').value;

    this.conversationForm.get('phone').setValue(currentPhoneValue);

    return this.dialog
      .open(EventLogAddContactDialogComponent, {
        data: {
          client: this.credit.user,
          interactionMode: InteractionMode.NEW,
          eventLogPhoneTypes: this.phoneTypes,
          eventLogContacts: this.relatedContacts,
        },
        restoreFocus: false,
        minWidth: '900px',
      })
      .afterClosed()
      .pipe(
        takeUntil(this._unsubscribe),
        filter(success => !!success)
      )
      .subscribe((success: boolean) => {
        this.contactService.dialogEmmited.next(true);
        this.conversationForm.get('phone').setValue(this.relatedContacts[0]?.phone);
      });
  }

  openReminderEdit(reminder: Reminder = null) {
    this.dialog
      .open(ReminderEditComponent, {
        data: {
          reminder,
          credit: this.data,
        },
        minWidth: '400px',
        restoreFocus: false,
      })
      .afterClosed()
      .pipe(
        takeUntil(this._unsubscribe),
        filter(success => !!success)
      )
      .subscribe((success: boolean) => {
        this.loadReminder();
      });
  }

  initComponentData(data: any) {
    const isCredit: boolean = !!data.creditStatus;

    if (isCredit) {
      const credit: Credit = data;
      this.clientContact = credit.user.mobile;
      this.noteState = this.conversations.get();
      this.setInteractionMode(InteractionMode.NEW);
      this.conversationForm = this.createConversationForm(this.formBuilder);
      if (this.noteState && this.noteState.creditId === credit.id) {
        this.conversationForm.patchValue(this.noteState.note);
      }
      this.conversationForm.get('phone').setValue(this.clientContact);
    } else {
      const conversation: DeserializedConversation = data;
      this.clientContact = conversation.credit.user.mobile;
      this.selectedConversation = conversation;
      this.selectedConversation.phone = this.selectedConversation.phone
        ? this.selectedConversation.phone
        : this.hiddenMobile;
      this.setInteractionMode(InteractionMode.EDIT);
      this.conversationForm = this.createConversationForm(this.formBuilder);
      this.fillConversationForm(this.selectedConversation);
      this.conversationForm.get('phone').setValue(conversation.phone);
    }
    this.loadReminder();
  }

  conversationMediumChanged(event: { source: any, value: { id: number, name: ConversationTypes } }) {
    // this.formState.patchState({
    //   paymentPromiseControlsShown: (event.value.name === ConversationTypes.OUTGOING_CALL_CONTACTED || event.value.name === ConversationTypes.INCOMING_CALL)
    // });
  }

  showPaymentControls(mode) {
    this.conversationForm.get(['reminder', 'date']).setValue(null);
    this.formState.patchState({
      reminderControlsShown: false,
      paymentPromiseControlsShown: true,
    });
  }

  showReminderControls(mode) {
    this.conversationForm.get(['agreedUponPaymentDate']).setValue(null);
    this.conversationForm.get(['agreedUponPaymentAmount']).setValue(null);
    this.formState.patchState({
      reminderControlsShown: true,
      paymentPromiseControlsShown: false,
    });
  }

  onSave(event: Event) {
    this.saveNoteButtonDisabled = true;
    this.formManipulationService.markAllControlsTouched(this.conversationForm);

    if (this.conversationForm.valid) {
      const formValue = this.conversationForm.value;
      const phoneNumber =
        formValue.phone !== this.hiddenMobile ? formValue.phone : null;
      const credit = this.data;
      const newConversationDto = {
        type: null,
        contacted: null,
        phone: null,
        notes: formValue.note,
        credit: { id: credit.id },
      };
      const observables$: Array<Observable<
        DeserializedConversation | Reminder
      >> = [];

      if (this.formMode === this.formModes.PHONE) {
        newConversationDto.phone = phoneNumber;
        newConversationDto.contacted = formValue.contactEstablished;
        newConversationDto.type = formValue.conversationMedium;
      }

      this.conversations.saveConversation(
        newConversationDto
      )
        .pipe(
          finalize(() => this.saveNoteButtonDisabled = false),
          takeUntil(this._unsubscribe)
        )
        .subscribe(
          (conversation: DeserializedConversation) => {
            this.notifications.showLocalizedSuccessMessage({
              notificationText: 'communication.noteSuccessfullyAdded',
            });
            this.conversations.onCreditNoteDataChange.next(credit);
            this.clearConversationForm();
            // this.close();
          }
        );
    } else {
      console.log(this.conversationForm);
      // Form is invalid
      this.notifications.showLocalizedErrorMessage({
        notificationText: 'forms.formSavedIsInvalid',
      });
    }
  }

  createConversationForm(formBuilder: UntypedFormBuilder) {
    const fb = formBuilder;
    return fb.group({
      conversationMedium: [null],
      contactEstablished: [false],
      phone: [null, [Validators.required]],
      // agreedUponPaymentDate: [null],
      // agreedUponPaymentAmount: [null, [Validators.min(0.01)]],
      // reminder: fb.group(
      //   {
      //     date: [null],
      //     time: [null],
      //   },
      // ),
      note: [null],
    },
      {
        validator: [
          // RequiredValidation.fieldDependsOn(
          //   'agreedUponPaymentDate',
          //   ['agreedUponPaymentAmount'],
          //   'paymentAmountRequiredForPaymentDate'
          // ),
          // RequiredValidation.fieldDependsOn(
          //   'agreedUponPaymentAmount',
          //   ['agreedUponPaymentDate'],
          //   'paymentDateRequiredForPaymentAmount'
          // ),
        ],
        updateOn: 'submit'
      });
  }

  clearConversationForm() {
    this.conversationForm.reset({
      phone: this.clientContact,
      conversationMedium: this.phoneType,
      contactEstablished: false,
      agreedUponPaymentDate: null,
      agreedUponPaymentAmount: null,
      reminder: {
        date: null,
        time: null,
      },
    });
  }

  fillConversationForm(conversation: DeserializedConversation) {
    this.conversationForm.setValue({
      conversationMedium: conversation.type,
      contactEstablished: conversation.contacted,
      phone: null,
      // agreedUponPaymentDate: conversation.promisedPaymentTime || null,
      // agreedUponPaymentAmount: conversation.promisedPaymentAmount,
      // reminder: {
      //   date: conversation.nextConversationTime || null,
      //   time: conversation.nextConversationTime
      //     ? moment(conversation.nextConversationTime).format('HH:mm')
      //     : null,
      // },
      note: conversation.notes,
    });
  }

  setInteractionMode(interactionModeToSet: InteractionMode) {
    this.mode = interactionModeToSet;
  }

  close() {
    this.bottomDrawerService.addNoteOpenedState.next(false);
  }

  compareItemsByNumber(
    firstItem: { phone: number },
    secondItem: { phone: number }
  ) {
    if (firstItem && secondItem) {
      return firstItem === secondItem;
    } else {
      return false;
    }
  }

  deactivateReminder(reminder: Reminder) {
    this.dialogs
      .openConfirm({
        message: this.translate.instant('communication.deactivateReminderMessage'),
        disableClose: false,
        viewContainerRef: this.viewContainerRef,
        title: this.translate.instant('global.deactivate'),
        cancelButton: this.translate.instant('global.cancel'),
        acceptButton: this.translate.instant('global.confirm'),
      })
      .afterClosed()
      .pipe(takeUntil(this._unsubscribe))
      .subscribe((accept: boolean) => {
        if (accept) {
          this.reminderService.deactivateReminder(reminder.id)
            .pipe(takeUntil(this._unsubscribe))
            .subscribe(res => {
              this.notifications.showLocalizedSuccessMessage({
                notificationText: 'communication.deactivateReminderSuccessMessage'
              });
              this.loadReminder();
            })
        }
      });
  }

  ngOnDestroy() {
    clearInterval(this.datepickerStartDateInterval);
    this._unsubscribe.next();
    this._unsubscribe.complete();
  }
}
