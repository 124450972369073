import { Component, OnInit, ChangeDetectionStrategy, OnChanges, Input } from '@angular/core';
import { CALL_STATUS_SVG_STYLE_MAP } from '@app/config/call-config';
import { StyleConfigMap } from '@app/config/credit-config';
import { CallStatus } from '@app/core/types/call';

@Component({
  selector: 'itfg-call-status-icon',
  templateUrl: './call-status-icon.component.html',
  styleUrls: ['./call-status-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CallStatusIconComponent implements OnInit, OnChanges {
  @Input() callStatus: CallStatus;
  public icon: string;
  public class: string = '';
  public callStatusStyleMap: StyleConfigMap;

  constructor() {
    this.callStatusStyleMap = CALL_STATUS_SVG_STYLE_MAP;
  }

  ngOnInit(): void {
  }

  ngOnChanges(): void {
    if (this.callStatusStyleMap[this.callStatus]) {
      this.icon = this.callStatusStyleMap[this.callStatus].icon;
      this.class = this.callStatusStyleMap[this.callStatus]?.class;
    }
  }
}
