<mat-chip-set aria-label="Fish selection" *ngIf="shownNodes.length > 0">
  <ng-container *ngFor="let record of shownNodes">
    @switch (record?.node.labels[0]) {
      @case ("Blacklist") {
        <mat-chip 
         class="mat-elevation-z2"
         [ngStyle]="{'background-color': getColor(record) }"
        >
        <div flex layout="row" layout-align="space-between center">
          Blacklist: {{record?.node.properties.type}}, {{record?.node.properties.value}}
          
           <mat-icon class="open-graph-icon" (click)="redirectToGraph(record)">open_in_new</mat-icon>
        </div>
        </mat-chip>  
      }

      @case ("User") {
        <!-- [matTooltip]="('identityReports.graph.redirectToUser' | translate)" -->
        <mat-chip
         class="mat-elevation-z2"
         [ngStyle]="{'background-color': getColor(record) }"
         >
         <div flex class="black" layout="row" layout-align="space-between center">
           <div class="user-chip-content" (click)="redirectToUser(record)" [matTooltip]="('identityReports.graph.redirectToUser' | translate)">
             <ng-container *ngIf="record?.node.properties.civilId">
              {{ 'global.civilId' | translate}}: {{record?.node.properties.civilId}}
              </ng-container>
              <ng-container *ngIf="!record?.node.properties.civilId">
                Id: {{record?.node.properties.id}}
              </ng-container>
           </div>
           <div>
             <mat-icon class="open-graph-icon" [matTooltip]="('identityReports.graph.redirectToGraph' | translate)" (click)="redirectToGraph(record)">open_in_new</mat-icon>
           </div>
         </div>
        </mat-chip>  
      }
      
      @default {
        <mat-chip 
         class="mat-elevation-z2"
        [ngStyle]="{'background-color': 'rgb(72 116 225)' }"
        >
        <div (click)="redirectToUser(record)" [matTooltip]="('identityReports.graph.redirectToUser' | translate)">
          {{record?.node.labels[0]}}
          <mat-icon class="open-graph-icon" [matTooltip]="record.count.low" (click)="redirectToGraph(record)">open_in_new</mat-icon>
        </div>
        </mat-chip>
      } 
    }
  </ng-container>
  <mat-chip 
  *ngIf="hiddenNodes.length > 0"
  class="mat-elevation-z2"
  [ngStyle]="{'background-color': 'rgb(154 154 154)' }"
  (click)="showMore()">
  {{"global.showMore" | translate}}
  </mat-chip>
  </mat-chip-set>
