import {
  Directive,
  Input,
  HostBinding,
  OnDestroy,
  OnInit,
  OnChanges,
} from '@angular/core';
import { ColumnCardComponent } from '../column-card/column-card.component';
import { Subscription } from 'rxjs';
import { Column, ColumnStatus } from '../../../core/types/column';
import { SessionService } from '../../../core/services/session.service';

@Directive({
  selector: '[itfgColumnButton]',
})
export class ColumnButtonDirective implements OnInit, OnChanges, OnDestroy {
  @Input() forCard: ColumnCardComponent;
  @HostBinding('class.mat-accent') colored: boolean;

  private subscriptions: Subscription[];
  private initialColumns: Column[];

  constructor(private sessionService: SessionService) {
    this.subscriptions = [];
  }

  ngOnInit() {
    this.subscriptions.push(
      this.sessionService.onColumnsChange.subscribe(columns => {
        this.applyInitialColumnsOrder(columns);
      })
    );
    this.subscriptions.push(
      this.forCard.columnsService.onColumnsChange.subscribe(columns => {
        this.applyColoringIfColumnModificationsPresent(columns);
      })
    );
  }

  ngOnChanges(changes) {}

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  applyColoringIfColumnModificationsPresent(columnList: Column[]) {
    if (columnList.some(column => column.status === ColumnStatus.INACTIVE)) {
      this.colored = true;
    } else {
      this.colored = false;
    }

    if (this.initialColumns) {
      const columnsLength =
        this.initialColumns.length <= columnList.length
          ? this.initialColumns.length
          : columnList.length;
      for (let i = 0; i < columnsLength; i++) {
        if (columnList[i].config.name !== this.initialColumns[i].config.name) {
          this.colored = true;
        }
      }
    }
  }
  applyInitialColumnsOrder(initialColumnList: Column[]) {
    this.initialColumns = initialColumnList;
  }
}
