import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FilterUIType } from '@app/main/filter/types/filter-ui-type';
import { Subject } from 'rxjs';
import { ColumnFilterConfig } from '@app/main/filter/types/column-filter-config';
import { MatMenuTrigger } from '@angular/material/menu';

@Component({
  selector: 'itfg-column-filter-card',
  templateUrl: './column-filter-card.component.html',
  styleUrls: ['./column-filter-card.component.scss'],
})
export class ColumnFilterCardComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  @Input() filterConfig: ColumnFilterConfig;
  @Input() filterOptions: any[];
  @Input() menuTrigger: MatMenuTrigger;
  @Output() filterConfigValueChange = new EventEmitter<ColumnFilterConfig>();
  filterUITypes: any;
  tempSelections: any[] = [];
  _unsubscribe: Subject<void> = new Subject<void>();
  @ViewChild('valueInput') valueInput: ElementRef;

  constructor() {
    this.filterUITypes = {
      autocomplete: FilterUIType.AUTOCOMPLETE,
      datepicker: FilterUIType.DATEPICKER,
      dropdown: FilterUIType.DROPDOWN,
      input: FilterUIType.INPUT,
      multiselect: FilterUIType.MULTISELECT,
      multiselectAutocomplete: FilterUIType.MULTISELECT_AUTOCOMPLETE,
    };
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {

  }

  ngAfterViewInit(): void {
    this.menuTrigger.menuOpened.subscribe(() => {
      if (this.filterConfig && this.filterConfig.type === FilterUIType.INPUT && this.valueInput) {
        this.valueInput.nativeElement.focus();
      }
    });
  }

  emitFilterChange() {
    this.filterConfigValueChange.emit();
  }

  onSelectionChange(event: any, filterConfig: any) {
    filterConfig.currentValue = event.source.selectedOptions.selected.map(item => item.value);
  }

  stopPropagation(event: any) {
    event.stopPropagation();
    event.preventDefault();
  }

  removeFilterValue(event: any, filterConfig: any): void {
    event.stopPropagation();

    const isFilterMultiSelect = filterConfig.type === FilterUIType.MULTISELECT
    filterConfig.currentValue = isFilterMultiSelect ? [] : '';

    this.emitFilterChange();
  }

  focusInputField() {
    this.valueInput.nativeElement.focus();
  }

  applyFilters() {
    this.emitFilterChange();
  }

  ngOnDestroy() {
    this._unsubscribe.next();
    this._unsubscribe.complete();
  }
}
