import { BrowserStorageType } from '../../config/app-config';

export class StorageService {
  storage: any;

  constructor(storageType: BrowserStorageType) {
    if (storageType === BrowserStorageType.LocalStorage) {
      this.storage = window.localStorage;
    } else {
      this.storage = window.sessionStorage;
    }
  }

  getItem(itemName) {
    return this.storage.getItem(itemName);
  }

  setItem(itemName, itemValue) {
    return this.storage.setItem(itemName, itemValue);
  }

  removeItem(itemName) {
    return this.storage.removeItem(itemName);
  }
}
