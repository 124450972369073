import { Injectable } from '@angular/core';
import { SearchCriterium } from '../../core/types/search-criterium';
import { APP_CONFIG } from '../../config/app-config';
import { Subject } from 'rxjs';
import { Filter } from './types/filter';
import { FilterConfig, FilterUIType } from './types';

const FILTERING = APP_CONFIG.FILTERING;

@Injectable()
export class FilterService {
  filters: Filter[];
  filtersChanged: Subject<Filter[]>;

  constructor() {
    this.filters = [];
    this.filtersChanged = new Subject<Filter[]>();

    // this.filters.push({
    //   criterium: {
    //     key: undefined,
    //     operation: undefined,
    //     value: '',
    //   },
    // });
  }

  addFilter(filterToAdd: Filter) {
    filterToAdd = this.parseTextFilterValue(filterToAdd);
    this.filters.push(filterToAdd);
    this.notifySubscribers();
  }

  removeFilter(indexToSplice: number): Filter {
    const removedFilter = this.filters.splice(indexToSplice, 1);
    this.notifySubscribers();
    return removedFilter[0];
  }

  replaceFilter(indexToSplice: number, newFilter: Filter) {
    newFilter = this.parseTextFilterValue(newFilter);
    this.filters.splice(indexToSplice, 1, newFilter);
    this.notifySubscribers();
  }

  setFilterConfig(newConfig: FilterConfig, index: number) {
    this.filters[index].config = newConfig;
    this.notifySubscribers();
  }

  trimEmptyFilters() {
    if (this.filters.length > 1) {
      this.filters = this.filters.filter(
        this.isFilterPartiallyPopulated.bind(this)
      );
      this.notifySubscribers();
    }
  }

  formatTextFilterValue(filter: Filter): Filter {
    if (filter.config && filter.config.type === FilterUIType.INPUT) {
      const formattedFilter = Object.assign({}, filter);
      if (formattedFilter.criterium.value instanceof Array) {
        formattedFilter.criterium.value = formattedFilter.criterium.value.join(
          FILTERING.MULTI_VALUE_SEPARATOR
        );
      }
      return formattedFilter;
    } else {
      return filter;
    }
  }

  formatAutocompleteFilterValue(filter: Filter): Filter {
    if (
      filter.config &&
      (filter.config.type === FilterUIType.AUTOCOMPLETE ||
        filter.config.type === FilterUIType.MULTISELECT_AUTOCOMPLETE)
    ) {
      const formattedFilter = Object.assign({}, filter);
      formattedFilter.criterium = Object.assign({}, filter.criterium);
      const filterValue = <any>filter.criterium.value;
      if (filter.config.type === FilterUIType.AUTOCOMPLETE) {
        formattedFilter.criterium.value = filterValue.value;
      }
      if (filter.config.type === FilterUIType.MULTISELECT_AUTOCOMPLETE) {
        formattedFilter.criterium.value = filterValue.map(value => value.value);
      }
      return formattedFilter;
    } else {
      return filter;
    }
  }

  parseTextFilterValue(filter: Filter): Filter {
    if (filter.config && filter.config.type === FilterUIType.INPUT) {
      const parsedFilter = Object.assign({}, filter);
      let parsedFilterValue = <string>parsedFilter.criterium.value;
      if (
        parsedFilterValue &&
        parsedFilterValue.indexOf(
          APP_CONFIG.FILTERING.MULTI_VALUE_SEPARATOR
        ) !== -1
      ) {
        // String is multi-value
        parsedFilterValue = parsedFilterValue.trim();
        parsedFilterValue = this.trimFilterSeparators(
          parsedFilterValue,
          FILTERING.MULTI_VALUE_SEPARATOR
        );
        parsedFilterValue = this.collapseFilterSeparators(
          parsedFilterValue,
          FILTERING.MULTI_VALUE_SEPARATOR
        );
        const parsedFilterMultiValue = parsedFilterValue.split(
          FILTERING.MULTI_VALUE_SEPARATOR
        );
        parsedFilter.criterium.value = parsedFilterMultiValue;
      }
      return parsedFilter;
    } else {
      return filter;
    }
  }

  setFilters(filters: Filter[]) {
    this.filters = filters;
  }

  getFilters(): Filter[] {
    return this.filters.map(this.formatTextFilterValue.bind(this));
  }

  getPartiallyPopulatedFilters(): Filter[] {
    return this.filters
      .filter(this.isFilterPartiallyPopulated.bind(this))
      .map(this.formatTextFilterValue.bind(this));
  }

  getFullyPopulatedFilters(): Filter[] {
    return this.filters
      .filter(this.isFilterFullyPopulated.bind(this))
      .map(this.formatTextFilterValue.bind(this));
  }

  getFiltersForAPICall(): SearchCriterium[] {
    return this.getFullyPopulatedFilters()
      .map(this.formatAutocompleteFilterValue.bind(this))
      .map((filter: Filter): SearchCriterium => {
        if (filter.config.transformFilterCriteriumForApiCall) {
          const newFilter: SearchCriterium = filter.config.transformFilterCriteriumForApiCall(filter);
          return newFilter;
        }
        return filter.criterium;
      });
  }

  extractColumnsFromObjectTemplate(object: any) {
    if (typeof object === 'object') {
      return Object.keys(object);
    } else {
      return [];
    }
  }

  clearAllFilters() {
    this.filters = [];
    this.notifySubscribers();
  }

  clearAllFilterValues() {
    this.filters.map((filter: Filter) => (filter.criterium.value = undefined));
    this.notifySubscribers();
  }

  public notifySubscribers() {
    this.filtersChanged.next(
      this.filters.map(this.formatTextFilterValue.bind(this))
    );
  }

  private isFilterPartiallyPopulated(filter: Filter) {
    return (
      filter.criterium.key !== undefined ||
      filter.criterium.operation !== undefined ||
      filter.criterium.value !== undefined ||
      filter.criterium.value !== '' ||
      (<any[]>(<any>filter.criterium.value)).length !== 0
    );
  }

  private isFilterFullyPopulated(filter: Filter) {
    return (
      filter.criterium.key !== undefined &&
      filter.criterium.operation !== undefined &&
      filter.criterium.value !== undefined &&
      filter.criterium.value !== '' &&
      (<any[]>(<any>filter.criterium.value)).length !== 0
    );
  }

  private trimFilterSeparators(value: string, separator: string): string {
    const trimMatcher = new RegExp(
      '^(' + separator + ')*(.+?)(' + separator + ')*$',
      'g'
    );
    const trimReplacer = '$2';
    return value.replace(trimMatcher, trimReplacer);
  }

  private collapseFilterSeparators(value: string, separator: string): string {
    const trimMatcher = new RegExp('(' + separator + '{2,})', 'g');
    const trimReplacer = separator;
    return value.replace(trimMatcher, trimReplacer);
  }
}
