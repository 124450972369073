<div layout="row">
  <mat-card appearance="outlined" flex="50">
    <div layout="row" layout-align="space-between">
      <mat-card-title>
        <span>{{ 'global.configuration' | translate }}</span>
      </mat-card-title>

    </div>
    <mat-card-content>
      <form (ngSubmit)="onSubmit($event)" [formGroup]="form" layout="column">
        <mat-form-field flex>
          <input type="number" min="1" required matInput
          formControlName="amount" placeholder="{{ 'clients.loyaltyPointsAmount' | translate }}">
      <mat-label>{{ 'clients.loyaltyPointsAmount' | translate }}</mat-label>
          <mat-error *ngIf="form?.get('amount').hasError('required')"
                 [translate]="'forms.required'">This field is required
          </mat-error>
          <mat-error *ngIf="form.get('amount').hasError('min')"
            [translate]="'forms.minValue'" [translateParams]="{value: addLoyaltyPointsMinAmount }">
            Min value error
          </mat-error>
        </mat-form-field>
        <mat-form-field>
          <input
            matInput
            required
            readonly="true"
            [matDatepicker]="picker"
            [min]="currentDate"
            formControlName="expiryDate"
            placeholder="{{ 'clients.loyaltyPointsЕxpiryDate' | translate }}"
            (click)="picker.open()"
            >
      <mat-label>{{ 'clients.loyaltyPointsЕxpiryDate' | translate }}</mat-label>
          <mat-datepicker-toggle 
            matSuffix
            [for]="picker"
            >
          </mat-datepicker-toggle>
          <mat-datepicker #picker>
          </mat-datepicker>
          <mat-error *ngIf="form?.get('expiryDate').hasError('required')"
                 [translate]="'forms.required'">This field is required
          </mat-error>
        </mat-form-field>
        <mat-form-field style="width: 100%;">
          <textarea
            matInput
            formControlName="clientIds"
            placeholder="{{'global.clients' | translate}}"
            cdkTextareaAutosize
            cdkAutosizeMinRows="15"
            cdkAutosizeMaxRows="50"
          ></textarea>
        </mat-form-field>
        <div layout="row" layout-align="end">

          <button
            type="submit"
            color="primary"
            mat-raised-button
            [disabled]="clientIds.length === 0 || !this.form.valid"
          >
            <span [translate]="'global.proceed'">Proceed</span>
          </button>
        </div>
      </form>
    </mat-card-content>
  </mat-card>

  <mat-card appearance="outlined" flex="50">
    <div layout="row" layout-align="space-between">
      <mat-card-title>
        <span>{{ 'clients.viewClients' | translate }}</span>
      </mat-card-title>
      <span *ngIf="clientIds.length > 0" 
        class="clients-additional-info"
        >
        {{ ('clients.totalClients' | translate) + ' ' + clientIds.length }}
      </span>
      </div>
    <mat-card-content
      [ngClass]="{ 'blur-content': clientPage?.content?.length < 1 }"
    >
    <div *ngIf="clientPage?.content && !isLoadingResult">
      <itfg-data-table
        [style.height]="'80vh'"
        [data]="clientPage?.content"
        [isClickable]="true"
        [columns]="columns"
      >
        <ng-template itfgDataTableTemplate="id" let-clientId="value">
          <a routerLink="/clients/{{ clientId }}" target="_blank" flex>
            {{ clientId }}
          </a>
        </ng-template>
        <ng-template itfgDataTableTemplate="fullName" let-value="value"
                     let-row="row"
                     let-column="column">
            {{ row | clientFullName}}
        </ng-template>
        <ng-template itfgDataTableTemplate="tags"
                     let-tags="value">
          <itfg-tags-table-cell-view [tags]="tags"></itfg-tags-table-cell-view>
        </ng-template>
      </itfg-data-table>
      <mat-paginator #pagingBarResponsive
               (page)="pageChanged($event)"
               [pageIndex]="clientPage.number"
               [length]="clientPage.totalElements"
               [pageSize]="clientPage.size"
               [pageSizeOptions]="[20,30,100]"
               showFirstLastButtons>
      </mat-paginator>
    </div>
    </mat-card-content>
  </mat-card>
</div>

