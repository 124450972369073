import {Directive, ElementRef, HostListener, Input} from '@angular/core';

@Directive({
  selector: '[itfgZoomImage]'
})
export class ZoomImageDirective {
  public inputElement;
  public inputImage;
  @Input()url: string;

  constructor(private elementRef: ElementRef) {
    this.inputElement = this.elementRef.nativeElement;
  }

  @HostListener('mousemove', ['$event'])
  onMouseMove(event: MouseEvent) {
    this.inputImage = this.inputElement.children[0];
    const rect = this.inputElement.getBoundingClientRect(),
      offsetX = event.clientX - rect.left,
      offsetY = event.clientY - rect.top;
    this.inputImage.style.transformOrigin =
    ((offsetX - this.inputElement.offsetLeft) / this.inputImage.width) * 100  +
    '% ' +
      (((offsetY - this.inputElement.offsetTop + 55) / this.inputImage.height) * 100 ) +
    '%';
  }

  @HostListener('mouseover', ['$event'])
  onMouseOver(event: MouseEvent) {
    this.inputImage = this.inputElement.children[0];
    this.inputElement.style.overflow = 'hidden';
    this.inputElement.style.cursor = 'zoom-in';
    this.inputImage.style.transition = 'transform 0.5s ease-out';
    this.inputImage.style.transform = 'scale(2)';

  }

  @HostListener('mouseout', ['$event'])
  onMouseOut(event) {
    this.inputImage.style.transform = 'scale(1)';
  }
  @HostListener('click', ['$event'])
  onClick(event) {
    window.open(this.url, '_blank');
  }
}
