import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { MenuItem } from '../../config/app-config';

@Injectable()
export class NavigationMenuService {
  onCloseNavigationDrawerClick = new Subject<boolean>();
  onOpenNavigationDrawerClick: Subject<MenuItem[]> = new Subject<MenuItem[]>();
  toggleNavigationDrawerClick: Subject<void> = new Subject<void>();
  toggleProductCalculator: Subject<void> = new Subject<void>();
  onChangeLanguageClick: Subject<string> = new Subject<string>();
  onSearchDrawerOpened: Subject<void> = new Subject<void>();

  constructor() {}
}
