import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ClientCommunicationRequestService } from './client-communication-request.service';
import { SearchOptionsDTO } from '../types/search-options';
import { CreateNoteDto, Note, UpdateNoteDto } from '../types/note';

@Injectable()
export class NoteService {
  request: ClientCommunicationRequestService;
  public onNoteChange: Subject<boolean> = new Subject<boolean>();

  constructor(request: ClientCommunicationRequestService) {
    this.request = request
  }

  getNoteById(noteId: number): Observable<Note> {
    return this.request.get(['notes', noteId]);
  }

  saveNote(note: CreateNoteDto): Observable<Note> {
    return this.request.post(['notes'], {
      body: note,
    })
  }

  updateNote(noteId: number, note: UpdateNoteDto): Observable<Note> {
    return this.request.patch(['notes', noteId], {
      body: note,
    })
  }

  getNotesWithTopicsAndCall(creditId: number): Observable<Note[]> {
    return this.request.get(['notes', 'credit', creditId]);
  }

  getNotes(options: SearchOptionsDTO): Observable<Note[]> {
    return this.request.get(
      ['notes'],
      { params: options }
    );
  }
}
