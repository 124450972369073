import { FilterValueListService, FilterValue } from '../filter/types';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CallDirection } from '@app/core/types/call';

@Injectable()
export class CallDirectionValueListService
  implements FilterValueListService {
  constructor(private translate: TranslateService) {}

  getFilterValues$(): FilterValue[] {
    return Object.values(CallDirection).map(value => ({
      value: value,
      displayText: this.translate.instant('communication.callDirections.' + value.toLowerCase()),
    }));
  }
}
