<div flex>
  <div flex layout-align="space-between center" layout="row">
    <div class="modal-dialog-title" layout-align="start center" flex layout="row">
      <mat-icon flex="10">report_problem</mat-icon>
      <span *ngIf="errorData.type === 'default' || errorData.type === '400'" flex>{{ 'error.defaultErrorMessage' | translate }}</span>
      <span *ngIf="errorData.type === 'delete'" flex>{{ 'error.deleteErrorTitle' | translate }}</span>
      <span *ngIf="errorData.type === 'post'" flex>{{ 'error.postErrorTitle' | translate }}</span>
    </div>
    <button tabindex="-1" mat-icon-button (click)="onNoClick()" flex="10">
      <mat-icon>clear</mat-icon>
    </button>
  </div>
  <hr>
  <div class="mat-dialog-content">
    <div layout-align="space-between none" flex layout="column">
      <div class="error-dialog-content-spacing" *ngIf="errorData.type === 'delete'" flex>{{ 'error.deleteErrorContent' | translate }}</div>
      <div class="error-dialog-content-spacing" *ngIf="errorData.type === 'post'" flex>{{ 'error.postErrorContent' | translate }}</div>
      <div class="error-dialog-content-spacing" *ngIf="errorData.type === '400'" flex>{{'The field "' + rawError.error[0].field + '" ' + rawError.error[0].message}}</div>
        <mat-expansion-panel [expanded]="true">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <div layout-align="start center" flex layout="row">
                <div flex="10">
                  <mat-icon class="primary-icon">info</mat-icon>
                </div>
                <span flex>{{ 'error.additionalErrorInformation' | translate }}</span>
              </div>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <mat-form-field class="error-dialog-textarea-full-screen">
            <textarea matInput value="{{ errorData.errorMessage }}" readonly cdkTextareaAutosize cdkAutosizeMaxRows="30" cdkAutosizeMinRows="15"></textarea>
          </mat-form-field>
        </mat-expansion-panel>
    </div>
  </div>
  <div mat-dialog-actions>
    <div flex="100" layout="row" layout-align="end center">
      <button mat-raised-button (click)="onNoClick()">
        {{ 'global.close' | translate }}
      </button>
    </div>
  </div>
</div>