<div flex layout-align="space-between center" layout="row">
  <div class="modal-dialog-title" [translate]="'communication.sendSms'" flex>Send sms</div>
  <button tabindex="-1" mat-icon-button (click)="close()">
    <mat-icon>clear</mat-icon>
  </button>
</div>
<!-- <ng-template tdLoading tdLoadingMode="indeterminate" tdLoadingType="circle" tdLoadingStrategy="overlay" tdLoadingColor="accent"
  [tdLoadingUntil]="!dataLoading"> -->
  <div layout="column">
    <!-- <form novalidate (ngSubmit)="sendSms($event)" [formGroup]="sendSmsForm"> -->
    <form novalidate [formGroup]="sendSmsForm">
      <div class="flex-padding-margin-fix">
        <div layout="row" flex="100" layout-wrap>
          <mat-form-field class="flex-padding" flex-gt-md="15" flex-gt-sm="50" flex-gt-xs="50" flex="100">
            <mat-label>
              <span [translate]="'communication.sendTo'">Recipient sms</span>
            </mat-label>
            <mat-select required formControlName="recipientType">
              <mat-option [value]="recipientTypes.CLIENT">{{ 'communication.sendToOptions.client' | translate }}</mat-option>
              <mat-option [value]="recipientTypes.CLIENT_CONTACT">{{ 'communication.sendToOptions.contact' | translate }}</mat-option>
            </mat-select>
          </mat-form-field>
          <div *ngIf="sendSmsForm.get('clientRecipient')" formGroupName="clientRecipient" layout="row" flex-gt-md="85" flex-gt-sm="50"
            flex-gt-xs="50" flex="100">
            <mat-form-field class="flex-padding" flex-gt-md="50" flex-gt-sm="50" flex-gt-xs="50" flex="100">
              <mat-label>
                <span [translate]="'communication.recipientSms'">Recipient sms</span>
              </mat-label>
              <input type="text" matInput readonly formControlName="recipient">
            </mat-form-field>
            <mat-form-field class="flex-padding" flex-gt-md="50" flex-gt-sm="50" flex-gt-xs="50" flex="100">
              <mat-label>
                <span [translate]="'communication.template'">Template</span>
              </mat-label>
              <mat-select required formControlName="template" [compareWith]="compareItemsById">
                <mat-option *ngFor="let template of smsTemplates" [value]="template.code">{{ template.name }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div *ngIf="sendSmsForm.get('contactRecipient')" formGroupName="contactRecipient" layout="row" flex-gt-md="85" flex-gt-sm="50"
            flex-gt-xs="50" flex="100">
            <mat-form-field class="flex-padding" flex-gt-md="50" flex-gt-sm="50" flex-gt-xs="50" flex="100">
              <mat-label>
                <span [translate]="'communication.recipientSms'">Recipient sms</span>
              </mat-label>
              <mat-select 
              required 
              formControlName="recipient" 
              [compareWith]="compareItemsById"
              disableOptionCentering="true"
              >
            <div class="phone-select-panel">
              <mat-option 
                *ngFor="let relatedContact of relatedContacts" 
                [value]="relatedContact"
                itfgOverflowTooltip
                matTooltip
                [tooltipText]="relatedContact.phone + ' - ' + relatedContact.type.name + ' - ' + relatedContact.description"
                [tooltipPosition]="'above'"
                >
                {{ relatedContact.phone }} - {{ relatedContact.type.name }} - {{ relatedContact.description }}
              </mat-option>
            </div>
              </mat-select>
            </mat-form-field>
            <mat-form-field class="flex-padding" flex-gt-md="50" flex-gt-sm="50" flex-gt-xs="50" flex="100">
              <mat-label>
                <span [translate]="'communication.template'">Template</span>
              </mat-label>
              <input type="text" matInput readonly formControlName="template">
            </mat-form-field>
          </div>
        </div>
      </div>
      <div *ngIf="sendSmsForm.get('body')" layout="row" layout-wrap class="flex-padding-margin-fix">
        <mat-form-field class="flex-padding" flex="100">
          <mat-label>{{ 'global.text' | translate }}</mat-label>
          <input matInput formControlName="body" />
        </mat-form-field>
      </div>
      <div layout="row" layout-wrap layout-align="end center" class="flex-padding-fix">
        <button mat-raised-button type="button" [disabled]="disableSend" (click)="sendSms()">{{ 'global.send' | translate }}</button>
      </div>
    </form>
  </div>
<!-- </ng-template> -->