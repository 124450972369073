import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { InteractionMode } from '../../../core/types/interaction-mode';
import { ClaimService } from '../../../core/services/claims.service';
import { NotificationService } from '../../../core/services/notification.service';
import { DeserializedClaim } from '../../../core/types/claim';

@Component({
  selector: 'itfg-claim-edit',
  templateUrl: './claim-edit.component.html',
  styleUrls: ['./claim-edit.component.scss'],
})
export class ClaimEditComponent implements OnInit {
  public modes: typeof InteractionMode = InteractionMode;
  public mode: InteractionMode;
  public editMode: boolean;
  public claimForm: UntypedFormGroup;
  public claimSubjectMinLength = 5;
  public userId: number;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    private data,
    private dialogRef: MatDialogRef<ClaimEditComponent>,
    public formBuilder: UntypedFormBuilder,
    // Services
    private claimService: ClaimService,
    private notificationService: NotificationService
  ) {
    this.mode = data.mode;
    this.editMode = this.mode === this.modes.EDIT;
    this.userId = data.userId;
    this.claimForm = this.createClaimForm();
  }

  ngOnInit() {}

  addNewClaim(event) {
    if (this.claimForm.valid) {
      const claimToSave = this.parseFormDataToClaimBody(
        this.userId,
        this.claimForm
      );

      if (!this.editMode) {
        // Add new claim
        this.claimService
          .saveClaim$(claimToSave)
          .subscribe(res => this.dialogRef.close(true));
      } else {
        // Edit existing claim
        this.claimService
          .updateClaim$(this.data.claim.id, claimToSave)
          .subscribe(res => this.dialogRef.close(true));
      }
    } else {
      this.showInvalidFormNotification();
    }
  }

  showInvalidFormNotification() {
    this.notificationService.showLocalizedErrorMessage({
      notificationText: 'forms.formSavedIsInvalid',
    });
  }

  parseFormDataToClaimBody(userId: number, form: UntypedFormGroup): DeserializedClaim {
    return {
      user: {
        id: this.userId,
      },
      ...this.claimForm.value,
    };
  }

  createClaimForm(): UntypedFormGroup {
    const fb = this.formBuilder;

    return fb.group({
      subject: [
        this.editMode ? this.data.claim.subject : null,
        [Validators.required, Validators.minLength(this.claimSubjectMinLength)],
      ],
      notes: [this.editMode ? this.data.claim.notes : null],
      completed: [this.editMode ? this.data.claim.completed : null],
    });
  }

  onCloseClicked() {
    this.dialogRef.close();
  }
}
