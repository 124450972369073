import { Injectable } from '@angular/core';
import { IConfirmConfig, IAlertConfig, IPromptConfig } from '../types/dialog.type';
import { ComponentType } from '@angular/cdk/portal';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../components/confirm-dialog/confirm-dialog.component';
import { PromptDialogComponent } from '../components/prompt-dialog/prompt-dialog.component';
import { AlertDialogComponent } from '../components/alert-dialog/alert-dialog.component';
import { InfoDialogComponent } from '../components/info-dialog-component/info-dialog.component';

@Injectable()
export class DialogProviderService {
  constructor(
    private _matDialog: MatDialog,
  ) { }

  open<T>(component: ComponentType<T>, config?: MatDialogConfig): MatDialogRef<T> {
    return this._matDialog.open(component, {
      ...config,
      data: config?.data
    });
  }

  openConfirm(config: IConfirmConfig) {
    return this._matDialog.open(ConfirmDialogComponent, {
      ...config,
      data: {
        ...config,
      }
    });
  }

  openAlert(config: IAlertConfig) {
    return this._matDialog.open(AlertDialogComponent, {
      ...config,
      data: {
        ...config,
      }
    });
  }

  openPrompt(config: IPromptConfig) {
    return this._matDialog.open(PromptDialogComponent, {
      ...config,
      data: {
        ...config,
      }
    });
  }

  openInfo(config: IPromptConfig) {
    return this._matDialog.open(InfoDialogComponent, {
      ...config,
      data: {
        ...config,
      }
    });
  }

}
