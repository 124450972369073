<mat-tab-group xmlns="http://www.w3.org/1999/html">
  <mat-tab>
    <ng-template mat-tab-label>
      <div>{{ 'verification.id' | translate }}<span matBadge="!" *ngIf="idDataNewIds.length > 0"></span></div>

    </ng-template>
    <itfg-data-table [data]="updatedIdVerificationData"
                     [columns]="columns">
      <ng-template itfgDataTableTemplate="id" let-value="value" let-row="row">

        <a [routerLink]="['/credits', value]" target="_blank">
          <span [ngClass]="{'change-grey': idDataMissingIds.includes(value)}">{{ value }}
            </span>
        </a>
      </ng-template>
      <ng-template itfgDataTableTemplate="creditStatus"
                   let-status="value">
        <itfg-credit-status-icon [creditStatus]="status" [showName]="true"></itfg-credit-status-icon>
      </ng-template>
      <ng-template itfgDataTableTemplate="user"
                   let-value="value"
                   let-row="row"
                   let-column="column">
        <span [ngClass]="{'change-grey': idDataMissingIds.includes(row.id), 'change-bold': idDataNewIds.includes(row.id) }">
          {{ value | clientFullName }}
        </span>
      </ng-template>
      <ng-template itfgDataTableTemplate="tags"
                   let-tags="value">
        <itfg-tags-table-cell-view [tags]="tags"></itfg-tags-table-cell-view>
      </ng-template>
      <ng-template itfgDataTableTemplate="createTime"
                   let-createTime="value">
        <itfg-date-format [date]="createTime"
                          [fullDate]="true"></itfg-date-format>
      </ng-template>
      <ng-template itfgDataTableTemplate="operator"
                   let-value="value"
                   let-row="row"
                   let-column="column">
        {{ value | administratorFullName }}
      </ng-template>
    </itfg-data-table>
  </mat-tab>
  <mat-tab>
    <ng-template mat-tab-label>
      <div>{{ 'verification.bankAccounts' | translate }}<span matBadge="!" *ngIf="bankAccountsDataNewIds.length > 0"></span></div>
    </ng-template>
    <itfg-data-table [data]="updatedBankAccountsVerificationData"
                     [columns]="columns">
      <ng-template itfgDataTableTemplate="id" let-value="value">
        <a [routerLink]="['/credits', value]" target="_blank">{{ value }}</a>
      </ng-template>
      <ng-template itfgDataTableTemplate="creditStatus"
                   let-status="value">
        <itfg-credit-status-icon [creditStatus]="status" [showName]="true"></itfg-credit-status-icon>
      </ng-template>
      <ng-template itfgDataTableTemplate="user"
                   let-value="value"
                   let-row="row"
                   let-column="column">
        <span [ngClass]="{'change-grey': bankAccountsDataMissingIds.includes(row.id), 'change-bold': bankAccountsDataNewIds.includes(row.id) }">
          {{ value | clientFullName }}
        </span>
      </ng-template>
      <ng-template itfgDataTableTemplate="tags"
                   let-tags="value">
        <itfg-tags-table-cell-view [tags]="tags"></itfg-tags-table-cell-view>
      </ng-template>
      <ng-template itfgDataTableTemplate="createTime"
                   let-createTime="value">
        <itfg-date-format [date]="createTime"
                          [fullDate]="true"></itfg-date-format>
      </ng-template>
      <ng-template itfgDataTableTemplate="operator"
                   let-value="value"
                   let-row="row"
                   let-column="column">
        {{ value | administratorFullName }}
      </ng-template>
    </itfg-data-table>
  </mat-tab>
</mat-tab-group>
