import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewContainerRef, ViewChild} from '@angular/core';
import {ColumnsService} from '../../../core/services/columns.service';
import {bufferCount, concatMap, debounceTime, filter, finalize, map, takeUntil, takeWhile, tap, timeout} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';
import {SearchOptions} from '../../../core/types/search-options';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {NotificationService} from '../../../core/services/notification.service';
import {Credit} from '../../../core/types/credit';
import {ErrorService} from '../../../core/services/error.service';
import {SearchOperations} from '../../../core/types/search-criterium';
import {HttpErrorResponse} from '@angular/common/http';
import { ItfgDataTableColumn } from '@app/core/components/data-table/types/data-table.column';
import {forkJoin, from, Observable, Subject} from 'rxjs';
import {Page} from '../../../core/types/page';
import {ClientService} from '../../../core/services/client.service';
import {TagService} from '../../../core/services/tag.service';
import {Client} from '../../../core/types/client';
import {ListBaseComponent} from '../../list-base/list-base.component';
import {Tag, TagActionNames} from '../../../core/types/tag';
import {AutoCompleteValidation} from '../../../core/validation/autocomplete-validation';
import {startWith} from 'rxjs/operators';
import {ConfirmDialogComponent} from '../../../core/components/confirm-dialog/confirm-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import { ProgressBarDialogComponent } from '../../../core/components/progress-bar-dialog/progress-bar-dialog.component';
import { MatPaginator } from '@angular/material/paginator';

@Component({
  selector: 'itfg-bulk-tagging',
  templateUrl: './bulk-tagging.component.html',
  styleUrls: ['./bulk-tagging.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BulkTaggingComponent extends ListBaseComponent implements OnInit, OnDestroy {
  public form: UntypedFormGroup;
  public tags: Tag[] = [];
  public clientPage: Page<Client> = {};
  public columns: ItfgDataTableColumn[];
  public searchOptions = new SearchOptions({
    criteria: [],
  });
  public progressBar: number = 0;
  public isLoadingResult = false;
  public readonly CLIENTS_LIMIT = 500;
  tagActionNames: typeof TagActionNames = TagActionNames;

  public clientIds = [];
  public missingClientIds = [];
  public existingClientIds = [];

  public shownTags: Tag[] = [];
  public filteredShownTags$: Observable<Tag[]>;
  public _unsubscribe = new Subject<void>();
  _viewContainerRef: ViewContainerRef;
  @ViewChild('pagingBarResponsive', { static: true })
  public pagingBarResponsive: MatPaginator;

  private _filter(value: string): Tag[] {
    return value
      ? this.shownTags.filter(tag =>
        tag.name.toLowerCase().includes(value.toLowerCase())
      )
      : this.shownTags.slice();
  }


  constructor(
    private fb: UntypedFormBuilder,
    private clientService: ClientService,
    private translate: TranslateService,
    public columnsService: ColumnsService,
    private tagService: TagService,
    private errorHandler: ErrorService,
    private notification: NotificationService,
    public dialog: MatDialog,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    super(columnsService);
    this.form = fb.group({
      clientIds: '',
      action: this.tagActionNames.ADD,
      tagControl: [null, [
        Validators.required,
        AutoCompleteValidation.isAutocompleteValidObject('notValidTag', 'id'),
      ]]
    });
    this.form
      .get('clientIds')
      .valueChanges.pipe(
      debounceTime(500),
      takeUntil(this._unsubscribe)
    )
      .subscribe(change => {
        this.matchClientIds(change);
        this.checkForClientsIdsLimit();
      });
  }

  ngOnInit() {
    this.loadTags();
    this.getColumnLabels().subscribe(translations => {
      this.setupColumnConfig(translations);
    });
    this.triggerDataChange$.subscribe((searchOptions: SearchOptions) => {
      this.loadClientsPreview();
    });
  }

  loadTags() {
    this.tagService
      .getTagList$(new SearchOptions({pageSize: 6000}).getDTO())
      .pipe(map((page: Page<Tag>) => page.content))
      .subscribe((tagList: Tag[]) => {
        this.shownTags = tagList.filter(
          (tag: Tag) => !this.tags.map(ct => ct.id).includes(tag.id)
        );
        this.filteredShownTags$ = this.form.get('tagControl').valueChanges.pipe(
          startWith(''),
          map(value => (typeof value === 'string' ? value : value.name)),
          map(value => this._filter(value)),
          takeUntil(this._unsubscribe)
        );
      });
  }

  getColumnLabels() {
    return this.translate.get([
      'global.numberSign',
      'global.client',
      'clients.civilId',
      'global.tags',
    ]);
  }

  mapTagToDisplayValue(tag: Tag) {
    return tag ? tag.name : undefined;
  }

  setupColumnConfig(translations) {
    this.columns = [
      {
        name: 'id',
        label: translations['global.numberSign'],
        width: 70,
      },
      {
        name: 'fullName',
        label: translations['global.client'],
        isSortable: false,
      },
      {
        name: 'tags',
        label: translations['global.tags'],
      },
      {
        name: 'civilId',
        label: translations['clients.civilId'],
      },

    ];
  }

  matchClientIds(value: string) {
    this.isLoadingResult = true;

    this.clientIds = this.parseClientIds(value);
    if (this.clientIds.length > 0) {
      this.searchOptions.page = 0;
      this.searchOptions.criteria = [
        {
          key: 'id',
          operation: SearchOperations.IN,
          value: this.clientIds,
        },
      ];
      this.loadClientsPreview();
    } else {
      this.clientPage['content'] = [];
    }
  }

  loadClientsPreview() {
    this.existingClientIds = [];
    this.searchOptions.criteria = [
      {
        key: 'id',
        operation: SearchOperations.IN,
        value: this.clientIds,
      },
    ];
    this.clientService
      .getClientList(this.searchOptions.getDTO())
      .subscribe((clients: Credit[]) => {
        this.clientPage = clients;
        this.isLoadingResult = false;
        this.changeDetectorRef.detectChanges();
      });
  }

  pageChanged(page: number) {
    super.pageChanged(page);
    this.loadClientsPreview();
  }

  parseClientIds(text: string) {
    const clientIds = [];
    const regex = /(\d+)/gim;
    let match;
    do {
      match = regex.exec(text);
      if (match) {
        clientIds.push(Number(match[1]));
      }
    } while (match);

    return clientIds;
  }

  onSubmit(event: any) {
    const tag = this.form.get('tagControl').value;
    const action = this.form.get('action').value;
    
    if (this.clientIds.length > this.CLIENTS_LIMIT) {
      this.checkForClientsIdsLimit();
    } else {
      if (action === this.tagActionNames.ADD) {
        this.getDialogConfig('tags.addTag', 'tags.confirmAddClientsTagMessage',  { tag: tag.name })
              .afterClosed()
              .pipe(filter(accept => accept === true))
              .subscribe((accept: boolean) => {
                if (accept) {
                  this.updateClientsTag(tag, action);
                }
              });
      } else {
        this.getDialogConfig('tags.removeTag', 'tags.confirmRemoveClientsTagMessage',  { tag: tag.name })
              .afterClosed()
              .pipe(filter(accept => accept === true))
              .subscribe((accept: boolean) => {
                if (accept) {
                  this.updateClientsTag(tag, action);
                }
              });
    }
  }
  }

  updateClientsTag(tag, tagActionName: TagActionNames) {
    const CLIENTS_CHUNK = 20;
    const totalIterations = Math.ceil(this.clientIds.length / CLIENTS_CHUNK);
    let chunksProcessed = 0;

    const dialogConfig = {
      progressBarValue: this.progressBar,
      message: this.translate.instant('tags.processedClients', {progressBarValue: Math.round(this.progressBar)}),
      disableClose: false,
      viewContainerRef: this._viewContainerRef,
      title: this.translate.instant('tags.taggingInProgress'),
    };

    const dialogRef = this.dialog.open(ProgressBarDialogComponent, {
      data: dialogConfig,
      width: '400px',
      restoreFocus: false,
      autoFocus: false,
    });

    const chunks$ = from(this.clientIds).pipe(
      bufferCount(CLIENTS_CHUNK),
      takeWhile(chunk => chunk.length > 0)
    );

    const successMessage = tagActionName === this.tagActionNames.ADD ? 'tags.addSuccessMessage' : 'tags.removeSuccessMessage';
    const errorMessage = tagActionName === this.tagActionNames.ADD ? 'tags.addErrorMessage' : 'tags.removeErrorMessage';
    
    chunks$.pipe(
      concatMap(chunk => {
      if (tagActionName === this.tagActionNames.ADD) {
        return this.tagService.tagUsers(chunk, tag.id);
      } else {
        return this.tagService.untagUsers(chunk, tag.id);
      }
    }),
      finalize(() => {
        dialogRef.close();
      })
    ).subscribe(
      (res: any) => {
        chunksProcessed++;
        this.progressBar = (chunksProcessed / totalIterations) * 100;
        dialogRef.componentInstance.data.progressBarValue = this.progressBar
        dialogRef.componentInstance.data.message = this.translate.instant('tags.processedClients', { progressBarValue: Math.round(this.progressBar) });
  
        if (chunksProcessed === totalIterations) {
          this.loadClientsPreview();
          this.notification.showLocalizedSuccessMessage({ notificationText: successMessage });
          this.progressBar = 0;
        }
        this.changeDetectorRef.detectChanges();
      },
      (error: any) => {
        dialogRef.close();
        this.notification.showLocalizedErrorMessage({ notificationText: errorMessage });
        this.progressBar = 0;
      })
  }

  checkForClientsIdsLimit() {
    if (this.clientIds.length > this.CLIENTS_LIMIT) {
      this.getDialogConfig('tags.clientsLimit', 'tags.confirmTrimClients', {clientsLimit: this.CLIENTS_LIMIT, clients: this.clientIds.length})
            .afterClosed()
            .pipe(filter(accept => accept === true))
            .subscribe(() => {
              this.trimClientIds();
            });
        };
    }

  getDialogConfig(titleKey: string, messageKey: string, params?: any) {
    const translations = this.translate.instant(
      [
        'global.cancel', 
        'global.confirm', 
        titleKey, 
        messageKey
      ],
      params
    );
    const dialogConfig = {
      message: translations[messageKey],
      disableClose: false,
      viewContainerRef: this._viewContainerRef,
      title: translations[titleKey],
      cancelButton: translations['global.cancel'],
      acceptButton: translations['global.confirm'],
    };
    return this.dialog.open(ConfirmDialogComponent, {
      data: dialogConfig,
      width: '400px',
      restoreFocus: false,
      autoFocus: false,
    });
  }

  trimClientIds() {
    this.clientIds = this.clientIds.slice(0, this.CLIENTS_LIMIT);
    this.form.patchValue({ clientIds: this.clientIds.join(', ') });
    this.matchClientIds(this.clientIds.join(', '));
  }

  clearTag(event: any) {
    event.preventDefault();
    event.stopPropagation();
    this.loadTags();
  }

  ngOnDestroy(): void {
    this._unsubscribe.next();
    this._unsubscribe.complete();
  }
}
