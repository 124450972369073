<div class="cardContainer">
  <div flex layout="column" layout-align="center center" [ngClass]="{'is-client': clientId == node.data.id}">
    @switch (node.label) {
      @case ("User") {
        <mat-icon>face</mat-icon>
        <button matTooltipPosition="right" [matTooltip]="('identityReports.graph.redirectToUser' | translate)" (click)="redirectToUser($event, node.data.id)" mat-button> {{ node.data.id }} </button>    
      }
      @case ("IdCard") {
        <h5 [ngClass]="node.label">{{ node.label }}</h5>
        <p>Number: {{ node.data.number }} </p>
      }
      @case ("Email") {
        <h5 [ngClass]="node.label">{{ node.label }}</h5>
        <p>{{ node.data.email }} </p>
      }
      @case ("Blacklist") {
        <h5 [ngClass]="node.label">{{ node.label }}</h5>
        <p>{{node.data.type}}: {{ node.data.value }} </p>
      }
      @default {
        <h5 [ngClass]="node.label">{{ node.label }}</h5>
        <p>{{getGenericNodeDataPreview()}}</p>
      } 
    }
  </div>
</div>


