import { Injectable } from '@angular/core';
import { RequestService } from './request.service';
import { Observable } from 'rxjs';

@Injectable()
export class OverduePenaltyAccrualService {
  constructor(public request: RequestService) {}

  getOverduePenaltyAccrualsByCreditId(
    creditId: number | string
  ): Observable<any> {
    return this.request.get([
      'accruals/overdue-penalties/total?creditId=' + creditId,
    ]);
  }
}
