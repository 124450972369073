import { Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {NssiAutomationScore, NssiAutomationScoreClientUpdate} from '@core/types/identity-reports';
import {ItfgDataTableColumn} from '@core/components/data-table/types/data-table.column';
import {TranslateService} from '@ngx-translate/core';
import {Subject} from 'rxjs';

@Component({
  selector: 'itfg-nssi-automation-details',
  templateUrl: './nssi-automation-details.component.html',
  styleUrls: ['./nssi-automation-details.component.scss']
})
export class NssiAutomationDetailsComponent implements OnInit {
  tableColumns: ItfgDataTableColumn[];
  tableData = [];
  _unsubscribe: Subject<void> = new Subject<void>();

  constructor(@Inject(MAT_DIALOG_DATA) public data: {
                clientUpdate: NssiAutomationScoreClientUpdate,
                employmentType?: string,
                details: NssiAutomationScore
              }, public dialogRef: MatDialogRef<NssiAutomationDetailsComponent>, private translate: TranslateService) {
  }

  ngOnInit() {
    this.setColumns();
    this.setData();

  }

  setColumns() {
    this.tableColumns = [
      {
        name: 'label',
        label: this.translate.instant('global.client'),
        isBold: true,
      },
      {
        name: 'data',
        label: this.translate.instant('global.value'),
      },
    ];
  }

  setData() {
    Object.keys(this.data.clientUpdate).forEach((key: string) => {
      const translationKey = `nssi.clientUpdate.${key}`;
      const foundTranslation = this.translate.instant(translationKey);
      const label = (foundTranslation !== translationKey) ? foundTranslation : key;
      let data = this.data.clientUpdate[key];
      if (key === 'employmentTypeId') {
        data = this.data.employmentType;
      }
      if (key === 'workCity') {
        data = this.data.clientUpdate.workCity.type + ' ' + this.data.clientUpdate.workCity.name;
      }
      this.tableData.push({label, data});
    });
  }
  closeDialog (runNssiAutomation) {
    this.dialogRef.close(runNssiAutomation);
  }

}
