import { Injectable } from '@angular/core';
import { RequestService } from './request.service';
import { Application, ApplicationSource, ApplicationStatusNames, ParsedApplication } from '../types/application-type';
import { Observable, forkJoin, EMPTY} from 'rxjs';
import { Page } from '../types/page';
import { SearchOptionsDTO } from '../types/search-options';
import { EgnUtils } from '../utils/egn-utils';
import { Utils } from '../utils/utils';
import { ClientService } from './client.service';
import { map, mergeMap } from 'rxjs/operators';
import { CountryService } from './country.service';
import { Country } from '../types/country';
import { Education } from '../types/education';
import { EmploymentType } from '../types/employment-type';

@Injectable()
export class ApplicationService {
  constructor(
    private request: RequestService,
    private countryService: CountryService,
    private clientService: ClientService) {}

  getApplicationList$(
    applicationListOptionsDto: SearchOptionsDTO
  ): Observable<Page<Application>> {
    return this.request.get(['applications'], {
      params: applicationListOptionsDto,
    });
  }

  getApplicationByCreditId(applicationListOptionsDto: SearchOptionsDTO): Observable<ParsedApplication> {
    return this.getApplicationList$(applicationListOptionsDto).pipe(
      mergeMap((page: Page<Application>) => {
        if (page.content.length > 0) {
          return this.mapApplication(page.content[0]);
        }
        return EMPTY;
      })
    );
  }

  saveApplication$(application: Application) {
    return this.request.post(['applications'], {
      body: application,
    });
  }

  pullPendingApplication$(): Observable<Application> {
    return this.request.post(['applications', 'pending', 'pull']);
  }

   mapApplication(application: Application): Observable<ParsedApplication> {
    const nationality$ = !isNaN(Number(application.nationality)) && Number(application.nationality) > 0
    ? this.countryService.getCountryById$(Number(application.nationality))
    : this.countryService.findCountriesStartingWith(application.nationality);
  const secondNationality$ = !isNaN(Number(application.secondNationality)) && Number(application.secondNationality) > 0
    ? this.countryService.getCountryById$(Number(application.secondNationality))
    : this.countryService.findCountriesStartingWith(application.secondNationality);

  return forkJoin([nationality$, secondNationality$]).pipe(
    map(([nationality, secondNationality]) => {
      let education: Education = null;
      let employmentType: EmploymentType  = null;
      let foundNationality: Country = null;
      let foundSecondNationality: Country  = null;
      let mobile: string = null;

      if (application.education) {
        this.clientService.educationTypesSubject$.subscribe(((educationTypes: Education[]) => {
            education = educationTypes.find(e => e.id === Number(application.education))
            || educationTypes.find(e => e.name.toLowerCase() === application.education.toLowerCase())
            || { id: null, name: application.education };
        }))
      }

      if (application.employmentStatus) {
        this.clientService.employmentTypesSubject$.subscribe(((employmentTypes: EmploymentType[]) => {
          employmentType = employmentTypes.find(e => e.id === Number(application.employmentStatus))
            || employmentTypes.find(e => e.type.toLowerCase() === application.employmentStatus.toLowerCase())
            || { id: null, type: application.employmentStatus };
        }))
      }

      if (application.nationality) {
        if (Array.isArray(nationality) && nationality.length === 1) {
          foundNationality = nationality[0];
        } else if (!Array.isArray(nationality)) {
          foundNationality = nationality;
        } else {
          foundNationality = { id: null, name: application.nationality };
        }
      }

      if (application.secondNationality) {
        if (Array.isArray(secondNationality) && secondNationality.length === 1) {
          foundSecondNationality = secondNationality[0];
        } else if (!Array.isArray(secondNationality)) {
          foundSecondNationality = secondNationality;
        } else {
          foundSecondNationality = { id: null, name: application.secondNationality };
        }
      }

      if (application.mobile) {
        mobile = application.mobile.startsWith('0') ? `+359${application.mobile.substring(1)}`: application.mobile;
      }

      const parsedApplication: ParsedApplication = {
        ...application,
        mobile: mobile,
        education: education,
        employmentStatus: employmentType,
        nationality: foundNationality,
        secondNationality: foundSecondNationality
      };

      return parsedApplication;
  }))
}

  getApplicationSources(): Observable<ApplicationSource[]> {
    return this.request.get(['applications', 'sources']);
  }

  getApplicationById$(id: string | number): Observable<ParsedApplication> {
    return this.request.get(['applications', id]).pipe(
      mergeMap((application: Application) => {
       return this.mapApplication(application)
      })
    );
  }

  completeApplication$(id: number, creditId?: number): Observable<Application> {
    return this.request.post(['applications', id, 'status', 'completed'], {
      body: creditId,
    });
  }

  assignApplication$(applicationId: number) {
    return this.request.post([
      'applications',
      applicationId,
      'operator',
      'current',
    ]);
  }

  generateRandomApplication(): Application {
    const names = [
      {
        firstName: 'Автономка',
        lastName: 'Торимацова',
      },
      {
        firstName: 'Автономка',
        lastName: 'Шестакова',
      },
      {
        firstName: 'Анна',
        lastName: 'Кокошкова',
      },
      {
        firstName: 'Африкан',
        lastName: 'Симеонов',
      },
      {
        firstName: 'Босилчо',
        lastName: 'Кърков',
      },
      {
        firstName: 'Братухчо',
        lastName: 'Въчев',
      },
      {
        firstName: 'Брилянт',
        lastName: 'Иванов',
      },
      {
        firstName: 'Букетка',
        lastName: 'Градинарова',
      },
      {
        firstName: 'Буря',
        lastName: 'Куртакова',
      },
      {
        firstName: 'Ваньоздрав',
        lastName: 'Яназов',
      },
      {
        firstName: 'Великан',
        lastName: 'Тодоров',
      },
      {
        firstName: 'Веселазасмяна',
        lastName: 'Тодорова',
      },
      {
        firstName: 'Винету',
        lastName: 'Келешев',
      },
      {
        firstName: 'Генчо',
        lastName: 'Бърборков',
      },
      {
        firstName: 'Георги',
        lastName: 'Дришльов',
      },
      {
        firstName: 'Георги',
        lastName: 'Макаронски',
      },
      {
        firstName: 'Геройка',
        lastName: '(Dimitar',
      },
      {
        firstName: 'Гита',
        lastName: 'Пътечкова',
      },
      {
        firstName: 'Графиня',
        lastName: 'Скринска',
      },
      {
        firstName: 'Гуца',
        lastName: 'Контопишева',
      },
      {
        firstName: 'Гъргурин',
        lastName: 'Китов',
      },
      {
        firstName: 'Гьончо',
        lastName: 'Горчев',
      },
      {
        firstName: 'Делчо',
        lastName: 'Кукуряшков',
      },
      {
        firstName: 'Джанка',
        lastName: 'Таралингова',
      },
      {
        firstName: 'Дойничка',
        lastName: 'Многознаева',
      },
      {
        firstName: 'Европ',
        lastName: 'Европов',
      },
      {
        firstName: 'Етко',
        lastName: 'Хвърчилков',
      },
      {
        firstName: 'Ефремилия',
        lastName: 'Сопаджиева',
      },
      {
        firstName: 'Жана-Дарк',
        lastName: 'Илиева',
      },
      {
        firstName: 'Златохрист',
        lastName: 'Месаров',
      },
      {
        firstName: 'Идеал',
        lastName: 'Тухчиев',
      },
      {
        firstName: 'Изгрев',
        lastName: 'Топков',
      },
      {
        firstName: 'Изографил',
        lastName: 'Яков',
      },
      {
        firstName: 'Иконом',
        lastName: 'Иликьов',
      },
      {
        firstName: 'Исперих',
        lastName: 'Бурханларски',
      },
      {
        firstName: 'Испиридон',
        lastName: 'Вражалски',
      },
      {
        firstName: 'Касиер',
        lastName: 'Бранков',
      },
      {
        firstName: 'Кимирсен',
        lastName: 'Зенгинов',
      },
      {
        firstName: 'Кинче',
        lastName: 'Кривошапкова',
      },
      {
        firstName: 'Кокона',
        lastName: 'Катърова',
      },
      {
        firstName: 'Куцар',
        lastName: 'Фенеров',
      },
      {
        firstName: 'Ласкар',
        lastName: 'Възвъзов',
      },
      {
        firstName: 'Левент',
        lastName: 'Кьоров',
      },
      {
        firstName: 'Лимонка',
        lastName: 'Крушовска',
      },
      {
        firstName: 'Лука',
        lastName: 'И',
      },
      {
        firstName: 'Марулка',
        lastName: 'Сланинкова',
      },
      {
        firstName: 'Менто',
        lastName: 'Ментешев',
      },
      {
        firstName: 'Мечтати',
        lastName: 'Андонов',
      },
      {
        firstName: 'Мочо',
        lastName: 'Мочев',
      },
      {
        firstName: 'Мучачо',
        lastName: 'Младенов',
      },
      {
        firstName: 'Надежден',
        lastName: 'Тошев',
      },
      {
        firstName: 'Нана',
        lastName: 'Певецова',
      },
      {
        firstName: 'Нягул',
        lastName: 'Тумангелов',
      },
      {
        firstName: 'Обретен',
        lastName: 'Негрилов',
      },
      {
        firstName: 'Олимпия',
        lastName: 'Първанова',
      },
      {
        firstName: 'Паганини',
        lastName: 'Канчин',
      },
      {
        firstName: 'Партизан',
        lastName: 'Миленков',
      },
      {
        firstName: 'Патко',
        lastName: 'Патков',
      },
      {
        firstName: 'Патьо',
        lastName: 'Пондьов',
      },
      {
        firstName: 'Патьо',
        lastName: 'Самоходов',
      },
      {
        firstName: 'Паун',
        lastName: 'Четрафилски',
      },
      {
        firstName: 'Паца',
        lastName: 'Пачаръзка',
      },
      {
        firstName: 'Пацка',
        lastName: 'Куртажова',
      },
      {
        firstName: 'Пеперудка',
        lastName: 'Йоткова',
      },
      {
        firstName: 'Перко',
        lastName: 'Смърдански',
      },
      {
        firstName: 'Петилетка',
        lastName: 'Пенджакова',
      },
      {
        firstName: 'Плутон',
        lastName: 'Вакрилов',
      },
      {
        firstName: 'Портокал',
        lastName: 'Портокалов',
      },
      {
        firstName: 'Прошко',
        lastName: 'Прошков',
      },
      {
        firstName: 'Пульо',
        lastName: 'Пулев',
      },
      {
        firstName: 'Рад',
        lastName: 'Манавски',
      },
      {
        firstName: 'Райна',
        lastName: 'Точева-Клопова',
      },
      {
        firstName: 'Салфетка',
        lastName: 'Габровлиева',
      },
      {
        firstName: 'Самолетка',
        lastName: 'Първанова',
      },
      {
        firstName: 'Семка',
        lastName: 'Пройкова',
      },
      {
        firstName: 'Симфония',
        lastName: 'Младенова',
      },
      {
        firstName: 'Славка',
        lastName: 'Кочеткова',
      },
      {
        firstName: 'Солидар',
        lastName: 'Кесьов',
      },
      {
        firstName: 'Спасител',
        lastName: 'Златков',
      },
      {
        firstName: 'Стадион',
        lastName: 'Колев',
      },
      {
        firstName: 'Сталинка',
        lastName: '(Rumen',
      },
      {
        firstName: 'Станетко',
        lastName: 'Пикянски',
      },
      {
        firstName: 'Стенограф',
        lastName: 'Николов',
      },
      {
        firstName: 'Стихия',
        lastName: 'Балканска',
      },
      {
        firstName: 'Столетник',
        lastName: 'Стойков',
      },
      {
        firstName: 'Стоман',
        lastName: 'Каракашев',
      },
      {
        firstName: 'Стоян-Клод',
        lastName: 'Чуканов',
      },
      {
        firstName: 'Сърнела',
        lastName: 'Докова',
      },
      {
        firstName: 'Ташо',
        lastName: 'Мераков',
      },
      {
        firstName: 'Телефонка',
        lastName: 'Негритова',
      },
      {
        firstName: 'Тинтява',
        lastName: 'Мустакова',
      },
      {
        firstName: 'Титко',
        lastName: 'Педалов',
      },
      {
        firstName: 'Тодор',
        lastName: 'Тъпчилещов',
      },
      {
        firstName: 'Тонет',
        lastName: 'Въртунински',
      },
      {
        firstName: 'Трендафил',
        lastName: 'Кукуригов',
      },
      {
        firstName: 'Трендафил',
        lastName: 'Юрганчев',
      },
      {
        firstName: 'Трифон',
        lastName: 'Клатикрушев',
      },
      {
        firstName: 'Трошка',
        lastName: 'Пръндачка',
      },
      {
        firstName: 'Трудолюб',
        lastName: 'Монтянов',
      },
      {
        firstName: 'Унчо',
        lastName: 'Топчийски',
      },
      {
        firstName: 'Утеха',
        lastName: 'Яркова',
      },
      {
        firstName: 'Фото',
        lastName: 'Костов',
      },
      {
        firstName: 'Франчешко',
        lastName: 'Колипатков',
      },
      {
        firstName: 'Хамомилия',
        lastName: 'Васовa',
      },
      {
        firstName: 'Харалампи',
        lastName: 'Чукчуков',
      },
      {
        firstName: 'Храбър',
        lastName: 'Геройски',
      },
      {
        firstName: 'Храбър',
        lastName: 'Кобиларов',
      },
      {
        firstName: 'Христо',
        lastName: 'Лимонадов',
      },
      {
        firstName: 'Христос',
        lastName: 'Цоцов',
      },
      {
        firstName: 'Христослав',
        lastName: 'Гиздашки',
      },
      {
        firstName: 'Цоликофер',
        lastName: 'Недялков',
      },
      {
        firstName: 'Чайка',
        lastName: 'Бележкова',
      },
      {
        firstName: 'Черешка',
        lastName: 'Червенкова',
      },
      {
        firstName: 'Шумейкър',
        lastName: 'Йорданов',
      },
      {
        firstName: 'Шунка',
        lastName: 'Сапунджиева',
      },
      {
        firstName: 'Щильон',
        lastName: 'Щърбов',
      },
      {
        firstName: 'Щраус',
        lastName: 'Занов',
      },
    ];
    const randomName = names[Math.floor(Math.random() * names.length)];
    const firstNameLatin = Utils.transliterate(randomName.firstName);
    const lastNameLatin = Utils.transliterate(randomName.lastName);
    const email = `${firstNameLatin.toLowerCase()}-${lastNameLatin.toLowerCase()}@gmail.com`;
    return new Application({
      id: null,
      civilId: EgnUtils.generateRandomEgn(),
      email,
      firstName: randomName.firstName,
      middleName: '',
      lastName: randomName.lastName,
      period: Math.floor(Math.random() * 24) + 1,
      idCardNumber: '123123123',
      nationality: "",
      secondNationality: "",
      contactName: randomName.firstName + ' ' + randomName.lastName,
      contactPhone: '+359' + Utils.generateRandomNumber(111111111, 999999999),
      education: "",
      employmentStatus: "",
      mobile: '+359' + Utils.generateRandomNumber(111111111, 999999999),
      principal: Utils.generateRandomNumber(10, 1500),
      product: "",
      createTime: null,
      notes: Utils.generateRandomNumber(1, 12) + ' месеца',
      bought: null,
      reference: null,
      source: {
        id: 1,
        name: 'IMAPARI',
      },
      brand: {
        id: 1,
        name: 'SmileCredit',
        code: 'SC',
        active: true,
      },
      status: {
        id: 1,
        name: ApplicationStatusNames.NEW,
      },
      operator: null,
      credit: null,
      partner: null,
    });
  }
}
