import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { RequestService } from './request.service';
import { Observable } from 'rxjs';
import { CreditDto } from '../types/creditDto';
import { Credit } from '../types/credit';
import { CreditService } from './credit.service';
import { RefinanceRelation } from '../types/refinance-relations';
import { ReminderService } from './reminder.service';
import { NotificationService } from './notification.service';
import { ScoringRequestService } from './scoring-request.service';

@Injectable()
export class RefinanceService extends CreditService {
  constructor(
    request: RequestService,
    reminderService: ReminderService,
    notification: NotificationService,
    scoring: ScoringRequestService,
  ) {
    super(request, reminderService, notification, scoring);
  }

  getRefinancePossible$(creditId: number | string): Observable<any> {
    return this.request.get(['credits', creditId, 'refinances', 'possible']);
  }

  getRefinanceRelations$(
    creditId: number | string
  ): Observable<RefinanceRelation[]> {
    return this.request.get(['credits', creditId, 'refinances']);
  }

  saveRefinance$(oldCreditId, newCredit: CreditDto): Observable<Credit> {
    return this.request
      .post(['credits', oldCreditId, 'refinances'], { body: newCredit })
      .pipe(map(this.deserializeCredit.bind(this)));
  }
}
