import {
  Component,
  OnInit,
  Input,
  OnChanges,
  EventEmitter,
  Output,
  ViewEncapsulation,
  OnDestroy,
} from '@angular/core';
import {
  ClientStatusNames,
  ClientStatus,
} from '../../../../core/types/client-status';
import { Validators, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { NgxPermissionsService } from 'ngx-permissions';
import { ClientService } from '@app/core/services/client.service';
import { Subject, takeUntil } from 'rxjs';
import { DialogProviderService } from '@app/core/services/dialog-provider.service';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router';
import { HistoryCredit } from '@app/core/types/client-credits-history';
import { CreditStatusNames } from '@app/core/types/credit-status';

@Component({
  selector: 'itfg-status-data-edit',
  templateUrl: './status-data-edit.component.html',
  styleUrls: ['./status-data-edit.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class StatusDataEditComponent implements OnInit, OnChanges, OnDestroy {
  public clientStatusStyleMap = {
    [ClientStatusNames.ACTIVE]: {
      class: 'active-profile',
      icon: 'assignment_turned_in',
    },
    [ClientStatusNames.ARCHIVED]: {
      class: 'archived-profile',
      icon: 'archive',
    },
    [ClientStatusNames.INCOMPLETE]: {
      class: 'incomplete-profile',
      icon: 'assignment',
    },
  };
  @Input() clientId: number;
  @Input() statusEditData: ClientStatus;
  @Input() clientStatuses: ClientStatus[];
  @Output() statusDataChanged: EventEmitter<ClientStatus> = new EventEmitter<
    ClientStatus
  >();
  public clientStatus: ClientStatus = { id: null, name: '', reason: '' };
  public clientStatusNames: any;
  public isAuthorized: boolean;
  uniqueClientStatuses: any[];
  public clientHasActiveContracts: boolean;
  public activeCreditContractsStatuses: string[] = [
    CreditStatusNames.OVERDUE,
    CreditStatusNames.REGULAR,
    CreditStatusNames.REPAID,
    CreditStatusNames.LOSS,
  ];
  statusEditForm: UntypedFormGroup;
  menuStatuses: ClientStatus[];
  menuReasons: any = {};
  currentReasons: String[];

  _unsubscribe: Subject<void> = new Subject<void>();


  constructor(
    public formBuilder: UntypedFormBuilder,
    public ngxPermissionService: NgxPermissionsService,
    public clientService: ClientService,
    public dialogService: DialogProviderService,
    public translate: TranslateService,
    public activatedRoute: ActivatedRoute,
  ) {
    this.statusEditForm = this.createForm();
  }

  ngOnInit() {
    this.clientStatusNames = ClientStatusNames;
    this.checkIfAuthorized();

    this.activatedRoute.params
      .pipe(takeUntil(this._unsubscribe))
      .subscribe(params => {
        this.clientService.clientCreditHistory$.next([]);
      });
  }

  createForm() {
    return this.formBuilder.group({
      name: ['', [Validators.required]],
      reason: [''],
    });
  }

  checkIfAuthorized() {
    this.ngxPermissionService
      .hasPermission(['USER_WRITE'])
      .then((authorized: boolean) => {
        this.isAuthorized = authorized;
      });
  }

  ngOnChanges(changes) {
    if (changes.statusEditData && this.statusEditData) {
      this.clientStatus = this.statusEditData;
    }
    if (
      changes.clientStatuses &&
      this.clientStatuses &&
      this.clientStatuses.length > 0
    ) {
      if (!this.statusEditData) {
        // this.setDefaultStatus(); // default status should not be set
      }
      this.mapDataToStatuses(this.clientStatuses);
    }
  }

  setDefaultStatus() {
    this.clientStatus = this.clientStatuses.find(
      (status: ClientStatus) =>
        status.name === this.clientStatusNames.INCOMPLETE
    );
    this.selectedStatusChanged(this.clientStatus);
  }

  selectedStatusChanged(status: ClientStatus) {
    if (this.clientId && (status.name === ClientStatusNames.ARCHIVED || status.name === ClientStatusNames.INCOMPLETE)) {
      const clientCreditHistory: HistoryCredit[] = this.clientService.clientCreditHistory$.getValue();
      this.getClientCreditHistory(clientCreditHistory, status);
    } else {
      this.statusDataChanged.emit(status);
    }
  }

  getClientCreditHistory(clientCreditHistory: HistoryCredit[], status: ClientStatus) {
    if (clientCreditHistory && clientCreditHistory.length === 0) {
      this.clientService.getClientCreditsHistory$(this.clientId)
        .pipe(takeUntil(this._unsubscribe))
        .subscribe((creditHistory: HistoryCredit[]) => {
          this.checkForActiveContracts(creditHistory, status);
        });
    } else {
      this.checkForActiveContracts(clientCreditHistory, status);
    }
  }

  checkForActiveContracts(creditHistory: HistoryCredit[], status: ClientStatus) {
    this.clientService.clientCreditHistory$.next(creditHistory);
    this.clientHasActiveContracts = creditHistory
      .filter(credit => credit.userId === this.clientId)
      .some(credit => this.activeCreditContractsStatuses.includes(credit.status?.name));

    if (this.clientHasActiveContracts) {
      this.promptWarningDialog(status)
    } else {
      this.statusDataChanged.emit(status);
    }
  }

  promptWarningDialog(status: ClientStatus) {
    const dialogConfig = {
      message: status.name === ClientStatusNames.ARCHIVED ? this.translate.instant('credits.hadActiveContractsArchived') : status.name === ClientStatusNames.INCOMPLETE ? this.translate.instant('credits.hadActiveContractsIncomplete') : '',
      disableClose: false,
      title: this.translate.instant('communication.paymentPromiseTransferWarningTitle'),
      titleWarningStyle: true,
      cancelButton: this.translate.instant('global.cancel'),
      acceptButton: this.translate.instant('global.confirm'),
    };

    this.dialogService
      .openConfirm(dialogConfig)
      .afterClosed()
      .pipe(takeUntil(this._unsubscribe))
      .subscribe((accept: boolean) => {
        if (accept) {
          this.statusDataChanged.emit(status);
        }
      });
  }

  mapDataToStatuses(statuses) {
    this.uniqueClientStatuses = [];
    this.menuReasons = {};

    statuses.forEach((currentStatus: ClientStatus) => {
      const foundStatus = this.uniqueClientStatuses.find(
        status => status.name === currentStatus.name
      );
      if (currentStatus.reason) {
        if (!this.menuReasons.hasOwnProperty(currentStatus.name)) {
          this.menuReasons[currentStatus.name] = [];
        }
        this.menuReasons[currentStatus.name].push(currentStatus);
      }

      if (!foundStatus) {
        this.uniqueClientStatuses.push(currentStatus);
      }
    });
  }

  shouldShowReasonTrigger() {
    return (
      this.menuReasons.hasOwnProperty(this.clientStatus.name) &&
      this.menuReasons[this.clientStatus.name].length > 0
    );
  }

  statusMenuOpened() {
    this.menuStatuses = this.uniqueClientStatuses.filter(
      status => status.name !== this.clientStatus.name
    );
  }

  reasonMenuOpened() {
    this.currentReasons = this.menuReasons[this.clientStatus.name].filter(
      reason => reason.reason !== this.clientStatus.reason
    );
  }

  ngOnDestroy() {
    this._unsubscribe.next();
    this._unsubscribe.complete();
  }
}
