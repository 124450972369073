import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FilterValueListService, FilterValue } from '../filter/types';
import { BrandService } from '../../core/services/brand.service';
import { Brand } from '../../core/types/brand';

@Injectable()
export class BrandValueListService implements FilterValueListService {
  constructor(private brands: BrandService) {}

  getFilterValues$(): Observable<FilterValue[]> {
    return this.brands.getBrandList().pipe(
      map((brandList: Brand[]): FilterValue[] => {
        return brandList.map(
          (brand: Brand): FilterValue => {
            return {
              value: brand.id,
              displayText: brand.name,
            };
          }
        );
      })
    );
  }
}
