import { Component, OnInit, OnDestroy, ViewChild, ViewContainerRef } from '@angular/core';
import {
  SearchOptions,
  SearchDirection,
} from '../../../core/types/search-options';
import { ClientService } from '../../../core/services/client.service';
import { Client, ClientOptionsMenuConfig } from '../../../core/types/client';
import { Page } from '../../../core/types/page';
import {MatDialog} from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ColumnsService } from '../../../core/services/columns.service';
import { FilterService } from '../../filter/filter.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { SearchCriterium } from '../../../core/types/search-criterium';
import { IncompleteClientListFilterConfigService } from './incomplete-client-list-filter-config.service';
import { DialogProviderService } from '@app/core/services/dialog-provider.service';
import { SessionService } from '../../../core/services/session.service';
import * as moment from 'moment/moment';
import 'moment/locale/bg';
import { ItfgDataTableColumn } from '@app/core/components/data-table/types/data-table.column';
import { ItfgDataTableSortingOrder } from '@app/core/components/data-table/types/sort.type';

@Component({
  selector: 'itfg-incomplete-client-list',
  templateUrl: './incomplete-client-list.component.html',
  styleUrls: ['./incomplete-client-list.component.scss'],
  providers: [FilterService, IncompleteClientListFilterConfigService, SessionService],
})
export class IncompleteClientListComponent implements OnInit, OnDestroy {
  public columns: ItfgDataTableColumn[];
  clientList: Client[] = [];
  appliedColumns: ItfgDataTableColumn[];
  public clientPage: Page<Client>;
  public currentPage: number;
  public dialogConfig: any;
  public clientObjectTemplate: Client;
  public searchOptions: SearchOptions;
  public filtersShown: boolean;
  public filterCardSettingsShown: boolean;
  @ViewChild('pagingBarResponsive', { static: true })
  public pagingBarResponsive;
  public sortBy: string;
  public sortOrder: ItfgDataTableSortingOrder =
  ItfgDataTableSortingOrder.DESCENDING;
  _unsubscribe: Subject<void> = new Subject<void>();
  actionsConfig: ClientOptionsMenuConfig = new ClientOptionsMenuConfig({
    showClaims: false,
    showCommunication: false,
    showClientHistory: false,
    showCreateNewCredit: false,
    showCreditsHistory: false,
  });

  constructor(
    public clientService: ClientService,
    public translate: TranslateService,
    public filters: FilterService,
    public _dialogService: DialogProviderService,
    public _viewContainerRef: ViewContainerRef,
    public dialog: MatDialog,
    public filterConfig: IncompleteClientListFilterConfigService,
    public columnsService: ColumnsService
  ) {
  }

  ngOnInit() {
    this.loadClientList();
    this.setupColumnConfig();
    this.columnsTableChangeSubscription();
  }

  setupColumnConfig() {
    this.translate
      .get([
        'global.numberSign',
        'global.civilId',
        'clients.createTime',
        'global.email',
        'global.tags',
        'global.personalId',
        'global.phoneNumber',
        'global.status',
        'global.actions',
      ])
      .subscribe(translation => {
        this.columns = [
          {
            name: 'id',
            label: translation['global.numberSign'],
            width: 80,
          },
          {
            name: 'createTime',
            label: translation['clients.createTime'],
            format: createTime => moment(createTime).fromNow(),
          },
          {
            name: 'civilId',
            label: translation['global.civilId'],
            isSortable: false,
          },
          {
            name: 'email',
            label: translation['global.email'],
            width: {
              min: 100,
            },
          },
          {
            name: 'tags',
            label: translation['global.tags'],
            isSortable: false,
            width: {
              min: 100,
            },
          },
          {
            name: 'mobile',
            label: translation['global.phoneNumber'],
            width: {
              min: 100,
            },
          },
          {
            name: 'actions',
            label: translation['global.actions'],
            width: 50,
            isSortable: false,
          },
        ];
      });
  }

  private loadClientList() {
    const clients$ = this.clientService.getIncompleteUsers$();
    clients$.subscribe((clientList: Client[]) => {
      this.clientList = clientList;
      console.log(this.clientList)
    });
  }

  getInitialGridData(options?: any) {
    this.getClientData(options);
  }

  getClientData(options: any) {
    this.clientService.getClientList(options).subscribe(response => {
      this.clientPage = response;
      this.clientObjectTemplate = this.clientPage.content[0];
    });
  }
  
  // TODO: Apply filter in the template in the future when it's able to work.
  toggleFilters() {
    this.filtersShown = !this.filtersShown;
  }

  onShowFilterCardSettings() {
    this.filterCardSettingsShown = !this.filterCardSettingsShown;
  }

  onFiltersApplied(filters: SearchCriterium[]) {
    this.searchOptions.criteria = filters;
    this.currentPage = this.pagingBarResponsive.initialPage - 1;
    this.searchOptions.page = this.currentPage;
    this.pagingBarResponsive.pageSize = this.searchOptions.pageSize;
    this.pagingBarResponsive.navigateToPage(
      this.pagingBarResponsive.initialPage
    );
  }

  columnsTableChangeSubscription() {
    this.columnsService.onColumnsTableChange
      .pipe(takeUntil(this._unsubscribe))
      .subscribe(columns => {
        this.appliedColumns = columns;
      });
  }

  ngOnDestroy() {
    this._unsubscribe.next();
    this._unsubscribe.complete();
  }
}
