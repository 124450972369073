import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardComponent } from './card/card.component';
import { AddCardComponent } from './add-card/add-card.component';
import { MaterialModule } from '../../lib/material.module';

@NgModule({
  imports: [CommonModule, MaterialModule],
  declarations: [CardComponent, AddCardComponent],
  exports: [CardComponent, AddCardComponent],
})
export class CardModule {}
