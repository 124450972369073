
<button [disabled]="disabled"
        type="button"
        class="list-screen-operations-item"
        mat-icon-button
        class="button-color-not-active"
        [matTooltip]="toolTip"
        (click)="addButtonClicked()"
        >
        <mat-icon fontSet="fa-solid" fontIcon="fa-plus"></mat-icon>
</button>
