import { Component, EventEmitter, Input, OnDestroy, Output } from "@angular/core";
import { Subject } from "rxjs";


@Component({
  selector: 'itfg-single-company-phone',
  templateUrl: './single-company-phone.component.html',
  styleUrls: ['./single-company-phone.component.scss'],
})
export class SingleCompanyPhoneComponent implements OnDestroy {
  _unsubscribe: Subject<void> = new Subject<void>();
  @Input() data: any //TODO: add interface
  @Input() disableAddToContacts: boolean //TODO: add interface
  @Output() addToContacts: EventEmitter<any> = new EventEmitter();

  constructor(
  ) {}

  onAddToContacts() {
    this.addToContacts.emit(this.data);
  }
  ngOnDestroy() {
    this._unsubscribe.next();
    this._unsubscribe.complete();
  }
  }
