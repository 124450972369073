import { NgModule } from '@angular/core';
import { CreditNoteEditComponent } from './credit-note-edit.component';
import { CreditPipesModule } from '../../../credit-pipes.module';
import { CommonModule, } from '@angular/common';
import { MaterialModule } from '../../../../../lib/material.module';
import { TranslateModule } from '@ngx-translate/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { SharedModule } from '../../../../../shared/shared.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { A11yModule } from '@angular/cdk/a11y';
import { DataTableModule } from '@app/core/components/data-table/data-table.module';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    TranslateModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    CreditPipesModule,
    A11yModule,
    DataTableModule,
    MatExpansionModule,
    RouterModule,
  ],
  declarations: [CreditNoteEditComponent],
  exports: [CreditNoteEditComponent],
})
export class CreditNoteEditModule {}
