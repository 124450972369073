import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MenuItem } from '../../../config/app-config';
import { NAVIGATION_CONFIG } from '../../../config/navigation-config';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'itfg-main-pages-menu',
  templateUrl: './main-pages-menu.component.html',
  styleUrls: ['./main-pages-menu.component.scss'],
})
export class MainPagesMenuComponent implements OnInit, OnDestroy {
  @Input() routes;
  public route;
  public menuRoutes: MenuItem[];
  _unsubscribe: Subject<void> = new Subject<void>();

  constructor(private router: Router) { }

  ngOnInit() {
    this.initMenuRoutes();

    this.router.events.pipe(takeUntil(this._unsubscribe)).subscribe(event => {
      this.initMenuRoutes();
    });
  }

  initMenuRoutes() {
    this.route = this.router.url;
  
    // Specific logic for Bondster routes
    const findBondsterRoutes = (bondsterRoute, routeToFind) => {
      let bondsterChildRoutes = []
      const bondsterRouteToFind = routeToFind.split('/').slice(-2).join('/');
      const bondsterSubRoute = bondsterRoute.children.find(subRoute => subRoute.routerLink.includes(bondsterRouteToFind));
  
      if (bondsterSubRoute) {
       bondsterChildRoutes.push(bondsterSubRoute);
  
        const bondsterPayments = bondsterRoute.children.find(child => child.label === 'global.payments');
        const bondsterTasksRoute = bondsterRoute.children.find(child => child.label === 'global.tasks');
        if (bondsterPayments) {
          bondsterChildRoutes.push(bondsterPayments);
        }
        if (bondsterTasksRoute) {
          bondsterChildRoutes.push(bondsterTasksRoute);
        }
      }

      return bondsterChildRoutes;
    };
  
    const findRoute = (routes, routeToFind) => {
      for (let route of routes) {
        if (route.routerLink === routeToFind) {
          return route.children;
        }
  
        // Specific logic for Bondster routes
        if (route.label === 'p2p.bondster' && routeToFind.includes('/p2p/bondster')) {
          return findBondsterRoutes(route, routeToFind);
        }
  
        if (route.children) {
          const foundRoutes = findRoute(route.children, routeToFind);
          if (foundRoutes && foundRoutes.length > 0) {
            return foundRoutes;
          }
        }
      }
      return null;
    }
  
    const foundRoutes = findRoute(NAVIGATION_CONFIG.routes, this.route);
    this.menuRoutes = foundRoutes ? foundRoutes : [];
  }

  ngOnDestroy() {
    this._unsubscribe.next();
    this._unsubscribe.complete();
  }
}
