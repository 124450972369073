import { Injectable } from '@angular/core';
import { RequestService } from './request.service';
import { Observable } from 'rxjs';

@Injectable()
export class ForfeitAccrualService {
  constructor(public request: RequestService) {}

  getForfeitAccrualsByCreditId(creditId: number | string): Observable<any> {
    return this.request.get(['accruals/forfeits/total?creditId=' + creditId]);
  }
}
