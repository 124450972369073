import { Component, Inject } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { NotificationService } from '../../../../../core/services/notification.service';
import { PaymentPromiseService } from '../../../../../core/services/payment-promise.service';
import {
  PaymentPromise,
} from '../../../../../core/types/payment-promise';
import { CustomErrorStateMatcher } from '../../../../../core/validation/error-state-matcher';
import { RequiredValidation } from '../../../../../core/validation/required-validation';
import * as moment from 'moment/moment';
import { SessionService } from '../../../../../core/services/session.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Credit } from '../../../../../core/types/credit';
import { catchError } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError as observableThrowError } from 'rxjs';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { ItfgDateAdapter } from '@app/core/adapters/date-adapter.service';
import { DateFormat } from '@app/core/types/date-format';

const MY_DATE_FORMATS = {
  parse: {
    dateInput: 'DD.MM.YYYY',
  },
  display: {
    dateInput: 'DD.MM.YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'itfg-payment-promise-edit',
  templateUrl: './payment-promise-edit.component.html',
  styleUrls: ['./payment-promise-edit.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: ItfgDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS },
  ],
})
export class PaymentPromiseEditComponent {
  paymentsForm: UntypedFormGroup;
  dateMatcher = new CustomErrorStateMatcher('date');
  public datepickerStartDate: Date = new Date();
  dateFormat: string = DateFormat.DATE;
  constructor(
    public dialogRef: MatDialogRef<PaymentPromiseEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { credit: Credit },
    public fb: UntypedFormBuilder,
    public paymentPromiseService: PaymentPromiseService,
    public notifications: NotificationService,
    public session: SessionService
  ) {
    this.paymentsForm = this.createForm(fb);
  }

  createForm(formBuilder: UntypedFormBuilder) {
    const fb = formBuilder;
    return fb.group(
      {
        agreedUponPaymentDate: [null],
        agreedUponPaymentAmount: [null, [Validators.min(0.01)]],
      },
      {
        validator: [
          RequiredValidation.fieldDependsOn(
            'agreedUponPaymentDate',
            ['agreedUponPaymentAmount'],
            'paymentAmountRequiredForPaymentDate'
          ),
          RequiredValidation.fieldDependsOn(
            'agreedUponPaymentAmount',
            ['agreedUponPaymentDate'],
            'paymentDateRequiredForPaymentAmount'
          ),
        ],
      }
    );
  }

  onNoClick() {
    this.dialogRef.close();
  }

  onSave(event) {
    if (this.paymentsForm.valid) {
      const formValue = this.paymentsForm.value;
      const reminderDate = moment(formValue.agreedUponPaymentDate).format('YYYY-MM-DD');
      const paymentPromiseDto: PaymentPromise = {
        amount: formValue.agreedUponPaymentAmount,
        credit: {
          id: this.data.credit.id,
        },
        date: reminderDate,
        operatorId: this.session.currentlyLoggedOperator.id,
        operatorEmail: this.session.currentlyLoggedOperator.email,
      };
      this.paymentPromiseService
        .createPaymentPromise$(paymentPromiseDto)
        .pipe(
          catchError((error: HttpErrorResponse, caught: Observable<any>) => {
            this.notifications.showLocalizedErrorMessage({
              notificationText: error.error[0].message,
            });
            return observableThrowError(error);
          })
        )
        .subscribe(res => {
          this.paymentPromiseService.onCreditPaymentPromiseDataChange.next(
            true
          );
          this.notifications.showLocalizedSuccessMessage({
            notificationText: 'communication.paymentPromiseSuccessfullyAdded',
          });
          this.dialogRef.close(res);
        });
    } else {
      this.notifications.showLocalizedErrorMessage({
        notificationText: 'forms.formSavedIsInvalid',
      });
    }
  }
}
