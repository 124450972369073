<mat-card-header flex
                 layout-align="space-between end"
                 class="padding-0"
                 layout="row">
  <mat-card-title class="edit-screen-card-title">
    {{ dialogMode === callDialogModes.CREATE ? ('pbx.actions.createCall' | translate) : ('pbx.actions.updateCall' |
    translate) }}
  </mat-card-title>
  <button tabindex="-1"
          mat-icon-button
          (click)="onNoClick()">
    <mat-icon>clear</mat-icon>
  </button>
</mat-card-header>
<form novalidate
      layout="column"
      [formGroup]="createCallForm"
      (ngSubmit)="onSave()">
  <mat-form-field flex>
    <mat-select required
                formControlName="phoneNumber"
                placeholder="{{ 'global.phoneNumber' | translate }}">
      <mat-option *ngIf="data?.phoneNumber && data.phoneNumber !== hiddenMobile"
                  [value]="data.phoneNumber">{{ data.phoneNumber }}</mat-option>

      <mat-option [value]="hiddenMobile">{{ 'communication.hiddenNumber' | translate }}</mat-option>
    </mat-select>
    <mat-label>{{ 'global.phoneNumber' | translate }}</mat-label>
    <mat-error *ngIf="createCallForm.get('phoneNumber').hasError('required')"
               [translate]="'forms.required'">This field is required</mat-error>
  </mat-form-field>

  <div layout="row"
       layout-align="space-between center">
    <mat-form-field flex="50">
      <mat-select required
                  formControlName="direction"
                  placeholder="{{ 'filtering.names.callDirection' | translate }}">
        <mat-option *ngFor="let direction of callDirectionTypes"
                    [value]="direction">
          {{ ('communication.callDirections.' + direction.toLowerCase()) | translate }}
        </mat-option>
      </mat-select>
      <mat-label>{{ 'filtering.names.callDirection' | translate }}</mat-label>
      <mat-error *ngIf="createCallForm.get('direction').hasError('required')"
                 [translate]="'forms.required'">This field is required</mat-error>
    </mat-form-field>
    <mat-form-field flex="50">
      <mat-select required
                  panelClass="call-status-select-width"
                  formControlName="status"
                  disableOptionCentering="true"
                  placeholder="{{ 'filtering.names.callStatus' | translate }}">
        <mat-option *ngFor="let status of callStatusTypes"
                    [value]="status">
          {{ (status | callStatusTranslationPipe) | translate }}
        </mat-option>
      </mat-select>
      <mat-label>{{ 'filtering.names.callStatus' | translate }}</mat-label>
      <mat-error *ngIf="createCallForm.get('status').hasError('required')"
                 [translate]="'forms.required'">This field is required</mat-error>
    </mat-form-field>
  </div>
  <mat-form-field flex>
    <mat-select required
                [(value)]="selectedCredit"
                [disabled]="!data.creditContextArr || data.creditContextArr.length === 0"
                placeholder="{{ 'global.credit' | translate }}"
                (selectionChange)="onSelectedCreditChanged($event)">
      <mat-select-trigger>
        <span *ngIf="selectedCredit"
              layout="row"
              layout-align="start center">
          <itfg-credit-status-icon [creditStatus]="selectedCredit?.creditStatus"></itfg-credit-status-icon>
          {{ selectedCredit?.id }} / {{ selectedCredit?.user?.fullName || 'communication.missingName' | translate }}
        </span>
      </mat-select-trigger>

      <mat-option *ngFor="let credit of data.creditContextArr"
                  [value]="credit">
        <div layout="row">
          <itfg-credit-status-icon [creditStatus]="credit.creditStatus"></itfg-credit-status-icon>
          <span>
            {{ credit.id }} / <span>{{
              credit.user.fullName
              ? credit.user.firstName + ' ' + credit.user.lastName
              : 'communication.missingName' | translate
              }} / </span>
            <span>{{
              credit.user.civilId ? credit.user.civilId : 'communication.missingCivilId' | translate
              }}</span>
          </span>
        </div>
      </mat-option>
    </mat-select>
    <!-- <mat-icon matSuffix
              mat-icon-button
              (click)="clearSelectedCredit()"
              aria-label="Премахни кредита">
      close
    </mat-icon> -->
    <mat-label>{{ 'global.credit' | translate }}</mat-label>
  </mat-form-field>
  <itfg-note [mode]="NoteMode.EDIT"></itfg-note>
  <div layout="row"
       class="margin-top-16px"
       layout-align="end end">
    <button mat-raised-button
            type="submit"
            color="primary">
      {{ 'global.save' | translate }}
    </button>
  </div>
</form>
