export interface Tag {
  id?: number;
  color: string;
  description: string;
  name: string;
  code?: string;
}

export interface EditTagDto extends Tag {
  id: number;
}
export enum TagTypeNames {
  CREDIT = 'CREDIT',
  CLIENT = 'CLIENT'
}
export enum TagActionNames {
  ADD = 'ADD',
  DELETE = 'DELETE'
}
