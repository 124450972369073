import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatTableModule } from '@angular/material/table';
import { DataTableComponent } from './components/data-table.component';
import { ItfgDataTableTemplateDirective } from './directives/data-table-template.directive';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSortModule } from '@angular/material/sort';
import { MatMenuModule } from '@angular/material/menu';
import { ColumnFilterCardComponent } from '@app/main/column/column-filter-card/column-filter-card.component';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { isEmptyFilterPipe } from '@app/shared/pipes/is-empty-filter.pipe';
import { TranslateFilterOptionPipe } from '@app/main/credit/pipes/translate-column-filter-options.pipe';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatButtonModule } from '@angular/material/button';
import { DataTablePipes } from './data-table-pipes.module';

@NgModule({
  declarations: [DataTableComponent, ColumnFilterCardComponent, ItfgDataTableTemplateDirective, TranslateFilterOptionPipe],
  imports: [CommonModule, MatTableModule, MatTooltipModule, MatSortModule, MatInputModule, MatMenuModule, MatSelectModule, MatListModule, MatButtonModule, MatIconModule, TranslateModule, FormsModule, DataTablePipes],
  exports: [DataTableComponent, ColumnFilterCardComponent, ItfgDataTableTemplateDirective, TranslateFilterOptionPipe],
})
export class DataTableModule { }
