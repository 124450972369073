import { Brand } from './brand';

export interface PaymentMethod {
  active?: boolean;
  brand?: Brand;
  id?: number;
  name?: string;
}

export enum PaymentMethodTypes {
  BANK = 'BANK',
  EASYPAY = 'EASYPAY',
  CASH = 'CASH',
}
