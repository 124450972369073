import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FilterValueListService, FilterValue } from '../filter/types';
import { CountryService } from '../../core/services/country.service';
import { Country } from '../../core/types/country';

@Injectable()
export class CountryValueListService implements FilterValueListService {
  constructor(private countries: CountryService) {}

  getFilterValues$(countryToFind?: string): Observable<FilterValue[]> {
    return this.countries.findCountriesStartingWith(countryToFind || '').pipe(
      map((countryList: Country[]): FilterValue[] => {
        return countryList.map(this.mapCountryToFilterValue.bind(this));
      })
    );
  }

  mapCountryToFilterValue(country: Country) {
    return {
      value: country.id,
      displayText: country.name,
    };
  }
}
