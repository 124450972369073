<div class="dialog-container">
  <div
    flex="100"
    layout="row"
    layout-align="space-between start"
    class="installment-details-header"
  >
    <span class="modal-dialog-title" [translate]="'clients.loyaltyPoints'">
      Loyalty Points flow
    </span>
    <div>
      <button tabindex="-1" mat-icon-button *ngxPermissionsOnly="['LOYALTY_POINT_WRITE']" (click)="showLoyaltyPointsForm()" matTooltip="{{ 'clients.addLoyaltyPoints' | translate }}">
        <mat-icon>add</mat-icon>
      </button>
      <button tabindex="-1" mat-icon-button (click)="onNoClick()">
        <mat-icon>clear</mat-icon>
      </button>
    </div>
  </div>
  <form *ngIf="loyaltyPointsFormIsShown" (ngSubmit)="addLoyaltyPoints()" [formGroup]="addLoyaltyPointsForm" layout-align="space-between center" layout="row">
    <mat-form-field flex="40">
      <input type="number" min="1" required matInput
      formControlName="amount" placeholder="{{ 'clients.loyaltyPointsAmount' | translate }}">
      <mat-label>{{ 'clients.loyaltyPointsAmount' | translate }}</mat-label>
      <mat-error *ngIf="addLoyaltyPointsForm?.get('amount').hasError('required')"
             [translate]="'forms.required'">This field is required
      </mat-error>
      <mat-error *ngIf="addLoyaltyPointsForm.get('amount').hasError('min')"
       [translate]="'forms.minValue'" [translateParams]="{value: addLoyaltyPointsMinAmount }">
        Min value error
      </mat-error>
    </mat-form-field>
    <mat-form-field flex="40">
      <input
        matInput
        required
        readonly="true"
        [matDatepicker]="picker"
        [min]="currentDate"
        formControlName="expiryDate"
        placeholder="{{ 'clients.loyaltyPointsЕxpiryDate' | translate }}"
        (click)="picker.open()"
        >
      <mat-label>{{ 'clients.loyaltyPointsЕxpiryDate' | translate }}</mat-label>
      <mat-datepicker-toggle 
        matSuffix
        [for]="picker"
        >
      </mat-datepicker-toggle>
      <mat-datepicker #picker>
      </mat-datepicker>
      <mat-error *ngIf="addLoyaltyPointsForm?.get('expiryDate').hasError('required')"
             [translate]="'forms.required'">This field is required
      </mat-error>
    </mat-form-field>
    <button type="submit" mat-raised-button color="primary" matTooltip="{{ 'clients.addLoyaltyPoints' | translate }}">
      {{ 'global.add' | translate }}
    </button>
  </form>
  <div flex layout-align="start stretch" layout="column">

    <mat-card appearance="outlined">
      <mat-card-header flex layout-align="space-between center" layout="row" *ngIf="loyaltyPointsFlowData && loyaltyPointsFlowData.length === 0">
        <mat-card-title [translate]="'clients.noLoyaltyPointsFlowMessage'">
          No loyalty points flow
        </mat-card-title>
      </mat-card-header>
      <mat-card-content *ngIf="loyaltyPointsFlowData && loyaltyPointsFlowData.length > 0">
        <itfg-data-table
          #dataTable
          [data]="loyaltyPointsFlowData"
          [columns]="pointsFlowColumns"
          [stickyHeader]="true"
          [containerStyle]="{ 'max-height': '350px' }"
        >
          <ng-template
            itfgDataTableTemplate="createTime"
            let-value="value"
            let-row="row"
            let-column="column"
          >
            <span [ngClass]="{'points-not-active': row.notActive}">{{ value | itfgDateFormat }}</span>
          </ng-template>
          <ng-template
            itfgDataTableTemplate="creditId"
            let-value="value"
            let-row="row"
            let-column="column"
          >

            <a [routerLink]="['/credits', value]"
               target="_blank">
              <span [ngClass]="{'points-not-active': row.notActive}">{{ value }}</span>
            </a>
          </ng-template>
          <ng-template
            itfgDataTableTemplate="installmentNumber"
            let-value="value"
            let-row="row"
            let-column="column"
          >
            <span [ngClass]="{'points-not-active': row.notActive}">{{ value }}</span>
          </ng-template>
          <ng-template
            itfgDataTableTemplate="points"
            let-value="value"
            let-row="row"
            let-column="column"
          >
            <span [ngClass]="{'points-not-active': row.notActive}">{{ value }}</span>
          </ng-template>
          <ng-template
            itfgDataTableTemplate="usedPoints"
            let-value="value"
            let-row="row"
            let-column="column"
          >
            <span [ngClass]="{'points-not-active': row.notActive}">{{ value }}</span>
          </ng-template>
          <ng-template
            itfgDataTableTemplate="received"
            let-value="value"
            let-row="row"
            let-column="column"
          >
            <mat-icon color="{{!row.received ? 'warn' : 'primary'}}">{{!row.received ? 'arrow_back' : 'arrow_forward'
              }}
            </mat-icon>
          </ng-template>
          <ng-template
            itfgDataTableTemplate="actions"
            let-row="row"
          >
            <button mat-icon-button
                    *ngIf="row.received && !row.notActive"
                    (click)="deletePoint(row.id)">
              <mat-icon>clear</mat-icon>
            </button>
          </ng-template>
        </itfg-data-table>
      </mat-card-content>
    </mat-card>
  </div>
</div>

