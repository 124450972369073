import { filter, takeUntil } from 'rxjs/operators';
import {
  Component, Inject,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { IdentifierValidation } from '../../../../core/validation/identifier-validation';
import { Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotificationService } from '../../../../core/services/notification.service';
import { ClientService } from '../../../../core/services/client.service';
import { BankAccount } from '../../../../core/types/bank-account';


@Component({
  selector: 'itfg-client-add-bank-account',
  templateUrl: './client-add-bank-account.component.html',
  styleUrls: ['./client-add-bank-account.component.scss'],
  providers: [],
})
export class ClientAddBankAccountComponent {
  bankAccountForm: UntypedFormGroup;
  userId: number;

  constructor(
    private fb: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) private data: { userId: number },
    public dialogRef: MatDialogRef<ClientAddBankAccountComponent>,
    private notifications: NotificationService,
    private clientService: ClientService,
  ) {
    this.userId = this.data.userId;
    this.bankAccountForm = this.fb.group({
      bankAccount: new UntypedFormControl('', [
        IdentifierValidation.isValidIban,
        Validators.required,
      ]),
    })
  }

  onSubmit(event: any) {
    if (!!this.bankAccountForm.valid) {
      const iban = this.bankAccountForm.controls['bankAccount'].value;
      const bankAccount: BankAccount = {
        iban,
        userDocument: null,
        verified: false,
      };
      this.clientService
          .saveClientBankAccount(this.userId, bankAccount, null)
          .subscribe(res => {
            this.notifications.showLocalizedSuccessMessage({
              notificationText: 'clients.successfullyAddedBankAccount'
            });
            this.close(res);
          });
    } else {
      this.notifications.showLocalizedErrorMessage({
        notificationText: 'forms.formSavedIsInvalid',
      });
    }
  }

  trimEmptyChars(iban: any, event: any) {
    event.preventDefault();
    const clipBoardData = event.clipboardData.getData('Text');
    iban.setValue(clipBoardData.replace(/ /g, ''));
  }

  close(res = null) {
    this.dialogRef.close(res);
  }
}