<div layout="row">
  <mat-card appearance="outlined"
            flex="50">
    <mat-card-content>
      <form novalidate
            flex
            layout="row"
            layout-wrap
            layout-align="space-between center"
            [formGroup]="calculatorForm"
            *ngIf="false">
        <div flex="100"
             layout="row"
             layout-wrap
             layout-align="space-between center">
          <mat-form-field flex="100">
            <mat-select matInput
                        formControlName="product"
                        (selectionChange)="productChanged($event)"
                        placeholder="{{ 'global.product' | translate }}">
              <mat-option *ngFor="let product of productList"
                          value="{{ product.id }}">{{ product.name }}</mat-option>
            </mat-select>
            <mat-error *ngIf="calculatorForm.get('product').hasError('required')"
                       [translate]="'forms.required'">No digit</mat-error>
          </mat-form-field>
          <div flex="100"
               layout="row"
               layout-wrap
               layout-align="space-between center">
            <div flex="60"
                 flex-sm="70"
                 flex-gt-md="70"
                 layout="row"
                 layout-align="space-between center">
              <button disabled="{{ !uniquePrincipalValues[selectedPrincipalIndex] }}"
                      type="button"
                      mat-icon-button
                      (click)="pricipalOneStepDown()">
                <mat-icon>remove</mat-icon>
              </button>
              <mat-slider flex
                          color="accent"
                          [disabled]="disableAll"
                          [max]="productPrincipalMax"
                          [min]="productPrincipalMin"
                          [step]="productPrincipalStep"
                          #ngSlider>
                <input formControlName="productPrincipal"
                       matSliderThumb
                       [value]="selectedPrincipalIndex"
                       (dragStart)="onDragStarted($event, 'principal')"
                       (dragEnd)="onDragEnded($event, 'principal')"
                       (input)="
                  productPrincipalChanges({
                    source: ngSliderThumb,
                    parent: ngSlider,
                    value: ngSliderThumb.value
                  })
                "
                       #ngSliderThumb="matSliderThumb" />
              </mat-slider>
              <button disabled="{{ !uniquePrincipalValues[selectedPrincipalIndex] }}"
                      type="button"
                      mat-icon-button
                      (click)="pricipalOneStepUp()">
                <mat-icon>add</mat-icon>
              </button>
            </div>
            <div flex>
              <span *ngIf="!uniquePrincipalValues[selectedPrincipalIndex]"></span>
              <div flex
                   layout="row"
                   layout-wrap
                   layout-align="start center"
                   *ngIf="uniquePrincipalValues[selectedPrincipalIndex]">
                {{ uniquePrincipalValues[selectedPrincipalIndex] }}&nbsp;
                <span class="to-lowercase">{{ 'global.bgn' | translate }}</span>
              </div>
            </div>
          </div>
          <div flex="100"
               layout="row"
               layout-wrap
               layout-align="space-between center">
            <div flex="60"
                 flex-sm="70"
                 flex-gt-md="70"
                 layout="row"
                 layout-align="space-between center">
              <button disabled="{{ !productPeriodsSelectedValue }}"
                      type="button"
                      mat-icon-button
                      (click)="periodsNumberOneStepDown()">
                <mat-icon>remove</mat-icon>
              </button>
              <mat-slider flex
                          [disabled]="disableAll"
                          color="accent"
                          [max]="productPeriodsNumberMax"
                          [min]="productPeriodsNumberMin"
                          [step]="productPeriodsNumberStep"
                          #ngSlider>
                <input formControlName="productPeriodsNumber"
                       matSliderThumb
                       [value]="productPeriodsSelectedValue"
                       (input)="
                  productInstallmentsChanges({
                    source: ngSliderThumb,
                    parent: ngSlider,
                    value: ngSliderThumb.value
                  })
                "
                       (dragStart)="onDragStarted($event, 'period')"
                       (dragEnd)="onDragEnded($event, 'period')"
                       #ngSliderThumb="matSliderThumb" />
              </mat-slider>
              <button disabled="{{ !productPeriodsSelectedValue }}"
                      type="button"
                      mat-icon-button
                      (click)="periodsNumberOneStepUp()">
                <mat-icon>add</mat-icon>
              </button>
            </div>
            <div flex>
              <span *ngIf="!productPeriodsSelectedValue"></span>
              <div flex
                   layout="row"
                   layout-wrap
                   layout-align="start center"
                   *ngIf="productPeriodsSelectedValue">
                <span>&nbsp;{{ productPeriodsSelectedValue }}&nbsp;</span>
                <span class="to-lowercase">{{
                  productUnitTranslation | translate
                  }}</span>
              </div>
            </div>
          </div>
        </div>
      </form>

      <!-- STAND ALONE CALCULATOR -->
      <form novalidate
            flex
            layout="row"
            layout-wrap
            layout-align="space-between center"
            [formGroup]="calculatorForm"
            *ngIf="
        creditCalculatorTemplateCode ===
          creditCalculatorTemplateCodes.CREDIT_DETAILS_CALCULATOR ||
        creditCalculatorTemplateCode ===
          creditCalculatorTemplateCodes.STAND_ALONE_CALCULATOR ||
        creditCalculatorTemplateCode ===
          creditCalculatorTemplateCodes.CLONE_CALCULATOR ||
        creditCalculatorTemplateCode ===
          creditCalculatorTemplateCodes.REFINANCE_CALCULATOR
      ">
        <div flex="100"
             layout="row"
             layout-wrap
             layout-align="space-between center">
          <mat-form-field flex="100"
                          style="margin-bottom: 15px">
            <mat-select matInput
                        formControlName="product"
                        (selectionChange)="productChanged($event)"
                        placeholder="{{ 'global.product' | translate }}">
              <mat-option *ngFor="let product of productList"
                          value="{{ product.id }}">{{ product.name }}</mat-option>
            </mat-select>
            <mat-error *ngIf="calculatorForm.get('product').hasError('required')"
                       [translate]="'forms.required'">No digit</mat-error>
          </mat-form-field>
          <div layout="row"
               layout-align="center center"
               *ngIf="isRefinance && productPlanList && !refinanceThresholdPlan">
            <p [translate]="'error.repaymentTooHighRefinance'">
              No apropriate poduct plans for this product.
            </p>
          </div>
          <div flex
               *ngIf="!isRefinance || (isRefinance && !!refinanceThresholdPlan)">
            <span *ngIf="!uniquePrincipalValues[selectedPrincipalIndex]">&nbsp;</span>
            <div flex
                 layout="row"
                 layout-wrap
                 layout-align="center"
                 *ngIf="uniquePrincipalValues[selectedPrincipalIndex]">
              <div *ngIf="!editPrincipal"
                   class="cursor-pointer"
                   (click)="editPrincipal = true">
                {{ uniquePrincipalValues[selectedPrincipalIndex] }}&nbsp;
              </div>

              <input matInput
                     #principalInputRef
                     *ngIf="editPrincipal"
                     autofocus
                     onfocus="this.select()"
                     (keydown.enter)="onPrincipalEdit(principalInputRef.value)"
                     (blur)="onPrincipalEdit(principalInputRef.value)"
                     [value]="uniquePrincipalValues[selectedPrincipalIndex]"
                     class="principal-period-input" />

              <span class="to-lowercase">{{ 'global.bgn' | translate }}</span>
            </div>
          </div>
          <div flex="100"
               layout="row"
               layout-wrap
               layout-align="space-between center"
               *ngIf="!isRefinance || (isRefinance && !!refinanceThresholdPlan)">
            <div flex
                 layout="row"
                 layout-align="space-between center">
              <button disabled="{{
                !uniquePrincipalValues[selectedPrincipalIndex] || disableAll
              }}"
                      type="button"
                      mat-icon-button
                      (click)="pricipalOneStepDown()">
                <mat-icon>remove</mat-icon>
              </button>
              <mat-slider color="accent"
                          flex
                          [disabled]="disableAll"
                          [max]="calculatorValues.max"
                          [min]="calculatorValues.min"
                          [step]="calculatorValues.step"
                          #ngSlider><input formControlName="productPrincipal"
                       matSliderThumb
                       [value]="selectedPrincipalIndex"
                       (input)="
                  productPrincipalChanges({
                    source: ngSliderThumb,
                    parent: ngSlider,
                    value: ngSliderThumb.value
                  })
                "
                       (dragStart)="onDragStarted($event, 'principal')"
                       (dragEnd)="onDragEnded($event, 'principal')"
                       #ngSliderThumb="matSliderThumb" /></mat-slider>
              <button disabled="{{
                !uniquePrincipalValues[selectedPrincipalIndex] || disableAll
              }}"
                      type="button"
                      mat-icon-button
                      (click)="pricipalOneStepUp()">
                <mat-icon>add</mat-icon>
              </button>
            </div>
          </div>
          <div flex="100"
               *ngIf="!isRefinance || (isRefinance && !!refinanceThresholdPlan)"
               layout="row"
               layout-wrap
               layout-align="space-between center">
            <div flex
                 *ngIf="productPeriodsSelectedValue">
              <div flex
                   layout="row"
                   layout-wrap
                   layout-align="center">
                <div *ngIf="!editPeriod"
                     class="cursor-pointer"
                     (click)="editPeriod = true">
                  {{ productPeriodsSelectedValue }}&nbsp;
                </div>

                <input matInput
                       #periodInputRef
                       *ngIf="editPeriod"
                       autofocus
                       onfocus="this.select()"
                       (keydown.enter)="onPeriodEdit(periodInputRef.value)"
                       (blur)="onPeriodEdit(periodInputRef.value)"
                       [value]="productPeriodsSelectedValue"
                       class="principal-period-input" />

                <span class="to-lowercase">{{
                  productUnitTranslation | translate
                  }}</span>
              </div>
            </div>
            <div flex="100"
                 layout="row"
                 layout-align="space-between center">
              <button disabled="{{ !productPeriodsSelectedValue || disableAll }}"
                      type="button"
                      mat-icon-button
                      (click)="periodsNumberOneStepDown()">
                <mat-icon>remove</mat-icon>
              </button>
              <mat-slider flex
                          [disabled]="disableAll"
                          color="accent"
                          [max]="productPeriodsNumberMax"
                          [min]="productPeriodsNumberMin"
                          [step]="productPeriodsNumberStep"
                          #ngSlider>
                <input formControlName="productPeriodsNumber"
                       matSliderThumb
                       [value]="productPeriodsSelectedValue"
                       (input)="
                  productInstallmentsChanges({
                    source: ngSliderThumb,
                    parent: ngSlider,
                    value: ngSliderThumb.value
                  })
                "
                       (dragStart)="onDragStarted($event, 'period')"
                       (dragEnd)="onDragEnded($event, 'period')"
                       #ngSliderThumb="matSliderThumb" />
              </mat-slider>
              <button disabled="{{ !productPeriodsSelectedValue || disableAll }}"
                      type="button"
                      mat-icon-button
                      (click)="periodsNumberOneStepUp()">
                <mat-icon>add</mat-icon>
              </button>
            </div>
          </div>
          <div flex="100"
               *ngIf="!isRefinance || (isRefinance && !!refinanceThresholdPlan)"
               layout="row"
               layout-wrap
               layout-align="space-between center">
            <!-- This check is added due to the fact product plan with insurance has an id of 0 -->
            <div flex
                 *ngIf="productPlan && productPlan.id !== null && productPlan.id !== undefined">
              <div flex
                   layout="row"
                   layout-wrap
                   layout-align="center">
                <div *ngIf="!editLoyaltyPoints"
                     class="cursor-pointer"
                     (click)="editLoyaltyPoints = true">
                  {{ loyaltyPointsAvailableNumber }}&nbsp;
                </div>

                <input matInput
                       #loyaltyPointsInputRef
                       *ngIf="editLoyaltyPoints"
                       autofocus
                       onfocus="this.select()"
                       (keydown.enter)="onLoyaltyPointsEdit(loyaltyPointsInputRef.value)"
                       (blur)="onLoyaltyPointsEdit(loyaltyPointsInputRef.value)"
                       [value]="loyaltyPointsAvailableNumber"
                       class="principal-period-input" />

                <span *ngIf="!disableAll"
                      class="to-lowercase">{{
                  'точки'
                  }}</span>
              </div>
            </div>
            <div flex="100"
                 layout="row"
                 layout-align="space-between center">
              <button disabled="{{ loyaltyPointsAvailableNumber == minLoyaltyPoints || disableAll }}"
                      type="button"
                      mat-icon-button
                      (click)="loyaltyPointsStepDown()">
                <mat-icon>remove</mat-icon>
              </button>
              <mat-slider flex
                          [disabled]="maxLoyaltyPoints == 0"
                          color="accent"
                          [max]="maxLoyaltyPoints"
                          [min]="minLoyaltyPoints"
                          step="1"
                          #ngSlider>
                <input formControlName="loyaltyPoints"
                       matSliderThumb
                       [value]="loyaltyPointsAvailableNumber"
                       (input)="
                  loyaltyPointsChanges({
                    source: ngSliderThumb,
                    parent: ngSlider,
                    value: ngSliderThumb.value
                  })
                "
                       (dragStart)="onDragStarted($event, 'loyaltyPoints')"
                       (dragEnd)="onDragEnded($event, 'loyaltyPoints')"
                       #ngSliderThumb="matSliderThumb" />
              </mat-slider>
              <button disabled="{{ loyaltyPointsAvailableNumber == maxLoyaltyPoints || disableAll }}"
                      type="button"
                      mat-icon-button
                      (click)="loyaltyPointsStepUp()">
                <mat-icon>add</mat-icon>
              </button>
            </div>
          </div>
        </div>

        <div *ngIf="creditCalculatorTemplateCode === creditCalculatorTemplateCodes.STAND_ALONE_CALCULATOR && productPlan && productPlan.product?.code === productTypes.INSTALLMENTS"
             flex
             layout="row">
          <button mat-icon-button
                  type="button"
                  matTooltip="{{ calculatorForm.get('insuranceEnabled')?.value ? ('plan.insuranceDisable' | translate) : ('plan.insuranceEnable' | translate) }}"
                  matTooltipPosition="above"
                  (click)="toggleInsurance()">
            <mat-icon fontSet="material-symbols-outlined"
                      [ngStyle]="{'color': calculatorForm.get('insuranceEnabled')?.value ? 'green' : 'gray'}">verified_user</mat-icon>
          </button>
          <mat-form-field class="remove-mat-form-field-wrapper">
            <input matInput
                   #birthDateInputRef
                   [readonly]="!calculatorForm.get('insuranceEnabled').value"
                   [matDatepicker]="picker"
                   autocomplete="off"
                   [max]="maxDate"
                   [formControl]="birthDateCtrl"
                   (keydown.enter)="$event.preventDefault(); setProductPlanWithInsurance()"
                   [disabled]="!calculatorForm.get('insuranceEnabled').value"
                   placeholder="{{ 'plan.birthDate' | translate }}">
            <mat-label>{{ 'plan.birthDate' | translate }}</mat-label>
            <mat-hint>дд/мм/ГГГГ</mat-hint>
            <div matSuffix
                 layout="row"
                 layout-align="center center">
              <button mat-icon-button
                      matTooltip="{{ 'products.findInsurancePlan' | translate }}"
                      matTooltipPosition="above"
                      [disabled]="!calculatorForm.get('insuranceEnabled').value || !birthDateCtrl.value"
                      (click)="setProductPlanWithInsurance()">
                <mat-icon>policy</mat-icon>
              </button>
              <itfg-form-control-clear [disabled]="!calculatorForm.get('insuranceEnabled').value || !birthDateCtrl.value"
                                       matSuffix
                                       [control]="birthDateCtrl"></itfg-form-control-clear>
            </div>
          </mat-form-field>
        </div>
      </form>

      <form novalidate
            [formGroup]="calculatorForm"
            *ngIf="false">
        <!-- TODO Implement it!!!! -->

        <div flex="100"
             layout="row"
             layout-wrap
             layout-align="space-between center">
          <mat-form-field flex="100">
            <mat-select matInput
                        formControlName="product"
                        (selectionChange)="productChanged($event)"
                        placeholder="{{ 'global.product' | translate }}">
              <mat-option *ngFor="let product of productList"
                          [disabled]="!uniquePrincipalValues[selectedPrincipalIndex]"
                          value="{{ product.id }}">{{ product.name }}</mat-option>
            </mat-select>
            <mat-error *ngIf="calculatorForm.get('product').hasError('required')"
                       [translate]="'forms.required'">No digit</mat-error>
          </mat-form-field>
          <div flex="100"
               layout="row"
               layout-wrap
               layout-align="space-between center">
            <div flex="60"
                 flex-sm="70"
                 flex-gt-md="70"
                 layout="row"
                 layout-align="space-between center">
              <button disabled="{{ !uniquePrincipalValues[selectedPrincipalIndex] }}"
                      type="button"
                      mat-icon-button
                      (click)="pricipalOneStepDown()">
                <mat-icon>remove</mat-icon>
              </button>
              <!-- <mat-form-field> -->
              <mat-slider formControlName="productPrincipal"
                          color="accent"
                          flex
                          [disabled]="disableAll"
                          [max]="productPrincipalMax"
                          [min]="productPrincipalMin"
                          [step]="productPrincipalStep"
                          #ngSlider><input matSliderThumb
                       [value]="selectedPrincipalIndex"
                       (input)="
                  productPrincipalChanges({
                    source: ngSliderThumb,
                    parent: ngSlider,
                    value: ngSliderThumb.value
                  })
                "
                       #ngSliderThumb="matSliderThumb" /></mat-slider>
              <!-- </mat-form-field> -->
              <button disabled="{{ !uniquePrincipalValues[selectedPrincipalIndex] }}"
                      type="button"
                      mat-icon-button
                      (click)="pricipalOneStepUp()">
                <mat-icon>add</mat-icon>
              </button>
            </div>
            <div flex>
              <span *ngIf="!uniquePrincipalValues[selectedPrincipalIndex]">&nbsp;</span>
              <div flex
                   layout="row"
                   layout-wrap
                   layout-align="start center"
                   *ngIf="uniquePrincipalValues[selectedPrincipalIndex]">
                {{ uniquePrincipalValues[selectedPrincipalIndex] }}&nbsp;
                <span class="to-lowercase">{{ 'global.bgn' | translate }}</span>
              </div>
            </div>
          </div>
          <div flex="100"
               layout="row"
               layout-wrap
               layout-align="space-between center">
            <div flex="60"
                 flex-sm="70"
                 flex-gt-md="70"
                 layout="row"
                 layout-align="space-between center">
              <button disabled="{{ !productPeriodsSelectedValue }}"
                      type="button"
                      mat-icon-button
                      (click)="periodsNumberOneStepDown()">
                <mat-icon>remove</mat-icon>
              </button>
              <mat-slider flex
                          color="accent"
                          [disabled]="disableAll"
                          formControlName="productPeriodsNumber"
                          [max]="productPeriodsNumberMax"
                          [min]="productPeriodsNumberMin"
                          [step]="productPeriodsNumberStep"
                          #ngSlider>
                <input matSliderThumb
                       (input)="
                  productInstallmentsChanges({
                    source: ngSliderThumb,
                    parent: ngSlider,
                    value: ngSliderThumb.value
                  })
                "
                       (dragStart)="onDragStarted($event, 'period')"
                       (dragEnd)="onDragEnded($event, 'period')"
                       #ngSliderThumb="matSliderThumb" />
              </mat-slider>
              <button disabled="{{ !productPeriodsSelectedValue }}"
                      type="button"
                      mat-icon-button
                      (click)="periodsNumberOneStepUp()">
                <mat-icon>add</mat-icon>
              </button>
            </div>
            <div flex>
              <span *ngIf="!productPeriodsSelectedValue">&nbsp;</span>
              <div flex
                   layout="row"
                   layout-wrap
                   layout-align="start center"
                   *ngIf="productPeriodsSelectedValue">
                <span>&nbsp;{{ productPeriodsSelectedValue }}&nbsp;</span>
                <span class="to-lowercase">{{
                  productUnitTranslation | translate
                  }}</span>
              </div>
            </div>
          </div>
        </div>
        <hr flex="100"
            class="flex-margin-right" />
        <div flex="100"
             layout="row"
             layout-wrap
             layout-align="center center">
          <div flex="35"
               layout="row"
               layout-align="start center">
            {{ installmentUnitTranslation | translate }}:&nbsp;
            <span *ngIf="!productPlan?.installmentAmount">--</span>
            <span *ngIf="productPlan?.installmentAmount">{{ productPlan?.installmentsNumber }} &#215;
              {{ productPlan?.installmentAmount | number: '1.2-2' }}&nbsp;</span>
          </div>
          <div flex="40"
               layout="row"
               layout-align="start center">
            <div>
              {{ 'global.returnAmount' | translate }}:&nbsp;
              <span *ngIf="!productPlan?.totalReturnAmount">--</span>
              <span *ngIf="productPlan?.totalReturnAmount">{{
                productPlan?.totalReturnAmount | number: '1.2-2'
                }}</span>
            </div>
          </div>
          <div flex="25"
               layout="row"
               layout-align="start center">
            <div>
              {{ 'plan.apr' | translate }}:&nbsp;
              <span *ngIf="!productPlan?.product?.apr">--</span>
              <span *ngIf="productPlan?.product?.apr">{{
                productPlan?.product.apr | number: '1.2-2'
                }}</span>
            </div>
          </div>
        </div>
      </form>
    </mat-card-content>
  </mat-card>

  <mat-card appearance="outlined"
            *ngIf="
      creditCalculatorTemplateCode ===
        creditCalculatorTemplateCodes.STAND_ALONE_CALCULATOR ||
      creditCalculatorTemplateCode ===
        creditCalculatorTemplateCodes.CREDIT_DETAILS_CALCULATOR ||
      creditCalculatorTemplateCode ===
        creditCalculatorTemplateCodes.REFINANCE_CALCULATOR ||
      creditCalculatorTemplateCode ===
        creditCalculatorTemplateCodes.CLONE_CALCULATOR
    "
            flex="50">
    <mat-card-content>
      <!-- <mat-list>
        <mat-list-item>
          {{ 'plan.installmentWithoutForfeit' | translate }}
        </mat-list-item>
      </mat-list> -->
      <mat-list>
        <mat-list-item>
          <div layout="row"
               layout-align="space-between center">
            <div class="td label-cell mat-column-item">
              {{ 'plan.installmentWithoutForfeit' | translate }}
            </div>
            <div class="td value-cell mat-column-cost">
              <span *ngIf="!isDisabled && !isFetchingInsurance"
                    matTooltip="{{ calculatorForm.get('insuranceEnabled')?.value ? ('plan.installmentPlusFeeWithoutForfeitAndWithInsuranceTooltipText' | translate) : ('plan.installmentPlusFeeWithoutForfeitTooltipText' | translate) }}"
                    matTooltipPosition="above">
                <span matTooltip="{{ 'plan.installmentPlusFeeWithoutForfeitTooltipFormula' | translate : {
                      installmentAmount: productPlan?.installmentAmount | toCustomItfgCurrency,
                      installmentUtilizationFee: productPlan?.installmentUtilizationFee | toCustomItfgCurrency
                  } }}"
                      matTooltipPosition="below">
                  {{ productPlan?.installmentAmount + productPlan?.installmentUtilizationFee | toCustomItfgCurrency }}
                </span>
              </span>
              <div *ngIf="!isDisabled && isFetchingInsurance"
                   class="pulse-loader">
                <div></div>
                <div></div>
                <div></div>
              </div>
              <span *ngIf="isDisabled">
                {{ '-' }}
              </span>
            </div>
          </div>
        </mat-list-item>
        <mat-list-item>
          <div layout="row"
               layout-align="space-between center">
            <div class="td label-cell mat-column-item">
              {{ 'plan.installmentPlusForfeit' | translate }}
            </div>
            <div class="td value-cell mat-column-cost">
              <span *ngIf="!isDisabled && !isFetchingInsurance"
                    matTooltip="{{ calculatorForm.get('insuranceEnabled')?.value ? ('plan.installmentPlusForfeitAndWithInsuranceTooltipText' | translate) :
                  ('plan.installmentPlusForfeitTooltipText' | translate)
                }}"
                    matTooltipPosition="above">
                <span matTooltip="{{
                    'plan.installmentPlusForfeitTooltipFormula'
                      | translate
                        : {
                            installmentAmount:
                              productPlan?.installmentAmount
                              | toCustomItfgCurrency,
                            installmentForfeit:
                              productPlan?.installmentForfeit
                              | toCustomItfgCurrency,
                            installmentUtilizationFee:
                              productPlan?.installmentUtilizationFee
                              | toCustomItfgCurrency,
                              loyaltyPoints:
                              (loyaltyPointsAvailableNumber / productPlan.installmentsNumber)
                              | toCustomItfgCurrency
                          }
                  }}"
                      matTooltipPosition="below">
                  {{
                  productPlan?.installmentAmount +
                  productPlan?.installmentForfeit +
                  productPlan?.installmentUtilizationFee -
                  loyaltyPointsAvailableNumber / productPlan?.installmentsNumber
                  | toCustomItfgCurrency
                  }}
                </span>
              </span>

              <div *ngIf="!isDisabled && isFetchingInsurance"
                   class="pulse-loader">
                <div></div>
                <div></div>
                <div></div>
              </div>

              <span *ngIf="isDisabled">
                {{ '-' }}
              </span>
            </div>
          </div>
        </mat-list-item>

        <mat-list-item>
          <div layout="row"
               layout-align="space-between center">
            <div class="td label-cell mat-column-item">
              {{ 'plan.totalReturnAmountWithoutForifeit' | translate }}
            </div>
            <div class="td value-cell mat-column-cost">
              <span *ngIf="!isDisabled && !isFetchingInsurance"
                    matTooltip="{{ calculatorForm.get('insuranceEnabled')?.value ? ('plan.installmentTotalWithoutForfeitAndWithInsuranceTooltipText' | translate) : ('plan.installmentTotalWithoutForfeitTooltipText' | translate) }}"
                    matTooltipPosition="above">
                <span matTooltip="{{ 'plan.installmentTotalWithoutForfeitTooltipFormula' | translate: {
                    totalReturnAmount: (productPlan?.totalReturnAmount | toCustomItfgCurrency),
                    totalUtilizationFee: ((productPlan?.installmentUtilizationFee * productPlan?.installmentsNumber) | toCustomItfgCurrency)
                  } }}"
                      matTooltipPosition="below">
                  {{(productPlan?.totalReturnAmount + productPlan?.installmentUtilizationFee *
                  productPlan?.installmentsNumber) | toCustomItfgCurrency }}
                </span>
              </span>

              <div *ngIf="!isDisabled && isFetchingInsurance"
                   class="pulse-loader">
                <div></div>
                <div></div>
                <div></div>
              </div>

              <span *ngIf="isDisabled">
                {{ '-' }}
              </span>
            </div>
          </div>
        </mat-list-item>
        <mat-list-item>
          <div layout="row"
               layout-align="space-between center">
            <div class="td label-cell mat-column-item">
              {{ 'plan.totalReturnAmountWithForfeit' | translate }}
            </div>
            <div class="td value-cell mat-column-cost">
              <span *ngIf="!isDisabled && !isFetchingInsurance"
                    matTooltip="{{ calculatorForm.get('insuranceEnabled')?.value ? ('plan.installmentTotalWithForfeitAndWithInsuranceTooltipText' | translate) : ('plan.installmentTotalWithForfeitTooltipText' | translate) }}"
                    matTooltipPosition="above">
                <span matTooltip="{{ 'plan.installmentTotalWithForfeitTooltipFormula' | translate: {
                    totalReturnAmount: (productPlan?.totalReturnAmount | toCustomItfgCurrency),
                    totalForfeit: ((productPlan?.installmentForfeit * productPlan?.installmentsNumber) | toCustomItfgCurrency),
                    totalUtilizationFee: ((productPlan?.installmentUtilizationFee * productPlan?.installmentsNumber) | toCustomItfgCurrency),
                    loyaltyPoints: (loyaltyPointsAvailableNumber | toCustomItfgCurrency)
                  } }}">
                  {{ productPlan.totalReturnAmountWithForfeit | toCustomItfgCurrency }}
                </span>
              </span>

              <div *ngIf="!isDisabled && isFetchingInsurance"
                   class="pulse-loader">
                <div></div>
                <div></div>
                <div></div>
              </div>

              <span *ngIf="isDisabled">
                {{'-'}}
              </span>
            </div>
          </div>
        </mat-list-item>

        <mat-list-item>
          <div layout="row"
               layout-align="space-between center">
            <div class="td label-cell mat-column-item">
              <span>
                {{ 'plan.installmentForfeit' | translate }}
              </span>
            </div>
            <div class="td value-cell mat-column-cost">
              <span *ngIf="!isDisabled && !isFetchingInsurance"
                    matTooltip="{{ calculatorForm.get('insuranceEnabled')?.value ? ('plan.installmentForfeitWithInsuranceTooltipText' | translate) : ('plan.installmentForfeitTooltipText' | translate) }}"
                    matTooltipPosition="above">
                <span matTooltip="{{ 'plan.installmentForfeitTooltipFormula' | translate: {
                    installmentForfeit: (productPlan?.installmentForfeit | toCustomItfgCurrency),
                    loyaltyPoints: ((loyaltyPointsAvailableNumber / productPlan.installmentsNumber) | toCustomItfgCurrency)}
                   }}">
                  {{ productPlan?.installmentForfeit - loyaltyPointsAvailableNumber / productPlan.installmentsNumber |
                  toCustomItfgCurrency }}
                </span>
              </span>

              <div *ngIf="!isDisabled && isFetchingInsurance"
                   class="pulse-loader">
                <div></div>
                <div></div>
                <div></div>
              </div>

              <span *ngIf="isDisabled">
                {{'-'}}
              </span>
            </div>
          </div>
        </mat-list-item>

        <mat-list-item
                       *ngIf="productPlan && productPlan.product?.code === productTypes.INSTALLMENTS && calculatorForm.get('insuranceEnabled')?.value && insuranceAmount">
          <div layout="row"
               layout-align="space-between center">
            <div class="td label-cell mat-column-item">
              <span>
                {{ 'credits.insurance' | translate }}
              </span>
            </div>
            <div class="td value-cell mat-column-cost">
              <span *ngIf="!isDisabled && !isFetchingInsurance"
                    matTooltip="{{ 'plan.insuranceAmount' | translate: { insuranceAmount: insuranceAmount } }}"
                    matTooltipPosition="above">
                <span>
                  {{ insuranceAmount | toCustomItfgCurrency }}
                </span>
              </span>

              <div *ngIf="!isDisabled && isFetchingInsurance"
                   class="pulse-loader">
                <div></div>
                <div></div>
                <div></div>
              </div>

              <span *ngIf="isDisabled">
                {{'-'}}
              </span>
            </div>
          </div>
        </mat-list-item>

      </mat-list>
    </mat-card-content>
  </mat-card>
</div>
