<div flex
     layout-align="space-between start"
     layout="row">
  <div class="modal-dialog-title"
       flex>
    {{ 'historyLog.addPaymentPromise' | translate }}
  </div>
  <button tabindex="-1"
          mat-icon-button
          (click)="onNoClick()">
    <mat-icon>clear</mat-icon>
  </button>
</div>

<form [formGroup]="paymentsForm"
      layout="column"
      novalidate
      (ngSubmit)="onSave($event)">
  <div layout="row"
       layout-wrap
       layout-align="space-between center">
    <mat-form-field class="flex-padding">
      <mat-label>{{ 'communication.agreedUponPaymentAmount' | translate }}</mat-label>
      <input type="number"
             matInput
             formControlName="agreedUponPaymentAmount">
      <mat-error *ngIf="paymentsForm.get('agreedUponPaymentAmount').hasError('min')"
                 [translate]="'forms.minValue'"
                 [translateParams]="{value: '0.01'}">Min value error
      </mat-error>
      <mat-hint [ngStyle]="{color: 'red' }"
                *ngIf="paymentsForm.hasError('paymentAmountRequiredForPaymentDate')"
                [translate]="'forms.paymentDateRequiresPaymentAmount'">Payment amount requires payment date
      </mat-hint>
    </mat-form-field>
    <mat-form-field class="flex-padding agreedUponPaymentDateField">
      <mat-label>{{ 'communication.agreedUponPaymentDate' | translate }}</mat-label>
      <input matInput
             formControlName="agreedUponPaymentDate"
             [errorStateMatcher]="dateMatcher"
             [matDatepicker]="agreedUponPaymentDate"
             [min]="datepickerStartDate">
      <itfg-form-control-clear matSuffix
                               [control]="paymentsForm.get('agreedUponPaymentDate')"></itfg-form-control-clear>
      <mat-datepicker #agreedUponPaymentDate></mat-datepicker>
      <mat-datepicker-toggle matSuffix
                             [for]="agreedUponPaymentDate"></mat-datepicker-toggle>
      <mat-error *ngIf="paymentsForm.get('agreedUponPaymentDate').value < datepickerStartDate"
                 [translate]="'forms.minValue'"
                 [translateParams]="{value: datepickerStartDate | itfgDateFormat: dateFormat }">
        Min value error
      </mat-error>
      <mat-error *ngIf="paymentsForm.get('agreedUponPaymentDate').errors?.required"
                 [translate]="'forms.required'">
        Required field
      </mat-error>
      <mat-hint [align]="'start'">дд/мм/ГГГГ</mat-hint>
      <mat-hint [align]="'end'"
                [ngStyle]="{color: 'red' }"
                *ngIf="paymentsForm.hasError('paymentDateRequiredForPaymentAmount')"
                [translate]="'forms.paymentAmountRequiresPaymentDate'">Payment date requires payment amount
      </mat-hint>
    </mat-form-field>
  </div>
  <div class="actions"
       layout="row"
       flex
       layout-align="end">
    <button itfgPreventDoubleClick
            mat-raised-button
            (throttledClick)="onSave($event)"
            [throttleTime]="500"
            type="submit">
      {{ 'global.save' | translate }}
    </button>
  </div>

</form>
