import { Injectable } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';

@Injectable()
export class MatPaginatorIntlLocalizedService extends MatPaginatorIntl {
  itemsPerPageLabel: string = 'Брой редове на страница: ';
  nextPageLabel: string = 'Следваща страница';
  previousPageLabel: string = 'Предишна страница';
  firstPageLabel: string = 'Начална страница';
  lastPageLabel: string = 'Последна страница';

  getRangeLabel = (page: number, pageSize: number, length: number) => {
      return ((page * pageSize) + 1) + ' - ' + ((page * pageSize) + pageSize) + ' от ' + length;
  }
}
