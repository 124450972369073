import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IConfirmConfig } from '@app/core/types/dialog.type';

@Component({
  selector: 'mfa-instructions-dialog',
  templateUrl: './mfa-instructions-dialog.component.html',
  styleUrls: ['./mfa-instructions-dialog.component.scss'],
})
export class MfaInstructionsDialog implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<MfaInstructionsDialog>,
  ) {
  }

  ngOnInit() {}
}
