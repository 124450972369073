import { Injectable } from '@angular/core';
import { RequestService } from './request.service';
import { Observable } from 'rxjs';
import { ProductPlanEntry } from '../types/product-plan-entry';
import { Page } from '../types/page';
import { Credit } from '../types/credit';
import { HttpHeaders } from '@angular/common/http';

@Injectable()
export class ProductPlanService {
  request: RequestService;

  constructor(request: RequestService) {
    this.request = request;
  }

  getProductPlanById(id: number | string): Observable<any> {
    return this.request.get(['product-plans', id]);
  }

  getProductPlanList(options?: any): Observable<Page<ProductPlanEntry>> {
    return this.request.get(['product-plans'], { params: options });
  }

  getProductPlanForProduct(id: number | string): Observable<any> {
    return this.request.get(['product-plans', 'product', id]);
  }

  generateLocalProductPlan(product): Observable<any> {
    const jsonProduct = JSON.stringify(product);
    return this.request.get(['product-plans', 'product'], {
      params: { product: jsonProduct },
    });
  }

  regenerateProductPlanForProduct(id: number | string): Observable<any> {
    return this.request.post(['product-plans', 'product', id]);
  }

  uploadProductPlan({ productId, validFrom, file }: {
    productId: number | string,
    validFrom: any,
    file: any
  }): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'multipart/form-data');
    const query =  '?validFrom=' + validFrom;

    return this.request.post(['product-plans', 'product', productId, query], {
      body: file,
    });
  }

  deleteProductPlanForProduct(id: number | string): Observable<any> {
    return this.request.delete(['product-plans', id]);
  }

  findMatchingProductPlan(
    credit: Credit,
    productPlanList: ProductPlanEntry[]
  ): ProductPlanEntry {
    const matcherFunc = (product: ProductPlanEntry): boolean => {
      const principalMatch = product.principal === credit.principal;
      if (credit.product.productPeriod.unit === 'MONTH') {
        return (
          principalMatch &&
          product.installmentsNumber === credit.installmentsNumber
        );
      } else {
        return (
          principalMatch && product.installmentDays === credit.installmentDays
        );
      }
    };

    const matchingPlan: ProductPlanEntry = productPlanList.find(matcherFunc);
    return matchingPlan;
  }

  findClosestMatchingPlanByPrincipal(
    principal: number,
    productPlanList: ProductPlanEntry[]
  ) {
    const principalReducer = (a: ProductPlanEntry, b: ProductPlanEntry) => {
      return Math.abs(a.principal - principal) <
        Math.abs(b.principal - principal)
        ? a
        : b;
    };
    const closestPrincipal: ProductPlanEntry = productPlanList.reduce(
      principalReducer
    );
    return closestPrincipal;
  }

  findExactMatchingPlanByPrincipalAndPeriod(
    principal: number,
    period: number,
    productPlanList: ProductPlanEntry[]
  ) {
    const matcherFunc = (product: ProductPlanEntry): boolean => {
      const principalMatch = product.principal === principal;
      if (product.installmentsNumber === 1) {
        return (
          principalMatch &&
          period === product.installmentDays
        );
      } else {
        return (
          principalMatch && 
          period === product.installmentsNumber 
        );
      }
    };

    const matchingPlan: ProductPlanEntry = productPlanList.find(matcherFunc);
    return matchingPlan;
  }

  findClosestMatchingPlanByPrincipalAndPeriod(
    principal: number,
    period: number,
    productPlanList: ProductPlanEntry[]
  ) {
    const principalAndPeriodReducer = (a: ProductPlanEntry, b: ProductPlanEntry) => {
      const closestPrincipalDiff = Math.abs(a.principal - principal);
      const closestPeriodDiff = a.installmentsNumber > 1 ? Math.abs(a.installmentsNumber - period) : Math.abs(a.installmentDays - period);
  
      const currentPrincipalDiff = Math.abs(b.principal - principal);
      const currentPeriodDiff = b.installmentsNumber > 1 ? Math.abs(b.installmentsNumber - period) : Math.abs(b.installmentDays - period);
  
      const closestTotalDiff = closestPrincipalDiff + closestPeriodDiff;
      const currentTotalDiff = currentPrincipalDiff + currentPeriodDiff;
  
      return currentTotalDiff < closestTotalDiff ? b : a;
    };
  
    const closestMatchingPlan: ProductPlanEntry = productPlanList.reduce(
      principalAndPeriodReducer
    );

    return closestMatchingPlan;
  }

  findClosestMatchingProductPlan(
    credit: Credit,
    productPlanList: ProductPlanEntry[]
  ): ProductPlanEntry {
    const closestMatchReducer = (a: ProductPlanEntry, b: ProductPlanEntry) => {
      if (credit.product.productPeriod.unit === 'MONTH') {
        return Math.abs(credit.installmentsNumber - a.installmentsNumber) <
          Math.abs(credit.installmentsNumber - b.installmentsNumber)
          ? a
          : b;
      } else {
        return Math.abs(credit.installmentDays - a.installmentDays) <
          Math.abs(credit.installmentDays - b.installmentDays)
          ? a
          : b;
      }
    };
    const closestPrincipalReducer = (a: number, b: number) => {
      return Math.abs(a - credit.principal) < Math.abs(b - credit.principal)
        ? a
        : b;
    };

    const closestPrincipal: number = productPlanList
      .map(p => p.principal)
      .reduce(closestPrincipalReducer);
    const closestMatchingPeriod = productPlanList
      .filter(p => p.principal === closestPrincipal)
      .reduce(closestMatchReducer);
    return closestMatchingPeriod;
  }
}
