import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { MainRoutingModule } from './main-routing.module';
import { HomeComponent } from './home/home.component';
import { MainComponent } from './main.component';
import { SharedModule } from '../shared/shared.module';
import { MaterialModule } from '../lib/material.module';
import { BreadcrumbsComponent } from './breadcrumbs/breadcrumbs.component';
import { BrandValueListService } from './services/brand-value-list.service';
import { ZoneValueListService } from './services/zone-value-list.service';
import { CityValueListService } from './services/city-value-list.service';
import { BlacklistTypeValueListService } from './services/blacklist-type-value-list.service';
import { ClientStatusValueListService } from './services/client-status-value-list.service';
import { CountryValueListService } from './services/country-value-list.service';
import { OfficeValueListService } from './services/office-value-list.service';
import { TranslateModule } from '@ngx-translate/core';
import { ProductValueListService } from './services/credit-value-list.service';
import { CreditStatusValueListService } from './services/credit-status-value-list.service';
import { AdministratorValueListService } from './services/administrator-value-list.service';
import { PaymentValueListService } from './services/payment-value-list.service';
import { AccountOwnerTypeValueListService } from './services/account-owner-type-value-list.service';
import { ToCustomItfgCurrencyPipe } from './credit/pipes/to-custom-itfg-currency.pipe';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BucketAdministratorValueListService } from './services/bucket-administrator-value-list.service';
import { MainLayoutModule } from './main-layout/main-layout.module';
import { CreditStatusReasonValueListService } from './services/credit-status-reason-value-list.service';
import { FormatIbanPipe } from './credit/pipes/format-iban.pipe';
import { EditAuditDisplayTextPipe } from './client/pipes/edit-audit-display-text.pipe';
import { AdministratorEmailToDisplayTextPipe } from './client/pipes/administrator-email-to-display-text.pipe';
import { ColumnCardComponent } from './column/column-card/column-card.component';
import { ListBaseComponent } from './list-base/list-base.component';
import { TransactionStateValueListService } from './services/transaction-state-value-list.service';
import { TransactionTypeValueListService } from './services/transaction-type-value-list.service';
import { CollectorAdministratorValueListService } from './services/collector-administrator-value-list.service';
import { ItfgDateFormatPipe } from '../shared/pipes/itfg-date-format.pipe';
import { ToCustomItfgAmountPipe } from './identity-reports/pipes/to-custom-itfg-amount.pipe';
import { ApplicationStatusValueListService } from './services/application-status-value-list.service';
import { GcProductValueList } from './services/gc-product-value-list.service';
import { AdministratorProfileComponent } from './administration/administrators/administrator-profile/administrator-profile.component';
import { PaymentBankAccountValueListService } from './services/payment-bank-account-value-list.service';
import { IbanCodePipe } from './credit/pipes/to-iban-code.pipe';
import { ApplicationSourceValueList } from './services/application-source-value-list.service';
import { CreditStatusNameValueListService } from './services/credit-status-name-value-list.service';
import { CallStatusValueListService } from './services/call-status-value-list.service';
import { CallDirectionValueListService } from './services/call-direction-value-list.service';
import { AgentValueListService } from './services/agent-value-list.service';
import { TopicsValueListService } from './services/topics-value-list.service';
import { DataTableModule } from '@app/core/components/data-table/data-table.module';

@NgModule({
  imports: [
    CommonModule,
    MainRoutingModule,
    MaterialModule,
    SharedModule.forRoot(),
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    MainLayoutModule,
    DataTableModule,
  ],
  declarations: [
    HomeComponent,
    MainComponent,
    BreadcrumbsComponent,
    ListBaseComponent,
    AdministratorProfileComponent,
  ],
  exports: [MainRoutingModule],
})
export class MainModule {
  static forChild(): ModuleWithProviders<MainModule> {
    return {
      ngModule: MainModule,
    };
  }

  static forRoot(): ModuleWithProviders<MainModule> {
    return {
      ngModule: MainModule,
      providers: [
        BlacklistTypeValueListService,
        BrandValueListService,
        ZoneValueListService,
        CityValueListService,
        ClientStatusValueListService,
        CountryValueListService,
        ProductValueListService,
        OfficeValueListService,
        CreditStatusValueListService,
        CreditStatusReasonValueListService,
        AdministratorValueListService,
        BucketAdministratorValueListService,
        PaymentValueListService,
        PaymentBankAccountValueListService,
        AccountOwnerTypeValueListService,
        ToCustomItfgCurrencyPipe,
        ToCustomItfgAmountPipe,
        FormatIbanPipe,
        EditAuditDisplayTextPipe,
        AdministratorEmailToDisplayTextPipe,
        DatePipe,
        TransactionStateValueListService,
        TransactionTypeValueListService,
        CollectorAdministratorValueListService,
        ItfgDateFormatPipe,
        ApplicationStatusValueListService,
        GcProductValueList,
        IbanCodePipe,
        ApplicationSourceValueList,
        CreditStatusNameValueListService,
        CallStatusValueListService,
        CallDirectionValueListService,
        AgentValueListService,
        TopicsValueListService,
      ],
    };
  }

  constructor() {}
}
