<div flex layout-align="space-between center" layout="row">
  <div
    class="modal-dialog-title"
    flex
  >
    {{ 'ccrRequests.uploadCcrRequestsReport' | translate }}
  </div>
  <button tabindex="-1" mat-icon-button (click)="onNoClick()">
    <mat-icon>clear</mat-icon>
  </button>
</div>
<form (ngSubmit)="onSubmit($event)" [formGroup]="requestsForm" flex
      layout="column"
      layout-wrap
      layout-align="space-between stretch">
  <div flex="100">
    <mat-form-field class="full-width">
    <textarea
      matInput
      formControlName="reportText"
      placeholder="Последните X дни: X проверки от X банки и X проверки от X ФИ"
      cdkTextareaAutosize
      cdkAutosizeMinRows="15"
      cdkAutosizeMaxRows="50"
    ></textarea>
    </mat-form-field>
  </div>

  <itfg-ccr-requests *ngIf="requests?.rows.length > 0"
                     [report]="requests">

  </itfg-ccr-requests>
  <div flex layout="row" layout-align="end end">
    <button
      mat-raised-button
      button="submit"
      [disabled]="(['CCR_ADD'] | isUnauthorized | async) || !isValidatedByRegex"
      matTooltip="{{ 'global.add' | translate }}"
    >
      {{ 'global.add' | translate }}
    </button>
  </div>
</form>
