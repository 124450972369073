<div class="itfg-expansion-list">
  <mat-expansion-panel id="expansion-panel"
                       (opened)="onExpansionListOpened()"
                       (closed)="onExpansionListClosed()"
                       [disabled]="eventLogStateService.isFetchingCredits">
    <mat-expansion-panel-header>
      <ng-container *ngIf="eventLogStateService.isFetchingCredits">
        <div flex
             layout="row"
             layout-align="start center">
          <div class="header-icon-box grey">
            <mat-icon fontSet="material-symbols-outlined"
                      class="spinner">progress_activity</mat-icon>
          </div>
          <mat-list-item class="header-text pointer-no-select">
            {{ 'pbx.creditsLoading' | translate }}
          </mat-list-item>
        </div>
      </ng-container>

      <ng-container *ngIf="!eventLogStateService.isFetchingCredits && creditContextArr?.length > 0 && selectedCredit">
        <div flex
             layout="row"
             layout-align="start center">
          <div class="header-credit-status-icon-box">
            <itfg-credit-status-icon [creditStatus]="selectedCredit.creditStatus"></itfg-credit-status-icon>
          </div>
          <span class="pointer-no-select">{{
            selectedCredit.user.fullName
            ? selectedCredit.user.firstName + ' ' + selectedCredit.user.lastName
            : 'communication.missingName' | translate
            }}</span>
          <span class="client-info">
            <mat-icon fontSet="material-symbols-outlined"
                      class="credit-icon caller-credit-relation-icon fill"
                      [matTooltip]="phoneNumber === hiddenNumber || phoneNumber === inquiryPhoneNumber|| selectedCredit?.isUnrelated ? ('global.unrelated' | translate) :
                      (selectedCredit?.user?.mobile === formattedPhoneNumber ? 
                      ('global.client' | translate) : 
                      ('global.contact' | translate))"
                      matTooltipPosition="above">
              {{ phoneNumber === hiddenNumber || phoneNumber === inquiryPhoneNumber || selectedCredit?.isUnrelated ? 'question_mark' :
              (selectedCredit?.user?.mobile ===
              formattedPhoneNumber ? 'person' : 'people') }}
            </mat-icon>
          </span>
          <span class="header-credit-id pointer-no-select">
            {{ selectedCredit?.id }}
          </span>
        </div>
      </ng-container>

      <ng-container *ngIf="!eventLogStateService.isFetchingCredits && creditContextArr?.length > 0 && !selectedCredit">
        <div flex
             layout="row"
             layout-align="start center">
          <div class="def-header-add-icon grey">
            <mat-icon>add_circle_outline</mat-icon>
          </div>
          <mat-list-item class="def-header-text pointer-no-select">
            {{ 'pbx.actions.chooseCreditContext' | translate }}
          </mat-list-item>
        </div>
      </ng-container>

      <ng-container *ngIf="!eventLogStateService.isFetchingCredits && !creditContextArr && !selectedCredit">
        <div flex
             layout="row"
             layout-align="start center">
          <div class="def-header-add-icon grey">
            <mat-icon>add_circle_outline</mat-icon>
          </div>
          <mat-list-item class="def-header-text pointer-no-select">
            Изтегли кредити
          </mat-list-item>
        </div>
      </ng-container>

      <ng-container *ngIf="!eventLogStateService.isFetchingCredits && creditContextArr?.length === 0">
        <div flex
             layout="row"
             layout-align="start center">
          <div class="def-header-add-icon grey">
            <mat-icon>credit_card_off</mat-icon>
          </div>
          <mat-list-item class="def-header-text pointer-no-select">
            {{ 'buckets.credits.noCreditsFound' | translate}}
          </mat-list-item>
        </div>
      </ng-container>
    </mat-expansion-panel-header>

    <div *ngIf="eventLogStateService.isFetchingCredits"
         class="progress-spinner-container">
      <mat-spinner class="progress-spinner"
                   strokeWidth="10"
                   [diameter]="150"></mat-spinner>
    </div>

    <mat-selection-list *ngIf="!eventLogStateService.isFetchingCredits"
                        [multiple]="false"
                        hideSingleSelectionIndicator>
      <div layout="row"
           layout-align="center center">
        <mat-form-field flex>
          <input #noteInput
                 matInput
                 placeholder="{{ 'pbx.actions.searchForCredit' | translate }}"
                 [formControl]="searchControl"
                 (keyup)="$event.preventDefault(); $event.stopPropagation()"
                 (keydown)="$event.stopPropagation()" />
          <mat-icon fontSet="material-symbols-outlined"
                    matIconPrefix
                    matTooltip="{{ 'pbx.actions.searchCreditByCriteria' | translate }}"
                    matTooltipPosition="below"
                    class="grey">
            info
          </mat-icon>
          <button matIconSuffix
                  mat-icon-button
                  (click)="clearInput()">
            <mat-icon fontSet="material-symbols-outlined"> clear </mat-icon>
          </button>
        </mat-form-field>
      </div>
      <ng-container *ngIf="filteredCredits?.length > 0; else noMatchesMessage">
        <div class="credit-tiles-box">
          <mat-list-option *ngFor="let credit of filteredCredits"
                           [value]="credit"
                           (click)="selectCredit(credit)"
                           [selected]="credit?.id === selectedCredit?.id"
                           [ngClass]="{ selected: credit?.id === selectedCredit?.id }"
                           lines="2"
                           class="credit-tile">
            <div matListItemIcon
                 class="credit-status-icon-box">
              <itfg-credit-status-icon [creditStatus]="credit.creditStatus"></itfg-credit-status-icon>
            </div>

            <div matListItemTitle
                 class="pointer-no-select">
              <span class="client-info">
                <span class="client-name">
                  {{ credit.user.fullName ? credit.user.firstName + ' ' + credit.user.lastName :
                  'communication.missingName' | translate }}
                </span>
                <mat-icon fontSet="material-symbols-outlined"
                          class="credit-icon caller-credit-relation-icon fill">
                  {{ credit?.isUnrelated ? 'question_mark' : (credit.user.mobile ===
                  formattedPhoneNumber ? 'person' : 'people') }}
                </mat-icon>
                <mat-icon *ngIf="openedCredit?.id === credit?.id"
                          matTooltip="{{ 'pbx.openedCredit' | translate }}"
                          fontSet="material-symbols-outlined"
                          class="credit-icon opened-credit-icon fill">credit_card</mat-icon>
              </span>
            </div>

            <span matListItemLine
                  class="pointer-no-select">{{
              (credit.user.civilId ? credit.user.civilId : 'communication.missingCivilId' | translate) +
              ' / ' +
              (credit.user.email
              ? getTruncatedText(credit.user.email, 22)
              : 'communication.missingEmail' | translate)
              }}</span>

            <span class="credit-id pointer-no-select">
              {{ credit.id }}
            </span>
          </mat-list-option>
          <mat-list-option (click)="selectCredit(null)">
            <mat-icon matListItemIcon>clear</mat-icon>
            <span [translate]="'pbx.actions.reset'"
                  matListItemTitle
                  class="pointer-no-select">Нулирай</span>
          </mat-list-option>
        </div>
      </ng-container>
      <ng-template #noMatchesMessage>
        <mat-list-item [disabled]="true">
          <span [translate]="'credits.noResultsFound'"
                class="no-select">Няма намерени резултати</span>
        </mat-list-item>
      </ng-template>
    </mat-selection-list>
  </mat-expansion-panel>
</div>
