import { of as observableOf, Observable } from 'rxjs';

import { share, filter, map, mergeMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {
  Router,
  NavigationEnd,
  ActivatedRoute,
  ActivatedRouteSnapshot,
} from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BreadcrumbNode } from '../types/breadcrumb-node';
import { Title } from '@angular/platform-browser';

@Injectable()
export class NavigationInfoService {
  public breadcrumbsChanged$: Observable<BreadcrumbNode[]>;
  public titleChanged$: Observable<string>;
  public translate: TranslateService;
  public breadcrumbs: any[];
  public title: string;
  public headTitle: string;
  public activatedRoute: ActivatedRoute;

  constructor(
    router: Router,
    activatedRoute: ActivatedRoute,
    translate: TranslateService,
    private titleService: Title
  ) {
    this.translate = translate;
    this.activatedRoute = activatedRoute;

    const lastRoute = <ActivatedRoute>this.getLastChildRoute(activatedRoute);
    const initRoutes = [];
    let lastRouteSnapshot = activatedRoute.snapshot;
    while (lastRouteSnapshot.firstChild) {
      lastRouteSnapshot = lastRouteSnapshot.firstChild;
      initRoutes.push(lastRouteSnapshot);
    }

    this.generateBreadcrumbs(initRoutes).subscribe(
      (breadcrumbs: BreadcrumbNode[]) => {
        this.breadcrumbs = breadcrumbs;
      }
    );

    lastRoute.data
      .pipe(mergeMap(this.mapDataToTitle.bind(this)))
      .subscribe(title => {
        this.title = (title as any) as string;
      });

    this.titleChanged$ = router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => activatedRoute),
      filter((route: ActivatedRoute) => route.outlet === 'primary'),
      map(this.getLastChildRoute.bind(this)),
      mergeMap((route: ActivatedRoute) => route.data),
      mergeMap(this.mapDataToTitle.bind(this)),
      share()
    ) as Observable<string>;

    this.breadcrumbsChanged$ = router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => activatedRoute.snapshot),
      filter((route: ActivatedRouteSnapshot) => route.outlet === 'primary'),
      map(this.extractRouteChain.bind(this)),
      mergeMap(this.generateBreadcrumbs.bind(this)),
      share()
    ) as Observable<BreadcrumbNode[]>;

    this.breadcrumbsChanged$.subscribe(
      (breadcrumbs: BreadcrumbNode[]) => (this.breadcrumbs = breadcrumbs)
    );
    this.titleChanged$.subscribe((title: string) => (this.title = title));

    // set head title
    this.titleChanged$.subscribe((title: string) => {
      if (title) {
        this.headTitle =
          this.translate.instant(title) +
          ' - ' +
          this.translate.instant('global.itfgAdmin');
        this.titleService.setTitle(this.headTitle);
      } else {
        this.headTitle = this.translate.instant('global.itfgAdmin');
        this.titleService.setTitle(this.headTitle);
      }
    });
  }

  mapDataToTitle(data) {
    if (data.titleKey) {
      return this.translate.get(data.titleKey);
    } else {
      return observableOf('');
    }
  }

  extractRouteChain(route: ActivatedRouteSnapshot): ActivatedRouteSnapshot[] {
    const routeChain: ActivatedRouteSnapshot[] = [];
    while (route.firstChild) {
      route = route.firstChild;
      routeChain.push(route);
    }
    return routeChain;
  }

  getLastChildRoute(
    activatedRoute: ActivatedRoute | ActivatedRouteSnapshot
  ): ActivatedRoute | ActivatedRouteSnapshot {
    while (activatedRoute.firstChild) {
      activatedRoute = activatedRoute.firstChild;
    }
    return activatedRoute;
  }

  generateBreadcrumbs(
    routes: ActivatedRouteSnapshot[]
  ): Observable<BreadcrumbNode[]> {
    routes = routes.filter((route: ActivatedRouteSnapshot) =>
      route.data.hasOwnProperty('breadcrumbKey')
    );
    let breadcrumbs = routes.map(
      (route: ActivatedRouteSnapshot, index: number) => {
        const urlPath = route.pathFromRoot
          .filter(
            (routeSnapshot: ActivatedRouteSnapshot) =>
              routeSnapshot.url.length > 0
          )
          .map((routeSnapshot: ActivatedRouteSnapshot) =>
            routeSnapshot.url.toString()
          );
        return {
          name: route.data.breadcrumbKey,
          url: '/' + urlPath.join('/'),
        };
      }
    );

    breadcrumbs = this.removeDuplicatesByProperty(breadcrumbs, 'name');

    let breadcrumbs$: Observable<BreadcrumbNode[]>;

    if (breadcrumbs.length > 0) {
      const lastChildRouteSnapshot = <ActivatedRouteSnapshot>(
        this.getLastChildRoute(this.activatedRoute.snapshot)
      );
      const params = Object.values(lastChildRouteSnapshot.params);
      const params2 = Object.values(lastChildRouteSnapshot.params);
      breadcrumbs.filter(
        (breadcrumb: BreadcrumbNode) => breadcrumb.name[0] === ':'
      );
      const breadcrumbTranslationKeys = breadcrumbs.map(
        (breadcrumb: BreadcrumbNode): string => breadcrumb.name
      );
      breadcrumbs$ = this.translate.get(breadcrumbTranslationKeys).pipe(
        map(translations => {
          breadcrumbs.forEach(
            (breadcrumb: BreadcrumbNode) =>
              (breadcrumb.name = translations[breadcrumb.name])
          );
          breadcrumbs.forEach((breadcrumb: BreadcrumbNode) => {
            if (breadcrumb.name[0] === ':') {
              breadcrumb.name = params.pop();
            }
          });
          return breadcrumbs;
        })
      );
    } else {
      breadcrumbs$ = observableOf([]);
    }
    return breadcrumbs$;
  }

  removeDuplicatesByProperty(array, property) {
    return array.filter((obj, pos, arr) => {
      return arr.map(mapObj => mapObj[property]).indexOf(obj[property]) === pos;
    });
  }
}
