<div layout="column" >
  <div layout="row"
       layout-align="space-between center"
       flex>
  <span [translate]="'compensation.getNewCompensationReport'"
        mat-dialog-title>Get new compensation report</span>
    <button tabindex="-1"
            mat-icon-button
            class="close-button"
            [mat-dialog-close]="false">
      <mat-icon>clear</mat-icon>
    </button>
  </div>

  <div>
    <form [formGroup]="compensationTypesForm"
          (ngSubmit)="onSubmit()">
      <div layout="column" layout-wrap>

        <mat-form-field>
          <mat-select
            required
            formControlName="type"
            placeholder="{{ 'compensation.chooseCompensationReport' | translate }}"
            disableOptionCentering="true"
            >

            <mat-select-trigger>
            <span layout="row"
                  layout-align="start end">

              <span>{{ compensationTypesForm.get('type').value && "compensation." + compensationTypesForm.get('type').value.toLowerCase() | translate }}</span>
            </span>
            </mat-select-trigger>

            <mat-option *ngFor="let compensationType of data.compensationTypesList | keyvalue"
                        [value]="compensationType.value"
                        [matTooltip]="'compensation.types.' + compensationType.value | translate"
                        matTooltipPosition="above"
                        >
              {{ "compensation." + compensationType.value.toLowerCase() | translate }}
            </mat-option>
          </mat-select>
          <mat-error [translate]="'forms.required'">This field is required</mat-error>
        </mat-form-field>

        <mat-form-field
      >
        <input
          matInput
          formControlName="dateFrom"
          [matDatepicker]="datePickerFrom"
          (click)="datePickerFrom.open()"
          [max]="currentDate"
          readonly
          placeholder="{{ 'global.from' | translate }}"
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="datePickerFrom"
        ></mat-datepicker-toggle>
        <mat-datepicker #datePickerFrom [touchUi]="false"></mat-datepicker>
      </mat-form-field>
    <div layout="row"
         class="margin-top-24px"
         layout-wrap layout-align="end center">
      <button type="submit"
              [disabled]="compensationTypesForm.get('type').disabled"
              mat-raised-button>
        {{ 'global.download' | translate }}
      </button>
    </div>
  </div>
    </form>
  </div>

</div>


