import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MatExpansionPanel } from '@angular/material/expansion';
import { Router } from '@angular/router';
import { Credit } from '@app/core/types/credit';
import { EventLogStateService } from '../../event-log-state.service';
import { Subject, debounceTime, takeUntil } from 'rxjs';
import * as _ from 'lodash-es';
import { UntypedFormControl } from '@angular/forms';
import {
  Call,
  HIDDEN_PHONE_NUMBER,
  INQUIRY_PHONE_NUMBER,
} from '@app/core/types/call';
import { ExpansionListState } from '../../../shared/state.interfaces';
import { ContactPhone, ContactPhoneType } from '@app/core/types/contact-phone';
import { CreditService } from '@app/core/services/credit.service';

@Component({
  selector: 'itfg-expansion-list',
  templateUrl: './expansion-list.component.html',
  styleUrls: ['./expansion-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ExpansionListComponent implements OnInit, OnDestroy {
  @Input() onExpansionListOpened: () => void;
  @Input() onExpansionListClosed: () => void;
  @ViewChild(MatExpansionPanel) expansionPanel: MatExpansionPanel;
  creditContextArr: Credit[] = [];
  filteredCredits: Credit[] = [];
  openedCredit: Credit;
  selectedCredit: Credit;
  phoneNumber: string;
  formattedPhoneNumber: string;
  call: Call;
  openedCreditContacts: ContactPhone[];
  contactPhoneTypes: ContactPhoneType[];
  searchTerm: string = '';
  searchControl = new UntypedFormControl('');
  hiddenNumber: string = HIDDEN_PHONE_NUMBER;
  inquiryPhoneNumber: string = INQUIRY_PHONE_NUMBER;

  private _unsubscribe = new Subject<void>();

  constructor(
    private router: Router,
    public eventLogStateService: EventLogStateService,
    private creditService: CreditService,
  ) {}

  ngOnInit(): void {
    this.eventLogStateService
      .getExpansionListState()
      .pipe(takeUntil(this._unsubscribe))
      .subscribe(
        ({
          phoneNumber,
          openedCredit,
          selectedCredit,
          creditContextArr,
        }: ExpansionListState) => {
          this.updateState({
            phoneNumber,
            openedCredit,
            selectedCredit,
            creditContextArr,
          });
        }
      );

    this.creditService.reloadSectedAndOpenedCreditExpansionList
      .pipe(takeUntil(this._unsubscribe))
      .subscribe((credit: Credit) => {
        if (this.selectedCredit?.id === credit.id) {
          this.selectedCredit = credit;
          this.eventLogStateService.setState({
            selectedCredit: credit
          })
        }

        const creditIndex = this.creditContextArr?.findIndex((c: Credit) => c.id === credit.id);

        if (creditIndex !== -1) {
          this.creditContextArr[creditIndex] = credit;
          
          this.creditContextArr = [...this.creditContextArr];
      
          this.eventLogStateService.setState({
            creditContextArr: this.creditContextArr
          });
        }
      });

    this.searchControl.valueChanges
      .pipe(debounceTime(300))
      .subscribe((searchTerm: string) => {
        this.filterCredits(searchTerm);
      });
  }

  updateState(state: ExpansionListState) {
    const {
      phoneNumber,
      openedCredit,
      selectedCredit,
      creditContextArr,
    } = state;

    const prevPhoneNumber = this.phoneNumber;
    const prevOpenedCredit = this.openedCredit;
    const prevSelectedCredit = this.selectedCredit;

    if (prevPhoneNumber !== phoneNumber) {
      this.phoneNumber = phoneNumber;
      this.formattedPhoneNumber = this.eventLogStateService.getBulgarianPhoneNumberWithCountryCode(phoneNumber);
      this.expansionPanel?.close();
    }
  
    if (!prevOpenedCredit || prevOpenedCredit.id !== openedCredit?.id) {
      this.openedCredit = openedCredit;
    }
  
    if (!prevSelectedCredit || prevSelectedCredit.id !== selectedCredit?.id) {
      this.selectedCredit = selectedCredit;
    }
  
    this.creditContextArr = creditContextArr;
    this.filteredCredits = this.creditContextArr;
  }

  selectCredit(selCredit: Credit) {
    this.eventLogStateService.setState({ selectedCredit: selCredit });
    if (selCredit) {
      this.router.navigate(['credits', selCredit.id]);
    }

    this.expansionPanel.close();
  }

  filterCredits(searchTerm: string) {
    if (!searchTerm) {
      this.filteredCredits = this.creditContextArr;
      return;
    }

    const searchTerms = searchTerm.trim().toLowerCase().split(/\s+/);

    this.filteredCredits = this.creditContextArr.filter((credit: Credit) => {
      const creditIdMatch = credit.id.toString().includes(searchTerm);
      const civilIdMatch = credit.user.civilId.includes(searchTerm);
      const emailMatch =
        credit.user.email &&
        credit.user.email
          .toLowerCase()
          .includes(searchTerm.trim().toLowerCase());

      const fullNameParts = credit.user?.fullName
        .trim()
        .toLowerCase()
        .split(/\s+/);

      const nameMatch = searchTerms.every((term) =>
        fullNameParts.some((part) => part.includes(term))
      );

      return civilIdMatch || emailMatch || creditIdMatch || nameMatch;
    });
  }

  clearInput() {
    this.searchControl.reset();
    this.filteredCredits = this.creditContextArr;
  }

  getTruncatedText(text: string, maxLength = Infinity) {
    return text.length > maxLength ? text.slice(0, maxLength) + '...' : text;
  }

  ngOnDestroy() {
    this._unsubscribe.next();
    this._unsubscribe.complete();
  }
}
