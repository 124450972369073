<div *ngIf="client?.civilId && client?.id" class="top-padding-16">
  <h3 class="edit-screen-card-title">
    {{ 'maritalStatus.maritalStatusReport' | translate }}
  </h3>
  <div
    flex
    layout="row"
    layout-wrap
    layout-align="space-between stretch"
  >
    <div flex="100" flex-gt-md="75" flex-gt-sm="100" flex-gt-xs="100">
      <itfg-reports-info-panel
        [listData]="maritalStatusListData"
        [reportData]="maritalStatusData"
        [reportTime]="requestTime"
        [requesterName]="requesterName"
        [metaStatusCode]="''"
        [requestTime]="requestTime"
        (dataChanged)="maritalStatusDataChanged($event)"
      ></itfg-reports-info-panel>
    </div>
    <div flex layout="row" layout-align="end start" [style.min-height.px]="65">
      <itfg-client-differences-button
        [config]="maritalStatusConfig"
      >
      </itfg-client-differences-button>
      <button
        mat-raised-button
        [disabled]="['MARITAL_ADD'] | isUnauthorized | async"
        (click)="getNewMaritalStatusReport()"
      >
        {{ 'maritalStatus.newReport' | translate }}
      </button>
    </div>
  </div>
  <div *ngIf="maritalStatusListData?.length === 0 && !isReport"
       flex
       layout="row"
       layout-align="start"
       class="left-padding-16"
  >
    <h3
      class="edit-screen-card-title"
      [translate]="'maritalStatus.noMaritalStatusData'"
    >
      No reports
    </h3>
  </div>
  <div *ngIf="isReport">
    <ng-container [ngSwitch]="requestStatus">
      <ng-container *ngSwitchCase="'success'">
        <ng-container *ngIf="maritalStatusListData?.length > 0">
          <div
            flex
            layout="row"
            layout-wrap
            layout-align="space-between stretch"
          >
            <mat-card appearance="outlined" flex="100">
              <mat-card-content
                flex="100"
                layout="row"
                layout-wrap
                layout-align="space-around center"
              >
                <div
                  flex="100"
                  layout="row"
                  layout-wrap
                  layout-align="space-around center"
                >
                  <h1 mat-display-4>{{maritalStatusReportData?.MaritalStatusName}}</h1>
                </div>
              </mat-card-content>
            </mat-card>
          </div>
        </ng-container>
      </ng-container>

      <ng-container *ngSwitchCase="'incorrect_input'">
        <h2 layout="row" layout-align="start">
          {{ 'maritalStatus.incorrectDataMsg' | translate }}
        </h2>
      </ng-container>

      <ng-container *ngSwitchCase="'not_found'">
        <h4 layout="row" layout-align="start">
          {{
          'maritalStatus.noMaritalStatusDataFound'
            | translate
          }}
        </h4>
      </ng-container>

      <ng-container *ngSwitchCase="'server_timeout'">
        <h4 class="edit-screen-card-title" layout="row" layout-align="start">
          {{
          'reports.serverTimeoutError'
            | translate: {requestTime: requestTime | itfgDateFormat}
          }}
          <itfg-no-reports-info
            [alertMessage]="{
              civilId: client?.civilId,
              type: maritalStatusData?.type,
              source: maritalStatusData?.source,
              status: maritalStatusData?.status,
              time: maritalStatusData?.time,
              data: maritalStatusData?.data
            }"
          >
          </itfg-no-reports-info>
        </h4>
      </ng-container>

      <ng-container *ngSwitchCase="'server_error'">
        <div layout="row" layout="row" layout-align="start">
          <h4 class="edit-screen-card-title">
            {{
            'reports.serverGenericError'
              | translate: {requestTime: requestTime | itfgDateFormat}
            }}
            <itfg-no-reports-info
              [alertMessage]="{
                civilId: client?.civilId,
                type: maritalStatusData?.type,
                source: maritalStatusData?.source,
                status: maritalStatusData?.status,
                time: maritalStatusData?.time,
                data: maritalStatusData?.data
              }"
            >
            </itfg-no-reports-info>
          </h4>
        </div>
      </ng-container>
    </ng-container>
  </div>
</div>
