import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MenuListItemComponent } from './navigation-menu/menu-list-item/menu-list-item.component';
import { NavigationMenuComponent } from './navigation-menu/navigation-menu.component';
import { ToolbarComponent } from './toolbar/toolbar.component';
import { MainLayoutComponent } from './main-layout.component';
import { NavigationMenuService } from '../../core/services/navigation-menu.service';
import { MaterialModule } from '../../lib/material.module';
import { BrowserModule } from '@angular/platform-browser';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '../../shared/shared.module';
import { ClientComponentModule } from '../client/client-component.module';
import { QuickSearchComponent } from './quick-search/quick-search.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CreditStatusIconModule } from '../credit/credit-status-icon/credit-status-icon.module';
import { ProductCalculatorComponent } from '../product/product-calculator/product-calculator.component';
import { MainPagesMenuComponent } from './main-pages-menu/main-pages-menu.component';
import { BottomDrawerService } from '../../core/services/bottom-drawer.service';
import { CreditPipesModule } from '../credit/credit-pipes.module';
import { MatExpansionModule } from '@angular/material/expansion';
import { CreditCalculatorFormModule } from '../credit-calculator-form/credit-calculator-form.module';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatBadgeModule } from '@angular/material/badge';
import { NotificationsButtonComponent } from './notifications-button/notifications-button.component';
import { RightDrawerModule } from './right-drawer/right-drawer.module';
import { CallHubComponent } from './call-hub/call-hub.component';
import { CallStatusIconModule } from '../client-communications/call-status-icon/call-status-icon.module';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule,
    MaterialModule,
    BrowserModule,
    MatIconModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    CreditStatusIconModule,
    ClientComponentModule,
    MatButtonToggleModule,
    CreditPipesModule,
    MatExpansionModule,
    CreditCalculatorFormModule,
    DragDropModule,
    MatBadgeModule,
    RightDrawerModule,
    CallStatusIconModule,
    MatProgressSpinnerModule,
  ],
  declarations: [
    MainLayoutComponent,
    ToolbarComponent,
    NotificationsButtonComponent,
    NavigationMenuComponent,
    QuickSearchComponent,
    MenuListItemComponent,
    MainPagesMenuComponent,
    ProductCalculatorComponent,
    CallHubComponent,
  ],
  providers: [NavigationMenuService, BottomDrawerService],
  exports: [MainLayoutComponent, MainPagesMenuComponent],
})
export class MainLayoutModule {}
