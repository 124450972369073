import { Pipe, PipeTransform } from '@angular/core';
import {
  EntityMatchOperations,
  EntityMatch,
} from '../../core/types/entity-match';

const isEmpty = (value: any): boolean => {
  return value === null || value === undefined || value === '' || JSON.stringify(value) === '{}';
};

@Pipe({
  name: 'objectDeepDiff',
})
export class ObjectDeepDiffPipe implements PipeTransform {
  transform(firstObject, secondObject): EntityMatch {
    const primitiveTypes = ['string', 'number', 'boolean'];
    const firstKeys = Object.keys(firstObject);
    const secondKeys = Object.keys(secondObject);
    const keys = Array.from(new Set(firstKeys.concat(secondKeys)));
    const matches: EntityMatch = {
      fields: {},
      operation: EntityMatchOperations.NOT_MODIFIED,
    };

    keys.forEach((key: string) => {
      const propertyType = typeof firstObject[key];
      const firstObjectValue = firstObject[key];
      const secondObjectValue = secondObject[key];

      matches.fields[key] = {
        oldValue: firstObject[key],
        newValue: secondObject[key],
        operation: EntityMatchOperations.NOT_MODIFIED,
      };

      if (isEmpty(firstObjectValue) && isEmpty(secondObjectValue)) {
        matches.fields[key].operation = EntityMatchOperations.NOT_MODIFIED;
      } else if (isEmpty(firstObjectValue)) {
        // Value added
        matches.fields[key].operation = EntityMatchOperations.ADDED;
      } else if (isEmpty(secondObjectValue)) {
        // Value removed
        matches.fields[key].operation = EntityMatchOperations.REMOVED;
      } else if (primitiveTypes.includes(propertyType)) {
        if (firstObjectValue !== secondObjectValue) {
          // Value changed
          matches.fields[key].operation = EntityMatchOperations.MODIFIED;
        } else {
          // Value not modified
          matches.fields[key].operation = EntityMatchOperations.NOT_MODIFIED;
        }
      } else {
        matches.fields[key] = this.transform(
          firstObjectValue,
          secondObjectValue
        );
      }

      if (
        matches.fields[key].operation !== EntityMatchOperations.NOT_MODIFIED
      ) {
        matches.operation = EntityMatchOperations.MODIFIED;
      }
    });

    return matches;
  }
}
