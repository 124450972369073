import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ClientCommunicationRequestService } from './client-communication-request.service';
import { Call, CallDialogMode, CallDirection, CallStage, CallStatus, CreateCallDto } from '../types/call';
import { SearchOptions, SearchOptionsDTO } from '../types/search-options';
import { Page } from '../types/page';
import { Note } from '../types/note';
import { EventLogStateService } from '@app/main/main-layout/right-drawer/event-log/event-log-state.service';
import { NotificationService } from './notification.service';
import { TranslateService } from '@ngx-translate/core';
import { HttpErrorResponse } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { CallEditDialogComponent } from '@app/main/main-layout/right-drawer/event-log/pbx/call-edit-dialog/call-edit-dialog.component';

@Injectable()
export class CallService {
  onCallsSearchOptionsChange = new Subject<SearchOptions>();
  connectingToPbx$ = new Subject<boolean>();
  callStage: CallStage = CallStage.INIT;

  constructor(
    private request: ClientCommunicationRequestService,
    private eventLogStateService: EventLogStateService,
    private notifications: NotificationService,
    private translate: TranslateService,
    private dialog: MatDialog
  ) { }

  initiateClickToCall(phoneNumber: string): Observable<Call> {
    return this.request.post(['calls', 'click-to-call'], {
      body: {
        phoneNumber: phoneNumber,
        direction: CallDirection.OUT,
        status: CallStatus.INITIATED,
      },
    });
  }

  call(phoneNumber: string, isInCreditEdit?: boolean, isInClientEdit?: boolean) {
    // const snackBarRef = this.notifications.showPbxNotificationComponent({
    //   notificationText: 'pbx.actions.connectingToPbx',
    // });

    this.connectingToPbx$.next(true);

    // TODO: Once we integrate with PBX this should use initiateClickToCall function
    this.initiateClickToCall(phoneNumber).subscribe({
      next: (call: Call) => {
        // this.notifications.closePbxNotificationSnackbarComponent(snackBarRef);
        this.eventLogStateService.setState({
          call: call,
          phoneNumber: call.phoneNumber,
        }, {
          isInCreditEdit: isInCreditEdit,
          isInClientEdit: isInClientEdit,
        });

        this.notifications.showLocalizedSuccessMessage({
          notificationText: this.translate.instant(
            'communication.successfullyCreatedCall',
            { phoneNumber }
          ),
          duration: 3000
        });

        return call;
      },

      error: (err: HttpErrorResponse) => {
        // this.notifications.showLocalizedErrorMessage({
        //   notificationText: this.translate.instant(
        //     'error.' + (err.error[0]?.message || err.error?.message)
        //   ),
        // });

        const createManualCallDto: CreateCallDto = {
          phoneNumber: phoneNumber,
          direction: CallDirection.OUT,
          status: CallStatus.INITIATED,
        }

        this.createManualCall(createManualCallDto).subscribe({
          next: (call: Call) => {
            this.eventLogStateService.setState({
              call: call,
              phoneNumber: call.phoneNumber,
            }, {
              isInCreditEdit: isInCreditEdit,
              isInClientEdit: isInClientEdit,
            });

            this.notifications.showLocalizedSuccessMessage({
                notificationText: this.translate.instant('pbx.successfullyCreatedManualCall', { phoneNumber: call.phoneNumber })
            });
    
            return call;
          },
          error: (err: HttpErrorResponse) => {
            this.notifications.showLocalizedErrorMessage({
              notificationText: 'pbx.unsuccessfullyCreatedManualCall'
            })
          },
          complete: () => {
            this.connectingToPbx$.next(false);
          }
        })

        // this.eventLogStateService.setState({
        //   phoneNumber: phoneNumber,
        //   call: null,
        //   failedCallData: {
        //     phoneNumber: this.eventLogStateService.getBulgarianPhoneNumberWithCountryCode(phoneNumber),
        //     direction: CallDirection.OUT,
        //   }
        // })
      },
      complete: () => {
        // this.notifications.closePbxNotificationSnackbarComponent(snackBarRef);
        this.connectingToPbx$.next(false);
      },
    });
  }

  openEditCallDialog(mode?: CallDialogMode) {
    const callServiceState = this.eventLogStateService.getCallServiceState();
    const data = {
      ...callServiceState,
      mode: mode
    };

    return this.dialog.open(CallEditDialogComponent, {
      data: data,
      restoreFocus: false,
      autoFocus: false,
      minWidth: '500px',
    });
  }

  getCallsList(options?: SearchOptionsDTO): Observable<Page<Call>> {
    return this.request.get(['calls'], { params: options });
  }

  createManualCall(callDto: CreateCallDto): Observable<Call> {
    return this.request.post(['calls', 'manual-call'], {
      body: callDto,
    });
  }

  updateCall(callId: number, updateCallDto: any): Observable<Call> {
    return this.request.patch(['calls', callId], {
      body: updateCallDto
    })
  }

  getCallById(id: number) {
    return this.request.get(['calls', id])
  }

  getAgentUnhandledCalls(
    options?: SearchOptionsDTO
  ): Observable<{
    agentUnhandledCalls: Call[];
    agentCallbackTaggedCalls: Call[];
    agentCallsWithNoNotes: Call[];
  }> {
    return this.request.get(['calls', 'unhandled', 'agent'], {
      params: options,
    });
  }

  getNoAgentUnhandledCalls(options?: SearchOptionsDTO): Observable<Call[]> {
    return this.request.get(['calls', 'unhandled', 'no-agent'], {
      params: options,
    });
  }

  getConversationLogByCreditId(creditId: number): Observable<Note[]> {
    return this.request.get(['calls', 'credit', creditId, 'conversation-log']);
  }

  getConversationLogByClientId(clientId: number): Observable<Note[]> {
    return this.request.get(['calls', 'client', clientId, 'conversation-log']);
  }

  aggregateCallData(calls: Call[]) {
    const totalCalls = calls.length;
    const incomingCalls = calls.filter((call: Call) => call.direction === CallDirection.IN);
    const outgoingCalls = calls.filter((call: Call) => call.direction === CallDirection.OUT);

    const successfulIncoming = incomingCalls.filter((call: Call) => call.status === CallStatus.AGENT_CONNECTED).length;
    const successfulOutgoing = outgoingCalls.filter((call: Call) => call.status === CallStatus.ANSWER).length;

    const unsuccessfulOutgoing = outgoingCalls.filter((call: Call) => 
      [CallStatus.NO_ANSWER, CallStatus.BUSY, CallStatus.FAILED, CallStatus.CONNECTION_FAILED].includes(call.status)
    ).length;

    const unsuccessfulIncoming = incomingCalls.filter((call: Call) => 
      [
        CallStatus.AGENT_FREE_CALLBACK_REQUESTED,
        CallStatus.AGENT_FREE_NO_CALLBACK_REQUESTED,
        CallStatus.ALL_BUSY_CALLBACK_REQUESTED,
        CallStatus.ALL_BUSY_NO_CALLBACK_REQUESTED,
        CallStatus.HANGUP_AT_WELCOME,
        CallStatus.NON_WORKING_HOURS_CALLBACK_REQUESTED,
        CallStatus.NON_WORKING_HOURS_NO_CALLBACK_REQUESTED,
      ].includes(call.status)
    ).length;

      const inProgressCalls = calls.filter((call: Call) => 
      [CallStatus.INITIATED, CallStatus.ONGOING].includes(call.status)
    ).length;

    const phoneNumberCounts = calls.reduce((acc, call: Call) => {
      acc[call.phoneNumber] = (acc[call.phoneNumber] || 0) + 1;
      return acc;
    }, {} as Record<string, number>);

    const uniqueNumbers = Object.keys(phoneNumberCounts).length;
    const repeatCallers = Object.values(phoneNumberCounts).filter(count => count > 1).length;

    const totalDuration = calls.reduce((sum, call: Call) => sum + (call.duration || 0), 0);
    const averageDuration = totalDuration / totalCalls;
    const longestCall = Math.max(...calls.map((call: Call) => call.duration || 0));

    const callStatusCounts = Object.values(CallStatus).reduce((acc, status) => {
      acc[status] = calls.filter(call => call.status === status).length;
      return acc;
    }, {} as Record<string, number>);

    return {
      totalCalls,
      incomingCalls: incomingCalls.length,
      outgoingCalls: outgoingCalls.length,
      successfulIncoming,
      successfulOutgoing,
      unsuccessfulOutgoing,
      unsuccessfulIncoming,
      inProgressCalls,
      uniqueNumbers,
      repeatCallers,
      totalDuration,
      averageDuration,
      longestCall,
      totalSuccessful: successfulIncoming + successfulOutgoing,
      totalUnsuccessful: unsuccessfulIncoming + unsuccessfulOutgoing,
      callStatusCounts
    };
  }
}
