import {
  Component,
  OnInit,
  ChangeDetectorRef,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ClaimEditComponent } from '../claim-edit/claim-edit.component';
import { DeserializedClaim } from '../../../core/types/claim';
import { InteractionMode } from '../../../core/types/interaction-mode';
import { Page } from '../../../core/types/page';
import {
  SearchDirection,
  SearchOptions,
} from '../../../core/types/search-options';
import { ClaimService } from '../../../core/services/claims.service';
import { finalize } from 'rxjs/operators';
import { ListBaseComponent } from '@app/main/list-base/list-base.component';
import { ColumnsService } from '@app/core/services/columns.service';
import { MatPaginator } from '@angular/material/paginator';
import { ItfgDataTableColumn } from '@app/core/components/data-table/types/data-table.column';

@Component({
  selector: 'itfg-claim-list',
  templateUrl: './claim-list.component.html',
  styleUrls: ['./claim-list.component.scss'],
})
export class ClaimListComponent extends ListBaseComponent implements OnInit, OnChanges {
  public claimPage: Page<DeserializedClaim>;
  public claimListLoading: boolean;
  public claimListEmpty: boolean;
  public modes: typeof InteractionMode = InteractionMode;
  @ViewChild('pagingBarResponsive', { static: true })
  public pagingBarResponsive: MatPaginator;
  @Input() claimColumns: ItfgDataTableColumn[];
  @Input() searchOptions: SearchOptions;

  constructor(
    private claimService: ClaimService,
    private dialog: MatDialog,
    private _changeDetectorRef: ChangeDetectorRef,
    public columnsService: ColumnsService,
  ) {
    super(columnsService);
    this.claimPage = {};
    this.claimListEmpty = false;
  }

  ngOnInit() {
    this.claimListLoading = false;
    this.triggerDataChange$.subscribe((searchOptions: SearchOptions) => {
      this.loadClaimList(searchOptions);
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    this.loadClaimList(this.searchOptions);
  }

  loadClaimList(options: SearchOptions, fromOnInit?: boolean) {
    this.claimListLoading = true;
    this.claimService
      .getClaimList$(options.getDTO())
      .pipe(finalize(() => (this.claimListLoading = false)))
      .subscribe((claimPage: Page<DeserializedClaim>) => {
        this.claimPage = claimPage;
        this.claimListEmpty = claimPage.content.length < 1;

        if (fromOnInit) {
          this._changeDetectorRef.detectChanges(); // Fix for ExpressionChangedAfterItHasBeenCheckedError
        }
      });
  }

  addClaim(userId: number) {
    const dialogOptions = {
      data: {
        mode: this.modes.NEW,
        userId: userId,
      },
    };

    this.openClaimEditDialog(dialogOptions);
  }

  editClaim(claim: DeserializedClaim) {
    const dialogOptions = {
      data: {
        mode: this.modes.EDIT,
        claim: claim,
        userId: claim.user.id,
      },
    };

    this.openClaimEditDialog(dialogOptions);
  }

  openClaimEditDialog(dialogOptions) {
    this.dialog
      .open(ClaimEditComponent, dialogOptions)
      .beforeClosed()
      .subscribe((shouldRealodData: boolean) => {
        if (shouldRealodData) {
          this.loadClaimList(this.searchOptions, true);
        }
      });
  }

  pageChanged(page) {
    super.pageChanged(page);
    this.loadClaimList(this.searchOptions, true);
  }

}
