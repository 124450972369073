<div *ngxPermissionsOnly="'CONVERSATION_READ_ALL'">
  <div class="edit-screen-header"
       layout="row"
       layout-wrap
       layout-align="space-between">
    <div flex-gt-md="33"
         flex-gt-sm="50"
         flex="50"
         layout="row"
         layout-align-gt-md="space-between end"
         layout-align="space-between end">
      <span class="edit-screen-title"
            [translate]="'communication.smsList'">SMS</span>
    </div>
    <div flex-gt-md="33"
         flex-gt-sm="50"
         flex="50"
         layout="row"
         layout-align-gt-md="end end"
         layout-align="end end">
      <div class="add-margin-left">
        <button [disabled]="['SMS_SEND'] | isUnauthorized | async"
                mat-raised-button
                type="button"
                (click)="sendNewSms($event)">
          <mat-icon>add</mat-icon>
        </button>
      </div>
      <mat-slide-toggle *ngIf="credit"
                        labelPosition="before"
                        (change)="onSmsModeChange($event)">
        <span [translate]="'communication.showAll'">Show All</span>
        </mat-slide-toggle>
    </div>
  </div>
  <mat-card appearance="outlined">
    <mat-card-content>
      <!-- <ng-template tdLoading="smsListLoading"
                   tdLoadingMode="indeterminate"
                   tdLoadingType="circle"
                   tdLoadingStrategy="overlay"
                   tdLoadingColor="accent"> -->
        <itfg-data-table [data]="smsPage?.content"
                       [columns]="smsColumns">
          <ng-template itfgDataTableTemplate="credit.id"
                       let-value="value"
                       let-row="row"
                       let-column="column">
            <div [itfgHighlightDataTableRow]="(value === credit.id) && includeAllSms">
            <!-- <div> -->
              <a *ngIf="value"
                 target="_blank"
                 [routerLink]="['/credits', value]">{{ value }}</a>
            </div>
          </ng-template>
          <ng-template itfgDataTableTemplate="body"
                       let-value="value"
                       let-row="row"
                       let-column="column">
            <div class="table-cell-overflow-ellipsis"
                 matTooltipPosition="above"
                 [matTooltip]="value">
              <span>{{ value }}</span>
            </div>
          </ng-template>
          <ng-template itfgDataTableTemplate="sent_at"
                       let-sent_at="value">
            <itfg-date-format [date]="sent_at"></itfg-date-format>
          </ng-template>
          <ng-template itfgDataTableTemplate="actions"
                       let-value="value"
                       let-row="row"
                       let-column="column">
            <!-- <div layout="row">
              <button [disabled]="['SMS_SEND'] | isUnauthorized | async"
                      mat-icon-button
                      flex
                      (click)="resendSms(row.id)">
                <mat-icon matTooltip="{{ 'communication.resendSms' | translate }}"
                          matTooltipShowDelay="500"
                          matTooltipPosition="above">refresh
                </mat-icon>
              </button>
            </div> -->
          </ng-template>
        </itfg-data-table>
        <mat-paginator #pagingBarResponsive
               (page)="pageChanged($event)"
               [pageIndex]="smsPage.number"
               [length]="smsPage.totalElements"
               [pageSize]="searchOptions.size"
               [pageSizeOptions]="[5,20,30,100]"
               showFirstLastButtons>
        </mat-paginator>
      <!-- </ng-template> -->
    </mat-card-content>
  </mat-card>
</div>