<div
  flex
  layout="row"
  layout-wrap
  layout-align="space-between stretch"
>
  <mat-card appearance="outlined" flex="100">
    <mat-card-title>
      <span [translate]="'global.client'">Client</span>
    </mat-card-title>
    <mat-card-content
      flex="100"
      layout="row"
      layout-wrap
      layout-align="space-around center"
    >
      <ng-container flex>
        <div flex="100"
             layout="row"
             layout-wrap
             layout-align="start center"
        >
          <div flex="33" layout="row" *ngFor="let info of pensionerData; let i = index">
            <mat-form-field flex="100" class="right-padding-16">
              <input
                matInput
                readonly
                type="text"
                placeholder="{{ info?.PensionerNameInfo }}"
                [value]="info?.Value"
              />
      <mat-label>{{ info?.PensionerNameInfo }}</mat-label>
            </mat-form-field>

          </div>
        </div>
      </ng-container>
    </mat-card-content>
  </mat-card>

  <mat-card appearance="outlined" flex="100">
    <mat-card-title>
      <span [translate]="'nssi.additionsOrDebt'">Client</span>
    </mat-card-title>
    <mat-card-content
      flex="100"
      layout="row"
      layout-align="space-around center"
    >
      <div *ngIf="additionOrDebtData.length > 0">
        <ng-container
          flex
          *ngFor="let info of additionOrDebtData; let i = index"
        >
          <mat-form-field class="right-padding-16">
            <input
              matInput
              readonly
              type="text"
              placeholder="{{ info?.Details }}"
              [value]="info?.Value"
            />
      <mat-label>{{ info?.Details }}</mat-label>
          </mat-form-field>

        </ng-container>
      </div>
      <div *ngIf="additionOrDebtData.length === 0" [translate]="'global.noData'">
        NO DATA
      </div>
    </mat-card-content>
  </mat-card>

  <mat-card appearance="outlined" flex="100">
    <mat-card-title>
      <span [translate]="'nssi.pensions'">Pensions</span>
    </mat-card-title>
    <mat-card-content
      flex="100"
      layout="row"
      layout-align="space-around center"
    >
      <div *ngIf="pensionsData.length > 0">
        <ng-container
          flex
          *ngFor="let info of pensionsData; let i = index"
        >
          <h3 *ngIf="info?.PensionsDetails === 'Вид на пенсията:'">{{info?.PensionsDetails}} {{info?.Value}}</h3>
          <mat-form-field *ngIf="info?.PensionsDetails !== 'Вид на пенсията:'" class="right-padding-16" matTooltip="{{info?.PensionsDetails}}">
            <input
              matInput
              readonly
              type="text"
              placeholder="{{ info?.PensionsDetails }}"
              [value]="info?.Value"
            />
      <mat-label>{{ info?.PensionsDetails }}</mat-label>
          </mat-form-field>

        </ng-container>
      </div>
      <div *ngIf="pensionsData.length === 0" [translate]="'global.noData'">
        NO DATA
      </div>
    </mat-card-content>
  </mat-card>
</div>


