import {Component, Input, OnChanges, OnInit, ViewContainerRef} from '@angular/core';
import { DialogProviderService } from '@app/core/services/dialog-provider.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'itfg-no-reports-info',
  templateUrl: './no-reports-info.component.html',
  styleUrls: ['./no-reports-info.component.scss']
})
export class NoReportsInfoComponent implements OnInit, OnChanges {

  @Input() alertMessage: object;

  public reportInfo = {};

  constructor(private dialogService: DialogProviderService, private translateService: TranslateService, private viewContainerRef: ViewContainerRef) { }

  ngOnInit(): void {
  }
  ngOnChanges() {
  }
  noReportsInfo() {
    this.dialogService.openAlert({
      title: this.translateService.instant('identityReports.noReportsMessage'),
      viewContainerRef: this.viewContainerRef,
      closeButton: this.translateService.instant('global.dismiss'),
      message:  JSON.stringify(this.alertMessage),
      width: '50%',
      maxHeight: '400px'
    });
  }
}
