import {
  Component,
  OnInit,
  Input,
  ViewChild,
  OnDestroy,
  ChangeDetectorRef,
} from '@angular/core';
import { MenuItem, BrowserStorageType } from '../../config/app-config';
import { NavigationMenuService } from '../../core/services/navigation-menu.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SessionService } from '../../core/services/session.service';
import { Credit } from '../../core/types/credit';
import { Client } from '../../core/types/client';
import { StorageService } from '../../core/services/storage.service';
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatDrawer, MatDrawerToggleResult } from '@angular/material/sidenav';
import { ProductCalculatorComponent } from '../product/product-calculator/product-calculator.component';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { IdentifierValidation } from '../../core/validation/identifier-validation';
import { DrawerMode } from '../../core/types/drawer-mode';
import { QuickSearchComponent } from './quick-search/quick-search.component';
import { LoaderService } from '@app/core/services/loader.service';
import { RightDrawerService } from './right-drawer/right-drawer.service';
import { EventLogStateService } from './right-drawer/event-log/event-log-state.service';
import { UserDetails } from '@app/core/types/user-details';
import { Call } from '@app/core/types/call';

@Component({
  selector: 'itfg-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss'],
})
export class MainLayoutComponent implements OnInit, OnDestroy {
  @Input() config: MenuItem[];
  @ViewChild('outerLayout') outerLayout: any;
  @ViewChild('rightSidenav', { static: true }) rightSidenav: MatDrawer;
  @ViewChild('leftSidenav') leftSidenav: any;
  @ViewChild('nestedMenuItems') nestedMenuItemsTemplate;
  @ViewChild('anchor') anchor: any;
  @ViewChild(QuickSearchComponent)
  quickSearch: QuickSearchComponent = {} as any;

  outerDrawerActiveTemplate: any;
  navMenuRoutes: MenuItem[];
  navigationMenuMode: string;
  drawerMode: DrawerMode;
  public drawerModeTypes: typeof DrawerMode = DrawerMode;

  sidenavWidth;
  _subscriptions: Subject<void> = new Subject<void>();
  credit: Credit;
  client: Client;
  call: Call;
  phoneNumber: string;
  storage: StorageService;
  productPlanDialogRef: MatDialogRef<ProductCalculatorComponent>;
  searchCreditForm: UntypedFormGroup;
  showLoader: boolean;
  logButtonVisible: boolean;
  currentlyLoggedOperator: UserDetails;

  constructor(
    private navigationMenuService: NavigationMenuService,
    public sessionService: SessionService,
    public dialogService: MatDialog,
    public formBuilder: UntypedFormBuilder,
    private loader: LoaderService,
    private rightDrawer: RightDrawerService,
    private eventLogStateService: EventLogStateService,
    private cdref: ChangeDetectorRef,
    public rightDrawerService: RightDrawerService
  ) {
    this.storage = new StorageService(BrowserStorageType.LocalStorage);
    this.sidenavWidth = '';
    this.drawerMode = DrawerMode.Navigation;
    this.loader.loadingBus$
      .pipe(takeUntil(this._subscriptions))
      .subscribe((isLoading: boolean) => {
        this.showLoader = isLoading;
      });
    //
    this.toggleLeftSideNav = this.toggleLeftSideNav.bind(this);

    this.currentlyLoggedOperator = this.sessionService.currentlyLoggedOperator;
  }

  ngOnInit() {
    this.navMenuRoutes = this.config;
    this.initializeSubscriptions();
    this.searchCreditForm = this.createSearchForm();
    this.rightDrawer.setDrawer(this.rightSidenav);

    this.eventLogStateService.getGlobalState().subscribe((state) => {
      this.credit = state.openedCredit;
      this.call = state.call;
      this.client = state.client;

      if (!this.call && !this.client && !this.credit) {
        this.eventLogStateService.handleRightDrawerAction({
          closeDrawer: true,
        }); // TODO: Better way to handle this case, in eventLogStateService!
      }
    });
  }

  createSearchForm() {
    const fb = this.formBuilder;
    return fb.group({
      civilId: [
        '',
        [
          Validators.minLength(6),
          Validators.maxLength(10),
          IdentifierValidation.onlyNumbers,
        ],
      ],
      phone: ['', [Validators.minLength(5), Validators.maxLength(15)]],
      email: ['', [Validators.minLength(3), Validators.maxLength(50)]],
      firstName: ['', [Validators.minLength(2), Validators.maxLength(30)]],
      middleName: ['', [Validators.minLength(2), Validators.maxLength(30)]],
      lastName: ['', [Validators.minLength(2), Validators.maxLength(30)]],
    });
  }

  toggleLayout() {
    if (this.drawerMode === DrawerMode.Navigation) {
      this.navigationMenuMode = 'menu';
    } else {
      this.navigationMenuMode = 'submenu';
    }
    this.leftSidenav.mode = 'side';

    this.navMenuRoutes = this.config;
    this.leftSidenav.toggle();
  }

  openSideDrawerMenu(routes: MenuItem[]) {
    this.drawerMode = DrawerMode.Navigation;
    this.leftSidenav.mode = 'over';
    this.navigationMenuMode = 'submenu';
    this.navMenuRoutes = routes;
    this.leftSidenav.open();
  }

  closeSideDrawerMenu(event?) {
    this.drawerMode = DrawerMode.Navigation;
    this.leftSidenav.close();
  }

  initializeSubscriptions() {
    this.navigationMenuService.onCloseNavigationDrawerClick
      .pipe(takeUntil(this._subscriptions))
      .subscribe(() => {
        this.closeSideDrawerMenu();
      });
    this.navigationMenuService.onOpenNavigationDrawerClick
      .pipe(takeUntil(this._subscriptions))
      .subscribe((routes: MenuItem[]) => {
        this.openSideDrawerMenu(routes);
      });
    this.navigationMenuService.toggleNavigationDrawerClick
      .pipe(takeUntil(this._subscriptions))
      .subscribe(() => {
        this.leftSidenav.toggle();
      });
    this.navigationMenuService.toggleProductCalculator.subscribe(
      this.toggleProductCalculator.bind(this)
    );
    this.eventLogStateService
      .isLogButtonVisible()
      .subscribe((logButtonVisible: boolean) => {
        this.logButtonVisible = logButtonVisible;
      });
  }

  toggleProductCalculator() {
    const dialogConfig: MatDialogConfig = {
      hasBackdrop: false,
      autoFocus: false,
      width: '800px',
    };
    if (!this.productPlanDialogRef) {
      this.productPlanDialogRef = this.dialogService.open(
        ProductCalculatorComponent,
        dialogConfig
      );
    } else {
      this.productPlanDialogRef.close();
      this.productPlanDialogRef = undefined;
    }
  }

  toggleRightDrawer() {
    this.rightDrawer.toggle();
    this.storage.setItem('open-right-drawer', this.rightSidenav.opened);
  }

  toggleLeftSideNav(drawerMode: DrawerMode, sideNavMode: string) {
    const oldDrawerMode = this.drawerMode;

    this.drawerMode = drawerMode;
    this.leftSidenav.mode = sideNavMode;

    if (this.leftSidenav.opened && oldDrawerMode !== drawerMode) {
      this.cdref.detectChanges();
    } else {
      this.leftSidenav.toggle();
    }
  }

  searchClicked(event) {
    if (!event && this.drawerMode === DrawerMode.Search) {
      this.leftSidenav.close();
    }
    if (event) {
      const opened: Promise<MatDrawerToggleResult> = this.leftSidenav.open();
      opened.then((fulfilled: MatDrawerToggleResult) => {
        if (fulfilled === 'open') {
          this.navigationMenuService.onSearchDrawerOpened.next();
        }
      });
    }

    this.drawerMode = DrawerMode.Search;
    this.leftSidenav.mode = 'side';
    this.navigationMenuMode = 'submenu';
    this.navMenuRoutes = [];
  }

  ngOnDestroy() {
    this._subscriptions.next();
    this._subscriptions.complete();
  }
}
