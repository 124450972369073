<mat-card appearance="outlined">
  <mat-card-header flex layout-align="space-between start">
      <mat-card-title [translate]="'filtering.filters'">Filters</mat-card-title>
    <div>
      <button mat-icon-button (click)="onNoClick()">
        <mat-icon class="icon-clear">clear</mat-icon>
      </button>
    </div>
  </mat-card-header>
  <mat-card-content>
    <mat-form-field class="margin-left-16px">
      <input matInput [placeholder]="'global.search' | translate" [formControl]="filterSearchCtrl"/>
    </mat-form-field>

    <form [formGroup]="formGroup" (ngSubmit)="onFormSubmit()" ngNativeValidate>

      <div class="filters-list" flex layout="row" layout-align="start start" layout-wrap no-flex-gap>
        <ng-container *ngFor="let control of formGroup.controls | objectToArray">
          <div *ngIf="filteredKeys.indexOf(control.key.split(' ')[0]) !== -1" class="filters-box" 
               layout="row" 
               layout-align="space-around center"
          >
            <div  class="change-cursor"
                 flex layout="row" layout-align="space-between center" (click)="slideToggle(control.key)">
              <div >{{data.filtersConfig[control.key.split(' ')[0]].nameTranslationKey | translate}}
                {{(control.key.split(' ')[1] |
                searchOperationTranslationMap | translate) }}</div>
            </div>
            <mat-slide-toggle [formControlName]="control.key">
            </mat-slide-toggle>
          </div>
        </ng-container>
      </div>
      <div layout="row"
           layout-align="end end"
           flex>
        <button mat-raised-button class="margin-top-10px" type="submit"><span [translate]="'global.apply'">Apply</span></button>
      </div>
    </form>

  </mat-card-content>
</mat-card>
