import { Injectable } from '@angular/core';
import { RequestService } from './request.service';
import { Observable } from 'rxjs';
import { Page } from '../types/page';
import { DocumentTemplate } from '../types/document';
import { EntityHistory, EntityHistoryService } from '../types/entities';
import { EntityHistoryFilter } from '../types/entity-history-filter';

@Injectable()
export class DocumentTemplateService implements EntityHistoryService {
  constructor(public request: RequestService) {}

  getDocumentTemplateList(options?: any): Observable<Page<DocumentTemplate>> {
    return this.request.get(['document-templates'], { params: options });
  }

  saveDocumentTemplate(
    documentTemplate: DocumentTemplate
  ): Observable<DocumentTemplate> {
    return this.request.post(['document-templates'], {
      body: documentTemplate,
    });
  }

  getHistory(filter: string): Observable<EntityHistory<DocumentTemplate>> {
    return this.request.get(['document-templates', 'history'], {
      params: { filter: filter },
    });
  }

  getMutableTemplateCodes(): Observable<string[]> {
    return this.request.get(['document-templates', 'mutable', 'codes']);
  }

  deleteDocumentTemplate(templateId: number | string): Observable<any> {
    return this.request.delete(['document-templates', templateId]);
  }

  getDocumentTmplateById(
    templateId: number | string
  ): Observable<DocumentTemplate> {
    return this.request.get(['document-templates', templateId]);
  }

  getDocumentHistory(
    filter: EntityHistoryFilter
  ): Observable<EntityHistory<any>> {
    return this.request.get(['document-templates', 'history'], {
      params: {
        filter: filter.getDTO(),
      },
    });
  }

  getEntityHistory(filter: EntityHistoryFilter) {
    return this.getDocumentHistory(filter);
  }
}
