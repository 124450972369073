<mat-card appearance="outlined">
  <mat-card-content class="flex-padding-fix">
    <form #conversation
          layout="column"
          [itfgPermissions]="['CONVERSATION_ADD']"
          novalidate
          [formGroup]="conversationForm"
          (ngSubmit)="onSave($event)">
      <!-- START CONVERSATION CONTROLS -->
      <mat-list>
        <div mat-subheader layout="row" layout-align="center center" flex
             [style]="{ 'border-left': '4px solid #d81b60', 'margin-bottom': '5px' }">
          <div layout="row" layout-align="start center" flex>
            <mat-icon *ngIf="formMode === formModes.PHONE" [style]="{ 'margin-right': '10px' }">perm_phone_msg
            </mat-icon>
            <mat-icon *ngIf="formMode === formModes.INFO" [style]="{ 'margin-right': '10px' }">info</mat-icon>
            <span *ngIf="formMode === formModes.PHONE" [translate]="'historyLog.conversation'">Conversation</span>
            <span *ngIf="formMode === formModes.INFO" [translate]="'communication.note'">Note</span>
          </div>
          <button mat-icon-button
                  [disabled]="saveNoteButtonDisabled"
                  matTooltip="{{ 'global.save' | translate }}"
                  type="submit">
            <mat-icon>save</mat-icon>
          </button>
        </div>
        <!-- NOTE -->
        <div layout="row">
          <mat-form-field class="flex-padding" flex>
            <mat-label>{{ 'communication.note' | translate }}</mat-label>
            <textarea matInput
                      #addCreditNoteTextarea
                      cdkTextareaAutosize
                      cdkAutosizeMinRows="3"
                      cdkAutosizeMaxRows="3"
                      formControlName="note">
            </textarea>
            <mat-error
              *ngIf="conversationForm.get('note').hasError('minlength')"
              [translate]="'forms.minLength'"
              [translateParams]="{ value: 2 }"
            >Min value error
            </mat-error>
            <mat-error *ngIf="conversationForm.get('note').hasError('required')"
                       [translate]="'forms.required'">This field is required
            </mat-error>
          </mat-form-field>
        </div>
        <!-- END NOTE -->

        <!-- PHONE CONTROLS -->
        <div layout="row"
             layout-wrap
             *ngIf="formState.phoneControlsShown"
             [@fadeInAnimation]="'in'">
          <mat-form-field class="flex-padding"
                          flex="1">
            <mat-label>{{ 'global.phoneNumber' | translate }}</mat-label>
            <mat-select formControlName="phone"
                        #phoneFormField
                        disableOptionCentering="true"
                        [matTooltip]="phoneFormField.innerText">
              <div class="phone-select-panel">
                <mat-option [ngStyle]="{'font-weight': 'bold'}"
                            [value]="clientContact">{{ clientContact }}</mat-option>
                <mat-option *ngFor="let relatedContact of relatedContacts"
                            [value]="relatedContact.phone"
                            [matTooltip]="relatedContact.type.name + ' - ' + relatedContact.description"
                            [matTooltipPosition]="'left'"
                            >
                  <span>{{ relatedContact.phone }}</span>
                </mat-option>
                <mat-option [value]="hiddenMobile">{{ 'communication.hiddenNumber' | translate  }}</mat-option>
                <mat-option (click)="openAddContactDialog()">{{ 'historyLog.addContact' | translate  }}</mat-option>
              </div>
            </mat-select>
          </mat-form-field>
          <mat-form-field class="flex-padding"
                          flex="1">
            <mat-label>{{ 'communication.conversationMedium' | translate }}</mat-label>
            <mat-select formControlName="conversationMedium"
                        (selectionChange)="conversationMediumChanged($event)"
                        #conversationMedium
                        [compareWith]="compareConversationMedia">
              <mat-option *ngFor="let conversationType of conversationTypesList"
                          [value]="conversationType"
                          [disabled]="['CONVERSATION_ADD'] | isUnauthorized | async">
                {{ conversationType | conversationTypesTranslation | translate }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <!-- END PHONE CONTROLS -->
      </mat-list>
      <!-- END CONVERSATION -->

      <mat-list *ngIf="formState.reminderControlsShown" [style.margin-bottom.px]="2">
        <div mat-subheader layout="row" layout-align="center center" flex
             [style]="{ 'border-left': '4px solid #d81b60' }">
          <div layout="row" layout-align="start center" flex>
            <mat-icon [style]="{ 'margin-right': '10px' }">notifications</mat-icon>
            <span>{{ 'communication.reminder' | translate }}</span>
          </div>
          <!-- <mat-icon>add_alert</mat-icon> -->
          <button mat-icon-button
                  matTooltip="{{ 'historyLog.addReminder' | translate }}"
                  type="button"
                  (click)="openReminderEdit()">
            <mat-icon fontSet="fa-solid" fontIcon="fa-plus fa-2xs"></mat-icon>
            <!-- <span>Добави напомняне</span> -->
          </button>
        </div>

        <mat-list-item *ngIf="reminder">
          <div layout="row" layout-align="space-between center" flex>
            <div layout="row" layout-align="start center">
                <mat-icon [ngClass]="{ active: reminderIsActive }"
                          [matTooltip]="reminderService.requestCreditReminderToDisplayText(reminder?.time).tooltip"
                          matTooltipPosition="left"
                          class="reminderText">timelapse</mat-icon>
              <span class="padding-left-5px">
                {{ reminder?.time | date:'dd.MM.yyyy HH:mm' }}
              </span>
            </div>
              <button mat-icon-button type="button"
                      [disabled]="sessionService.currentlyLoggedOperator.id !== reminder.operatorId"
                      matTooltip="{{ 'historyLog.deactivatePaymentPromise' | translate }}"
                      matTooltipShowDelay="500"
                      matTooltipPosition="above"
                      (click)="deactivateReminder(reminder)">
                <mat-icon
                  [ngClass]="{ reminderDeactivateNotAllowed: sessionService.currentlyLoggedOperator.id !== reminder.operatorId }"
                  [style.margin-right.px]="0">notifications_off
                </mat-icon>
              </button>
          </div>
        </mat-list-item>
      </mat-list>

      <!-- END REMINDER CONTROLS -->

      <!-- PAYMENT PROMISE CONTROLS -->
      <mat-list [@fadeInAnimation]="'in'"
                *ngIf="formState.paymentPromiseControlsShown">
        <div mat-subheader layout="row" layout-align="center center" flex
             [style]="{ 'border-left': '4px solid #d81b60' }">
          <div layout="row" layout-align="start center" flex>
            <mat-icon [style]="{ 'margin-right': '10px' }">payment</mat-icon>
            <span [translate]="'communication.paymentPromises'">Payment Promises</span>
          </div>
          <button mat-icon-button
                  [disabled]="(data?.creditStatus?.name !== 'OVERDUE') && (data?.creditStatus?.name !== 'REGULAR')"
                  matTooltip="{{ 'historyLog.addPaymentPromise' | translate }}"
                  (click)="openPaymentPromiseCreate()"
                  type="button">
            <mat-icon fontSet="fa-solid" fontIcon="fa-plus fa-2xs"></mat-icon>
          </button>
        </div>
        <mat-list-item *ngFor="let promise of paymentPromises" class="cursor-pointer" (click)="openPaymentPromiseDetails(promise)"
        >
        <div layout="row" layout-align="start center" flex>
          <mat-icon [matTooltip]="promise.status | paymentPromiseStatusToTranslationKey | translate"
                    matTooltipPosition="left"
                    [ngStyle]="{'color': promiseStatusStyleMap[promise.status]?.color}">{{promiseStatusStyleMap[promise.status]?.icon}}</mat-icon>
          <span matTooltip="{{ promise.operatorEmail }}"
                matTooltipPosition="right"
                class="padding-left-5px">
            {{ promise.amount }}&nbsp;{{ 'лв. на' }}&nbsp;
            <span>{{promise.date | date : 'dd.MM.yyyy'}}</span>
          </span>
        </div>
        </mat-list-item>
      </mat-list>

    </form>
  </mat-card-content>
</mat-card>
