import {Component, Inject, OnDestroy, OnInit, ViewContainerRef} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
  UntypedFormControl,
  ValidatorFn,
  UntypedFormArray,
} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {BreakpointObserver} from '@angular/cdk/layout';
import {BlacklistService} from '../../../core/services/blacklist.service';
import {IdentifierValidation} from '../../../core/validation/identifier-validation';
import { DialogProviderService } from '@app/core/services/dialog-provider.service';
import {NotificationService} from '../../../core/services/notification.service';
import {debounceTime, takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';

@Component({
  selector: 'itfg-blacklist-edit',
  templateUrl: './blacklist-edit.component.html',
  styleUrls: ['./blacklist-edit.component.scss'],
})
export class BlacklistEditComponent implements OnInit, OnDestroy {
  public formBuilder: UntypedFormBuilder;
  public blacklistEditForm: UntypedFormGroup;
  public blacklistService: BlacklistService;
  public translate: TranslateService;
  public blacklistTypes: any;
  public blacklistEditData: any;
  public blacklistFormArr: UntypedFormArray;
  public selectedBrand: string;
  public isNewItem: boolean;
  public gridSecondColumnTranslations: any;
  public blacklistOriginalTypes: any;
  public breakpointObserver: BreakpointObserver;
  public isPreset: boolean;
  public selectedBlacklistType: string;
  public currentDate: Date;
  public _dialogService: DialogProviderService;
  public _viewContainerRef: ViewContainerRef;
  public dialogConfig: any;
  notifications: NotificationService;
  _unsubscribe = new Subject<void>();
  invalidValues = '';
  validators: ValidatorFn[] = [
    IdentifierValidation.onlyNumbers,
    IdentifierValidation.isValidEgn,
    Validators.minLength(10),
    Validators.maxLength(10)
  ];

  constructor(
    public dialogRef: MatDialogRef<BlacklistEditComponent>,
    @Inject(MAT_DIALOG_DATA) blacklistEditData: any,
    formBuilder: UntypedFormBuilder,
    translate: TranslateService,
    breakpointObserver: BreakpointObserver,
    blacklistService: BlacklistService,
    _dialogService: DialogProviderService,
    _viewContainerRef: ViewContainerRef,
    notifications: NotificationService
  ) {
    this.blacklistService = blacklistService;
    this.formBuilder = formBuilder;
    this.isPreset = false;
    this.blacklistEditForm = this.createForm();
    this.blacklistFormArr = this.formBuilder.array([], Validators.required);
    this.blacklistEditData = blacklistEditData;
    this.isNewItem = true;
    this.translate = translate;
    this.blacklistTypes = [];
    this.blacklistOriginalTypes = [];
    this.breakpointObserver = breakpointObserver;
    this.selectedBlacklistType = '1';
    this.currentDate = new Date();
    this.gridSecondColumnTranslations = {
      '1': 'global.personalId',
      '2': 'clients.employer',
      '3': 'global.phoneNumberWithPrefix',
    };
    this._dialogService = _dialogService;
    this._viewContainerRef = _viewContainerRef;
    this.notifications = notifications;

    if (blacklistEditData) {
      if (blacklistEditData.isPreset) {
        this.isPreset = blacklistEditData.isPreset;
        this.blacklistEditForm = this.createForm();
      }

      if (
        blacklistEditData.blacklistType &&
        blacklistEditData.blacklistType.id
      ) {
        const mapedData = {
          blacklistType: blacklistEditData.blacklistType.id,
          value: blacklistEditData.value || '',
          expire: blacklistEditData.expire
            ? new Date(blacklistEditData.expire)
            : null,
          notes: blacklistEditData.notes ? blacklistEditData.notes : '',
        };

        this.blacklistEditForm.setValue(mapedData);
        this.isNewItem = false;
        this.selectedBlacklistType = blacklistEditData.blacklistType.id;

        if (blacklistEditData.blacklistType.id.toString() !== '1') {
          this.selectedValueChanged(mapedData.value);
        }
        this.matchValues([mapedData.value]);
      }
    }
  }

  ngOnInit() {
    if (this.isNewItem) {
      this.blacklistEditForm.patchValue({blacklistType: '1'});
    }
    this.getBlacklistTypeData();
    this.blacklistEditForm
      .get('value')
      .valueChanges.pipe(
      debounceTime(500),
      takeUntil(this._unsubscribe)
    )
      .subscribe(change => {
        const parsedChange = this.parseValues(change);
        this.matchValues(parsedChange);
        if (this.blacklistFormArr.invalid) {
          this.setErrorValues();
        }
      });
  }

  isMobile() {
    return this.breakpointObserver.isMatched('(max-width: 510px)');
  }

  resetForm() {
    this.blacklistEditForm.get('expire').reset();
  }

  getBlacklistTypeData() {
    this.blacklistService.getBlacklistTypes().subscribe(response => {
      this.blacklistTypes = response;

      // Deep copy of the response array
      for (let i = 0; i < response.length; i++) {
        this.blacklistOriginalTypes[i] = Object.assign({}, response[i]);
      }

      this.translate
        .get(['clients.civil', 'clients.employer', 'clients.phones'])
        .subscribe(translation => {
          if (this.blacklistTypes.length > 0 && this.blacklistTypes[0].id) {
            this.blacklistTypes[0].name = translation['clients.civil'];
            this.blacklistTypes[1].name = translation['clients.employer'];
            this.blacklistTypes[2].name = translation['clients.phones'];
            if (this.isNewItem) {
              this.blacklistEditForm.patchValue({
                blacklistType: this.blacklistTypes[0].id,
              });
            }
          }
        });
    });
  }

  selectedValueChanged(value?) {
    // this.blacklistEditForm.patchValue({ value: '' });
    const validators: ValidatorFn[] = [];
    this.selectedBlacklistType = this.blacklistEditForm.value.blacklistType;

    if (this.blacklistEditForm.value.blacklistType === 1) {
      validators.push(
        IdentifierValidation.onlyNumbers,
        IdentifierValidation.isValidEgn,
        Validators.minLength(10),
        Validators.maxLength(10)
      );
    } else if (this.blacklistEditForm.value.blacklistType === 2) {
      validators.push(IdentifierValidation.isValidEik);
      validators.push(Validators.minLength(0));
    } else if (this.blacklistEditForm.value.blacklistType === 3) {
      validators.push(Validators.pattern(/^\+359/));
      validators.push(Validators.minLength(12));
    }

    const valueControl: UntypedFormControl = this.formBuilder.control(
      value || '',
      validators
    );
    // this.blacklistEditForm.setControl('value', valueControl);
    this.blacklistEditForm.controls.value.setValue(this.blacklistEditData?.value || '');
    this.validators = validators;
    this.invalidValues = '';
    // if (this.isPreset) {
    //   this.blacklistEditForm.controls.value.disable();
    // }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onSubmit(event) {
    if (!this.blacklistEditForm.controls.value) {
      this.blacklistEditForm.controls.value.markAsDirty();
      this.blacklistEditForm.controls.value.markAsTouched();
    }
    if (this.blacklistEditForm.valid && this.blacklistFormArr.valid) {
      if (this.isPreset && this.blacklistEditData) {
        this.blacklistEditForm.value.blacklistType = this.blacklistEditData.blacklistType.id;
        this.blacklistEditForm.value.value = this.blacklistEditData.value;
      }

      const valuesCopy = this.blacklistFormArr.value.map(el => {
        return el = {
          blacklistType: {
            id: this.blacklistEditForm.value.blacklistType,
            name: this.getBlaclistTypeName(this.blacklistEditForm.value.blacklistType)
          },
          notes: this.blacklistEditForm.controls.notes.value.toString().trim(''),
          value: el,
          expire: this.serializedDate(this.blacklistEditForm.value.expire),
          id: this.blacklistEditData ? this.blacklistEditData.id : undefined
        };
      });
      this.blacklistService
        .saveToBlacklistBulkAdd(valuesCopy)
        .subscribe(response => {
          this.dialogRef.close(!!response);
        });
    } else {
      this.notifications.showLocalizedErrorMessage({
        notificationText: 'forms.formSavedIsInvalid',
      });
    }
  }

  createForm() {
    return this.formBuilder.group({
      blacklistType: ['', [Validators.required]],
      value: [''],
      expire: ['', []],
      notes: [''],
    });
  }

  getNewDatePlusOneYear(): Date {
    const currDate = new Date();
    const year = currDate.getFullYear();
    const month = currDate.getMonth();
    const day = currDate.getDate();
    const datePlusOneYear = new Date(year + 1, month, day);
    return datePlusOneYear;
  }

  serializedDate(inputDate: Date) {

    if (!inputDate) {
      return null;
    }
    const indexOfTheSelectedYear = inputDate.toString().indexOf('00:00:00');
    if (indexOfTheSelectedYear === -1) {
      return inputDate;
    }
    const firstHalfOfDtae = inputDate
      .toString()
      .substring(0, indexOfTheSelectedYear);

    const currentDate = new Date();
    const currentYear = currentDate.getFullYear().toString();
    const indexOfCurrentYear = currentDate.toString().indexOf(currentYear) + 4;
    const secondHalfOfDate = currentDate
      .toString()
      .substring(indexOfCurrentYear, currentDate.toString().length);
    return new Date(firstHalfOfDtae + ' ' + secondHalfOfDate);
  }

  deleteFromBlackList() {
    this.translate
      .get(
        [
          'clients.deleteRow',
          'global.confirm',
          'global.cancel',
          'global.delete',
        ],
        {
          blacklistValue: this.blacklistEditData.value,
        }
      )
      .subscribe(translation => {
        this.dialogConfig = {
          message: translation['clients.deleteRow'],
          disableClose: false,
          viewContainerRef: this._viewContainerRef,
          title: translation['global.delete'],
          cancelButton: translation['global.cancel'],
          acceptButton: translation['global.confirm'],
        };

        this._dialogService
          .openConfirm(this.dialogConfig)
          .afterClosed()
          .subscribe((accept: boolean) => {
            if (accept) {
              this.blacklistService
                .deleteInBlacklist(this.blacklistEditData.id)
                .subscribe(response => {
                  this.dialogRef.close(true);
                });
            } else {
              this.dialogRef.close();
            }
          });
      });
  }

  bulkAddPaste(event: ClipboardEvent) {
    event.preventDefault();
    this.invalidValues = '';
    const clipboardData = event.clipboardData;
    const pastedText = clipboardData.getData('text');
    const arrOfInputs = this.parseValues(pastedText);
    this.blacklistEditForm.controls.value.setValue(this.blacklistEditForm.controls.value.value + arrOfInputs.join('\n'));

    this.matchValues(arrOfInputs);
    if (this.blacklistFormArr.invalid) {
      this.setErrorValues();
    }
  }

  parseValues(text: string) {
    const creditIds = [];
    const regex = this.blacklistEditForm.value.blacklistType === 3 ? /([+]?\d+)+/gim : /(\d+)/gim;
    let match;
    do {
      match = regex.exec(text);
      if (match) {
        creditIds.push(match[1]);
      }
    } while (match);
    return creditIds;
  }

  matchValues(change) {
    this.blacklistFormArr.clear();
    change.forEach(el => this.addValueToArr(el));
  }

  addValueToArr(value) {
    const newControlForm = this.formBuilder.control(value, this.validators);
    this.blacklistFormArr.push(newControlForm);
  }

  setErrorValues() {
    const valid = [];
    const invalid = [];
    this.blacklistFormArr.controls.forEach(el => el.valid ? valid.push(el) : invalid.push(el));
    this.invalidValues = invalid.map(el => el.value).join(', ');
    if (this.blacklistFormArr.controls.length == 0) {
      this.invalidValues = this.blacklistEditForm.value.value;
    }
  }

  getBlaclistTypeName(id: string) {
    return this.blacklistOriginalTypes
      .find(el => el.id === this.blacklistEditForm.value.blacklistType).name;
  }

  ngOnDestroy(): void {
    this._unsubscribe.next();
    this._unsubscribe.complete();
  }
}
