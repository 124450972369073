<div flex layout="row" layout-align="space-between stretch" layout-wrap>
  <div
    flex
    layout="row"
    layout-align="space-between stretch"
    layout-wrap
    *ngIf="
      client?.civilId &&
      creditHistoryData?.length > 0 &&
      creditsSummary.length > 0
    "
  >
    <div flex="50" *ngFor="let summary of creditsSummary; let i = index">
      <mat-card appearance="outlined">
        <mat-card-content>
          <div flex layout="row" layout-align="space-between center" layout-wrap>
            <itfg-brand-logo [brand]="summary.brand"></itfg-brand-logo>
            <div class="flex-padding-8"
              ><span>{{ ('credits.total' | translate) + ': ' }}</span
              >{{ summary.totalCredits }}</div
            >
            <div class="flex-padding-8"
              ><span>{{ ('credits.rejectedCredits' | translate) + ': ' }}</span
              >{{ summary.rejectedCredits }}</div
            >
            <div flex layout="row" class="flex-padding-8"
              ><span>{{ ('credits.repaidCredits' | translate) + ': ' }}</span
              >{{ summary.repaidCredits }}
            </div>
            <div *ngIf="summary?.brand?.id === 1"
                 flex layout="row" layout-align="end end"
                 class="flex-padding-8">
                <mat-slide-toggle *ngIf="creditHistoryData.length >= CLIENT_CREDITS_HISTORY_LIMIT"
                                  matTooltip = "{{ 'communication.showAll' | translate }}"
                                  labelPosition="before"
                                  [checked]="isAllCreditHistoryShown"
                                  (change)="getFullClientCreditHistory($event)">
                </mat-slide-toggle>
              </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
  <div flex="5" layout="row" layout-align="center center">
    <itfg-settings-menu
    *ngIf="isExistingClient && client.civilId && isHistoryData"
    [columns]="columns"
    [sessionSettingsPageName]="pageName"
  >
  </itfg-settings-menu>
  </div>
  <div>
    <itfg-column-card
      #columnCard
      hidden
      [columns]="columns"
      [sessionSettingsPageName]="pageName"
    >
    </itfg-column-card>
  </div>
</div>
<ng-container *ngIf="isExistingClient">
  <itfg-data-table
    [data]="creditHistoryData"
    [columns]="appliedColumns ? appliedColumns : columns"
    *ngIf="creditHistoryData && creditHistoryData.length > 0"
  >
    <ng-template
      itfgDataTableTemplate="userId"
      let-userId="value"
      let-row="row"
    >
      <div [itfgHighlightDataTableRow]="row.id === currentCredit?.id"
        >
        <a [ngStyle]="{'color': userId !== client?.id ? 'red' : ''}"
        matTooltip="{{userId !== client?.id ? ('error.clientIdNotMatching' | translate) : '' }}"
        matTooltipPosition="right"
        [routerLink]="['/clients', userId]" target="_blank">{{ userId }}</a>
      </div>
    </ng-template>
    <ng-template itfgDataTableTemplate="creditId" let-value="value">
      <a [routerLink]="['/credits', value]" target="_blank">{{ value }}</a>
    </ng-template>
    <ng-template itfgDataTableTemplate="status" let-status="value">
      <itfg-credit-status-icon
        [creditStatus]="status"
        [showName]="true"
      ></itfg-credit-status-icon>
    </ng-template>
    <ng-template itfgDataTableTemplate="tags" let-tags="value">
      <itfg-tags-table-cell-view [tags]="tags"></itfg-tags-table-cell-view>
    </ng-template>
    <ng-template itfgDataTableTemplate="createTime" let-createTime="value">
      <itfg-date-format [date]="createTime"></itfg-date-format>
    </ng-template>
    <ng-template itfgDataTableTemplate="contractTime" let-value="value">
      <itfg-date-format [date]="value"></itfg-date-format>
    </ng-template>
    <ng-template itfgDataTableTemplate="repaymentDate" let-value="value">
      <itfg-date-format [date]="value"></itfg-date-format>
    </ng-template>
    <ng-template
      itfgDataTableTemplate="overdueDays"
      let-value="value"
      let-row="row"
    >
      <span
        matTooltip="{{ 'credits.currMax' | translate }}"
        matTooltipPosition="above"
        >{{ value }} / {{ row.maxOverdueDays }}</span
      >
    </ng-template>
    <ng-template itfgDataTableTemplate="repaidAmount" let-principal="value">
      <span>{{ principal | toCustomItfgCurrency }}</span>
    </ng-template>
    <ng-template
      itfgDataTableTemplate="brand"
      let-value="value"
      let-row="row"
      let-column="column"
    >
      <itfg-brand-logo [brand]="value"></itfg-brand-logo>
    </ng-template>
    <ng-template
      itfgDataTableTemplate="refinanceReference"
      let-value="value"
      let-row="row"
      let-column="column"
    >
      <mat-icon
        *ngIf="!value"
        matTooltip="{{ 'global.no' | translate }}"
        matTooltipPosition="above"
        >remove
      </mat-icon>
      <mat-icon
        *ngIf="value"
        matTooltip="{{ value }}"
        matTooltipPosition="above"
        >done
      </mat-icon>
    </ng-template>
    <ng-template itfgDataTableTemplate="principal" let-principal="value">
      <span>{{ principal | toCustomItfgCurrency }}</span>
    </ng-template>
    <ng-template
      itfgDataTableTemplate="installmentAmountWithDiscount"
      let-installmentAmountWithDiscount="value"
    >
      <span>{{ installmentAmountWithDiscount | toCustomItfgCurrency }}</span>
    </ng-template>
    <ng-template
    itfgDataTableTemplate="installmentDays"
    let-installmentDays="value"
  >
    <span>{{ installmentDays | creditInstalmentPeriod }}</span>
  </ng-template>
  </itfg-data-table>

  <h3
    *ngIf="client && !client.civilId && isExistingClient"
    flex
    layout="row"
    layout-align="center center"
    [translate]="'credits.noCivilId'"
  >
    The Client doest have civil id!
  </h3>
  <h3
    *ngIf="!isExistingClient"
    flex
    layout="row"
    layout-align="center center"
    [translate]="'clients.notExistingClient'"
    [translateParams]="{ clientId: client.id }"
  ></h3>
  <h3
    *ngIf="!isHistoryData && isExistingClient && client.civilId"
    flex
    layout="row"
    layout-align="center center"
    [translate]="'clients.noClientCreditsHistory'"
  >
    no client credit history data
  </h3>
</ng-container>
