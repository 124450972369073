import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { NssiReportComponent } from '../nssi-report/nssi-report.component';
import { IdentityReportsService } from '../../../../app/core/services/identity-reports.service';
import { UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ErrorService } from '../../../../app/core/services/error.service';
import { ClientService } from '../../../../app/core/services/client.service';
import { TranslateService } from '@ngx-translate/core';
import { IdentifierValidation } from '../../../../app/core/validation/identifier-validation';
import { DialogProviderService } from '@app/core/services/dialog-provider.service';
import {CreditService} from '@core/services/credit.service';
import {NotificationService} from '@core/services/notification.service';

@Component({
  selector: 'itfg-nssi-standalone',
  templateUrl: './nssi-standalone.component.html',
  styleUrls: ['./nssi-standalone.component.scss']
})
export class NssiStandaloneComponent extends NssiReportComponent implements OnInit {

  civilId: UntypedFormControl = new UntypedFormControl(null, [
    Validators.minLength(0),
    Validators.maxLength(10),
    IdentifierValidation.onlyNumbers,
    IdentifierValidation.isValidEgn,
  ]);

  showTable = false;
  client: any = {
    id: 1,
    civilId: null,
  };

  isReport = true;

  constructor(
    identityReportsService: IdentityReportsService,
    formBuilder: UntypedFormBuilder,
    notificationService: NotificationService,
    route: ActivatedRoute,
    clientService: ClientService,
    creditService: CreditService,
    errorService: ErrorService,
    translate: TranslateService,
    dialogService: DialogProviderService,
    _viewContainerRef: ViewContainerRef
  ) {
    super(
      identityReportsService,
      formBuilder,
      notificationService,
      route,
      clientService,
      creditService,
      errorService,
      translate,
      dialogService,
      _viewContainerRef,
    );
  }

  ngOnInit(): void {
    this.civilId.valueChanges.pipe(
      // filter(value => this.civilId.valid === true)
    ).subscribe(value => {
      if (this.civilId.valid === true && !!value) {
        this.showTable = true;
        this.client = {
          ...this.client,
          civilId: value,
        };
      } else {
        this.showTable = false;
      }
    });
  }

}
