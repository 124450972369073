import { Pipe, PipeTransform } from '@angular/core';
import {IdCardVerificationName, IdCardVerificationOptions} from '../../../core/types/id-card-verified';
import {TranslateService} from '@ngx-translate/core';

@Pipe({
  name: 'clientIdVerificationTypeToTranslationKey'
})
export class ClientIdVerificationTypeToTranslationKeyPipe implements PipeTransform {
  static readonly idCardVerificationTranslationMap: { [key: string]: string } = {
    [IdCardVerificationName.VALID]: 'global.valid',
    [IdCardVerificationName.INVALID]: 'global.invalid',
    [IdCardVerificationName.UNVERIFIED]: 'global.unverified',
  };
  idCardVerificationOptionsList: IdCardVerificationOptions;

  constructor(private translateService: TranslateService) {
    this.idCardVerificationOptionsList = new IdCardVerificationOptions();

  }
  transform(value: any, args?: any[]): any {

    const idCardVerificationType = this.idCardVerificationOptionsList.optionsList.filter(
      option => option.value === value
    )[0];
    const translationValue = ClientIdVerificationTypeToTranslationKeyPipe.idCardVerificationTranslationMap[idCardVerificationType.name]
     return this.translateService
       .instant(translationValue);
     }

}
