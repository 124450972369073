export enum PbxEvents {
  LOG_REQ = 'log_req',
  LOG_RES = 'log_res',
  LOG_NEW = 'log_new',
  NOTIF_REQ = 'notif_req',
  NOTIF_RES = 'notif_res',
  NOTIF_NEW = 'notif_new',
  CALL_RINGING = 'call_ringing',
  CALL_ONGOING = 'call_ongoing',
  CALL_IN_PROGRESS = 'call_in_progress',
  CALL_ENDED = 'call_ended',
  NOTE_ADDED = 'note_added',
  NOTE_EDITED = 'note_edited',
  CALLBACK_REMINDER_ADDED = "callback_reminded_added",
  CHECK_ONGOING_CALL = 'check_ongoing_call',
  CALL_CREATED = 'call_created',
  CHECK_CALL_STAGE = 'check_call_stage',
  CALL_RECORD_CREATE = 'call_record_create',
  CALL_RECORD_UPDATE = 'call_record_update',

  // CH -- CallHub
  CH_CALLS = 'ch_calls',
  CH_CALL_CREATED = 'ch_call_created',
  CH_NEW_CALL = 'ch_new_call',
  CH_UPDATED_CALL = 'ch_updated_call',
}
