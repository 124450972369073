import { Directive, ElementRef, Input, OnInit, Renderer2, HostListener } from "@angular/core";
import { MatTooltip } from "@angular/material/tooltip";
import { TranslateService } from "@ngx-translate/core";
import { BondsterTaskMeta } from "../../../../src/app/core/types/bondster-tasks";

@Directive({
  selector: '[metaVisualization]',
  providers: [MatTooltip]
})
export class BondsterMetaVisualizationDirective implements OnInit {

  @Input('metaVisualization') meta: BondsterTaskMeta;
  public tooltipText: string;

  constructor(
    private el: ElementRef, 
    private renderer: Renderer2, 
    private tooltip: MatTooltip, 
    private translate: TranslateService) {}

  ngOnInit() {
    const metaCount = this.renderer.createText(`${this.meta.count}`);
    this.renderer.appendChild(this.el.nativeElement, metaCount);

    let fullToolTipText = '';
    let hasError = false;
    const duplicatesTranslate = this.translate.instant('p2p.tasks.meta.duplicates');
    const errorsTranslate = this.translate.instant('p2p.tasks.meta.errors');
    const excludedTranslate = this.translate.instant('p2p.tasks.meta.excluded');

    if (this.meta.duplicates > 0) {
      fullToolTipText += (fullToolTipText ? ', ' : '') + `${duplicatesTranslate}: ${this.meta.duplicates}`;
      hasError = true;
    } 

    if (this.meta.errors > 0) {
      fullToolTipText += (fullToolTipText ? ', ' : '') + `${errorsTranslate}: ${this.meta.errors}`;
      hasError = true;
    } 

    if (this.meta.excluded > 0) {
      fullToolTipText += (fullToolTipText ? ', ' : '') + `${excludedTranslate}: ${this.meta.excluded}`;
      hasError = true;
    }

    this.tooltipText = fullToolTipText;

    if (hasError) {
      this.renderer.addClass(this.el.nativeElement, 'meta-error');
    }

  }

  @HostListener('mouseover') mouseover() {
    this.tooltip.message = this.tooltipText;
    this.tooltip.position = "above";
    this.tooltip.show();
  }

  @HostListener('mouseleave') mouseleave() {
    this.tooltip.hide();
}
}