<div *ngIf="data.title"
     class="modal-dialog-title">
  <p>{{ data.title }}</p>
</div>

<div class="mat-dialog-content">
  <p class="dialog-message">
    <span [innerHtml]="data.message"></span>
  </p>
</div>

<div layout="row"
     layout-align="end center">
  <button mat-button
          [mat-dialog-close]="false"
          cdkFocusInitial>{{ data.closeButton || ('global.cancel'|translate) }}</button>
</div>
