import { CurrencyPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyFormat } from '../../../core/types/currency-format';
@Pipe({
  name: 'toCustomItfgCurrency',
})
export class ToCustomItfgCurrencyPipe extends CurrencyPipe implements PipeTransform {
  transform(
    number?: number | string, 
    currencyCode: string = CurrencyFormat.LEV,
    display?: 'code' | 'symbol' | 'symbol-narrow' | string | boolean,
    digitsInfo: string = '1.2-2', 
    locale?: string
  ): any {
    if (number === null || number === undefined || Number.isNaN(number)) {
      return null;
    }

    const result = super.transform(Number(number), currencyCode, display, digitsInfo, locale);
    return result ? result.replace(',', '.') : '';
  }
}
