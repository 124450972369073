import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {DataForeignCitizen, IDCardVerifiedTypes, IdentityReport, MIReportData} from '../../../../core/types/identity-reports';
import {Utils} from '../../../../core/utils/utils';

@Component({
  selector: 'itfg-mi-card',
  templateUrl: './mi-card.component.html',
  styleUrls: ['./mi-card.component.scss']
})
export class MiCardComponent implements OnInit, OnChanges {

  @Input() isReport: boolean;
  @Input() isClientDataMatch: boolean;
  @Input() civilId: number;
  @Input() idCardNumber: number;
  @Input() reportData: IdentityReport<MIReportData>;
  @Input() clientReportData: MIReportData;
  @Input() selectedIdCardFront: any;
  @Output() scrollToClientMapper: EventEmitter<void> = new EventEmitter<void>();
  @Output() idCardVerificationHovered: EventEmitter<boolean> = new EventEmitter<boolean>();

  public IDCardVerifiedType: typeof IDCardVerifiedTypes = IDCardVerifiedTypes;
  public civilIdAge: number;
  public civilIdGender: string;
  public requestTime: Date;

  public dataForeignCitizen: DataForeignCitizen;

  constructor() { }

  ngOnInit(): void {
  }
  ngOnChanges(changes: SimpleChanges) {
    if (this.civilId) {
      this.civilIdGender = Utils.getClientGenderFromCivilId(this.civilId);
      this.civilIdAge = Utils.calculateClientAgeFromCivilId(this.civilId);
    }
    if (this.clientReportData) {
      this.dataForeignCitizen = {};
      this.parseClientData(this.clientReportData);
    }
  }
  parseClientData(clientReportData) {

    if (clientReportData) {
      this.requestTime = new Date(this.reportData.time);
      if (clientReportData.NationalityList.length > 0) {
        const nationality = clientReportData.NationalityList[0];
        const secondNationality = clientReportData.NationalityList[1] ? clientReportData.NationalityList[1] : null;
        this.clientReportData.Nationality = nationality;
        this.clientReportData.SecondNationality = secondNationality;
      }

      let settlementNameParts = [];
      let additionalLocationName = '';

      if (clientReportData.PermanentAddress && clientReportData.PermanentAddress.SettlementName) {

        settlementNameParts = clientReportData.PermanentAddress.SettlementName.split(',');

        if (settlementNameParts.length > 1) {
          clientReportData.PermanentAddress.SettlementName = settlementNameParts[0];
          additionalLocationName = settlementNameParts.pop();
        }
      }



      this.dataForeignCitizen = clientReportData.DataForeignCitizen;

      this.clientReportData.IssuerName = clientReportData.IssuerName.split('/')[0];

      if (clientReportData.PersonNames === '') {
        this.clientReportData.PersonNames = {};
      }

      if (clientReportData.PersonNames.FirstName) {
        this.clientReportData.PersonNames.FirstName = this.capitalizeValue(clientReportData.PersonNames.FirstName);
      } else if (this.dataForeignCitizen.Names.FirstName) {
        this.clientReportData.PersonNames.FirstName = this.capitalizeValue(this.dataForeignCitizen.Names.FirstName);
      }

      if (clientReportData.PersonNames.Surname) {
        this.clientReportData.PersonNames.Surname = this.capitalizeValue(clientReportData.PersonNames.Surname);
      } else if (this.dataForeignCitizen.Names.Surname) {
        this.clientReportData.PersonNames.Surname = this.capitalizeValue(this.dataForeignCitizen.Names.Surname);
      }

      if (clientReportData.PersonNames.FamilyName) {
        this.clientReportData.PersonNames.FamilyName = this.capitalizeValue(clientReportData.PersonNames.FamilyName);
      } else if (this.dataForeignCitizen.Names.FamilyName) {
        this.clientReportData.PersonNames.FamilyName = this.capitalizeValue(this.dataForeignCitizen.Names.FamilyName);
      }

      let address = clientReportData.PermanentAddress.LocationName;

      if (additionalLocationName !== '') {
        address = additionalLocationName + ', ' + address;
      }

      if (clientReportData.PermanentAddress.BuildingNumber) {
        const buildingNumber = ', No ' + clientReportData.PermanentAddress.BuildingNumber;
        address += buildingNumber;
      }
      if (clientReportData.PermanentAddress.Entrance) {
        const entrance = ', вх. ' + clientReportData.PermanentAddress.Entrance;
        address += entrance;
      }
      if (clientReportData.PermanentAddress.Floor) {
        const floor = ', ет. ' + clientReportData.PermanentAddress.Floor;
        address += floor;
      }
      if (clientReportData.PermanentAddress.Apartment) {
        const apartment = ', ап. ' + clientReportData.PermanentAddress.Apartment;
        address += apartment;
      }

      this.clientReportData.IssueDate = clientReportData.IssueDate.substring(0, 10);
      this.clientReportData.address = address;
    }
  }

  capitalizeValue(value) {
    const valueParts = value.split('-');
    let valueToCapitalCase = '';

    valueToCapitalCase = valueParts[0].trim().substring(0, 1).toUpperCase() + valueParts[0].substring(1).toLowerCase();
    if (valueParts.length > 1) {
      const valueAditionalPartCapitalCase = '-' + valueParts[1].trim().substring(0, 1).toUpperCase() + valueParts[1].substring(1).toLowerCase();
      valueToCapitalCase += valueAditionalPartCapitalCase;
    }
    return valueToCapitalCase;
  }
  scrollToClient() {
    this.scrollToClientMapper.emit();
  }

  mouseEnter() {
    this.idCardVerificationHovered.emit(true);
  }

  mouseLeave() {
    this.idCardVerificationHovered.emit(false);
  }
}
