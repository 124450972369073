import { Component, OnInit, Inject, ViewContainerRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { InteractionMode } from '@app/core/types/interaction-mode';
import { Note } from '@app/core/types/note';
import { NoteMode } from '../note/note.types';

@Component({
  selector: 'itfg-edit-note-dialog',
  templateUrl: './edit-note-dialog.component.html',
  styleUrls: ['./edit-note-dialog.component.scss'],
})
export class EditNoteDialogComponent implements OnInit {
  public notes: Note[];
  public interactionMode: InteractionMode;
  public noteModes: typeof NoteMode = NoteMode;

  NoteMode = NoteMode;

  constructor(
    public dialogRef: MatDialogRef<EditNoteDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { notes: Note[]; interactionMode: InteractionMode },
    public _viewContainerRef: ViewContainerRef
  ) {
    this.notes = data.notes;
    this.interactionMode = data.interactionMode;
  }

  ngOnInit() {}

  onNoClick(): void {
    this.dialogRef.close();
  }
}
