import { Subscription, Subject } from 'rxjs';
import { Directive, OnInit, OnDestroy } from '@angular/core';
import { MatDialogContainer, MatDialogRef } from '@angular/material/dialog';
import { takeUntil, take } from 'rxjs/operators';
import { ModalPositionCache, Position } from '../cache/modal-position.cache';

/**
 * Saves the cordinates of a MatDialog Container on close
 * and retrieves its position upon reopening
 */
@Directive({
  selector: '[itfgMatDialogRestorePosition]',
})
export class MatDialogRestorePositionDirective implements OnInit, OnDestroy {
  private _subscription: Subscription;
  _unsubscribe: Subject<void> = new Subject<void>();
  mouseStart: Position;
  mouseDelta: Position;
  offset: Position;

  constructor(
    private matDialogRef: MatDialogRef<any>,
    private container: MatDialogContainer,
    private positionCache: ModalPositionCache
  ) {}

  ngOnInit() {
    const dialogType = this.matDialogRef.componentInstance.constructor;
    const cachedValue = this.positionCache.get(dialogType);
    this.offset = cachedValue || this._getOffset();
    this._updatePosition(this.offset.y, this.offset.x);
    this.matDialogRef
      .beforeClosed()
      .pipe(
        take(1),
        takeUntil(this._unsubscribe)
      )
      .subscribe(() => {
        this.positionCache.set(dialogType, this._getOffset());
      });
  }

  private _getOffset(): Position {
    const box = this.container[
      '_elementRef'
    ].nativeElement.getBoundingClientRect();
    return {
      x: box.left + window.pageXOffset,
      y: box.top + window.pageYOffset,
    };
  }

  private _updatePosition(top: number, left: number) {
    this.matDialogRef.updatePosition({
      top: top + 'px',
      left: left + 'px',
    });
  }

  ngOnDestroy() {
    this._unsubscribe.next();
    this._unsubscribe.complete();
  }
}
