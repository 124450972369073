import { forkJoin as observableForkJoin, Observable, Subject } from 'rxjs';

import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { RequestService } from './request.service';
import { Page } from '../types/page';
import {
  ForfeitAccrual,
  AccrualBase,
  AccrualComponentType,
  InterestAccrual,
  OverduePenaltyAccrual,
  Accrual,
  TaxAccrual,
  TaxAccrualType,
  UtilizationFeeAccrual,
} from '../types/accrual';
import { SearchOptions, SearchOptionsDTO } from '../types/search-options';
import { SearchOperations } from '../types/search-criterium';
import { Decimal } from '../utils/decimal';

@Injectable()
export class CreditAccrualsService {
  public accrualUpdated: Subject<boolean> = new Subject<boolean>();

  public accrualConfig = {
    [AccrualComponentType.TAX]: {
      API_URL: 'taxes',
      camelCaseName: 'taxes',
    },
    [AccrualComponentType.FORFEIT]: {
      API_URL: 'forfeits',
      camelCaseName: 'forfeits',
    },
    [AccrualComponentType.INTEREST]: {
      API_URL: 'interests',
      camelCaseName: 'interests',
    },
    [AccrualComponentType.OVERDUE_PENALTY]: {
      API_URL: 'overdue-penalties',
      camelCaseName: 'overduePenalties',
    },
    [AccrualComponentType.UTILIZATION_FEE]: {
      API_URL: 'utilization-fees',
      camelCaseName: 'utilizationFee',
    },
  };

  constructor(private request: RequestService) {}

  saveCreditAccrualByComponentType(
    accrualType: AccrualComponentType,
    payload: AccrualBase
  ): Observable<AccrualBase> {
    return this.request.post(
      ['accruals', this.accrualConfig[accrualType].API_URL],
      {
        body: payload,
      }
    );
  }

  getForfeitAccrualsList(
    options: SearchOptionsDTO
  ): Observable<Page<ForfeitAccrual>> {
    return this.request.get(['accruals', 'forfeits'], { params: options });
  }

  getInterestAccrualsList(
    options: SearchOptionsDTO
  ): Observable<Page<InterestAccrual>> {
    return this.request.get(['accruals', 'interests'], { params: options });
  }

  getUtilizationFeeCorrectionList(options: SearchOptionsDTO): Observable<Page<UtilizationFeeAccrual>> {
    return this.request.get(['accruals', 'utilization-fees'], { params: options });
  }

  getOverduePenaltyAccrualsList(
    options: SearchOptionsDTO
  ): Observable<Page<OverduePenaltyAccrual>> {
    return this.request.get(['accruals', 'overdue-penalties'], {
      params: options,
    });
  }

  getTaxAccrualList$(options: SearchOptionsDTO): Observable<Page<TaxAccrual>> {
    return this.request.get(['accruals', 'taxes'], { params: options });
  }

  getTaxAccrualTypes$(options?: SearchOptions): Observable<TaxAccrualType[]> {
    return this.request.get(['accruals', 'taxes', 'types']);
  }

  getTaxAccrualTotal$(
    id: number | string,
    options?: SearchOperations
  ): Observable<number> {
    return this.request.get(['accruals', 'taxes', 'total'], {
      body: { creditId: id },
    });
  }

  getAggregateAccrualsList(
    selectedComponents: AccrualComponentType[],
    creditId,
    installmentId,
    searchOptions?: SearchOptions
  ): Observable<AccrualBase[]> {
    const accrualsList$ = [];
    console.log({selectedComponents})
    for (const component of selectedComponents) {
      const options = new SearchOptions({ pageSize: 1000 });
      if (searchOptions) {
        options.criteria = [...searchOptions.criteria];
      }

      if (component === AccrualComponentType.TAX) {
        options.addCriterium({
          key: 'credit.id',
          operation: SearchOperations.EQUALS,
          value: creditId,
        });
      } else {
        options.addCriterium({
          key: installmentId ? 'installment.id' : 'installment.credit.id',
          operation: SearchOperations.EQUALS,
          value: installmentId ? installmentId : creditId,
        });
      }

      switch (component) {
        case AccrualComponentType.TAX:
          accrualsList$.push(this.getTaxAccrualList$(options.getDTO()));
          break;
        case AccrualComponentType.FORFEIT:
          accrualsList$.push(this.getForfeitAccrualsList(options.getDTO()));
          break;
        case AccrualComponentType.INTEREST:
          accrualsList$.push(this.getInterestAccrualsList(options.getDTO()));
          break;
        case AccrualComponentType.OVERDUE_PENALTY:
          accrualsList$.push(
            this.getOverduePenaltyAccrualsList(options.getDTO())
          );
          break;
        case AccrualComponentType.UTILIZATION_FEE:
          accrualsList$.push(
            this.getUtilizationFeeCorrectionList(options.getDTO())
          );
          break;
      }
    }

    return observableForkJoin([...accrualsList$]).pipe(
      map((accrualsPages: Array<Page<Accrual>>) => {
        let aggregatedList = [];

        accrualsPages.forEach((page, index) => {
          const currentComponentName = this.accrualConfig[
            selectedComponents[index]
          ].camelCaseName;

          page.content.forEach((accrual: Accrual) => {
            const getTargetAccrual = () => {
              return aggregatedList.find(a => a.id === accrual.id);
            };
            let targetAccrual = getTargetAccrual();

            if (!targetAccrual) {
              const newAccrual = {
                id: accrual.id,
                installment: accrual.installment,
                key: accrual.date,
                total: 0,
                createdBy: accrual.createdBy,
                createdAt: accrual.createdAt,
                taxType: accrual.type,
                note: accrual.note,
                reference: accrual.reference
              };
              aggregatedList.push(newAccrual);
            }

            targetAccrual = getTargetAccrual();

            if (!targetAccrual[currentComponentName]) {
              targetAccrual[currentComponentName] = {
                amount: 0,
                notes: [],
              };
            }
            if (accrual.note) {
              targetAccrual[currentComponentName].notes.push(accrual.note);
            }
            const res = new Decimal(
              targetAccrual[currentComponentName].amount
            ).plus(new Decimal(accrual.amount));
            targetAccrual[currentComponentName].amount = Number.parseFloat(
              res.toPrecision(5)
            );
            const accTotal: Decimal = new Decimal(targetAccrual.total).plus(
              new Decimal(accrual.amount)
            );
            targetAccrual.total = Number.parseFloat(accTotal.toPrecision(5));
          });
        });

        const total = aggregatedList.map(a => a.total);

        const totalRow = {
          key: 'total',
          total: total.reduce(
            (a, b) =>
              Number.parseFloat(
                new Decimal(a).plus(new Decimal(b)).toPrecision(5)
              ),
            0
          ),
        };

        // Calculate total for each component
        selectedComponents.forEach((component, index) => {
          const currentComponentName = this.accrualConfig[
            selectedComponents[index]
          ].camelCaseName;
          const mappedAmountsForComponent: number[] = aggregatedList
            .filter(e => !!e[currentComponentName])
            .map(a => a[currentComponentName].amount);
          totalRow[currentComponentName] = {
            amount:
              mappedAmountsForComponent && mappedAmountsForComponent.length > 0
                ? mappedAmountsForComponent.reduce(
                    (a, b) =>
                      Number.parseFloat(
                        new Decimal(a).plus(new Decimal(b)).toPrecision(5)
                      ),
                    0
                  )
                : null,
          };
        });

        // Sort list by date ascending
        aggregatedList = aggregatedList
          .sort((a, b) => (a.key < b.key ? 1 : b.key < a.key ? -1 : 0))
          .reverse();
        if (aggregatedList.length > 0) {
          aggregatedList.push(totalRow);
        }

        console.log({
          aggregatedList
        })
        return aggregatedList;
      })
    );
  }
}
