import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { UpdateClientComponent } from '../../update-client/update-client.component';
import { IdentityReportsService } from '../../../../core/services/identity-reports.service';
import { filter, takeUntil } from 'rxjs/operators';
import { ReportConfig } from '../../../../core/types/identity-reports';
import { UpdateDataRowService } from '../../../../core/services/update-data-row.service';
import { DialogProviderService } from '@app/core/services/dialog-provider.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'itfg-client-differences-button',
  templateUrl: './client-differences-button.component.html',
  styleUrls: ['./client-differences-button.component.scss']
})
export class ClientDifferencesButtonComponent implements OnInit, OnChanges, OnDestroy {
  @Input() config: ReportConfig;

  public updateData: any;
  public dataRows: any;
  public warn: boolean = false;
  public info: boolean = false;
  _unsubscribe: Subject<void> = new Subject<void>();


  constructor(
    private dataRowService: UpdateDataRowService,
    private dialogService: DialogProviderService,
  ) {
  }

  ngOnInit() {
  }

  ngOnChanges() {
    if (this.config) {
      this.config.reportData = this.config.processReportData ? this.config.processReportData(this.config.reportData) : this.config.reportData;
      this.updateData = this.dataRowService.mapTempValues(
        this.config?.client,
        this.config?.reportData,
        this.config?.clientMapper,
        this.config?.notUpdatableProperties,
      )

      this.dataRows = this.updateData.dataRows;

      this.warn = this.dataRows.some(row =>
        this.config.criticalProperties.includes(row.key) && !row.isReportEqualClient
      );

      this.info = this.dataRows.some(row =>
        !this.config.criticalProperties.includes(row.key) && !row.isReportEqualClient
      );
    }
  }

  updateClientData() {
    this.dialogService
      .open(UpdateClientComponent, {
        width: '800px',
        restoreFocus: false,
        autoFocus: false,
        data: {
          client: this.config?.client,
          clientData: this.config?.reportData,
          CLIENT_MAPPER: this.config?.clientMapper,
          updateData: this.updateData,
          notUpdatableProperties: this.config?.notUpdatableProperties,
        },
      })
      .afterClosed()
      .pipe(
        takeUntil(this._unsubscribe),
        filter(res => !!res)
      )
      .subscribe((response: any) => {
      });
  }

  ngOnDestroy() {
    this._unsubscribe.next();
    this._unsubscribe.complete();
  }
}
