import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FilterValueListService, FilterValue } from '../filter/types';
import { AdministratorService } from '../../core/services/administrator.service';
import { Administrator } from '../../core/types/administrator';
import { Page } from '../../core/types/page';
import { SearchOptions } from '../../../app/core/types/search-options';
import { SearchOperations } from '../../../app/core/types/search-criterium';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class AgentValueListService implements FilterValueListService {
  constructor(private administrators: AdministratorService, private translate: TranslateService) {}

  getFilterValues$(startWith = ''): Observable<FilterValue[]> {
    const options = new SearchOptions({
      pageSize: 6000,
      criteria: [
        {
          key: 'firstName',
          operation: SearchOperations.LIKE,
          value: startWith,
        },
        {
          key: 'office.zone.brand.id',
          operation: SearchOperations.EQUALS,
          value: 1,
        },
        {
          key: 'active',
          operation: SearchOperations.EQUALS,
          value: true,
        },
      ],
    });

    return this.administrators.getAdministratorList(options.getDTO()).pipe(
      map(
        (administratorPage: Page<Administrator>): Administrator[] =>
          administratorPage.content
      ),
      map((administratorList: Administrator[]): FilterValue[] => {
        const filteredAdministrators = administratorList.filter(administrator => !!administrator.phoneExtension);

        const filterValues: FilterValue[] = filteredAdministrators.sort((a, b) =>
          a.firstName < b.firstName ? -1 : a.firstName > b.firstName ? 1 : 0
        ).map(
          (administrator: Administrator): FilterValue => ({
            value: Number(administrator.id),
            displayText: `${administrator.firstName} ${administrator.lastName} (${administrator.phoneExtension})`,
          })
        );

        // Add missing agent option at the end
        // filterValues.unshift({
        //   value: null,
        //   displayText: this.translate.instant('filtering.missingAgent')
        // });

        return filterValues;
      })
    );
  }
}
