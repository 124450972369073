<div flex layout="column">
  <div flex layout-align="space-between center" layout="row">
    <div
      class="modal-dialog-title"
      [translate]="'communication.emailPreview'"
      flex
    >
      Email preview
    </div>
    <button tabindex="-1" mat-icon-button (click)="close()">
      <mat-icon>clear</mat-icon>
    </button>
  </div>
  <div flex layout-align="space-between center" layout="row">
    <mat-form-field flex="33">
      <input
        matInput
        readonly
        type="text"
        [value]="data?.email?.from || ''"
        placeholder="{{ 'global.from' | translate }}"
        flex="100"

      />
      <mat-label>{{ 'global.from' | translate }}</mat-label>
    </mat-form-field>
    <mat-form-field flex="30">
      <input
        matInput
        readonly
        type="text"
        [value]="data?.email?.to || ''"
        placeholder="{{ 'global.to' | translate }}"
        flex="100"
      />
      <mat-label>{{ 'global.to' | translate }}</mat-label>
    </mat-form-field>
    <mat-form-field flex="30">
      <input
        matInput
        readonly
        type="text"
        [value]="data?.email?.subject || ''"
        placeholder="{{ 'global.subject' | translate }}"
        flex="100"

      />
      <mat-label>{{ 'global.subject' | translate }}</mat-label>
    </mat-form-field>
  </div>
  <angular-editor
    [config]="editorConfig"
    [(ngModel)]="data.email.body"
  ></angular-editor>
</div>
