import { FilterValueListService, FilterValue } from '../filter/types';
import { Observable, of as observableOf } from 'rxjs';
import { Injectable } from '@angular/core';
import { TransactionService } from '../../../app/core/services/transaction.service';
import { TransactionStateType } from '../../../app/core/types/transaction';
import { map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class TransactionStateValueListService
  implements FilterValueListService {
  constructor(
    private transaction: TransactionService,
    private translate: TranslateService
  ) {}

  getFilterValues$(): Observable<FilterValue[]> {
    return observableOf(Object.values(TransactionStateType)).pipe(
      map((states: string[]): FilterValue[] => {
        return states.map(
          (stateName: string): FilterValue => {
            return {
              value: stateName,
              displayText: this.translate.instant(
                'transaction.state.' + stateName
              ),
            };
          }
        );
      })
    );
  }
}
