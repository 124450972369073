<button mat-icon-button
          *ngIf="config && config.reportData && warn"
          matTooltip="{{ 'identityReports.clientDataDoNotMatch' | translate }}"
          matTooltipPosition="above"
          [disableRipple]="true"
          (click)="updateClientData()">
    <mat-icon class="itfg-warn">person</mat-icon>
  </button>
  <button mat-icon-button
          *ngIf="config && config.reportData && !warn && info"
          matTooltip="{{ 'identityReports.clientDataDoNotMatch' | translate }}"
          matTooltipPosition="above"
          [disableRipple]="true"
          (click)="updateClientData()">
    <mat-icon class="color-orange">person</mat-icon>
  </button>
