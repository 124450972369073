import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FilterValueListService, FilterValue } from '../filter/types';
import { OfficeService } from '../../core/services/office.service';
import { Office } from '../../core/types/office';
import { Page } from '../../core/types/page';

@Injectable()
export class OfficeValueListService implements FilterValueListService {
  constructor(private offices: OfficeService) {}

  getFilterValues$(): Observable<FilterValue[]> {
    return this.offices.getOfficeList$().pipe(
      map((officesPage: Page<Office>): Office[] => officesPage.content),
      map((officeList: Office[]): FilterValue[] => {
        return officeList.map(
          (office: Office): FilterValue => {
            return {
              value: office.id,
              displayText: office.name,
            };
          }
        );
      })
    );
  }
}
