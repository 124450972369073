import { Administrator } from './administrator';
import { ITFGAccount } from './account';
import { Credit } from './credit';
import { ItfgDataTableColumn } from '@app/core/components/data-table/types/data-table.column';

export interface Transaction {
  amount?: number;
  credit?: Credit;
  description?: string;
  destination?: ITFGAccount;
  id?: number;
  operator?: Administrator;
  reference?: string;
  source?: ITFGAccount;
  state?: TransactionState;
  type?: TransactionType;
  time?: string;
  paymentDate?: string;
  direction?: TransactionDirection;
}

export enum TransactionDirection {
  incoming = 'incoming',
  outgoing = 'outgoing',
}

export interface TransactionState {
  id?: number;
  name?: TransactionStateType;
}

export interface TransactionStatus {
  id: number;
  name?: string;
}

export interface TransactionType {
  id: number;
  name?: string;
}

export interface PayinTransactionDto {
  amount: number;
  creditId: number;
  description: string;
  destinationAccountId: number;
  intermediary: boolean;
  paymentDate?: string;
  reference: string;
}

export interface PayinExtraExpenseDto {
  amount: number;
  creditId: number;
  description: string;
}

export interface CessionTransactionDto {
  description: string;
  creditId: number;
}

export interface PayoutTransactionDto {
  creditId: number;
  description: string;
  intermediary: boolean;
  reference: string;
  sourceAccountId: number;
}

export interface RevertTransactionDto {
  description?: string;
  paymentDate?: string;
  transactionId: number;
}

export interface RevertExtraIncomeTransactionDto {
  description: string;
  sourceAccountId: number;
  transactionId: number;
}

export interface ExtraIncomeTransactionDto {
    amount: number,
    creditId: number,
    description: string,
    destinationAccountId: number,
    intermediary: boolean;
    paymentDate?: string;
    reference: string;
}

export enum TransactionRevertFormMode {
  OUTGOING = 'OUTGOING',
  INCOMING = 'INCOMING',
  EXTRA_INCOME = 'EXTRA_INCOME',
}

export enum TransactionMode {
  PAY_IN = 'PAY-IN',
  PAY_OUT_REFINANCE = 'PAY-OUT-REFINANCE',
  PAY_OUT = 'PAY-OUT',
  DISABLED = 'DISABLED',
  EXTRA_INCOME = 'EXTRA-INCOME'
}

export enum TransactionTypeNames {
  CORRECTION = 'CORRECTION',
  EXTRA_INCOME = 'EXTRA_INCOME',
  EXTRA_EXPENSE = 'EXTRA_EXPENSE',
  INTERNAL_TRANSFER = 'INTERNAL_TRANSFER',
  CREDIT = 'CREDIT',
  REFINANCE = 'REFINANCE',
}

export enum TransactionStateType {
  SENT = 'SENT',
  PROCESSING = 'PROCESSING',
  COMPLETED = 'COMPLETED',
  CANCELED = 'CANCELED',
}

export interface TransactionParamsComponentDialogData {
  transaction: Transaction;
  columns: ItfgDataTableColumn[];
}
