<!-- [translate]="'communication.emailPreview'" -->
<div flex layout-align="space-between center" layout="row">
  <div
    class="modal-dialog-title"
    flex
  >
    {{'clientRelations.company.companyPhonesTitle' |translate: { EIK:data.identifier } }}
  </div>

  <button
   tabindex="-1"
   mat-icon-button
   (click)="updatePhones()" 
   [disabled]="disableSearch"
   matTooltip="{{'clientRelations.company.initiateSearch' | translate}}"
   >

    <mat-icon>refresh</mat-icon>
  </button>

  <button 
  tabindex="-1" 
  mat-icon-button 
  (click)="close()"
  >
    <mat-icon>clear</mat-icon>
  </button>
</div>
<div class="margin-top">
  <ng-container *ngIf="companyPhones[0]?.phone;else messages">
    <itfg-single-company-phone 
    *ngFor="let phone of companyPhones"
    [data] = phone
    [disableAddToContacts] = isExisting(phone.phone)
    (addToContacts)="addToContacts($event)"
    >
    
    </itfg-single-company-phone>
  
  </ng-container>
</div>

<ng-template #messages class="messages">
  <div layout="column" flex layout-align="center center">
    <ng-container *ngIf="companyPhones[0]">
      {{'clientRelations.company.noResultsFound' | translate}}
    </ng-container>
    <ng-container *ngIf="!companyPhones[0]">
        {{'clientRelations.company.noSearchBatch' | translate}}
      <button 
       tabindex="-1" 
       mat-raised-button 
       (click)="updatePhones()" 
       [disabled]="disableSearch" 
       class="margin-top"
       >
        {{'clientRelations.company.initiateSearch' | translate}}
      </button>
    </ng-container>
  </div>
</ng-template>