import { Pipe, PipeTransform } from '@angular/core';
import { SessionService } from '@app/core/services/session.service';
import { Note } from '@app/core/types/note';
import * as moment from 'moment';

@Pipe({
  name: 'isNoteEditable'
})
export class IsNoteEditablePipe implements PipeTransform {
  constructor(
    private sessionService: SessionService
  ) { }

  transform(notes: Note | Note[]): boolean {
    if (!notes) {
      return false;
    }

    if (Array.isArray(notes)) {
      return notes.some((note: Note) => this.isNoteEditable(note));
    } else {
      return this.isNoteEditable(notes);
    }
  }
  
  private isNoteEditable(note: Note): boolean {
    const noteOperatorId = Number(note.operatorId);
    const currentlyLoggedOperatorId = this.sessionService.currentlyLoggedOperator.id;

    const createdAtTime = moment(note.createdAt);
    const currentTime = moment();

    const differenceInMinutes = currentTime.diff(createdAtTime, 'minutes');
    return differenceInMinutes <= 30 && currentlyLoggedOperatorId === noteOperatorId;
  }
}
