<div layout="column">
  <div layout="row"
       layout-align="space-between center"
       flex>
    <span [translate]="'tags.addTag'"
          mat-dialog-title>Add tag</span>
    <button tabindex="-1"
            class="close-mat-dialog"
            mat-icon-button
            [mat-dialog-close]="false">
      <mat-icon>clear</mat-icon>
    </button>
  </div>

  <form layout="column"
        (ngSubmit)="onSave($event)">
    <mat-form-field flex>
      <input type="text"
             placeholder="{{ 'tags.tag' | translate }}"
             [style.color]="tagControl.value?.color"
             matInput
             itfgSelectFirstAutocompleteOptionOnBlur
             [formControl]="tagControl"
             [matAutocomplete]="auto">
      <mat-label>{{ 'tags.tag' | translate }}</mat-label>
      <mat-autocomplete #auto="matAutocomplete"
                        autoActiveFirstOption
                        [displayWith]="mapTagToDisplayValue">
        <mat-option *ngFor="let tag of filteredShownTags$ | async"
                    [value]="tag">
          <span [style.color]="tag.color">{{tag.name}}</span>
        </mat-option>
      </mat-autocomplete>
      <mat-error *ngIf="tagControl.hasError('required')"
                 [translate]="'forms.required'">This field is required
      </mat-error>
      <mat-error *ngIf="tagControl.hasError('notValidTag')" [translate]="'forms.notValidTag'"></mat-error>
    </mat-form-field>

    <div layout="row"
         layout-align="end center">
      <button mat-stroked-button
              type="submit">
        <span [translate]="'global.save'">Save</span>
      </button>
    </div>
  </form>
</div>
