import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FilterValueListService, FilterValue } from '../filter/types';
import { ApplicationService } from '@app/core/services/application.service';
import { ApplicationSource } from '@app/core/types/application-type';

@Injectable()
export class ApplicationSourceValueList implements FilterValueListService {
  constructor(private applicationService: ApplicationService) {}

  getFilterValues$(): Observable<FilterValue[]> {
    return this.applicationService.getApplicationSources().pipe(
      map((applicationSources: ApplicationSource[]): FilterValue[] => {
        return applicationSources.sort((a, b) => a.id - b.id).map(
          (applicationSource: ApplicationSource): FilterValue => {
            return {
              value: applicationSource.id,
              displayText: applicationSource.name,
            };
          }
        );
      })
    );
  }
}
