import { Injectable } from '@angular/core';
import { RequestService } from './request.service';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { ContactPhone, ContactPhoneDto, ContactPhoneType } from '../types/contact-phone';
import { tap } from 'rxjs/operators';

@Injectable()
export class ContactService {
  public onContactAddedSubject: Subject<ContactPhone> = new Subject<ContactPhone>();
  public contactsSubject: BehaviorSubject<ContactPhone[]> = new BehaviorSubject<ContactPhone[]>([]);
  public phoneTypesSubject: BehaviorSubject<ContactPhoneType[]> = new BehaviorSubject<ContactPhoneType[]>([]);
  public dialogEmmited: Subject<boolean> = new Subject<boolean>();
  public saveRequested = new Subject<void>();
  public saveableContact = new Subject<boolean>();
  
  constructor(public request: RequestService) {}

  getContactList(userId: number | string): Observable<any> {
    return this.request.get(['users', userId, 'contacts']).pipe(
      tap(contacts => this.contactsSubject.next(contacts))
    );
  }

  getContactTypes(userId: number | string): Observable<any> {
    return this.request.get(['users', userId, 'contacts', 'types']).pipe(
      tap(phoneTypes => this.phoneTypesSubject.next(phoneTypes))
    );
  }

  createContact(userId: number | string, contactsDto: ContactPhoneDto): Observable<any> {
    return this.request.post(['users', userId, 'contacts'], {
      body: contactsDto
    });
  }

  editContact(userId: number | string, id: number | string, contactsDto: ContactPhoneDto): Observable<any> {
    return this.request.post(['users', userId, 'contacts', id], {
      body: contactsDto
    });
  }

  deleteContact(userId: number | string, id: number | string): Observable<any> {
    return this.request.delete(['users', userId, 'contacts', id]);
  }

  saveAllContacts() {
    this.saveRequested.next();
    this.saveableContact.next(false);
  }
}
