<mat-nav-list class="collapsed" *ngIf="collapsed">
  <ng-container *ngFor="let route of routes">
    <itfg-menu-list-item
      *ngxPermissionsOnly="route.permissions"
      [collapsed]="true"
      (click)="openNestedMenu(route)"
      [config]="route"
    ></itfg-menu-list-item>
  </ng-container>
</mat-nav-list>

<mat-nav-list *ngIf="!collapsed" class="itfg-layout-nav-menu">
  <ng-container *ngFor="let route of routes">
    <itfg-menu-list-item
      *ngxPermissionsOnly="route.permissions"
      [collapsed]="false"
      (click)="navigationLinkClicked()"
      [config]="route"
    ></itfg-menu-list-item>
    <!-- <mat-accordion> -->
    <ng-container
      *ngIf="
        route.children &&
        route.children.length > 0 &&
        !(route.permissions | isUnauthorized | async)
      "
    >
      <mat-expansion-panel
        [expanded]="!isExpanded[route.routerLink]"
        class="shadow-none padding-left-16px navigation-expansion-panel"
      >
        <mat-expansion-panel-header>
          <mat-panel-title>
            <div layout-align="center center" flex layout="row">
              <div flex="15">
                <mat-icon>{{ route.icon }}</mat-icon>
              </div>
              <span [translate]="route.label" flex>Route label</span>
            </div>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
          <mat-nav-list flex class="flex-offset-5">
            <ng-container *ngFor="let childRoute of route.children">
              <itfg-menu-list-item
                *ngxPermissionsOnly="childRoute.permissions"
                [collapsed]="false"
                (click)="navigationLinkClicked()"
                [config]="childRoute"
              ></itfg-menu-list-item>
              <!-- <mat-accordion> -->
              <mat-expansion-panel
                [expanded]="isExpanded[route.routerLink]"
                class="shadow-none padding-left-16px navigation-expansion-panel"
                *ngIf="
                  childRoute.children &&
                  childRoute.children.length > 0 &&
                  !(childRoute.permissions | isUnauthorized | async)
                "
              >
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <div layout-align="center center" flex layout="row">
                      <div flex="15">
                        <mat-icon>{{ childRoute.icon }}</mat-icon>
                      </div>
                      <span [translate]="childRoute.label" flex
                        >Route label</span
                      >
                    </div>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div flex layout="row">
                  <mat-nav-list flex class="flex-offset-5">
                    <ng-container *ngFor="let child of childRoute.children">
                      <itfg-menu-list-item
                        *ngxPermissionsOnly="child.permissions"
                        [collapsed]="false"
                        class="padding-left-16px"
                        (click)="navigationLinkClicked()"
                        [config]="child"
                      ></itfg-menu-list-item>
                    </ng-container>
                  </mat-nav-list>
                </div>
              </mat-expansion-panel>
              <!-- </mat-accordion> -->
            </ng-container>
          </mat-nav-list>
        </ng-template>
      </mat-expansion-panel>
    </ng-container>
    <!-- </mat-accordion> -->
  </ng-container>
</mat-nav-list>
