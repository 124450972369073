import {
  Directive,
  Input,
  ContentChild,
  AfterViewInit,
  Renderer2,
  ChangeDetectorRef,
} from '@angular/core';
import { MatExpansionPanel } from '@angular/material/expansion';
import { Utils } from '../../core/utils/utils';

@Directive({
  selector: '[itfgScrollIntoViewOnOpen]',
})
export class ScrollIntoViewOnOpenDirective implements AfterViewInit {
  @Input() itfgScrollIntoViewOnOpen;
  @Input() containerToScrollTo: HTMLElement;
  @Input() delayScroll: string | number = 0;
  @Input() scrollOnViewInit: boolean = false;
  @ContentChild(MatExpansionPanel)
  matExpansionPanel: MatExpansionPanel;

  constructor(private renderer: Renderer2) {}

  ngAfterViewInit() {
    if (this.scrollOnViewInit) {
      Utils.scrollElementToTop(
        this.containerToScrollTo,
        Number(this.delayScroll)
      );
    }

    this.matExpansionPanel?.opened.subscribe(() => {
      Utils.scrollElementToTop(
        this.containerToScrollTo,
        Number(this.delayScroll)
      );
    });
  }
}
