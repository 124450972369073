import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[itfgPhonePrefix]',
})
export class PhonePrefixDirective {
  public inputElement;

  constructor(private elementRef: ElementRef) {
    this.inputElement = this.elementRef.nativeElement;
  }

  @HostListener('focus') onMouseEnter() {
    this.addPrefix(this.inputElement.value);
  }

  @HostListener('blur') onMouseLeave() {
    this.removePrefix(this.inputElement.value);
  }

  addPrefix(value) {
    if (value === '') {
      this.inputElement.value = '+359';
    }
  }
  removePrefix(value) {
    if (value === '+359') {
      this.inputElement.value = '';
    }
  }
}
