import {Component, Inject, Input, OnChanges, OnDestroy, OnInit, Output} from '@angular/core';
import {ClientService} from '@core/services/client.service';
import {VERIFICATION_STATUS_STYLE_MAP, VerificationStatus, VerificationStatusNames} from '@core/verification-status-type';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {debounceTime, takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {VerificationStatusReasonToTranslationKeyPipe} from '@app/main/identity-reports/pipes/verification-status-reason-to-translation-key.pipe';
import { BrandCodes } from '@app/core/types/brand';

@Component({
  selector: 'itfg-verification-status-edit',
  templateUrl: './verification-status-edit.component.html',
  styleUrl: './verification-status-edit.component.scss'
})
export class VerificationStatusEditComponent implements OnInit, OnChanges, OnDestroy {
  public statusForm: UntypedFormGroup;
  public status: VerificationStatus;
  statusChangeOptions: string[];
  reasonFilter = new UntypedFormControl();
  _unsubscribe = new Subject<void>();
  statusReasonsList: string[] = [];
  fullReasonlist: Array<string>;
  verificationStatusStyleMap: any;

  constructor(@Inject(MAT_DIALOG_DATA) public data: { type: string; civilId: number | string; status: VerificationStatus, verificationStatusList: VerificationStatus[], brandCode: BrandCodes },
              public dialogRef: MatDialogRef<VerificationStatusEditComponent>,
              private clientService: ClientService,
              public formBuilder: UntypedFormBuilder,
              private translate: TranslateService,
              public verificationStatusReasonToTranslationKey: VerificationStatusReasonToTranslationKeyPipe) {
    this.statusForm = this.createForm();
    this.verificationStatusStyleMap = VERIFICATION_STATUS_STYLE_MAP;
    this.status = this.data.status;
    this.statusChangeOptions = this.data.verificationStatusList.map(status => status.name).filter((value, index, self) => self.indexOf(value) === index);

  }

  ngOnInit() {
    const statusChange$ = this.statusForm.get('status').valueChanges;
    statusChange$.subscribe(this.loadReasonsByStatus.bind(this));
    this.reasonFilter.valueChanges
      .pipe(
        takeUntil(this._unsubscribe),
        debounceTime(100)
      )
      .subscribe(this.filterValueChanged.bind(this));

    this.loadReasonsByStatus(this.data.status.name);

  }

  ngOnChanges(changes) {
    console.log(changes);

  }

  saveStatus() {
    this.clientService.changeVerificationStatus$(this.data.civilId, this.data.type, this.data.brandCode, this.statusForm.value).subscribe(client => {
      this.dialogRef.close(true);
      this.clientService.onClientDataChange.next(client);

    }, error => {
      // todo
    });
  }
  createForm() {
    return this.formBuilder.group({
      status: [{ value: this.data.status.name, disabled: false }],
      reason: [{ value: this.data.status.reason, disabled: false }],
    });
  }
  filterValueChanged(searchStr) {
    this.statusReasonsList = this.fullReasonlist.filter(value => {
      const reasonTranslationKey = this.verificationStatusReasonToTranslationKey.transform(
        value
      );
      const translatedReason = this.translate
        .instant(reasonTranslationKey)
        .toLowerCase();
      return translatedReason.includes(searchStr.toLowerCase());
    });
  }

  loadReasonsByStatus(selectedStatus: string) {
    this.statusReasonsList = this.data.verificationStatusList
      .filter(
        status => status.reason !== null && status.name === selectedStatus
      )
      .map(status => status.reason);
    this.fullReasonlist = [...this.statusReasonsList];
    const rowFormValue = this.statusForm.getRawValue();
    if (selectedStatus === this.data.status.name || this.statusReasonsList.indexOf(rowFormValue.reason) > -1) {
      this.statusForm.get('reason').patchValue(this.data.status.reason);
    } else {
      this.statusForm.get('reason').patchValue(null);
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
  clearRason(event) {
    event.preventDefault();
    event.stopPropagation();
    this.statusForm.get('reason').patchValue(null);
  }

  onSubmit() {
    if (!this.statusForm.invalid) {
      this.saveStatus();
    }
  }
  ngOnDestroy() {
    this._unsubscribe.next();
    this._unsubscribe.complete();
  }
}
