import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewContainerRef,
} from '@angular/core';
import { Credit, PbxCredit } from '@app/core/types/credit';
import { Subject, distinctUntilChanged, filter, takeUntil } from 'rxjs';
import {
  EventLogHistoryType,
  EventLogMode,
  EventLogSubheader,
} from '../../shared/event-log.enums';
import { ContactService } from '@app/core/services/contacts.service';
import { Client } from '@app/core/types/client';
import { ContactPhone, ContactPhoneType } from '@app/core/types/contact-phone';
import { InteractionMode } from '@app/core/types/interaction-mode';
import { MatDialog } from '@angular/material/dialog';
import {
  Call,
  CallDialogMode,
  CallDirection,
  CallIncomingStatuses,
  CallOutgoingStatuses,
  CallStage,
  CallStatus,
  CallerClientRelation,
  HIDDEN_PHONE_NUMBER,
  INQUIRY_PHONE_NUMBER,
  PbxMode,
} from '@app/core/types/call';
import { EventLogStateService } from '../event-log-state.service';
import { EventLogExtendedState, PbxState } from '../../shared/state.interfaces';
import { NoteMode } from '../notes/note/note.types';
import { CallService } from '@app/core/services/call.service';
import { UserDetails } from '@app/core/types/user-details';
import { SessionService } from '@app/core/services/session.service';
import { NotificationService } from '@app/core/services/notification.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { SmsService } from '@app/core/services/sms.service';
import { DialogProviderService } from '@app/core/services/dialog-provider.service';
import {
  SearchCriterium,
  SearchOperations,
} from '@app/core/types/search-criterium';
import { BlacklistService } from '@app/core/services/blacklist.service';
import { BlacklistEditComponent } from '@app/main/client/blacklist-edit/blacklist-edit.component';
import { EventLogAddContactDialogComponent } from '../event-log-add-contact/event-log-add-contact-dialog.component';
import { CALL_DIRECTION_SVG_STYLE_MAP } from '@app/config/call-config';
import { StyleConfigMap } from '@app/config/credit-config';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { NgxPermissionsService } from 'ngx-permissions';
import { ClientService } from '@app/core/services/client.service';

@Component({
  selector: 'itfg-pbx',
  templateUrl: './pbx.component.html',
  styleUrls: ['./pbx.component.scss'],
})
export class PbxComponent implements OnInit, OnDestroy {
  mode: EventLogMode;
  NoteMode = NoteMode;
  currentlyLoggedOperator: UserDetails;
  EventLogHistoryType = EventLogHistoryType;
  CallerClientRelation = CallerClientRelation;
  call: Call;
  openedCredit: Credit;
  selectedCredit: Credit;
  client: Client;
  clientFoundByMobile: Client;
  phoneNumber: string;
  tempPhoneNumber: string;
  historyType: EventLogHistoryType;
  callStage: CallStage = CallStage.INIT;
  callStages: typeof CallStage = CallStage;
  callerClientRelation: CallerClientRelation;
  selectedCreditPhoneRelation: CallerClientRelation;
  creditContextArr: Credit[];
  contacts: ContactPhone[];
  phoneTypes: ContactPhoneType[];
  phoneNumberBlacklisted: boolean = false;
  blacklistData: any;
  hiddenNumber: string = HIDDEN_PHONE_NUMBER;
  inquiryPhonenumber: string = INQUIRY_PHONE_NUMBER;
  failedClick2CallNumber: string;
  callDirectionsStyleMap: StyleConfigMap = CALL_DIRECTION_SVG_STYLE_MAP;
  callDirectionTypes: any[] = Object.values(CallDirection);
  callStatusTypes: any[] = Object.values(CallStatus);
  callOutgoingTypes = Object.values(CallOutgoingStatuses);
  callIncomingTypes = Object.values(CallIncomingStatuses);
  interactionMode: PbxMode;
  interactionModes: typeof PbxMode = PbxMode;
  manualCallForm: UntypedFormGroup;
  callDialogModes = CallDialogMode;
  showSelectOpenedCreditBtn: boolean = false;
  isExpansionListOpen: boolean = false;
  isCustomerSupport;
  callStatuses: typeof CallStatus = CallStatus;
  callDirections: typeof CallDirection = CallDirection;
  connectingToPbx$: Subject<boolean>;

  private _unsubscribe = new Subject<void>();

  constructor(
    private contactService: ContactService,
    private dialog: MatDialog,
    public eventLogStateService: EventLogStateService,
    private callService: CallService,
    private translate: TranslateService,
    private sessionService: SessionService,
    private notificationService: NotificationService,
    private router: Router,
    private smsService: SmsService,
    private dialogService: DialogProviderService,
    private viewContainerRef: ViewContainerRef,
    private blacklistService: BlacklistService,
    private formBuilder: UntypedFormBuilder,
    private ngxPermissionService: NgxPermissionsService,
    private cdr: ChangeDetectorRef
  ) {
    this.currentlyLoggedOperator = this.sessionService.currentlyLoggedOperator;
    this.isCustomerSupport = this.ngxPermissionService.getPermission(
      'CALLS_CALLBACK_READ'
    );
    this.onExpansionListOpened = this.onExpansionListOpened.bind(this);
    this.onExpansionListClosed = this.onExpansionListClosed.bind(this);
    this.callStatusTypes = this.isCustomerSupport
      ? Object.values(CallIncomingStatuses)
      : Object.values(CallOutgoingStatuses);
    this.connectingToPbx$ = this.callService.connectingToPbx$;
    this.manualCallForm = this.createManualCallForm();
  }

  ngOnInit() {
    this.eventLogStateService
      .getPbxState()
      .pipe(takeUntil(this._unsubscribe))
      .subscribe((state: PbxState) => {
        this.updateState(state);
        this.updateButtonVisibility();
      });

    this.subscribeToContactSubjects();
    this.watchForFormChanges();
  }

  subscribeToContactSubjects() {
    this.contactService.phoneTypesSubject
      .pipe(takeUntil(this._unsubscribe))
      .subscribe((phoneTypes: ContactPhoneType[]) => {
        this.phoneTypes = phoneTypes;
      });

    this.contactService.contactsSubject
      .pipe(
        takeUntil(this._unsubscribe),
        distinctUntilChanged(
          (prev, curr) => JSON.stringify(prev) === JSON.stringify(curr)
        )
      )
      .subscribe((contacts: ContactPhone[]) => {
        this.contacts = contacts;
      });
  }

  updateState({
    mode,
    historyType,
    openedCredit,
    selectedCredit,
    client,
    creditContextArr,
    clientFoundByMobile,
    phoneNumber,
    call,
    failedCallData,
  }: PbxState) {
    const prevClientFoundByMobile = this.clientFoundByMobile;
    const prevClient = this.client;
    const prevPhoneNumber = this.phoneNumber;
    const prevOpenedCredit = this.openedCredit;
    const prevSelectedCredit = this.selectedCredit;

    if (!prevOpenedCredit || prevOpenedCredit.id !== openedCredit?.id) {
      this.openedCredit = openedCredit;
    }
    
    if (!prevSelectedCredit || prevSelectedCredit.id !== selectedCredit?.id) {
      this.selectedCredit = selectedCredit;
    }

    this.mode = mode;
    this.historyType = historyType;
    this.client = client;
    this.creditContextArr = creditContextArr;
    this.phoneNumber = phoneNumber;
    this.call = call;
    this.clientFoundByMobile = clientFoundByMobile;
    this.interactionMode = this.call
      ? PbxMode.EXISTING_CALL
      : PbxMode.MANUAL_CALL;

    const isNewClient: boolean =
      this.clientFoundByMobile &&
      prevClientFoundByMobile?.id !== this.clientFoundByMobile?.id;
    const hasPhoneNumberChanged: boolean =
      this.clientFoundByMobile && prevPhoneNumber !== this.phoneNumber && this.phoneNumber !== this.hiddenNumber && this.phoneNumber !== this.inquiryPhonenumber;
    const shouldSetPhoneFieldValue: boolean =
      (this.interactionMode === PbxMode.MANUAL_CALL &&
        !failedCallData &&
        !this.manualCallForm.get('phone').value) ||
      (this.client && prevClient && prevClient?.id !== this.client?.id) ||
      (this.openedCredit &&
        prevOpenedCredit &&
        prevOpenedCredit?.id !== this.openedCredit?.id);

    if (isNewClient || hasPhoneNumberChanged) {
      this.isPhoneNumberBlacklisted();
    }

    // TODO: Refactor this if check
    if (shouldSetPhoneFieldValue) {
      const defaultPhoneNumber = this.openedCredit
        ? this.openedCredit.user.mobile
        : this.client
          ? this.client.mobile
          : null;
      setTimeout(() =>
        this.manualCallForm.get('phone').setValue(defaultPhoneNumber)
      );
      this.tempPhoneNumber = defaultPhoneNumber;
      this.cdr.detectChanges();
    }

    if (failedCallData) {
      this.failedClick2CallNumber = failedCallData.phoneNumber;

      this.manualCallForm.setValue({
        phone: failedCallData.phoneNumber,
        direction: failedCallData.direction,
        status: CallStatus.ANSWER,
      });

      this.eventLogStateService.setState({
        failedCallData: null,
      });

      this.openDrawerWithPBX();
    }
  }

  updateButtonVisibility() {
    const isDifferentOrNoSelectedCredit: boolean =
      this.openedCredit &&
      (!this.selectedCredit || this.selectedCredit.id !== this.openedCredit.id);

    this.showSelectOpenedCreditBtn = isDifferentOrNoSelectedCredit;
  }

  makeCall(phoneNumber: string) {
    this.callService.call(phoneNumber);
  }

  editBlacklist() {
    const blacklistDialogOblect = this.blacklistData.content[0];
    blacklistDialogOblect.isPreset = true;

    const dialogRef = this.dialog.open(BlacklistEditComponent, {
      data: blacklistDialogOblect,
    });

    dialogRef.afterClosed().subscribe((res) => {
      this.isPhoneNumberBlacklisted();
    });
  }

  blacklistPhoneNumber() {
    const blacklistDialogOblect = {
      blacklistType: {
        id: 3,
      },
      value: this.clientFoundByMobile.mobile,
      isPreset: true,
    };
    const dialogRef = this.dialog.open(BlacklistEditComponent, {
      data: blacklistDialogOblect,
    });

    dialogRef.afterClosed().subscribe((res) => {
      this.isPhoneNumberBlacklisted();
    });
  }

  isPhoneNumberBlacklisted() {
    const blacklistHttpCallOptions: Array<SearchCriterium> = [
      // Value types:
      // '1': 'Civil'
      // '2': 'Employer id'
      // '3': 'Phone number'
      {
        key: 'blacklistType.id',
        operation: SearchOperations.EQUALS,
        value: '3',
      },
      {
        key: 'value',
        operation: SearchOperations.IN,
        value: [this.clientFoundByMobile.mobile],
      },
    ];

    this.blacklistService
      .getBlacklist({ criteria: JSON.stringify(blacklistHttpCallOptions) })
      .subscribe((res) => {
        this.blacklistData = res;

        if (res.content && res.content.length > 0) {
          this.phoneNumberBlacklisted = true;
        } else {
          this.phoneNumberBlacklisted = false;
        }
      });
  }

  sendTempPassBySms() {
    this.dialogService
      .openConfirm({
        message: this.translate.instant(
          'clients.sendPasswordBySmsConfirmationMessage',
          { phoneNumber: this.clientFoundByMobile.mobile }
        ),
        viewContainerRef: this.viewContainerRef,
        title: this.translate.instant('global.confirm'),
        cancelButton: this.translate.instant('global.cancel'),
        acceptButton: this.translate.instant('global.confirm'),
      })
      .afterClosed()
      .pipe(filter((accept) => accept === true))
      .subscribe(() => {
        this.smsService
          .sendTemporaryPassword(this.clientFoundByMobile.id)
          .subscribe(() => {
            this.notificationService.showLocalizedSuccessMessage({
              notificationText: 'communication.smsSuccessfullySent',
            });
          });
      });
  }

  fetchClient(phoneNumber: string) {
    const formattedPhoneNumber = this.eventLogStateService.getBulgarianPhoneNumberWithCountryCode(phoneNumber);

    this.eventLogStateService.fetchClient(formattedPhoneNumber)
  }

  addOpenedCreditToContext() {
    let modifiedCredit: PbxCredit;

    const isOpenedCreditIncluded: boolean = this.isOpenedCreditIncluded(
      this.openedCredit,
      this.creditContextArr
    );
    const formattedPhoneNumber: string =
      this.getBulgarianPhoneNumberWithCountryCode(this.phoneNumber);
    const isOpenedCreditUnrelated: boolean =
      this.openedCredit?.user?.mobile !== formattedPhoneNumber &&
      !this.contacts?.some(
        (contact: ContactPhone) => contact.phone === formattedPhoneNumber
      );

    if (isOpenedCreditUnrelated) {
      modifiedCredit = {
        ...this.openedCredit,
        isUnrelated: true,
      };
    } else {
      modifiedCredit = this.openedCredit;
    }

    if (!isOpenedCreditIncluded) {
      this.creditContextArr.unshift(modifiedCredit);
    }

    this.eventLogStateService.setState({
      selectedCredit: modifiedCredit,
      creditContextArr: this.creditContextArr,
    });

    this.notificationService.showLocalizedSuccessMessage({
      notificationText: 'pbx.successfullySelectedOpenedCredit',
    });
  }

  isOpenedCreditIncluded(openedCredit: Credit, creditContextArr: Credit[]) {
    return creditContextArr?.some(
      (credit: Credit) => credit.id === openedCredit?.id
    );
  }

  getBulgarianPhoneNumberWithCountryCode(phoneNumber: string) {
    if (phoneNumber) {
      return phoneNumber.startsWith('0')
        ? `+359${phoneNumber.substring(1)}`
        : phoneNumber;
    }
  }

  openEditCallDialog(mode: CallDialogMode) {
    this.callService.openEditCallDialog(mode);
  }

  exitCall() {
    const phoneNumber = this.openedCredit
      ? this.openedCredit.user.mobile
      : this.client
        ? this.client.mobile
        : null;
    const selectedCredit = this.openedCredit ? this.openedCredit : null;
    const historyType = this.openedCredit
      ? EventLogHistoryType.CREDIT
      : !this.openedCredit && this.client
        ? EventLogHistoryType.PHONE
        : null;
    const mode = this.openedCredit ? EventLogMode.CREDIT : this.client ? EventLogMode.CLIENT : null;

    this.eventLogStateService.setState(
      {
        selectedCredit: selectedCredit,
        call: null,
        subheader: null,
        historyType: historyType,
        phoneNumber: phoneNumber,
        mode: mode,
      },
      { modeOverride: true }
    );

    this.manualCallForm.get('phone').setValue(phoneNumber);
  }

  selectHistoryType(type: EventLogHistoryType) {
    this.eventLogStateService.setState({
      historyType: type,
    });
  }

  openAddContactDialog(currentPhoneValue: string) {
    this.manualCallForm.get('phone').setValue(currentPhoneValue);

    return this.dialog
      .open(EventLogAddContactDialogComponent, {
        data: {
          client: this.client,
          interactionMode: InteractionMode.NEW,
          eventLogPhoneTypes: this.phoneTypes,
          eventLogContacts: this.contacts,
        },
        restoreFocus: false,
        minWidth: '900px',
      })
      .afterClosed()
      .pipe(filter((success) => !!success))
      .subscribe((success: boolean) => {
        if (success) {
          this.contactService.dialogEmmited.next(true);
          this.manualCallForm.get('phone').setValue(this.contacts[0]?.phone);

          const formattedContactNumber =
            this.eventLogStateService.formatPhoneNumber(this.contacts[0].phone);
          this.eventLogStateService.setState({
            phoneNumber: formattedContactNumber,
          });
        }
      });
  }

  createManualCallForm() {
    return this.formBuilder.group({
      phone: [''],
      direction: [
        this.isCustomerSupport ? CallDirection.IN : CallDirection.OUT,
      ],
      status: [
        this.isCustomerSupport ? CallStatus.AGENT_CONNECTED : CallStatus.ANSWER,
      ],
    });
  }

  watchForFormChanges() {
    this.manualCallForm
      .get('direction')
      .valueChanges.subscribe((direction: CallDirection) => {
        if (direction === CallDirection.IN) {
          this.callStatusTypes = this.callIncomingTypes;
          this.manualCallForm
            .get('status')
            .setValue(CallStatus.AGENT_CONNECTED);
        } else {
          this.callStatusTypes = this.callOutgoingTypes;
          this.manualCallForm.get('status').setValue(CallStatus.ANSWER);
        }
      });

    this.manualCallForm
      .get('phone')
      .valueChanges.subscribe((phoneNumber: string) => {
        if (phoneNumber === 'addContact') {
          const currentPhoneValue =
            this.tempPhoneNumber || this.manualCallForm.get('phone').value;
          this.openAddContactDialog(currentPhoneValue);
        } else {
          const formattedPhoneNumber =
            this.eventLogStateService.formatPhoneNumber(phoneNumber);
          this.eventLogStateService.setState({
            phoneNumber: formattedPhoneNumber,
          });
          this.tempPhoneNumber = phoneNumber;
        }
      });
  }

  openDrawerWithPBX() {
    this.eventLogStateService.setState(
      {
        mode: EventLogMode.PBX,
        subheader: EventLogSubheader.PBX,
        historyType: EventLogHistoryType.PHONE,
      },
      { openDrawer: true }
    );
  }

  onExpansionListOpened() {
    this.isExpansionListOpen = true;

    if (
      this.mode === EventLogMode.CREDIT || this.mode === EventLogMode.CLIENT,
      this.eventLogStateService.shouldFetchCredits
    ) {
      this.eventLogStateService.fetchCredits(
        this.getBulgarianPhoneNumberWithCountryCode(this.phoneNumber)
      );
    }
  }

  onExpansionListClosed() {
    this.isExpansionListOpen = false;
  }
  

  ngOnDestroy() {
    this._unsubscribe.next();
    this._unsubscribe.complete();
  }
}
