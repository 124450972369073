<div layout="row"
     layout-align="space-between center"
     layout-wrap>
  <button mat-raised-button
          [disabled]="['CLAIM_WRITE'] | isUnauthorized | async"           
          class="list-screen-operations-item"
          (click)="claimList.addClaim(userId)">
    <mat-icon>add</mat-icon>
  </button>
</div>

<itfg-claim-list #claimList
                 [claimColumns]="claimColumns"
                 [searchOptions]="searchOptions"></itfg-claim-list>