import { Injectable } from '@angular/core';
import { RequestService } from './request.service';
import { Observable } from 'rxjs';
import { OverduePenalty } from '../types/overdue-penalty';

@Injectable()
export class OverduePenaltyService {
  request: RequestService;

  constructor(request: RequestService) {
    this.request = request;
  }

  getOverduePenaltyList(): Observable<OverduePenalty[]> {
    return this.request.get(['overdue-penalties']);
  }

  getOverduePenaltyById(id: number | string): Observable<OverduePenalty> {
    return this.request.get(['overdue-penalties', id]);
  }

  getOverduePenaltiesByProduct(
    id: number | string
  ): Observable<OverduePenalty[]> {
    return this.request.get(['overdue-penalties', 'product', id]);
  }

  saveOverduePenalty(overduePenalty: OverduePenalty): Observable<any> {
    return this.request.post(['overdue-penalties'], { body: overduePenalty });
  }

  deleteOverduePenalty(id: number | string): Observable<any> {
    return this.request.delete(['overdue-penalties', id]);
  }
}
