import { FormComponentData } from '../types/form-component-data';
import { FilterCardConfig } from '../../../app/main/filter/types';
import { EgnUtils } from './egn-utils';
import * as moment from 'moment/moment';
import 'moment/locale/bg';
import { UserDetails } from '../types/user-details';
import * as _ from 'lodash-es';

export const Utils = {
  deepValue,
  removeValues,
  removeEmptyValues,
  checkObjectForEmptyValuesRecursive,
  checkForNonExistingInnerObject,
  replaceIdWithFullObject,
  arrayRemoveDuplicates,
  dotNotationToCamelCase,
  scrollElementToTop,
  snakeCaseToCamelCase,
  checkIfObjectIsEmpty,
  trimValues,
  getNestedValueFromObject,
  extendFilterConfig,
  calculateClientAgeFromCivilId,
  getClientGenderFromCivilId,
  isPrivateIp,
  transliterate,
  generateRandomNumber,
  dateIsAfter,
  transliterateToBulgarian,
  generateTemplatePlaceholderValue,
  extractUniqueValues,
  convertCivilIdToBirthDate,
};

let cachedItem = {};

function isPrivateIp(ip: string): boolean {
  return (
    /^(::f{4}:)?10\.([0-9]{1,3})\.([0-9]{1,3})\.([0-9]{1,3})$/i.test(ip) ||
    /^(::f{4}:)?192\.168\.([0-9]{1,3})\.([0-9]{1,3})$/i.test(ip) ||
    /^(::f{4}:)?172\.(1[6-9]|2\d|30|31)\.([0-9]{1,3})\.([0-9]{1,3})$/i.test(
      ip
    ) ||
    /^(::f{4}:)?127\.([0-9]{1,3})\.([0-9]{1,3})\.([0-9]{1,3})$/i.test(ip) ||
    /^(::f{4}:)?169\.254\.([0-9]{1,3})\.([0-9]{1,3})$/i.test(ip) ||
    /^f[cd][0-9a-f]{2}:/i.test(ip) ||
    /^fe80:/i.test(ip) ||
    /^::1$/.test(ip) ||
    /^::$/.test(ip)
  );
}

function arrayRemoveDuplicates(arr: any[], key?: string) {
  const prims = { boolean: {}, number: {}, string: {} };
  const objs = [];

  return arr.filter(function(item) {
    const type = typeof item;
    if (type in prims) {
      return prims[type].hasOwnProperty(item)
        ? false
        : (prims[type][item] = true);
    } else if (item !== null) {
      if (key) {
        return objs.findIndex(itemEl => {
          return itemEl[key] === item[key];
        }) >= 0
          ? false
          : objs.push(item);
      } else {
        return objs.indexOf(item) >= 0 ? false : objs.push(item);
      }
    } else {
      return false;
    }
  });
}

function deepValue(obj: any, path: string) {
  const pathArray = path.split('.');
  for (let i = 0; i < pathArray.length; i++) {
    if (obj) {
      obj = obj[pathArray[i]];
    } else {
      return null;
    }
  }
  return obj;
}

function checkForNonExistingInnerObject(
  obj: Object,
  innerObjectNames: string[],
  propertyToCreate = 'object'
): void {
  for (const innerObject of innerObjectNames) {
    if (
      obj.hasOwnProperty(innerObject) &&
      obj[innerObject] !== null &&
      obj[innerObject] !== undefined
    ) {
      continue;
    } else {
      switch (propertyToCreate) {
        case 'object':
          obj[innerObject] = {};
          break;
        case 'string':
          obj[innerObject] = '';
          break;
      }
    }
  }
}

function removeValues(obj: any, value: any) {
  for (const prop in obj) {
    if (obj[prop] === value) {
      delete obj[prop];
    }
  }
  return obj;
}

function removeEmptyValues(obj: {}) {
  for (const prop in obj) {
    if (
      !obj[prop] ||
      (Object.keys(obj[prop]).length === 0 && obj[prop].constructor === Object)
    ) {
      delete obj[prop];
    }
  }

  return obj;
}

function checkArray(
  arr: Array<any>,
  propertyName: string,
  originalCollection: any
) {
  for (let i = 0; i < arr.length; i++) {
    if (arr[i] === 'object' && arr[i] instanceof Array) {
      checkArray(arr[i], i.toString(), arr);
    }
    if (arr[i] && typeof arr[i] === 'object' && !(arr[i] instanceof Array)) {
      iterateObject(arr[i]);
    }

    if (arr[i] && typeof arr[i] !== 'object') {
      continue;
    }

    if (arr[i]) {
      const keys = Object.keys(arr[i]);
      if (keys.length === 0) {
        arr.splice(i, 1);
        i--;
      }
    }
  }
  const keysInOriginalArray = Object.keys(arr);
  if (keysInOriginalArray.length === 0) {
    delete originalCollection[propertyName];
  }
}

function iterateObject(obj: any) {
  // tslint:disable-next-line:forin
  for (const prop in obj) {
    if (
      obj[prop] &&
      typeof obj[prop] === 'object' &&
      !(obj[prop] instanceof Array)
    ) {
      iterateObject(obj[prop]);
    }
    if (
      obj[prop] &&
      typeof obj[prop] === 'object' &&
      obj[prop] instanceof Array
    ) {
      if (obj[prop].length === 0) {
        delete obj[prop];
        continue;
      } else {
        checkArray(obj[prop], prop, obj);
      }
    }
    if (obj[prop] === null || obj[prop] === '' || obj[prop] === undefined) {
      delete obj[prop];
      continue;
    }

    if (
      obj[prop] &&
      typeof obj[prop] === 'object' &&
      typeof obj[prop].getMonth !== 'function'
    ) {
      const keys = Object.keys(obj[prop]);
      if (keys.length === 0) {
        delete obj[prop];
        iterateObject(cachedItem);
      }
    }
  }

  return obj;
}

function checkObjectForEmptyValuesRecursive(obj: any) {
  cachedItem = obj;
  obj = iterateObject(obj);

  return obj;
}

function replaceIdWithFullObject(
  collectionToSearch: any,
  id: any,
  formDataObject: FormComponentData,
  propertyToDelete: string,
  finalPropertyName: string
): void {
  for (const item of collectionToSearch) {
    if (item[id] === formDataObject.data[propertyToDelete]) {
      formDataObject.data[finalPropertyName] = item;
      delete formDataObject.data[propertyToDelete];
      break;
    }
  }
}

function dotNotationToCamelCase(value: string) {
  return value.replace(/\.([a-z])/g, function(g) {
    return g[1].toUpperCase();
  });
}

function snakeCaseToCamelCase(value: string) {
  return value ? value.replace(/((\_|\s)\w)/g, m => m[1].toUpperCase()) : value;
}

function scrollElementToTop(elementToScrollTo?: HTMLElement, delay: number = 0, offset: number = 55) {
  setTimeout(() => {
    const targetElement = elementToScrollTo || document.body;
    const boundingRectangle = targetElement.getBoundingClientRect();
    const currentElement = document.querySelector('.mat-drawer-content');
    const pos = elementToScrollTo ? currentElement.scrollTop + boundingRectangle.top - offset : 0;

    currentElement.scrollTo({ top: pos, behavior: 'smooth' });
  }, delay);
}

function checkIfObjectIsEmpty(obj) {
  return Object.getOwnPropertyNames(obj).length !== 0;
}

function getNestedValueFromObject(object: Object, propName: string): any {
  return propName.split('.').reduce((o, i) => o && o[i], object);
}

function trimValues(values) {
  Object.keys(values).forEach(key => {
    values[key] = values[key].trim();
  });
}

function extendFilterConfig(
  creditListFilterConfig: FilterCardConfig,
  newConfig: FilterCardConfig,
  prefix: string
): FilterCardConfig {
  const mappedConfig = {};
  const config = creditListFilterConfig;

  Object.keys(config).forEach((key: string) => {
    mappedConfig[`${prefix}.${key}`] = {
      ...config[key],
      path: `${prefix}.${config[key].path}`,
      groupTranslationKey: config[key].groupTranslationKey,
      nameTranslationKey: config[key].nameTranslationKey,
    };
  });

  return Object.assign(newConfig, mappedConfig);
}
function calculateClientAgeFromCivilId(civilId) {
  if (!civilId) {
    return;
  }
  let civilIdAge: number;
  const ageCode = Number(civilId.substr(0, 2));
  const monthCode = Number(civilId.substr(2, 2));
  const dateCode = Number(civilId.substr(4, 2));
  let year = 0;
  let month = 0;

  if (monthCode > 40) {
    year = 2000 + ageCode;
    month = monthCode - 40;
  } else {
    year = 1900 + ageCode;
    month = monthCode;
  }
  const dateOfBirth = new Date(year, month - 1, dateCode);

  const agePeriod = new Date(Date.now() - dateOfBirth.getTime());

  if (civilId.length > 5) {
    civilIdAge = agePeriod.getUTCFullYear() - 1970;
  } else {
    civilIdAge = null;
  }
  return civilIdAge;
}
function getClientGenderFromCivilId(civilId) {
  if (!civilId) {
    return;
  }

  let civilIdGender: string;
  const clientGender = civilId.substr(8, 1);
  if (civilId.length > 8) {
    return (civilIdGender = clientGender % 2 === 0 ? 'male' : 'female');
  } else {
    return (civilIdGender = null);
  }
}

function generateRandomNumber(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

function transliterate(text) {
  text = text
    .replace(/\u0401/g, 'YO')
    .replace(/\u0419/g, 'I')
    .replace(/\u0426/g, 'TS')
    .replace(/\u0423/g, 'U')
    .replace(/\u041A/g, 'K')
    .replace(/\u0415/g, 'E')
    .replace(/\u041D/g, 'N')
    .replace(/\u0413/g, 'G')
    .replace(/\u0428/g, 'SH')
    .replace(/\u0429/g, 'SCH')
    .replace(/\u0417/g, 'Z')
    .replace(/\u0425/g, 'H')
    .replace(/\u042A/g, 'U')
    .replace(/\u0451/g, 'yo')
    .replace(/\u0439/g, 'i')
    .replace(/\u0446/g, 'ts')
    .replace(/\u0443/g, 'u')
    .replace(/\u043A/g, 'k')
    .replace(/\u0435/g, 'e')
    .replace(/\u043D/g, 'n')
    .replace(/\u0433/g, 'g')
    .replace(/\u0448/g, 'sh')
    .replace(/\u0449/g, 'sch')
    .replace(/\u0437/g, 'z')
    .replace(/\u0445/g, 'h')
    .replace(/\u044A/g, `u`)
    .replace(/\u0424/g, 'F')
    .replace(/\u042B/g, 'I')
    .replace(/\u0412/g, 'V')
    .replace(/\u0410/g, 'a')
    .replace(/\u041F/g, 'P')
    .replace(/\u0420/g, 'R')
    .replace(/\u041E/g, 'O')
    .replace(/\u041B/g, 'L')
    .replace(/\u0414/g, 'D')
    .replace(/\u0416/g, 'ZH')
    .replace(/\u042D/g, 'E')
    .replace(/\u0444/g, 'f')
    .replace(/\u044B/g, 'i')
    .replace(/\u0432/g, 'v')
    .replace(/\u0430/g, 'a')
    .replace(/\u043F/g, 'p')
    .replace(/\u0440/g, 'r')
    .replace(/\u043E/g, 'o')
    .replace(/\u043B/g, 'l')
    .replace(/\u0434/g, 'd')
    .replace(/\u0436/g, 'zh')
    .replace(/\u044D/g, 'e')
    .replace(/\u042F/g, 'Ya')
    .replace(/\u0427/g, 'CH')
    .replace(/\u0421/g, 'S')
    .replace(/\u041C/g, 'M')
    .replace(/\u0418/g, 'I')
    .replace(/\u0422/g, 'T')
    .replace(/\u042C/g, `I`)
    .replace(/\u0411/g, 'B')
    .replace(/\u042E/g, 'YU')
    .replace(/\u044F/g, 'ya')
    .replace(/\u0447/g, 'ch')
    .replace(/\u0441/g, 's')
    .replace(/\u043C/g, 'm')
    .replace(/\u0438/g, 'i')
    .replace(/\u0442/g, 't')
    .replace(/\u044C/g, `i`)
    .replace(/\u0431/g, 'b')
    .replace(/\u044E/g, 'yu');
  return text;
}

function transliterateToBulgarian(text) {
  text = text
    .replace(/\u0053\u0068\u0074/g, 'Щ')
    .replace(/\u0073\u0068\u0074/g, 'щ')
    .replace(/\u0053\u0068/g, 'Ш')
    .replace(/\u0073\u0068/g, 'ш')
    .replace(/\u0059\u0061/g, 'Я')
    .replace(/\u0079\u0061/g, 'я')
    .replace(/\u0064\u007A\u0068/g, 'дж')    
    .replace(/\u0041/g, 'А')
    .replace(/\u0042/g, 'Б')
    .replace(/\u0043/g, 'Ц')
    .replace(/\u0044/g, 'Д')
    .replace(/\u0045/g, 'Е')
    .replace(/\u0046/g, 'Ф')
    .replace(/\u0047/g, 'Г')
    .replace(/\u0048/g, 'Х')
    .replace(/\u0049/g, 'И')
    .replace(/\u004A/g, 'Дж')
    .replace(/\u004B/g, 'К')
    .replace(/\u004C/g, 'Л')
    .replace(/\u004D/g, 'М')
    .replace(/\u004E/g, 'Н')
    .replace(/\u004F/g, 'О')
    .replace(/\u0050/g, 'П')
    .replace(/\u0051/g, 'Кю')
    .replace(/\u0052/g, 'Р')
    .replace(/\u0053/g, 'С')
    .replace(/\u0054/g, 'Т')
    .replace(/\u0055/g, 'У')
    .replace(/\u0056/g, 'В')
    .replace(/\u0057/g, 'В')
    .replace(/\u0058/g, 'Х')
    .replace(/\u0059/g, 'Й')
    .replace(/\u005A/g, 'З')

    .replace(/\u0061/g, 'а')
    .replace(/\u0062/g, 'б')
    .replace(/\u0063/g, 'ц')
    .replace(/\u0064/g, 'д')
    .replace(/\u0065/g, 'е')
    .replace(/\u0066/g, 'ф')
    .replace(/\u0067/g, 'г')
    .replace(/\u0068/g, 'х')
    .replace(/\u0069/g, 'и')
    .replace(/\u006A/g, 'дж')
    .replace(/\u006B/g, 'к')
    .replace(/\u006C/g, 'л')
    .replace(/\u006D/g, 'м')
    .replace(/\u006E/g, 'н')
    .replace(/\u006F/g, 'о')
    .replace(/\u0070/g, 'п')
    .replace(/\u0071/g, 'кю')
    .replace(/\u0072/g, 'р')
    .replace(/\u0073/g, 'с')
    .replace(/\u0074/g, 'т')
    .replace(/\u0075/g, 'у')
    .replace(/\u0076/g, 'в')
    .replace(/\u0077/g, 'в')
    .replace(/\u0078/g, 'х')
    .replace(/\u0079/g, 'й')
    .replace(/\u007A/g, 'з')
  return text;
}

function extractUniqueValues(data: any[], path: string): any[] {
  const uniqueValues = new Set();
  for (const item of data) {
    const value = _.get(item, path);
    if (value) {
      uniqueValues.add(value);
    }
  }
  return Array.from(uniqueValues);
}

function dateIsAfter(firstDate, secondDate = new Date()) {
  return moment(firstDate).isAfter(secondDate);
}

function generateTemplatePlaceholderValue (placeholder: string, operator: UserDetails): string {
  switch (true) {
    case /firstName/.test(placeholder):
        return operator.firstName;
  
    case /lastName/.test(placeholder):
          return operator.lastName;

    case /url|tmpPassword|password|token/.test(placeholder):
        return placeholder;
         
    case /amount/.test(placeholder):
        return  '10';

    case /date|expire/.test(placeholder):
        return  moment().format('DD.MM.YYYY');
          
    default:  
      return placeholder;
  }
}

function convertCivilIdToBirthDate(civilId) {
  const civilIdStr = civilId.toString();
  let year = civilIdStr.substring(0, 2);
  let month = civilIdStr.substring(2, 4);
  const day = civilIdStr.substring(4, 6);

  if (month > 40) {
    year = '20' + year;
    month = month - 40;
  } else if (month > 20) {
    year = '18' + year;
    month = month - 20;
  } else {
    year = '19' + year;
  }

  month = month.toString().padStart(2, '0');

  const birthDate = `${year}-${month}-${day}`;

  return birthDate;
}


