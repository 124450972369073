<div *ngIf="client?.civilId && client?.id" class="top-padding-16">
  <h3 class="edit-screen-card-title">
    <span>{{ 'address.addressStatusReport.' + reportType | translate }}</span>
  </h3>
  <div
    flex
    layout="row"
    layout-wrap
    layout-align="space-between stretch"
  >
    <div flex="100" flex-gt-md="75" flex-gt-sm="100" flex-gt-xs="100">
      <itfg-reports-info-panel
        [listData]="addressListData"
        [reportData]="addressData"
        [reportTime]="reportTime"
        [requesterName]="requesterName"
        [metaStatusCode]="''"
        [requestTime]="requestTime"
        (dataChanged)="addressDataChanged($event)"
      ></itfg-reports-info-panel>
    </div>
    <div flex layout="row" layout-align="end start" [style.min-height.px]="65">
      <itfg-client-differences-button *ngIf="addressReportData"
                                      [config]="addressConfig">
      </itfg-client-differences-button>
      <div class="white-space-no-wrap">
        <button
          mat-icon-button
          matTooltip="{{ 'address.updateHistory' | translate }}"
          matTooltipPosition="above"
          [disabled]="['MARITAL_ADD'] | isUnauthorized | async"
          (click)="updateAddressHistory(reportType)">
          <mat-icon>refresh</mat-icon>
        </button>
      </div>
      <div class="white-space-no-wrap right-padding-16">
        <button
          mat-raised-button
          [disabled]="['MARITAL_ADD'] | isUnauthorized | async"
          (click)="getNewAddressReport(reportType)">
          {{ 'address.newReport' | translate }}
        </button>
      </div>
    </div>
  </div>
  <div
    *ngIf="addressListData?.length === 0 && !isReport"
    flex
    layout="row"
    layout-align="start"
    class="left-padding-16"
  >
    <h4
      class="edit-screen-card-title"
      [translate]="'address.noAddressData.' + reportType"
    >
      No reports
    </h4>
  </div>
  <div *ngIf="isReport">
    <ng-container [ngSwitch]="requestStatus">
      <ng-container *ngSwitchCase="'success'">
        <ng-container *ngIf="addressListData?.length > 0">
          <div
            flex
            layout="row"
            layout-wrap
            layout-align="space-between stretch"
          >
            <mat-card appearance="outlined" flex="100">
              <mat-card-content
                flex="100"
                layout="row"
                layout-wrap
                layout-align="space-around center"
              >
                <div
                  flex="100"
                  layout="row"
                  layout-wrap
                  layout-align="space-around center"
                >
                  <div flex="100" layout="row"
                       layout-align="center center" class="column-gap-10">
                    <mat-form-field flex="25">
                      <input
                        matInput
                        readonly
                        type="text"
                        [value]="(addressReportData?.FromDate | date) || ''"
                        placeholder="{{ 'identityReports.FromDate' | translate }}"
                        flex="100"
                        matTooltip="{{
                  (addressReportData?.FromDate | date) || ''
                }}"
                        matTooltipPosition="above"
                      />
                      <mat-label>{{ 'identityReports.FromDate' | translate }}</mat-label>
                    </mat-form-field>
                    <mat-form-field flex="25">
                      <input
                        matInput
                        readonly
                        type="text"
                        [value]="addressReportData?.DistrictName || ''"
                        placeholder="{{ 'identityReports.DistrictName' | translate }}"
                        flex="100"
                        matTooltip="{{
                  addressReportData?.DistrictName || ''
                }}"
                        matTooltipPosition="above"
                      />
                      <mat-label>{{ 'identityReports.DistrictName' | translate }}</mat-label>
                    </mat-form-field>
                    <mat-form-field flex="25">
                      <input
                        matInput
                        readonly
                        type="text"
                        [value]="addressReportData?.MunicipalityName || ''"
                        placeholder="{{ 'identityReports.MunicipalityName' | translate }}"
                        flex="100"
                        matTooltip="{{
                  addressReportData?.MunicipalityName || ''
                }}"
                        matTooltipPosition="above"
                      />
                      <mat-label>{{ 'identityReports.MunicipalityName' | translate }}</mat-label>
                    </mat-form-field>
                    <mat-form-field flex="25">
                      <input
                        matInput
                        readonly
                        type="text"
                        [value]="addressReportData?.SettlementName || ''"
                        placeholder="{{ 'identityReports.SettlementName' | translate }}"
                        flex="100"
                        matTooltip="{{
                  addressReportData?.SettlementName || ''
                }}"
                        matTooltipPosition="above"
                      />
                      <mat-label>{{ 'identityReports.SettlementName' | translate }}</mat-label>
                    </mat-form-field>
                  </div>
                  <div flex="100" layout="row"
                       layout-align="center center" class="column-gap-10">

                    <mat-form-field flex="25">
                      <input
                        matInput
                        readonly
                        type="text"
                        [value]="addressReportData?.LocationName || ''"
                        placeholder="{{ 'identityReports.LocationName' | translate }}"
                        flex="100"
                        matTooltip="{{
                  addressReportData?.LocationName || ''
                }}"
                        matTooltipPosition="above"
                      />
                      <mat-label>{{ 'identityReports.LocationName' | translate }}</mat-label>
                    </mat-form-field>
                    <mat-form-field flex="25">
                      <input
                        matInput
                        readonly
                        type="text"
                        [value]="addressReportData?.BuildingNumber || ''"
                        placeholder="{{ 'identityReports.BuildingNumber' | translate }}"
                        flex="100"
                        matTooltip="{{
                  addressReportData?.BuildingNumber || ''
                }}"
                        matTooltipPosition="above"
                      />
                      <mat-label>{{ 'identityReports.BuildingNumber' | translate }}</mat-label>
                    </mat-form-field>
                    <mat-form-field flex>
                      <input
                        matInput
                        readonly
                        type="text"
                        [value]="addressReportData?.Entrance || ''"
                        placeholder="{{ 'identityReports.Entrance' | translate }}"
                        flex="100"
                        matTooltip="{{
                  addressReportData?.Entrance || ''
                }}"
                        matTooltipPosition="above"
                      />
                      <mat-label>{{ 'identityReports.Entrance' | translate }}</mat-label>
                    </mat-form-field>
                    <mat-form-field flex>
                      <input
                        matInput
                        readonly
                        type="text"
                        [value]="addressReportData?.Floor || ''"
                        placeholder="{{ 'identityReports.Floor' | translate }}"
                        flex="100"
                        matTooltip="{{
                  addressReportData?.Floor || ''
                }}"
                        matTooltipPosition="above"
                      />
                      <mat-label>{{ 'identityReports.Floor' | translate }}</mat-label>
                    </mat-form-field>
                    <mat-form-field flex>
                      <input
                        matInput
                        readonly
                        type="text"
                        [value]="addressReportData?.Apartment || ''"
                        placeholder="{{ 'identityReports.Apartment' | translate }}"
                        flex="100"
                        matTooltip="{{
                  addressReportData?.Apartment || ''
                }}"
                        matTooltipPosition="above"
                      />
                      <mat-label>{{ 'identityReports.Apartment' | translate }}</mat-label>
                    </mat-form-field>
                  </div>

                </div>
              </mat-card-content>

            </mat-card>
          </div>
        </ng-container>
      </ng-container>

      <ng-container *ngSwitchCase="'not_found'">
        <h4 layout="row" layout-align="start">
          {{
          'address.noAddressDataFound.' + reportType
            | translate
          }}
        </h4>
      </ng-container>

      <ng-container *ngSwitchCase="'server_timeout'">
        <h4 class="edit-screen-card-title" layout="row" layout-align="start">
          {{
          'reports.serverTimeoutError'
            | translate: {requestTime: requestTime | itfgDateFormat}
          }}
          <itfg-no-reports-info
            [alertMessage]="{
              civilId: client?.civilId,
              type: addressData?.type,
              source: addressData?.source,
              status: addressData?.status,
              time: addressData?.time,
              data: addressData?.data
            }"
          >
          </itfg-no-reports-info>
        </h4>
      </ng-container>

      <ng-container *ngSwitchCase="'server_error'">
        <div layout="row" layout="row" layout-align="start">
          <h4 class="edit-screen-card-title">
            {{
            'reports.serverGenericError'
              | translate: {requestTime: requestTime | itfgDateFormat}
            }}
            <itfg-no-reports-info
              [alertMessage]="{
                civilId: client?.civilId,
                type: addressData?.type,
                source: addressData?.source,
                status: addressData?.status,
                time: addressData?.time,
                data: addressData?.data
              }"
            >
            </itfg-no-reports-info>
          </h4>
        </div>
      </ng-container>
    </ng-container>
  </div>
  <ng-container *ngIf="addressHistory?.length > 0">
    <div class="bottom-padding-16">
      <itfg-data-table
        #dataTable
        [data]="addressHistory"
        [columns]="columns"
        [stickyHeader]="true"
        [containerStyle]="{ 'max-height': '300px' }"
      >
        <ng-template
          itfgDataTableTemplate="data.FromDate"
          let-value="value"
          let-row="row"
          let-column="column"
        >
          {{ value | itfgDateFormat: dateFormat }}
        </ng-template>
        <ng-template
          itfgDataTableTemplate="data.SearchDate"
          let-value="value"
          let-row="row"
          let-column="column"
        >
          {{ value | itfgDateFormat: dateFormat  }}
        </ng-template>
      </itfg-data-table>
    </div>
  </ng-container>
</div>

