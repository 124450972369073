import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'itfg-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss'],
})
export class AuthComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
