import { Component, OnInit } from '@angular/core';
import { SimpleSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';

@Component({
  selector: 'itfg-data-update-prompt',
  templateUrl: './data-update-prompt.component.html',
  styleUrls: ['./data-update-prompt.component.scss'],
})
export class DataUpdatePromptComponent implements OnInit {
  constructor(private ref: MatSnackBarRef<DataUpdatePromptComponent>) {}

  ngOnInit() {}

  dismiss() {
    this.ref.dismissWithAction();
  }
}
