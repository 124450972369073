import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewContainerRef} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {IdCardImageTypes} from '../../../../core/types/identity-reports';
import {ClientDocument} from '../../../../core/types/client-document';
import {TranslateService} from '@ngx-translate/core';
import { DialogProviderService } from '@app/core/services/dialog-provider.service';
import {ClientService} from '../../../../core/services/client.service';
import { fileValidation } from '../../../../core/validation/file-validation';

@Component({
  selector: 'itfg-id-card-image-list',
  templateUrl: './id-card-image-list.component.html',
  styleUrls: ['./id-card-image-list.component.scss']
})
export class IdCardImageListComponent implements OnInit, OnChanges {
  @Input() selectedImageId: number;
  @Input() clientId: number;
  @Input() idCardList: ClientDocument[];
  @Input() idCardImageType: IdCardImageTypes;
  @Output() imageChange = new EventEmitter<any>();
  @Output() imageSave = new EventEmitter<any>();
  @Output() imageDelete = new EventEmitter<any>();

  idCardListData: ClientDocument[];
  idCardListDataForm: UntypedFormGroup;
  public IdCardImageTypes: typeof IdCardImageTypes = IdCardImageTypes;
  public maxFileSize: number;

  constructor(public _viewContainerRef: ViewContainerRef,
              public _dialogService: DialogProviderService,
              private formBuilder: UntypedFormBuilder,
              private translateService: TranslateService,
              private clientService: ClientService) {
    this.maxFileSize = 9; // 9MB
  }

  ngOnInit(): void {
    this.idCardListDataForm = this.createIdCardListDataForm();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.idCardListData = this.idCardList;
    this.idCardListDataForm = this.createIdCardListDataForm();
  }

  saveDocument(event) {
    event.preventDefault();
    this.imageSave.emit({
      documentType: this.idCardImageType,
      document: this.idCardListDataForm.value['newFile']
    });
  }

  changeImage(document) {
    this.imageChange.emit(document);
  }

  rotateImage(document, clockwise) {
    this.clientService.rotateClientDocument(this.clientId, document.id, clockwise)
      .subscribe(res => {
          this.imageChange.emit(document);
      },
        err => {
          console.log(err);
        });
  }

  deleteImage(document) {
    this.translateService
      .get(
        [
          'clients.deleteDocument',
          'global.confirm',
          'global.cancel',
          'global.delete',
        ],
        {
          document:
          document.name,
        }
      ).subscribe(translation => {
      const dialogConfig = {
        message: translation['clients.deleteDocument'],
        disableClose: false,
        viewContainerRef: this._viewContainerRef,
        title: translation['global.delete'],
        cancelButton: translation['global.cancel'],
        acceptButton: translation['global.confirm'],
      };
      this._dialogService
        .openConfirm(dialogConfig)
        .afterClosed()
        .subscribe((accept: boolean) => {
          if (accept) {
            this.imageDelete.emit(document);
          }
        });
    });

  }

  onFileSelected(file: File, control: UntypedFormControl) {
    control.patchValue(file);
  }

  createIdCardListDataForm() {
    const fb = this.formBuilder;
    return fb.group({
      newFile: ['', [fileValidation.maxSize]]
    });
  }

  inputControl(event) {
    event.preventDefault();
  }

}
