<div layout="row"
     layout-wrap
     layout-align="end end"
     class="position-buttons-fix">
  <div>
    <itfg-settings-menu
    (showFilterCardSettings)="onShowFilterCardSettings()"
    [columns]="claimColumns"
    [sessionSettingsPageName]="filterConfig.pageName"
  >
  </itfg-settings-menu>
  </div>
</div>
<div>
  <itfg-column-card #columnCard
                    hidden
                    [columns]="claimColumns"
                    [sessionSettingsPageName]="filterConfig.pageName">
  </itfg-column-card>
</div>
<div>
  <itfg-filter-card #filterCard
                    [filters]="filters"
                    [sessionSettingsPageName]="filterConfig.pageName"
                    [filtersConfig]="filterConfig.config"
                    [isShown]="filtersShown"
                    [isSettingsShown]="filterCardSettingsShown"
                    (filtersApplied)="onFiltersApplied($event)"
                    (setShownFilters)="filtersShown = $event"
                    (lastFilterRemoved)="onLastFilterRemoved()"></itfg-filter-card>
</div>
<itfg-claim-list #claimList
                 [claimColumns]="appliedColumns ? appliedColumns : claimColumns"
                 [searchOptions]="searchOptions"></itfg-claim-list>
