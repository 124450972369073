import {
  Component,
  OnInit,
  Input,
  ViewContainerRef,
  OnChanges,
  SimpleChanges,
  Optional,
  OnDestroy,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators, UntypedFormArray } from '@angular/forms';
import { DialogProviderService } from '@app/core/services/dialog-provider.service';
import { TranslateService } from '@ngx-translate/core';
import { ClientService } from '../../../../core/services/client.service';
import { NotificationService } from '../../../../core/services/notification.service';
import { Router } from '@angular/router';
import { Client } from '../../../../core/types/client';
import { ContactService } from '../../../../core/services/contacts.service';
import { CreditService } from '../../../../core/services/credit.service';
import {
  ContactPhone,
  ContactPhoneType,
  ContactPhoneDto,
} from '../../../../core/types/contact-phone';
import { mergeMap, skip, takeUntil } from 'rxjs/operators';
import { Subject, of } from 'rxjs';
import * as _ from 'lodash-es';
import { InteractionMode } from '../../../../core/types/interaction-mode';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CompanyPhonesComponent } from './company-phones/company-phones.component';
import { IdentityReportsService } from '../../../../core/services/identity-reports.service';
import { EventLogAddContactDialogComponent } from '@app/main/main-layout/right-drawer/event-log/event-log-add-contact/event-log-add-contact-dialog.component';

@Component({
  selector: 'itfg-contact-data-edit',
  templateUrl: './contact-data-edit.component.html',
  styleUrls: ['./contact-data-edit.component.scss'],
})
export class ContactDataEditComponent implements OnInit, OnChanges, OnDestroy {
  @Input() client: Client;
  @Input() phoneNumber: string;
  @Input() interactionMode: InteractionMode;
  @Input() inputContacts: ContactPhone[];
  @Input() inputPhoneTypes: ContactPhoneType[];
  @ViewChild('descriptionInputRef') descriptionInputRef: ElementRef<
    HTMLInputElement
  >;
  contactForms: UntypedFormArray = new UntypedFormArray([]);

  clientId: number;
  contactList: ContactPhone[];
  contactsPhoneType: ContactPhoneType[];
  saveBtnEnabled: boolean[] = [];
  modes: typeof InteractionMode;
  currDate: Date;
  isInCreditEdit: boolean;
  isInClientEdit: boolean;
  prevClientMobile: string;
  _unsubscribe: Subject<void> = new Subject<void>();

  constructor(
    public formBuilder: UntypedFormBuilder,
    public creditService: CreditService,
    public clientService: ClientService,
    public translate: TranslateService,
    public _viewContainerRef: ViewContainerRef,
    public _dialogService: DialogProviderService,
    private notificationService: NotificationService,
    private contactService: ContactService,
    private router: Router,
    @Optional()
    private dialogRef: MatDialogRef<EventLogAddContactDialogComponent>,
    private dialog: MatDialog,
    private identityReportsService: IdentityReportsService,
  ) {
    this.currDate = new Date();
    this.contactList = [];
    this.setInteractionMode(InteractionMode.EDIT);
    this.modes = InteractionMode;
  }

  ngOnInit() {
    this.isInCreditEdit = this.router.url.includes('credits');
    this.isInClientEdit = this.router.url.includes('clients');

    this.contactService.saveRequested
      .pipe(takeUntil(this._unsubscribe))
      .subscribe(() => {
        this.contactForms.controls.forEach((formGroup: UntypedFormGroup, index: number) => {
          if (this.saveBtnEnabled[index]) {
            this.onSaveContact(index);
          }
        });
      });

    this.clientService.onClientSaved
      .pipe(
        takeUntil(this._unsubscribe),
        mergeMap((savedClient: Client) => {
          if (this.prevClientMobile && this.prevClientMobile !== savedClient.mobile) {
            const newContactDto: ContactPhone = {
              id: null,
              type: {
                id: 1,
              },
              phone: this.prevClientMobile,
              description: '',
              active: false,
            };

            return this.contactService.createContact(
              this.client.id,
              newContactDto
            );
          } else {
            return of(null);
          }
        }),
        takeUntil(this._unsubscribe)
      )
      .subscribe(
        (newContact: ContactPhone) => {
          if (newContact) {
            this.saveBtnEnabled.unshift(false);
            const newContactform = this.createExistingContactForm(newContact);

            this.contactForms.insert(0, newContactform);
            this.watchForFormChanges(newContactform);

            this.updateContactList(newContact);
            this.notificationService.showLocalizedSuccessMessage({
              notificationText: 'communication.contactSuccessfullyAdded',
            });
          }
        },
        error => {
          console.error(error);
          this.notificationService.showLocalizedErrorMessage({
            notificationText: 'communication.contactAddError',
          });
        }
      );

    this.contactService.onContactAddedSubject
      .pipe(takeUntil(this._unsubscribe))
      .subscribe((contact: ContactPhone) => {
        this.setInitialData()
      })

  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      (changes.client && this.client && this.client.id) ||
      (changes.interactionMode && this.interactionMode === InteractionMode.NEW)
    ) {
      this.prevClientMobile = this.client.mobile;
      this.setInitialData();
    }

    if (this.interactionMode === InteractionMode.EDIT && this.client && this.client.id) {
      this.contactService.contactsSubject
        .pipe(takeUntil(this._unsubscribe))
        .subscribe((contacts: ContactPhone[]) => {
          if (contacts) {
            this.contactList = contacts;
            this.mapContactsToForm(this.contactList);
          }
        });
    }
  }

  // ngAfterViewInit(): void {
  //   if (this.phoneNumber && this.interactionMode === InteractionMode.NEW) {
  //     setTimeout(() => {
  //       this.focusDescriptionInput();
  //     }, 100); // Have to delay it or use AfterViewChecked
  //   }
  // }

  setInitialData() {
    if (this.interactionMode === this.modes.NEW) {
      this.getContactDataNewMode();
    }

    if (this.interactionMode === this.modes.EDIT) {
      this.getPhoneTypes();
      this.getContactList();
    }
  }

  setInteractionMode(interactionModeToSet: InteractionMode) {
    this.interactionMode = interactionModeToSet;
  }

  createExistingContactForm(contact: ContactPhone): UntypedFormGroup {
    const fb = this.formBuilder;
    return fb.group({
      id: contact.id,
      type: contact.type,
      phone: [
        contact.phone,
        [
          Validators.required,
          Validators.pattern(/^\+359[0-9]*$/),
          Validators.minLength(10),
          Validators.maxLength(13),
        ],
      ],
      description: [contact.description, Validators.maxLength(64)],
      active: contact.active,
      updatedAt: contact.updatedAt,
      updatedBy: contact.updatedBy,
    });
  }

  createNewContactForm(): UntypedFormGroup {
    const fb = this.formBuilder;
    return fb.group({
      id: null,
      type: [null, Validators.required],
      phone: [
        '',
        [
          Validators.required,
          Validators.pattern(/^\+359[0-9]*$/),
          Validators.minLength(10),
          Validators.maxLength(13),
        ],
      ],
      description: ['', Validators.maxLength(64)],
      active: false,
    });
  }

  getPhoneTypes() {
    return this.contactService
      .getContactTypes(this.client.id)
      .pipe(takeUntil(this._unsubscribe))
      .subscribe((phoneTypes: ContactPhoneType[]) => {
        this.contactsPhoneType = phoneTypes;
      });
  }

  getContactList() {
    return this.contactService
      .getContactList(this.client.id)
      .pipe(takeUntil(this._unsubscribe))
      .subscribe((contacts: ContactPhone[]) => {
        this.contactList = contacts.sort((a, b) => b.id - a.id);

        this.mapContactsToForm(this.contactList);
      });
  }

  mapContactsToForm(contactList: ContactPhone[]) {
    this.contactForms = this.formBuilder.array(
      contactList.map(
        (contact, formIndex): UntypedFormGroup => {
          const formGroup = this.createExistingContactForm(contact);
          this.saveBtnEnabled[formIndex] = false;
          this.watchForFormChanges(formGroup);
          return formGroup;
        }
      )
    );
  }

  getContactDataNewMode() {
    this.contactsPhoneType = this.inputPhoneTypes;
    this.contactList = this.inputContacts;
    this.addNewContactFormGroup();

    // if (this.phoneNumber) {
    //   const newContactForm: FormGroup = this.contactForms.controls[0] as FormGroup;
    //   newContactForm.patchValue({
    //     phone: this.phoneNumber,
    //     type: {
    //       id: 3,
    //     },
    //     description: "",
    //     active: true,
    //   })
    // }
  }

  watchForFormChanges(form: UntypedFormGroup) {
    const initialFormValue = _.cloneDeep(form.value);
    form.valueChanges
      .pipe(takeUntil(this._unsubscribe))
      .subscribe(() => {
        const isExistingContact = form.get('id').value !== null;
        const formIndex = this.contactForms.controls.indexOf(form);
        const hasFormValueChanged = !_.isEqual(form.value, initialFormValue);

        this.saveBtnEnabled[formIndex] =
          (isExistingContact && hasFormValueChanged && form.valid) ||
          (!isExistingContact && form.valid);

        const hasAnyFormChanged = this.saveBtnEnabled.some(isEnabled => isEnabled);

        if (form.valid && hasAnyFormChanged && this.interactionMode === InteractionMode.EDIT) {
          this.contactService.saveableContact.next(true);
        } else if (form.valid && !hasAnyFormChanged && this.interactionMode === InteractionMode.EDIT) {
          this.contactService.saveableContact.next(false);
        } else {
          this.contactService.saveableContact.next(false);
        }
      });
  }

  comparePhoneTypes(
    phoneTypeOne: ContactPhoneType,
    phoneTypeTwo: ContactPhoneType
  ) {
    return phoneTypeOne.id === phoneTypeTwo.id ? true : false;
  }

  addNewContactFormGroup() {
    const newContactForm = this.createNewContactForm();
    this.watchForFormChanges(newContactForm);

    this.contactForms.insert(0, newContactForm);
    this.saveBtnEnabled.unshift(false);
  }

  replaceWithExistingContactForm(formIndex: number, newForm: UntypedFormGroup) {
    this.contactForms.setControl(formIndex, newForm);
    this.watchForFormChanges(newForm);
  }

  focusDescriptionInput() {
    if (this.descriptionInputRef && this.descriptionInputRef.nativeElement) {
      this.descriptionInputRef.nativeElement.focus();
    }
  }

  deleteContactFormGroup(formIndex: number) {
    const contactForm = this.contactForms.at(formIndex);

    const contactDto: ContactPhoneDto | ContactPhone = contactForm.value;
    const contactId = contactDto.id;

    this.translate
      .get(
        [
          'clients.deleteContact',
          'global.confirm',
          'global.cancel',
          'global.delete',
        ],
        {
          contact: contactDto.phone,
        }
      )
      .pipe(takeUntil(this._unsubscribe))
      .subscribe(translation => {
        const dialogConfig = {
          message: translation['clients.deleteContact'],
          disableClose: false,
          viewContainerRef: this._viewContainerRef,
          title: translation['global.delete'],
          cancelButton: translation['global.cancel'],
          acceptButton: translation['global.confirm'],
        };

        if (contactId) {
          this._dialogService
            .openConfirm(dialogConfig)
            .afterClosed()
            .pipe(takeUntil(this._unsubscribe))
            .subscribe((accept: boolean) => {
              if (accept) {
                this.contactService
                  .deleteContact(this.client.id, contactId)
                  .pipe(takeUntil(this._unsubscribe))
                  .subscribe(
                    deletedContact => {
                      this.contactForms.removeAt(formIndex);
                      this.saveBtnEnabled.splice(formIndex, 1);

                      const hasAnyFormChanged = this.saveBtnEnabled.some(isEnabled => isEnabled);

                      if (!hasAnyFormChanged) {
                        this.contactService.saveableContact.next(false);
                      }

                      this.notificationService.showLocalizedSuccessMessage({
                        notificationText:
                          'communication.contactSuccessfullyDeleted',
                      });

                      this.contactList = this.contactList.filter(
                        contact => contact.id !== contactDto.id
                      );

                      this.contactService.contactsSubject.next(
                        this.contactList
                      );
                    },
                    error => {
                      console.error(error);
                      this.notificationService.showLocalizedErrorMessage({
                        notificationText: 'communication.contactDeleteError',
                      });
                    }
                  );
              }
            });
        } else {
          this.contactForms.removeAt(formIndex);
          this.saveBtnEnabled.splice(formIndex, 1);

          const hasAnyFormChanged = this.saveBtnEnabled.some(isEnabled => isEnabled);

          if (!hasAnyFormChanged) {
            this.contactService.saveableContact.next(false);
          }
        }
      });
  }

  onSaveContact(formIndex: number) {
    const contactForm = this.contactForms.at(formIndex);
    if (contactForm.valid) {
      const contactDto: ContactPhoneDto = contactForm.value;
      const contactId: number = contactDto.id;
      if (contactId) {
        // Existing contact. ID is a real number.
        this.contactService
          .editContact(this.client.id, contactId, contactDto)
          .pipe(takeUntil(this._unsubscribe))
          .subscribe(
            updatedContact => {
              this.saveBtnEnabled[formIndex] = false;
              const hasAnyFormChanged = this.saveBtnEnabled.some(isEnabled => isEnabled);

              if (!hasAnyFormChanged) {
                this.contactService.saveableContact.next(false);
              }

              const updatedContactForm = this.createExistingContactForm(
                updatedContact
              );

              this.replaceWithExistingContactForm(
                formIndex,
                updatedContactForm
              );

              this.updateContactList(updatedContact);
              this.notificationService.showLocalizedSuccessMessage({
                notificationText: 'communication.contactSuccessfullyUpdated',
              });
            },
            error => {
              console.error(error);
              this.notificationService.showLocalizedErrorMessage({
                notificationText: 'communication.contactEditError',
              });
            }
          );
      } else if (!contactId) {
        // New Contact. ID is null.
        this.contactService.createContact(this.client.id, contactDto)
          .pipe(takeUntil(this._unsubscribe))
          .subscribe(
            newContact => {
              this.saveBtnEnabled[formIndex] = false;
              const hasAnyFormChanged = this.saveBtnEnabled.some(isEnabled => isEnabled);

              if (!hasAnyFormChanged) {
                this.contactService.saveableContact.next(false);
              }

              const newForm = this.createExistingContactForm(newContact);
              this.replaceWithExistingContactForm(formIndex, newForm);

              this.updateContactList(newContact);
              if (this.interactionMode === this.modes.NEW) {
                this.dialogRef.close(true);
              }
              this.notificationService.showLocalizedSuccessMessage({
                notificationText: 'communication.contactSuccessfullyAdded',
              });
            },
            error => {
              console.error(error);
              this.notificationService.showLocalizedErrorMessage({
                notificationText: 'communication.contactAddError',
              });
            }
          );
      }
    } else {
      // Form is invalid
      this.notificationService.showLocalizedErrorMessage({
        notificationText: 'forms.formSavedIsInvalid',
      });
    }
  }

  updateContactList(updatedContact: ContactPhone) {
    const contactIndex = this.contactList.findIndex(
      contact => contact.id === updatedContact.id
    );

    if (contactIndex !== -1) {
      this.contactList[contactIndex] = updatedContact;
    } else {
      this.contactList = [updatedContact, ...this.contactList];
    }

    this.contactService.contactsSubject.next(this.contactList);
  }

  openCompanyPhones() {
    this.identityReportsService.getCompanyPhones$(this.client.employerId)
      .pipe(takeUntil(this._unsubscribe))
      .subscribe(res => {
        this.dialog.open(CompanyPhonesComponent, {
          minWidth: '1000px',
          minHeight: '560px',
          data: {
            identifier: this.client.employerId,
            clientId: this.client.id,
            phones: res,
            addContactType: this.contactsPhoneType.find(type => type.code === 'OTHER'), // TODO add contact types enum
            contactListPhones: this.contactList.map(contact => contact.phone),
          },
        });
      })
  }

  ngOnDestroy() {
    this._unsubscribe.next();
    this._unsubscribe.complete();
  }
}
