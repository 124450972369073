import { Injectable } from '@angular/core';
import { RequestService } from './request.service';
import { Observable } from 'rxjs';
import { PaymentMethod } from '../types/payment-method';

@Injectable()
export class PaymentMethodService {
  constructor(private request: RequestService) {}

  getPaymentMethodList(brandId?: number): Observable<PaymentMethod[]> {
    const options = brandId && { brandId };
    return this.request.get(['payment-methods'], { params: options });
  }

  comparePaymentMethods(
    firstMethod: PaymentMethod,
    secondMethod: PaymentMethod
  ) {
    if (firstMethod && secondMethod) {
      return firstMethod.name === secondMethod.name;
    } else {
      return false;
    }
  }
}
