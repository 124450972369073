<div *ngIf="data.title"
     class="modal-dialog-title">
  <p [ngClass]="{'itfg-warn': data.titleWarningStyle}">{{ data.title }}</p>
</div>

<div class="mat-dialog-content">
  <p class="dialog-message">
    <span [innerHtml]="data.message"></span>
  </p>
</div>

<div layout="row"
     layout-align="end center">
  <button *ngIf="data.redirectButton"
          mat-color="primary"
          mat-button
          (click)="onRedirect()">{{ data.redirectButton }}</button>
  <button mat-button
          [mat-dialog-close]="false"
          cdkFocusInitial>{{ data.cancelButton || ('global.cancel'|translate) }}</button>
  <button mat-button
          color="accent"
          [mat-dialog-close]="true">{{ data.acceptButton || ('global.confirm'|translate) }}</button>
</div>
