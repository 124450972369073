@if (!!faceVerification) {
  <div layout="row"
        layout-align="start center"
        matTooltip="{{faceVerification.createdAt | itfgDateFormat}}"
        matTooltipPosition="above"
        [ngStyle]="{ 'color': faceVerification.status === 'verification.accepted' ? 'green' : 'red' }"
        >
    <mat-icon>verified</mat-icon>
    <span>
      <span>{{ 'reports.identityVerification.idCardMatchConfidence' | translate }}</span><span class="match">{{faceVerification.faceMatchConfidence}}%</span>
    </span>
  </div>
}