import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
  ChangeDetectionStrategy,
  OnDestroy,
  ChangeDetectorRef,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ColumnsService } from '../../../../core/services/columns.service';
import { ListBaseComponent } from '@app/main/list-base/list-base.component';
import { ItfgDataTableColumn } from '@app/core/components/data-table/types/data-table.column';
import { takeUntil } from 'rxjs';
import { IdentityReportsService } from '@app/core/services/identity-reports.service';
import { DYNAMIC_CCR_SUMMARY_VARIABLES } from '@app/core/types/ccr';


@Component({
  selector: 'itfg-ccr-report-summary',
  templateUrl: './ccr-report-summary.component.html',
  styleUrls: ['./ccr-report-summary.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CcrReportSummaryComponent extends ListBaseComponent implements OnInit, OnChanges, OnDestroy {
  @Input() reportData: any;
  ccrSummary: any;
  columns: ItfgDataTableColumn[];
  appliedColumns: ItfgDataTableColumn[];
  sessionSettingsPageName = "ccrSummary"
  tableData: any;
  arrayData: any;
  translations: any;

  constructor(
    private translate: TranslateService,
    public columnsService: ColumnsService,
    public cdr: ChangeDetectorRef,
    private identityReportsService: IdentityReportsService,

  ) {
    super(columnsService);
  }

  ngOnInit(): void {
    this.translate.get(DYNAMIC_CCR_SUMMARY_VARIABLES.map(key => `identityReports.variables.${key}`))
      .subscribe(translations => {
        this.translations = translations;
        this.columns = DYNAMIC_CCR_SUMMARY_VARIABLES.map(key => {
          return {
            name: key,
            label: translations[`identityReports.variables.${key}`]

          }
        })
      });
    this.columnsTableChangeSubscription();
  }

  columnsTableChangeSubscription() {
    this.columnsService.onColumnsTableChange
      .pipe(takeUntil(this._unsubscribe))
      .subscribe(columns => {
        this.appliedColumns = columns;
        this.cdr.detectChanges();
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.reportData?.status === 'success') {

      this.identityReportsService.getDynamicCCRSummary(this.reportData.id)
        .subscribe(data => {
          this.arrayData = Object.entries(data);
          this.ccrSummary = data;
          this.tableData = [data];
          this.cdr.detectChanges();
        })

    } else {
      this.tableData = undefined;
      this.cdr.detectChanges();
    }
  }


  ngOnDestroy(): void {
    this._unsubscribe.next();
    this._unsubscribe.complete();
  }
}
