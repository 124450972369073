import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
  selector: 'itfg-pbx-notification',
  templateUrl: './pbx-notification.component.html',
  styleUrls: ['./pbx-notification.component.scss']
})
export class PbxNotificationComponent {
  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: { message: string },
  ) { }
}
