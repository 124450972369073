import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ItfgDataTableColumn } from '../../../../core/components/data-table/types/data-table.column';

@Component({
  selector: 'itfg-settings-menu',
  templateUrl: './settings-menu.component.html',
  styleUrls: ['./settings-menu.component.css']
})
export class SettingsMenuComponent {

  @Input() columns: ItfgDataTableColumn[];
  @Input() sessionSettingsPageName: String;
  @Output() showFilterCardSettings: EventEmitter<null> = new EventEmitter(null);

  constructor() { }

  showMenu():void {
    console.log(this.showFilterCardSettings);
    this.showFilterCardSettings.emit(null);
  }

}
