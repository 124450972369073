import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './home/home.component';
import { MainComponent } from './main.component';
import { MainGuardService } from './main-guard.service';
import { UnauthorizedPageComponent } from '../auth/unauthorized-page/unauthorized-page.component';
import { ResourceNotFoundComponent } from '../auth/resource-not-found/resource-not-found.component';
import { APP_CONFIG } from '../config/app-config';
import { PageNotFoundComponent } from '../auth/page-not-found/page-not-found.component';
import { MainPagesMenuComponent } from './main-layout/main-pages-menu/main-pages-menu.component';
import { AdministratorProfileComponent } from './administration/administrators/administrator-profile/administrator-profile.component';
import { NssiStandaloneComponent } from './identity-reports/nssi-standalone/nssi-standalone.component';
import { AffiliatePageCreateComponent } from './credit/affiliate-page/affiliate-page-create.component';
import { MiStandaloneComponent } from './identity-reports/mi-standalone/mi-standalone.component';
import { CcrStandaloneComponent } from './identity-reports/ccr-standalone/ccr-standalone.component';
import { ngxPermissionsGuard } from 'ngx-permissions';

const routes: Routes = [
  {
    path: '',
    component: MainComponent,
    canActivate: [MainGuardService],
    canActivateChild: [ngxPermissionsGuard],
    data: {
      permissions: {}
    },
    children: [
      {
        path: '403',
        component: UnauthorizedPageComponent,
        data: {
          permissions: {}
        },
      },
      {
        path: 'home',
        component: HomeComponent,
        data: {
          titleKey: 'global.home',
          permissions: {}
        },
      },
      {
        path: 'products',
        loadChildren: () =>
          import('./product/product.module').then(m => m.ProductModule),
        data: { titleKey: 'global.products', permissions: {} },
      },
      {
        path: 'administration',
        loadChildren: () =>
          import('./administration/administration.module').then(
            m => m.AdministrationModule
          ),
        data: {
          titleKey: 'global.administration',
          breadcrumbKey: 'global.administration',
          permissions: {}
        },
      },
      {
        path: 'collection',
        loadChildren: () =>
          import('./collection/collection.module').then(
            m => m.CollectionModule
          ),
        data: {
          titleKey: 'global.collection',
          breadcrumbKey: 'global.collection',
          permissions: {}
        },
      },
      {
        path: 'clients',
        loadChildren: () =>
          import('./client/client.module').then(m => m.ClientModule),
        data: { titleKey: 'global.clients', permissions: {} },
      },
      {
        path: 'credits',
        loadChildren: () =>
          import('./credit/credit.module').then(m => m.CreditModule),
        data: { titleKey: 'global.credits', permissions: {} },
      },
      // {
      //   path: 'payments',
      //   loadChildren: './payment/payment.module#PaymentModule',
      //   data: { 'titleKey': 'global.payments' }
      // },
      {
        path: 'p2p',
        children: [
          {
            path: '',
            loadChildren: () =>
              import('./p2p-platform/p2p-platform.module').then(
                m => m.P2pPlatformModule
              ),
          }
        ],
        data: {
          breadcrumbKey: 'p2p.platforms',
          titleKey: 'p2p.platforms',
          permissions: ['P2P_LOANS_READ'],
        },
      },
      {
        path: 'payments',
        children: [
          {
            path: '',
            component: MainPagesMenuComponent,
          },
          {
            path: 'transactions',
            loadChildren: () =>
              import('./transaction/transaction.module').then(
                m => m.TransactionModule
              ),
            data: {
              breadcrumbKey: 'global.transactions',
              titleKey: 'global.transactions',
              permissions: {
                only: ['TRANSACTION_READ'],
                redirectTo: APP_CONFIG.ACCESS_DENIED_ROUTE,
              },
            },
          },
          {
            path: 'accounts',
            loadChildren: () =>
              import('./account/account.module').then(m => m.AccountModule),
            data: { breadcrumbKey: 'products.accounts', permissions: {} },
          },
          {
            path: 'bank',
            loadChildren: () =>
              import('./payment/bank-automation/bank-automation.module').then(m => m.BankAutomationModule),
            data: { breadcrumbKey: 'payments.banks.titleKey', permissions: {} },
          },
        ],
        data: {
          breadcrumbKey: 'global.payments',
          titleKey: 'global.payments',
          permissions: {}
        },
      },
      {
        path: 'claims',
        loadChildren: () =>
          import('./claim/claim.module').then(m => m.ClaimModule),
        data: {
          breadcrumbKey: 'clients.claims',
          titleKey: 'clients.claims',
          permissions: {}
        },
      },
      {
        path: 'bnb-reports',
        loadChildren: () =>
          import('./bnb-reports/bnb-reports.module').then(
            m => m.BnbReportsModule
          ),
        data: {
          breadcrumbKey: 'reports.bnbReports',
          titleKey: 'reports.bnbReports',
          permissions: {}
        },
      },
      {
        path: 'communication',
        loadChildren: () =>
          import('./client-communications/client-communications.module').then(
            m => m.ClientCommunicationModule
          ),
        data: {
          breadcrumbKey: 'clientComunications.communication',
          titleKey: 'clientComunications.communication',
          permissions: {}
        },
      },
      {
        path: 'nssi-reports',
        component: NssiStandaloneComponent,
        data: {
          breadcrumbKey: 'nssi.reports',
          titleKey: 'nssi.reports',
          permissions: {
            only: ['NSSI_READ'],
            redirectTo: APP_CONFIG.ACCESS_DENIED_ROUTE,
          }
        },
      },
      {
        path: 'mi-reports',
        component: MiStandaloneComponent,
        data: {
          breadcrumbKey: 'mi.reports',
          titleKey: 'mi.reports',
          permissions: {
            only: ['MI_READ'],
            redirectTo: APP_CONFIG.ACCESS_DENIED_ROUTE,
          }
        },
      },
      {
        path: 'ccr-reports',
        component: CcrStandaloneComponent,
        data: {
          breadcrumbKey: 'ccr.reports',
          titleKey: 'ccr.reports',
          permissions: {
            only: ['CCR_READ'],
            redirectTo: APP_CONFIG.ACCESS_DENIED_ROUTE,
          }
        },
      },
      {
        path: 'me',
        data: {
          permissions: {}
        },
        children: [
          {
            path: 'details',
            component: AdministratorProfileComponent,
            data: {
              breadcrumbKey: 'global.currentOperatorDetails',
              titleKey: 'global.currentOperatorDetails',
              permissions: {}
            },
          },
        ],
      },
      {
        path: 'affiliates',
        children: [
          {
            path: ':userId',
            component: AffiliatePageCreateComponent,
            data: {
              breadcrumbKey: 'global.affiliates',
              titleKey: 'global.affiliates',
              permissions: {
                only: ['AFFILIATE'],
                redirectTo: APP_CONFIG.ACCESS_DENIED_ROUTE,
              }
            },
          },
        ]
      },
      {
        path: 'resource-not-found',
        component: ResourceNotFoundComponent,
        data: {
          titleKey: 'error.resourceNotFound',
          permissions: {}
        }
      },
      {
        path: 'page-not-found',
        component: PageNotFoundComponent,
        data: {
          titleKey: 'error.pageNotFound',
          permissions: {}
        }
      },
      {
        path: 'communication',
        loadChildren: () =>
          import('./client-communications/client-communications.module').then(m => m.ClientCommunicationModule),
        data: {
          titleKey: 'clientComunications.communication',
          breadcrumbKey: 'clientComunications.communication',
          permissions: {}
        },
      },
      {
        path: '',
        pathMatch: 'full',
        redirectTo: '/home',
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [MainGuardService],
})
export class MainRoutingModule {
}
