import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BankAccountPaymentsPayin } from '../types/bank-account-payments-transactions';
import { Reader } from '../types/reader';
import { PaymentsApiRequestService } from './payments.request.service';

@Injectable()
export class PaymentsApiService {
  constructor(private request: PaymentsApiRequestService) {}

  sendEpayRevertRequest$(transactionId: number) {
    return this.request.post(['epay', 'payout', 'reverse', transactionId]);
  }

  startBulbankWebdriver$() {
    return this.request.post(['webdriver', 'unicredit', 'start']);
  }

  pauseBulbankWebdriver$(pauseDuration: number = 600) {
    return this.request.post(['webdriver', 'unicredit', 'pause'], {
      params: {
        pauseDurationInSeconds: pauseDuration,
      }
    });
  }

  restartBulbankWebdriver$() {
    return this.request.post(['webdriver', 'unicredit', 'restart']);
  }

  stopBulbankWebdriver$() {
    return this.request.post(['webdriver', 'unicredit', 'quit']);
  }

  getCurrentDriverSession$(): Observable<any> {
    return this.request.get(['webdriver', 'unicredit', 'session']);
  }

  sendUNCRAuthCode$(code: string) {
    return this.request.post(['webdriver', 'receive-message'], {
      body: {
        address: '',
        body: `Булбанк Онлайн: ${code}`,
        date: new Date(),
      }
    })
  }

  takeScreenshot$(): Observable<any> {
    return this.request.post(['webdriver', 'unicredit', 'screenshot'])
  }

  getBankPayins$(options?: any): Observable<any> {
    const crudOptions = { ...options, filter: options.criteria };
    delete crudOptions.criteria;
    return this.request.get(['bank-payin'],{ params: crudOptions })
  }

  addBankPayin$(body): Observable<any> {
    return this.request.post(['bank-payin'],{ body: body })
  }

  //by ID
  getBankPayinById$(id?: any): Observable<any> {
    return this.request.get(['bank-payin', id])
  }

  getBankAccounts$(): Observable<BankAccountPaymentsPayin[]> {
    return this.request.get(['bank-account'])
  }
  
  updateAccount$(updatedAccount: BankAccountPaymentsPayin): Observable<BankAccountPaymentsPayin> {
    return this.request.post(['bank-account'], {
      body: updatedAccount
    })
  }
  
  getBankReaders$(): Observable<Reader[]> {
    return this.request.get(['bank-reader'])
  }


  getPayinStatuses$(): Observable<any> {
    return this.request.get(['bank-payin','status'])
  }

  setPayinStatusesComplete$(id: string, creditId: string ): Observable<any> {
    return this.request.post(['bank-payin',id,'status','completed'], {
      body: {
        creditId: creditId
      }
    })
  }

  setPayinStatusesCanceled$(id: string ): Observable<any> {
    return this.request.post(['bank-payin',id,'status','canceled'])
  }

  setPayinStatusesManual$(id: string ): Observable<any> {
    return this.request.post(['bank-payin',id,'status','manual'])
  }

  editBankPayinAmount$(id: number, amount: number) {
    return this.request.post(['bank-payin', id, 'amount'], {
      body: {
        amount,
      }
    })
  }

  editBankValueDate$(id: number, value_date: string) {
    return this.request.post(['bank-payin', id, 'value_date'], {
      body: {
        value_date,
      }
    })
  }

}
