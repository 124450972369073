import { Component, Inject } from '@angular/core';
import { ReminderService } from '../../../../../core/services/reminder.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Reminder } from '../../../../../core/types/reminder';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import * as moment from 'moment/moment';
import { CustomErrorStateMatcher } from '../../../../../core/validation/error-state-matcher';
import { FormManipulationService } from '../../../../../core/services/form-manipulation.service';
import { ErrorService } from '../../../../../core/services/error.service';
import { HttpErrorResponse } from '@angular/common/http';
import { NotificationService } from '../../../../../core/services/notification.service';
import { ReminderOption } from '../credit-note-edit/reminder-option';
import { Credit } from '../../../../../core/types/credit';
import { SessionService } from '../../../../../core/services/session.service';

@Component({
  selector: 'itfg-reminder-edit',
  templateUrl: './reminder-edit.component.html',
  styleUrls: ['./reminder-edit.component.scss'],
})
export class ReminderEditComponent {
  public datepickerStartDate: Date = new Date();
  public reminderForm: UntypedFormGroup;
  public reminder: Reminder;
  public timeMatcher = new CustomErrorStateMatcher('time');
  public dateMatcher = new CustomErrorStateMatcher('date');
  public reminderOptionList: any = this.createReminderOptionList();
  showCustomOption: boolean = true;

  constructor(
    private notifications: NotificationService,
    private reminderService: ReminderService,
    private formManipulationService: FormManipulationService,
    private errorService: ErrorService,
    private notificationService: NotificationService,
    private dialogRef: MatDialogRef<Reminder>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      reminder: Reminder;
      credit: Credit;
    },
    public fb: UntypedFormBuilder,
    public session: SessionService
  ) {
    this.reminder = data.reminder;
    this.reminderForm = this.createReminderForm();
    this.reminderForm
      .get('reminder.date')
      .valueChanges.subscribe(this.reminderDateValueChanged.bind(this));
  }

  onSave(event) {
    if (this.reminderForm.valid) {
      this.formManipulationService.markAllControlsTouched(this.reminderForm);
      const { date } = this.reminderForm.get('reminder').value;

      this.reminderService
        .createReminder$({
          time: date?.timestampFunc()?.toDate(),
          credit: this.data.credit.id,
          operatorId: this.session.currentlyLoggedOperator.id,
        })
        .subscribe({
          next: () => {
            this.reminderService.refreshReminder$.next();

            this.notificationService.showLocalizedSuccessMessage({
              notificationText: 'communication.reminderSuccessfullyAdded',
            });
            
            this.dialogRef.close(true);
          },
          error: (error: HttpErrorResponse) => {
            this.errorService.showSnackbarErrorMessage(error);
          },
        });
    } else {
      this.formManipulationService.markAllControlsTouched(this.reminderForm);
      this.notificationService.showLocalizedErrorMessage({
        notificationText: 'forms.formSavedIsInvalid',
      });
    }
  }

  dateChosen(date) {
    this.reminderService
      .createReminder$({
        time: date,
        credit: this.data.credit.id,
        operatorId: this.session.currentlyLoggedOperator.id,
      })
      .subscribe({
        next: () => {
          this.reminderService.refreshReminder$.next();
          this.dialogRef.close(true);
          this.notifications.showLocalizedSuccessMessage({
            notificationText: 'communication.reminderSuccessfullyAdded',
          });
        },
        error: (error: HttpErrorResponse) => {
          this.errorService.showSnackbarErrorMessage(error);
        },
      });
  }

  createReminderForm() {
    const fb = this.fb;
    return fb.group({
      reminder: fb.group(
        {
          date: this.reminderOptionList[this.reminderOptionList.length - 1],
          time: moment(this.reminder?.time).format('HH:mm'),
        },
        {
          // validator: [timeValidation.timeDependsOn('time', 'date', 5)],
        }
      ),
    });
  }

  reminderDateValueChanged(value) {
    if (value?.text === 'Точно време...') {
      this.showCustomOption = true;
      // this.dialog.open(ReminderChooseCustomOptionComponent, {
      //   autoFocus: false,
      // })
      //   .afterClosed()
      //   .subscribe(selectedValue => {
      //     if (!!selectedValue) {
      //       const option = new ReminderOption({
      //         timestampFunc: () => selectedValue,
      //         text: selectedValue.fromNow()
      //       });
      //       this.reminderOptionList.splice(this.reminderOptionList.length - 1, 0, option);
      //       this.conversationForm.get('reminder.date').setValue(option);
      //     } else {
      //       this.conversationForm.get('reminder.date').setValue(null)
      //     }
      //   });
    } else {
      this.showCustomOption = false;
    }
  }

  onNoClick() {
    this.dialogRef.close();
  }

  createReminderOptionList(): ReminderOption[] {
    return [
      new ReminderOption({
        timestampFunc: () => moment().add(1, 'hour'),
        text: 'След 1 час',
      }),
      new ReminderOption({
        timestampFunc: () => moment().add(2, 'hour'),
        text: 'След 2 часа',
      }),
      new ReminderOption({
        timestampFunc: () =>
          moment().set({ hour: 17, minute: 0, second: 0, millisecond: 0 }),
        text: 'В края на деня',
      }),
      new ReminderOption({
        timestampFunc: () =>
          moment()
            .add(1, 'day')
            .set({ hour: 9, minute: 0, second: 0, millisecond: 0 }),
        text: 'Утре',
      }),
      new ReminderOption({
        timestampFunc: () =>
          moment()
            .add(2, 'day')
            .set({ hour: 9, minute: 0, second: 0, millisecond: 0 }),
        text: 'След 2 дни',
      }),
      new ReminderOption({
        timestampFunc: () =>
          moment().set({ d: 5, hour: 9, minute: 0, second: 0, millisecond: 0 }),
        text: 'В края на седмицата',
      }),
      new ReminderOption({
        text: 'Точно време...',
      }),
    ];
  }

  close() {
    this.dialogRef.close();
  }
}
