import { SearchCriterium } from './search-criterium';

export enum SearchDirection {
  ASCENDING = 'ASC',
  DESCENDING = 'DESC',
}

export interface SearchOptionsDTO {
  criteria?: string | string[];
  page?: number;
  pageSize?: number;
  direction?: string;
  column?: string;
  sort?: string;
}

export interface SearchOptionsConstructorParam {
  criteria?: SearchCriterium[];
  page?: number;
  pageSize?: number;
  direction?: SearchDirection;
  column?: string;
}

export class SearchOptions {
  criteria: SearchCriterium[];
  page: number;
  pageSize: number;
  direction: SearchDirection;
  column: string;

  constructor(
    {
      criteria = [],
      page = 0,
      pageSize = 20,
      direction = SearchDirection.DESCENDING,
      column = 'id',
    }: SearchOptionsConstructorParam = {
      criteria: [],
      page: 0,
      pageSize: 20,
      direction: SearchDirection.DESCENDING,
      column: 'id',
    }
  ) {
    this.criteria = criteria;
    this.page = page;
    this.pageSize = pageSize;
    this.direction = direction;
    this.column = column;
  }

  getDTO(): SearchOptionsDTO {
    return {
      criteria: JSON.stringify(this.criteria),
      page: this.page,
      pageSize: this.pageSize,
      direction: this.direction,
      column: this.column,
    };
  }

  getDTOCrud(): SearchOptionsDTO {
    return {
      criteria: this.criteria.map(el => `${el.key}||${el.operation}||${el.value}`),
      page: this.page,
      pageSize: this.pageSize,
      sort: `${this.column},${this.direction}`,
    };
  }

  addCriterium(searchCriterium: SearchCriterium) {
    this.criteria.push(searchCriterium);
  }
}
