import { Component, Input, OnInit } from '@angular/core';
import { ColumnCardComponent } from '../column-card/column-card.component';
import { ItfgDataTableColumn } from '@app/core/components/data-table/types/data-table.column';
import { PageName } from '../../../core/types/user-preferences';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'itfg-column-button',
  templateUrl: './column-button.component.html',
  styleUrls: ['./column-button.component.scss'],
})
export class ColumnButtonComponent implements OnInit {
  @Input() columns: ItfgDataTableColumn[];
  @Input() sessionSettingsPageName: PageName;

  constructor(public dialog: MatDialog) {}

  ngOnInit() {}

  toggleColumns() {
    const dialogRef = this.dialog.open(ColumnCardComponent, {
      data: {
        columns: this.columns,
        sessionSettingsPageName: this.sessionSettingsPageName,
      },
      width: '450px',
      autoFocus: false,
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(result, 'result');
    });
  }
}
