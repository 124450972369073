<mat-card appearance="outlined"
          [@cardState]>
  <mat-card-content>
    <form (ngSubmit)="applyFilters()"
          class="remove-mat-form-field-wrapper">
      <div flex
           layout="row"
           layout-wrap
           cdkDropListGroup
           class="cdk-drop-list-group">
        <ng-container *ngFor="let filter of filterList; let filterIndex = index"
                      [ngSwitch]="filter.config && filter.config.type">
          <div *ngIf="filter.config && filter.config.type !== filterUITypes.multiselectAutocomplete && reorderMode && !slim"
               cdkDropList
               [cdkDropListData]="filterIndex"
               (cdkDropListDropped)="drop($event)"
               [ngClass]="reorderMode ? 'draggable' : ''"
               layout="row"
               flex-gt-xs="50"
               flex-gt-sm="33"
               flex-gt-md="20"
               flex="100"
               >
            <div 
                cdkDrag
                [cdkDragData]="filterIndex"
                class="white-box-container"
                flex>
              <div class="custom-placeholder" *cdkDragPlaceholder></div>
              <div>{{ ( filter.config.nameTranslationKey | translate ) + ' ' + (filter.criterium.operation |
                searchOperationTranslationMap | translate) }}
              <mat-icon>reorder</mat-icon>  
              </div>
            </div>
          </div>
          <div *ngIf="filter.config && filter.config.type !== filterUITypes.multiselectAutocomplete && reorderMode && slim"
               cdkDropList
               [cdkDropListData]="filterIndex"
               (cdkDropListDropped)="drop($event)"
               [ngClass]="reorderMode ? 'draggable' : ''"
               layout="row"
               flex-gt-xs="100"
               flex-gt-sm="50"
               flex-gt-md="50"
               flex="100"
               >
            <div 
                cdkDrag
                [cdkDragData]="filterIndex"
                class="white-box-container"
                flex>
              <div class="custom-placeholder" *cdkDragPlaceholder></div>
              <div>{{ ( filter.config.nameTranslationKey | translate ) + ' ' + (filter.criterium.operation |
                searchOperationTranslationMap | translate) }}
              <mat-icon>reorder</mat-icon>  
              </div>
            </div>
          </div>
          <div *ngIf="filter.config && filter.config.type !== filterUITypes.multiselectAutocomplete && slim && !reorderMode"
               layout="row"
               flex-gt-xs="100"
               flex-gt-sm="100"
               flex-gt-md="50">
            <ng-container *ngTemplateOutlet="filterTemplates"></ng-container>
          </div>
          <div *ngIf="filter.config && filter.config.type !== filterUITypes.multiselectAutocomplete && !slim && !reorderMode"
               layout="row"
               flex-gt-xs="50"
               flex-gt-sm="33"
               flex-gt-md="20"
               flex="100">
            <ng-container *ngTemplateOutlet="filterTemplates"></ng-container>
          </div>

          <ng-template #filterTemplates>
            <mat-form-field *ngSwitchCase="filterUITypes.input"
                            flex>
              <input #valueInput
                     type="text"
                     matInput
                     [disabled]="reorderMode"
                     [(ngModel)]="filter.criterium.value"
                     [ngModelOptions]="{standalone: true}">
              <mat-label>
                <span>{{ ( filter.config.nameTranslationKey | translate ) + ' ' + (filter.criterium.operation |
                  searchOperationTranslationMap | translate) }}</span>
              </mat-label>
              <button matSuffix
                      tabindex="-1"
                      type="button"
                      mat-icon-button
                      matTooltip="{{ (valueInput.value | isEmptyFilter) ? ('global.remove' | translate) : ('global.clear' | translate) }}"
                      color="{{ (valueInput.value | isEmptyFilter) ? 'warn' : 'black'}}"
                      (click)="removeFilterOrValue($event, filterIndex)">
                <mat-icon>clear</mat-icon>
              </button>
            </mat-form-field>
            <mat-form-field *ngSwitchCase="filterUITypes.dropdown"
                            
                            flex>
              <mat-label>
                <span>{{ filter.config.nameTranslationKey | translate }}</span>
              </mat-label>
              <mat-select #dropdown
                          (openedChange)="matSelectOpenedChange($event, filterIndex)"
                          [disabled]="reorderMode"
                          [(value)]="filter.criterium.value"
                          disableOptionCentering="true">
                <mat-option>
                  <ngx-mat-select-search [formControl]="filterOptionsCtrl"
                                         placeholderLabel="{{ 'filtering.searchValues' | translate }}"
                                         noEntriesFoundLabel="{{ 'filtering.noResultsFound' | translate }}">
                  </ngx-mat-select-search>
                </mat-option>
                <mat-option *ngFor="let filterValue of filter.filterValues"
                            [value]="filterValue.value"
                            [matTooltip]="filterValue.displayText | translate"
                            matTooltipPosition="right">
                  <span>
                    {{ filterValue.displayText| translate }}
                  </span>
                </mat-option>
              </mat-select>
              <button matSuffix
                      tabindex="-1"
                      type="button"
                      mat-icon-button
                      matTooltip="{{ (dropdown.value | isEmptyFilter) ? ('global.remove' | translate) : ('global.clear' | translate) }}"
                      color="{{ (dropdown.value | isEmptyFilter) ? 'warn' : 'black'}}"
                      (click)="removeFilterOrValue($event, filterIndex)">
                <mat-icon>clear</mat-icon>
              </button>
            </mat-form-field>
            <mat-form-field *ngSwitchCase="filterUITypes.multiselect"
                            
                            flex>
              <mat-label>
                <span>{{ filter.config.nameTranslationKey | translate }}</span>
              </mat-label>
              <mat-select #valueInput
                          multiple
                          [disabled]="reorderMode"
                          (openedChange)="matSelectOpenedChange($event, filterIndex)"
                          name="first"
                          [(ngModel)]="filter.criterium.value"
                          [ngModelOptions]="{standalone: true}"
                          disableOptionCentering="true">
                <mat-option>
                  <ngx-mat-select-search [formControl]="filterOptionsCtrl"
                                         placeholderLabel="{{ 'filtering.searchValues' | translate }}"
                                         noEntriesFoundLabel="{{ 'filtering.noResultsFound' | translate }}">
                  </ngx-mat-select-search>
                </mat-option>
                <mat-option *ngFor="let filterValue of filter.filterValues"
                            [value]="filterValue.value"
                            [matTooltip]="filterValue.displayText | translate"
                            matTooltipPosition="right">
                  {{filterValue.displayText }}
                </mat-option>
              </mat-select>
              <button matSuffix
                      type="button"
                      mat-icon-button
                      matTooltip="{{ (valueInput.value | isEmptyFilter) ? ('global.remove' | translate) : ('global.clear' | translate) }}"
                      color="{{ (valueInput.value | isEmptyFilter) ? 'warn' : 'black'}}"
                      tabindex="-1"
                      (click)="removeFilterOrValue($event, filterIndex)">
                <mat-icon>clear</mat-icon>
              </button>
            </mat-form-field>
            <mat-form-field *ngSwitchCase="filterUITypes.autocomplete"
                            
                            flex>
              <mat-label>
                <span>{{ ( filter.config.nameTranslationKey | translate ) + ' ' + (filter.criterium.operation |
                  searchOperationTranslationMap | translate) }}</span>
              </mat-label>
              <input #valueInput
                     [disabled]="reorderMode"
                     type="text"
                     matInput
                     itfgSelectFirstAutocompleteOptionOnBlur
                     [(ngModel)]="filter.criterium.value"
                     [ngModelOptions]="{standalone: true}"
                     [matAutocomplete]="auto"
                     (input)="refreshAutocompleteValues(filterIndex, $event.target.value)">
              <mat-autocomplete #auto="matAutocomplete"
                                itfgHighlightFirstAutocompleteOptionOnChange
                                [autoActiveFirstOption]="true"
                                [displayWith]="mapValueToDisplayText">
                <mat-option *ngFor="let filterValue of filter.filterValues"
                            [value]="filterValue"
                            [matTooltip]="filterValue.displayText | translate"
                            matTooltipPosition="right">
                  <span>
                    {{ filterValue.displayText }}
                  </span>
                </mat-option>
              </mat-autocomplete>
              <button matSuffix
                      type="button"
                      mat-icon-button
                      matTooltip="{{ (valueInput.value | isEmptyFilter) ? ('global.remove' | translate) : ('global.clear' | translate) }}"
                      color="{{ (valueInput.value | isEmptyFilter) ? 'warn' : 'black'}}"
                      tabindex="-1"
                      (click)="removeFilterOrValue($event, filterIndex)">
                <mat-icon>clear</mat-icon>
              </button>
            </mat-form-field>
            <mat-form-field *ngSwitchCase="filterUITypes.datepicker"
                            
                            flex>
              <input #valueInput
                     type="text"
                     matInput
                     readonly
                     [disabled]="reorderMode"
                     [matDatepicker]="picker"
                     [(ngModel)]="filter.criterium.value"
                     [ngModelOptions]="{standalone: true}"
                     (click)="showDatepicker($event, picker)">
              <mat-label>
                <span>{{ (filter.config.nameTranslationKey | translate) + ' ' + (filter.criterium.operation |
                  searchOperationTranslationMap | translate) }}</span>
              </mat-label>
              <div matSuffix
                   layout="row">
                <div>
                  <mat-datepicker-toggle [for]="picker"></mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
                </div>
                <button type="button"
                        mat-icon-button
                        matTooltip="{{valueInput.value === '' ? ('global.remove' | translate) : ('global.clear' | translate) }}"
                        color="{{valueInput.value === '' ? 'warn' : 'black'}}"
                        tabindex="-1"
                        (click)="removeFilterOrValue($event, filterIndex)">
                  <mat-icon>clear</mat-icon>
                </button>
              </div>
            </mat-form-field>
            <mat-form-field *ngSwitchDefault
                            
                            flex>
              <input #valueInput
                     type="text"
                     matInput
                     disabled>
              <mat-label>
                <span [translate]="'global.value'">Value</span>
              </mat-label>
              <button matSuffix
                      type="button"
                      mat-icon-button
                      matTooltip="{{ (valueInput.value | isEmptyFilter) ? ('global.remove' | translate) : ('global.clear' | translate) }}"
                      color="{{ (valueInput.value | isEmptyFilter) ? 'warn' : 'black'}}"
                      tabindex="-1"
                      (click)="removeFilterOrValue($event, filterIndex)">
                <mat-icon>clear</mat-icon>
              </button>
            </mat-form-field>
          </ng-template>

          <div *ngIf="filter.config && filter.config.type === filterUITypes.multiselectAutocomplete"
               flex="100"
               flex-gt-xs="100"
               flex-gt-sm="100"
               flex-gt-md="100"
               flex
               layout="row"
               [ngClass]="reorderMode ? 'draggable' : ''">
            <mat-form-field *ngSwitchCase="filterUITypes.multiselectAutocomplete"
                            
                            flex>
              <mat-chip-grid #chipList>
                <mat-chip-row *ngFor="let filterValue of filter.criterium.value; let filterValueIndex = index"
                              [removable]="true"
                              (removed)="removeChipSelection(filterIndex, filterValueIndex)">
                  {{ filterValue.displayText }}
                  <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip-row>
                <input #valueInput
                       type="text"
                       [disabled]="reorderMode"
                       matInput
                       itfgSelectFirstAutocompleteOptionOnBlur
                       [matChipInputFor]="chipList"
                       [matAutocomplete]="chipAuto"
                       (input)="refreshAutocompleteValues(filterIndex, $event.target.value)" />
              </mat-chip-grid>
              <mat-label>
                <span>{{ filter.config.nameTranslationKey | translate }}</span>
              </mat-label>
              <mat-autocomplete #chipAuto="matAutocomplete"
                                itfgHighlightFirstAutocompleteOptionOnChange
                                [autoActiveFirstOption]="true"
                                (optionSelected)="chipOptionSelected(filterIndex, $event)">
                <mat-option *ngFor="let filterValue of filter.filterValues"
                            [value]="filterValue"
                            [matTooltip]="filterValue.displayText | translate"
                            matTooltipPosition="right">
                  <span>
                    {{ filterValue.displayText }}
                  </span>
                </mat-option>
              </mat-autocomplete>
              <button matSuffix
                      type="button"
                      mat-icon-button
                      matTooltip="{{ (valueInput.value | isEmptyFilter) ? ('global.remove' | translate) : ('global.clear' | translate) }}"
                      color="{{ (valueInput.value | isEmptyFilter) ? 'warn' : 'black'}}"
                      tabindex="-1"
                      (click)="removeFilterOrValue($event, filterIndex)">
                <mat-icon>clear</mat-icon>
              </button>
            </mat-form-field>
          </div>
        </ng-container>
      </div>

      <div layout="row"
           layout-align="start start"
           layout-wrap
           #filterActions>
        <!--<button type="button"-->
        <!--mat-raised-button-->
        <!--matTooltip="{{ 'filtering.selectFilters' | translate }}"-->
        <!--class="filter-action-item"-->
        <!--(click)="selectFiltersClicked()">-->
        <!--<mat-icon>playlist_add</mat-icon>-->
        <!--</button>-->
        <!--<button type="button"-->
        <!--mat-raised-button-->
        <!--matTooltip="{{ 'filtering.addFilter' | translate }}"-->
        <!--class="filter-action-item"-->
        <!--(click)="addFilterClicked()">-->
        <!--<mat-icon>add</mat-icon>-->
        <!--</button>-->
        <mat-button-toggle matTooltip="{{ 'global.reorder' | translate }}"
                           appearance="legacy"
                           class="filter-action-item"
                           (change)="toggleReorderMode()">
          <mat-icon>reorder</mat-icon>
        </mat-button-toggle>
        <button type="button"
                matTooltip="{{ 'filtering.clearFilterValues' | translate }}"
                [disabled]="filterList.length < 1"
                mat-raised-button
                class="filter-action-item"
                (click)="clearAllFilterValues()">
          <span [translate]="'global.clear'">Clear</span>
        </button>
        <button type="submit"
                color="primary"
                #searchButton
                mat-raised-button
                class="filter-action-item">
          <span [translate]="'global.search'">Search</span>
        </button>
      </div>
    </form>
  </mat-card-content>
</mat-card>
