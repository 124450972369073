import { Pipe, PipeTransform } from '@angular/core';
import { SearchOperations } from '../../../core/types/search-criterium';

@Pipe({
  name: 'searchOperationTranslationMap',
})
export class SearchOperationTranslationMapPipe implements PipeTransform {
  static readonly translationMap = {
    [SearchOperations.LIKE]: 'filtering.labels.contains',
    [SearchOperations.GREATER_THAN]: 'filtering.labels.greaterThan',
    [SearchOperations.GREATER_THAN_OR_EQUAL]: 'filtering.labels.greaterThanOrEqual',
    [SearchOperations.LESS_THAN]: 'filtering.labels.lessThan',
    [SearchOperations.LESS_THAN_OR_EQUAL]: 'filtering.labels.lessThanOrEqual',
    [SearchOperations.LESS_THAN_CRUD]: 'filtering.labels.lessThan',
    [SearchOperations.LIKE_CRUD]: 'filtering.labels.contains',
    [SearchOperations.GREATER_THAN_CRUD]: 'filtering.labels.greaterThan',
    [SearchOperations.GREATER_THAN_OR_EQUAL_CRUD]: 'filtering.labels.greaterThanOrEqual',
    [SearchOperations.LESS_THAN_OR_EQUAL_CRUD]: 'filtering.labels.lessThanOrEqual',



    

  };

  transform(searchOperation: SearchOperations, args?: any): any {
    if (
      SearchOperationTranslationMapPipe.translationMap.hasOwnProperty(
        searchOperation
      )
    ) {
      return SearchOperationTranslationMapPipe.translationMap[searchOperation];
    }
    return '';
  }
}
