<div layout="column" >
  <div layout="row"
       layout-align="space-between center"
       flex>
  <span [translate]="'nssi.chooseNssiTypeReport'"
        mat-dialog-title>Choose NSSI type report</span>
    <button tabindex="-1"
            mat-icon-button
            class="close-button"
            [mat-dialog-close]="false">
      <mat-icon>clear</mat-icon>
    </button>
  </div>

  <div>
    <form [formGroup]="reportTypeForm"
          (ngSubmit)="onSubmit()">
      <div layout="column" layout-wrap>

        <mat-form-field>
          <mat-select formControlName="type"
                      disableOptionCentering="true"
                      placeholder="{{ 'nssi.chooseNssiTypeReport' | translate }}"
                      (selectionChange)="selectedTypeChanged($event)">
            <mat-select-trigger>
            <span layout="row"
                  layout-align="start end">

              <span>{{ reportTypeForm.get('type').value && "nssi." + reportTypeForm.get('type').value.toLowerCase() | translate }}</span>
            </span>
            </mat-select-trigger>

            <mat-option *ngFor="let nssiType of data.nssiTypesList | keyvalue"
                        [value]="nssiType.value">
              {{ "nssi." + nssiType.value.toLowerCase() | translate }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field>
          <mat-label>
            <span [translate]="'ccr.chooseSourceType'">Choose source</span>
          </mat-label>
          <mat-select formControlName="source" (selectionChange)="selectedSourceChanged($event)"
                      disableOptionCentering="true">
            <mat-select-trigger>
            <span layout="row"
                  layout-align="start end">
              <span>{{ reportTypeForm.get('source').value && "reports.sources." + reportTypeForm.get('source').value | translate }}</span>
            </span>
            </mat-select-trigger>
            <mat-option *ngFor="let sourceType of data.nssiSourceTypes | keyvalue"
                        [value]="sourceType.value">
              {{ "reports.sources." + sourceType.key | translate }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field>
          <mat-label>
            <span [translate]="'ccr.chooseCacheDays'">Choose cache days</span>
          </mat-label>
          <input
            matInput
            formControlName="ttl"
            type="number"
            [readonly]="reportTypeForm.get('source').value === NssiSourceTypes?.NSSI"
            placeholder="{{ 'global.days' | translate }}"
          />
          <mat-error *ngIf="reportTypeForm.get('ttl').hasError('min')"
                     [translate]="'forms.minValue'"
                     [translateParams]="{value: MIN_CACHE_VALUE}">This field is required</mat-error>
          <mat-error *ngIf="reportTypeForm.get('ttl').hasError('max')"
                     [translate]="'forms.maxValue'"
                     [translateParams]="{value: MAX_CACHE_VALUE}">This field is required</mat-error>
        </mat-form-field>
        <div layout="row"
             layout-wrap layout-align="end center">
          <button type="submit"
                  [disabled]="reportTypeForm.get('type').disabled || !this.reportTypeForm.get('type').value"
                  mat-raised-button>
            {{ 'global.download' | translate }}
          </button>
        </div>
      </div>
    </form>
  </div>

</div>


