import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { TemplatePortalDirective } from '@angular/cdk/portal';

@Directive({ selector: '[itfgDataTableTemplate]ng-template' })
export class ItfgDataTableTemplateDirective extends TemplatePortalDirective {
  @Input() itfgDataTableTemplate: string;
  constructor(
    templateRef: TemplateRef<any>,
    viewContainerRef: ViewContainerRef
  ) {
    super(templateRef, viewContainerRef);
  }
}
