import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  DeserializedEmail,
  EmailContent,
} from '../../../../../core/types/email';
import { APP_CONFIG } from '../../../../../config/app-config';
import { AngularEditorConfig } from '@kolkov/angular-editor';

@Component({
  selector: 'itfg-email-content-preview',
  templateUrl: './email-content-preview.component.html',
  styleUrls: ['./email-content-preview.component.scss'],
})
export class EmailContentPreviewComponent implements OnInit {
  public editorConfig: AngularEditorConfig;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { email: EmailContent },
    public dialogRef: MatDialogRef<EmailContentPreviewComponent>
  ) {
    this.editorConfig = {
      ...APP_CONFIG.HTML_EDITOR,
      editable: false,
      enableToolbar: false,
      showToolbar: false,
    };
  }

  ngOnInit() {}

  close() {
    this.dialogRef.close();
  }
}
