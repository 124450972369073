import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'itfg-add-card',
  templateUrl: './add-card.component.html',
  styleUrls: ['./add-card.component.scss'],
})
export class AddCardComponent implements OnInit {
  @Output() addButtonClicked: EventEmitter<any> = new EventEmitter();
  constructor() {}

  ngOnInit() {}

  newButtonClicked() {
    this.addButtonClicked.emit();
  }
}
