<div class="dialog-title" layout="row" layout-align="space-between center">
  <h4>{{ 'nssi.automationDetails' | translate: {date: data.details.created_at | date} }}</h4>
  <div flex layout="row" layout-align="end center">
    <button mat-icon-button (click)="closeDialog(false)">
      <mat-icon>close</mat-icon>
    </button>
    <button mat-icon-button
            *ngxPermissionsOnly="['NSSI_ADD']"
            [matMenuTriggerFor]="detailsActions">
      <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu #detailsActions="matMenu">
      <button mat-menu-item
              (click)="closeDialog(true)">
        <mat-icon>auto_mode</mat-icon>
        <span [translate]="'nssi.automation'">NSSI automation</span>
      </button>
    </mat-menu>
  </div>


</div>
<!--<div>{{'global.credit' | translate}}: {{data.details.credit_id}}</div>-->

<itfg-data-table
  flex
  [data]="tableData"
  [columns]="tableColumns"
>
</itfg-data-table>
<div layout="row" layout-align="end end"><i>{{'global.operator' | translate}}: {{data.details.operator_email}}</i></div>
