import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { CreditService } from '../../core/services/credit.service';
import { CreditStatusReasonToTranslationKeyPipe } from '../credit/pipes/credit-status-reason-to-translation-key.pipe';
import {
  CreditStatus,
  CreditStatusReasons,
} from '../../core/types/credit-status';
import { FilterValue } from '../filter/types';
import { TranslateService } from '@ngx-translate/core';
import { CreditStatusToTranslationKeyPipe } from '../credit/pipes/credit-status-to-translation-key.pipe';
import { Observable } from 'rxjs';

@Injectable()
export class CreditStatusReasonValueListService {
  constructor(
    private credits: CreditService,
    private translateService: TranslateService
  ) {}

  getFilterValues$(): Observable<FilterValue[]> {
    const creditStatusTranslationPipe: CreditStatusToTranslationKeyPipe = new CreditStatusToTranslationKeyPipe();
    const creditStatusReasonTranslationPipe: CreditStatusReasonToTranslationKeyPipe = new CreditStatusReasonToTranslationKeyPipe();
    return this.credits.getCreditStatusList().pipe(
      map((creditStatusList: CreditStatus[]): FilterValue[] => {
        const statusList = creditStatusList.filter(
          status => status.reason !== null
        );

        const uniqueStatuses = statusList
          .map(item => item.name)
          .filter((value, index, list) => list.indexOf(value) === index);

        uniqueStatuses.forEach(statusName => {
          creditStatusList.forEach(statusItem => {
            if (statusItem.name === statusName && statusItem.reason === null) {
              statusItem.reason = CreditStatusReasons.NO_REASON;
            }
          });
        });

        return creditStatusList.map(
          (creditStatus: CreditStatus): FilterValue => {
            return {
              value: creditStatus.id,
              displayText: creditStatus.reason
                ? `${this.translateService
                    .instant(
                      creditStatusTranslationPipe.transform(creditStatus)
                    )
                    .toUpperCase()}
           - ${this.translateService.instant(
             creditStatusReasonTranslationPipe.transform(creditStatus.reason)
           )}`
                : this.translateService
                    .instant(
                      creditStatusTranslationPipe.transform(creditStatus)
                    )
                    .toUpperCase(),
            };
          }
        );
      })
    );
  }
}
