import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RequestService } from './request.service';
import { Brand } from '../types/brand';
import { EntityHistoryService, EntityHistory } from '../types/entities';
import { EntityHistoryFilter } from '../types/entity-history-filter';

@Injectable()
export class BrandService implements EntityHistoryService {
  request: RequestService;

  constructor(request: RequestService) {
    this.request = request;
  }

  getBrandList(): Observable<Brand[]> {
    return this.request.get(['brands']);
  }

  getBrandById(id: string): Observable<Brand> {
    return this.request.get(['brands', id]);
  }

  deleteBrand(id: string): Observable<any> {
    return this.request.delete(['brands', id], {
      responseType: 'text',
    });
  }

  saveBrand(brandFormData: any): Observable<Brand> {
    brandFormData.name = brandFormData.name.trim('');
    brandFormData.code = brandFormData.code.trim('');

    return this.request.post(['brands'], {
      headers: { 'Content-Type': 'application/json' },
      body: brandFormData,
    });
  }

  getProductHistory(
    filter: EntityHistoryFilter
  ): Observable<EntityHistory<any>> {
    return this.request.get(['brands', 'history'], {
      params: {
        filter: filter.getDTO(),
      },
    });
  }

  getEntityHistory(filter: EntityHistoryFilter) {
    return this.getProductHistory(filter);
  }
}
