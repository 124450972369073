import { Directive, ElementRef, HostListener, Input, Renderer2 } from '@angular/core';

@Directive({
  selector: '[itfgTogglePasswordVisibility]'
})
export class TogglePasswordVisibilityDirective {
  private _shown = false;
  private _inputElement: any;

  @Input('itfgTogglePasswordVisibility')
  set inputElement(inputEl: HTMLElement) {
    this._inputElement = inputEl;
  };

  get inputElement() {
    return this._inputElement;
  }

  constructor(private el: ElementRef, public renderer: Renderer2) { }

  @HostListener('click')
  onClick() {
    this.toggle();
  }

  toggle() {
    if (!this._shown) {
      this.renderer.setAttribute(this._inputElement, 'type', 'text');
      this._shown = true;
    } else {
      this.renderer.setAttribute(this._inputElement, 'type', 'password');
      this._shown = false;
    }
  }
}
