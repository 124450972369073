import { Pipe, PipeTransform } from '@angular/core';
import { PaymentPromiseStatusType } from '../../../core/types/payment-promise';

@Pipe({
  name: 'paymentPromiseStatusToTranslationKey',
})
export class PaymentPromiseStatusToTranslationKeyPipe implements PipeTransform {
  static readonly paymentPromiseStatusTranslationMap: { [key: string]: string } = {
    [PaymentPromiseStatusType.UPCOMING]: 'historyLog.paymentPromiseStatusTypes.upcoming',
    [PaymentPromiseStatusType.CURRENT]: 'historyLog.paymentPromiseStatusTypes.current',
    [PaymentPromiseStatusType.EXPIRED]: 'historyLog.paymentPromiseStatusTypes.expired',
    [PaymentPromiseStatusType.PAID]: 'historyLog.paymentPromiseStatusTypes.paid',
    [PaymentPromiseStatusType.UNFULFILLED]: 'historyLog.paymentPromiseStatusTypes.unfulfilled',
    [PaymentPromiseStatusType.PARTIALLY_PAID]: 'historyLog.paymentPromiseStatusTypes.partiallyPaid',
    [PaymentPromiseStatusType.CANCELED]: 'historyLog.paymentPromiseStatusTypes.canceled',
  };

  transform(methodName: string): string {
    return PaymentPromiseStatusToTranslationKeyPipe.paymentPromiseStatusTranslationMap[
      methodName
    ];
  }
}
