export enum PageName {
  CLIENTS = 'CLIENTS',
  CREDITS = 'CREDITS',
  REQUESTS = 'REQUESTS',
  APPLICATIONS = 'APPLICATIONS',
  OFFICES = 'OFFICE',
  ACCOUNTS = 'ACCOUNT',
  ADMINISTRATORS = 'ADMINISTRATORS',
  BUCKETS = 'BUCKETS',
  BUCKET_OPERATORS = 'BUCKET_OPERATORS',
  BUCKET_CREDITS = 'BUCKET_CREDITS',
  DOCUMENT_TEMPLATES = 'DOCUMENT_TEMPLATES',
  EMAIL_TEMPLATES = 'EMAIL_TEMPLATES',
  SMS_TEMPLATES = 'SMS_TEMPLATES',
  CLAIMS = 'CLAIMS',
  BLACKLISTS = 'BLACKLISTS',
  ENTITY_HISTORY = 'ENTITY_HISTORY',
  PRODUCTS = 'PRODUCTS',
  PRODUCT_PLANS = 'PRODUCT_PLANS',
  TRANSACTION_LIST = 'TRANSACTION_LIST',
  UNSET = '',
  COLLECTOR_CREDITS = 'COLLECTOR_CREDITS',
  COLLECTOR_FILTERED = 'COLLECTOR_FILTERED',
  EXTERNAL_COLLECTION = 'EXTERNAL_COLLECTION',
  LEGAL_COLLECTION = 'LEGAL_COLLECTION',
  CLIENT_CREDIT_HISTORY = 'CLIENT_CREDIT_HISTORY',
  APPLICATION_LIST = 'APPLICATION_LIST',
  COLLECTION_UNASSIGNED_LIST = 'COLLECTION_UNASSIGNED_LIST',
  PAYMENT_PROMISES = 'PAYMENT_PROMISES',
  PAYMENT_PROMISES_FILTERED = 'PAYMENT_PROMISES_FILTERED',
  INCOME = 'INCOME',
  ELANA = 'ELANA',
  BONDSTER = 'BONDSTER',
  BONDSTER_GC = 'BONDSTER-GC',
  BONDSTER_CD = 'BONDSTER-CD',
  BANK_PAYMENT_LIST = 'BANK_PAYMENT_LIST',
  LENDERMARKET = 'LENDERMARKET',
  PARTNERS = "PARTNERS",
  CALLS_LIST = 'CALLS_LIST",'
}

export interface UserPagePreferences {
  filters: {
    config: object[];
    isShown: boolean;
  };
  columns: object[];
  lastVisitedTab?: number;
}

export interface PageSettings {
  userPageSettings: UserPagePreferences[];
}

export class UserSettings {
  public userSettings: object;
  constructor() {
    this.userSettings = {};
  }

  addPagePreferences(pageName: PageName, pagePreferences: UserPagePreferences) {
    this.userSettings[pageName] = pagePreferences;
  }
}
