import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PaymentPromiseStatusType } from '../../../core/types/payment-promise';

@Pipe({
  name: 'paymentPromiseStatusDescriptionTooltip',
})
export class PaymentPromiseStatusDescriptionTooltip implements PipeTransform {
  static readonly paymentPromiseStatusTranslationMap: { [key: string]: string } = {
    [PaymentPromiseStatusType.UPCOMING]: 'communication.paymentPromisesStatusDescriptions.upcoming',
    [PaymentPromiseStatusType.CURRENT]: 'communication.paymentPromisesStatusDescriptions.current',
    [PaymentPromiseStatusType.EXPIRED]: 'communication.paymentPromisesStatusDescriptions.expired',
    [PaymentPromiseStatusType.PAID]: 'communication.paymentPromisesStatusDescriptions.paid',
    [PaymentPromiseStatusType.UNFULFILLED]: 'communication.paymentPromisesStatusDescriptions.unfulfilled',
    [PaymentPromiseStatusType.PARTIALLY_PAID]: 'communication.paymentPromisesStatusDescriptions.partiallyPaid',
    [PaymentPromiseStatusType.CANCELED]: 'communication.paymentPromisesStatusDescriptions.canceled',
  };

  constructor(
    private translate: TranslateService,
  ) { }

  transform(methodName: string): string {
    const translationKey = PaymentPromiseStatusDescriptionTooltip.paymentPromiseStatusTranslationMap[
      methodName
    ];
    const translation = this.translate.instant(translationKey);
    return translation;
  }
}
