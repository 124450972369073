import { Injectable } from '@angular/core';
import {RequestService} from './request.service';
import {Page} from '../types/page';
import {Credit, SerializedCredit} from '../types/credit';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';

@Injectable()
export class VerificationService {

  constructor(private request: RequestService) { }

  getIdVerification$(limit): Observable<Credit[]> {
    return this.request.get(['credits', 'verification', 'id'], { params: {limit: limit} });
  }

  getBankAccountVerification$(limit): Observable<Credit[]> {
    return this.request.get(['credits', 'verification', 'bank-account'], { params: {limit: limit} });
  }
}
