<div
  flex
  layout-align="end end"
  class="position-buttons-fix"
  layout-wrap
  layout="row"
>
  <div>
    <button
      mat-icon-button
      [matMenuTriggerFor]="settings"
      matTooltip="{{ 'global.settings' | translate }}"
      class="button-color-not-active"
      flex
    >
      <mat-icon>settings</mat-icon>
    </button>
    <mat-menu #settings="matMenu">
      <itfg-column-button
        [columns]="columns"
      ></itfg-column-button>
    </mat-menu>
  </div>
</div>
<div>
  <itfg-column-card
    #columnCard
    hidden
    [columns]="columns"
  >
  </itfg-column-card>
</div>
<itfg-data-table
  #dataTable
  [data]="clientList"
  [columns]="appliedColumns ? appliedColumns : columns"
  [isSortable]="true"
  [sortBy]="sortBy"
  [isClickable]="true"
>
  <ng-template itfgDataTableTemplate="tags" 
  let-tags="value" >
    <itfg-tags-table-cell-view [tags]="tags"></itfg-tags-table-cell-view>
  </ng-template>
  <ng-template itfgDataTableTemplate="id" let-clientId="value">
    <a routerLink="/clients/{{ clientId }}" target="_blank" flex>
      {{ clientId }}
    </a>
  </ng-template>
  <ng-template
    itfgDataTableTemplate="status.name"
    let-value="value"
    let-row="row"
    let-column="column"
  >
    {{ 'global.' + value.toLowerCase() | translate }}
  </ng-template>
  <ng-template
    itfgDataTableTemplate="actions"
    let-value="value"
    let-row="row"
    let-column="column"
  >
    <div layout="row">
      <a mat-icon-button routerLink="/clients/{{ row.id }}" flex>
        <mat-icon>{{
          ('USER_WRITE' | isUnauthorized | async) ? 'visibility' : 'edit'
        }}</mat-icon>
      </a>
      <itfg-client-options-menu
        [config]="actionsConfig"
        [clientId]="row.id"
      ></itfg-client-options-menu>
    </div>
  </ng-template>
</itfg-data-table>
