import { of as observableOf, Subject } from 'rxjs';
import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { SessionService } from '../core/services/session.service';
import { Router, ActivatedRoute } from '@angular/router';

import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { NavigationInfoService } from '../core/services/navigation-info.service';
import { StorageService } from '../core/services/storage.service';
import { APP_CONFIG, MenuItem } from '../config/app-config';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { CreditService } from '../core/services/credit.service';
import { NavigationMenuService } from '../core/services/navigation-menu.service';

@Component({
  selector: 'itfg-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
})
export class MainComponent implements OnInit, OnDestroy {
  @ViewChild('outerLayout') outerLayout: any;
  @ViewChild('nestedMenuItems') nestedMenuItemsTemplate;
  @ViewChild('searchDrawerContent')
  searchDrawerContentTemplate;
  outerDrawerActiveTemplate: any;

  session: SessionService;
  heading: string;
  router: Router;
  translate: TranslateService;
  activatedRoute: ActivatedRoute;
  isExpanded: any;
  navigationInfo: NavigationInfoService;
  storageService: StorageService;
  searchCreditForm: UntypedFormGroup;
  _unsubscribe: Subject<void> = new Subject<void>();
  menuRoutes: MenuItem[];
  expandedMenuItems: any = [];

  constructor(
    session: SessionService,
    router: Router,
    activatedRoute: ActivatedRoute,
    translate: TranslateService,
    navigationInfo: NavigationInfoService,
    private formBuilder: UntypedFormBuilder,
    private creditService: CreditService,
    private navigationMenuService: NavigationMenuService
  ) {
    this.session = session;
    this.activatedRoute = activatedRoute;
    this.router = router;
    this.translate = translate;
    this.navigationInfo = navigationInfo;
    this.storageService = new StorageService(APP_CONFIG.AUTH.STORAGE_TYPE);
    this.isExpanded = {
      products: true,
      administration: true,
      clients: true,
      credits: true,
      payments: true,
      buckets: true,
    };
  }

  ngOnInit() {
    this.heading = this.navigationInfo.title;
    this.searchCreditForm = this.createSearchForm();
    this.navigationInfo.titleChanged$.subscribe(title => {
      this.heading = (title as any) as string;
    });
    this.menuRoutes = APP_CONFIG.NAVIGATION.routes;
    this.navigationMenuService.onChangeLanguageClick
      .pipe(takeUntil(this._unsubscribe))
      .subscribe((lang: string) => this.swichLanguage(lang));
  }

  mapDataToTitle(data) {
    if (data.titleKey) {
      return this.translate.get(data.titleKey);
    } else {
      return observableOf('');
    }
  }

  logout(): void {
    this.session.logout();
  }

  expandedEvent(elementKey) {
    if (this.isExpanded.hasOwnProperty(elementKey)) {
      this.isExpanded[elementKey] = true;
    }
  }

  collapsedEvent(elementKey?) {
    if (this.isExpanded.hasOwnProperty(elementKey)) {
      this.isExpanded[elementKey] = false;
    }

    for (const key in this.isExpanded) {
      if (this.isExpanded.hasOwnProperty(key) && key !== elementKey) {
        this.isExpanded[key] = true;
      }
    }
  }

  createSearchForm() {
    const fb = this.formBuilder;
    return fb.group({
      creditId: [''],
    });
  }

  findCreditById(event) {
    event.preventDefault();
    const creditId = this.searchCreditForm.value.creditId;

    this.searchCreditForm.reset();
    this.router.navigate(['credits', creditId]);
  }

  openChildrenPopup(children) {
    this.outerDrawerActiveTemplate = this.nestedMenuItemsTemplate;
    this.expandedMenuItems = children;
    this.outerLayout.sidenav.open();
  }

  swichLanguage(lang: string) {
    if (this.translate.currentLang !== lang) {
      this.storageService.setItem('translationLanguage', lang);
      window.location.reload();
    }
  }

  closeOuterLayout() {
    this.outerLayout.sidenav.close();
  }

  ngOnDestroy() {
    this._unsubscribe.next();
    this._unsubscribe.complete();
  }
}
