import {Component, Inject, OnInit} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import {Client} from '../../../../core/types/client';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {NssiTypes} from '../../../../core/types/identity-reports';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {IdentityReportsService} from '@core/services/identity-reports.service';
import {NssiSourceTypes} from '@core/types/ccr';

export interface ChooseNssiTypeReportData {
  client: Client;
  nssiTypesList: NssiTypes;
  nssiSourceTypes: NssiSourceTypes;
}

@Component({
  selector: 'itfg-choose-nssi-type-report',
  templateUrl: './choose-nssi-type-report.component.html',
  styleUrls: ['./choose-nssi-type-report.component.scss']
})
export class ChooseNssiTypeReportComponent implements OnInit {

  public reportTypeForm: UntypedFormGroup;
  public reportsTtl: {type: string, ttl: number}[] = [];
  public NssiSourceTypes: typeof NssiSourceTypes = NssiSourceTypes;
  public MIN_CACHE_VALUE = 0;
  public MAX_CACHE_VALUE = 90;



  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ChooseNssiTypeReportData,
    private dialogRef: MatDialogRef<ChooseNssiTypeReportComponent>,
    private identityReportsService: IdentityReportsService,
    private fb: UntypedFormBuilder
  ) {
  }

  ngOnInit() {
    Object.values(this.data.nssiTypesList).forEach(key => {
      const reportTtl = {type: key, ttl: 0};
      this.reportsTtl.push(reportTtl);
    });
    this.reportTypeForm = this.fb.group({
      type: '',
      source: this.NssiSourceTypes.DEFAULT,
      ttl: null
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onSubmit() {
    this.dialogRef.close(this.reportTypeForm.getRawValue());
  }
  selectedTypeChanged(event) {
    if (this.reportTypeForm.get('source').value === NssiSourceTypes.DEFAULT ||
      this.reportTypeForm.get('source').value === NssiSourceTypes.DG) {

      const reportTtlIndex = this.reportsTtl.findIndex(ttl => ttl.type === event.value);

      if (reportTtlIndex !== -1 && this.reportsTtl[reportTtlIndex].ttl === 0) {

        this.identityReportsService.getConstantByKey(this.reportsTtl[reportTtlIndex].type + '-cache-ttl')
          .subscribe(cacheTtl => {
            this.reportsTtl[reportTtlIndex].ttl = cacheTtl;
            this.reportTypeForm.patchValue({ ttl: cacheTtl });
          });
      } else {
        this.reportTypeForm.patchValue({ ttl: this.reportsTtl[reportTtlIndex].ttl });
      }
    }
  }

  selectedSourceChanged(event) {
    if (event.value === NssiSourceTypes.NSSI) {
      this.reportTypeForm.patchValue({ ttl: this.MIN_CACHE_VALUE });
    } else {
      const reportType = this.reportTypeForm.get('type').value;
      const reportTtlIndex = this.reportsTtl.findIndex(ttl => ttl.type === reportType);
      const reportTtl = reportTtlIndex !== -1 ? this.reportsTtl[reportTtlIndex].ttl : this.MIN_CACHE_VALUE;

      this.reportTypeForm.patchValue({ ttl: reportTtl });
    }
  }
}
