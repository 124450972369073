import { map, filter } from 'rxjs/operators';
import {
  Component,
  OnInit,
  Input,
  OnChanges,
  ViewEncapsulation,
} from '@angular/core';
import { MenuItem } from '../../../config/app-config';
import { NavigationMenuService } from '../../../core/services/navigation-menu.service';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { DrawerMode } from '../../../core/types/drawer-mode';

@Component({
  selector: 'itfg-navigation-menu',
  templateUrl: './navigation-menu.component.html',
  styleUrls: ['./navigation-menu.component.scss'],
})
export class NavigationMenuComponent implements OnInit, OnChanges {
  @Input() mode: string;
  @Input() drawerMode: DrawerMode;
  @Input() collapsed = true;
  @Input() routes: MenuItem[];
  isExpanded: { [key: string]: boolean };
  lastClickedLink: MenuItem;

  constructor(
    private navigationMenuService: NavigationMenuService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map((navEnd: NavigationEnd) => navEnd.url)
      )
      .subscribe((url: string) => this.isExpandedState());
  }

  navigationLinkClicked() {
    if (this.mode === 'submenu') {
      this.closeOuterLayout();
    }
  }

  ngOnChanges(changes) {
    if (changes.routes && changes.routes.currentValue) {
      this.isExpandedState();
    }
  }

  isExpandedState(url?: string) {
    this.isExpanded = {};
    this.routes
      .filter(route => !!route.children)
      .forEach(route => {
        return (this.isExpanded = Object.assign(this.isExpanded, {
          [route.routerLink]: !this.matchActiveUrl(route.children),
        }));
      });
  }

  closeOuterLayout() {
    this.navigationMenuService.onCloseNavigationDrawerClick.next(true);
  }

  matchActiveUrl(children: MenuItem[]): any {
    return children.some(item => {
      return !!this.router.url.startsWith(item.routerLink);
    });
  }

  openNestedMenu(route: MenuItem) {
    const children = route.children;

    if (!children || children.length === 0) {
      this.closeOuterLayout();
      return;
    }

    if (
      !!this.lastClickedLink &&
      route.routerLink === this.lastClickedLink.routerLink &&
      this.drawerMode === DrawerMode.Navigation
    ) {
      this.navigationMenuService.toggleNavigationDrawerClick.next();
      this.lastClickedLink = null;
    } else {
      this.drawerMode = DrawerMode.Navigation;

      this.navigationMenuService.onOpenNavigationDrawerClick.next(children);
      this.lastClickedLink = route;
    }
  }
}
