<!-- <div layout="column" >
    <div layout="row"
          layout-align="space-between center"
          flex>
    <span [translate]="'communication.editReminder'"
          mat-dialog-title>Edit reminder</span>
    <button tabindex="-1"
            class="close-mat-dialog"
            mat-icon-button
            [mat-dialog-close]="false">
      <mat-icon>clear</mat-icon>
    </button>
</div>

<form [formGroup]="reminderForm"
      (ngSubmit)="onSave($event)"
      layout="column">

  <div formGroupName="reminder">
    <mat-form-field class="flex-padding">
      <mat-label>{{ 'communication.reminderDate' | translate }}</mat-label>
      <input matInput
              readonly="true"
              [matDatepicker]="reminderPicker"
              [errorStateMatcher]="dateMatcher"
              formControlName="date"
              [min]="datepickerStartDate"
              (click)="reminderPicker.open()">
      <mat-datepicker #reminderPicker></mat-datepicker>
      <mat-datepicker-toggle matSuffix
                              [for]="reminderPicker"></mat-datepicker-toggle>
      <mat-error *ngIf="reminderForm.get('reminder').errors?.date?.timeRequiresDate"
                  [translate]="'forms.timeRequiresDate'">
        Time requires date
      </mat-error>
      <mat-error *ngIf="reminderForm.get(['reminder', 'date']).errors?.required"
                  [translate]="'forms.required'">
        Required field
      </mat-error>
    </mat-form-field>
    <mat-form-field class="flex-padding"
                    flex="33">
      <input formControlName="time"
              [errorStateMatcher]="timeMatcher"
              placeholder="{{ 'communication.reminderTime' | translate }}"
              type="time"
              matInput>
      <mat-error *ngIf="reminderForm.get('reminder').errors?.time?.required"
                  [translate]="'forms.currentDateRequiresTime'">
        Upon selecting today, time must also be filled in
      </mat-error>
      <mat-error *ngIf="reminderForm.get('reminder').errors?.time?.invalid"
                  [translate]="'forms.timeNotValid'">
        Time is not valid
      </mat-error>
    </mat-form-field>
  </div>
  
  <div layout="row" layout-align="end center">
    <button mat-stroked-button
            type="submit">
      <span [translate]="'global.save'">Save</span>
    </button>
  </div>

</form> -->
<div flex layout-align="space-between start" layout="row">
  <div
    class="modal-dialog-title"
    flex
  >
    {{ 'historyLog.addReminder' | translate }}
  </div>
  <button tabindex="-1" mat-icon-button (click)="onNoClick()">
    <mat-icon>clear</mat-icon>
  </button>
</div>

<form [formGroup]="reminderForm"
      (ngSubmit)="onSave($event)"
      layout="column">
    <div formGroupName="reminder"
          layout="row">
      <mat-form-field class="flex-padding"
                      flex="100">
        <mat-label>{{ 'communication.remindMeAfter' | translate }}</mat-label>
        <mat-select formControlName="date"
                    panelClass="selectReminderOption">
          <mat-select-trigger>
            {{  reminderForm.get('reminder.date')?.value | reminderOptionToDisplayText }}
          </mat-select-trigger>
          <mat-option [value]="null">
            {{ 'communication.noReminder' | translate }}
          </mat-option>
          <mat-option *ngFor="let reminderOption of reminderOptionList"
                      [value]="reminderOption">
            {{ reminderOption.text }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <ng-container *ngIf="showCustomOption">
      <itfg-reminder-choose-custom-option (dateChosenEmitter)="dateChosen($event)"></itfg-reminder-choose-custom-option>
    </ng-container>

    <div layout="row" layout-align="end center" *ngIf="!showCustomOption">
      <button mat-stroked-button
              type="submit">
        <span [translate]="'global.save'">Save</span>
      </button>
    </div>
</form>

