import { Pipe, PipeTransform } from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';

@Pipe({
  name: 'toFormGroup',
})
export class AbstractControlToFormGroupPipe implements PipeTransform {
  transform(abstractControl: AbstractControl): UntypedFormGroup {
    return abstractControl as UntypedFormGroup;
  }
}
