import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'transactionTypeTranslation',
})
export class TransactionTypeTranslationPipe implements PipeTransform {
  transform(type: string): string {
    return type ? 'transaction.type.' + type.toLowerCase() : '';
  }
}
