import { Pipe, PipeTransform } from '@angular/core';
import { Administrator } from '@app/core/types/administrator';
import { Call } from '@app/core/types/call';
import { UserDetails } from '@app/core/types/user-details';

@Pipe({
  name: 'hasCallRecordAccess'
})
export class HasCallRecordAccessPipe implements PipeTransform {
  transform(currentOperator: UserDetails, call: Call): boolean {
    return !!currentOperator && currentOperator.roles?.some((role: string) => role === 'CALL_RECORD_LISTEN') && !!call.record; // TODO: Add this role in database..
  }

}
