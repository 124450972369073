import { Injectable } from '@angular/core';
import { RequestService } from './request.service';
import { Observable, Subject, of } from 'rxjs';
import { Page } from '../types/page';
import {
  Transaction,
  TransactionType,
  PayinTransactionDto,
  RevertTransactionDto,
  PayinExtraExpenseDto,
  RevertExtraIncomeTransactionDto,
  CessionTransactionDto,
  ExtraIncomeTransactionDto,
} from '../types/transaction';
import { AccountOwnerType } from '../types/account-owner-type';
import { SearchOptionsDTO } from '../types/search-options';

@Injectable()
export class TransactionService {
  transactionTypes: TransactionType[] = [];
  onTransactionListDataChange: Subject<Transaction[]> = new Subject<
    Transaction[]
  >();
  onSubmitPayment = new Subject<number>();
  dateChanged: Subject<Date> = new Subject<Date>();
  reloadSummary: Subject<Date> = new Subject<Date>();
  updateCreditTransactionList = new Subject<void>();

  constructor(private request: RequestService) {}

  getTransactionList$(
    options?: SearchOptionsDTO
  ): Observable<Page<Transaction>> {
    return this.request.get(['transactions'], { params: options });
  }

  getTransactionTypes$(): Observable<TransactionType[]> {
    return this.request.get(['transactions', 'types']);
  }

  getTransactionById$(id: number | string): Observable<Transaction> {
    return this.request.get(['transactions', id]);
  }

  saveTransaction$(transaction: Transaction): Observable<any> {
    return this.request.post(['transactions'], { body: transaction });
  }

  updateTransactionDestination$(transactionId: number, destinationId: number) {
    return this.request.post(['transactions', transactionId, 'destination'], {
      body: destinationId,
    });
  }

  updateTransactionSource$(transactionId: number, sourceId: number) {
    return this.request.post(['transactions', transactionId, 'source'], {
      body: {
        sourceAccountId: sourceId
      },
    });
  }

  revertPayinTransaction$(dto: RevertTransactionDto): Observable<Transaction> {
    return this.request.post(['transactions', 'pay-ins', dto.transactionId, 'revert'], {
      body: dto,
    });
  }

  revertPayoutTransaction$(transactionId: number) {
    return this.request.post(['transactions', 'pay-outs', transactionId, 'reverted'])
  }

  revertExtraIncomeTransaction$(
    dto: RevertExtraIncomeTransactionDto
  ): Observable<Transaction> {
    return this.request.post(['transactions', 'revert', 'extra-income'], {
      body: dto,
    });
  }

  payoutTransaction$(transaction: Transaction): Observable<any> {
    return this.request.post(['transactions', 'pay-outs'], {
      body: transaction,
    });
  }

  editPayoutTransaction$(transactionId: number, sourceAccountId: number) {
    return this.request.post(['transactions', 'pay-outs', transactionId], {
      body: {
        sourceAccountId
      },
    });
  }

  payinTtransaction$(transaction: PayinTransactionDto): Observable<any> {
    return this.request.post(['transactions', 'pay-ins'], {
      body: transaction,
    });
  }

  payinExtraIncome$(dto: ExtraIncomeTransactionDto) {
    return this.request.post(['transactions', 'pay-ins', 'extra-income'], {
      body: dto,
    });
  }

  payinExtraExpense$(dto: PayinExtraExpenseDto): Observable<any> {
    return this.request.post(['transactions', 'pay-ins', 'extra-expense'], {
      body: dto,
    });
  }

  payinCession$(dto: CessionTransactionDto): Observable<any> {
    return this.request.post(['transactions', 'pay-ins', 'cession'], {
      body: dto,
    });
  }

  confirmPayoutTransaction$(transaction: Transaction): Observable<Transaction> {
    return this.request.post([
      'transactions',
      'pay-outs',
      transaction.id,
      'confirm',
    ]);
  }

  confirmPayinTransaction$(transaction: Transaction): Observable<Transaction> {
    return this.request.post(
      ['transactions', 'pay-ins', transaction.id, 'confirm'],
      {
        body: transaction.destination.id,
      }
    );
  }

  cancelPayoutTransaction$(id: number | string): Observable<Transaction> {
    return this.request.post(['transactions', 'pay-outs', id, 'cancel']);
  }

  getDisallowedAccountTypes(accountType: string) {
    switch (accountType) {
      case 'COMPANY':
        return [];

      case 'CLIENT':
        return ['CLIENT', 'EXTERNAL'];

      case 'CLIENT_VIRTUAL':
        return ['CLIENT_VIRTUAL'];

      case 'EXTERNAL':
        return ['EXTERNAL', 'CLIENT'];

      default:
        return [];
    }
  }

  payoutRefinanceTransaction$(transaction: Transaction): Observable<any> {
    return this.request.post(['transactions', 'refinances'], {
      body: transaction,
    });
  }

  getAllowedTransactionTypes(
    accountSenderType: AccountOwnerType,
    accountRecieverType: AccountOwnerType
  ): TransactionType[] {
    switch (accountSenderType.name) {
      case 'CLIENT':
        switch (accountRecieverType.name) {
          case 'CLIENT_VIRTUAL':
            return [
              this.transactionTypes.find(
                transactionType => transactionType.name === 'CLIENT'
              ),
            ];
          case 'COMPANY':
            return [
              this.transactionTypes.find(
                transactionType => transactionType.name === 'EXTRA_INCOME'
              ),
              this.transactionTypes.find(
                transactionType => transactionType.name === 'MISC'
              ),
            ];

          default:
            return [];
        }

      case 'CLIENT_VIRTUAL':
        switch (accountRecieverType.name) {
          case 'CLIENT':
            return [
              this.transactionTypes.find(
                transactionType => transactionType.name === 'CLIENT'
              ),
            ];

          case 'COMPANY':
            return [
              this.transactionTypes.find(
                transactionType => transactionType.name === 'CREDIT'
              ),
              this.transactionTypes.find(
                transactionType => transactionType.name === 'REFINANCE'
              ),
              this.transactionTypes.find(
                transactionType => transactionType.name === 'EXTRA_INCOME'
              ),
            ];

          case 'EXTERNAL':
            return [
              this.transactionTypes.find(
                transactionType => transactionType.name === 'CLIENT'
              ),
            ];

          default:
            return [];
        }

      case 'COMPANY':
        switch (accountRecieverType.name) {
          case 'CLIENT':
            return [
              this.transactionTypes.find(
                transactionType => transactionType.name === 'EXTRA_INCOME'
              ),
              this.transactionTypes.find(
                transactionType => transactionType.name === 'MISC'
              ),
              this.transactionTypes.find(
                transactionType => transactionType.name === 'CREDIT'
              ),
            ];
          case 'CLIENT_VIRTUAL':
            return [
              this.transactionTypes.find(
                transactionType => transactionType.name === 'REFINANCE'
              ),
              this.transactionTypes.find(
                transactionType => transactionType.name === 'EXTRA_INCOME'
              ),
              this.transactionTypes.find(
                transactionType => transactionType.name === 'CREDIT'
              ),
            ];
          case 'COMPANY':
            return [
              this.transactionTypes.find(
                transactionType => transactionType.name === 'INTERNAL_TRANSFER'
              ),
            ];

          case 'EXTERNAL':
            return [
              this.transactionTypes.find(
                transactionType => transactionType.name === 'EXTRA_INCOME'
              ),
              this.transactionTypes.find(
                transactionType => transactionType.name === 'MISC'
              ),
              this.transactionTypes.find(
                transactionType => transactionType.name === 'CREDIT'
              ),
            ];

          default:
            return [];
        }

      case 'EXTERNAL':
        switch (accountRecieverType.name) {
          case 'CLIENT_VIRTUAL':
            return [
              this.transactionTypes.find(
                transactionType => transactionType.name === 'CLIENT'
              ),
            ];
          case 'COMPANY':
            return [
              this.transactionTypes.find(
                transactionType => transactionType.name === 'EXTRA_INCOME'
              ),
              this.transactionTypes.find(
                transactionType => transactionType.name === 'MISC'
              ),
            ];

          default:
            return [];
        }

      default:
        return [];
    }
  }
}
