import { Pipe, PipeTransform } from '@angular/core';
import {ClientRelationType} from '../../../core/types/client-relation';
import {MetaStatusCode} from '../../../core/types/identity-reports';
import {TranslateService} from '@ngx-translate/core';

@Pipe({
  name: 'reportCacheRegister'
})
export class ReportCacheRegisterPipe implements PipeTransform {
  constructor(private translateService: TranslateService) {
  }
  static readonly metaStatusTranslationMap: { [key: string]: string } = {
    [MetaStatusCode.ACCESS_DENIED]: 'reports.cacheRegister.cache',
    [MetaStatusCode.TEST]: 'reports.cacheRegister.cache',
    [MetaStatusCode.SUCCESS_DG]: 'reports.cacheRegister.cache',
    [MetaStatusCode.ERROR_DG]: 'reports.cacheRegister.cache',
    [MetaStatusCode.CONN_ERROR_REG]: 'reports.cacheRegister.register',
    [MetaStatusCode.ERROR_REG]: 'reports.cacheRegister.register',
    [MetaStatusCode.SUCCESS_REG]: 'reports.cacheRegister.register',
  };
  transform(value: MetaStatusCode): string {
    if (value) {
      const translationValue = ReportCacheRegisterPipe.metaStatusTranslationMap[value];
      return this.translateService.instant(translationValue);
    } else {
      return '';
    }
  }

}
