import {IdCardVerificationName} from '../core/types/id-card-verified';

export interface IdCardStyleConfigMap {
  [key: string]: {
    backgroundColor?: string;
    class?: string;
    color?: string;
    icon?: string;
  };
}

export const ID_CARD_STATUS_STYLE_MAP: IdCardStyleConfigMap = {

  [IdCardVerificationName.INVALID]: {
    backgroundColor: '#e53935',
    icon: 'cancel',
  },
  [IdCardVerificationName.UNVERIFIED]: {
    backgroundColor: '#696969',
    icon: 'warning',
  },
  [IdCardVerificationName.VALID]: {
    backgroundColor: '#228B22',
    icon: 'done',
  },
};
