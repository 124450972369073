import { Client } from './client';

export enum ClientRelationType {
  PHONE = 'PHONE',
  CIVIL_ID = 'CIVIL_ID',
  EMAIL = 'EMAIL',
  ID_CARD_NUMBER = 'ID_CARD_NUMBER',
  ADDRESS = 'ADDRESS',
  EMPLOYER_ID = 'EMPLOYER_ID',
  IP = 'IP',
  BANK_ACCOUNT = 'BANK_ACCOUNT',
}

export interface ClientRelationGroup {
  description?: string;
  type?: ClientRelationType;
  value?: string;
  users?: number[];
}

export interface PopulatedClientRelationGroup {
  description: string;
  type: ClientRelationType;
  users: Client[];
}

export interface ClientRelationGroupByType {
  type: ClientRelationType;
  matches: ClientRelationGroup[];
}

export interface ClientRelation {
  from?: Client;
  to: Client;
  type: ClientRelationType;
  value: string;
  description: string;
}
