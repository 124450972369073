<div class="custom-dialog-container">
  <div layout="row"
       layout-align="space-between center"
       class="claims-edit-header"
       flex>
    <div *ngIf="editMode"
         class="modal-dialog-title"
         flex
         [translate]="'claims.editClaim'"
         [translateParams]="{ claimId: data.claim.id }">Edit claim</div>
    <div *ngIf="!editMode"
         class="modal-dialog-title"
         flex
         [translate]="'claims.newClaim'">Add claim</div>
    <button tabindex="-1"
            mat-icon-button
            (click)="onCloseClicked()">
      <mat-icon>clear</mat-icon>
    </button>
  </div>
  <div class="flex-padding-24-margin-fix">
    <form [formGroup]="claimForm"
          (ngSubmit)="addNewClaim($event)">
      <div layout="column">
        <div layout="row"
             layout-wrap>
          <mat-form-field flex="100"
                          class="flex-padding-24">
            <input type="text"
                   matInput
                   required
                   formControlName="subject"
                   [readonly]="editMode"
                   placeholder="{{ 'global.subject' | translate }}">
            <mat-error *ngIf="claimForm.get('subject').hasError('minlength')"
                       [translate]="'forms.minLength'"
                       [translateParams]="{ value: claimSubjectMinLength }">
              Min length error
            </mat-error>
            <mat-error *ngIf="claimForm.get('subject').hasError('required')"
                       [translate]="'forms.required'">
              Field is required error
            </mat-error>
          </mat-form-field>
          <mat-form-field *ngIf="editMode"
                          class="flex-padding-24"
                          flex="100">
            <textarea matInput
                      cdkTextareaAutosize
                      cdkAutosizeMaxRows="8"
                      cdkAutosizeMinRows="3"
                      formControlName="notes"
                      placeholder="{{ 'claims.notes' | translate }}"></textarea>
          </mat-form-field>
          <div *ngIf="editMode"
               layout="row"
               flex="100"
               layout-align="start start">
            <mat-checkbox formControlName="completed">
              <div class="checkbox-label-text"
                   [translate]="'global.completed'">Completed claim</div>
            </mat-checkbox>
          </div>
        </div>
      </div>
      <div layout="row"
           layout-wrap
           layout-align="end end">
        <div class="flex-padding-24">
          <button type="submit"
                  [disabled]="['CLAIM_WRITE'] | isUnauthorized | async"
                  mat-stroked-button>
            {{ 'global.save' | translate }}
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
