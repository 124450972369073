import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { SearchOptions } from '../../core/types/search-options';
import { StorageService } from '../../core/services/storage.service';
import { APP_CONFIG } from '../../config/app-config';

@Injectable()
export class SearchOptionsResolver  {
  key: string;
  storage: StorageService;

  constructor() {
    this.storage = new StorageService(APP_CONFIG.FILTERING.STORAGE_TYPE);
  }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): SearchOptions {
    let searchOptions: SearchOptions;
    const serializedSearchOptions = this.storage.getItem(state.url);

    if (serializedSearchOptions) {
      const searchOptionsObject = JSON.parse(serializedSearchOptions);
      searchOptions = new SearchOptions(searchOptionsObject);
    } else {
      searchOptions = new SearchOptions();
    }
    return searchOptions;
  }
}
