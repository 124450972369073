import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CreditStatusIconComponent } from './credit-status-icon.component';
import { TranslateModule } from '@ngx-translate/core';
import { CreditPipesModule } from '../credit-pipes.module';
import { CreditStatusComponent } from '../credit-edit/credit-details/credit-status/credit-status.component';
import { MatButtonModule } from '@angular/material/button';
import { MaterialModule } from '../../../lib/material.module';
import { SharedModule } from '../../../shared/shared.module';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    MatIconModule,
    TranslateModule,
    MatTooltipModule,
    CreditPipesModule,
    MatButtonModule,
    MaterialModule
  ],
  declarations: [CreditStatusIconComponent, CreditStatusComponent],
  exports: [CreditStatusIconComponent, CreditStatusComponent],
})
export class CreditStatusIconModule {}
