import { Injectable } from '@angular/core';
import { FaceRecognitionRequestService } from './face-recognition-request.service';
import {map} from 'rxjs/operators';

@Injectable()
export class FaceRecognitionService {
  constructor(public request: FaceRecognitionRequestService) {}

  getUserVerification(civil_id: any, user_id: any, document_id: any) {
    return this.request.get(['verification'], {
      params: {
        civil_id,
        user_id,
        document_id
      }
    })
    .pipe(
      map(
        (verification): any => {
          if (!verification) {
            return verification;
          }
          const res = this.calculateConfidence(verification.distance, verification.max_threshold);
          verification['confidence'] = res * 100;
          console.log('opa', verification);
          return verification;
        }
      )
    );
  }

  verifyUserIdentity(civil_id: any, user_id: any, document_id: any) {
    return this.request.post(['verify'], {
        body: {
          first_img: {
            type: 'id_uploaded',
            data: {
              user_id,
              document_id
            }
          },
          second_img: {
            type: 'id_mi',
            data: civil_id
          }
      }
      })
      .pipe(
        map(
          (verification): any => {
            const res = this.calculateConfidence(verification.distance, verification.max_threshold);
            verification['confidence'] = res * 100;
            return verification;
          }
        )
      );
    }

    calculateConfidence(face_distance, face_match_threshold) {
      face_distance = Math.min(1, parseFloat(face_distance));
      face_match_threshold = parseFloat(face_match_threshold);
      if (face_distance > face_match_threshold) {
        let range = (1.0 - face_match_threshold)
        let linear_val = (1.0 - face_distance) / (range * 2.0)
        return linear_val
      } else {
        let range = face_match_threshold
        let linear_val = 1.0 - (face_distance / (range * 2.0))
        return linear_val + ((1.0 - linear_val) * Math.pow((linear_val - 0.5) * 2, 0.2))
      }
    }

}
