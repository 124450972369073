import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../../lib/material.module';
import { SharedModule } from '../../shared/shared.module';
import { CreditCalculatorFormComponent } from './credit-calculator-form.component';
import { DataTableModule } from '@app/core/components/data-table/data-table.module';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CreditPipesModule } from '../credit/credit-pipes.module';

@NgModule({
  imports: [
    MaterialModule,
    SharedModule,
    CommonModule,
    DataTableModule,
    CreditPipesModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    // MatTableModule,
  ],
  declarations: [CreditCalculatorFormComponent],
  exports: [CreditCalculatorFormComponent],
})
export class CreditCalculatorFormModule {}
