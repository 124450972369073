import { Pipe, PipeTransform } from '@angular/core';
import { CcrSummaryGroupNames } from '../../../core/types/ccr-summary-group';

@Pipe({
  name: 'summaryGroupToTranslation',
})
export class SummaryGroupToTranslationPipe implements PipeTransform {
  static readonly summaryGroupToTranslationMap: { [key: string]: string } = {
    [CcrSummaryGroupNames.TYPE]: 'ccr.summaryGroups.type',
    [CcrSummaryGroupNames.OVERDUE_PAYMENT_PERIOD]:
      'ccr.summaryGroups.overDuePaymentPeriod',
    [CcrSummaryGroupNames.REST]: 'ccr.summaryGroups.rest',
    [CcrSummaryGroupNames.TERM]: 'ccr.summaryGroups.term',
    [CcrSummaryGroupNames.TOTAL]: 'ccr.summaryGroups.total',
  };

  transform(summaryGroup: CcrSummaryGroupNames): string {
    return SummaryGroupToTranslationPipe.summaryGroupToTranslationMap[
      summaryGroup
    ];
  }
}
