import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ColumnCardComponent } from './column-card/column-card.component';
import { MaterialModule } from '../../lib/material.module';
import { ColumnButtonDirective } from './column-button-directive/column-button.directive';
import { TranslateModule } from '@ngx-translate/core';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatDialogModule } from '@angular/material/dialog';
import { ColumnButtonComponent } from './column-button/column-button.component';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    TranslateModule,
    DragDropModule,
    MatDialogModule,
  ],
  declarations: [
    ColumnCardComponent,
    ColumnButtonDirective,
    ColumnButtonComponent,
  ],
  exports: [ColumnCardComponent, ColumnButtonDirective, ColumnButtonComponent],
})
export class ColumnModule {}
