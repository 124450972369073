import { Injectable } from '@angular/core';
import { EventLogFilterTemplate } from './event-log-filter-template';
import { Subject } from 'rxjs';
import { EventLogService } from '@app/core/services/event-log.service';
import { StorageService } from '@app/core/services/storage.service';
import { BrowserStorageType } from '@app/config/app-config';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import Joi from 'joi';

@Injectable({
  providedIn: 'root'
})
export class EventLogFilteringService {
  storage: StorageService;
  filterTemplateChanged$ = new Subject<EventLogFilterTemplate>();
  originalFilterTemplate: EventLogFilterTemplate;

  filterTemplateSchema = Joi.object({
    name: Joi.string().required(),
    shown: Joi.boolean().required(),
    subTemplates: Joi.array().items(
      Joi.object({
        name: Joi.string().required(),
        shown: Joi.boolean().required(),
        group: Joi.string().required()
      }).required()
    )
  });

  filterTemplate: EventLogFilterTemplate = {
    name: 'DEFAULT',
    shown: true,
    subTemplates: [
      { name: 'BANKACCOUNT', shown: true, group: 'CREDITS' },
      { name: 'CONTACTPHONE', shown: true, group: 'CREDITS' },
      { name: 'CALL', shown: true, group: 'CLIENT_COMMUNICATION' },
      { name: 'NOTE', shown: true, group: 'CLIENT_COMMUNICATION' },
      { name: 'CREDIT', shown: true, group: 'CREDITS' },
      { name: 'TRANSACTION', shown: true, group: 'CREDITS' },
      { name: 'USER', shown: true, group: 'CREDITS' },
      { name: 'USERDOCUMENT', shown: true, group: 'CREDITS' },
      { name: 'USERLOGIN', shown: true, group: 'CREDITS' },
      { name: 'COLLECTION', shown: true, group: 'COLLECTION' },
      { name: 'REPORT', shown: true, group: 'IDENTITY_REPORTS' },
      { name: 'PAYMENTPROMISE', shown: true, group: 'COLLECTION' },
      { name: 'PAYMENTAGREEMENT', shown: true, group: 'COLLECTION' },
      { name: 'SCORE', shown: true, group: 'SCORE' }
    ]
  };

  constructor(public eventLogService: EventLogService) {
    this.storage = new StorageService(BrowserStorageType.LocalStorage);
    this.initializeFilterTemplate();
    this.originalFilterTemplate = this.getFilterTemplate() || this.filterTemplate;

    this.filterTemplateChanged$.subscribe(newFilterTemplate => {
      this.filterTemplate = newFilterTemplate;
      this.originalFilterTemplate = JSON.parse(JSON.stringify(newFilterTemplate));
      this.checkIfShouldUpdateEventLog(this.originalFilterTemplate, newFilterTemplate);
    });
  }

  initializeFilterTemplate() {
    const storedTemplateJson = this.storage.getItem('EVENT_LOG_FILTER_TEMPLATE');
    const storedTemplate = storedTemplateJson ? JSON.parse(storedTemplateJson) : null;
    const { error } = this.filterTemplateSchema.validate(storedTemplate);
  
    if (error || !storedTemplate) {
      this.setFilterTemplate(this.filterTemplate);
    } else {
      this.filterTemplate = this.mergeTemplates(this.filterTemplate, storedTemplate);
      this.setFilterTemplate(this.filterTemplate);
    }
  }

  mergeTemplates(defaultTemplate: EventLogFilterTemplate, storedTemplate: EventLogFilterTemplate): EventLogFilterTemplate {
    const defaultSubTemplateNames = defaultTemplate.subTemplates.map(st => st.name);
    const filteredStoredSubTemplates = storedTemplate.subTemplates.filter(st => defaultSubTemplateNames.includes(st.name));

    const mergedSubTemplates = [...filteredStoredSubTemplates];

    defaultTemplate.subTemplates.forEach(defaultSubTemplate => {
      const found = storedTemplate.subTemplates.find(st => st.name === defaultSubTemplate.name);
      if (!found) {
        mergedSubTemplates.push(defaultSubTemplate);
      }
    });

    return {
      ...storedTemplate,
      subTemplates: mergedSubTemplates
    };
  }

  setFilterTemplate(template: EventLogFilterTemplate) {
    this.filterTemplate = template;
    this.storage.setItem('EVENT_LOG_FILTER_TEMPLATE', JSON.stringify(template));
    this.filterTemplateChanged$.next(template);
  }

  compareFilterConfiguration(a: EventLogFilterTemplate, b: EventLogFilterTemplate): boolean {
    if (!a || !b) {
      return false;
    }
    const aSubTemplates = a.subTemplates.map(st => ({ name: st.name, shown: st.shown }));
    const bSubTemplates = b.subTemplates.map(st => ({ name: st.name, shown: st.shown }));
    return JSON.stringify(aSubTemplates) === JSON.stringify(bSubTemplates);
  }

  getFilterTemplate(): EventLogFilterTemplate {
    return this.filterTemplate;
  }

  checkIfShouldUpdateEventLog(oldTemplate: EventLogFilterTemplate, newTemplate: EventLogFilterTemplate) {
    if (!oldTemplate || !newTemplate || !oldTemplate.subTemplates || !newTemplate.subTemplates) {
      return;
    }

    const changesDetected = newTemplate.subTemplates.some((sub, index) => {
      return oldTemplate.subTemplates[index] && sub.shown !== oldTemplate.subTemplates[index].shown;
    });

    if (changesDetected) {
      this.eventLogService.refreshEventLog.next();
    }
  }

  slideToggleChanged(event: MatSlideToggleChange) {
    this.eventLogService.clientLogToggled = event.checked;
    this.eventLogService.refreshEventLog.next();
  }
}
