import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewContainerRef, ViewChild} from '@angular/core';
import {ColumnsService} from '../../../core/services/columns.service';
import {catchError, debounceTime, filter, mergeMap, takeUntil} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';
import {SearchOptions} from '../../../core/types/search-options';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {NotificationService} from '../../../core/services/notification.service';
import {Credit} from '../../../core/types/credit';
import {ErrorService} from '../../../core/services/error.service';
import {SearchOperations} from '../../../core/types/search-criterium';
import { ItfgDataTableColumn } from '@app/core/components/data-table/types/data-table.column';
import {Subject, from, of} from 'rxjs';
import {Page} from '../../../core/types/page';
import {ClientService} from '../../../core/services/client.service';
import {Client} from '../../../core/types/client';
import {ListBaseComponent} from '../../list-base/list-base.component';
import {ConfirmDialogComponent} from '../../../core/components/confirm-dialog/confirm-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import { ProgressBarDialogComponent } from '../../../core/components/progress-bar-dialog/progress-bar-dialog.component';
import { LoyaltyPointsService } from '../../../core/services/loyalty-points.service';
import { MatPaginator } from '@angular/material/paginator';
import { DialogProviderService } from '@app/core/services/dialog-provider.service';

@Component({
  selector: 'itfg-bulk-loyalty-points',
  templateUrl: './bulk-loyalty-points.component.html',
  styleUrls: ['./bulk-loyalty-points.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BulkLoyaltyPointsComponent extends ListBaseComponent implements OnInit, OnDestroy {
  public form: UntypedFormGroup;
  public clientPage: Page<Client> = {};
  public columns: ItfgDataTableColumn[];
  public searchOptions = new SearchOptions({
    criteria: [],
  });
  public isLoadingResult = false;
  public currentDate = new Date();
  public clientIds = [];
  public errorClientIds = [];
  public translations;
  public _unsubscribe = new Subject<void>();
  public addLoyaltyPointsMinAmount = 1;
  _viewContainerRef: ViewContainerRef;
  @ViewChild('pagingBarResponsive', { static: true })
  public pagingBarResponsive: MatPaginator;

  constructor(
    private fb: UntypedFormBuilder,
    private clientService: ClientService,
    private translate: TranslateService,
    public columnsService: ColumnsService,
    private errorHandler: ErrorService,
    private notification: NotificationService,
    public dialog: MatDialog,
    public dialogProvider: DialogProviderService,
    private changeDetectorRef: ChangeDetectorRef,
    private loyaltyPointsService: LoyaltyPointsService,
  ) {
    super(columnsService);
    this.form = fb.group({
      clientIds: '',
      amount: ['', [Validators.required, Validators.min(this.addLoyaltyPointsMinAmount)]],
      expiryDate: ['', [Validators.required]]
    });
    this.form
      .get('clientIds')
      .valueChanges.pipe(
      debounceTime(500),
      takeUntil(this._unsubscribe)
    )
      .subscribe(change => {
        this.matchClientIds(change);
      });
  }

  ngOnInit() {
    this.getTranslations().subscribe(translations => {
      this.translations = translations
      this.setupColumnConfig(translations);
    });
    this.triggerDataChange$.subscribe((searchOptions: SearchOptions) => {
      this.loadClientsPreview();
    });
  }

  getTranslations() {
    return this.translate.get([
      'global.numberSign',
      'global.client',
      'clients.civilId',
      'clients.loyaltyPointsBulkAddProgressBar.title',
      'clients.loyaltyPointsBulkAddError',
    ]);
  }

  setupColumnConfig(translations) {
    this.columns = [
      {
        name: 'id',
        label: translations['global.numberSign'],
        width: 70,
      },
      {
        name: 'fullName',
        label: translations['global.client'],
        isSortable: false,
      },
      {
        name: 'civilId',
        label: translations['clients.civilId'],
      },

    ];
  }

  matchClientIds(value: string) {
    this.isLoadingResult = true;

    this.clientIds = this.parseClientIds(value);
    if (this.clientIds.length > 0) {
      this.searchOptions.page = 0;
      this.searchOptions.criteria = [
        {
          key: 'id',
          operation: SearchOperations.IN,
          value: this.clientIds,
        },
      ];
      this.loadClientsPreview();
    } else {
      this.clientPage['content'] = [];
    }
  }

  loadClientsPreview() {
    this.searchOptions.criteria = [
      {
        key: 'id',
        operation: SearchOperations.IN,
        value: this.clientIds,
      },
    ];
    this.clientService
      .getClientList(this.searchOptions.getDTO())
      .subscribe((clients: Credit[]) => {
        this.clientPage = clients;
        this.isLoadingResult = false;
        this.changeDetectorRef.detectChanges();
      });
  }

  pageChanged(page: number) {
    super.pageChanged(page);
    this.loadClientsPreview();
  }

  parseClientIds(text: string) {
    const clientIds = new Set();
    const regex = /(\d+)/gim;
    let match;
    do {
      match = regex.exec(text);
      if (match) {
        clientIds.add(Number(match[1]));
      }
    } while (match);

    return Array.from(clientIds);
  }

  onSubmit(event: any) {
  const amount = this.form.get('amount').value;
  const expiryDate = this.form.get('expiryDate').value;
  
  this.getDialogConfig('clients.loyaltyPointsBulkAddConfirmation.title',
   'clients.loyaltyPointsBulkAddConfirmation.body',  { amount, clientsCount: this.clientIds.length })
        .afterClosed()
        .pipe(filter(accept => accept === true))
        .subscribe((accept: boolean) => {
          if (accept) {
             this.updateClientsLoyaltyPoints(amount, expiryDate);
          }
        });
  }

  async updateClientsLoyaltyPoints(amount, expiryDate = null) {
    let processedRequests = 0;

    const dialogConfig = {
      progressBarValue: 0,
      message: this.translate.instant('clients.loyaltyPointsBulkAddProgressBar.body', {processed: 0, total: this.clientIds.length}),
      disableClose: false,
      viewContainerRef: this._viewContainerRef,
      title: this.translations['clients.loyaltyPointsBulkAddProgressBar.title'], 
    };

    const dialogRef = this.dialog.open(ProgressBarDialogComponent, {
      data: dialogConfig,
      width: '400px',
      restoreFocus: false,
      autoFocus: false
    });
    
    from(this.clientIds)
    .pipe(
      mergeMap(clientId =>
          // creates disposable stream so the main stream stays alive if there is an error
          this.loyaltyPointsService.addLoyaltyPoints$(clientId, expiryDate, amount)
          .pipe(catchError(err => {
            this.errorClientIds.push(clientId);
            return of([])
          }))
      ))
    .subscribe(res => {
      processedRequests++
      dialogRef.componentInstance.changeProgressBarValue(processedRequests, this.clientIds.length);
      dialogRef.componentInstance.data.message = this.translate.instant('clients.loyaltyPointsBulkAddProgressBar.body', {processed: processedRequests, total: this.clientIds.length})
      if(this.errorClientIds.length > 0) {
        dialogRef.componentInstance.data.expandingMessage = {
          title: this.translations['clients.loyaltyPointsBulkAddError'],
          message: this.errorClientIds.join('\n')
        }
      }
      if (processedRequests === this.clientIds.length) {
        this.notification.showLocalizedSuccessMessage({ notificationText: this.translate.instant('clients.loyaltyPointsAddSuccessMessage')});
      }
      
      this.changeDetectorRef.detectChanges(); 
    },  (error: any) => {
      this.notification.showLocalizedErrorMessage({ notificationText: this.translate.instant('clients.loyaltyPointsAddErrorMessage') });
    })
  }

  getDialogConfig(titleKey: string, messageKey: string, params?: any) {
    const translations = this.translate.instant(
      [
        'global.cancel', 
        'global.confirm', 
        titleKey, 
        messageKey
      ],
      params
    );
    const dialogConfig = {
      message: translations[messageKey],
      disableClose: false,
      viewContainerRef: this._viewContainerRef,
      title: translations[titleKey],
      cancelButton: translations['global.cancel'],
      acceptButton: translations['global.confirm'],
      width: '400px',
      restoreFocus: false,
      autoFocus: false,
    };
    return this.dialogProvider.openConfirm(dialogConfig);
  }

  trimClientIds() {
    this.form.patchValue({ clientIds: this.clientIds.join(', ') });
    this.matchClientIds(this.clientIds.join(', '));
  }

  ngOnDestroy(): void {
    this._unsubscribe.next();
    this._unsubscribe.complete();
  }
}
