import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'itfg-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss'],
})
export class PageNotFoundComponent implements OnInit, OnDestroy {
  public countdown;
  public timeLeft = 4;
  constructor( private router: Router) {}

  ngOnInit() {
    this.countdown = setInterval(() => {
      this.timeLeft = this.timeLeft - 1; 
      
      if(!this.timeLeft){
        this.router.navigate([''])
      }
    }, 1000)
  }

  ngOnDestroy() {
    clearInterval(this.countdown);
  }
}
