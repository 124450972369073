import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { FilterValueListService, FilterValue } from '../filter/types';
import { AccountService } from '../../core/services/account.service';
import { Observable } from 'rxjs';
import { AccountOwnerType } from '../../core/types/account-owner-type';

@Injectable()
export class AccountOwnerTypeValueListService
  implements FilterValueListService {
  constructor(private accountTypes: AccountService) {}

  getFilterValues$(): Observable<FilterValue[]> {
    return this.accountTypes.getAccountOwnerTypes$().pipe(
      map((accountOwnerTypeList: AccountOwnerType[]): FilterValue[] => {
        return accountOwnerTypeList.map(
          (accountOwnerType: AccountOwnerType): FilterValue => {
            return {
              value: accountOwnerType.id,
              displayText: accountOwnerType.name,
            };
          }
        );
      })
    );
  }
}
