import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  SearchOptions,
  SearchDirection,
} from '../../core/types/search-options';
import { TranslateService } from '@ngx-translate/core';
import { ItfgDataTableColumn } from '@app/core/components/data-table/types/data-table.column';
import { SearchCriterium } from '../../core/types/search-criterium';
import { FilterService } from '../filter/filter.service';
import { ClaimListFilterConfigService } from './claim-list-filter-config.service';
import { ColumnsService } from '../../core/services/columns.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ListBaseComponent } from '../list-base/list-base.component';

@Component({
  selector: 'itfg-claim',
  templateUrl: './claim.component.html',
  styleUrls: ['./claim.component.scss'],
  providers: [FilterService, ClaimListFilterConfigService],
})
export class ClaimComponent extends ListBaseComponent implements OnInit, OnDestroy {
  public claimColumns: ItfgDataTableColumn[];
  public appliedColumns: ItfgDataTableColumn[];
  public searchOptions: SearchOptions;
  public filtersShown: boolean;
  public filterCardSettingsShown: boolean;
  _unsubscribe: Subject<void> = new Subject<void>();

  constructor(
    private translateService: TranslateService,
    public filterConfig: ClaimListFilterConfigService,
    public filters: FilterService,
    public columnsService: ColumnsService
  ) {
    super(columnsService);
    this.filtersShown = false;
    this.filterCardSettingsShown = false;
    this.triggerDataChange$.subscribe((searchOptions: SearchOptions) => {
      this.searchOptions = new SearchOptions({
        ...searchOptions
      });
    });
  }

  ngOnInit() {
    this.initializeColumns();
    this.columnsTableChangeSubscription();
    this.setClaimSearchOptions();
  }

  initializeColumns() {
    this.translateService
      .get([
        'global.actions',
        'global.client',
        'global.numberSign',
        'claims.createDate',
        'claims.completed',
        'claims.subject',
        'claims.notes',
        'filtering.names.userId',
      ])
      .subscribe((labels: string[]) => {
        this.claimColumns = [
          {
            name: 'id',
            label: labels['global.numberSign'],
            width: 120,
          },
          {
            name: 'user',
            label: labels['global.client'],
            width: 120,
          },
          {
            name: 'time',
            label: labels['claims.createDate'],
            width: 250,
          },
          {
            name: 'completed',
            label: labels['claims.completed'],
            width: 150,
          },
          {
            name: 'subject',
            label: labels['claims.subject'],
            width: 400,
          },
          {
            name: 'notes',
            label: labels['claims.notes'],
          },
          {
            name: 'actions',
            numeric: true,
            label: labels['global.actions'],
            isSortable: false,
          },
        ];
      });
  }

  setClaimSearchOptions() {
    this.searchOptions = new SearchOptions({
      page: 0,
      pageSize: 20,
      direction: SearchDirection.DESCENDING,
      column: 'id',
    });
  }

  ngOnDestroy() {
    this._unsubscribe.next();
    this._unsubscribe.complete();
  }
}
