<div class="notes">
  <div class="decorated-heading">Бележки</div>

  <div class="content-div">
    <div flex layout="row" class="promises-reminder-div">
      <div flex="1">
        <itfg-payment-promises
          [openedCredit]="openedCredit"
        ></itfg-payment-promises>
      </div>

      <div flex="1">
        <itfg-reminder [openedCredit]="openedCredit" flex="1"></itfg-reminder>
      </div>
    </div>
    <div class="note-div">
      <itfg-note
        [openedCredit]="openedCredit"
        [selectedCredit]="selectedCredit"
      ></itfg-note>
    </div>
  </div>
</div>
