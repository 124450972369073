import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RequestService } from './request.service';
import { EntityHistoryService, EntityHistory } from '../types/entities';
import { EntityHistoryFilter } from '../types/entity-history-filter';

@Injectable()
export class ZoneService implements EntityHistoryService {
  request: RequestService;

  constructor(request: RequestService) {
    this.request = request;
  }

  getZoneList(brandId?: string): Observable<any> {
    const options: any = {};
    if (brandId) {
      options.params = { brandId: brandId };
    }

    return this.request.get(['zones'], options);
  }

  getZoneById(id: string): Observable<any> {
    return this.request.get(['zones', id]);
  }

  deleteZone(id: string): Observable<any> {
    return this.request.delete(['zones', id], {
      responseType: 'text',
    });
  }

  saveZone(zoneFormData: any): Observable<any> {
    zoneFormData.name = zoneFormData.name.trim('');

    return this.request.post(['zones'], {
      headers: { 'Content-Type': 'application/json' },
      body: zoneFormData,
    });
  }

  getZoneHistory(filter: EntityHistoryFilter): Observable<EntityHistory<any>> {
    return this.request.get(['zones', 'history'], {
      params: {
        filter: filter.getDTO(),
      },
    });
  }

  getEntityHistory(filter: EntityHistoryFilter) {
    return this.getZoneHistory(filter);
  }
}
