import { mergeMap, filter, takeUntil, map } from 'rxjs/operators';
import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
  OnDestroy,
} from '@angular/core';
import { ItfgDataTableColumn } from '@app/core/components/data-table/types/data-table.column';
import { TranslateService } from '@ngx-translate/core';
import { Credit } from '../../../core/types/credit';
import { ClientService } from '../../../core/services/client.service';
import {
  SearchOptions,
  SearchDirection,
} from '../../../core/types/search-options';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Client } from '../../../core/types/client';
import { ErrorService } from '../../../core/services/error.service';
import { Subject, forkJoin } from 'rxjs';
import { CreditStatusNames } from '../../../core/types/credit-status';
import { Brand } from '../../../core/types/brand';
import {
  HistoryCredit,
  HistorySummary,
} from '../../../core/types/client-credits-history';
import { GcCreditSummaryService } from '../../../core/services/gc-credit-summary.service';
import { GcSummaryCredit } from '../../../core/types/gc-credit-summary';
import { PageName } from '../../../core/types/user-preferences';
import { ColumnsService } from '../../../core/services/columns.service';
import { BrandService } from '../../../core/services/brand.service';
import { Decimal } from '@app/core/utils/decimal';
import { FilterUIType } from '@app/main/filter/types';
import { ColumnFilterConfig } from '@app/main/filter/types/column-filter-config';

@Component({
  selector: 'itfg-client-credits-history',
  templateUrl: './client-credits-history.component.html',
  styleUrls: ['./client-credits-history.component.scss'],
})
export class ClientCreditsHistoryComponent
  implements OnInit, OnChanges, OnDestroy {
  @Input() client: Client;
  @Input() currentCredit: Credit;

  columns: ItfgDataTableColumn[];
  appliedColumns: ItfgDataTableColumn[];
  _shownColumns: ItfgDataTableColumn[];
  _shownColumnsNames: string[];
  get shownColumns() {
    return this._shownColumns;
  }

  set shownColumns(columns) {
    this._shownColumns = columns;
    this._shownColumnsNames = columns.map(c => c.name);
  }

  pageName: PageName;
  creditHistoryData: HistoryCredit[];
  CLIENT_CREDITS_HISTORY_LIMIT = 30;
  isAllCreditHistoryShown = false;
  brandData: Brand[];
  creditsSummary: HistorySummary[];
  isHistoryData: boolean;
  searchOptions: SearchOptions;
  public isExistingClient: boolean;
  public currentTabClicked: boolean;
  _unsubscribe: Subject<void> = new Subject<void>();

  constructor(
    private translateService: TranslateService,
    private clientService: ClientService,
    private route: ActivatedRoute,
    private errorService: ErrorService,
    private gcCreditSummaryService: GcCreditSummaryService,
    private columnsService: ColumnsService,
    private brandDataService: BrandService,
  ) {
    this.isExistingClient = false;
    this.currentTabClicked = false;
    this.creditHistoryData = [];
    this.isHistoryData = true;
    this.creditsSummary = [];
    this.pageName = PageName.CLIENT_CREDIT_HISTORY;
    this.searchOptions = new SearchOptions({
      column: 'id',
      direction: SearchDirection.DESCENDING,
    });
  }

  ngOnInit() {
    // this.getColumnTranslations();
    this.columnsTableChangeSubscription();
    this.route.paramMap
      .pipe(
        filter((params: ParamMap) => typeof params.get('id') === 'string'),
        mergeMap((params: ParamMap) =>
          this.clientService.getClientById(Number(params.get('id')))
        ),
        takeUntil(this._unsubscribe)
      )
      .subscribe(
        client => {
          this.client = client;
          this.isExistingClient = true;
          this.creditHistoryData = [];
          this.isAllCreditHistoryShown = false;
          this.getClientCreditHistory(this.client, this.CLIENT_CREDITS_HISTORY_LIMIT);
        },
        error => {
          this.errorService.handleError(error, this.route.data);
        }
      );
  }

  ngOnChanges(changes: SimpleChanges) {
    this.isExistingClient = true;
    if (changes.client && changes.client.currentValue.civilId) {
      this.isAllCreditHistoryShown = false;
      this.getClientCreditHistory(this.client, this.CLIENT_CREDITS_HISTORY_LIMIT);
    }
  }

  getColumnTranslations(options?: any) {
    this.translateService
      .get([
        'global.client',
        'global.credit',
        'credits.contractDateShort',
        'credits.repaymentTimeShort',
        'global.brand',
        'credits.creditType',
        'global.principal',
        'global.status',
        'credits.overdue',
        'global.discountCode',
        'credits.installmentPeriod',
        'plan.installmentTotal',
        'global.installments',
        'credits.repaidAmount',
        'credits.refinance',
        'credits.createTime',
        'global.tags',
      ])
      .subscribe(translation => {
        this.columns = [
          { name: 'userId', label: translation['global.client'], width: 80 },
          { name: 'creditId', label: translation['global.credit'], width: 80,
          filterConfig: new ColumnFilterConfig({
            path: 'creditId',
            translationKey: 'creditId',
            type: FilterUIType.INPUT,
          }) },
          { name: 'brand', label: translation['global.brand'], width: 100 },
          { name: 'status',
          label: translation['global.status'],
          width: 100,
          filterConfig: new ColumnFilterConfig({
            path: 'status.name',
            translationKey: 'status',
            optionsTranslationPath: 'credits.creditStatusList',
            type: FilterUIType.MULTISELECT,
          })
        },
          {
            name: 'tags',
            label: translation['global.tags'],
            isSortable: false,
            width: { min: 125 },
          },
          {
            name: 'principal',
            label: translation['global.principal'],
            width: { min: 130 },
            numeric: true,
          },
          {
            name: 'refinanceReference',
            label: translation['credits.refinance'],
            width: 100,
          },
          { name: 'createTime', label: translation['credits.createTime'] },
          {
            name: 'contractTime',
            label: translation['credits.contractDateShort'],
          },
          {
            name: 'repaymentDate',
            label: translation['credits.repaymentTimeShort'],
          },
          { name: 'overdueDays', label: translation['credits.overdue'] },
          {
            name: 'repaidAmount',
            label: translation['credits.repaidAmount'],
            numeric: true,
          },
          {
            name: 'productName',
            label: translation['credits.creditType'],
            isSortable: false,
            filterConfig: new ColumnFilterConfig({
              path: 'productName',
              translationKey: 'product',
              type: FilterUIType.MULTISELECT,
            })
          },
          {
            name: 'installmentAmountWithDiscount',
            label: translation['plan.installmentTotal'],
            numeric: true,
          },
          {
            name: 'installmentDays',
            label: translation['credits.installmentPeriod'],
            width: 100
          },
          {
            name: 'installmentsNumber',
            label: translation['global.installments'],
          },
          { name: 'discountCode', label: translation['global.discountCode'] },
        ];
        this.shownColumns = this.columns;
      });
  }

  getFullClientCreditHistory(event) {
    if (event.checked) {
      this.isAllCreditHistoryShown = event.checked;
      this. getClientCreditHistory(this.client);
    } else {
      this.isAllCreditHistoryShown = event.checked;
      this. getClientCreditHistory(this.client, this.CLIENT_CREDITS_HISTORY_LIMIT);
    }
  }

  getClientCreditHistory(client: Client, limit?) {
    const gcCredits$ = this.gcCreditSummaryService.getSummary$(
      this.client.civilId
      );
    forkJoin(
        this.clientService.getClientCreditsHistory$(client.id, limit),
        gcCredits$,
        this.brandDataService.getBrandList()
    ).pipe(
        map(([creditsListHistoryData, gcHistoryData, brandData]) => {
            const creditsListHistoryDataWithDiscount = creditsListHistoryData.map(credit => ({
                ...credit,
                installmentAmountWithDiscount: this.calculateInstallmenTotalWithDiscount(credit)
            }));

            return [creditsListHistoryDataWithDiscount, gcHistoryData, brandData] as [HistoryCredit[], GcSummaryCredit[], Brand[]];
        })
    ).subscribe(([creditsListHistoryDataWithDiscount, gcHistoryData, brandData]) => {
        this.clientService.clientCreditHistory$.next(creditsListHistoryDataWithDiscount);
        this.creditHistoryData = creditsListHistoryDataWithDiscount;

        this.isHistoryData = this.creditHistoryData.length > 0;
        this.creditsSummary = [];
        this.getScCreditsSummary(this.creditHistoryData, brandData);
        this.getGcCreditSummary(gcHistoryData, brandData);
        this.getColumnTranslations();
    });
}

  getScCreditsSummary(creditsListHistoryData, brandData: Brand[]) {
    const brand = brandData.filter(item => item.id === 1)[0];
    const creditsByBrand = creditsListHistoryData.filter(
      credit => credit.brand.name === brand.name
    );

    const summaryByBrand: HistorySummary = {
      brand: brand,
      totalCredits: creditsByBrand.length,
      rejectedCredits: creditsByBrand.filter(
        credit => credit.status.name === CreditStatusNames.REJECTED
      ).length,
      repaidCredits: creditsByBrand.filter(
        credit => credit.status.name === CreditStatusNames.REPAID
      ).length,
    };

    this.creditsSummary.push(summaryByBrand);
  }

  getGcCreditSummary(gcHistoryData: GcSummaryCredit[], brandData: Brand[]) {
    const brand = brandData.filter(item => item.id === 2)[0];
    const creditList = gcHistoryData;
    const gcSummary = {
      brand,
      totalCredits: creditList.length,
      rejectedCredits: creditList.filter(credit => credit.status === 'denied')
        .length,
      repaidCredits: creditList.filter(credit => credit.status === 'repaid')
        .length,
    };
    this.creditsSummary.push(gcSummary);
  }

  columnsTableChangeSubscription() {
    this.columnsService.onColumnsTableChange
      .pipe(takeUntil(this._unsubscribe))
      .subscribe(columns => {
        this.appliedColumns = columns;
        this.shownColumns = this.appliedColumns;
      });
  }

  calculateInstallmenTotalWithDiscount(credit) {
    const installmentForfeitDiscount =  Math.ceil((credit.discountAmount / credit.installmentsNumber) * 100) / 100;
    const creditInstallmentTotal = Decimal.sumNumbers(credit.installmentAmount, credit.installmentForfeit, credit.installmentUtilizationFee);
    const creditInstallmentWithDiscount = new Decimal(creditInstallmentTotal).minus(new Decimal(installmentForfeitDiscount)).toNumber();

    return creditInstallmentWithDiscount;
  }

  ngOnDestroy() {
    this._unsubscribe.next();
    this._unsubscribe.complete();
  }
}
