import { Directive, Input, OnInit, Renderer2, ElementRef } from '@angular/core';
import { NgxPermissionsService } from 'ngx-permissions';

enum UnauthorizedStrategies {
  DISABLE = 'DISABLE',
  POINTER_EVENTS_NONE = 'POINTER_EVENTS_NONE',
}

enum HtmlElementTagNames {
  INPUT = 'INPUT',
  TEXTAREA = 'TEXTAREA',
  BUTTON = 'BUTTON',
  MAT_CHECKBOX = 'MAT-CHECKBOX',
}

@Directive({
  selector: '[itfgPermissions]',
})
export class PermissionsDirective implements OnInit {
  @Input() itfgPermissions: string | string[];
  @Input() itfgPermissionsUnauthorizedStrategy = UnauthorizedStrategies.DISABLE;

  constructor(
    private ngxPermissionsService: NgxPermissionsService,
    private renderer2: Renderer2,
    private elementRef: ElementRef
  ) {}

  ngOnInit() {
    this.initialize();
  }

  get nativeElement(): HTMLElement {
    return this.elementRef.nativeElement;
  }

  initialize() {
    this.ngxPermissionsService
      .hasPermission(this.itfgPermissions)
      .then(isAuthorized => {
        this.switchStrategy(isAuthorized);
      });
  }

  switchStrategy(isAuthorized: boolean) {
    if (!isAuthorized) {
      this.executeSelectedUnauthorizedStrategy();
    } else {
      // implement logic for unauthorized strategy if needed
    }
  }

  executeSelectedUnauthorizedStrategy() {
    switch (this.itfgPermissionsUnauthorizedStrategy) {
      case UnauthorizedStrategies.DISABLE:
        this.disableStrategy(this.nativeElement);
        break;
      case UnauthorizedStrategies.POINTER_EVENTS_NONE:
        this.pointerEventsNoneStrategy(this.nativeElement);
        break;
    }
  }

  disableStrategy(element: HTMLElement) {
    if (element) {
      this.applyHtmlElementStrategy(element);
      const childElements = Array.from(
        element.querySelectorAll('input, button, mat-checkbox, textarea')
      );
      childElements.forEach((childElement: HTMLElement) =>
        this.applyHtmlElementStrategy(childElement)
      );
    }
  }

  applyHtmlElementStrategy(element: HTMLElement) {
    switch (element.tagName) {
      case HtmlElementTagNames.BUTTON:
        this.disableButton(element);
        break;
      case HtmlElementTagNames.INPUT:
      case HtmlElementTagNames.TEXTAREA:
        this.makeElementReadOnly(element);
        break;
      case HtmlElementTagNames.MAT_CHECKBOX:
        this.disableCheckbox(element);
        break;
    }
  }

  disableElement(element: HTMLElement) {
    this.renderer2.setProperty(element, 'disabled', true);
    this.renderer2.setAttribute(element, 'disabled', '');
  }

  disableCheckbox(checkbox: HTMLElement) {
    const checkBoxInput = checkbox.querySelector('input');
    this.disableElement(checkBoxInput);
    this.renderer2.setStyle(checkbox, 'pointer-events', 'none');
  }

  disableButton(button: HTMLElement) {
    this.disableElement(button);
  }

  makeElementReadOnly(element: HTMLElement) {
    this.disableElement(element);
    this.renderer2.setStyle(element, 'color', 'inherit');
    this.renderer2.setStyle(element, 'cursor', 'text');
  }

  pointerEventsNoneStrategy(element: HTMLElement) {
    this.renderer2.setStyle(element, 'pointer-events', 'none');
  }
}
