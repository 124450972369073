import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewContainerRef } from '@angular/core';
import { Client } from '../../../core/types/client';
import { filter, mergeMap, takeUntil } from 'rxjs/operators';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { ClientService } from '@core/services/client.service';
import { ErrorService } from '@core/services/error.service';
import { Subject } from 'rxjs';
import { IdentityReportsService } from '@core/services/identity-reports.service';
import {
  ClientMapperTypes, ClientPersonDataMapperTypes,
  IdentityReport,
  ListElement, PersonDataReportData,
  ReportConfig
} from '@core/types/identity-reports';
import {TranslateService} from '@ngx-translate/core';
import {DialogProviderService} from '@core/services/dialog-provider.service';

@Component({
  selector: 'itfg-person-data-report',
  templateUrl: './person-data-report.component.html',
  styleUrls: ['./person-data-report.component.scss']
})
export class PersonDataReportComponent implements OnInit, OnChanges, OnDestroy {
  @Input() inputClient: Client;

  public client: Client;
  public personDataListData: ListElement[];
  public isReport = false;
  public isListData = false;
  public personDataData: IdentityReport<any>;
  public personDataReportData: PersonDataReportData;
  public requestType: string;
  public requestTime: Date = new Date();
  public requesterName: string;
  public requestStatus = null;
  public personDataConfig: ReportConfig;
  public CLIENT_PERSONAL_INFO_MAPPER: { [key: string]: string };
  public criticalProperties: any[];
  public notUpdatableProperties: any[];
  public dialogConfig: any;

  _unsubscribe: Subject<void> = new Subject<void>();
  constructor(private route: ActivatedRoute,
              private clientService: ClientService,
              private errorService: ErrorService,
              private identityReportsService: IdentityReportsService,
              private translate: TranslateService,
              private dialogService: DialogProviderService,
              private _viewContainerRef: ViewContainerRef) {

    this.CLIENT_PERSONAL_INFO_MAPPER = {
      [ClientMapperTypes.FIRST_NAME]: ClientPersonDataMapperTypes.FIRST_NAME,
      [ClientMapperTypes.MIDDLE_NAME]: ClientPersonDataMapperTypes.MIDDLE_NAME,
      [ClientMapperTypes.LAST_NAME]: ClientPersonDataMapperTypes.LAST_NAME,
      [ClientMapperTypes.NATIONALITY_NAME]: ClientPersonDataMapperTypes.NATIONALITY_NAME,
      [ClientMapperTypes.SECOND_NATIONALITY_NAME]: ClientPersonDataMapperTypes.SECOND_NATIONALITY_NAME,
    };

    this.criticalProperties = [
      ClientMapperTypes.FIRST_NAME,
      ClientMapperTypes.MIDDLE_NAME,
      ClientMapperTypes.LAST_NAME,
      ClientMapperTypes.NATIONALITY_NAME,
      ClientMapperTypes.SECOND_NATIONALITY_NAME
    ];
  }

  ngOnInit() {
    this.route.paramMap
      .pipe(
        filter((params: ParamMap) => typeof params.get('id') === 'string'),
        mergeMap((params: ParamMap) =>
          this.clientService.getClientById(Number(params.get('id')))
        )
      )
      .pipe(takeUntil(this._unsubscribe))
      .subscribe(
        client => {
          this.client = client;
          if (this.client.civilId) {
            this.setInitialGridData(this.client.civilId);
          }
        },
        error => {
          this.errorService.handleError(error, this.route.data);
        }
      );
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (this.inputClient) {
      this.client = this.inputClient;
      if (this.client.civilId) {
        this.setInitialGridData(this.client.civilId);
      }
    }
  }
  setInitialGridData(civilId: string) {
    this.requesterName = null;
    this.requesterName = '';
    this.personDataListData = [];
    this.identityReportsService
      .getPersonDataReportList(civilId)
      .pipe(takeUntil(this._unsubscribe))
      .subscribe(personDataListData => {
        this.personDataListData = personDataListData;
        if (personDataListData.length > 0) {
          this.isReport = true;
          this.isListData = true;

          for (const listData of personDataListData) {
            const time = new Date(listData.time);
            listData.time = time;
          }
          const reportId = this.personDataListData[0].id;
          this.isListData = true;
          this.getReport(reportId);
        } else {
          this.isListData = false;
          this.isReport = false;
        }

      });
  }
  getReport(reportId) {
    this.identityReportsService.getPersonDataReportById(this.client.civilId, reportId)
      .pipe(takeUntil(this._unsubscribe))
      .subscribe((personDataReport: IdentityReport<any>) => {
        this.personDataData = personDataReport;
        this.personDataReportData = personDataReport.data;
        this.requestType = personDataReport.type.replace('_', '-');
        this.requestTime = new Date(personDataReport.time);
        this.requesterName = personDataReport.requesterName;
        this.requestStatus = personDataReport.status;

        if (this.personDataReportData) {
          this.personDataConfig = {
            reportData: this.personDataReportData,
            client: this.client,
            clientMapper: this.CLIENT_PERSONAL_INFO_MAPPER,
            criticalProperties: this.criticalProperties,
            notUpdatableProperties: this.notUpdatableProperties,
            processReportData: (reportData) => {
              return this.identityReportsService.parsePersonDataDataForUpdate(reportData);
            },
          };
        }
      });
  }

  getNewPersonDataReport() {
    this.translate
      .get([
        'personData.getNewPersonDataMessage',
        'global.confirm',
        'global.cancel',
        'personData.getNewPersonDataReport',
      ])
      .pipe(takeUntil(this._unsubscribe))
      .subscribe(translation => {
        this.dialogConfig = {
          message: translation['personData.getNewPersonDataMessage'],
          disableClose: false,
          viewContainerRef: this._viewContainerRef,
          title: translation['personData.getNewPersonDataReport'],
          cancelButton: translation['global.cancel'],
          acceptButton: translation['global.confirm'],
        };

        this.dialogService
          .openConfirm(this.dialogConfig)
          .afterClosed()
          .pipe(takeUntil(this._unsubscribe))
          .subscribe((accept: boolean) => {
            if (accept) {
              this.identityReportsService
                .getPersonDataReport(this.client.civilId)
                .pipe(takeUntil(this._unsubscribe))
                .subscribe(newPersonDataReport => {
                  this.setInitialGridData(this.client.civilId.toString());
                });
            }
          });
      });
  }

  personDataDataChanged(event) {
    const reportId = event.value.id;
    this.getReport(reportId);
  }

  ngOnDestroy() {
    this._unsubscribe.next();
    this._unsubscribe.complete();
  }
}
