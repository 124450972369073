import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {IdentityReport, ListElement, MetaStatusCode} from '@core/types/identity-reports';

@Component({
  selector: 'itfg-reports-info-panel',
  templateUrl: './reports-info-panel.component.html',
  styleUrl: './reports-info-panel.component.scss'
})

export class ReportsInfoPanelComponent implements OnInit{
  @Input() listData: ListElement[];
  @Input() reportData: IdentityReport<any>;
  @Input() metaStatusCode: MetaStatusCode | string;
  @Input() reportTime: Date;
  @Input() requesterName: string;
  @Input() requestTime: Date;

  @Output() dataChanged = new EventEmitter<any>();


  public currentDate: Date;
  readonly twoMontsMiliseconds: number;

  constructor() {
    this.currentDate = new Date();
    this.twoMontsMiliseconds = 1000 * 60 * 60 * 24 * 30 * 2;
  }

  ngOnInit() {
  }
  compareItemsById(firstObj, secondObj) {
    if (firstObj && secondObj) {
      return firstObj.id === secondObj.id;
    } else {
      return false;
    }
  }
  listDataChanged(event) {
    this.dataChanged.emit(event);
  }
}
