<mat-card appearance="outlined" [@cardState]>
  <mat-card-header flex layout-align="space-between center">
    <mat-card-title [translate]="'global.columnsSettings'" class="padding-left-0">Columns settings</mat-card-title>
    <div class="padding-right-7px">
      <button mat-icon-button (click)="onNoClick()">
        <mat-icon class="icon-clear">clear</mat-icon>
      </button>
    </div>
  </mat-card-header>
  <mat-card-content>
    <div cdkDropList class="example-list" (cdkDropListDropped)="onDrag($event)">
      <div class="example-box" flex layout="row" layout-align="space-between center"
           *ngFor="let column of columnList; let columnIndex = index"
           cdkDrag [cdkDragDisabled]="disableDrag" (click)="onRowClick(columnIndex)">
        <div flex layout="row" layout-align="space-between center">
          <mat-icon>reorder</mat-icon>
          <div flex class="row-inset-padding">{{column.config.label}}</div>
        </div>
        <mat-slide-toggle [checked]="column.status === columnStatusList.ACTIVE" (click)="$event.preventDefault()"
                          (mousedown)="onMouseDown()"
                          (mouseup)="onMouseUp()"
                          (change)="onToggleChange(columnIndex, $event)"></mat-slide-toggle>
      </div>
    </div>
    <div>
      <div class="column-buttons">

        <button type="button" mat-raised-button class="column-action-item" (click)="resetColumns()">
          <span [translate]="'global.resetSettings'">Reset settings</span>
        </button>
      </div>
    </div>
  </mat-card-content>
</mat-card>
