<div>
  <itfg-data-table
    *ngIf="tableData"
    [data]="tableData"
    [columns]="columns"
  >
    <ng-template itfgDataTableTemplate="key" let-value="value">
      <span>{{ 'ccrRequests.' + value | translate }}</span>
    </ng-template>
    <ng-template itfgDataTableTemplate="value.banks.value" let-value="value">
      <span>{{ value || 'N/A' }}</span>
    </ng-template>
    <ng-template itfgDataTableTemplate="value.banks.entityCount" let-value="value">
      <span>{{ value || 'N/A' }}</span>
    </ng-template>
    <ng-template itfgDataTableTemplate="value.nonBanks.value" let-value="value">
      <span>{{ value || 'N/A' }}</span>
    </ng-template>
    <ng-template itfgDataTableTemplate="value.nonBanks.entityCount" let-value="value">
      <span>{{ value || 'N/A' }}</span>
    </ng-template>
  </itfg-data-table>
</div>
