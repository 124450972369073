import { HttpClient } from '@angular/common/http';
import { APP_CONFIG } from '../../config/app-config';
import { RequestService } from './request.service';
import { Injectable } from '@angular/core';
import { LoadingService } from './loading.service';

@Injectable()
export class IdentityReportsRequestService extends RequestService {
  constructor(http: HttpClient, loading: LoadingService) {
    super(http, loading);
    this.apIUrl = APP_CONFIG.CONNECTION.API.IDENTITY_REPORTS;
  }
}
