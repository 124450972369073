<div class="dialog-container">
  <div flex layout-align="space-between center" layout="row">
    <div *ngIf="isNewItem" class="modal-dialog-title" [translate]="'clients.newBlacklist'" flex>Add in blacklist</div>
    <div *ngIf="!isNewItem" class="modal-dialog-title" [translate]="'clients.editBlacklist'" flex>Edit in blacklist</div>
    <button tabindex="-1" mat-icon-button (click)="onNoClick()">
      <mat-icon>clear</mat-icon>
    </button>
  </div>
  <hr>
  <form [itfgPermissions]="'BLACKLIST_WRITE'" layout="column" novalidate [formGroup]="blacklistEditForm" (ngSubmit)="onSubmit($event);" class="form-position-fixing">
    <div flex="100" layout="row" layout-wrap layout-align="center center">
      <div flex="100" layout="row" layout-wrap layout-align="space-around center">
        <mat-form-field flex class="edit-blacklist-aditional-padding">
          <mat-select *ngIf="!isPreset" required matInput formControlName="blacklistType" (selectionChange)="selectedValueChanged()" placeholder="{{ 'clients.chooseType' | translate }}">
            <mat-option [disabled]="['BLACKLIST_WRITE'] | isUnauthorized | async" *ngFor="let blacklistType of blacklistTypes" [value]="blacklistType.id">{{ blacklistType.name }}</mat-option>
          </mat-select>
          <input *ngIf="isPreset" readonly matInput value="{{ blacklistTypes[blacklistEditForm?.get('blacklistType')?.value - 1]?.name }}" formControlName="blacklistType">
        </mat-form-field>
        <!-- VALUE -->
        <!-- <mat-form-field *ngIf="!isPreset" flex class="edit-blacklist-aditional-padding">
          <input type="text" disabled="!bulkAdd" required="!bulkAdd" matInput formControlName="value" placeholder="{{ gridSecondColumnTranslations[selectedBlacklistType] | translate }}">
      <mat-label>{{ gridSecondColumnTranslations[selectedBlacklistType] | translate }}</mat-label>
          <mat-error *ngIf="blacklistEditForm.get('value').hasError('onlyNumbers') || blacklistEditForm.get('value').hasError('maxlength') || blacklistEditForm.get('value').hasError('incorrectEgnFormat')"
            [translate]="'clients.invalidCivilId'">Invalid civil id</mat-error>
          <mat-error *ngIf="blacklistEditForm.get('value').hasError('incorrectEik')" [translate]="'clients.invalidemployerNumber'">Invalid employe number</mat-error>
          <mat-error *ngIf="blacklistEditForm.get('value').hasError('pattern')" [translate]="'forms.bulgarianPhoneCode'">Missing 359</mat-error>
          <mat-error *ngIf="blacklistEditForm.get('value').hasError('minlength')" [translate]="'clients.phoneNumberMinLength'">Min length 8 symbols</mat-error>
          <mat-error *ngIf="blacklistEditForm.get('value').hasError('required')" [translate]="'forms.required'">required</mat-error>
        </mat-form-field>
        <mat-form-field *ngIf="isPreset" flex class="edit-blacklist-aditional-padding">
          <input readonly type="text" required matInput formControlName="value" placeholder="{{ gridSecondColumnTranslations[selectedBlacklistType] | translate }}">
      <mat-label>{{ gridSecondColumnTranslations[selectedBlacklistType] | translate }}</mat-label>
          <mat-error *ngIf="blacklistEditForm.get('value').hasError('onlyNumbers') || blacklistEditForm.get('value').hasError('maxlength') || blacklistEditForm.get('value').hasError('incorrectEgnFormat')"
            [translate]="'clients.invalidCivilId'">Invalid civil id</mat-error>
          <mat-error *ngIf="blacklistEditForm.get('value').hasError('incorrectEik')" [translate]="'clients.invalidemployerNumber'">Invalid employe number</mat-error>
          <mat-error *ngIf="blacklistEditForm.get('value').hasError('pattern')" [translate]="'forms.bulgarianPhoneCode'">Missing 359</mat-error>
          <mat-error *ngIf="blacklistEditForm.get('value').hasError('minlength')" [translate]="'clients.phoneNumberMinLength'">Min length 8 symbols</mat-error>
          <mat-error *ngIf="blacklistEditForm.get('value').hasError('required')" [translate]="'forms.required'">required</mat-error>
        </mat-form-field> -->
        <mat-form-field flex class="edit-blacklist-aditional-padding" layout="row">
          <input matInput readonly="true" formControlName="expire" [matDatepicker]="picker" [min]="currentDate" placeholder="{{ 'clients.expire' | translate }}">
      <mat-label>{{ 'clients.expire' | translate }}</mat-label>
          <itfg-form-control-clear matSuffix [control]="blacklistEditForm.get('expire')"></itfg-form-control-clear>
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker [touchUi]="isMobile()" #picker></mat-datepicker>
        </mat-form-field>
      </div>
      <div flex="100" layout="row" layout-wrap layout-align="space-between center">
        <mat-form-field flex class="edit-blacklist-aditional-padding">
          <input type="text" matInput formControlName="notes" placeholder="{{ 'clients.notes' | translate }}">
      <mat-label>{{ 'clients.notes' | translate }}</mat-label>
        </mat-form-field>
      </div>
      <div  flex="100" layout="row">
        <mat-form-field flex="100" class="edit-blacklist-aditional-padding">
          <textarea
            [readonly] = "isPreset"
            matInput
            required
            formControlName="value"
            placeholder="{{ gridSecondColumnTranslations[selectedBlacklistType] | translate }}"
            (paste)="bulkAddPaste($event)">
          </textarea>
          <mat-error *ngIf="blacklistFormArr.hasError('required')" [translate]="'forms.required'">required</mat-error>
          <mat-hint [ngStyle]="{'color': '#d32f2f'}" *ngIf="invalidValues && blacklistFormArr.invalid">{{'clients.blacklistImportInvalid' | translate}}{{invalidValues}}</mat-hint>
        </mat-form-field>
      </div>
    </div>
    <div class="dialog-bottom-nav-bar edit-blacklist-aditional-padding" flex="100" layout="row" layout-align="end center">
      <button mat-raised-button type="submit" flex-offset="80" flex="20" class="save-button">
        {{ 'global.save' | translate }}
      </button>
      <button mat-raised-button type="button" *ngIf="blacklistEditData?.id" (click)="deleteFromBlackList()" flex-offset="80" flex="20">
        <span class="mat-button-wrapper">{{ 'global.delete' | translate }}</span>
      </button>
    </div>
  </form>
</div>
