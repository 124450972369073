import {
  Component,
  ViewContainerRef,
  Input,
  SimpleChanges,
  OnInit,
  OnChanges,
  OnDestroy,
} from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { ClientService } from '../../../core/services/client.service';
import { ErrorService } from '../../../core/services/error.service';
import { IdentityReportsService } from '../../../core/services/identity-reports.service';
import { Client } from '../../../core/types/client';
import {
  ClientMapperTypes,
  ClientMaritalStatusMapperTypes,
  ClientMaritalStatusTypes,
  GenderTypes,
  IdentityReport,
  ListElement,
  MetaStatusCode,
  ReportConfig
} from '../../../core/types/identity-reports';
import { filter, mergeMap, takeUntil } from 'rxjs/operators';
import { DialogProviderService } from '@app/core/services/dialog-provider.service';
import { MaritalStatus } from '../../../core/types/marital-status';

@Component({
  selector: 'itfg-marital-status-report',
  templateUrl: './marital-status-report.component.html',
  styleUrls: ['./marital-status-report.component.scss'],
})
export class MaritalStatusReportComponent implements OnInit, OnChanges, OnDestroy {
  @Input() inputClient: Client;

  public maritalStatusData: IdentityReport<any>;
  public maritalStatusReportData: any;
  public maritalStatusListData: ListElement[];
  public requestType: string;
  public requestTime: Date = new Date();
  public requesterName: string;
  public requestStatus = null;
  public isReport = false;
  public isListData = false;
  public dialogConfig: any;
  public client: Client;
  public maritalStatuses: MaritalStatus[];
  public CLIENT_MARITAL_STATUS_MAPPER: { [key: number]: string };
  public CLIENT_PERSONAL_INFO_MAPPER: { [key: string]: string };
  public criticalProperties: any[];
  public notUpdatableProperties: any[];
  public maritalStatusConfig: ReportConfig;

  _unsubscribe: Subject<void> = new Subject<void>();

  public currentDate: Date;
  public readonly twoMontsMiliseconds: number;

  constructor(
    private identityReportsService: IdentityReportsService,
    private route: ActivatedRoute,
    private clientService: ClientService,
    private errorService: ErrorService,
    private translate: TranslateService,
    private dialogService: DialogProviderService,
    private _viewContainerRef: ViewContainerRef
  ) {

    this.CLIENT_MARITAL_STATUS_MAPPER = {
      1: ClientMaritalStatusTypes.SINGLE,
      2: ClientMaritalStatusTypes.MARRIED,
      3: ClientMaritalStatusTypes.WIDOWED,
      4: ClientMaritalStatusTypes.DIVORCED,
      5: ClientMaritalStatusTypes.COHABITING
    };

    this.CLIENT_PERSONAL_INFO_MAPPER = {
      [ClientMapperTypes.FIRST_NAME]: ClientMaritalStatusMapperTypes.FIRST_NAME,
      [ClientMapperTypes.MIDDLE_NAME]: ClientMaritalStatusMapperTypes.MIDDLE_NAME,
      [ClientMapperTypes.LAST_NAME]: ClientMaritalStatusMapperTypes.LAST_NAME,
      [ClientMapperTypes.MARITAL_STATUS_NAME]: ClientMaritalStatusMapperTypes.MARITAL_STATUS_NAME
    };

    this.criticalProperties = [
      ClientMapperTypes.FIRST_NAME,
      ClientMapperTypes.MIDDLE_NAME,
      ClientMapperTypes.LAST_NAME,
      ClientMapperTypes.MARITAL_STATUS_NAME
    ];
  }

  ngOnInit() {
    this.route.paramMap
      .pipe(
        filter((params: ParamMap) => typeof params.get('id') === 'string'),
        mergeMap((params: ParamMap) =>
          this.clientService.getClientById(Number(params.get('id')))
        )
      )
      .pipe(takeUntil(this._unsubscribe))
      .subscribe(
        client => {
          this.client = client;
          if (this.client.civilId) {
            this.setInitialGridData(this.client.civilId);
          }
        },
        error => {
          this.errorService.handleError(error, this.route.data);
        }
      );

    this.getMaritalStatuses();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.inputClient) {
      this.client = this.inputClient;
      if (this.client.civilId) {
        this.setInitialGridData(this.client.civilId);
      }
    }
  }

  setInitialGridData(civilId: string) {
    this.requestTime = null;
    this.requesterName = '';

    this.identityReportsService
      .getMaritalStatusReportList(civilId)
      .pipe(takeUntil(this._unsubscribe))
      .subscribe(maritalStatusListData => {
        this.maritalStatusListData = maritalStatusListData;
        if (maritalStatusListData.length > 0) {
          this.isReport = true;
          this.isListData = true;

          for (const listData of maritalStatusListData) {
            const time = new Date(listData.time);
            listData.time = time;
          }
          const reportId = this.maritalStatusListData[0].id;


          this.isListData = true;
          this.getReport(reportId);
        } else {
          this.isListData = false;
          this.isReport = false;
        }

      });
  }

  getReport(reportId) {
    this.identityReportsService.getMaritalStatusReportById(this.client.civilId, reportId)
      .pipe(takeUntil(this._unsubscribe))
      .subscribe((maritalStatusReport: IdentityReport<any>) => {
        this.maritalStatusData = maritalStatusReport;
        this.maritalStatusReportData = maritalStatusReport.data;
        this.requestType = maritalStatusReport.type.replace('_', '-');
        this.requestTime = new Date(maritalStatusReport.time);
        this.requesterName = maritalStatusReport.requesterName;
        this.requestStatus = maritalStatusReport.status;

        if (this.maritalStatusReportData) {
          if (this.maritalStatusReportData.Gender) {
            this.maritalStatusReportData.Gender = this.parseGenders(this.maritalStatusReportData.Gender);
          }

          this.maritalStatusConfig = {
            reportData: this.maritalStatusReportData,
            client: this.client,
            clientMapper: this.CLIENT_PERSONAL_INFO_MAPPER,
            criticalProperties: this.criticalProperties,
            notUpdatableProperties: this.notUpdatableProperties,
            processReportData: (reportData) => {
              return this.identityReportsService.parseMaritalStatusDataForUpdate(reportData, this.CLIENT_MARITAL_STATUS_MAPPER, this.maritalStatuses);
            },
          };
        }
      });
  }

  getNewMaritalStatusReport() {
    this.translate
      .get([
        'maritalStatus.getNewMaritalStatusWarning',
        'global.confirm',
        'global.cancel',
        'maritalStatus.getNewMaritalStatusReport',
      ])
      .pipe(takeUntil(this._unsubscribe))
      .subscribe(translation => {
        this.dialogConfig = {
          message: translation['maritalStatus.getNewMaritalStatusWarning'],
          disableClose: false,
          viewContainerRef: this._viewContainerRef,
          title: translation['maritalStatus.getNewMaritalStatusReport'],
          cancelButton: translation['global.cancel'],
          acceptButton: translation['global.confirm'],
        };

        this.dialogService
          .openConfirm(this.dialogConfig)
          .afterClosed()
          .pipe(takeUntil(this._unsubscribe))
          .subscribe((accept: boolean) => {
            if (accept) {
              this.identityReportsService
                .getMaritalStatusReport(this.client.civilId)
                .pipe(takeUntil(this._unsubscribe))
                .subscribe(newMaritalStatusReport => {
                  this.setInitialGridData(this.client.civilId.toString());
                });
            }
          });
      });
  }

  maritalStatusDataChanged(event) {
    const reportId = event.value.id;
    this.getReport(reportId);
  }

  parseGenders(genderCode: number) {
    return genderCode === 1 ? GenderTypes.MALE : GenderTypes.FEMALE;
  }

  getMaritalStatuses() {
    return this.clientService.maritalStatusesSubject$
      .pipe(takeUntil(this._unsubscribe))
      .subscribe((maritalStatuses: MaritalStatus[]) => {
        this.maritalStatuses = maritalStatuses;
      });
  }

  ngOnDestroy(): void {
    this._unsubscribe.next();
    this._unsubscribe.complete();
  }
}
