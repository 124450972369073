import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClient } from '@angular/common/http';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import {
  TranslateModule,
  TranslateLoader,
  TranslateService,
} from '@ngx-translate/core';

import { NgModule, LOCALE_ID } from '@angular/core';
import { CommonModule, registerLocaleData } from '@angular/common';
import { BrandService } from './services/brand.service';
import { FormManipulationService } from './services/form-manipulation.service';
import { RequestService } from './services/request.service';
import { ZoneService } from './services/zone.service';
import { AuthInterceptor } from './services/auth.interceptor';
import { SessionService } from './services/session.service';
import { PreserveRouteService } from './services/preserveRoute.service';
import { AuthStorageService } from './services/auth-storage.service';
import { ProductService } from './services/product.service';
import { OfficeService } from './services/office.service';
import { CityService } from './services/city.service';
import { ProductPlanService } from './services/product-plan.service';
import { AdministratorService } from './services/administrator.service';
import { PermissionService } from './services/permission.service';
import { PermissionGroupService } from './services/permission-group.service';
import { TemplatesService } from './services/templates.service';
import { OverduePenaltyService } from './services/overdue-penalty.service';
import { ClientService } from './services/client.service';
import { ErrorInterceptor } from './services/error.interceptor';
import { ErrorDialogComponent } from './components/error-dialog/error-dialog.component';
import { MaterialModule } from '../lib/material.module';
import { BlacklistService } from './services/blacklist.service';
import { CountryService } from './services/country.service';
import { NavigationInfoService } from './services/navigation-info.service';
import { CreditService } from './services/credit.service';
import { NotificationDialogComponent } from './components/notification-dialog/notification-dialog.component';
import { CcrService } from './services/ccr.service';
import { ConversationService } from './services/conversation.service';
import { SmsService } from './services/sms.service';
import { EmailService } from './services/email.service';
import { DateService } from './services/date.service';
import { StorageService } from './services/storage.service';
import { APP_CONFIG } from '../config/app-config';
import { FrameAgreementService } from './services/frame-agreement.service';
import { InstallmentService } from './services/installment.service';
import { DocumentTemplateService } from './services/document-template.service';
import { CreditDocumentService } from './services/credit-document.service';
import { NotificationService } from './services/notification.service';
import { AccountService } from './services/account.service';
import { SearchOptionsResolver } from './resolvers/search-options.resolver';
import { TransactionService } from './services/transaction.service';
import { PaymentMethodService } from './services/payment-method.service';
import { LoaderService } from './services/loader.service';
import { PermissionsService } from './services/permissions.service';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { InterestAccrualService } from './services/interest-accrual.service';
import { ForfeitAccrualService } from './services/forfeit-accrual.service';
import { OverduePenaltyAccrualService } from './services/overdue-penalty-accrual.service';
import { CreditAccrualsService } from './services/credit-accruals.service';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { IbanUtilService } from './services/iban-util.service';
import { PaymentService } from './services/payment.service';
import localeBg from '@angular/common/locales/bg';
import { RefinanceService } from './services/refinance.service';
import { ClaimService } from './services/claims.service';
import { BucketService } from './services/bucket.service';
import { ErrorService } from './services/error.service';
import { NavigationMenuService } from './services/navigation-menu.service';
import { DCAService } from './services/dca.service';
import { ColumnsService } from './services/columns.service';
import { ContactService } from './services/contacts.service';
import { CollectionRequestService } from './services/collection-request.service';
import { CollectionService } from './services/collection.service';
import { DocumentService } from './services/document.service';
import { BottomDrawerService } from './services/bottom-drawer.service';
import { ReminderService } from './services/reminder.service';
import { PaymentPromiseService } from './services/payment-promise.service';
import { TagService } from './services/tag.service';
import { IdentityReportsRequestService } from './services/identity-reports-request.service';
import { DataUpdatePromptComponent } from './components/data-update-prompt/data-update-prompt.component';
import { SseService } from './services/sse.service';
import { ApplicationService } from './services/application.service';
import { PaymentsApiRequestService } from './services/payments.request.service';
import { PaymentsApiService } from './services/payments-api.service';
import { ScoringRequestService } from './services/scoring-request.service';
import { LoyaltyPointsService } from './services/loyalty-points.service';
import { PaymentAgreementService } from './services/payment-agreement.service';
import { VerificationService } from './services/verification.service';
import { FaceRecognitionService } from './services/face-recongition.service';
import { FaceRecognitionRequestService } from './services/face-recognition-request.service';
import { WarningNotificationPrompt } from './components/warning-notification-prompt/warning-notification-prompt.component';
import { TopicsService } from './services/topics.service';
import { ClientCommunicationRequestService } from './services/client-communication-request.service';
import { P2pRequestService } from './services/p2p-request.service';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { ProgressBarDialogComponent } from './components/progress-bar-dialog/progress-bar-dialog.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { PartnersService } from './services/partners.service';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { LoadingService } from './services/loading.service';
import { DialogProviderService } from './services/dialog-provider.service';
import { PromptDialogComponent } from './components/prompt-dialog/prompt-dialog.component';
import { FormsModule } from '@angular/forms';
import { AlertDialogComponent } from './components/alert-dialog/alert-dialog.component';
import { InfoDialogComponent } from './components/info-dialog-component/info-dialog.component';
import { ProgressSpinnerComponent } from './components/progress-spinner/progress-spinner.component';
import { CallService } from './services/call.service';
import { PbxNotificationComponent } from '@app/main/main-layout/right-drawer/event-log/pbx/pbx-notification-component/pbx-notification.component';

registerLocaleData(localeBg);

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    CommonModule,
    RouterModule,
    MatExpansionModule,
    MatProgressSpinnerModule,
    HttpClientModule,
    MaterialModule,
    MatIconModule,
    MatProgressBarModule,
    MatTableModule,
    FormsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  declarations: [
    ErrorDialogComponent,
    NotificationDialogComponent,
    ConfirmDialogComponent,
    PromptDialogComponent,
    ProgressBarDialogComponent,
    DataUpdatePromptComponent,
    WarningNotificationPrompt,
    AlertDialogComponent,
    InfoDialogComponent,
    PbxNotificationComponent,
    ProgressSpinnerComponent,
  ],
  providers: [
    AccountService,
    AdministratorService,
    PartnersService,
    AuthStorageService,
    BlacklistService,
    BrandService,
    CcrService,
    CityService,
    ClientService,
    ConversationService,
    CountryService,
    CreditService,
    CreditAccrualsService,
    CreditDocumentService,
    DateService,
    DocumentService,
    DocumentTemplateService,
    EmailService,
    FormManipulationService,
    FrameAgreementService,
    InstallmentService,
    NavigationInfoService,
    NotificationService,
    OfficeService,
    OverduePenaltyService,
    PaymentMethodService,
    PaymentService,
    PaymentsApiService,
    PermissionGroupService,
    PermissionService,
    PermissionsService,
    PreserveRouteService,
    ProductPlanService,
    ProductService,
    RequestService,
    CollectionRequestService,
    PaymentsApiRequestService,
    SessionService,
    SmsService,
    TemplatesService,
    ZoneService,
    TransactionService,
    PaymentsApiRequestService,
    LoaderService,
    LoadingService,
    InterestAccrualService,
    ForfeitAccrualService,
    OverduePenaltyAccrualService,
    LoyaltyPointsService,
    IbanUtilService,
    RefinanceService,
    ClaimService,
    BucketService,
    DCAService,
    ErrorService,
    NavigationMenuService,
    CollectionService,
    BottomDrawerService,
    ReminderService,
    PaymentPromiseService,
    TagService,
    IdentityReportsRequestService,
    SseService,
    ApplicationService,
    ScoringRequestService,
    PaymentAgreementService,
    VerificationService,
    FaceRecognitionService,
    FaceRecognitionRequestService,
    TopicsService,
    ClientCommunicationRequestService,
    P2pRequestService,
    DialogProviderService,
    CallService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
    {
      provide: LOCALE_ID,
      deps: [TranslateService],
      useFactory: (translateService: TranslateService) =>
        translateService.currentLang,
    },
    {
      provide: MAT_DATE_LOCALE,
      useValue: 'bg',
    },
    // Resolvers
    SearchOptionsResolver,
    Title,
    ColumnsService,
    ContactService,
  ],
  exports: [HttpClientModule],
})
export class CoreModule {
  constructor(translate: TranslateService) {
    // this language will be used as a fallback when a translation isn't found in the current language
    translate.setDefaultLang('bg');

    // the lang to use, if the lang isn't available, it will use the current loader to get them
    translate.use('bg');

    const storage = new StorageService(APP_CONFIG.AUTH.STORAGE_TYPE);
    const storedLanguage = storage.getItem('translationLanguage');
    if (storedLanguage) {
      const userMedia = navigator.userAgent.toLowerCase();
      if (userMedia.indexOf('edge') !== -1) {
        setTimeout(() => {
          translate.use(storedLanguage);
        }, 1000);
      } else {
        translate.use(storedLanguage);
      }
    }
  }
}
