import { Component, OnDestroy, OnInit } from '@angular/core';
import { Call } from '@app/core/types/call';
import { Credit } from '@app/core/types/credit';
import { Subject, takeUntil } from 'rxjs';
import { EventLogStateService } from '../event-log-state.service';
import { NotesState } from '../../shared/state.interfaces';

@Component({
  selector: 'itfg-event-log-notes',
  templateUrl: './event-log-notes.component.html',
  styleUrls: ['./event-log-notes.component.scss'],
})
export class EventLogNotesComponent implements OnInit, OnDestroy {
  openedCredit: Credit;
  selectedCredit: Credit;
  call: Call;

  private _unsubscribe = new Subject<void>();

  constructor(private eventLogStateService: EventLogStateService) {}

  ngOnInit() {
    this.eventLogStateService
      .getNotesState()
      .pipe(takeUntil(this._unsubscribe))
      .subscribe((state: NotesState) => {
        const { openedCredit, selectedCredit, call } = state;

        this.openedCredit = openedCredit;
        this.selectedCredit = selectedCredit;
        this.call = call;
      });
  }

  ngOnDestroy() {
    this._unsubscribe.next();
    this._unsubscribe.complete();
  }
}
