import { tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpInterceptor } from '@angular/common/http';
import { HttpRequest, HttpHandler } from '@angular/common/http';
import { APP_CONFIG } from '../../config/app-config';
import { AuthStorageService } from './auth-storage.service';
import { HttpErrorResponse } from '@angular/common/http';
import { SessionService } from './session.service';
import { PreserveRouteService } from './preserveRoute.service';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { NotificationService } from './notification.service';

export const bearerAuthApiURLs = [
  APP_CONFIG.CONNECTION.API.COLLECTION,
  APP_CONFIG.CONNECTION.API.PAYMENTS,
  APP_CONFIG.CONNECTION.API.IDENTITY_REPORTS,
  APP_CONFIG.CONNECTION.API.SCORING,
  APP_CONFIG.CONNECTION.API.FACE_RECOGNITION,
  APP_CONFIG.CONNECTION.API.CLIENT_COMMUNICATION,
  APP_CONFIG.CONNECTION.API.P2P
]

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  storage: AuthStorageService;
  session: SessionService;
  preserveService: PreserveRouteService;
  notification: NotificationService;
  router: Router;
  location: Location;

  constructor(
    storage: AuthStorageService,
    router: Router,
    preserveService: PreserveRouteService,
    session: SessionService,
    notification: NotificationService,
    location: Location
  ) {
    this.storage = storage;
    this.session = session;
    this.router = router;
    this.preserveService = preserveService;
    this.notification = notification;
    this.location = location;
  }

  intercept(request: HttpRequest<any>, next: HttpHandler) {
    const authToken =
      this.storage.getItem(APP_CONFIG.AUTH.SESSION_TOKEN_NAME) || '';

    if (
      this.location.path() &&
      this.location.path() !== '/auth/login' &&
      this.location.path() !== '/'
    ) {
      this.session.setLastVisitedRoute(this.location.path());
    }

    let authRequest = request;

    if (request.url.startsWith(APP_CONFIG.CONNECTION.API.ADMIN)) {
      authRequest = request.clone({
        headers: request.headers.set(
          APP_CONFIG.AUTH.SESSION_TOKEN_NAME,
          authToken
        ),
      });
    }
    
    if (bearerAuthApiURLs.some(url => request.url.startsWith(url)) ) {
      authRequest = request.clone({
        headers: request.headers.set('Authorization', 'Bearer ' + authToken),
      });
    }

    return next.handle(authRequest).pipe(
      tap({
        error: (error: HttpErrorResponse) => {
          if (error.status === 401) {
            if (
              !this.preserveService.lastVisitedRoute &&
              this.location.path()
            ) {
              // this.preserveService.lastVisitedRoute = this.router.url;
              if (
                this.location.path() &&
                this.location.path() !== '/auth/login' &&
                this.location.path() !== '/'
              ) {
                this.session.setLastVisitedRoute(this.location.path());
              }
            }

            this.storage.removeItem(APP_CONFIG.AUTH.SESSION_TOKEN_NAME);
            this.router.navigate(['auth', 'login']);
          }

          if (error.status === 400 && error.error?.message === "UnauthorizedException" || error.error?.message === "Unauthorized") {
            if (
              !this.preserveService.lastVisitedRoute &&
              this.location.path()
            ) {
              if (
                this.location.path() &&
                this.location.path() !== '/auth/login' &&
                this.location.path() !== '/'
              ) {
                this.session.setLastVisitedRoute(this.location.path());
              }
            }
            this.notification.showLocalizedErrorMessage({
              notificationText: 'error.invalidSessionErrorMessage',
            });
            this.storage.removeItem(APP_CONFIG.AUTH.SESSION_TOKEN_NAME);
            this.router.navigate(['auth', 'login']);
          }
        },
      })
    );
  }
}
