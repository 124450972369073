import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RequestService } from './request.service';
import { EntityHistoryFilter } from '../types/entity-history-filter';
import { EntityHistoryService, EntityHistory } from '../types/entities';
import { Product } from '../types/product';
import { Page } from '../types/page';
import { P2pRequestService } from './p2p-request.service';

const productPeriodsTranslationKeyMap = {
  DAY: 'global.day',
  WEEK: 'global.week',
  MONTH: 'global.month',
};

@Injectable()
export class ProductService implements EntityHistoryService {
  request: RequestService;
  p2pRequest: P2pRequestService
  constructor(request: RequestService, p2pRequest: P2pRequestService) {
    this.request = request;
    this.p2pRequest = p2pRequest
  }

  getProductPeriod(): Observable<any> {
    return this.request.get(['product-periods']).pipe(
      map(productPeriods => {
        productPeriods.forEach((item, index, array) => {
          item.textKey = productPeriodsTranslationKeyMap[item.unit];
        });
        return productPeriods;
      })
    );
  }

  getProductList(options?: any): Observable<Page<Product>> {
    return this.request.get(['products'], { params: options });
  }

  getGcProductList() {
    return this.p2pRequest.get(['gc', 'products']);
  }

  getProductById(id: number | string): Observable<any> {
    return this.request.get(['products', id]);
  }

  deleteProduct(id: number | string): Observable<any> {
    return this.request.delete(['products', id]);
  }

  saveProduct(product): Observable<any> {
    return this.request.post(['products'], {
      body: product,
      responseType: 'text',
    });
  }

  getProductHistory(
    filter: EntityHistoryFilter
  ): Observable<EntityHistory<any>> {
    return this.request.get(['products', 'history'], {
      params: {
        filter: filter.getDTO(),
      },
    });
  }

  getEntityHistory(filter: EntityHistoryFilter) {
    return this.getProductHistory(filter);
  }
}
