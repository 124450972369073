import { Component, Input, EventEmitter, Output, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'itfg-chip-nodes',
  templateUrl: './chip-nodes.component.html',
  styleUrls: ['./chip-nodes.component.scss'],
})
export class ChipNodesComponent implements OnChanges {

  @Input() records: { node: any, count: number }[];
  @Output() redirectToGraphEvent = new EventEmitter<any>();
  @Output() redirectToUserEvent = new EventEmitter<any>();

  public shownNodes = [];
  public hiddenNodes = [];
  public nodesBatchLimit = 20;
  public label = ''

  constructor(
    private translateService: TranslateService,
  ) { }

  ngOnChanges(): void {
    if (this.records) {
      this.label = this.records[0]?.node.labels[0];

      // TODO: get chip width and set limit based on view.
      // limit shown nodes limit based on label
      if (this.label === 'Blacklist') {
        this.nodesBatchLimit = 11;
      }
      if (this.label === 'User') {
        this.nodesBatchLimit = 40;
      }
      this.shownNodes = this.records.slice(0, this.nodesBatchLimit);
      this.hiddenNodes = this.records.slice(this.nodesBatchLimit + 1);
    }
  }

  redirectToGraph(record) {
    this.redirectToGraphEvent.emit({ label: record.node.labels[0], properties: this.getNodeProperties(record) });
  }

  redirectToUser(record) {
    this.redirectToUserEvent.emit(record.node.properties.id);
  }

  getNodeProperties(record) {
    let properties = [];
    for (let prop of Object.entries(record.node.properties)) {
      if (prop[0] !== "color") {
        properties.push(`${prop[0]}: "${prop[1]}"`)
      }
    }
    return properties.join(',')
  }

  showMore() {
    for (let i = 0; i <= this.nodesBatchLimit; i++) {
      const node = this.hiddenNodes.shift()
      if (node) {
        this.shownNodes.push(node)
      }
    }
  }

  getColor(record) {
    const label = record.node.labels[0];
    const alpha = record.count.low * 0.1;

    if (label === 'Blacklist') {
      return `rgba(0, 0, 0, ${alpha + 0.3})`
    }

    if (label === 'User') {
      if (record.count.low === 1) {
        return `rgba(218, 207, 179)`
      }

      if (record.count.low === 2) {
        return `rgb(245, 200, 158)`
      }

      if (record.count.low === 3) {
        return `rgba(255, 172, 28)`
      }

      if (record.count.low > 3) {
        return `rgba(256, 10, 10, 0.78)`
      }
    }

    return `rgba(198, ${40 * alpha}, ${40 * alpha}, ${alpha})`
  }
}
