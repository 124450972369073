<h2 mat-dialog-title>{{data.title}}</h2>
<mat-dialog-content class="mat-typography">
  <p>
    {{data.message}}
  </p>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button
          mat-dialog-close>{{'confirmPopup.agree'|translate}}</button>
</mat-dialog-actions>
