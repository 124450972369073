import { Pipe, PipeTransform } from '@angular/core';
import {VerificationStatusReasons} from '../../../core/verification-status-type';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'verificationStatusReasonToTranslationKey'
})
export class VerificationStatusReasonToTranslationKeyPipe implements PipeTransform {
  static readonly reasonTypesTranslationMap: { [key: string]: string } = {
    [VerificationStatusReasons.NEW_CLIENT]: 'verification.verificationStatusReasonList.newClient',
    [VerificationStatusReasons.UPLOADED]: 'verification.verificationStatusReasonList.uploaded',
    [VerificationStatusReasons.CANCELED]: 'verification.verificationStatusReasonList.canceled',
    [VerificationStatusReasons.RETRY]:
      'verification.verificationStatusReasonList.retry',
    [VerificationStatusReasons.SEVICE_UNAVAILABLE]:
      'verification.verificationStatusReasonList.serviceUnavailable',
    [VerificationStatusReasons.DUPLICATED_ACCOUNT]:
      'verification.verificationStatusReasonList.duplicatedAccount',
    [VerificationStatusReasons.DOCUMENT_NOT_SUPPORTED]:
      'verification.verificationStatusReasonList.documentNotSupported',
    [VerificationStatusReasons.DOCUMENT_BAD_QUALITY]:
      'verification.verificationStatusReasonList.documentBadQuality',
    [VerificationStatusReasons.TECHNICAL_ISSUES]: 'verification.verificationStatusReasonList.technicalIssues',
    [VerificationStatusReasons.NEW_CREDIT]:
      'verification.verificationStatusReasonList.newCredit',
    [VerificationStatusReasons.VERIFICATION_UPDATE]: 'verification.verificationStatusReasonList.verificationUpdate',
    [VerificationStatusReasons.PROCESSING_AUTO]: 'verification.verificationStatusReasonList.processingAuto',
    [VerificationStatusReasons.DOCUMENT_VERIFIED]:
      'verification.verificationStatusReasonList.documentVerified',
    [VerificationStatusReasons.LOW_QUALITY]:
      'verification.verificationStatusReasonList.lowQuality',
    [VerificationStatusReasons.ID_NOT_MATCH]: 'verification.verificationStatusReasonList.idNotMatch',
    [VerificationStatusReasons.DOCUMENT_DAMAGED]: 'verification.verificationStatusReasonList.documentDamaged',
    [VerificationStatusReasons.FRAUD]: 'verification.verificationStatusReasonList.fraud',
    [VerificationStatusReasons.FACE_NOT_MATCH]:
      'verification.verificationStatusReasonList.faceNotMatch',
    [VerificationStatusReasons.FACE_VERIFIED]:
      'verification.verificationStatusReasonList.faceVerified',
    [VerificationStatusReasons.IMAGE_CORRUPTED]:
      'verification.verificationStatusReasonList.imageCorrupted',
    [VerificationStatusReasons.DOCUMENT_CORRUPTED]: 'verification.verificationStatusReasonList.documentCorrupted',
    [VerificationStatusReasons.DOCUMENT_COPY]:
      'verification.verificationStatusReasonList.documentCopy',
    [VerificationStatusReasons.DATA_NOT_MATCH]:
      'verification.verificationStatusReasonList.dataNotMatch',
    [VerificationStatusReasons.DOCUMENT_EXPIRED]:
      'verification.verificationStatusReasonList.documentExpired',
    [VerificationStatusReasons.UNKNOWN]:
      'verification.verificationStatusReasonList.unknown',
    [VerificationStatusReasons.FACE_BACKGROUND]: 'verification.verificationStatusReasonList.faceBackground',
    [VerificationStatusReasons.PROOF_NOT_MATCH]:
      'verification.verificationStatusReasonList.proofNotMatch',
    [VerificationStatusReasons.MISSING_PROOF]:
      'verification.verificationStatusReasonList.missingProof',
    [VerificationStatusReasons.FACE_NOT_VERIFIED]: 'verification.verificationStatusReasonList.faceNotVerified',
    [VerificationStatusReasons.IMAGE_BAD_QUALITY]:
      'verification.verificationStatusReasonList.imageBadQuality',
  };

  constructor(
    private translate: TranslateService,
  ) {

  }

  transform(reason: string | string[]): string {
    if (Array.isArray(reason)) {
      let txt = [];
      for (let r of reason) {
        if (VerificationStatusReasonToTranslationKeyPipe.reasonTypesTranslationMap.hasOwnProperty(r)) {
          txt.push(this.translate.instant(VerificationStatusReasonToTranslationKeyPipe.reasonTypesTranslationMap[r]));
        } else {
          txt.push(r);
        }
      }
      return txt.join(' / ');
    } else {
      if (
        VerificationStatusReasonToTranslationKeyPipe.reasonTypesTranslationMap.hasOwnProperty(
          reason
        )
      ) {
        return VerificationStatusReasonToTranslationKeyPipe.reasonTypesTranslationMap[
          reason
          ];
      } else {
        return reason;
      }
    }
  }
}
