import {
  Component,
  Inject,
  OnInit,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ClientService } from '../../../../core/services/client.service';
import { ClientStatus } from '../../../../core/types/client-status';

@Component({
  selector: 'itfg-status-edit',
  templateUrl: './status-edit.component.html',
  styleUrls: ['./status-edit.component.scss'],
})
export class StatusEditComponent implements OnInit, OnChanges {
  statusEditForm: UntypedFormGroup;
  clientStatuses: ClientStatus[];
  mappedClientStatuses: any[];
  mappedClientArchiveReasons: any[];

  constructor(
    public dialogRef: MatDialogRef<StatusEditComponent>,
    @Inject(MAT_DIALOG_DATA) public statusEditData: any,
    public formBuilder: UntypedFormBuilder,
    public clientService: ClientService
  ) {
    this.statusEditForm = this.createForm();
    this.statusEditForm.controls.reason.disable();
  }

  ngOnInit() {
    this.setInitialData();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.setInitialData();
  }

  setInitialData() {
    this.getClientStatuses();
    this.setData();
  }

  setData() {
    if (this.statusEditData.reason && this.statusEditData.reason.length > 0) {
      this.statusEditForm.patchValue({ name: 3 });
      this.statusEditForm.patchValue({ reason: this.statusEditData.id });
    } else {
      this.statusEditForm.patchValue({ name: this.statusEditData.id });
    }

    this.selectedValueChanged();
  }

  getClientStatuses() {
    this.clientService.getClientStatusList().subscribe(statusList => {
      this.clientStatuses = statusList;
      this.mapDataToStatuses(statusList);
    });
  }

  mapDataToStatuses(statuses) {
    this.mappedClientStatuses = [];
    this.mappedClientArchiveReasons = [];

    for (let i = 0; i < statuses.length; i++) {
      if (
        statuses[i].id.toString() !== '4' &&
        statuses[i].id.toString() !== '5'
      ) {
        this.mappedClientStatuses.push({
          name: statuses[i].name,
          id: statuses[i].id,
        });
      }

      if (statuses[i].reason) {
        this.mappedClientArchiveReasons.push({
          id: statuses[i].id,
          reason: statuses[i].reason,
        });
      }
    }
    console.log(this.mappedClientStatuses, this.mappedClientArchiveReasons);
  }

  selectedValueChanged(event?) {
    if (this.statusEditForm.value.name.toString() === '3') {
      this.statusEditForm.controls.reason.enable();
      this.statusEditForm.controls.reason.setValidators(Validators.required);
    } else {
      this.statusEditForm.controls.reason.disable();
      this.statusEditForm.controls.reason.clearValidators();
      this.statusEditForm.patchValue({ reason: '' });
    }
  }

  mapDataToEmit(formData) {
    let selectedId = '';
    let selectedStatus = {};
    if (formData.value.reason && formData.value.reason.toString().length > 0) {
      selectedId = formData.value.reason;
    } else {
      selectedId = formData.value.name;
    }

    for (let i = 0; i < this.clientStatuses.length; i++) {
      if (this.clientStatuses[i].id.toString() === selectedId.toString()) {
        selectedStatus = this.clientStatuses[i];
      }
    }

    return selectedStatus;
  }

  createForm() {
    return this.formBuilder.group({
      name: ['', [Validators.required]],
      reason: [''],
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onSubmit(event) {
    if (!this.statusEditForm.invalid) {
      this.dialogRef.close(this.mapDataToEmit(this.statusEditForm));
    }
  }
}
