<div *ngIf="conversationType"
     flex
     layout="row">
  <mat-icon [matTooltip]="conversationType | conversationTypesTranslation | translate"
            matTooltipPosition="above"
            class="font-size-21"
            [ngStyle]="{'color': contactTypeIconMap[conversationType.name]?.color }"
            [ngStyle]="{'font-size': 21}">
    {{ contactTypeIconMap[conversationType.name]?.icon }}
  </mat-icon>
  <span *ngIf="contactedPhone">&nbsp;{{contactedPhone}}</span>
  <span *ngIf="!contactedPhone">&nbsp;{{'communication.hiddenNumber' | translate
    }}</span>
</div>
