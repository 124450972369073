import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewContainerRef } from '@angular/core';
import { Client } from '@core/types/client';
import {
  AddressReportData, ClientAddressMapperTypes,
  ClientMapperTypes,
  IdentityReport,
  ListElement,
  ReportConfig, ReportTypes
} from '@core/types/identity-reports';
import { Subject } from 'rxjs';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { ClientService } from '@core/services/client.service';
import { ErrorService } from '@core/services/error.service';
import { IdentityReportsService } from '@core/services/identity-reports.service';
import { TranslateService } from '@ngx-translate/core';
import { DialogProviderService } from '@core/services/dialog-provider.service';
import { filter, mergeMap, takeUntil } from 'rxjs/operators';
import * as moment from 'moment';
import { ChooseDateFromComponent } from '@app/main/identity-reports/address-report/choose-date-from/choose-date-from.component';
import { ItfgDataTableColumn } from '@core/components/data-table/types/data-table.column';
import { DateFormat } from '@core/types/date-format';

@Component({
  selector: 'itfg-address-report',
  templateUrl: './address-report.component.html',
  styleUrls: ['./address-report.component.scss']
})
export class AddressReportComponent implements OnInit, OnChanges, OnDestroy {
  @Input() inputClient: Client;
  @Input() reportType: string;

  public client: Client;
  public addressListData: ListElement[];
  public addressHistory: IdentityReport<AddressReportData>[];
  public isReport = false;
  public isListData = false;
  public addressData: IdentityReport<any>;
  public addressReportData: AddressReportData;
  public requestType: string;
  public requestTime: Date = new Date();
  public requesterName: string;
  public requestStatus = null;
  public addressConfig: ReportConfig;
  public CLIENT_PERSONAL_INFO_MAPPER: { [key: string]: string };
  public criticalProperties: any[];
  public notUpdatableProperties: any[];
  public dialogConfig: any;
  reportTime: Date;

  dateFormat = DateFormat.DATE;
  public columns: ItfgDataTableColumn[];
  ReportTypes: typeof ReportTypes = ReportTypes;
  _unsubscribe: Subject<void> = new Subject<void>();
  constructor(private route: ActivatedRoute,
              private clientService: ClientService,
              private errorService: ErrorService,
              private identityReportsService: IdentityReportsService,
              private translate: TranslateService,
              private dialogService: DialogProviderService,
              private _viewContainerRef: ViewContainerRef) {

  }

  ngOnInit() {
    this.setupColumnConfig();
    this.route.paramMap
      .pipe(
        filter((params: ParamMap) => typeof params.get('id') === 'string'),
        mergeMap((params: ParamMap) =>
          this.clientService.getClientById(Number(params.get('id')))
        )
      )
      .pipe(takeUntil(this._unsubscribe))
      .subscribe(
        client => {
          this.client = client;
          if (this.client.civilId) {
            this.setInitialGridData(this.client.civilId, this.reportType);
          }
        },
        error => {
          this.errorService.handleError(error, this.route.data);
        }
      );
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (this.inputClient) {
      this.addressReportData = null;
      this.client = this.inputClient;
      if (this.client.civilId) {
        this.setInitialGridData(this.client.civilId, this.reportType);
      }
    }
  }
  setInitialGridData(civilId: string, reportType) {
    this.requesterName = null;
    this.requesterName = '';
    this.reportTime = null;
    if (this.reportType === ReportTypes.PERMANENT_ADDRESS) {
      this.CLIENT_PERSONAL_INFO_MAPPER = {
        [ClientMapperTypes.ID_CARD_ADDRESS_CITY_NAME]: ClientAddressMapperTypes.SETTALMENT_NAME,
        [ClientMapperTypes.ID_CARD_ADDRESS_ADDRESS]: ClientAddressMapperTypes.ADDRESS,
      };

      this.criticalProperties = [
        ClientMapperTypes.ID_CARD_ADDRESS_CITY_NAME,
        ClientMapperTypes.ID_CARD_ADDRESS_ADDRESS
      ];

    } else {
      this.CLIENT_PERSONAL_INFO_MAPPER = {
        [ClientMapperTypes.CURRENT_ADDRESS_CITY_NAME]: ClientAddressMapperTypes.SETTALMENT_NAME,
        [ClientMapperTypes.CURRENT_ADDRESS_ADDRESS]: ClientAddressMapperTypes.ADDRESS,
      };
      this.criticalProperties = [
        ClientMapperTypes.CURRENT_ADDRESS_CITY_NAME,
        ClientMapperTypes.CURRENT_ADDRESS_ADDRESS
      ];
    }
    this.identityReportsService.getAddressReportHistory(civilId, reportType)
      .pipe(takeUntil(this._unsubscribe))
      .subscribe(addressHistory => {
        if (addressHistory.length > 0) {
          this.addressHistory = addressHistory
            .filter((a, i) => addressHistory.findIndex((report) => a.data.FromDate === report.data.FromDate) === i)
            .sort((a, b) => moment(b.data.FromDate).diff(moment(a.data.FromDate)));
        } else {
          this.addressHistory = [];
        }
      });

    this.identityReportsService
      .getAddressReportList(civilId, reportType)
      .pipe(takeUntil(this._unsubscribe))
      .subscribe(addressListData => {
        this.addressListData = addressListData;
        if (addressListData.length > 0) {
          this.isReport = true;
          this.isListData = true;

          for (const listData of addressListData) {
            const time = new Date(listData.time);
            listData.time = time;
          }
          const reportId = this.addressListData[0].id;

          this.isListData = true;
          this.getReport(reportId, this.reportType);
        } else {
          this.isListData = false;
          this.isReport = false;
        }

      });
  }
  setupColumnConfig() {
    this.translate
      .get([
        'global.numberSgn',
        'identityReports.FromDate',
        'identityReports.DistrictName',
        'identityReports.MunicipalityName',
        'identityReports.SettlementName',
        'identityReports.LocationName',
        'identityReports.BuildingNumber',
        'identityReports.Entrance',
        'identityReports.Floor',
        'identityReports.Apartment',
        'identityReports.SearchDate',
      ])
      .pipe(takeUntil(this._unsubscribe))
      .subscribe(translation => {
        this.columns = [
          {
            name: 'data.FromDate',
            label: translation['identityReports.FromDate'],
            width: 150,
          },
          {
            name: 'data.SearchDate',
            label: translation['identityReports.SearchDate'],
          },
          {
            name: 'data.DistrictName',
            label: translation['identityReports.DistrictName'],

          },
          {
            name: 'data.MunicipalityName',
            label: translation['identityReports.MunicipalityName'],
          },
          {
            name: 'data.SettlementName',
            label: translation['identityReports.SettlementName'],
          },
          {
            name: 'data.CityArea',
            label: translation['identityReports.CityArea'],
          },
          {
            name: 'data.LocationName',
            label: translation['identityReports.LocationName'],
          },
          {
            name: 'data.BuildingNumber',
            label: translation['identityReports.BuildingNumber'],
          },
          {
            name: 'data.Entrance',
            label: translation['identityReports.Entrance'],
          },
          {
            name: 'data.Floor',
            label: translation['identityReports.Floor'],
          },
          {
            name: 'data.Apartment',
            label: translation['identityReports.Apartment'],
          }
        ];
      });
  }
  getReport(reportId, reportType) {
    this.identityReportsService.getAddressReportById(this.client.civilId, reportId, reportType)
      .pipe(takeUntil(this._unsubscribe))
      .subscribe((addressReport: IdentityReport<any>) => {
        this.addressData = addressReport;
        this.addressReportData = addressReport.data;
        this.requestType = addressReport.type.replace('_', '-');
        this.requestTime = new Date(addressReport.time);
        this.requesterName = addressReport.requesterName;
        this.requestStatus = addressReport.status;
        this.reportTime = new Date(this.addressReportData?.SearchDate || null);

        if (this.addressReportData) {

          let settlementNameParts = [];
          let additionalLocationName = '';

          if (this.addressReportData && this.addressReportData.SettlementName) {

            settlementNameParts = this.addressReportData.SettlementName.split(',');

            if (settlementNameParts.length > 1) {
              this.addressReportData.SettlementName = settlementNameParts[0];
              additionalLocationName = settlementNameParts.pop();
            }
          }
          let address = this.addressReportData.LocationName;

          if (additionalLocationName !== '') {
            address = additionalLocationName + ', ' + address;
          }

          if (this.addressReportData.BuildingNumber) {
            const buildingNumber = ', No ' + this.addressReportData.BuildingNumber;
            address += buildingNumber;
          }
          if (this.addressReportData.Entrance) {
            const entrance = ', вх. ' + this.addressReportData.Entrance;
            address += entrance;
          }
          if (this.addressReportData.Floor) {
            const floor = ', ет. ' + this.addressReportData.Floor;
            address += floor;
          }
          if (this.addressReportData.Apartment) {
            const apartment = ', ап. ' + this.addressReportData.Apartment;
            address += apartment;
          }
          this.addressReportData.Address = address;

          this.addressConfig = {
            reportData: this.addressReportData,
            client: this.client,
            clientMapper: this.CLIENT_PERSONAL_INFO_MAPPER,
            criticalProperties: this.criticalProperties,
            notUpdatableProperties: this.notUpdatableProperties,
            processReportData: (reportData) => {
              return reportData;
            },

          };
        }
      });
  }

  getNewAddressReport(reportType) {
    this.dialogService
      .open(ChooseDateFromComponent, {
        minWidth: '250px',
        restoreFocus: false,
        autoFocus: false,
        data: {
          reportType: this.reportType,
        },
      })
      .afterClosed()
      .pipe(
        takeUntil(this._unsubscribe),
        filter(res => !!res),
        mergeMap((response: { dateFrom?: string }) => {
          const fromDate = response.dateFrom ?
            moment(response.dateFrom).format('YYYY-MM-DD') :
            moment().subtract(1, 'years').format('YYYY-MM-DD');
          return this.identityReportsService.getAddressReport(this.client.civilId, this.reportType, fromDate);
        })
      )
      .subscribe(res => {
        this.setInitialGridData(this.client.civilId, this.reportType);
      });
  }
  updateAddressHistory(reportType) {
    this.translate
      .get(
        [
          'address.updateHistoryMessage.' + reportType,
          'address.addressStatusReport.' + reportType,
          'global.update',
          'global.cancel',
          'global.confirm',
        ]
      )
      .pipe(takeUntil(this._unsubscribe))
      .subscribe(translation => {
        const dialogConfig = {
          message: translation['address.updateHistoryMessage.' + reportType],
          disableClose: false,
          title: translation['address.addressStatusReport.' + reportType],
          cancelButton: translation['global.cancel'],
          acceptButton: translation['global.confirm'],
        };

        this.dialogService
          .openConfirm(dialogConfig)
          .afterClosed()
          .pipe(
            takeUntil(this._unsubscribe),
            filter((accept: boolean) => accept === true),
            mergeMap(() => this.identityReportsService.updateAddressReportHistory(this.client.civilId, this.reportType))
          )
          .subscribe(res => {
            this.setInitialGridData(this.client.civilId, this.reportType);
          });
      });
  }

  addressDataChanged(event) {
    const reportId = event.value.id;
    this.getReport(reportId, this.reportType);
  }

  ngOnDestroy() {
    this._unsubscribe.next();
    this._unsubscribe.complete();
  }
}
