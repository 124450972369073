import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Edge, Node } from '@swimlane/ngx-graph';
import { ItfgGraphLayout } from '../layouts/ItfgGraphLayout';
import { mockGraphData } from '../types/GraphRecords.type';

@Component({
  selector: 'itfg-graph',
  templateUrl: './graph.component.html',
  styleUrls: ['./graph.component.scss'],
})
export class GraphComponent {
  @Input() nodes: Node[] = mockGraphData.nodes;
  @Input() links: Edge[] = mockGraphData.links;
  @Input() clientId: string;
  public view = [window.screen.width + window.screen.width, window.screen.height]
  @Output() filterGraph = new EventEmitter<any>();
  public layout = new ItfgGraphLayout()
  constructor() {

  }

  emitFilterGraph(node: Node) {
    const properties = this.getNodeProperties(node)
    this.filterGraph.emit({ label: node.label, properties });
  }

  getNodeProperties(node: Node) {
    let properties = [];
    for (let prop of Object.entries(node.data)) {
      if (prop[0] !== "color") {
        properties.push(`${prop[0]}: "${prop[1]}"`)
      }
    }

    return properties
  }

  getGenericNodeDataPreview(node) {
    const preview = [];
    for (let key of Object.keys(node.data)) {
      if (key === 'color') {
        continue;
      }
      preview.push(`${key}: ${node.data[key]}`);
    }
    return preview.join('\n');
  }
}
