import {
  Directive,
  Input,
  AfterContentInit,
  ElementRef,
  OnDestroy,
} from '@angular/core';

@Directive({
  selector: '[itfgStickyElement]',
})
export class StickyElementDirective implements AfterContentInit, OnDestroy {
  @Input() stickyElementPageSelector: string;

  private pageElement: Element;
  private onPageScrollCallback;

  constructor(private elementRef: ElementRef) {}

  ngAfterContentInit() {
    const interval = setInterval(() => {
      this.pageElement = document.querySelector(this.stickyElementPageSelector);
      if (this.pageElement !== null) {
        this.onPageScrollCallback = this.onPageScroll.bind(this);
        this.pageElement.addEventListener('scroll', this.onPageScrollCallback);
        clearInterval(interval);
      }
    }, 200);
  }

  ngOnDestroy() {
    if (this.onPageScrollCallback) {
      this.pageElement.removeEventListener('scroll', this.onPageScrollCallback);
    }
  }

  onPageScroll(event) {
    this.elementRef.nativeElement.style.transform = `translateY(${event.target.scrollTop}px)`;
  }
}
