import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { SessionService } from '../core/services/session.service';

@Injectable()
export class AuthGuardService  {
  private router: Router;
  private session: SessionService;

  constructor(router: Router, session: SessionService) {
    this.router = router;
    this.session = session;
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    const isAuthenticated = this.session.isAuthenticated();
    const roles = this.session.currentlyLoggedOperator.roles || [];
    const preauthorizationStep = roles.length > 0 && roles.every((role: string) => role === 'PRE_AUTH_ADMIN');

    if (preauthorizationStep) {
      if (/auth\/2fa/.test(state.url)) {
        return true
      } else {
        this.router.navigate(['/auth/2fa']);
      }
    }

    if (!isAuthenticated || preauthorizationStep) {
      return true;
    } else {
      this.router.navigate(['/home']);
      return false;
    }

  }
}
