import { Directive, Input, OnDestroy, TemplateRef, ViewContainerRef } from "@angular/core";
import {Subject} from 'rxjs';
import {
  filter,
  takeUntil,
} from 'rxjs/operators';

@Directive({selector: '[loadContentWhen]'})
export class LazyLoadTabContentDirective implements OnDestroy {
  private hasView = false;
  private _isActive = false;
  _unsubscribe = new Subject<void>();

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef) {
    }

  @Input()
  set loadContentWhen(isActive: any) {
    this._isActive = isActive;
    if (isActive && !this.hasView) {
      this.showView();
    }
  }

  @Input()
  set loadContentWhenDestroyContentOn($observable: any) {
    $observable
    .pipe(
      filter((credit: any) => !!credit.id),
      takeUntil(this._unsubscribe)
    )
    .subscribe(() => {
      if (!this._isActive) {
        this.hideView();
      }
    })
  }

  showView() {
    this.viewContainer.createEmbeddedView(this.templateRef);
    this.hasView = true;
  }

  hideView() {
    this.viewContainer.clear();
    this.hasView = false;
  }

  ngOnDestroy() {
    this._unsubscribe.next();
    this._unsubscribe.complete();
  }

}
