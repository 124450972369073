import { Installment } from './installment';
import { Credit } from './credit';
import { Administrator } from './administrator';

export interface AccrualBase {
  id?: number;
  amount?: number;
  createdAt?: string;
  date?: string;
  createdBy?: Administrator;
}

export interface Accrual extends AccrualBase {
  credit?: Credit;
  installment?: Installment;
  note?: string;
  type?: string
  reference?: string
}

export interface ForfeitAccrual extends AccrualBase {
  installment: Installment;
}

export interface UtilizationFeeAccrual extends AccrualBase {
  installment: Installment;
}

export interface InterestAccrual extends AccrualBase {
  installment: Installment;
}

export interface OverduePenaltyAccrual extends AccrualBase {
  installment: Installment;
}

export interface TaxAccrual {
  amount: number;
  creditId: number;
  date?: string;
  note: string;
  type: string;
}

export enum AccrualComponentType {
  INTEREST = 'INTEREST',
  FORFEIT = 'FORFEIT',
  UTILIZATION_FEE = 'UTILIZATION_FEE',
  OVERDUE_PENALTY = 'OVERDUE_PENALTY',
  TAX = 'TAX',
}

export enum InstallmentAccrualComponentType {
  PRINCIPAL = 'principal',
  INTEREST = 'interest',
  FORFEIT = 'forfeit',
  UTILIZATION_FEE = 'utilizationFee',
  OVERDUE_PENALTY = 'overduePenalty',
  TAX = 'tax',
  TOTAL = 'total',
  TOTAL_DEDUCTED = 'totalDeducted',
}

export declare enum TaxAccrualType {
  COURT_FEE,
  CONSULTANT,
}
