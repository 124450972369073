import { map, distinct, mergeMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FilterValueListService, FilterValue } from '../filter/types';
import { Page } from '../../core/types/page';
import { BucketService } from '../../core/services/bucket.service';
import { BucketOperator } from '../../core/types/bucket';
import { AdministratorService } from '../../core/services/administrator.service';
import { SearchOptions } from '../../core/types/search-options';
import { SearchOperations } from '../../core/types/search-criterium';
import { Administrator } from '../../core/types/administrator';

@Injectable()
export class BucketAdministratorValueListService
  implements FilterValueListService {
  public searchOptions: SearchOptions;
  public activeAdministrators: Administrator[];
  constructor(
    private bucketService: BucketService,
    private administratorService: AdministratorService
  ) {
    this.searchOptions = new SearchOptions();
    this.searchOptions.pageSize = 100;
  }

  getFilterValues$(): Observable<any> {
    return this.bucketService.getBucketOperatorList$(this.searchOptions.getDTO()).pipe(
      mergeMap((bucketOperatorPage: Page<BucketOperator>) => {
        const sortedBucketOperators = bucketOperatorPage.content

        const searchOptions = new SearchOptions();
        searchOptions.pageSize = 1000;
        searchOptions.criteria = [
          {
            key: 'active',
            operation: SearchOperations.EQUALS,
            value: true,
          },
          {
            key: 'id',
            operation: SearchOperations.IN,
            value: sortedBucketOperators.map((bucketOperator) => bucketOperator.external_operator_id),
          }
        ];

        return this.administratorService.getAdministratorList(searchOptions.getDTO()).pipe(
          map((activeAdministratorsPage: Page<Administrator>) => {
            const activeOperators = activeAdministratorsPage.content.sort((a, b) =>
            a.firstName < b.firstName ? -1 : a.firstName > b.firstName ? 1 : 0
          );
            return activeOperators.map(
              (bucketOperator): FilterValue => {
                return {
                  value: bucketOperator.id,
                  displayText: `${bucketOperator.firstName} ${bucketOperator.lastName}`,
                };
              }
            );
          })
        );
      })
    );
  }
}
