import { Injectable } from '@angular/core';
import { RequestService } from './request.service';
import { Observable } from 'rxjs';

@Injectable()
export class InterestAccrualService {
  constructor(public request: RequestService) {}

  getInterstsAccrualsByCreditId(creditId: number | string): Observable<any> {
    return this.request.get(['accruals/interests/total?creditId=' + creditId]);
  }
}
