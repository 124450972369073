import { NgModule } from '@angular/core';
import { ColumnModule } from '../column/column.module';
import { FilterModule } from '../filter/filter.module';
import { SettingsMenuComponent } from './components/settings-menu/settings-menu.component';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from '../../lib/material.module';
import { MatIconModule } from '@angular/material/icon';
import { AddNewComponent } from './components/add-new/add-new.component';
import { CommonModule } from '@angular/common';  
@NgModule({
  declarations: [
    SettingsMenuComponent,
    AddNewComponent,
  ],
  imports: [ 
    CommonModule,
    ColumnModule,
    FilterModule,
    MaterialModule,
    MatIconModule,
    TranslateModule,
  ],
  exports: [
    SettingsMenuComponent,
    AddNewComponent
  ]
})
export class ListBaseModule { }
