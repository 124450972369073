import { Pipe, PipeTransform } from '@angular/core';
import { ConversationType } from '../../../core/types/converstion';

@Pipe({
  name: 'conversationTypesTranslation',
})
export class ConversationTypesTranslationPipe implements PipeTransform {
  transform(conversationType: ConversationType): string {
    return conversationType && conversationType.name
      ? 'communication.conversationTypes.' + conversationType.name.toLowerCase()
      : '';
  }
}
