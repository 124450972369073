import { Pipe, PipeTransform } from '@angular/core';
import { NgxPermissionsService } from '../../../../node_modules/ngx-permissions';

@Pipe({
  name: 'isUnauthorized',
})
export class IsUnauthorizedPipe implements PipeTransform {
  constructor(private ngxPermissionsService: NgxPermissionsService) {}

  transform(value: any, args?: any): any {
    const isUnauthorized$ = this.ngxPermissionsService
      .hasPermission(value)
      .then(hasPermission => {
        return !hasPermission;
      });

    return isUnauthorized$;
  }
}
