import { mergeMap, debounceTime, filter, takeUntil } from 'rxjs/operators';
import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewContainerRef,
  OnChanges,
  SimpleChanges,
  OnDestroy,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogProviderService } from '@app/core/services/dialog-provider.service';
import { IdentifierValidation } from '../../../../core/validation/identifier-validation';
import { FormComponentData } from '../../../../core/types/form-component-data';
import { CityService } from '../../../../core/services/city.service';
import { Education } from '../../../../core/types/education';
import { EmploymentType } from '../../../../core/types/employment-type';
import { ClientService } from '../../../../core/services/client.service';
import { Address } from '../../../../core/types/address';
import { BlacklistService } from '../../../../core/services/blacklist.service';
import { BlacklistEditComponent } from '../../blacklist-edit/blacklist-edit.component';
import { AutoCompleteValidation } from '../../../../core/validation/autocomplete-validation';
import { LanguageValidation } from '../../../../core/validation/language-validation';
import { RequiredValidation } from '../../../../core/validation/required-validation';
import { Subject } from 'rxjs';

@Component({
  selector: 'itfg-occupation-status-edit',
  templateUrl: './occupation-status-edit.component.html',
  styleUrls: ['./occupation-status-edit.component.scss'],
})
export class OccupationStatusEditComponent implements OnInit, OnChanges, OnDestroy {
  readonly EMPLOYER_NAME_MAX_LENGTH = 64;
  readonly EMPLOYER_ID_MAX_LENGTH = 13;
  readonly WORK_POSITION_MAX_LENGTH = 64;
  readonly WORK_EXPERIENCE_MAX_VALUE = 255;
  readonly SALARY_MAX_VALUE = 32767;
  readonly EXTRA_INCOME_MAX_VALUE = 32767;
  _unsubscribe = new Subject<void>();


  @Input() data;
  @Input() isNewItem: boolean;
  @Output() dataChanged = new EventEmitter<FormComponentData>();
  occupationStatusForm: UntypedFormGroup;
  cityList: any[];
  education: Education[];
  employmentType: EmploymentType[];
  paymentType: any[];
  employerIdIsInBlacklist: boolean;
  blacklistData: any;

  constructor(
    public formBuilder: UntypedFormBuilder,
    public clientService: ClientService,
    public cities: CityService,
    public translate: TranslateService,
    public blacklistService: BlacklistService,
    public dialog: MatDialog,
    public _dialogService: DialogProviderService,
    public _viewContainerRef: ViewContainerRef
  ) {
    this.occupationStatusForm = this.createOccupationStatusForm();
    this.education = [];
    this.employmentType = [];
    this.paymentType = [];
    this.employerIdIsInBlacklist = false;
    this.blacklistData = {};
  }

  ngOnInit() {
    this.getEducationTypes();
    this.getEmploymentTypes();
    this.getPaimentTranslations();
    this.watchForCityInput();
    this.watchForFormChanges();
    this.setInitialData();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.setInitialData();
  }

  setInitialData() {
    this.occupationStatusForm.setValue(
      this.parseOccupationStatusData(this.data)
    );

    if (
      !this.isNewItem &&
      this.data.employerId &&
      this.data.employerId.length > 0
    ) {
      this.checkIfEmployerIdIsInBlacklist();
    }
  }

  checkIfEmployerIdIsInBlacklist() {
    this.employerIdIsInBlacklist = false;
    const blacklistHttpCallOptions: Array<object> = [
      // Value types:
      // '1': 'Civil'
      // '2': 'Employer id'
      // '3': 'Phone number'
      { key: 'blacklistType.id', operation: '=', value: '2' },
      {
        key: 'value',
        operation: ':',
        value: [this.occupationStatusForm.value.employerId],
      },
    ];
    this.blacklistService
      .getBlacklist({ criteria: JSON.stringify(blacklistHttpCallOptions) })
      .pipe(takeUntil(this._unsubscribe))
      .subscribe(response => {
        this.blacklistData = response;
        if (response.content && response.content.length > 0) {
          this.employerIdIsInBlacklist = true;
        }

        for (let i = 0; i < response.content.length; i++) {
          const date = new Date(response.content[i].expire);
          this.blacklistData.content[i].expire = date.toLocaleDateString();
        }
      });
  }

  addToBlacklist() {
    const blacklistDialogOblect = {
      blacklistType: {
        id: 2,
      },
      value: this.occupationStatusForm.value.employerId,
      isPreset: true,
    };
    const dialogRef = this.dialog.open(BlacklistEditComponent, {
      data: blacklistDialogOblect,
    });

    dialogRef.afterClosed()
      .pipe(takeUntil(this._unsubscribe))
      .subscribe(result => {
        this.checkIfEmployerIdIsInBlacklist();
      });
  }

  editInBlacklist() {
    const blacklistDialogOblect = this.blacklistData.content[0];
    blacklistDialogOblect.isPreset = true;

    const dialogRef = this.dialog.open(BlacklistEditComponent, {
      data: blacklistDialogOblect,
    });

    dialogRef.afterClosed()
      .pipe(takeUntil(this._unsubscribe))
      .subscribe(result => {
        this.checkIfEmployerIdIsInBlacklist();
      });
  }

  getPaimentTranslations() {
    this.translate
      .get(['clients.bankTransferPaiment', 'clients.cashPaiment'])
      .pipe(takeUntil(this._unsubscribe))
      .subscribe(translation => {
        this.paymentType = [
          { id: '1', name: translation['clients.bankTransferPaiment'] },
          { id: '2', name: translation['clients.cashPaiment'] },
        ];
        this.parseDataToEmit(this.occupationStatusForm.value);
        this.dataChanged.emit({
          valid: this.occupationStatusForm.valid,
          data: this.occupationStatusForm.value,
          dirty: this.occupationStatusForm.dirty,
        });
      });
  }

  getEducationTypes() {
    this.clientService.getEducationTypes()
      .pipe(takeUntil(this._unsubscribe))
      .subscribe(educationTypes => {
        this.education = educationTypes;
        this.parseDataToEmit(this.occupationStatusForm.value);
        this.dataChanged.emit({
          valid: this.occupationStatusForm.valid,
          data: this.occupationStatusForm.value,
          dirty: this.occupationStatusForm.dirty,
        });
      });
  }

  getEmploymentTypes() {
    this.clientService.getEmploymentTypes()
      .pipe(takeUntil(this._unsubscribe))
      .subscribe(employmentTypes => {
        this.employmentType = employmentTypes;
        this.parseDataToEmit(this.occupationStatusForm.value);
        this.dataChanged.emit({
          valid: this.occupationStatusForm.valid,
          data: this.occupationStatusForm.value,
          dirty: this.occupationStatusForm.dirty,
        });
      });
  }

  createOccupationStatusForm(): UntypedFormGroup {
    const fb = this.formBuilder;
    return fb.group(
      {
        employerName: [
          '',
          [Validators.maxLength(this.EMPLOYER_NAME_MAX_LENGTH)],
        ],
        employerId: [
          '',
          [
            Validators.maxLength(this.EMPLOYER_ID_MAX_LENGTH),
            IdentifierValidation.isValidEik,
          ],
        ],
        workPosition: [
          '',
          [Validators.maxLength(this.WORK_POSITION_MAX_LENGTH)],
        ],
        workCity: [
          '',
          [
            AutoCompleteValidation.isAutocompleteValid(
              'lacksLatin',
              'name',
              LanguageValidation.lacksLatin
            ),
            AutoCompleteValidation.isAutocompleteValidObject(
              'notValidCity',
              'name'
            ),
          ],
        ],
        workAddress: ['', []],
        workExperience: [
          '', [],
        ],
        workExperienceYears: [
          '',
          [Validators.min(0), Validators.max(this.WORK_EXPERIENCE_MAX_VALUE)],
        ],
        workExperienceMonth: [
          '',
          [Validators.min(0), Validators.max(this.WORK_EXPERIENCE_MAX_VALUE)],
        ],
        workExperienceTotal: [
          '', [],
        ],
        workExperienceTotalYears: [
          '',
          [Validators.min(0), Validators.max(this.WORK_EXPERIENCE_MAX_VALUE)],
        ],
        workExperienceTotalMonth: [
          '',
          [Validators.min(0), Validators.max(this.WORK_EXPERIENCE_MAX_VALUE)],
        ],
        employmentType: ['', []],
        education: [''],
        bankTransferSalary: [''],
        salary: [
          '',
          [Validators.min(0), Validators.max(this.SALARY_MAX_VALUE)],
        ],
        extraIncome: [
          '',
          [Validators.min(0), Validators.max(this.EXTRA_INCOME_MAX_VALUE)],
        ],
        extraIncomeNotes: [''],
      },
      {
        validator: [
          RequiredValidation.fieldDependsOn(
            'workAddress',
            ['workCity'],
            'cityRequiredForAddress'
          ),
        ],
      }
    );
  }

  watchForCityInput() {
    const location = this.occupationStatusForm.controls.workCity;
    const cityInputChange$ = location.valueChanges;

    cityInputChange$
      .pipe(
        filter(value => value === ''),
        takeUntil(this._unsubscribe)
      )
      .subscribe(() => (this.cityList = []));

    cityInputChange$
      .pipe(
        debounceTime(700),
        filter(value => value.length > 0),
        mergeMap(value => this.cities.getCitiesStartingWith(value)),
        takeUntil(this._unsubscribe)
      )
      .subscribe(cityList => {
        this.cityList = cityList;
      });
  }

  mapCityToDisplayValue(city) {
    return city ? `${city.name} - ${city.municipality.name}` : '';
  }

  parseOccupationStatusData(data) {
    data.workCity = '';
    if (data.workAddress) {
      if (data.workAddress.city) {
        data.workCity = data.workAddress.city;
      }

      if (data.workAddress.address) {
        data.workAddress = data.workAddress.address;
      } else {
        data.workAddress = '';
      }
    }

    if (data.hasOwnProperty('bankTransferSalary')) {
      if (data.bankTransferSalary === true) {
        data.bankTransferSalary = '1';
      } else if (data.bankTransferSalary === false) {
        data.bankTransferSalary = '2';
      }
    }

    if (data.employmentType && data.employmentType.id) {
      data.employmentType = data.employmentType.id;
    }

    if (data.education && data.education.id) {
      data.education = data.education.id;
    }

    data.workExperienceYears = '';
    data.workExperienceMonth = '';
    if (data.workExperience) {
      if (data.workExperience >= 12) {
        data.workExperienceYears = Math.trunc(data.workExperience / 12);
        data.workExperienceMonth = data.workExperience % 12;
      } else {
        data.workExperienceMonth = data.workExperience;
      }
    } else {
      data.workExperience = '';
    }


    data.workExperienceTotalYears = '';
    data.workExperienceTotalMonth = '';
    if (data.workExperienceTotal) {
      if (data.workExperienceTotal >= 12) {
        data.workExperienceTotalYears = Math.trunc(data.workExperienceTotal / 12);
        data.workExperienceTotalMonth = data.workExperienceTotal % 12;
      } else {
        data.workExperienceTotalMonth = data.workExperienceTotal;
      }
    } else {
      data.workExperienceTotal = '';
    }

    return {
      ...data,
    };
  }

  watchForFormChanges() {
    this.occupationStatusForm.valueChanges
      .pipe(takeUntil(this._unsubscribe))
      .subscribe(value => {
        this.parseDataToEmit(value);
        this.dataChanged.emit({
          valid: this.occupationStatusForm.valid,
          data: value,
          dirty: this.occupationStatusForm.dirty,
        });
      });
  }

  parseDataToEmit(data) {
    const address: Address = {};
    // address.address = '';
    if (data.workAddress) {
      if (data.workAddress.length > 0) {
        address.address = data.workAddress;
      } else if (
        data.workAddress.address &&
        data.workAddress.address.length > 0
      ) {
        address.address = data.workAddress.address;
      }
    }

    // address.city = {};
    if (data.workCity) {
      address.city = data.workCity;
    }

    if (data.employmentType) {
      this.employmentType.forEach(type => {
        if (type.id === data.employmentType) {
          data.employmentType = type;
          return;
        }
      });
    }

    if (data.education) {
      this.education.forEach(education => {
        if (education.id === data.education) {
          data.education = education;
          return;
        }
      });
    }

    data.workAddress = address;

    if (data.bankTransferSalary) {
      if (data.bankTransferSalary.toString() === '1') {
        data.bankTransferSalary = true;
      } else if (data.bankTransferSalary.toString() === '2') {
        data.bankTransferSalary = false;
      }
    }
    data.workExperience = Number(data.workExperienceYears) * 12 + Number(data.workExperienceMonth);
    delete data.workExperienceYears;
    delete data.workExperienceMonth;

    data.workExperienceTotal = Number(data.workExperienceTotalYears) * 12 + Number(data.workExperienceTotalMonth);
    delete data.workExperienceTotalYears;
    delete data.workExperienceTotalMonth;
  }

  ngOnDestroy() {
    this._unsubscribe.next();
    this._unsubscribe.complete();
  }
}
