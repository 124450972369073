import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import * as moment from 'moment/moment';
import { CcrReportTypes, CcrSourceTypes } from '../../../../core/types/ccr';

export interface ChooseCcrSourceData {
  ccrReportTypes: CcrReportTypes;
  ccrSourceTypes: CcrSourceTypes;
  ttl: number;
}

@Component({
  selector: 'itfg-client-ccr-choose-source',
  templateUrl: './client-ccr-choose-source.component.html',
  styleUrl: './client-ccr-choose-source.component.scss'
})
export class ClientCcrChooseSourceComponent implements OnInit {
  public sourceTypeForm: UntypedFormGroup;
  public currentDate: Date = new Date();
  public dateFrom: string;
  public CcrSourceTypes: typeof CcrSourceTypes = CcrSourceTypes;
  public CcrReportTypes: typeof CcrReportTypes = CcrReportTypes;
  public MIN_CACHE_VALUE = 0;
  public MAX_CACHE_VALUE = 90;
  private DEFAULT_FORM_VALUES = {
    type: {
      [CcrReportTypes.CCR]: {
        source: CcrSourceTypes.DEFAULT,
        ttl: this.data.ttl,
      },
      [CcrReportTypes.CCR_REQUESTS]: {
        source: CcrSourceTypes.DG,
        ttl: this.MIN_CACHE_VALUE
      }
    },
    source: {
      [CcrSourceTypes.DEFAULT]: {
        source: CcrSourceTypes.DEFAULT,
        ttl: this.data.ttl
      },
      [CcrSourceTypes.BNB]: {
        source: CcrSourceTypes.BNB,
        ttl: this.MIN_CACHE_VALUE,
      },
      [CcrSourceTypes.DG]: {
        source: CcrSourceTypes.DG,
        ttl: this.data.ttl
      }

    }
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ChooseCcrSourceData,
    private dialogRef: MatDialogRef<ClientCcrChooseSourceComponent>,
    private fb: UntypedFormBuilder
  ) {
    this.dateFrom = moment().subtract(1, 'years').format('YYYY-MM-DD');
  }

  ngOnInit() {
    this.sourceTypeForm = this.fb.group({
      type: CcrReportTypes.CCR,
      source: CcrSourceTypes.DEFAULT,
      ttl: [this.data.ttl, [Validators.min(this.MIN_CACHE_VALUE), Validators.max(this.MAX_CACHE_VALUE)]]
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onSubmit() {
    this.dialogRef.close(this.sourceTypeForm.getRawValue());
  }

  // selectedSourceChanged(event) {
  //   if (event.value === CcrSourceTypes.BNB) {
  //     this.sourceTypeForm.patchValue({ ttl: this.MIN_CACHE_VALUE });
  //   } else {
  //     this.sourceTypeForm.patchValue({ ttl: this.data.ttl });
  //   }
  // }

  changeFormValuesByConfiguration(changedField: string, value: string) {
    const config = this.DEFAULT_FORM_VALUES[changedField][value];

    if (!config) {
      throw new Error(`Config for ccr request not found!`)
    }

    this.sourceTypeForm.patchValue(config);
  }

}

