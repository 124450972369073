import { Note } from './note';

export interface Call {
  id: number;
  externalId: string;
  direction: CallDirection;
  agent: number;
  phoneNumber: string;
  waitingTime: string;
  duration: number;
  record: string;
  didNumber: number;
  status: CallStatus;
  reference: string;
  createdAt: Date | string;
  updatedAt: Date | string;
  notes: Note[];
}

export enum CallDirection {
  IN = 'IN',
  OUT = 'OUT',
}

export enum CallStatus {
  ANSWER = 'ANSWER',
  NO_ANSWER = 'NO_ANSWER',
  BUSY = 'BUSY',
  FAILED = 'FAILED',
  AGENT_CONNECTED = 'AGENT_CONNECTED',
  INITIATED = 'INITIATED',
  ONGOING = 'ONGOING',
  CONNECTION_FAILED = 'CONNECTION_FAILED',
  AGENT_FREE_CALLBACK_REQUESTED = 'AGENT_FREE_CALLBACK_REQUESTED',
  AGENT_FREE_NO_CALLBACK_REQUESTED = 'AGENT_FREE_NO_CALLBACK_REQUESTED',
  ALL_BUSY_CALLBACK_REQUESTED = 'ALL_BUSY_CALLBACK_REQUESTED',
  ALL_BUSY_NO_CALLBACK_REQUESTED = 'ALL_BUSY_NO_CALLBACK_REQUESTED',
  HANGUP_AT_WELCOME = 'HANGUP_AT_WELCOME',
  NON_WORKING_HOURS_CALLBACK_REQUESTED = 'NON_WORKING_HOURS_CALLBACK_REQUESTED',
  NON_WORKING_HOURS_NO_CALLBACK_REQUESTED = 'NON_WORKING_HOURS_NO_CALLBACK_REQUESTED',
}

export enum CallOutgoingStatuses {
  ANSWER = 'ANSWER',
  NO_ANSWER = 'NO_ANSWER',
  BUSY = 'BUSY',
  FAILED = 'FAILED',
}

export enum CallIncomingStatuses {
  AGENT_CONNECTED = 'AGENT_CONNECTED',
  AGENT_FREE_CALLBACK_REQUESTED = 'AGENT_FREE_CALLBACK_REQUESTED',
  AGENT_FREE_NO_CALLBACK_REQUESTED = 'AGENT_FREE_NO_CALLBACK_REQUESTED',
  ALL_BUSY_CALLBACK_REQUESTED = 'ALL_BUSY_CALLBACK_REQUESTED',
  ALL_BUSY_NO_CALLBACK_REQUESTED = 'ALL_BUSY_NO_CALLBACK_REQUESTED',
  HANGUP_AT_WELCOME = 'HANGUP_AT_WELCOME',
  NON_WORKING_HOURS_CALLBACK_REQUESTED = 'NON_WORKING_HOURS_CALLBACK_REQUESTED',
  NON_WORKING_HOURS_NO_CALLBACK_REQUESTED = 'NON_WORKING_HOURS_NO_CALLBACK_REQUESTED',
}

export interface CreateCallDto {
  direction: CallDirection;
  phoneNumber: string;
  status?: CallStatus;
}

export enum CallsCategoriesType {
  MISSED_AGENT = 'MISSED',
  MISSED_NO_AGENT = 'NO_AGENT',
  WAITING_FOR_PROCESSING = 'FOR_NOTE',
  WAITING_FOR_CALLBACK = 'FOR_CALLBACK',
  ALL = 'ALL',
}

export enum CallStage {
  INIT = 'INIT',
  RINGING = 'RINGING',
  IN_PROGRESS = 'IN_PROGRESS',
  ENDED = 'ENDED',
}

export enum CallerClientRelation {
  CLIENT = 'CLIENT',
  CONTACT = 'CONTACT',
  UNRELATED = 'UNRELATED',
}

export enum CallDialogMode {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
}

export enum PbxMode {
  MANUAL_CALL = 'MANUAL_CALL',
  EXISTING_CALL = 'EXISTING_CALL',
}

export const HIDDEN_PHONE_NUMBER = '0000000000';
export const INQUIRY_PHONE_NUMBER = "011800";
export const FORMATTED_INQUIRY_PHONE_NUMBER = '+35911800';
export const FORMATTED_HIDDEN_PHONE_NUMBER = '+359000000000';
