import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Observable, Subject, filter, mergeMap, takeUntil } from 'rxjs';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { GraphRecords } from '@app/core/components/graph/types/GraphRecords.type';
import { TranslateService } from '@ngx-translate/core';
import { Client } from '@app/core/types/client';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { ClientService } from '@app/core/services/client.service';
import { GraphService } from '@app/core/services/graph.service';
import { ClientRelatedPeopleShowAllComponent } from './client-related-people-show-all/client-related-people-show-all.component';
import { ErrorService } from '../../../core/services/error.service';
import { ContactService } from '../../../core/services/contacts.service';
import { ItfgDataTableColumn } from '@app/core/components/data-table/types/data-table.column';
import { ContactPhone } from '@app/core/types/contact-phone';

@Component({
  selector: 'itfg-client-related-people',
  templateUrl: './client-related-people.component.html',
  styleUrls: ['./client-related-people.component.scss'],
})
export class ClientRelatedPeopleComponent implements OnInit, OnDestroy {
  public graphData: GraphRecords;
  public connectionTypes: string[];
  public selectedTabIndex = 0;
  public translations = {};
  public client: Client;
  public isFiltered: Boolean;
  private lastPath: any;
  public limitReached: any;
  @Input() inputClient: Client;
  @Input() public tabSelection: Observable<MatTabChangeEvent>;
  _unsubscribe: Subject<void> = new Subject<void>();


  constructor(
    private graphService: GraphService,
    private translateService: TranslateService,
    private clientService: ClientService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnChanges() {
    if (this.inputClient && this.inputClient.id) {
      this.client = this.inputClient;
      this.setConnections(this.client?.id);
      this.selectedTabIndex = 0;
    }
  }

  ngOnInit() {
    this.route.paramMap
      .pipe(
        filter((params: ParamMap) => typeof params.get('id') === 'string'),
        mergeMap((params: ParamMap) =>
          this.clientService.getClientById(Number(params.get('id')))
        )
      )
      .subscribe(
        client => {
          this.client = client;
        }
      );
    this.setConnections(this.client?.id)
  }

  redirectToUser(event) {
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['/clients/', event])
    );

    return window.open('#' + url, '_blank');
  }

  setConnections(clientId: number) {
    this.graphService.getConnectionByUser(clientId)
      .pipe(takeUntil(this._unsubscribe))
      .subscribe(connectionTypes => {
        connectionTypes.sort(a => { //  TODO: remove after implementing connection importance
          if (a?.type === 'User_HAS_LOGGEDIN' || a?.type === 'Blacklist_HAS_LOGGEDIN') {
            return 1
          } else {
            return -1
          }
        })
        this.setConnectionTypes(connectionTypes)
      })
  }

  setConnectionTypes(connectionTypes) {
    for (let connectionType of connectionTypes as any[]) {
      this.translations[connectionType.type] = this.translateService.instant(`clients.connectionTypes.${connectionType.type}`)
    }
    this.connectionTypes = connectionTypes

    // this.contactService.contactsSubject
    // .pipe(takeUntil(this._unsubscribe), skip(1)).subscribe((contacts: ContactPhone[]) => {
    //   this.initMatchesData();
    // });
  }

  showPathToNode(node) {
    this.graphService.getPath(this.client.id, node.label, node.properties)
      .subscribe(data => {
        const path = new GraphRecords(data);
        this.graphData = path;
        this.lastPath = path;
        this.isFiltered = false;
        this.limitReached = false;
        document.querySelector('.mat-sidenav-content').scrollTop = 0;
        this.selectedTabIndex = 1;
      })
  }

  changeSelectedTabIndex(index) {
    event.stopPropagation()
    this.selectedTabIndex = index;
  }

  filterGraph(node) {
    this.graphService.getNeighbors(node.label, node.properties.toString())
      .subscribe(data => {
        this.graphData = new GraphRecords(data);
        this.limitReached = data.limitReached;
        this.isFiltered = true;
      })
  }

  removeFilter() {
    this.graphData = this.lastPath;
    this.isFiltered = false;
    this.limitReached = false;
  }

  ngOnDestroy() {
    this._unsubscribe.next();
    this._unsubscribe.complete();
  }
}
