import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RequestService } from './request.service';
import { BlacklistItem } from '../types/blacklist-item';
import { BlacklistType } from '../types/blacklist-type';
import { Page } from '../types/page';

@Injectable()
export class BlacklistService {
  request: RequestService;

  constructor(request: RequestService) {
    this.request = request;
  }

  getBlacklist(options: any): Observable<Page<BlacklistItem>> {
    return this.request.get(['blacklists'], { params: options });
  }

  getBlacklistTypes(): Observable<BlacklistType[]> {
    return this.request.get(['blacklists', 'types']);
  }

  saveToBlacklist(params: any): Observable<BlacklistItem> {
    return this.request.post(['blacklists'], {
      headers: { 'Content-Type': 'application/json' },
      body: params,
    });
  }

  saveToBlacklistBulkAdd(params: any): Observable<BlacklistItem[]> {
    return this.request.post(['blacklists/import'], {
      headers: { 'Content-Type': 'application/json' },
      body: params,
    });
  }

  deleteInBlacklist(id: string): Observable<any> {
    return this.request.delete(['blacklists', id], {
      responseType: 'text',
    });
  }
}
