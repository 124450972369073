export enum FilterGroupType {
  CREDIT = 'CREDIT',
  CLIENT = 'CLIENT',
  PARTNER = 'PARTNER',
  PRODUCT = 'PRODUCT',
  OFFICE = 'OFFICE',
  OTHER = 'OTHER',
  CLAIM = 'CLAIM',
  TRANSACTION = 'TRANSACTION',
  ACCOUNT = 'ACCOUNT',
  BUCKET = 'BUCKET',
  SOURCE = 'SOURCE',
  DESTINATION = 'DESTINATION',
  COLLECTOR = 'COLLECTOR',
  AGENT = 'AGENT',
  APPLICATION = 'APPLICATION',
  REMINDER = 'REMINDER',
  PAYMENTPROMISE = 'PAYMENTPROMISE',
  PAYMENTSTRANSACTION = 'PAYMENTSTRANSACTION',
  CALL = 'CALL',
}
