<div layout="column" >
  <div layout="row"
       layout-align="space-between start"
       flex>
  <span [translate]="'mi.idCardChangeStatus'"
        mat-dialog-title>Change status</span>
    <button tabindex="-1"
            mat-icon-button
            class="close-button"
            [mat-dialog-close]="false">
      <mat-icon>clear</mat-icon>
    </button>
  </div>

  <div>
    <form [formGroup]="statusForm"
          (ngSubmit)="onSubmit()">
      <div layout="column" layout-wrap>
        <mat-form-field>
          <mat-select formControlName="status"
                      placeholder="{{ 'clients.changeStatus' | translate }}">

            <mat-select-trigger>
            <span layout="row"
                  layout-align="start center">
              <mat-icon [ngStyle]="{ color: idCardStatusStyleMap[statusForm.get('status').value]?.backgroundColor }">
                {{ idCardStatusStyleMap[statusForm.get('status').value]?.icon }}</mat-icon>
             {{'global.' + statusForm.get('status').value?.toLowerCase() | translate }}
            </span>
            </mat-select-trigger>
            <mat-option *ngFor="let status of data.statusList?.optionsList"
                        [value]="status.name">
              <mat-icon [ngStyle]="{ color: idCardStatusStyleMap[status.name]?.backgroundColor }">
                {{ idCardStatusStyleMap[status.name]?.icon }}
              </mat-icon>
              {{ 'global.' + status.name.toLowerCase() | translate}}
            </mat-option>

          </mat-select>

        </mat-form-field>


        <div layout="row"
             layout-wrap layout-align="end center">
          <button type="submit"
                  mat-raised-button>
            {{ 'global.save' | translate }}
          </button>
        </div>
      </div>
    </form>
  </div>

</div>


