<div flex layout-align="end end"
class="position-buttons-fix"
layout-wrap
layout="row">
<div>
   <itfg-add-new
   toolTip = "{{ 'tags.newTag' | translate }}"
   (onClick)="openTagEdit()"
   >
 </itfg-add-new>
 </div>
</div>

<itfg-data-table #dataTable
               [data]="tagListData?.content"
               [columns]="columns"
               [isClickable]="true">
  <ng-template itfgDataTableTemplate="name"
               let-tag="row">
    <mat-chip-listbox>
      <mat-chip-option class="mat-elevation-z2"
                [ngStyle]="{'background-color': tag.color, 'color': 'white' }">
        {{tag.name}}
      </mat-chip-option>
    </mat-chip-listbox>
  </ng-template>
               
  <ng-template itfgDataTableTemplate="color"
               let-color="value">
    <div class="color-circle"
         [matTooltip]="color"
         matTooltipPosition="right"
         [ngStyle]="{ 'background-color': color }"></div>
  </ng-template>
  <ng-template itfgDataTableTemplate="actions"
               let-row="row">
    <a mat-icon-button
      *ngxPermissionsOnly="'TAG_WRITE'"
       (click)="openTagEdit(row)">
      <mat-icon>edit</mat-icon>
    </a>
    <a mat-icon-button
       *ngxPermissionsOnly="'TAG_DELETE'"
       (click)="deleteTag(row)">
      <mat-icon>delete</mat-icon>
    </a>
  </ng-template>
</itfg-data-table>