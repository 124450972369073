import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { SessionService } from '../core/services/session.service';
import { PreserveRouteService } from '../core/services/preserveRoute.service';

@Injectable()
export class MainGuardService  {
  private router: Router;
  private session: SessionService;
  private preserveService: PreserveRouteService;

  constructor(
    router: Router,
    session: SessionService,
    preserveService: PreserveRouteService
  ) {
    this.router = router;
    this.session = session;
    this.preserveService = preserveService;
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    const isAuthenticated = this.session.isAuthenticated();

    if (!this.session.currentlyLoggedOperator) {
      this.session.getCurrentlyLoggedOperator();
    }

    const roles = this.session.currentlyLoggedOperator.roles || [];
    const hasRoles = roles.length > 0;
    const preauthorizationStep = roles.every((role: string) => role === 'PRE_AUTH_ADMIN');

    if (isAuthenticated && !preauthorizationStep) {
      return true;
    } else {
      if (state.url) {
        // this.preserveService.lastVisitedRoute = state.url;
        if (
          this.router.url &&
          this.router.url !== '/auth/login' &&
          this.router.url !== '/auth/2fa' &&
          this.router.url !== '/'
        ) {
          this.session.setLastVisitedRoute(state.url);
        }
      }

      if (preauthorizationStep && hasRoles) {
        this.router.navigate(['/auth/2fa']);
      } else {
        this.router.navigate(['/auth/login']);
      }
      return false;
    }
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot) {
    return this.canActivate(route, state);
  }
}
