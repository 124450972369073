import { debounceTime } from 'rxjs/operators';
import { Directive, ContentChild, AfterContentInit } from '@angular/core';
import { AfterViewInit } from '@angular/core';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';

@Directive({
  selector: '[itfgSelectFirstAutocompleteOptionOnBlur]',
})
export class SelectFirstAutocompleteOptionOnBlurDirective
  implements AfterContentInit {
  @ContentChild(MatAutocompleteTrigger)
  autocomplete: MatAutocompleteTrigger;

  constructor() {}

  ngAfterContentInit() {
    // this.autocomplete.panelClosingActions
    //   .pipe(debounceTime(200))
    //   .subscribe(() => {
    //     if (
    //       this.autocomplete.autocomplete.options &&
    //       this.autocomplete.autocomplete.options.first
    //     ) {
    //       this.autocomplete.autocomplete.options.first.select();
    //       // TODO: Fix maximum call stack exeeded error.
    //     }
    //   });
  }
}
