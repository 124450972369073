export enum AddNoteFormMode {
  PHONE = 'PHONE',
  INFO = 'INFO',
  REMINDER = 'REMINDER',
  FILTER_SETTINGS = 'FILTER_SETTINGS'
}

export interface CreditNodeEditFormConfig {
  phoneControlsShown: boolean;
  paymentPromiseControlsShown: boolean;
  reminderControlsShown: boolean;
  addReminderShown: boolean;
  addPaymentPromiseShown: boolean;
}

export class AddNoteFormState {
  static readonly stateByFormMode = {
    [AddNoteFormMode.PHONE]: {
      phoneControlsShown: true,
      paymentPromiseControlsShown: true,
      reminderControlsShown: true,
      addReminderShown: true,
      addPaymentPromiseShown: true,
    },
    [AddNoteFormMode.INFO]: {
      phoneControlsShown: false,
      paymentPromiseControlsShown: false,
      reminderControlsShown: false,
      addReminderShown: false,
      addPaymentPromiseShown: false,
    },
    [AddNoteFormMode.REMINDER]: {
      phoneControlsShown: false,
      paymentPromiseControlsShown: false,
      reminderControlsShown: true,
      addReminderShown: false,
      addPaymentPromiseShown: false,
    },
  };

  get state() {
    return this._state;
  }

  set state(newState: CreditNodeEditFormConfig) {
    this._state = newState;
  }

  get phoneControlsShown() {
    return this.state.phoneControlsShown;
  }

  get paymentPromiseControlsShown() {
    return this.state.paymentPromiseControlsShown;
  }

  get reminderControlsShown() {
    return this.state.reminderControlsShown;
  }

  get addReminderShown() {
    return this.state.addReminderShown;
  }

  get addPaymentPromiseShown() {
    return this.state.addPaymentPromiseShown;
  }

  private _state: CreditNodeEditFormConfig = {
    phoneControlsShown: false,
    paymentPromiseControlsShown: false,
    reminderControlsShown: false,
    addReminderShown: false,
    addPaymentPromiseShown: false,
  };

  constructor(formMode?: AddNoteFormMode) {
    this.setState(formMode);
  }

  setState(formMode: AddNoteFormMode) {
    this.state = AddNoteFormState.stateByFormMode[formMode];
    return this;
  }

  patchState(newState: Partial<CreditNodeEditFormConfig>) {
    this.state = {
      ...this.state,
      ...newState,
    };
  }
}
