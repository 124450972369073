import { Injectable } from '@angular/core';
import {RequestService} from './request.service';
import {Observable, Subject} from 'rxjs';
import {Page} from '../types/page';
import {PaymentAgreement} from '../types/payment-agreement';

@Injectable()
export class PaymentAgreementService {
  onPaymentAgreementsChange: Subject<PaymentAgreement> = new Subject<PaymentAgreement>();
  onEarlyRepaymentChange: Subject<any> = new Subject<any>();

  constructor(private request: RequestService) {}

  getPaymentAgreementList$(options?: any): Observable<Page<PaymentAgreement>> {
    return this.request.get(['payment-agreements'], { params: options });
  }

  createPaymentAgreementsDrafts$(drafts): Observable<PaymentAgreement> {
    return this.request.post(['payment-agreements', 'drafts'], {
      body: drafts,
    });
  }
  savePaymentAgreementDraft$(id, draft): Observable<PaymentAgreement> {
    return this.request.post(['payment-agreements', 'drafts', id], {
      body: draft,
    });
  }
  confirmPaymentAgreements$(id): Observable<PaymentAgreement> {
    return this.request.post(['payment-agreements', id, 'status', 'active']);
  }
  approvePaymentAgreementDraft$(id, agreement): Observable<PaymentAgreement> {
    return this.request.post(['payment-agreements', id, 'status', 'approved'], {
      body: agreement,
    });
  }
  cancelPaymentAgreements$(agreementIds: number[]): Observable<PaymentAgreement> {
    return this.request.post(['payment-agreements', 'canceled'], {
      body: agreementIds
    });
  }
}
