import { Injectable } from '@angular/core';
import { RequestService } from './request.service';
import { FrameAgreement } from '../types/frame-agreement';
import { Observable } from 'rxjs';
import { Page } from '../types/page';

@Injectable()
export class FrameAgreementService {
  constructor(public request: RequestService) {}

  getFrameAgreementList(
    userId: number | string,
    options?: any
  ): Observable<Page<FrameAgreement>> {
    return this.request.get(['users', userId, 'frame-agreements'], {
      params: options,
    });
  }

  createFrameAgreement(userId: number | string): Observable<FrameAgreement> {
    return this.request.post(['users', userId, 'frame-agreements']);
  }

  getFrameAgreementById(
    userId: number | string,
    frameAgreementId: number | string
  ): Observable<FrameAgreement> {
    return this.request.get([
      'users',
      userId,
      'frame-agreements',
      frameAgreementId,
    ]);
  }

  getFrameAgreementAsHtml(
    userId: number | string,
    frameAgreementId: number | string
  ): Observable<HTMLDocument> {
    return this.request.get(
      ['users', userId, 'frame-agreements', frameAgreementId, 'html'],
      { responseType: 'document' }
    );
  }

  getFrameAgreementAsPdf(
    userId: number | string,
    frameAgreementId: number | string
  ): Observable<Blob> {
    return this.request.get(
      ['users', userId, 'frame-agreements', frameAgreementId, 'pdf'],
      { responseType: 'blob' }
    );
  }

  deactivateFrameAgreementById(
    userId: number | string,
    frameAgreementId: number | string
  ): Observable<FrameAgreement> {
    return this.request.post([
      'users',
      userId,
      'frame-agreements',
      frameAgreementId,
      'inactive',
    ]);
  }
}
