import {
  Component,
  OnInit,
  Input,
  ChangeDetectionStrategy,
} from '@angular/core';
import { DateFormat } from '../../../core/types/date-format';

@Component({
  selector: 'itfg-date-format',
  templateUrl: './date-format.component.html',
  styleUrls: ['./date-format.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DateFormatComponent implements OnInit {
  @Input() public date: string;
  @Input() public dateFormat: string = DateFormat.DATE;
  @Input() public timeFormat: string = DateFormat.TIME;
  @Input() public tooltip: string;
  @Input() public matTooltipPosition = 'above';
  @Input() public fullDate = false;

  private dateFormats = DateFormat;

  constructor() {}

  ngOnInit() {}
}
