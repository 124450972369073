import { Injectable } from '@angular/core';

@Injectable()
export class IbanUtilService {
  constructor() {}

  matchIbanBICCode(iban: string) {
    const bankIdCodePattern = /[A-Z]{4}/;
    const match = iban.match(bankIdCodePattern);
    return match ? match[0] : '';
  }

  isValidIban(iban: string): boolean {
    const pattern = /^BG[0-9]{2}[A-Z]{4}[0-9]{6}[A-Z0-9]{8}$/;
    return pattern.test(iban.replace(/ /g, ''));
  }

  extractAndValidateIban(input: string): string {
    if (!input) {
      return '';
  }

    const pattern = /BG[0-9]{2}[A-Z]{4}[0-9]{6}[A-Z0-9]{8}/;
    const extractedIban = input.match(pattern);
    return (extractedIban && this.isValidIban(extractedIban[0])) ? extractedIban[0] : '';
  }
}
