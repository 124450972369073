<div class="message-container-wrapper">
    <div *ngIf="opened" #messageContainer class="message-container" @shake>
        <span>
            <mat-icon color="warn">error</mat-icon>
        </span>
        <div>
            <div class="message-title">
                <span>{{ label }}</span>
            </div>
            <div class="message-body">
                {{ sublabel }}
            </div>
        </div>
    </div>
</div>