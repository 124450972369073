import { Component, OnInit, ViewChild, Input, OnDestroy, AfterViewInit } from '@angular/core';
import {
  SearchDirection,
  SearchOptions,
} from '../../core/types/search-options';
import { SearchCriterium } from '../../core/types/search-criterium';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ColumnsService } from '../../../app/core/services/columns.service';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { ItfgDataTableSortChangeEvent, ItfgDataTableSortingOrder } from '@app/core/components/data-table/types/sort.type';
import { ItfgDataTableColumn } from '@app/core/components/data-table/types/data-table.column';

@Component({
  selector: 'itfg-list-base',
  template: `
    <p>
      Base component!
    </p>
  `,
  styleUrls: ['./list-base.component.scss'],
})
export class ListBaseComponent implements OnInit, OnDestroy, AfterViewInit {
  public columnsService: ColumnsService;
  public currentPage: number;
  public columns: ItfgDataTableColumn[];
  public appliedColumns: ItfgDataTableColumn[];
  public triggerDataChange$ = new Subject<SearchOptions>();

  @ViewChild('pagingBarResponsive', { static: true })
  public pagingBarResponsive: MatPaginator;

  @Input()
  public searchOptions: SearchOptions = new SearchOptions({
    direction: ItfgDataTableSortingOrder.ASCENDING as any,
  });
  public sortBy: string;
  public sortOrder: ItfgDataTableSortingOrder = ItfgDataTableSortingOrder.ASCENDING;
  public dataLoading: boolean;
  public filtersShown: boolean;
  public filterCardSettingsShown: boolean;
  public _unsubscribe: Subject<void> = new Subject<void>();

  constructor(columnsService: ColumnsService) {
    this.columnsService = columnsService;
  }
  ngAfterViewInit(): void {
    // this.pagingBarResponsive.firstPage();
  }

  ngOnInit() {}

  sort(sortEvent: ItfgDataTableSortChangeEvent): void {
    this.sortBy = sortEvent.name;
    this.sortOrder = sortEvent.order;
    if (this.sortOrder !== ItfgDataTableSortingOrder.ASCENDING) {
      this.searchOptions.direction = SearchDirection.DESCENDING;
    } else {
      this.searchOptions.direction = SearchDirection.ASCENDING;
    }
    this.searchOptions.column = this.sortBy !== '' ? this.sortBy : 'id';
    if (this.pagingBarResponsive.pageIndex !== 0) {
      this.pagingBarResponsive.firstPage();
    } else {
      this.triggerDataChange$.next(this.searchOptions);
    }
  }

  toggleFilters() {
    this.filtersShown = !this.filtersShown;
  }
  onShowFilterCardSettings() {
    this.filterCardSettingsShown = !this.filterCardSettingsShown;
  }

  onFiltersApplied(filters: SearchCriterium[]) {
    this.searchOptions.criteria = filters;
    this.searchOptions.page = 0;
    this.triggerDataChange$.next(this.searchOptions);
  }

  onLastFilterRemoved() {}

  pageChanged(event: PageEvent | number) {
    console.log('page-changed', event);
    this.searchOptions.page = event['pageIndex'];
    this.searchOptions.pageSize = event['pageSize'];
  }

  columnsTableChangeSubscription() {
    this.columnsService.onColumnsTableChange
      .pipe(takeUntil(this._unsubscribe))
      .subscribe(columns => {
        const filteredColumns = [];
        Object.values(columns).forEach(column => {
          Object.values(this.columns).forEach(col => {
            if (column.name === col.name) {
              filteredColumns.push(col);
            }
          });
        });
        this.appliedColumns = filteredColumns;
      });
  }

  ngOnDestroy() {
    this._unsubscribe.next();
    this._unsubscribe.complete();
  }
}
