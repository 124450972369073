import { Directive, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[itfgTrimInput]',
})
export class TrimInputDirective {
  public inputElement;

  constructor(private formControl: NgControl) {}

  @HostListener('blur') onMouseLeave() {
    this.trimValue(this.formControl);
  }

  trimValue(formControl) {
    const value = formControl.control.value;
    if (!!value && (typeof value === 'string' || value instanceof String)) {
      const trimmedValue = value.trim();
      formControl.control.setValue(trimmedValue);
    }
  }
}
