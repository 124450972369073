import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Product } from '../../../core/types/product';
import { ProductPlanEntry } from '../../../core/types/product-plan-entry';
import { NavigationMenuService } from '../../../core/services/navigation-menu.service';
import { CreditCalculatorTemplateCode } from '@app/core/types/credit-calculator-template-code';

@Component({
  selector: 'itfg-product-calculator',
  templateUrl: './product-calculator.component.html',
  styleUrls: ['./product-calculator.component.scss'],
})
export class ProductCalculatorComponent implements OnInit {
  public calculatorForm: UntypedFormGroup;
  public productList: Product[];
  public targetProductPlan: ProductPlanEntry;
  public standaloneCalculatorTemplateCode = CreditCalculatorTemplateCode.STAND_ALONE_CALCULATOR;
  public productPlanList: ProductPlanEntry[];
  public productPlanFrequencyList: ProductPlanEntry[];
  public productPlanAmountList: ProductPlanEntry[];
  public productPlanInstallmentNumberList: Array<number>;
  public targetPlanData: any;

  constructor(
    private navigationMenuService: NavigationMenuService
  ) {
  }

  ngOnInit() {
  }

  close(): void {
    this.navigationMenuService.toggleProductCalculator.next();
  }
}
