import { CombinedStatusNameType, VerificationStatus, VerificationStatusNames, VerificationStatusReasons } from "@app/core/verification-status-type"

class CombinedStatus { name: CombinedStatusNameType; reasons: string[] };

export class IdentityVerificationStatus {

  public status: CombinedStatus;

  private face: {
    valid: boolean,
    manual: boolean;
    pendingFile: boolean;
    pendingValidation: boolean;
  } = {
    valid: false,
    manual: false,
    pendingFile: false,
    pendingValidation: false,
  };

  private idCard: {
    valid: boolean,
    manual: boolean;
    pendingFile: boolean;
    pendingValidation: boolean;
  } = {
    valid: false,
    manual: false,
    pendingFile: false,
    pendingValidation: false,
  };

  public valid: boolean;
  public pendingFile: boolean;
  public pendingValidation: boolean;
  public manual: boolean;
  public auto: boolean;

  constructor(
    private faceStatus: VerificationStatus,
    private idCardStatus: VerificationStatus
  ) {
    this.setVerification();
  }

  setVerification() {
    this.face.valid = this.faceStatus.name === VerificationStatusNames.VALID;
    this.idCard.valid = this.idCardStatus.name === VerificationStatusNames.VALID;
    this.valid = this.idCard.valid && this.face.valid;
    this.face.manual = this.faceStatus.name === VerificationStatusNames.PENDING_MANUAL;
    this.idCard.manual = this.idCardStatus.name === VerificationStatusNames.PENDING_MANUAL;
    this.manual = this.face.manual && this.idCard.manual;
    this.auto = (this.faceStatus.name === VerificationStatusNames.PENDING_AUTO) || (this.idCardStatus.name === VerificationStatusNames.PENDING_AUTO);
    this.face.pendingFile = !this.face.valid && this.faceStatus.reason !== VerificationStatusReasons.UPLOADED;
    this.idCard.pendingFile = !this.idCard.valid && this.idCardStatus.reason !== VerificationStatusReasons.UPLOADED;
    this.pendingFile = this.face.pendingFile || this.idCard.pendingFile;
    this.face.pendingValidation = this.face.manual && this.faceStatus.reason === VerificationStatusReasons.UPLOADED;
    this.idCard.pendingValidation = this.idCard.manual && this.idCardStatus.reason === VerificationStatusReasons.UPLOADED;
    this.pendingValidation = (this.face.pendingValidation || this.face.valid) && this.idCard.pendingValidation;
    this.setStatus();
  }

  setStatus() {
    let status: CombinedStatusNameType;

    if (this.valid) {
      status = CombinedStatusNameType.VALID;
    } else if (this.auto) {
      status = CombinedStatusNameType.PENDING_AUTO
    } else if (this.pendingFile) {
      status = CombinedStatusNameType.PENDING_FILE;
    } else if (this.pendingValidation) {
      status = CombinedStatusNameType.PENDING_VALIDATION;
    } else {
      status = CombinedStatusNameType.UNKNOWN;
    }

    const reasons = [];

    if (this.faceStatus.reason) {
      reasons.push(this.faceStatus.reason);
    }

    if (this.idCardStatus.reason) {
      reasons.push(this.idCardStatus.reason);
    }

    this.status = {
      name: status,
      reasons: [...new Set(reasons)],
    };
  }

}