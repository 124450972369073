<mat-card appearance="outlined">
  <mat-card-title *ngIf="interactionMode !== modes.NEW">
    <div flex
         layout="row"
         layout-align="space-between center">
      <span class="edit-screen-card-title"
            [translate]="'global.contacts'">Contacts</span>
      <div flex
           layout="row"
           layout-align="end center">
        <button [disabled]="
            !client.id || (['USER_CONTACT_ADD'] | isUnauthorized | async)
          "
                type="button"
                mat-raised-button
                (click)="addNewContactFormGroup()">
          <mat-icon>add</mat-icon>
        </button>
        <button mat-icon-button
                [matMenuTriggerFor]="contactActions">
          <mat-icon>more_vert</mat-icon>
        </button>
      </div>
    </div>
  </mat-card-title>
  <mat-card-content>
    <div *ngFor="let formGroup of contactForms.controls; let i = index">
      <form *ngxPermissionsOnly="['USER_READ']"
            novalidate
            [formGroup]="formGroup"
            (ngSubmit)="onSaveContact(i)"
            flex
            layout="column"
            class="form-position-fixing">
        <div flex
             layout="row"
             layout-wrap
             layout-align="space-between center"
             [itfgPermissions]="formGroup?.id ? 'USER_CONTACT_ADD' : ''">
          <mat-form-field>
            <input itfgPhonePrefix
                   itfgTrimInput
                   matInput
                   type="text"
                   formControlName="phone"
                   placeholder="{{ 'global.phoneNumber' | translate }}" />
            <mat-label>{{ 'global.phoneNumber' | translate }}</mat-label>
            <itfg-click-to-call-button *ngIf="interactionMode !== modes.NEW"
                                               [contactId]="formGroup.get('id').value"
                                               [control]="formGroup.get('phone')"
                                               matSuffix
                                               [isInCreditEdit]="isInCreditEdit"
                                               [isInClientEdit]="isInClientEdit">
            </itfg-click-to-call-button>
            <itfg-search-in-event-log-button *ngIf="isInCreditEdit && interactionMode !== modes.NEW"
                                             [control]="formGroup.get('phone')"
                                             matSuffix>
            </itfg-search-in-event-log-button>
            <mat-error *ngIf="formGroup.get('phone').hasError('required')"
                       [translate]="'forms.required'">This field is required
            </mat-error>
            <mat-error *ngIf="formGroup.get('phone').hasError('notBulgarianCode')"
                       [translate]="'forms.bulgarianPhoneCode'">Missing 359
            </mat-error>
            <mat-error *ngIf="formGroup.get('phone').hasError('minlength')"
                       [translate]="'clients.phoneNumberMinLength'">Min length 10 symbols
            </mat-error>
            <mat-error *ngIf="formGroup.get('phone').hasError('pattern')"
                       [translate]="'forms.invalidCharactersMobile'">
              Phone number must contain only numbers.
            </mat-error>
            <mat-error *ngIf="formGroup.get('phone').hasError('maxlength')"
                       [translate]="'forms.mobileManyCharacters'">Too many characters
            </mat-error>
          </mat-form-field>
          <mat-form-field flex="33"
                          flex-gt-sm="25"
                          flex-gt-md="20">
            <mat-select disableOptionCentering="true"
                        [compareWith]="comparePhoneTypes"
                        formControlName="type"
                        placeholder="{{ 'p2p.selectType' | translate }}">
              <mat-option *ngFor="let type of contactsPhoneType"
                          [value]="type">
                {{ type.name }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="formGroup.get('type').hasError('required')"
                       [translate]="'forms.required'">This field is required
            </mat-error>
          </mat-form-field>
          <mat-form-field flex>
            <input matInput
                   itfgTrimInput
                   type="text"
                   formControlName="description"
                   #descriptionInputRef
                   placeholder="{{ 'clients.description' | translate }}" />
            <mat-label>{{ 'clients.description' | translate }}</mat-label>
            <mat-error *ngIf="formGroup.get('description').hasError('maxlength')"
                       [translate]="'forms.mobileManyCharacters'">Too many characters
            </mat-error>
          </mat-form-field>
          <mat-checkbox formControlName="active"
                        matTooltip="{{ 'clients.contacted' | translate }}"
                        matTooltipPosition="above">
          </mat-checkbox>
          <div *ngIf="interactionMode !== modes.NEW">
            <span class="font-weight-bold"
                  matTooltip="{{
                formGroup.get('id').value 
                  ? 'Актуализирано на ' +
                    (formGroup.get('updatedAt').value
                      | date: 'dd.MM.yyyy HH:mm') +
                    ' от ' +
                    formGroup.get('updatedBy').value.firstName +
                    ' ' +
                    formGroup.get('updatedBy').value.lastName
                  : ''
              }}"
                  matTooltipPosition="above">
              {{
              formGroup.get('id').value 
              ? (formGroup.get('updatedAt').value | date: 'dd.MM.yyyy')
              : (currDate | date: 'dd.MM.yyyy')
              }}
            </span>
          </div>
          <div>
            <button type="submit"
                    mat-icon-button
                    [disabled]="
                !saveBtnEnabled[i] ||
                ((['USER_CONTACT_ADD'] | isUnauthorized | async) &&
                  formGroup.get('id').value === null) ||
                ((['USER_CONTACT_EDIT'] | isUnauthorized | async) &&
                  formGroup.get('id').value)
              "
                    [ngClass]="{
                'mat-elevation-z2': true,
                'enabled-save-btn':
                  (saveBtnEnabled[i] &&
                    formGroup.get('id').value &&
                    !(['USER_CONTACT_EDIT'] | isUnauthorized | async)) ||
                  (saveBtnEnabled[i] &&
                    formGroup.get('id').value === null &&
                    !(['USER_CONTACT_ADD'] | isUnauthorized | async)),
                'disabled-save-btn':
                  !saveBtnEnabled[i] ||
                  ((['USER_CONTACT_ADD'] | isUnauthorized | async) &&
                    formGroup.get('id').value === null) ||
                  ((['USER_CONTACT_EDIT'] | isUnauthorized | async) &&
                    formGroup.get('id').value)
              }">
              <mat-icon matTooltip="{{ 'identityReports.saveAllValue' | translate }}"
                        matTooltipPosition="above">save</mat-icon>
            </button>
          </div>
          <div>
            <button *ngIf="interactionMode !== modes.NEW"
                    [disabled]="['USER_CONTACT_DELETE'] | isUnauthorized | async"
                    type="button"
                    mat-icon-button
                    (click)="deleteContactFormGroup(i)">
              <mat-icon>delete</mat-icon>
            </button>
          </div>
        </div>
      </form>
    </div>
  </mat-card-content>
</mat-card>

<mat-menu #contactActions="matMenu">
  <button mat-menu-item
          [disabled]="!client.employerId"
          (click)="openCompanyPhones()">
    <!-- *ngxPermissionsOnly="['OVERDUEPENALTY_DELETE']" -->
    <span>{{ 'clientRelations.company.companyPhonesButton' | translate }}</span>
  </button>
</mat-menu>
