import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isTruncated',
})
export class IsTruncated implements PipeTransform {
  transform(value: any): boolean {
    console.log(value);
    // TODO
    return false;
  }
}
