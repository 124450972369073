<div class="grid-box">
  <div *ngxPermissionsOnly="['CALLS_READ']"
       class="grid-item item-1">
    <mat-card class="reports-nav-card">
      <div class="reports-box">
        <span class="reports-heading">Справки</span>

        <div class="reports-controls">
          <div *ngxPermissionsOnly="['CALLS_READ']"
               class="button-with-label">
            <button mat-icon-button
                    (click)="navigate('communication/calls-history')">
              <mat-icon fontSet="material-symbols-outlined"
                        class="fill">call_log</mat-icon>
            </button>
            <span class="button-label">Обаждания</span>
          </div>

          <div *ngxPermissionsOnly="['CALLS_ADMIN_READ_ALL']"
               class="button-with-label">
            <button mat-icon-button
                    (click)="navigate('communication/calls-report')">
              <mat-icon fontSet="material-symbols-outlined"
                        class="fill">call_log</mat-icon>
            </button>
            <span class="button-label">Обаждания (справка)</span>
          </div>
        </div>
      </div>
    </mat-card>
  </div>

  <!-- <div class="grid-item"></div> -->
</div>