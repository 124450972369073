import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilterCardComponent } from './filter-card/filter-card.component';
import { MaterialModule } from '../../lib/material.module';
import { SharedModule } from '../../shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FilterButtonDirective } from './filter-button/filter-button.directive';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { FilterCardAddFilterComponent } from './filter-card-add-filter/filter-card-add-filter.component';
import { SearchOperationTranslationMapPipe } from './pipes/search-operation-translation-map.pipe';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { DragDropModule } from '@angular/cdk/drag-drop';
import {FilterCardSelectFiltersComponent} from './filter-card-select-filters/filter-card-select-filters.component';
import {FilterSettingsButtonComponent} from './filter-setings-button/filter-settings-button.component';
import { DataTablePipes } from '@app/core/components/data-table/data-table-pipes.module';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    TranslateModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMatSelectSearchModule,
    DragDropModule,
    MatButtonToggleModule,
    DataTablePipes,
  ],
  exports: [FilterCardComponent, FilterButtonDirective, FilterSettingsButtonComponent],
  declarations: [
    FilterCardComponent,
    FilterButtonDirective,
    FilterCardAddFilterComponent,
    FilterCardSelectFiltersComponent,
    FilterSettingsButtonComponent,
    SearchOperationTranslationMapPipe,
  ],
})
export class FilterModule {}
