import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
  ChangeDetectionStrategy,
} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ItfgDataTableColumn} from '@app/core/components/data-table/types/data-table.column';
import {CcrRequests} from '@core/types/ccr-requests-report';


@Component({
  selector: 'itfg-ccr-requests',
  templateUrl: './ccr-requests.component.html',
  styleUrls: ['./ccr-requests.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CcrRequestsComponent implements OnChanges, OnInit {
  @Input() report: CcrRequests;
  public tableData;
  public columns: ItfgDataTableColumn[];

  constructor(
    private translate: TranslateService,
  ) {
  }

  ngOnInit(): void {
    this.tableData = this.report?.rows;
    this.translate
      .get([
        'global.period',
        'ccrRequests.total',
        'ccrRequests.bankRequests',
        'ccrRequests.bankSources',
        'ccrRequests.nonbankRequests',
        'ccrRequests.nonbankSources',
      ])
      .subscribe(translation => {
        this.columns = [
          {
            name: 'key',
            label: translation['global.period'],
            width: {min: 100},
          },
          {
            name: 'value.total',
            label: translation['ccrRequests.total'],
          },
          {
            name: 'value.banks.value',
            label: translation['ccrRequests.bankRequests'],
          },
          {
            name: 'value.banks.entityCount',
            label: translation['ccrRequests.bankSources'],
          },
          {
            name: 'value.nonBanks.value',
            label: translation['ccrRequests.nonbankRequests'],
          },
          {
            name: 'value.nonBanks.entityCount',
            label: translation['ccrRequests.nonbankSources'],
          },

        ];
      });
  }


  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.report) {
      this.tableData = this.report?.rows;
    }
  }
}
