import { Pipe, PipeTransform } from '@angular/core';
import { Client } from '../../../core/types/client';
import { ClientRelationType } from '../../../core/types/client-relation';

@Pipe({
  name: 'relationTypeToClientProperty',
})
export class RelationTypeToClientPropertyPipe implements PipeTransform {
  transform(client: Client, relationType: ClientRelationType): string {
    let result;
    switch (relationType) {
      case ClientRelationType.ADDRESS:
        result = client.currentAddress.address;
        break;

      case ClientRelationType.CIVIL_ID:
        result = client.civilId;
        break;

      case ClientRelationType.EMAIL:
        result = client.email;
        break;

      case ClientRelationType.EMPLOYER_ID:
        result = client.employerId;
        break;

      case ClientRelationType.ID_CARD_NUMBER:
        result = client.idCardNumber;
        break;

      case ClientRelationType.IP:
        result = client.ip;
        break;

      case ClientRelationType.PHONE:
        result = client.mobile;
        break;

      default:
        result = '';
        break;
    }
    return result;
  }
}
