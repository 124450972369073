import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'transactionStatusTranslation',
})
export class TransactionStatusTranslationPipe implements PipeTransform {
  transform(status: string): string {
    return status ? 'transaction.status.' + status.toLowerCase() : '';
  }
}
