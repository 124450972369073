<!-- <div class="loading-bar" [ngClass]="{ 'hidden': !loader.loading }">
  <mat-progress-bar  mode="indeterminate" color="accent"></mat-progress-bar>
</div> -->
<!-- <div class="wrapper">
  <div class="overlay">
    <div class="spinner">
      <mat-spinner></mat-spinner>
    </div>
  </div>
</div> -->

<div class="spinner-wrapper">
  <!-- <mat-spinner></mat-spinner>  -->
  <mat-progress-bar  mode="indeterminate" color="accent"></mat-progress-bar>
</div>