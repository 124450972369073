<div class="collapsed">
  <form action="" autocomplete="off" class="edit-padding-10-20px credit-search-form" [formGroup]="searchCreditByIdForm"
        (ngSubmit)="findCreditById($event)">
    <div flex layout="row" layout-align="space-between center">
      <mat-form-field subscriptSizing="dynamic" flex>
        <input #creditIdInput cdkFocusInitial matInput type="number" formControlName="creditId" placeholder="{{'global.credit' | translate}}">
      <mat-label>{{'global.credit' | translate}}</mat-label>
      </mat-form-field>
      <button mat-raised-button
              type="submit"
              tabIndex="-1"
              matTooltip="{{ 'global.loadCredit' | translate }}"
              layout-align="end end">
        <mat-icon>arrow_forward</mat-icon>
      </button>
    </div>
  </form>
  <mat-divider></mat-divider>
  <form autocomplete="off" class="edit-padding-20px row-gap-10" [formGroup]="searchCreditForm"
        (ngSubmit)="findCredit($event)">
    <div flex layout="column" class="row-gap-10">
      <div flex layout="row" >
        <mat-form-field subscriptSizing="dynamic" flex="50">
          <input matInput type="text" (paste)="trimValue($event, 'civilId')" formControlName="civilId" placeholder="{{'global.civilId' | translate}}">
      <mat-label>{{'global.civilId' | translate}}</mat-label>
          <itfg-form-control-clear matSuffix [control]="searchCreditForm.get('civilId')"></itfg-form-control-clear>
          <mat-error
            *ngIf="searchCreditForm.get('civilId').hasError('minlength') && !searchCreditForm.get('civilId').hasError('onlyNumbers')"
            [translateParams]="{value: 6}" [translate]="'forms.minLength'">Min value error
          </mat-error>
          <mat-error
            *ngIf="searchCreditForm.get('civilId').hasError('maxlength') && !searchCreditForm.get('civilId').hasError('onlyNumbers')"
            [translateParams]="{value: 10}" [translate]="'forms.maxLength'">Max value error
          </mat-error>
          <mat-error
            *ngIf="searchCreditForm.get('civilId').hasError('onlyNumbers')"
            [translate]="'forms.valueNotValid'">Invalid value
          </mat-error>
        </mat-form-field>
        <mat-form-field subscriptSizing="dynamic" flex="50">
          <input matInput type="text" (paste)="trimValue($event, 'phone')" formControlName="phone" placeholder="{{'global.phone' | translate}}">
      <mat-label>{{'global.phone' | translate}}</mat-label>
          <itfg-form-control-clear matSuffix [control]="searchCreditForm.get('phone')"></itfg-form-control-clear>
          <mat-error
            *ngIf="searchCreditForm.get('phone').hasError('minlength')"
            [translateParams]="{value: 5}" [translate]="'forms.minLength'">Min value error
          </mat-error>
          <mat-error
            *ngIf="searchCreditForm.get('phone').hasError('maxlength')"
            [translateParams]="{value: 15}" [translate]="'forms.maxLength'">Max value error
          </mat-error>
        </mat-form-field>
      </div>
      <div flex layout="row" >
        <mat-form-field subscriptSizing="dynamic" flex>
          <input matInput type="text" (paste)="trimValue($event, 'email')" formControlName="email" placeholder="{{'global.emailContains' | translate}}">
      <mat-label>{{'global.emailContains' | translate}}</mat-label>
          <itfg-form-control-clear matSuffix [control]="searchCreditForm.get('email')"></itfg-form-control-clear>
          <mat-error
            *ngIf="searchCreditForm.get('email').hasError('minlength')"
            [translateParams]="{value: 3}" [translate]="'forms.minLength'">Min value error
          </mat-error>
          <mat-error
            *ngIf="searchCreditForm.get('email').hasError('maxlength')"
            [translateParams]="{value: 50}" [translate]="'forms.maxLength'">Max value error
          </mat-error>
        </mat-form-field>
      </div>
      <div flex layout="row" >
        <mat-form-field subscriptSizing="dynamic" flex="50">
          <input matInput type="text" formControlName="firstName" placeholder="{{'users.firstName' | translate}}"
                 (paste)="transformFirstNameValue($event)">
      <mat-label>{{'users.firstName' | translate}}</mat-label>
          <itfg-form-control-clear matSuffix [control]="searchCreditForm.get('firstName')"></itfg-form-control-clear>
          <mat-error
            *ngIf="searchCreditForm.get('firstName').hasError('minlength')"
            [translateParams]="{value: 2}" [translate]="'forms.minLength'">Min value error
          </mat-error>
          <mat-error
            *ngIf="searchCreditForm.get('firstName').hasError('maxlength')"
            [translateParams]="{value: 30}" [translate]="'forms.maxLength'">Max value error
          </mat-error>
        </mat-form-field>
        <mat-form-field subscriptSizing="dynamic" flex="50">
          <input matInput type="text" formControlName="middleName" placeholder="{{'users.middleName' | translate}}"
                 (paste)="transformMiddleNameValue($event)">
      <mat-label>{{'users.middleName' | translate}}</mat-label>
          <itfg-form-control-clear matSuffix [control]="searchCreditForm.get('middleName')"></itfg-form-control-clear>
          <mat-error
            *ngIf="searchCreditForm.get('middleName').hasError('minlength')"
            [translateParams]="{value: 2}" [translate]="'forms.minLength'">Min value error
          </mat-error>
          <mat-error
            *ngIf="searchCreditForm.get('middleName').hasError('maxlength')"
            [translateParams]="{value: 30}" [translate]="'forms.maxLength'">Max value error
          </mat-error>
        </mat-form-field>
      </div>
      <div flex layout="row" >
        <mat-form-field subscriptSizing="dynamic" flex>
          <input matInput type="text"  (paste)="trimValue($event, 'lastName')" formControlName="lastName" placeholder="{{'users.lastName' | translate}}">
      <mat-label>{{'users.lastName' | translate}}</mat-label>
          <itfg-form-control-clear matSuffix [control]="searchCreditForm.get('lastName')"></itfg-form-control-clear>
          <mat-error
            *ngIf="searchCreditForm.get('lastName').hasError('minlength')"
            [translateParams]="{value: 2}" [translate]="'forms.minLength'">Min value error
          </mat-error>
          <mat-error
            *ngIf="searchCreditForm.get('lastName').hasError('maxlength')"
            [translateParams]="{value: 30}" [translate]="'forms.maxLength'">Max value error
          </mat-error>
        </mat-form-field>
      </div>
      <div flex="50" layout="row" layout-align="space-between center">
        <button mat-stroked-button flex
                [ngStyle]="{ 'margin-right': '5px' }"
                type="reset"
                color="accent"
                matTooltip="{{ 'global.clear' | translate }}"
                (click)="resetCreditList()"
                layout-align="end end">
          <mat-icon>clear</mat-icon>
        </button>
        <button mat-stroked-button flex
                tabIndex="-1"
                matTooltip="{{ 'global.search' | translate }}"
                color="primary"
                layout-align="end end">
          <mat-icon>search</mat-icon>
        </button>
      </div>
    </div>
  </form>
  <mat-nav-list>
    <ng-container *ngFor="let credit of creditList" flex>
      <a routerLink="/credits/{{credit.id}}" mat-list-item class="list-item-height" (click)="closeSideDrawerMenu()">
          <itfg-credit-status-icon matListItemIcon [creditStatus]=credit.creditStatus></itfg-credit-status-icon>
          <!-- <mat-icon matListItemIcon>folder</mat-icon> -->
          <span>
            <div class="font-size-15">{{credit.product.name}} / {{credit.id}}</div>
            <div class="font-size-14 color-gray">
              <span>{{credit.user | clientFullName}}</span>
              <span *ngIf="credit.user.civilId"> / {{credit.user.civilId}}</span>
            </div>
            <div class="font-size-14 color-gray">
              <span *ngIf="credit.user.email">{{credit.user.email}}</span>
              <span *ngIf="credit.user.mobile"> / {{credit.user.mobile}}</span>
            </div>
          </span>
      </a>
    </ng-container>
    <div class="edit-padding-20px" flex *ngIf="!isData" [translate]="'credits.noResultsFound'">No results found</div>
  </mat-nav-list>
</div>
