import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FilterValueListService, FilterValue } from '../filter/types';
import { ZoneService } from '../../core/services/zone.service';
import { Zone } from '../../core/types/zone';
import { Office } from '../../core/types/office';

@Injectable()
export class ZoneValueListService implements FilterValueListService {
  constructor(private zones: ZoneService) {}

  getFilterValues$(): Observable<FilterValue[]> {
    return this.zones.getZoneList().pipe(
      map((zoneList: Zone[]): FilterValue[] => {
        return zoneList.map(
          (zone: Office): FilterValue => {
            return {
              value: zone.id,
              displayText: zone.name,
            };
          }
        );
      })
    );
  }
}
