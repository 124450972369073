import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isLogIssuePipe',
})
export class isLogIssuePipe implements PipeTransform {
  transform(log: string, logIssues: string[]): boolean {
    return logIssues.some(x => x === log);
  }
}
