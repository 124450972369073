import { Injectable } from '@angular/core';
import { ClientCommunicationRequestService } from './client-communication-request.service';
import { RequestService } from './request.service';

@Injectable()
export class TopicsService {
  request: RequestService;

  constructor(request: ClientCommunicationRequestService) {
    this.request = request;
  }

  getTree$() {
    return this.request.get(['topics', 'tree']);
  }

  getTopics$() {
    return this.request.get(['topics']);
  }

  addTopics$(DTO) {
    return this.request.post(['topics'], { body: DTO });
  }

  editTopics$(DTO) {
    return this.request.post(['topics', DTO.id], { body: DTO });
  }

  deleteTopic$(id) {
    return this.request.delete(['topics', id]);
  }
}
