import {
  forkJoin as observableForkJoin,
  Observable,
  Subject,
  BehaviorSubject,
} from 'rxjs';

import { share, mergeMap, map, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { RequestService } from './request.service';
import { Client } from '../types/client';
import { Page } from '../types/page';
import { Education } from '../types/education';
import { EmploymentType } from '../types/employment-type';
import { HouseholdType } from '../types/household-type';
import { PoliceDepartment } from '../types/police-department';
import { ClientStatus } from '../types/client-status';
import { BankAccount } from '../types/bank-account';
import { ClientDocument } from '../types/client-document';
import { HttpHeaders } from '@angular/common/http';
import {
  ClientRelationGroup,
  ClientRelation,
  ClientRelationType,
  PopulatedClientRelationGroup,
  ClientRelationGroupByType,
} from '../types/client-relation';
import { Utils } from '../utils/utils';
import { EntityHistoryFilter } from '../types/entity-history-filter';
import { EntityHistory, EntityHistoryService } from '../types/entities';
import { EventLogAudit } from '../types/event-log.type';
import { HistoryCredit } from '../types/client-credits-history';
import { of } from 'rxjs';
import { NgxPermissionsService } from 'ngx-permissions';
import { MaritalStatus } from '../types/marital-status';
import { SearchOperations } from '../types/search-criterium';
import {VerificationStatus} from '@core/verification-status-type';
import { BrandCodes } from '../types/brand';

@Injectable()
export class ClientService implements EntityHistoryService {
  static readonly clientRelationTypeToPropertyPathMap: {
    [key: string]: string;
  } = {
    [ClientRelationType.PHONE]: 'mobile',
    [ClientRelationType.CIVIL_ID]: 'civilId',
    [ClientRelationType.EMAIL]: 'email',
    [ClientRelationType.ID_CARD_NUMBER]: 'idCardNumber',
    [ClientRelationType.ADDRESS]: 'currentAddress.address',
    [ClientRelationType.EMPLOYER_ID]: 'employerId',
    [ClientRelationType.IP]: 'ip',
  };
  request: RequestService;
  ngxPermissionService: NgxPermissionsService;
  onClientDataChange = new Subject<Client>();
  onClientOcupationDataChange = new Subject<Client>();
  onClientSaved = new Subject<Client>();
  onDataChange = new Subject<any>();
  onBankAccoutDataChange = new Subject<any>();
  onClientRelatedPeopleTabClicked = new Subject<boolean>();
  clientBankAccounts$ = new Subject<BankAccount[]>();
  clientStatuses$ = new BehaviorSubject<ClientStatus[]>([]);
  clientCreditHistory$ = new BehaviorSubject<HistoryCredit[]>([]);
  employmentTypesSubject$ = new BehaviorSubject<EmploymentType[]>([]);
  educationTypesSubject$ = new BehaviorSubject<Education[]>([]);
  maritalStatusesSubject$ = new BehaviorSubject<MaritalStatus[]>([]);

  constructor(
    request: RequestService,
    ngxPermissionService: NgxPermissionsService
  ) {
    this.request = request;
    this.ngxPermissionService = ngxPermissionService;
  }

  getClientByMobile(mobile: string) {
    const params = {
      criteria: JSON.stringify([
        { key: 'mobile', operation: SearchOperations.EQUALS, value: mobile },
      ]),
    };

    return this.request.get(['users'], { params });
  }

  saveClient(client: Client): Observable<Client> {
    return this.request.post(['users'], { body: client });
  }

  deleteClient(id: number | string): Observable<any> {
    return this.request.delete(['users', id]);
  }

  getIncompleteUsers$(): Observable<Client[]> {
    return this.request.get(['users', 'incomplete']);
  }

  getClientRelationsGroups$(
    id: number | string,
    options?: any
  ): Observable<ClientRelationGroup[]> {
    return this.request.get(['users', id, 'matches'], options);
  }

  getClientRelations(
    id: number | string,
    options?: any
  ): Observable<ClientRelation[]> {
    const result$ = <Observable<ClientRelation[]>>(
      this.getClientRelationsGroups$(id, options).pipe(
        mergeMap(this.mapClientRelationGroupsToClientRelations.bind(this))
      )
    );
    return result$;
  }

  mapClientRelationGroupsToClientRelations(
    relationGroups: ClientRelationGroup[]
  ): Observable<ClientRelation[]> {
    const clientRelationsCache$: {
      [key: number]: Observable<ClientRelation>;
    } = {};
    const clientRelations$: Observable<ClientRelation>[] = [];
    relationGroups.forEach(
      (
        group: ClientRelationGroup,
        groupIndex: number,
        groups: ClientRelationGroup[]
      ) => {
        group.users.forEach((clientId: number) => {
          if (!clientRelationsCache$[clientId]) {
            const clientRelation$ = this.getClientById(clientId).pipe(
              map(
                (client: Client): ClientRelation => {
                  return {
                    to: client,
                    type: group.type,
                    description: group.description,
                    value: Utils.deepValue(
                      client,
                      ClientService.clientRelationTypeToPropertyPathMap[
                        group.type
                      ]
                    ),
                  };
                }
              )
            );
            clientRelationsCache$[clientId] = clientRelation$;
            clientRelations$.push(clientRelation$);
          }
        });
      }
    );
    return observableForkJoin(...clientRelations$);
  }

  getClientRelationGroupsByType$(
    id: number | string,
    options?: any
  ): Observable<ClientRelationGroupByType[]> {
    return this.getClientRelationsGroups$(id, options).pipe(
      map((groups: ClientRelationGroup[]) => {
        const result: ClientRelationGroupByType[] = [];
        groups.forEach((group: ClientRelationGroup) => {
          if (!result.find(g => g.type === group.type)) {
            result.push({ type: group.type, matches: [] });
          }
          result.find(g => g.type === group.type).matches.push(group);
        });
        return result;
      })
    );
  }

  getClientRelationsByGroup(
    id: number | string,
    options?: any
  ): Observable<PopulatedClientRelationGroup[]> {
    const result$ = this.getClientRelationsGroups$(id, options).pipe(
      mergeMap(
        (
          relationGroups: ClientRelationGroup[]
        ): Observable<PopulatedClientRelationGroup[]> => {
          const clientsCache$: { [key: number]: Observable<Client> } = {};
          const populatedClientRelationGroups$: Observable<
            PopulatedClientRelationGroup
          >[] = [];
          relationGroups.forEach(
            (
              group: ClientRelationGroup,
              groupIndex: number,
              groups: ClientRelationGroup[]
            ) => {
              const clients$: Observable<Client>[] = [];
              group.users.forEach((clientId: number) => {
                if (clientsCache$[clientId]) {
                  clients$.push(clientsCache$[clientId]);
                } else {
                  const client$ = this.getClientById(clientId).pipe(share());
                  clientsCache$[clientId] = client$;
                  clients$.push(client$);
                }
              });
              const populatedClientRelationGroup$ = observableForkJoin(
                ...clients$
              ).pipe(
                map(
                  (clients: Client[]): PopulatedClientRelationGroup => {
                    return {
                      description: group.description,
                      type: group.type,
                      users: clients,
                    };
                  }
                )
              );
              populatedClientRelationGroups$.push(
                populatedClientRelationGroup$
              );
            }
          );
          return observableForkJoin(...populatedClientRelationGroups$);
        }
      )
    );
    return result$;
  }

  getClientById(id: number | string): Observable<Client> {
    return this.request.get(['users', id]);
  }

  getEducationList(): Observable<Education[]> {
    return this.request.get(['users', 'educations']);
  }

  getEmploymentTypeList(): Observable<EmploymentType[]> {
    return this.request.get(['users', 'employment-types']);
  }

  getHouseholdTypeList(): Observable<HouseholdType[]> {
    return this.request.get(['users', 'household-types']);
  }

  getPoliceDepartmentList(): Observable<PoliceDepartment[]> {
    return this.request.get(['users', 'police-departments']);
  }

  getClientList(options?: any): Observable<Page<Client>> {
    return this.request.get(['users'], { params: options });
  }

  findClientList$(options?: any): Observable<Client[]> {
    return this.request.get(['users', 'find'], { params: options });
  }

  getClientStatusList(): Observable<ClientStatus[]> {
    return this.request.get(['users', 'statuses']);
  }

  getClientBankAccounts(id: number | string): Observable<BankAccount[]> {
    const readPermission = this.ngxPermissionService.getPermission(
      'USER_BANK_ACCOUNT_READ'
    );

    if (!readPermission) {
      return of([]);
    }
    return this.request.get(['users', id, 'bank-accounts']);
  }

  getClientBankAccountById(
    clientId: number | string,
    accountId: number | string
  ): Observable<BankAccount[]> {
    return this.request.get(['users', clientId, 'bank-accounts', accountId]);
  }

  saveClientBankAccount(
    clientId: number | string,
    bankAccount: any,
    bankAccountFile: any
  ): Observable<BankAccount> {
    let headers = new HttpHeaders();

    headers = headers.set('Content-Type', 'multipart/form-data');
    const queryString =
      '?' + 'iban=' + bankAccount.iban + '&verified=' + bankAccount.verified;
    return this.request.post(
      ['users', clientId, 'bank-accounts', queryString],
      {
        body: bankAccountFile,
      }
    );
  }

  updateClientBankAccount(
    clientId: number | string,
    bankAccountId: number | string,
    bankAccount: any,
    bankAccountFile?: any
  ) {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'multipart/form-data');
    const queryString =
      '?' + 'iban=' + bankAccount.iban + '&verified=' + bankAccount.verified;
    if (bankAccountFile) {
      return this.request.post(
        ['users', clientId, 'bank-accounts', bankAccountId, queryString],
        {
          body: bankAccountFile,
        }
      );
    } else {
      return this.request.post([
        'users',
        clientId,
        'bank-accounts',
        bankAccountId,
        queryString,
      ]);
    }
  }

  deleteClientBankAccount(
    clientId: number | string,
    accountId: number | string
  ): Observable<BankAccount[]> {
    return this.request.delete(['users', clientId, 'bank-accounts', accountId]);
  }

  getClientDocumentList(
    clientId: number | string
  ): Observable<ClientDocument[]> {
    const readPermission = this.ngxPermissionService.getPermission(
      'USER_DOCUMENT_READ'
    );

    if (!readPermission) {
      return of([]);
    }
    return this.request.get(['users', clientId, 'documents']);
  }

  addClientDocument(
    clientId: number | string,
    clientFile: any
  ): Observable<ClientDocument> {
    return this.request.post(['users', clientId, 'documents'], {
      body: clientFile,
    });
  }

  deleteClientDocument(
    clientId: number | string,
    documentId: number | string
  ): Observable<any> {
    return this.request.delete(['users', clientId, 'documents', documentId]);
  }

  getClientDocumentById(
    clientId: number | string,
    documentId: number | string
  ): Observable<ClientDocument> {
    return this.request.get(['users', clientId, 'documents', documentId]);
  }

  renderClientDocument(
    clientId: number | string,
    documentId: number | string
  ): Observable<ClientDocument> {
    return this.request.get(
      ['users', clientId, 'documents', documentId, 'content'],
      { responseType: 'blob' }
    );
  }

  rotateClientDocument(
    clientId: number | string,
    documentId: number | string,
    clockwise: boolean
  ): Observable<any> {
    return this.request.post(
      ['users', clientId, 'documents', documentId, 'rotation'],
      { headers: { 'Content-Type': 'application/json' }, body: clockwise }
    );
  }

  getEducationTypes(): Observable<Education[]> {
    return this.request
      .get(['users', 'educations'])
      .pipe(
        tap(educationTypes => this.educationTypesSubject$.next(educationTypes))
      );
  }

  getEmploymentTypes(): Observable<EmploymentType[]> {
    return this.request
      .get(['users', 'employment-types'])
      .pipe(
        tap(employmentTypes =>
          this.employmentTypesSubject$.next(employmentTypes)
        )
      );
  }

  getMaritalStatuses(): Observable<any> {
    return this.request.get(['users', 'marital-statuses']);
  }

  getClientHistory(
    filter: EntityHistoryFilter
  ): Observable<EntityHistory<any>> {
    return this.request.get(['users', 'history'], {
      params: {
        filter: filter.getDTO(),
      },
    });
  }

  getEntityHistory(filter: EntityHistoryFilter) {
    return this.getClientHistory(filter);
  }

  getEventLog$(
    clientId: number | string,
    creditId?: number | string
  ): Observable<EventLogAudit[]> {
    const options = creditId ? { params: { creditId } } : {};
    return this.request.get(['users', clientId, 'event-log'], options);
  }

  getClientCreditsHistory$(clientId: number, limit: number = 1000): Observable<HistoryCredit[]> {
    return this.request.get(['users', clientId, 'credits'], {params: {limit}});
  }

  getClientLastLogin$(userId: number | string): Observable<any> {
    return this.request.get(['users', userId, 'logins', 'last']);
  }

  idCardVerified$(userId: number, verified: boolean | null): Observable<any> {
    return this.request.post(['users', userId, 'id-card-verified'], {
      headers: { 'Content-Type': 'application/json' },
      body: verified,
    });
  }

  addIdentityDocument$(
    clientId: number | string,
    type: string,
    clientFile: any
  ): Observable<ClientDocument> {
    return this.request.post(['users', clientId, 'documents', type], {
      body: clientFile,
    });
  }
  changeVerificationStatus$ (civilId: number | string, type: string, brandCode: BrandCodes, status: VerificationStatus): Observable<any> {
    return this.request.post(['users', civilId, type + '-status', brandCode], {
      body: status,
    });
  }
  getVerificationStatuses$(type: string) {
    return this.request.get(['users',  type + '-statuses']);
  }
}
