import { Directive, ElementRef, Input, HostListener, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { MatTooltip, TooltipPosition } from '@angular/material/tooltip';

@Directive({
  selector: '[itfgOverflowTooltip]',
})
export class TooltipOnOverflowDirective implements AfterViewInit {
  @Input() tooltipText: string;
  @Input() tooltipPosition: TooltipPosition;

  constructor(
    private el: ElementRef, 
    private tooltip: MatTooltip, 
    private changeDetectorRef: ChangeDetectorRef) {}

    ngAfterViewInit() {
      this.changeDetectorRef.detectChanges();
    }

  @HostListener('mouseover') mouseover() {
    const element = this.el.nativeElement.firstElementChild || this.el.nativeElement;
    const difference = element.scrollWidth - element.clientWidth;
    const isTextOverflowing = difference > 2;
    if (isTextOverflowing) {
        this.tooltip.message = this.tooltipText;
        this.tooltip.position = this.tooltipPosition;
        this.tooltip.show();
    }
  }

  @HostListener('mouseleave') mouseleave() {
    this.tooltip.hide();
  }
}