import { Pipe, PipeTransform } from '@angular/core';
import {
  CreditStatusNames,
  CreditStatus,
} from '../../../core/types/credit-status';

@Pipe({
  name: 'creditStatusToTranslationKey',
})
export class CreditStatusToTranslationKeyPipe implements PipeTransform {
  static readonly creditStatusTranslationMap: { [key: string]: string } = {
    [CreditStatusNames.NEW]: 'credits.creditStatusList.new',
    [CreditStatusNames.APPROVED]: 'credits.creditStatusList.approved',
    [CreditStatusNames.CONFIRMED]: 'credits.creditStatusList.confirmed',
    [CreditStatusNames.LOSS]: 'credits.creditStatusList.loss',
    [CreditStatusNames.OVERDUE]: 'credits.creditStatusList.overdue',
    [CreditStatusNames.REGULAR]: 'credits.creditStatusList.regular',
    [CreditStatusNames.REJECTED]: 'credits.creditStatusList.rejected',
    [CreditStatusNames.REPAID]: 'credits.creditStatusList.repaid',
    MONEYSENT: 'credits.creditStatusList.moneySent',
  };

  transform(creditStatus: CreditStatus): string {
    return CreditStatusToTranslationKeyPipe.creditStatusTranslationMap[
      creditStatus.name
    ];
  }
}
