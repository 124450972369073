import { PipeTransform, Pipe } from '@angular/core';

@Pipe({ name: 'objectToArray' })
export class ObjectToArrayPipe implements PipeTransform {
  transform(objectToParse, args?: string[]): any {
    const result = [];
    Object.keys(objectToParse).forEach((key, index: number, array: any[]) => {
      result.push({
        key: key,
        value: objectToParse[key],
      });
    });
    return result;
  }
}
