import { Pipe, PipeTransform } from '@angular/core';
import { AbstractControl, UntypedFormArray } from '@angular/forms';

@Pipe({
  name: 'toFormArray',
})
export class AbstractControlToFormArrayPipe implements PipeTransform {
  transform(abstractControl: AbstractControl): UntypedFormArray {
    return abstractControl as UntypedFormArray;
  }
}
