import {
  Directive,
  ElementRef,
  OnDestroy,
  AfterContentInit,
  Input,
} from '@angular/core';

@Directive({
  selector: '[itfgStickyTabs]',
})
export class StickyTabsDirective implements AfterContentInit, OnDestroy {
  @Input() stickyTabsPageElementSelector: string;
  @Input() stickyTabsDocumentOffsetSelector: string;

  private pageElement: Element;
  private onPageScrollCallback;
  private matTabHeader;

  constructor(private elementRef: ElementRef) {}

  checkTabsAndPageIntersection(): boolean {
    return false;
  }

  ngAfterContentInit() {
    const interval = setInterval(() => {
      this.pageElement = document.querySelector(
        this.stickyTabsPageElementSelector
      );
      if (this.pageElement !== null) {
        this.onPageScrollCallback = this.onPageScroll.bind(this);
        this.pageElement.addEventListener('scroll', this.onPageScrollCallback);
        clearInterval(interval);
      }
    }, 200);
  }

  ngOnDestroy() {
    if (this.onPageScrollCallback) {
      this.pageElement.removeEventListener('scroll', this.onPageScrollCallback);
    }
  }

  onPageScroll(event) {
    const boundingRectangle = this.elementRef.nativeElement.getBoundingClientRect();
    const offsetNodeList = document.querySelectorAll(
      this.stickyTabsDocumentOffsetSelector
    );
    const offsetElements = [];
    for (let i = 0; i < offsetNodeList.length; i++) {
      offsetElements.push(offsetNodeList[i]);
    }
    const scrollHeights = offsetElements.map(
      offsetElement => offsetElement.scrollHeight
    );
    const offsetHeight = scrollHeights.reduce(
      (accumulator: number, value: number) => accumulator + value,
      0
    );
    const tabHeader = this.elementRef.nativeElement.querySelector(
      'mat-tab-header'
    );
    if (boundingRectangle.top - offsetHeight <= 0) {
      offsetElements.forEach(element => (element.style.zIndex = 3));
      tabHeader.style.transform = `translateY(${offsetHeight -
        boundingRectangle.top -
        1}px)`;
      tabHeader.classList.add('sticky-tabs-applied');
    } else {
      tabHeader.classList.remove('sticky-tabs-applied');
      tabHeader.style.transform = 'initial';
      offsetElements.forEach(element => (element.style.zIndex = ''));
    }
  }
}
