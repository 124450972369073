import {Component, OnDestroy} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {debounceTime, takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {CcrRequestsPeriods, CcrRequests, CcrRequestsRowValue, CcrRequestsRow} from '@core/types/ccr-requests-report';
import {ItfgDataTableColumn} from '@core/components/data-table/types/data-table.column';
import {TranslateService} from '@ngx-translate/core';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'itfg-client-ccr-parse-requests-report',
  templateUrl: './client-ccr-parse-requests-report.component.html',
  styleUrl: './client-ccr-parse-requests-report.component.scss'
})
export class ClientCcrParseRequestsReportComponent implements OnDestroy {

  public requestsForm: UntypedFormGroup;
  public isValidatedByRegex: boolean;
  public requests: CcrRequests;
  public columns: ItfgDataTableColumn[];
  public _unsubscribe = new Subject<void>();


  constructor(public dialogRef: MatDialogRef<ClientCcrParseRequestsReportComponent>,
              private fb: UntypedFormBuilder,
              private translate: TranslateService) {

    this.isValidatedByRegex = false;
    this.requestsForm = fb.group({
      reportText: '',
    });
    this.requestsForm
      .get('reportText')
      .valueChanges.pipe(
      debounceTime(500),
      takeUntil(this._unsubscribe)
    )
      .subscribe(change => {
        this.parseRequests(change);
      });
    this.requests = {rows: []};
  }

  ngOnInit(): void {

    this.translate
      .get([
        'global.period',
        'ccrRequests.bankRequests',
        'ccrRequests.bankSources',
        'ccrRequests.nonbankRequests',
        'ccrRequests.nonbankSources',
      ])
      .subscribe(translation => {
        this.columns = [
          {
            name: 'key',
            label: translation['global.period'],
            width: {min: 100},
          },
          {
            name: 'value.banks.value',
            label: translation['ccrRequests.bankRequests'],
          },
          {
            name: 'value.banks.entityCount',
            label: translation['ccrRequests.bankSources'],
          },
          {
            name: 'value.nonBanks.value',
            label: translation['ccrRequests.nonbankRequests'],
          },
          {
            name: 'value.nonBanks.entityCount',
            label: translation['ccrRequests.nonbankSources'],
          },

        ];
      });
  }

  onSubmit(event) {
    this.dialogRef.close(this.requests);
  }

  parseRequests(change) {
    const ccrRequests: CcrRequests = {rows: []};
    this.isValidatedByRegex = false;

    const regex = /Последните (\d+ дни): (\d+) проверки от (\d+) банки и (\d+) проверки от (\d+) ФИ/gm;
    let match;
    do {
      match = regex.exec(change);
      if (match) {
        const requestsRowValue = new CcrRequestsRowValue();
        requestsRowValue.banks = {};
        const days = match[1];
        requestsRowValue.banks.value = match[2];
        requestsRowValue.banks.entityCount = match[3];
        requestsRowValue.nonBanks = {};
        requestsRowValue.nonBanks.value = match[4];
        requestsRowValue.nonBanks.entityCount = match[5];
        requestsRowValue.total = Number(requestsRowValue.banks.value) + Number(requestsRowValue.nonBanks.value);

        const ccrRequestsRow: CcrRequestsRow = {
          key: CcrRequestsPeriods[days],
          value: requestsRowValue
        };
        ccrRequests.rows.push(ccrRequestsRow);
        this.isValidatedByRegex = true;
      }
    } while (match);
    this.requests = ccrRequests;
  }
  onNoClick() {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    this._unsubscribe.next();
    this._unsubscribe.complete();
  }
}
