<div class="toolbar-container"
     layout="row"
     layout-align="center"
     [ngClass]="{ collapsed: collapsed }"
     flex>
  <div class="toolbar"
       layout="column"
       layout-align="space-between center">
    <div layout="column">
      <!-- Search -->
      <a mat-icon-button
         (click)="toggleLayout()"
         *ngxPermissionsOnly="['CREDIT_READ_ALL']">
        <mat-icon>search</mat-icon>
      </a>

      <!-- Language -->
      <a mat-icon-button
         [matMenuTriggerFor]="changeLanguageMenu">
        <mat-icon>public</mat-icon>
      </a>

      <!-- Language Menu -->
      <mat-menu #changeLanguageMenu="matMenu"
                xPosition="after"
                [overlapTrigger]="false">
        <button mat-menu-item
                (click)="swichLanguage('bg')">
          <span [translate]="'global.bulgarian'">Bulgarian</span>
        </button>
        <button mat-menu-item
                (click)="swichLanguage('en')">
          <span [translate]="'global.english'">English</span>
        </button>
      </mat-menu>

      <!-- Credit calculator -->
      <a mat-icon-button
         *ngxPermissionsOnly="['CREDIT_READ_ALL']"
         (click)="openProductCalculator()">
        <mat-icon fontSet="fa-solid"
                  fontIcon="fa-calculator"></mat-icon>
      </a>
    </div>

    <!-- itfg-navigation-menu -->
    <div class="navigation-links"
         *ngIf="collapsed || !mode || mode === 'submenu'"
         [@toolbarCollapse]>
      <ng-content></ng-content>
    </div>

    <div *ngIf="!collapsed && mode === 'menu'"
         (click)="backArrowClicked()">
      <a mat-icon-button>
        <mat-icon>arrow_back</mat-icon>
      </a>
    </div>

    <div layout="column">
      <!-- Call hub -->
      <button *ngxPermissionsOnly="['CALLS_READ']"
              mat-icon-button
              (click)="handleToggleLeftSideNav(DrawerModeType.CallHub, 'side')"
              [matTooltip]="socketService.initializingSocketConnection ? ('communication.calls' | translate) : (!socketService.socketConnected ? 'Проблем с връзката. Моля, презаредете страницата.' : ('communication.calls' | translate))"
              matTooltipPosition="right"
              [@jiggle]="callNotificationAnimation">
        <mat-icon fontSet="material-symbols-outlined"
                  [matBadge]="!socketService.socketConnected && !socketService.initializingSocketConnection ? '!' : (hasCallNotifications ? '&#8203;' : '')"
                  [matBadgeHidden]="(socketService.socketConnected || socketService.initializingSocketConnection) && !hasCallNotifications"
                  matBadgePosition="after"
                  matBadgeSize="small"
                  [matBadgeColor]="'warn'">deskphone</mat-icon>
      </button>

      <!-- Notifications -->
      <itfg-notifications-button></itfg-notifications-button>

      <!-- Profile / Logout -->
      <a mat-icon-button
         [matMenuTriggerFor]="profileMenu"
         [matTooltip]="sessionService.currentlyLoggedOperator.email"
         matTooltipPosition="right">
        <mat-icon>person</mat-icon>
      </a>

      <!-- Profile Menu -->
      <mat-menu #profileMenu="matMenu"
                [overlapTrigger]="false">
        <button mat-menu-item
                [routerLink]="['/me', 'details']">
          <mat-icon>info</mat-icon>
          <span [translate]="'global.profile'">Profile</span>
        </button>
        <button mat-menu-item
                (click)="logout()">
          <mat-icon>exit_to_app</mat-icon>
          <span [translate]="'global.logout'">Logout</span>
        </button>
      </mat-menu>
    </div>
  </div>
</div>
