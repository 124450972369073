export enum EventLogMode {
  CREDIT = 'CREDIT',
  CLIENT = 'CLIENT',
  PBX = 'PBX',
}

export enum EventLogHistoryType {
  CREDIT = 'CREDIT',
  PHONE = 'PHONE',
}

export enum EventLogSubheader {
  FILTERS = 'FILTERS',
  PBX = 'PBX',
  NOTES = 'NOTES',
}
