import { Injectable } from '@angular/core';
import { Observable} from 'rxjs';
import { EmailTemplate, Email } from '../types/email';
import { SmsTemplate, Sms } from '../types/sms';
import { Page } from '../types/page';
import { ClientCommunicationRequestService } from './client-communication-request.service';

@Injectable()
export class TemplatesService {
  
  constructor(
    private request: ClientCommunicationRequestService,
    ) {
    this.request = request;
  }

  getSMStemplates(options?: any): Observable<Page<SmsTemplate>> {
    return this.request.get(['sms-templates'], { params: options });
  }

  getSMStemplatesByUser(userId: number): Observable<SmsTemplate[]> {
    return this.request.get(['sms-templates','user', userId]);
  }

  toggleSMStemplateActive(id: number | string): Observable<any> {
    return this.request.post(['sms-templates', id, 'active', 'toggle']);
  }

  // addOrUpdateSMStemplate(smsTemplate): Observable<SmsTemplate> {
  //   return this.request.post(['sms-templates'], { body: smsTemplate });
  // }

  sendSmsTemplate(smsTemplateData): Observable<Sms> {
    const { template , ...body } = smsTemplateData;
    return this.request.post(['sms', 'send-test'], { body: body, params: { code:template} });
  }  


  getEmailTemplates(options?: any): Observable<Page<EmailTemplate>> {
    return this.request.get(['email-templates'], { params: options });
  }

  getEmailTemplatesById(id: string | number): Observable<EmailTemplate> {
    return this.request.get(['email-templates', id]);
  }

  // addOrUpdateEmailtemplate(emailTemplate): Observable<EmailTemplate> {
  //   return this.request.post(['email-templates'], { body: emailTemplate });
  // }

  sendEmailTemplate(emailTemplateData): Observable<Email> { 
    const { template , ...body } = emailTemplateData;
    return this.request.post(['email', 'send-test'], { body: body, params: { code:template} });
  }

  toggleEmailTemplateActive(id: number | string): Observable<any> {
    return this.request.post(['email-templates', id, 'active', 'toggle']);
  }
}
