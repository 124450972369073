import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'itfg-error-dialog',
  templateUrl: './error-dialog.component.html',
  styleUrls: ['./error-dialog.component.scss'],
})
export class ErrorDialogComponent implements OnInit {
  dialogRef: MatDialogRef<ErrorDialogComponent>;
  errorData: any;
  rawError: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) errorData: any,
    dialogRef: MatDialogRef<ErrorDialogComponent>
  ) {
    this.dialogRef = dialogRef;
    this.errorData = errorData;
    this.rawError = this.errorData.errorMessage;
    this.errorData.errorMessage = JSON.stringify(
      this.errorData.errorMessage,
      null,
      2
    );
  }

  ngOnInit() {
    console.log(this.rawError);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
