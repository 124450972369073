<div *ngIf="client?.civilId && client?.id" class="top-padding-16">
  <h3 class="edit-screen-card-title">
    {{ 'personData.personDataStatusReport' | translate }}
  </h3>
  <div
    flex
    layout="row"
    layout-wrap
    layout-align="space-between stretch"
  >
    <div flex="100" flex-gt-md="75" flex-gt-sm="100" flex-gt-xs="100">
      <itfg-reports-info-panel
        [listData]="personDataListData"
        [reportData]="personDataData"
        [reportTime]="requestTime"
        [requesterName]="requesterName"
        [metaStatusCode]="''"
        [requestTime]="requestTime"
        (dataChanged)="personDataDataChanged($event)"
      ></itfg-reports-info-panel>
    </div>

    <div flex layout="row" layout-align="end start" [style.min-height.px]="65">
      <itfg-client-differences-button *ngIf="personDataListData?.length > 0"
                                      [config]="personDataConfig"
      >
      </itfg-client-differences-button>
      <button
        mat-raised-button
        [disabled]="['MARITAL_ADD'] | isUnauthorized | async"
        (click)="getNewPersonDataReport()"
      >
        {{ 'personData.newReport' | translate }}
      </button>
    </div>
  </div>
  <div
    *ngIf="personDataListData?.length === 0 && !isReport"
    flex
    layout="row"
    layout-align="start"
    class="left-padding-16"
  >
    <h3
      class="edit-screen-card-title"
      [translate]="'personData.noPersonDataData'"
    >
      No reports
    </h3>
  </div>
  <div *ngIf="isReport">
    <ng-container [ngSwitch]="requestStatus">
      <ng-container *ngSwitchCase="'success'">
        <ng-container *ngIf="personDataListData?.length > 0 && !personDataReportData?.PersonNames?.FirstName">
          <h4 layout="row" layout-align="start">
            {{
            'personData.noPersonDataDataFound'
              | translate
            }}
          </h4>
        </ng-container>
        <ng-container *ngIf="personDataListData?.length > 0 && personDataReportData?.PersonNames?.FirstName">
          <div
            flex
            layout="row"
            layout-wrap
            layout-align="space-between stretch"
          >
            <mat-card appearance="outlined" flex="100">
              <mat-card-content
                flex="100"
                layout="row"
                layout-wrap
                layout-align="space-around center"
              >
                <div
                  flex="100"
                  layout="row"
                  layout-wrap
                  layout-align="space-around center"
                >
                  <div flex="100" layout="row"
                       layout-align="center center" class="column-gap-10">
                    <mat-form-field flex="33">
                      <input
                        matInput
                        readonly
                        type="text"
                        [value]="personDataReportData?.PersonNames?.FirstName || ''"
                        placeholder="{{ 'identityReports.firstName' | translate }}"
                        flex="100"
                        matTooltip="{{
                  personDataReportData?.PersonNames?.FirstName || ''
                }}"
                        matTooltipPosition="above"
                      />
                      <mat-label>{{ 'identityReports.firstName' | translate }}</mat-label>
                    </mat-form-field>
                    <mat-form-field flex="33">
                      <input
                        matInput
                        readonly
                        type="text"
                        [value]="personDataReportData?.PersonNames?.SurName || ''"
                        placeholder="{{ 'identityReports.middleName' | translate }}"
                        flex="100"
                        matTooltip="{{
                  personDataReportData?.PersonNames?.SurName || ''
                }}"
                        matTooltipPosition="above"
                      />
                      <mat-label>{{ 'identityReports.middleName' | translate }}</mat-label>
                    </mat-form-field>
                    <mat-form-field flex="33">
                      <input
                        matInput
                        readonly
                        type="text"
                        [value]="personDataReportData?.PersonNames?.FamilyName || ''"
                        placeholder="{{ 'identityReports.lastName' | translate }}"
                        flex="100"
                        matTooltip="{{
                  personDataReportData?.PersonNames?.FamilyName || ''
                }}"
                        matTooltipPosition="above"
                      />
                      <mat-label>{{ 'identityReports.lastName' | translate }}</mat-label>
                    </mat-form-field>
                  </div>
                  <div flex="100" layout="row"
                       layout-align="center center" class="column-gap-10">
                    <mat-form-field flex="33">
                      <input
                        matInput
                        readonly
                        type="text"
                        [value]="personDataReportData?.LatinNames?.FirstName || ''"
                        placeholder="{{ 'identityReports.firstNameLatin' | translate }}"
                        flex="100"
                        matTooltip="{{
                  personDataReportData?.LatinNames?.FirstName || ''
                }}"
                        matTooltipPosition="above"
                      />
                      <mat-label>{{ 'identityReports.firstNameLatin' | translate }}</mat-label>
                    </mat-form-field>
                    <mat-form-field flex="33">
                      <input
                        matInput
                        readonly
                        type="text"
                        [value]="personDataReportData?.LatinNames?.SurName || ''"
                        placeholder="{{ 'identityReports.middleNameLatin' | translate }}"
                        flex="100"
                        matTooltip="{{
                  personDataReportData?.LatinNames?.SurName || ''
                }}"
                        matTooltipPosition="above"
                      />
                      <mat-label>{{ 'identityReports.middleNameLatin' | translate }}</mat-label>
                    </mat-form-field>
                    <mat-form-field flex="33">
                      <input
                        matInput
                        readonly
                        type="text"
                        [value]="personDataReportData?.LatinNames?.FamilyName || ''"
                        placeholder="{{ 'identityReports.lastNameLatin' | translate }}"
                        flex="100"
                        matTooltip="{{
                  personDataReportData?.LatinNames?.FamilyName || ''
                }}"
                        matTooltipPosition="above"
                      />
                      <mat-label>{{ 'identityReports.lastNameLatin' | translate }}</mat-label>
                    </mat-form-field>
                  </div>
                  <div flex="100" layout="row" *ngIf="personDataReportData?.ForeignNames?.FirstName"
                       layout-align="center center" class="column-gap-10">

                    <mat-form-field flex="33">
                      <input
                        matInput
                        readonly
                        type="text"
                        [value]="personDataReportData?.ForeignNames?.FirstName || ''"
                        placeholder="{{ 'identityReports.firstNameInForeign' | translate }}"
                        flex="100"
                        matTooltip="{{
                  personDataReportData?.ForeignNames?.FirstName || ''
                }}"
                        matTooltipPosition="above"
                      />
                      <mat-label>{{ 'identityReports.firstNameInForeign' | translate }}</mat-label>
                    </mat-form-field>
                    <mat-form-field flex="33">
                      <input
                        matInput
                        readonly
                        type="text"
                        [value]="personDataReportData?.ForeignNames?.SurName || ''"
                        placeholder="{{ 'identityReports.middleNameInForeign' | translate }}"
                        flex="100"
                        matTooltip="{{
                  personDataReportData?.ForeignNames?.SurName || ''
                }}"
                        matTooltipPosition="above"
                      />
                      <mat-label>{{ 'identityReports.middleNameForeign' | translate }}</mat-label>
                    </mat-form-field>
                    <mat-form-field flex="33">
                      <input
                        matInput
                        readonly
                        type="text"
                        [value]="personDataReportData?.ForeignNames?.FamilyName || ''"
                        placeholder="{{ 'identityReports.lastNameForeign' | translate }}"
                        flex="100"
                        matTooltip="{{
                  personDataReportData?.ForeignNames?.FamilyName || ''
                }}"
                        matTooltipPosition="above"
                      />
                      <mat-label>{{ 'identityReports.lastNameForeign' | translate }}</mat-label>
                    </mat-form-field>
                  </div>
                  <div flex="100" layout="row"
                       layout-align="center center" class="column-gap-10">

                    <mat-form-field flex="33">
                      <input
                        matInput
                        readonly
                        type="text"
                        [value]="(personDataReportData?.BirthDate | date) || ''"
                        placeholder="{{ 'global.dateOfBirth' | translate }}"
                        flex="100"
                        matTooltip="{{
                  (personDataReportData?.BirthDate | date) || ''
                }}"
                        matTooltipPosition="above"
                      />
                      <mat-label>{{ 'global.dateOfBirth' | translate }}</mat-label>
                    </mat-form-field>
                    <mat-form-field flex="33">
                      <input
                        matInput
                        readonly
                        type="text"
                        [value]="personDataReportData?.PlaceBirth || ''"
                        placeholder="{{ 'mi.BirthPlace' | translate }}"
                        flex="100"
                        matTooltip="{{
                  personDataReportData?.PlaceBirth|| ''
                }}"
                        matTooltipPosition="above"
                      />
                      <mat-label>{{ 'mi.BirthPlace' | translate }}</mat-label>
                    </mat-form-field>
                    <mat-form-field flex="33">
                      <input
                        matInput
                        readonly
                        type="text"
                        [value]="personDataReportData?.Nationality.NationalityName || ''"
                        placeholder="{{ 'identityReports.nationality.name' | translate }}"
                        flex="100"
                        matTooltip="{{
                  personDataReportData?.Nationality.NationalityCode || ''
                }}"
                        matTooltipPosition="above"
                      />
                      <mat-label>{{ 'identityReports.nationality.name' | translate }}</mat-label>
                    </mat-form-field>
                    <mat-form-field flex="33" *ngIf="personDataReportData?.Nationality?.NationalityCode2">
                      <input
                        matInput
                        readonly
                        [style.color]="personDataReportData?.Nationality.NationalityName2 ? 'red' : ''"
                        type="text"
                        [value]="personDataReportData?.Nationality?.NationalityName2 || ''"
                        placeholder="{{ 'identityReports.secondNationality.name' | translate }}"
                        flex="100"
                        matTooltip="{{
                  personDataReportData?.Nationality.NationalityCode2 || ''
                }}"
                        matTooltipPosition="above"
                      />
                      <mat-label>{{ 'identityReports.secondNationality.name' | translate }}</mat-label>
                    </mat-form-field>
                  </div>
                  <div flex="100" layout="row"
                       layout-align="center center" class="column-gap-10">

                    <mat-form-field flex="33">
                      <input
                        matInput
                        readonly
                        type="text"
                        [value]="personDataReportData?.DeathDate || ''"
                        placeholder="{{ 'global.dateOfDeath' | translate }}"
                        flex="100"
                        matTooltip="{{
                  personDataReportData?.DeathDate || ''
                }}"
                        matTooltipPosition="above"
                      />
                      <mat-label>{{ 'global.dateOfDeath' | translate }}</mat-label>
                    </mat-form-field>
                    <mat-form-field flex="33">
                      <input
                        matInput
                        readonly
                        type="text"
                        [value]="personDataReportData?.Gender.GenderName || ''"
                        placeholder="{{ 'maritalStatus.Gender' | translate }}"
                        flex="100"
                        matTooltip="{{
                  personDataReportData?.Gender.GenderCode || ''
                }}"
                        matTooltipPosition="above"
                      />
                      <mat-label>{{ 'maritalStatus.Gender' | translate }}</mat-label>
                    </mat-form-field>
                    <mat-form-field flex="33">
                      <input
                        matInput
                        readonly
                        type="text"
                        [value]="personDataReportData?.Alias || ''"
                        placeholder="{{ 'identityReports.Alias' | translate }}"
                        flex="100"
                        matTooltip="{{
                  personDataReportData?.Alias || ''
                }}"
                        matTooltipPosition="above"
                      />
                      <mat-label>{{ 'identityReports.Alias' | translate }}</mat-label>
                    </mat-form-field>
                  </div>
                </div>
              </mat-card-content>

            </mat-card>
          </div>
        </ng-container>
      </ng-container>

      <ng-container *ngSwitchCase="'incorrect_input'">
        <h4 layout="row" layout-align="start">
          {{ 'personData.incorrectDataMsg' | translate }}
        </h4>
      </ng-container>

      <ng-container *ngSwitchCase="'not_found'">
        <h4 layout="row" layout-align="start">
          {{
          'personData.noPersonDataDataFound'
            | translate
          }}
        </h4>
      </ng-container>

      <ng-container *ngSwitchCase="'server_timeout'">
        <h3 class="edit-screen-card-title" layout="row" layout-align="start">
          {{
          'reports.serverTimeoutError'
            | translate: {requestTime: requestTime | itfgDateFormat}
          }}
          <itfg-no-reports-info
            [alertMessage]="{
              civilId: client?.civilId,
              type: personDataData?.type,
              source: personDataData?.source,
              status: personDataData?.status,
              time: personDataData?.time,
              data: personDataData?.data
            }"
          >
          </itfg-no-reports-info>
        </h3>
      </ng-container>

      <ng-container *ngSwitchCase="'server_error'">
        <div layout="row" layout="row" layout-align="start">
          <h3 class="edit-screen-card-title">
            {{
            'reports.serverGenericError'
              | translate: {requestTime: requestTime | itfgDateFormat}
            }}
            <itfg-no-reports-info
              [alertMessage]="{
                civilId: client?.civilId,
                type: personDataData?.type,
                source: personDataData?.source,
                status: personDataData?.status,
                time: personDataData?.time,
                data: personDataData?.data
              }"
            >
            </itfg-no-reports-info>
          </h3>
        </div>
      </ng-container>
    </ng-container>
  </div>
</div>

