import {
  Component,
  ChangeDetectionStrategy,
  SimpleChanges,
  ViewContainerRef,
  Input,
  ChangeDetectorRef,
  OnDestroy,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { DialogProviderService } from '@app/core/services/dialog-provider.service';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { filter, mergeMap, takeUntil } from 'rxjs/operators';
import { ClientService } from '../../../core/services/client.service';
import { ErrorService } from '../../../core/services/error.service';
import { IdentityReportsService } from '../../../core/services/identity-reports.service';
import { Client } from '../../../core/types/client';
import { ClientMapperTypes, CompensationTypes, DisabilityCompensationReportData, IdentityReport, ListElement, UnemploymentCompensationReportData, ReportConfig } from '../../../core/types/identity-reports';
import { ChooseCompensationTypeReportComponent } from './choose-compensation-type-report/choose-compensation-type-report.component';
import * as moment from 'moment';

@Component({
  selector: 'itfg-compensation-report',
  templateUrl: './compensation-report.component.html',
  styleUrls: ['./compensation-report.component.scss'],
})
export class CompensationReportComponent implements OnDestroy {
  @Input() inputClient: Client;

  public compensationData: IdentityReport<any>;
  public compensationListData: ListElement[] = [];
  public requestType: string;
  public requestTime: Date = new Date();
  public requesterName: string;
  public requestStatus = null;
  public disabilityCompensationColumns: any[];
  public unemploymentCompensationColumns: any[];
  public dialogConfig: any;
  public CompensationTypes = CompensationTypes;
  public isReport: boolean;
  public client: Client;
  public dateFrom: string;
  public dateTo: string;
  public paymentByDateData: any;
  public CLIENT_PERSONAL_INFO_MAPPER: { [key: string]: string };
  public criticalProperties: any[];
  public compensationConfig: ReportConfig;
  _unsubscribe: Subject<void> = new Subject<void>();

  public _paymentData: any;

  get paymentData() {
    return this._paymentData;
  }

  set paymentData(paymentData) {
    this._paymentData = paymentData;
    this.cdr.detectChanges();
  }


  constructor(
    private identityReportsService: IdentityReportsService,
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private clientService: ClientService,
    private errorService: ErrorService,
    private translate: TranslateService,
    private dialogService: DialogProviderService,
    private _viewContainerRef: ViewContainerRef,
    private cdr: ChangeDetectorRef
  ) {

    this.CLIENT_PERSONAL_INFO_MAPPER = {
      [ClientMapperTypes.FIRST_NAME]: ClientMapperTypes.FIRST_NAME,
      [ClientMapperTypes.MIDDLE_NAME]: ClientMapperTypes.MIDDLE_NAME,
      [ClientMapperTypes.LAST_NAME]: ClientMapperTypes.LAST_NAME,
    };

    this.criticalProperties = [
      ClientMapperTypes.FIRST_NAME,
      ClientMapperTypes.MIDDLE_NAME,
      ClientMapperTypes.LAST_NAME
    ];
  }

  ngOnInit() {
    this.route.paramMap
      .pipe(
        filter((params: ParamMap) => typeof params.get('id') === 'string'),
        mergeMap((params: ParamMap) =>
          this.clientService.getClientById(Number(params.get('id')))
        )
      )
      .pipe(takeUntil(this._unsubscribe))
      .subscribe(
        client => {
          this.client = client;
          if (this.client.civilId) {
            this.setInitialGridData(this.client.civilId);
          }
        },
        error => {
          this.errorService.handleError(error, this.route.data);
        }
      );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.inputClient) {
      this.client = this.inputClient;
      if (this.client.civilId) {
        this.setInitialGridData(this.client.civilId);
      }
    }
  }

  setInitialGridData(civilId: string) {
    this.requesterName = null;
    this.requesterName = '';
    this.identityReportsService
      .getCompensationReportList$(civilId)
      .pipe(takeUntil(this._unsubscribe))
      .subscribe(compensationListData => {
        for (const listData of compensationListData) {
          const time = new Date(listData.time);
          listData.time = time;
          this.compensationListData.push(compensationListData);
        }
        this.compensationListData = compensationListData;

        if (this.compensationListData && this.compensationListData.length > 0) {
          const reportId = this.compensationListData[0].id;
          this.requestType = this.compensationListData[0].type.replace('_', '-');

          this.getReport(this.client.civilId, reportId, this.requestType);
          this.isReport = true;
        } else {
          this.isReport = false;
        }
      });

    this.setDisabilityCompensationData();
    this.setUnemploymentCompensationData();
  }

  getReport(civilId, reportId, requestType) {
    this.identityReportsService
      .getCompensationReportById(civilId, reportId, requestType)
      .pipe(takeUntil(this._unsubscribe))
      .subscribe((data: IdentityReport<any>) => {
        this.compensationData = data;
        this.requestType = data.type.replace('_', '-');
        this.requestTime = new Date(data.time);
        this.requesterName = data.requesterName;
        this.requestStatus = data.status;


        this.processCompensationReportData(this.compensationData.data);
      });
  }

  setDisabilityCompensationData() {
    this.translate
      .get([
        'global.kind',
        'global.month',
        'global.year',
        'global.sum',
        'global.date'
      ])
      .pipe(takeUntil(this._unsubscribe))
      .subscribe(translation => {
        this.disabilityCompensationColumns = [
          {
            name: 'BenefitType',
            label: translation['global.kind'],
          },
          {
            name: 'BenefitMonth',
            label: translation['global.month'],
          },
          {
            name: 'BenefitYear',
            label: translation['global.year'],
          },
          {
            name: 'BenefitDatePayment',
            label: translation['global.date'],
          },
          {
            name: 'BenefitAmount',
            label: translation['global.sum'],
          },
        ];
      });
  }

  setUnemploymentCompensationData() {
    this.translate
      .get([
        'global.month',
        'global.year',
        'global.sum',
      ])
      .pipe(takeUntil(this._unsubscribe))
      .subscribe(translation => {
        this.unemploymentCompensationColumns = [
          {
            name: 'BenefitMonth',
            label: translation['global.month'],
          },
          {
            name: 'BenefitYear',
            label: translation['global.year'],
          },

          {
            name: 'BenefitAmount',
            label: translation['global.sum'],
          },
        ];
      });
  }

  createCompensationForm() {
    return this.formBuilder.group({
      compensationList: [''],
    });
  }

  compensationDataChanged(event) {
    for (let i = 0; i < this.compensationListData.length; i++) {
      if (event.value.id === this.compensationListData[i].id) {
        this.requestType = this.compensationListData[i].type.replace('_', '-');
        const reportId = this.compensationListData[i].id;
        this.getReport(this.client.civilId, reportId, this.requestType);
        break;
      }
    }
  }

  getNewCompensationReport() {
    this.dialogService
      .open(ChooseCompensationTypeReportComponent, {
        minWidth: '320px',
        minHeight: '300px',
        restoreFocus: false,
        autoFocus: false,
        data: {
          client: this.client,
          compensationTypesList: CompensationTypes,
        },
      })
      .afterClosed()
      .pipe(
        takeUntil(this._unsubscribe),
        filter(res => !!res),
        mergeMap((response: { type: string, dateFrom?: string }) => {
          const fromDate = response.dateFrom ?
            moment(response.dateFrom).format('YYYY-MM-DD') :
            moment().subtract(1, 'years').format('YYYY-MM-DD');
          if (response.type === CompensationTypes.DISABILITY_COMPENSATION) {
            return this.identityReportsService.getDisabilityCompensationReport(this.client.civilId, fromDate);
          } else if (response.type === CompensationTypes.UNEMPLOYMENT_COMPENSATION) {
            return this.identityReportsService.getUnemploymentCompensationReport(this.client.civilId, fromDate);
          }
        })
      )
      .subscribe(res => {
        this.setInitialGridData(this.client.civilId);
      });
  }

  processCompensationReportData(reportData) {
    if (reportData) {
      this.dateFrom = reportData.dateFrom;
      this.dateTo = reportData.dateTo;

      const clientFullName = reportData?.Names ? reportData?.Names : '';
      const clientNames = clientFullName.split(' ');

      if (reportData.PaymentData) {
        this.paymentData = reportData.PaymentData;
      }

      if (reportData.PaymentByDate) {
        this.paymentByDateData = reportData.PaymentByDate;
      }

      this.compensationConfig = {
        reportData: reportData,
        client: this.client,
        clientMapper: this.CLIENT_PERSONAL_INFO_MAPPER,
        criticalProperties: this.criticalProperties,
        processReportData: (reportData) => {
          return this.identityReportsService.parseCompensationDataForUpdate(clientNames);
        },
      };
    }
  }

  compareItemsById(firstObj, secondObj) {
    if (firstObj && secondObj) {
      return firstObj.id === secondObj.id;
    } else {
      return false;
    }
  }

  ngOnDestroy(): void {
    this._unsubscribe.next();
    this._unsubscribe.complete();
  }
}
