import { Subject } from 'rxjs';
import { mergeMap, filter, takeUntil } from 'rxjs/operators';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { ClientService } from '../../../core/services/client.service';
import { ErrorService } from '../../../core/services/error.service';

import { Client } from '../../../core/types/client';

@Component({
  selector: 'itfg-client-communication',
  templateUrl: './client-communication.component.html',
  styleUrls: ['./client-communication.component.scss'],
})
export class ClientCommunicationComponent implements OnInit, OnDestroy {
  client: Client;

  private _unsubscribe: Subject<void> = new Subject<void>();

  constructor(

    private route: ActivatedRoute,
    private clientService: ClientService,
    private errorService: ErrorService,
  ) {}

  ngOnInit() {
    this.route.paramMap
      .pipe(
        filter((params: ParamMap) => typeof params.get('id') === 'string'),
        mergeMap((params: ParamMap) =>
          this.clientService.getClientById(Number(params.get('id')))
        ),
        takeUntil(this._unsubscribe)
      )
      .subscribe(
        client => {
          this.client = client
        },
        error => {
          this.errorService.handleError(error, this.route.data);
        }
      );
  }

  ngOnDestroy(): void {
    this._unsubscribe.next();
    this._unsubscribe.complete();
  }
}
