import {
  Component,
  OnInit,
  Input,
  EventEmitter,
  Output,
  OnChanges,
  SimpleChanges,
  Inject,
  Optional,
} from '@angular/core';
import { trigger, transition, style, animate } from '@angular/animations';
import { ItfgDataTableColumn } from '@app/core/components/data-table/types/data-table.column';
import { Column, ColumnStatus } from '../../../core/types/column';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import {
  PageName,
  UserPagePreferences,
  UserSettings,
} from '../../../core/types/user-preferences';
import { SessionService } from '../../../core/services/session.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ColumnsService } from '../../../core/services/columns.service';

@Component({
  selector: 'itfg-column-card',
  templateUrl: './column-card.component.html',
  styleUrls: ['./column-card.component.scss'],
  animations: [
    trigger('cardState', [
      transition(':enter', [
        style({
          transformOrigin: 'top',
          transform: 'scaleY(0)',
          opacity: 0,
        }),
        animate(
          150,
          style({
            transformOrigin: 'top',
            transform: 'scaleY(1)',
            opacity: 1,
          })
        ),
      ]),
      transition(':leave', [
        style({
          transformOrigin: 'top',
          transform: 'scaleY(1)',
          opacity: 1,
        }),
        animate(
          150,
          style({
            transformOrigin: 'top',
            transform: 'scaleY(0)',
            opacity: 0,
          })
        ),
      ]),
    ]),
  ],
})
export class ColumnCardComponent implements OnInit, OnChanges {
  @Input() isShown: boolean;
  @Input() columns: ItfgDataTableColumn[];
  @Input() sessionSettingsPageName: PageName;
  @Output() columnsChange: EventEmitter<Column[]>;
  @Output() closeClicked: EventEmitter<void>;
  @Output() tableColumnsChange: EventEmitter<ItfgDataTableColumn[]>;

  public initialColumnList: Column[];
  public columnList: Column[];
  public columnStatusList: typeof ColumnStatus = ColumnStatus;
  public userPagePreferences: UserPagePreferences;
  public userSettings: UserSettings;
  public disableDrag: boolean;

  constructor(
    @Optional() public dialogRef: MatDialogRef<ColumnCardComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private sessionService: SessionService,
    public columnsService: ColumnsService
  ) {
    this.userPagePreferences = {
      filters: { config: [], isShown: true },
      columns: [],
    };
    this.userSettings = new UserSettings();

    // this.closeClicked = new EventEmitter<void>();
    // this.columnsChange = new EventEmitter<Column[]>();
    // this.tableColumnsChange = new EventEmitter<ItfgDataTableColumn[]>();
    if (this.data) {
      this.columns = data.columns;
      this.sessionSettingsPageName = data.sessionSettingsPageName;
    }
  }

  ngOnChanges(changes: SimpleChanges) {}

  ngOnInit() {
    this.initialColumnList = this.columns.map(
      (column: ItfgDataTableColumn): Column => {
        return {
          status: ColumnStatus.ACTIVE,
          config: {
            ...column,
          },
        };
      }
    );

    this.columnList = this.copyColumnList(this.initialColumnList);
    this.applyUserPreferences();
  }

  onRowClick(index: number) {
    this.columnList[index].status =
      this.columnList[index].status === ColumnStatus.ACTIVE
        ? ColumnStatus.INACTIVE
        : ColumnStatus.ACTIVE;
    this.columnChangesApplied(this.columnList);
  }

  onMouseDown() {
    this.disableDrag = true;
  }

  onMouseUp() {
    this.disableDrag = false;
  }

  onToggleChange(index: number, event: MatSlideToggleChange) {
    // this.columnList[index].status = !this.columnList[index].status;
    this.columnList[index].status =
      this.columnList[index].status === ColumnStatus.ACTIVE
      ? ColumnStatus.ACTIVE
      : ColumnStatus.INACTIVE;
    this.columnChangesApplied(this.columnList);
  }

  columnChangesApplied(columnList: Column[]) {
    this.sessionService.onColumnsChange.next(this.initialColumnList);

    this.columnsService.onColumnsTableChange.next(
      columnList
        .filter(column => column.status === ColumnStatus.ACTIVE)
        .map(column => column.config)
    );

    this.columnsService.onColumnsChange.next([...columnList]);

    // this.columnsChange.emit([
    //   ...columnList
    // ]);
    // this.tableColumnsChange.emit(
    //   columnList
    //     .filter(column => column.status === ColumnStatus.ACTIVE)
    //     .map(column => column.config)
    // );
    if (this.sessionSettingsPageName) {
      this.saveColumnsState(columnList);
    }
    this.disableDrag = false;
  }

  resetColumns() {
    this.columnList = this.copyColumnList(this.initialColumnList);
    this.columnChangesApplied(this.columnList);
  }

  onDrag(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.columnList, event.previousIndex, event.currentIndex);
    this.columnChangesApplied(this.columnList);
  }

  applyUserPreferences() {
    const columnPreferences = this.sessionService.getColumnSettings(
      this.sessionSettingsPageName
    );

    if (columnPreferences && columnPreferences.length > 0) {
      const currentColumnList = [...columnPreferences];

      this.columnList.forEach(column => {
        const savedColumn = currentColumnList.find(
          col => col.config.name === column.config.name
        );
        if (!!savedColumn) {
          savedColumn.config = column.config;
        } else {
          currentColumnList.push(column);
        }
      });

      currentColumnList.forEach(column => {
        column.config = this.columnList.find(
          col => col.config.name === column.config.name
        ).config;
      });

      this.columnList = currentColumnList;
    } else {
      // TODO: get filters with api call and save them in the storage
    }
    this.columnChangesApplied(this.columnList);
  }

  saveColumnsState(columnList) {
    this.userPagePreferences.columns = columnList;
    this.userSettings.addPagePreferences(
      this.sessionSettingsPageName,
      this.userPagePreferences
    );
    this.sessionService.setColumnsSettings(
      this.sessionSettingsPageName,
      this.userSettings
    );
  }

  private copyColumnList(columnList: Column[]) {
    return [...columnList.map(column => ({ ...column }))];
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
