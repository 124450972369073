import {Component, Input, OnInit} from '@angular/core';
import { ReportTypes } from '@app/core/types/identity-reports';
import {Client} from '@core/types/client';

@Component({
  selector: 'itfg-grao-reports',
  templateUrl: './grao-reports.component.html',
  styleUrls: ['./grao-reports.component.scss']
})
export class GraoReportsComponent implements OnInit {
  @Input() inputClient: Client;
  @Input() reportType: string;

  public ReportTypes: typeof ReportTypes = ReportTypes;

  ngOnInit() {
  }
}
