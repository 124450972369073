<h2 mat-dialog-title>Инструкции за настройка на двустепенно удостоверяване</h2>
<mat-dialog-content class="mat-typography">
  <p>
    Приложението за за двустепенно удостоверяване служи за сигурно идентифициране на вашата самоличност.
    <b>Google</b>, <b>Microsoft</b> и <b>Authy</b> са примери за добре познати приложения за удостоверяване.
  </p>

  <ul>
    <li>Ако вече имате приложение за двустепенно удостоверяване, го отворете на телефона си и продължете процеса.</li>
    <li>
      Ако все още нямате приложение за двустепенно удостоверяване:
      <ul>
        <li>Потърсете и инсталирайте надеждно приложение за двустепенно удостоверяване през Play store (Google) или App Store (Apple)</li>
        <li>Може да използвате някое от следните приложения:</li>
      </ul>
    </li>
  </ul>

  <table>
    <tr>
      <td>
        <b>Google Authenticator</b>
      </td>
      <td>
        <a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en&pli=1" target="_blank">Android</a>
      </td>
      <td>
        <a href="https://apps.apple.com/us/app/google-authenticator/id388497605" target="_blank">IOS</a>
      </td>
    </tr>
    <tr>
      <td>
        <b>Microsoft Authenticator</b>
      </td>
      <td>
        <a href="https://play.google.com/store/apps/details?id=com.azure.authenticator&hl=en" target="_blank">Android</a>
      </td>
      <td>
        <a href="https://apps.apple.com/us/app/microsoft-authenticator/id983156458" target="_blank">IOS</a>
      </td>
    </tr>
    <tr>
      <td>
        <b>Authy</b>
      </td>
      <td> 
        <a href="https://play.google.com/store/apps/details?id=com.authy.authy&hl=en" target="_blank">Android</a>
      </td>
      <td>
        <a href="https://apps.apple.com/us/app/twilio-authy/id494168017" target="_blank">
          IOS
        </a>
      </td>
    </tr>
  </table>


</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button [mat-dialog-close]="true" cdkFocusInitial>{{'confirmPopup.agree'|translate}}</button>
</mat-dialog-actions>
