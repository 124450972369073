import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import {
  CreditStatusNames,
  CreditStatus,
} from '../../../../../core/types/credit-status';
import {
  CREDIT_STATUS_STYLE_MAP,
  StyleConfigMap,
} from '../../../../../config/credit-config';
import { TranslateService } from '@ngx-translate/core';
import { CreditStatusReasonToTranslationKeyPipe } from '../../../pipes/credit-status-reason-to-translation-key.pipe';
import { CreditAssignment } from '../../../../../../app/core/types/credit-assignment';
import { CreditService } from '../../../../../../app/core/services/credit.service';
import { CollectionAgentTypes } from '../../../../../../app/core/types/collection-agent';

@Component({
  selector: 'itfg-credit-status',
  templateUrl: './credit-status.component.html',
  styleUrls: ['./credit-status.component.scss'],
})
export class CreditStatusComponent implements OnInit, OnChanges {
  @Input() status: CreditStatus;
  @Input() statusReasonsList: string[];
  @Input() statusChangeDisabled: boolean;
  @Input() reasonChangeDisabled: boolean;
  @Input() activeAssignment: CreditAssignment;
  public creditStatusStyleMap: StyleConfigMap;
  statusChangeOptions: CreditStatusNames[];
  statusDisplayText: string;
  statusHoverText: string;
  styleOptions: any;
  icon: string;
  translatedStatus: string;
  translatedReason: string;

  constructor(
    private translate: TranslateService,
    private creditService: CreditService
  ) {
    this.creditStatusStyleMap = CREDIT_STATUS_STYLE_MAP;
  }

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges): void {
    this.statusChangeOptions = this.creditService.statusChangeOptions(
      this.status.name
    );
    const statusReasonPipe = new CreditStatusReasonToTranslationKeyPipe();
    const statusReasonTranslationKey =
      this.status.reason && statusReasonPipe.transform(this.status.reason);
    this.translatedReason =
      statusReasonTranslationKey &&
      this.translate.instant(statusReasonTranslationKey);
    this.translatedStatus =
      this.status.name &&
      this.translate.instant(
        'credits.creditStatusList.' + this.status.name.toLowerCase()
      );
    this.statusDisplayText = this.translatedStatus;
    this.statusHoverText = this.translatedStatus;

    if (this.activeAssignment) {
      let translationKey = 'collection.sentStatusTextTemplate';
      if (this.activeAssignment.assignTime) {
        translationKey = 'collection.assignedStatusTextTemplate';
      }
      if (this.status?.name !== CreditStatusNames.REPAID) {
        this.statusDisplayText = this.translate.instant(
          translationKey,
          {
            instanceName: this.activeAssignment.instanceName || this.translate.instant(
              'collection.agentType.' + this.activeAssignment.operator.category
            ),
          }
        );
      }
      // this.statusDisplayText = this.activeAssignment.instanceName || this.activeAssignment.operator.name;
      this.statusHoverText =
        `${this.translatedStatus}` +
        (this.status.reason ? ` - ${this.translatedReason}` : '');
    }

    if (this.status.reason) {
      this.statusDisplayText = this.translatedReason;
      this.statusHoverText =
        `${this.translatedStatus}` +
        (this.activeAssignment ? ` - ${this.activeAssignment.instanceName}` : '');
    }

    this.statusDisplayText = this.statusDisplayText.toUpperCase();
    this.icon = this.creditStatusStyleMap[this.status.name].icon;

    this.styleOptions = {
      color: 'white',
      'background-color': this.creditStatusStyleMap[this.status.name]
        .backgroundColor,
    };
  }
}
